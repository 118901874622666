import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as SharedStore from './sharedAction';
import SchoolStore from '../../school/store/reducers/reducerSchool';
import StudentStore from '../../student/store/reducers/reducerStudent';
import StaffStore from '../../staff/store/reducers/reducerStaff';
import courseStore from '../../course/store/reducers/reducerCourse';
import attendanceStore from '../../attendance/store/reducers/reducerAttendance';
import LoginStore from '../../common/store/reducers/reducerLogin';
import CommonStore from '../../shared/store/reducer/reducerShared';
import ScheduleStore from '../../schedule/store/reducers/reducerSchedule';
import ReportStore from '../../reports/store/reducers/reducerReport';
import DisciplineStore from '../../discipline/store/reducers/reducerDiscipline';
import DistrictStore from '../../district/store/reducers/reducerDistrict';
import AssessmentStore from '../../assessment/store/reducers/reducerAssessment';
import SystemStore from '../../system/store/reducers/reducerSystem';
import MessageStore from '../../messages/store/reducers/reducerMessage';

export default function sharedConfigureStore(history, initialState) {
    const reducers = {
        sharedStore: SharedStore.reducer,
        schoolStore: SchoolStore,
        studentStore: StudentStore,
        staffStore: StaffStore,
        courseStore: courseStore,
        loginStore: LoginStore,
        commonStore: CommonStore,
        scheduleStore: ScheduleStore,
        attendanceStore: attendanceStore,
        reportStore: ReportStore,
        disciplineStore: DisciplineStore,
        districtStore: DistrictStore,
        assessmentStore: AssessmentStore,
        systemStore: SystemStore,
        messageStore: MessageStore
    };
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];
    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }
    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });
    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}