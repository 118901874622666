export class commonStudentConfig {
    static COMPONENT_STUDENT = 'student';
    static METHOD_TYPE_POST = 'POST';
    static METHOD_TYPE_GET = 'GET';
    static COMPONENT_STUDENT_GETALL = 'v1/studentinfo/GetStudentInfo';
    static COMPONENT_STUDENT_GET_BY_ID = 'v1/studentinfo/GetStudentInfoById?id=';
    static COMPONENT_STUDENT_ACTIVE_INACTIVE_GET_BY_SESSOINID = 'v1/studentinfo/getstudentinfoactiveinactivebyid?id=';
    static COMPONENT_STUDENT_GET_BY_SCHOOL_ID = 'v1/studentinfo/GetStudentInfo';
    static COMPONENT_STUDENT_GET_BY_SESSION_ID = 'v1/studentinfo/getstudentinforecordsbysessionid';
    static COMPONENT_STUDENT_GET_BY_PAGINATION = 'v1/studentinfo/getstudentinfopaginated';
    static COMPONENT_STUDENT_GET_BY_EXPORT = 'v1/studentinfo/getstudentinfoexport?schoolId=';
    static COMPONENT_STUDENT_GETALL_BY_SCHOOL_AND_GRADE = 'v1/studentinfo/getstudentbasicdetailsbyschoolgrade';
    static COPONENT_STUDENT_GET_BY_PDF = 'v1/studentinfo/downloadpdfformatdata?';
    static COMPONENT_STUDENT_GETALL_BY_SESSION_AND_GRADELEVEL = 'v1/studentinfo/getstudentlistbysessionidgradelevel';
    static COMPONENT_STUDENT_GET_DEPENDENCIES_BY_ID = 'v1/studentinfo/getstudentdependenciesbyid?studentId=';
    static COMPONENT_STUDENT_DELETE_BY_ID = 'v1/studentinfo/deletestudentwithdependenciesbyid?studentId=';
    //GeneralInfo    
    static COMPONENT_STUDENT_GENERALINFO_SAVE = 'v1/studentinfo/SaveStudentInfo';
    static COMPONENT_STUDENT_GENERALINFO_UPDATE = 'v1/studentinfo/UpdatesStudentInfo';
    static COMPONENT_STUDENT_ADDRESS_TYPE = 'v1/studentinfo/getstudentaddresstype';
    static COMPONENT_STUDENT_GET_LANGUAGES = 'v1/studentinfo/GetAllAvailableLanguages';
    static COMPONENT_STUDENT_GET_LANGUAGES_NM = 'v1/studentinfo/getlanguagesbycountryandstatecode?countryCode=';
    static COMPONENT_STUDENT_GET_STUDENT_CODE = 'v1/studentinfo/getstudentcode?';
    static COMPONENT_STUDENT_GET_LANGUAGE_BY_STATECODE = 'v1/studentinfo/getlanguagesbycountryandstatecode?countryCode=';
    static COMPONENT_STUDENT_GET_BY_EXPORT_CSV = 'v1/studentinfo/getstudentlistcsvexport?schoolId=';
    //GeneralInfo India School
    static COMPONENT_IN_STUDENT_GENERALINFO_SAVE = 'v1/in/indianschoolstudentinfo/savestudentinfo';
    static COMPONENT_IN_STUDENT_GENERALINFO_UPDATE = 'v1/in/indianschoolstudentinfo/updatestudentinfo';
    static COMPONENT_IN_STUDENT_GET_BY_ID = 'v1/in/indianschoolstudentinfo/getstudentinfobyid?id=';
    // Identifier
    static COMPONENT_STUDENT_IDENTIFIER_GETALL = 'v1/studentidentifier/getstudentidentifierbystudentid?Id=';
    static COMPONENT_STUDENT_IDENTIFIER_SAVE = 'v1/studentidentifier/savestudentidentifier';
    static COMPONENT_STUDENT_IDENTIFIER_UPDATE = 'v1/studentidentifier/updatestudentidentifier';
    static COMPONENT_STUDENT_IDENTIFIER_DELETE = 'v1/studentidentifier/deletestudentidentifier';
    static COMPONENT_STUDENT_IDENTIFIER_GET_BY_ID = 'v1/studentidentifier/getstudentidentifierbyid?Id=';
    static COMPONENT_DEFAULT_STUDENT_IDENTIFIER = 'v1/studentidentifier/getdefaultstudentidentifier';
    static COMPONENT_DEFAULT_STUDENT_GET_IDENTIFICATIONCODE = 'v1/studentidentifier/getstudentcodeforstateassignednumber?DistrictId=';
    //Document Upload
    static COMPONENT_STUDENT_DOCUMENT_GETALL = 'v1/studentdocumentupload/getstudentdocumentuploadbystudentId?studentId=';
    static COMPONENT_STUDENT_DOCUMENT_SAVE = 'v1/studentdocumentupload/savestudentdocumentupload';
    static COMPONENT_STUDENT_DOCUMENT_UPDATE = 'v1/studentdocumentupload/updatestudentdocumentupload';
    static COMPONENT_STUDENT_DOCUMENT_DELETE = 'v1/studentdocumentupload/deletestudentdocumentupload';
    static COMPONENT_STUDENT_DOCUMENT_GET_BY_ID = 'v1/studentdocumentupload/getstudentdocumentuploadbyid?id=';
    static COMPONENT_STUDENT_DOCUMENT_UPLOAD_PAGINATED = 'v1/studentdocumentupload/getstudentuploadeddocumentspaginated';
    //misc status
    static COMPONENT_STUDENT_MISCSTATUS_GETALL = 'v1/studentmiscstatus/GetStudentMiscStatusByStudentId';
    static COMPONENT_STUDENT_MISCSTATUS_SAVE = 'v1/studentmiscstatus/savestudentmiscstatus';
    static COMPONENT_STUDENT_MISCSTATUS_UPDATE = 'v1/studentmiscstatus/updatestudentmiscstatus';
    static COMPONENT_STUDENT_MISCSTATUS_DELETE = 'v1/studentmiscstatus/deletestudentmiscstatus';
    static COMPONENT_STUDENT_MISCSTATUS_GET_BY_ID = 'v1/studentmiscstatus/GetStudentMiscStatusById';
    //misc status indian specific
    static COMPONENT_IN_STUDENT_MISCSTATUS_SAVE = 'v1/in/indianschoolstudentinfo/savestudentmiscstatus';
    static COMPONENT_IN_STUDENT_MISCSTATUS_UPDATE = 'v1/in/indianschoolstudentinfo/updatestudentmiscstatus';
    static COMPONENT_IN_STUDENT_MISCSTATUS_GET_BY_ID = 'v1/in/indianschoolstudentinfo/getstudentmiscstatusbyid';
    //Visa
    static COMPONENT_STUDENT_VISA_GETALL = 'v1/studentvisa/GetStudentVisa';
    static COMPONENT_STUDENT_VISA_SAVE = 'v1/studentvisa/SaveStudentVisa';
    static COMPONENT_STUDENT_VISA_UPDATE = 'v1/studentvisa/UpdateStudentVisa';
    static COMPONENT_STUDENT_VISA_DELETE = 'v1/studentvisa/DeleteStudentVisa';
    static COMPONENT_STUDENT_VISA_GET_BY_ID = 'v1/studentvisa/GetStudentVisaById';
    //guardian
    static COMPONENT_STUDENT_GUARDIAN_GETALL = 'v1/studentguardian/getstudentallguardian';
    static COMPONENT_STUDENT_GUARDIAN_SAVE = 'v1/studentguardian/savestudentguardian';
    static COMPONENT_STUDENT_GUARDIAN_UPDATE = 'v1/studentguardian/updatestudentguardian';
    static COMPONENT_STUDENT_GUARDIAN_DELETE = 'v1/studentguardian/deletestudentguardian';
    static COMPONENT_STUDENT_GUARDIAN_GET_BY_ID = 'v1/studentguardian/getstudentguardianbyid';
    static COMPONENT_STUDENT_ROLE_GET_ALL = 'v1/SchoolRole/getallschoolrole';
    //Guardian(IN)
    static COMPONENT_STUDENT_IN_GUARDIAN_SAVE = 'v1/in/indianschoolstudentinfo/savestudentparentcontact';
    static COMPONENT_STUDENT_IN_GUARDIAN_UPDATE = 'v1/in/indianschoolstudentinfo/updatestudentparentcontact';
    static COMPONENT_STUDENT_IN_GUARDIAN_GET_BY_ID = 'v1/in/indianschoolstudentinfo/getstudentparentcontactbyid';
    static COMPONENT_STUDENT_INDIAN_DELETE_STUDENT = 'v1/in/indianschoolstudentinfo/deleteindianstudentguardian';
    static COMPONENT_STUDENT_GET_STUDENT_GURDIAN_CONTACT_INFO = "v1/studentguardian/getstudentguardiancontactnobypersonid?PersonId=";
    //Disability
    static COMPONENT_STUDENT_DISABILITY_GETALL = 'v1/studentdisability/GetStudentDisabilityByStudentId';
    static COMPONENT_STUDENT_DISABILITY_SAVE = 'v1/studentdisability/SaveStudentDisability';
    static COMPONENT_STUDENT_DISABILITY_UPDATE = 'v1/studentdisability/UpdateStudentDisability';
    static COMPONENT_STUDENT_DISABILITY_DELETE = 'v1/studentdisability/DeleteStudentDisability';
    static COMPONENT_STUDENT_DISABILITY_GET_BY_ID = 'v1/studentdisability/GetStudentDisabilityById';
    static COMPONENT_STUDENT_REFERRAL_BY_DISABILITY = 'v1/studentdisability/GetStudentDisabilityById';
    //Health Screening
    //static COMPONENT_STUDENT_HEALTH_GETALL = 'v1/StudentHealthScreening/GetStudentAllHealthScreeningData';
    static COMPONENT_STUDENT_HEALTH_SCREENING_GETALL = 'v1/StudentHealthScreening/GetStudentAllHealthScreeningData?studentId=';
    static COMPONENT_STUDENT_HEALTH_SAVE = 'v1/StudentHealthScreening/SaveStudentHealthScreening';
    static COMPONENT_STUDENT_HEALTH_UPDATE = 'v1/StudentHealthScreening/UpdatesStudentHealthScreening';
    static COMPONENT_STUDENT_HEALTH_DELETE = 'v1/StudentHealthScreening/DeleteStudentHealthScreening';
    static COMPONENT_STUDENT_HEALTH_GET_BY_ID = 'v1/StudentHealthScreening/GetStudentHealthScreeningById?id=';
    static COMPONENT_STUDENT_HEALTH_GET_BY_SCHOOL_ID = 'v1/studenthealthscreening/getstudenthealthscreeningpaginated?schoolId=';
    //Immunization
    static COMPONENT_STUDENT_IMMUNIZATION_GETALL = 'v1/studentimmunization/GetStudentImmunizations?studentId=';
    static COMPONENT_STUDENT_IMMUNIZATION_SAVE = 'v1/studentimmunization/saveimmunizationinfo';
    static COMPONENT_STUDENT_IMMUNIZATION_UPDATE = 'v1/studentimmunization/updateimmunizationinfo';
    static COMPONENT_STUDENT_IMMUNIZATION_DELETE = 'v1/studentimmunization/DeleteStudentImmunization';
    static COMPONENT_STUDENT_IMMUNIZATION_GET_BY_ID = 'v1/studentimmunization/GetImmunizationById?id=';
    static COMPONENT_STUDENT_IMMUNIZATION_NAME_GETALL = 'v1/studentimmunization/getimmunizationnames';
    static COMPONENT_STUDENT_IMMUNIZATION_TRADE_NAME_GETALL = 'v1/studentimmunization/getimmunizationtradenames?id=';
    //Enrollment Info
    static COMPONENT_STUDENT_ENROLLMENTINFO_GETALL = 'v1/studentenrollment/GetStudentEnrollmentByStudentId';
    static COMPONENT_STUDENT_GETALL_BY_PARENT_ID = 'v1/studentenrollment/getsearchedstudentlistforparent?parentUserId=';
    static COMPONENT_STUDENT_ENROLLMENTINFO_SAVE = 'v1/studentenrollment/SaveStudentEnrollment';
    static COMPONENT_STUDENT_ENROLLMENTINFO_UPDATE = 'v1/studentenrollment/UpdateStudentEnrollment';
    static COMPONENT_STUDENT_ENROLLMENTINFO_DELETE = 'v1/studentenrollment/DeleteStudentEnrollment';
    static COMPONENT_STUDENT_ENROLLMENTINFO_GET_BY_ID = 'v1/studentenrollment/GetStudentEnrollmentById';
    static COMPONENT_STUDENT_SESSIONID_GET_BY_SCHOOL = 'v1/schoolsession/GetActiveSessionForSchool';
    static COMPONENT_STUDENT_ENDOFYEAR_GETALL = 'v1/studentenrollment/geteoystatusstudentlist';
    static COMPONENT_STUDENT_ENDOFYEAR_SAVE = 'v1/studentenrollment/savepromotiondata';
    static COMPONENT_STUDENT_CLEAR_ENROLLMENT = 'v1/studentenrollment/clearenrollmentfornextyear';
    static COMPONENT_STUDENT_PREV_ENROLLMENTCODE = 'v1/studentenrollment/getpreviousenrollmentcodebystudentid?';
    static COMPONENT_STUDENT_ENROLLMENT_STATUS = 'v1/studentenrollment/getenrollmentstatusbystudentidandsessionid?studentId=';
    static COMPONENT_STUDENT_DELETE_ENROLLMENT = 'v1/studentenrollment/deletestudentenrollmentdetails';
    //Enrollment Info Indian School
    static COMPONENT_IN_STUDENT_ENROLLMENTINFO_GETALL = 'v1/in/indianschoolstudentinfo/getstudentenrollmentbystudentid';
    static COMPONENT_IN_STUDENT_ENROLLMENTINFO_GET_BY_ID = 'v1/in/indianschoolstudentinfo/getstudentenrollmentbyid?id=';
    static COMPONENT_IN_STUDENT_ENROLLMENTINFO_SAVE = 'v1/in/indianschoolstudentinfo/savestudentenrollment';
    static COMPONENT_IN_STUDENT_ENROLLMENTINFO_UPDATE = 'v1/in/indianschoolstudentinfo/updatestudentenrollment';
    static COMPONENT_IN_STUDENT_ENROLLMENTINFO_GET_SECTION_BY_ID = 'v1/in/indianschoolstudentinfo/getstudentenrollmentbystudentid?';
    static COMPONENT_IN_STUDENT_STUDENT_SAVE_PROMOTION = 'v1/in/indianschoolstudentinfo/savepromotiondata';
    //Program Gift
    static COMPONENT_STUDENT_ENROLLMENTPROGRAMGIFT_GETALL = 'v1/studentenrollmentprogramgift/GetStudentProgramGiftByStudentId';
    static COMPONENT_STUDENT_ENROLLMENTPROGRAMGIFT_SAVE = 'v1/studentenrollmentprogramgift/SaveStudentProgramGift';
    static COMPONENT_STUDENT_ENROLLMENTPROGRAMGIFT_UPDATE = 'v1/studentenrollmentprogramgift/UpdateStudentProgramGift';
    static COMPONENT_STUDENT_ENROLLMENTPROGRAMGIFT_DELETE = 'v1/studentenrollmentprogramgift/DeleteStudentProgramGift';
    static COMPONENT_STUDENT_ENROLLMENTPROGRAMGIFT_GET_BY_ID = 'v1/studentenrollmentprogramgift/GetStudentProgramGiftById';
    //Enrollment Eligibility
    static COMPONENT_STUDENT_ENROLLMENTELIGIBILITY_GETALL = 'v1/studentenrollmenteligibility/GetStudentEnrollmentEligibility';
    static COMPONENT_STUDENT_ENROLLMENTELIGIBILITY_SAVE = 'v1/studentenrollmenteligibility/SaveStudentEnrollmentEligibility';
    static COMPONENT_STUDENT_ENROLLMENTELIGIBILITY_UPDATE = 'v1/studentenrollmenteligibility/UpdateStudentEnrollmentEligibility';
    static COMPONENT_STUDENT_ENROLLMENTELIGIBILITY_DELETE = 'v1/studentenrollmenteligibility/DeleteStudentEnrollmentEligibility';
    static COMPONENT_STUDENT_ENROLLMENTELIGIBILITY_GET_BY_ID = 'v1/studentenrollmenteligibility/GetStudentEnrollmentEligibilityById';
    //Referral
    static COMPONENT_STUDENT_REFERRAL_GETALL = 'v1/studentreferral/getstudentreferral';
    static COMPONENT_STUDENT_REFERRAL_SAVE = 'v1/studentreferral/savestudentreferral';
    static COMPONENT_STUDENT_REFERRAL_UPDATE = 'v1/studentreferral/updatestudentreferral';
    static COMPONENT_STUDENT_REFERRAL_DELETE = 'v1/studentreferral/deletestudentreferral';
    static COMPONENT_STUDENT_REFERRAL_GET_BY_ID = 'v1/studentreferral/GetStudentReferralById';
    //Medical Emergency
    static COMPONENT_STUDENT_MEDICALEMERGENCY_SAVE = 'v1/studentmedicalemergency/savestudentmedicalemergency';
    static COMPONENT_STUDENT_MEDICALEMERGENCY_UPDATE = 'v1/studentmedicalemergency/updatestudentmedicalemergency';
    static COMPONENT_STUDENT_MEDICALEMERGENCY_DELETE = 'v1/studentmedicalemergency/deletestudentmedicalemergency';
    static COMPONENT_STUDENT_MEDICALEMERGENCY_GETALL = 'v1/studentmedicalemergency/getallstudentmedicalemergencybyschoolpaginated?schoolId=';
    static COMPONENT_STUDENT_MEDICALEMERGENCY_GET_BY_ID = 'v1/studentmedicalemergency/getstudentmedicalemergencybyid';
    static COMPONENT_STUDENT_MEDICALEMERGENCY_GET_BY_STUDENT_ID = 'v1/studentmedicalemergency/getstudentmedicalemergencybystudentid';
    static COMPONENT_STUDENT_GET_ALL_MEDICAL_EMERGENCY_BY_SCHOOL_ID = 'v1/studentmedicalemergency/getallschoolviststudentmedicalemergencybyschool?schoolId=';
    //Cohort Info
    static COMPONENT_STUDENT_COHORT_SAVE = 'v1/studentcohort/savestudentcohort';
    static COMPONENT_STUDENT_COHORT_UPDATE = 'v1/studentcohort/updatestudentcohort';
    static COMPONENT_STUDENT_COHORT_GET_BY_ID = 'v1/studentcohort/getstudentcohortbyid?Id=';
    static COMPONENT_STUDENT_COHORT_GET_ALL = 'v1/studentcohort/getallstudentcohortbystudentid?';
    static COMPONENT_STUDENT_COHORT_DETAILS = 'v1/studentcohort/getcohortdetailsbystudentid?';
    static COMPONENT_STUDENT_COHORT_DELETE = 'v1/studentcohort/deletestudentcohort';
    //Birth Info
    static COMPONENT_STUDENT_BIRTH_SAVE = 'v1/studentbirth/SaveStudentBirth';
    static COMPONENT_STUDENT_BIRTH_UPDATE = 'v1/studentbirth/UpdateStudentBirth';
    static COMPONENT_STUDENT_BIRTH_GET_BY_ID = 'v1/studentbirth/GetStudentBirthByStudentId';
    //Student course section list and kids list on parent role
    static COMPONENT_STUDENT_GET_COURSE_SECTION_LIST_ON_STUDENT_ROLE = 'v1/studentinfo/getcoursesectionlistonstudentrole';
    static COMPONENT_STUDENT_GET_KIDS_LIST_ON_PARENT_ROLE = 'v1/studentinfo/getkidslistonparentrole';
    //Re Enrollment
    static COMPONENT_STUDENT_RE_ENROLLMENT = 'v1/studentreenrollment/SaveStudentReEnrollment';
    static COMPONENT_STUDENT_DROPOUT_GETALL = 'v1/studentreenrollment/GetDropedOutStudentListofSchool';
    static COMPONENT_STUDENT_REENROLLMENT_PAGINATION = 'v1/studentreenrollment/getdropedoutstudentlistofschoolpaginated?schoolId=';
    static COMPONENT_STUDENT_REENROLLMENT_IDS = 'v1/studentreenrollment/getdropedoutstudentidlistbyschoolsessionid?schoolId=';
    //Mass-assign
    static COMPONENT_STUDENT_MASSASSIGN_SAVE = 'v1/StudentInfo/UpdatesStudentMassAssignmentInfo';
    static COMPONENT_STUDENT_MASS_ASSIGN_GET_BY_PAGINATION = 'v1/studentinfo/GetStudentForMassAssignPaginated?schoolId=';
    //Exit student
    static COMPONENT_STUDENT_EXITSTATUS_GETALL = 'v1/studentexitstatus/getstudentexitstatusbyschool';
    static COMPONENT_STUDENT_EXITSTATUS_GET_BY_ID = 'v1/studentexitstatus/getstudentexitstatusbyid';
    static COMPONENT_STUDENT_EXITSTATUS_UPDATE = 'v1/studentexitstatus/updatestudentexitstatus';
    static COMPONENT_STUDENT_EXITSTATUS_PAGINATED = 'v1/studentexitstatus/getstudentexitstatuspaginated';
    //Search student
    static COMPONENT_SEARCH_STUDENT = 'v1/studentenrollment/getsearchedstudentlist';
    static COMPONENT_SEARCH_STUDENT_ON_EXIT = 'v1/studentexitstatus/getsearchedstudentlistnonexit';
    //Health Info
    static COMPONENT_STUDENT_HEALTH_INFO_SAVE = 'v1/studenthealthinfo/savestudenthealthinfo';
    static COMPONENT_STUDENT_HEALTH_INFO_UPDATE = 'v1/studenthealthinfo/updatestudenthealthinfo';
    static COMPONENT_STUDENT_HEALTH_INSURANCE_DELETE = 'v1/studenthealthinfo/deletestudentinsurance';
    static COMPONENT_STUDENT_HEALTH_INFO_GET_ALL = 'v1/studenthealthinfo/getstudenthealthinfobystudentid';
    static COMPONENT_STUDENT_HEALTH_INSURANCE_GET_BY_ID = 'v1/studentinsurance/getstudentinsurancebyid?id=';
    static COMPONENT_STUDENT_HEALTH_DOCUMENT_DELETE = 'v1/studenthealthinfo/deletestudenthealthdocument';
    static COMPONENT_SAVE_STUDENT_MEDICATION = 'v1/studentmedicationsection/createstudentmedicationsection';
    static COMPONENT_UPDATE_STUDENT_MEDICATION = 'v1/studentmedicationsection/updatestudentmedicationsection';
    static COMPONENT_GET_ALL_MEDICATION_BY_STUDENT_ID = 'v1/studentmedicationsection/getstudentmedicationsectioninfobystudentid?studentId=';
    static COMPONENT_GET_STUDENT_MEDICATION_BY_ID = 'v1/studentmedicationsection/getstudentmedicationsectionbyid?id=';
    static COMPONENT_DELETE_STUDENT_MEDICATION = 'v1/studentmedicationsection/deletestudentmedicationsection';
    static COMPONENT_GET_ALL_INVENTORY_BY_STUDENT_MEDICATION_ID = 'v1/studentmedicationinventoryhistory/getmedicationhistorybymedicationid?studentMedicationId=';
    static COMPONENT_SAVE_STUDENT_INVENTORY = 'v1/studentmedicationinventory/savestudentmedicationinventory';
    static COMPONENT_STUDENT_INVENTORY_HISTORY_DELETE = 'v1/studentmedicationinventory/deletestudentmedicationinventory';
    static COMPONENT_SAVE_STUDENT_SCHEDULE = 'v1/studentmedicationschedule/createstudentmedicationschedule';
    static COMPONENT_UPDATE_STUDENT_SCHEDULE = 'v1/studentmedicationschedule/updatestudentmedicationschedule'
    static COMPONENT_STUDENT_SCHEDULE_HISTORY_DELETE = 'v1/studentmedicationschedule/deletestudentmedicationschedule';
    static COMPONENT_STUDENT_SCHEDULE_HISTORY_EDIT_BY_ID = 'v1/studentmedicationschedule/getstudentmedicationschedulebyid?id=';
    static COMPONENT_GET_ALL_SCHEDULE_BY_STUDENT_MEDICATION_ID = 'v1/studentmedicationschedule/getstudentmedicationscheduleinfo?studentMedicationId=';
    static COMPONENT_GET_STUDENT_MEDICATION_HEALTH_VISIT = 'v1/studentmedication/getstudentmedicationbyid?studentMedicationId=';
    static COMPONENT_SAVE_STUDENT_MEDICATION_HEALTH_VISIT = 'v1/studentmedication/savestudentmedication';
    static COMPONENT_UPDATE_STUDENT_MEDICATION_HEALTH_VISIT = 'v1/studentmedication/updatestudentmedication';
    // health visit
    static COMPONENT_STUDENT_HEALTH_VISIT_GET_ALL = 'v1/studenthealthvisit/getallstudenthealthvisit?schoolId=';
    static COMPONENT_SAVE_STUDENT_HEALTH_VISIT = 'v1/studenthealthvisit/createstudenthealthvisit';
    static COMPONENT_UPDATE_STUDENT_HEALTH_VISIT = 'v1/studenthealthvisit/updatestudenthealthvisit';
    static COMPONENT_STUDENT_HEALTH_VISIT_GET_BY_ID = 'v1/studenthealthvisit/getstudenthealthvisitbyid?Id=';
    static COMPONENT_STUDENT_HEALTH_VISIT_CONTACT_DELETE = 'v1/studenthealthvisitcontactpersons/deletestudenthealthvisitcontactperson';
    static COMPONENT_STUDENT_HEALTH_VISIT_VITAL_DELETE = 'v1/studenthealthvisitvitals/deletestudenthealthvisitvitals';
    static COMPONENT_GETALL_STUDENT_HEALTH_VISIT_CONTACT = 'v1/studenthealthvisitcontactpersons/getallstudenthealthvisitcontactperson?StudentHealthVisitId=';
    static COMPONENT_GETALL_STUDENT_HEALTH_VISIT_VITAL = 'v1/studenthealthvisitvitals/getallstudenthealthvisitvitals?StudentHealthVisitId=';
    static COMPONENT_STUDENT_GETALL_MEDICATION_SECTION_INFO = 'v1/studentmedicationsection/getstudentmedicationsectioninfobystudentid?studentId=';
    static COMPONENT_STUDENT_GETALL_MEDICATION_INFO = 'v1/studentmedicationsection/getstudentmedicationsectioninfobystudentid?studentId=';
    // delete photo
    static COMPONENT_DELETE_STUDENT_PHOTO = 'v1/studentinfo/deletephoto';
    static COMPONENT_DELETE_STUDENT_DOCUMENT = 'v1/studentdocumentverification/deletedocument';
    static COMPONENT_DELETE_STUDENT_DOCUMENT_UPLOAD = 'v1/studentdocumentupload/deletedocument';
    //View Student Course Section
    static COMPONENT_STUDENT_FILTER_DATA_GETALL = 'v1/viewstudentcoursesection/getfiltereddatas';
    static COMPONENT_STUDENT_COURSE_SECTION_DATA_GETALL = 'v1/viewstudentcoursesection/getstudentcoursesectiondatas';
    static COMPONENT_ALLOCATED_STUDENT_DATA_GETALL = 'v1/viewstudentcoursesection/getallocatedstudentdatas?';
    //View Student Promotion
    static COMPONENT_STUDENT_PROMOTION_GET_BY_ID = 'v1/viewstudentpromotion/getstudentpromotionrecords?studentId=';
    static COMPONENT_STUDENT_PROMOTION_PAGINATED = 'v1/viewstudentpromotion/getstudentpromotionbasicinfopaginated';
    //View Student Promotion Indian
    static COMPONENT_STUDENT_IN_PROMOTION_GET_BY_ID = 'v1/in/indianschoolstudentinfo/getindianschoolstudentpromotionrecords?studentId=';
    //Student Overview
    static COMPONENT_STUDENT_OVERVIEW_GET_BY_ID = 'v1/studentdashboarddata/getstudentdashboarddatabystudentid';
    // student document verification
    static COMPONENT_STUDENT_GETALL_BY_GRADE_AND_STATUS = 'v1/studentdocumentverification/getstudentsuploadeddocumentlist';
    static COMPONENT_DOCUMENT_GET_BY_ID = 'v1/studentdocumentverification/getstudentuploadeddocument';
    static COMPONENT_DOCUMENT_VERIFY = 'v1/studentdocumentverification/verifydocument';
    static COMPONENT_DOCUMENT_CERTIFY = 'v1/studentdocumentverification/certifydocument';
    static COMPONENT_STUDENT_SUBMENU_DATA = 'v1/studentinfo/getstudentsectionbyid?studentId=';
    //No Show
    static COMPONENT_STUDENT_NOSHOW_LIST = 'v1/noshowstudent/getnoshowstudentlist';
    static COMPONENT_STUDENT_NOSHOW_PROCESS = 'v1/noshowstudent/processnoshowstudent';
    //Special Program
    static COMPONENT_GET_STUDENT_SPECIAL_PROGRAM_ALL_MENUS = 'v1/specialprogram/getallmenus?studentId=';
    static COMPONENT_STUDENT_SPECIAL_PROGRAM_GETALL = 'v1/specialprogram/getall';
    static COMPONENT_STUDENT_SPECIAL_PROGRAM_SAVE = 'v1/specialprogram/savespecialprogram';
    static COMPONENT_STUDENT_SPECIAL_PROGRAM_UPDATE = 'v1/specialprogram/updatespecialprogram';
    static COMPONENT_STUDENT_SPECIAL_PROGRAM_DELETE = 'v1/specialprogram/deletespecialprogram';
    static COMPONENT_STUDENT_SPECIAL_PROGRAM_GET_BY_ID = 'v1/specialprogram/getbyid';
    static COMPONENT_GET_SPECIAL_PROGRAM_LIST = 'v1/schoolrolepermissionset/getspecialprogramlist?schoolRoleId=';
    // India Endpoints
    // IN - Health Info
    static COMPONENT_STUDENT_IN_HEALTH_INFO_SAVE = 'v1/in/indianschoolstudentinfo/savestudenthealthinfo';
    static COMPONENT_STUDENT_IN_HEALTH_INFO_UPDATE = 'v1/in/indianschoolstudentinfo/updatestudenthealthinfo';
    static COMPONENT_STUDENT_IN_HEALTH_INFO_GET_ALL = 'v1/in/indianschoolstudentinfo/getstudenthealthriskbystudentid';
    // IN - Student Contacts
    static COMPONENT_STUDENT_IN_GET_STUDENT_SIBLING_SEARCH = 'v1/in/indianschoolstudentinfo/getstudentsiblingsearch';
    static COMPONENT_STUDENT_IN_SAVE_ASSOCIATE_PARENT_CONTACT = 'v1/in/indianschoolstudentinfo/saveassociateparentcontact';
    static COMPONENT_IN_STUDENT_GUARDIAN_DELETE = 'v1/in/indianschoolstudentinfo/deleteindianstudentguardian';
    //student graduation
    static COMPONENT_STUDENT_GRADUATION_PLAN_UPDATE = 'v1/studentgraduationplan/updatestudentgraduationplan';
    static COMPONENT_STUDENT_GRADUATION_PLAN_SAVE = 'v1/studentgraduationplan/savestudentgraduationplan';
    static COMPONENT_STUDENT_GRADUATION_PLAN_GET_BY_ID = 'v1/studentgraduationplan/getstudentgraduationplanbyid';
    //student transportation 
    static COMPONENT_TRANSPORT = 'transport';
    static COMPONENT_TRANSPORT_GET_CURRENT_PICKUP_DROP_BY_STUDENTID = 'v1/guardianpickdroprequest/getcurrentpickupdropbystudentid';
    static COMPONENT_TRANSPORT_GET_TRANSPORT_REQUEST_BY_STUDENTID = 'v1/guardianpickdroprequest/gettransportrequestbystudentid';
    static COMPONENT_TRANSPORT_GET_STUDENT_ATTENDANCE_BY_STUDENTID = 'v1/takeattendance/getstudentattendancebystudentid';
    //IN - Student ID Card
    static COMPONENT_GET_STUDENT_ID_CARD = "v1/in/studentidcarddesign/getbyschoolid?schoolId=";
    static COMPONENT_SAVE_STUDENT_ID_CARD = "v1/in/studentidcarddesign/savestudentidcarddesign";
    static COMPONENT_GET_STUDENT_ID_CARD_DOWNLOAD = "v1/in/studentidcardfiles/downloadstudentsidcard";
    static COMPONENT_GET_STUDENT_GENERATED_ID_CARD = 'v1/in/studentidcardfiles/getgoupbystudentidcardbyschoolid?schoolId=';
    //IN - Student List
    static COMPONENT_IN_STUDENT_GET_BY_PAGINATION = 'v1/in/indianschoolstudentinfo/getstudentinfopaginated?schoolId=';
    // Student Contact Sibling
    static COMPONENT_STUDENT_GET_STUDENT_SIBLING_SEARCH = 'v1/studentinfo/getstudentsiblingsearch';
    static COMPONENT_STUDENT_SAVE_ASSOCIATE_SIBLING_CONTACT = 'v1/studentinfo/saveassociateparentcontact';
    static COMPONENT_STUDENT_SIBLING_CONTACT_DELETE = 'v1/studentinfo/deleteindianstudentguardian';
    static COMPONENT_STUDENT_CONTACT_ADDRESS_TYPE = 'v1/studentinfo/getstudentcontactaddresstype';
    //Student Certificate
    static COMPONENT_GET_STUDENT_CERTIFICATE_LIST = 'v1/studentcertificate/getstudentscertificatelists';
    static COMPONENT_DOWNLOAD_STUDENT_CERTIFICATE_LIST = 'v1/studentcertificate/downstudentscertificatelists';
    // overview
    static COMPONENT_STUDENT_OVERVIEW_GET_GRADES_BY_STUDENT_ID = 'v1/assignment/getpublishedassignmentandassessmentbystudentid?studentId=';
    static COMPONENT_STUDENT_OVERVIEW_GET_TODAYS_ASSIGNMENT_BY_STUDENT_ID = 'v1/assignment/gettodaysassignmentandassessmentbystudentid?studentId=';
    static COMPONENT_STUDENT_OVERVIEW_GET_TODAYS_ASSIGNMENT_BY_STUDENT_ID_TWO = 'v1/assignment/gettodaysassignmentandassessmentbystudentid?sessionId=';
    static COMPONENT_GET_STUDENT_ASSIGNMENT_COURSE_SECTION = 'v1/studentassignment/getstudentassignmentcoursesection?studentId=';
    static COMPONENT_STUDENT_OVERVIEW_GET_ASSESSMENT_ASSIGNMENT_BY_STUDENT_ID = 'v1/assignment/getassignmentandassessmentbystudentidcoursesectionid?studentId=';
    static COMPONENT_STUDENT_ASSOCIATED_GRADE_LEVEL_GETALL = 'v1/studentenrollment/getstudentsallassociatedgradelevels?studentIds=';
    //Study Material
    static COMPONENT_DEFAULT_SUBJECTLIST = 'v1/coursesubjectsoffered/getallsubjectsbystudentid?';
    //counselor log
    static COMPONENT_GET_STUDENT_LIST = 'v1/studentcounselorlog/getstudentlistforcounselorlog'
    static COMPONENT_GET_STUDENT_DETAILS_BY_STUDENT_ID = 'v1/studentcounselorlog/getstudentdetailsbysessionandid?sessionId='
    static COMPONENT_GET_STUDENT_ALL_LOGS_BY_SESSION = 'v1/studentcounselorlog/getstudentsalllogsbysession?sessionId='
    static COMPONENT_GET_STUDENT_COUNSELOR_LOG_BY_ID = 'v1/studentcounselorlog/getstudentcounselorlogbyid?id='
    static COMPONENT_GET_ALL_STAFF_LIST_BY_SCHOOLID = 'v1/studentcounselorlog/getallstaffbyschoolid?schoolId='
    static COMPONENT_SAVE_STUDENT_COUNSELOR_LOG = 'v1/studentcounselorlog/savestudentcounselorlog'
    static COMPONENT_UPDATE_STUDENT_COUNSELOR_LOG = 'v1/studentcounselorlog/updatestudentcounselorlog'
    static COMPONENT_DELETE_STUDENT_COUNSELOR_LOG = 'v1/studentcounselorlog/deletestudentcounselorlog'
    static COMPONENT_DOWNLOAD_COUNSELOR_LOG_REPORT = 'v1/studentcounselorlog/downloadstudentscounselorlogreport?sessionId='
    //new student mass assing
    static COMPONENT_ASSIGN_INFO_LIST = 'v1/studentinfo/geteltpuicomponentdetails'
    static COMPONENT_MASS_ASSIGN_PAGINATED_FOR_DIGITAL = 'v1/studentinfo/getstudentformassassignpaginatedfordigital?sessionId='
    static COMPONENT_STUDENT_FIELD_DIGITAL_LIST = 'v1/studentinfo/getdynamicuicomponentdetails'
    static COMPONENT_ECONOMIC_DISADVANTAGE_DROPDOWN = 'v1/lookup/getbylookupstatespecific'
    static COMPONENT_ACTIVE_STUDENT_LIST = 'v1/studentinfo/getactivestudentformassassignpaginated?schoolId='
    static COMPONENT_UPDATE_STUDENT_MASS_INFO_SYNC = 'v1/studentinfo/updatestudentmassassigninfoasync'
    static COMPONENT_UPDATE_STUDENT_ECONOMIC_DISADVANTAGE = 'v1/studentinfo/updateeconomicdisadvantageenddate?endDate='
    static COMPONENT_LOOKUP_DISADVANTAGE_DROPDOWN = 'v1/lookup/getbylookupstatespecific'
    static COMPONENT_SAVE_STUDENT_ECONOMIC_DISADVANTAGE = 'v1/studentinfo/saveeconomicdisadvantage'
    static COMPONENT_STUDENT_GET_ALL_TWO = 'v1/studentinfo/getstudentinfopaginated'
    static COMPONENT_STUDENT_GET_ALL_ECONOMIC_DISADVANTAGE = 'v1/studentinfo/getstudentsforeconomicdisadvantage?sessionId='
    static COMPONENT_STUDENT_GET_ALL_END_ECONOMIC_DISADVANTAGE = 'v1/studentinfo/getstudentsforendeconomicdisadvantage?sessionId='
    static COMPONENT_GET_ELTP_STUDENT_LIST = 'v1/studentinfo/getstudentinfoforeltppaginated'
    static COMPONENT_STUDENT_GET_ALL_ECONOMIC_DISADVANTAGE_STUDENT_LIST = 'v1/studentinfo/getstudentsforeconomicdisadvantage?sessionId='
    static COMPONENT_STUDENT_GET_ALL_END_ECONOMIC_DISADVANTAGE_STUDENT_LIST = 'v1/studentinfo/getstudentsforendeconomicdisadvantage?sessionId='
    //static COMPONENT_STUDENT
    //Attendance log
    static COMPONENT_GET_ALL_STAFF_LIST = 'v1/studentcounselorlog/getallstaffbyschoolid?schoolId=';
    static COMPONENT_STUDENT_GET_STUDENTIDS_BY_SCHOOL_SESSION = 'v1/studentinfo/getstudentidsbyschoolsession';
    static COMPONENT_STUDENT_GET_STUDENTIDS_BY_SCHOOL_SESSION = 'v1/studentinfo/getallstudents';
}
export default commonStudentConfig;
