export class commonMessageConfig {
    static COMPONENT_MESSAGE = 'message';
    static COMPONENT_SCHOOL = 'school';
    static COMPONENT_COURSE = 'course';
    static COMPONENT_SCHEDULE = 'schedule';
    static COMPONENT_STAFF = 'staff';
    static COMPONENT_STUDENT = 'student';
    static COMPONENT_SYSTEM = 'system';
    static COMPONENT_RESOURCE = 'resource';
    static METHOD_TYPE_POST = 'POST';
    static METHOD_TYPE_GET = 'GET';
    static COMPONENT_GET_MESSAGE_SENT_LIST = 'v1/adminsendemail/getsentemails';
    static COMPONENT_GET_MESSAGE_BY_ID = 'v1/adminsendemail/getemailbyid?';
    static COMPONENT_SEND_EMAIL = 'v1/adminsendemail/sendemail';
    static COMPONENT_SEND_EMAIL_ROLE = 'v1/adminsendemail/sendemailrolewise';
    static COMPONENT_SCHOOL_SENT_MESSAGES_GETALL = 'v1/communication/getallsentmessages';
    static COMPONENT_SCHOOL_SENT_MESSAGES_GET_BY_ID = 'v1/communication/getsentmessagebyid';
    static COMPONENT_MESSAGE_SEND_MESSAGE = 'v1/communication/sendmessage';
    static COMPONENT_MESSAGE_REPLY_MESSAGE = 'v1/communication/replymessage';
    static COMPONENT_COMMUNICATION_USER_GETALL = 'v1/communication/getallstudentstaffparentlistforsms';
    static COMPONENT_COMMUNICATION_USER_GETALL_FOR_INDIVIDUAL = 'v1/communication/getallstudentstaffparentlistindividualforsms';
    static COMPONENT_SEND_TEXT_MESSAGE = 'v1/communication/sendsmstextmessage';
    static COMPONENT_SEND_TEXT_MESSAGE_GETALL = 'v1/communication/getallmessagesbyroleid';
    static COMPONENT_SEND_TEXT_MESSAGE_GET_BY_ID = 'v1/communication/getsmstextmessagebyid?id=';
    //received messages
    static COMPONENT_RECEIVED_MESSAGES_GETALL = 'v1/communication/GetAllReceiveMessages';
    static COMPONENT_DELETE_SENT_MESSAGE = 'v1/communication/deletesentmessage';
}
export default commonMessageConfig;
