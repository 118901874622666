import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';
const initialState = {
  schoolSubGroupList: [],
  schoolStandardScaleList: [],
  schoolImportedStandardsDataList: [],
  getSchoolStandardsChange: {},
  schoolStandardsDataList: [],
  schoolStandardSetList: [],
  getSchoolStandardSetChange: {},
  saveSchoolStandardSet: {},
  schoolStandardSetDataList: [],
  schoolList: null,
  getSchoolInfoDetails: {},
  schoolDropdownList: [],
  schoolPhoneList: [],
  schoolEmailList: [],
  getSchoolPhone: [],
  getSchoolEmail: [],
  saveSchoolPhone: 0,
  saveSchoolEmail: 0,
  saveSchool: 0,
  getSchoolAddress: [],
  getSchoolInfo: [],
  getSchoolGeneralInfo: [],
  getSchoolInformation: [],
  schoolIdentifierList: [],
  getSchoolDesignator: [],
  getSchoolAdmin: [],
  saveSchoolAdmin: 0,
  schoolAddressList: [],
  saveSchoolAddress: 0,
  schoolRoleList: [],
  getSchoolIdentifier: {},
  saveSchoolIdentifier: 0,
  saveSchoolRole: 0,
  getSchoolRole: {},
  schoolSessionList: [],
  schoolSessionViewList: [],
  schoolPeriodList: [],
  getSchoolPeriod: {},
  saveSchoolPeriod: 0,
  saveSchoolAccredation: 0,
  schoolAccredationList: [],
  getSchoolAccredation: {},
  schoolDesignatorList: [],
  schoolGradeList: [],
  schoolSectionList: [],
  schoolSectionByGradeLevelIdList: [],
  saveSchoolGrade: 0,
  getSchoolGrade: {},
  saveSchoolSection: 0,
  schoolRoomList: [],
  schoolVirtualRoomList: [],
  saveSchoolRoom: 0,
  getSchoolRoom: {},
  schoolPremisesList: [],
  getSchoolSection: {},
  getSchoolSectionInfo: {},
  getSchoolSession: {},
  getSchoolSessionInfo: {},
  saveSchoolSession: 0,
  schoolDepartmentList: [],
  saveSchoolDepartment: 0,
  getSchoolDepartment: {},
  schoolMarkingPeriodList: [],
  getSchoolMarkingPeriod: {},
  saveSchoolMarkingPeriod: 0,
  schoolStudentStaffCountList: [],
  isLoading: false,
  schoolFieldList: [],
  saveSchoolField: 0,
  saveSchoolPerson: 0,
  schoolFieldCategoryList: [],
  saveSchoolFieldGroup: 0,
  getSchoolFieldGroup: {},
  schoolHolidayList: [],
  saveSchoolHoliday: 0,
  getSchoolHoliday: {},
  getSchoolCalendar: {},
  getSchoolDefaultCalendar: {},
  schoolCalendarList: [],
  saveSchoolCalendarCrisis: 0,
  getSchoolCalendarCrisis: {},
  getSchoolAdministrationInfo: {},
  saveSchoolDesignator: 0,
  schoolActivityGroupList: [],
  schoolCourseList: [],
  saveSchoolActivityGroup: 0,
  getSchoolActivityGroup: {},
  saveSchoolActivity: 0,
  getSchoolActivity: {},
  getSchoolGradeScaleDetails: {},
  schoolGradeScaleLetterList: [],
  saveSchoolGradeScaleCategory: {},
  saveSchoolGradeLetter: {},
  getSchoolGradeLetterInfo: {},
  saveSchoolGradeScaleCopy: {},
  schoolGradeLevelList: [],
  schoolPrevGradeLevelList: [],
  schoolRolePermissionList: [],
  saveSchoolCalendar: 0,
  schoolCalendarEventList: [],
  saveSchoolCalendarEvent: {},
  deleteSchoolCalendar: false,
  deleteSchoolCalendarEvent: {},
  schoolCalendarHolidayList: [],
  getSchoolCalendarEvent: {},
  schoolPersonList: [],
  schoolAllPersonList: [],
  schoolRoomByPremiseIdList: [],
  schoolCalenderEventTypeList: [],
  schoolAnnouncementList: [],
  saveSchoolAnnouncement: false,
  getSchoolAnnouncement: {},
  schoolAnnouncementByRoleIdList: [],
  schoolRoleBasedPermissionList: [],
  schoolStudentStaffParentList: [],
  schoolAllStudentStaffParentList: [],
  schoolBasedRoleList: [],
  getSchoolHolidayInfo: {},
  saveSchoolHolidayStatus: false,
  getSchoolHolidayIndividual: {},
  saveSchoolHolidayVacation: false,
  getSchoolDashBoardInfo: [],
  schoolCurrentMonthEventsList: [],
  schoolDateEventsCrisisList: [],
  schoolStaffRoleList: [],
  schoolLanguageList: [],
  saveSchoolLanguage: false,
  getSystemDateFormat: {},
  saveSchoolLunchProgram: false,
  getSchoolLunchProgram: {},
  schoolTimeZoneList: [],
  schoolHigherGradeLevelList: [],
  schoolTimeZoneNameList: null,
  schoolMarkingPeriodGradeBookList: [],
  saveSchoolGradeScaleLevel: 0,
  schoolGradeScaleLevelList: [],
  getSchoolGradeScaleLevel: {},
  saveSchoolDefineGpa: 0,
  schoolDefineGpaList: [],
  getSchoolDefineGpa: {},
  schoolAllGradeScaleLevelList: [],
  getSchoolProgressMarkingPeriod: {},
  schoolGradedMarkingPeriodList: [],
  schoolAllMarkingPeriodsList: [],
  schoolMarkingPeriodsList: [],
  getCourseList: [],
  //roleover
  schoolSessionBySchoolYearList: [],
  schoolCalendarBySessionList: [],
  schoolMarkingPeriodBySessionList: [],
  schoolCourseManagerList: [],
  schoolCourseManagerGradeList: [],
  schoolCourseManagerByFilterList: [],
  saveSchoolRollOver: false,
  getSchoolRolloverDetails: {},
  saveSchoolBlockDefination: false,
  getSchoolBlockDefination: {},
  schoolBlockDefinationList: [],
  schoolBlockDatesList: [],
  saveSchoolBlockDates: false,
  schoolBlockDaysList: [],
  schoolPeriodByAllowAttendaceList: [],
  saveSchoolValidateBlockDates: false,
  saveSchoolCohort: false,
  schoolCohortList: [],
  getSchoolCohort: {},
  schoolPeriodBlockMappingList: [],
  saveSchoolPeriodBlockMapping: false,
  getSchoolCountryCode: null,
  getSchoolStateCode: null,
  saveSchoolScheduleType: {},
  getSchoolScheduleType: {},
  schoolNonEditableRolePermissionList: [],
  getSchoolBlockDates: {},
  saveSchoolComment: 0,
  schoolCommentList: [],
  getSchoolComment: {},
  schoolStandardGroupList: [],
  saveSchoolStandardGroup: {},
  getSchoolStandardGroup: {},
  schoolChildMarkingPeriodList: [],
  schoolScheduleMarkingPeriodList: [],
  saveSchoolStudentUserName: false,
  getSchoolStudentUserName: {},
  getSchoolYearStartRange: [],
  getSchoolYearEndRange: [],
  schoolRolewisePersonList: [],
  schoolPersonIncludeList:[],
  getSchoolTeacherAttendance:{},
  schoolCourseFromList:[],
  saveSchoolGradePlan:{},
  getSchoolDefaultPlan:{},
  deleteSchoolGradePlan:{},
  saveSchoolGraduationCategory:[],
  schoolGraduationCategoriesList: [],
  getSchoolGraduationCategory:{},
  schoolCategoriesWithGradPlanAssocValuesList: [],
  saveSchoolGraduationAssociationCategory:[],
  saveSchoolGraduationCategoryAssoc:{},
  getSchoolRequirement:{},
  schoolRequirementList:[],
  getSchoolCheckIsDefaultSession: [],
  schoolGetAttendanceCategoryList: []
};
const handleFail = (state, action) => {
  return updateObject(state, { isLoading: false });
};
const handleSuccess = (state, action, storeStateName) => {
  return updateObject(state, {
    loading: false,
    [storeStateName]: action.responseData.data,
  });
};
const reducerSchool = (state = initialState, action) => {
  state = state || initialState;
  switch (action.type) {
    case 'SYSTEM_GET':
      return handleSuccess(state, action, camelCase(`get_system_${action.title}`));
    case 'SCHOOL_LIST':
      return handleSuccess(state, action, camelCase(`school_${action.title}_list`));
    case 'SCHOOL_SAVE':
      return handleSuccess(state, action, camelCase(`save_school_${action.title}`));
    case 'SCHOOL_GET':
      return handleSuccess(state, action, camelCase(`get_school_${action.title}`));
    case 'SCHOOL_DELETE':
      return handleSuccess(state, action, camelCase(`delete_school_${action.title}`));
    case commonConfig.SCHOOL_STORE_RESET:
      return handleSuccess(state, action, action.title);
    case 'HANDLE_SCHOOL_FAIL':
      return handleFail(state, action);
    default:
      return state;
  }
};
export default reducerSchool;
