export class commonDistrictConfig {
  static COMPONENT_CONTROL_PANEL = 'controlpanel';
  static METHOD_TYPE_POST = 'POST';
  static METHOD_TYPE_GET = 'GET';
  //Student Staff Code
  static COMPONENT_GET_STUDENT_STAFF_CODE = 'v1/studentstaffcode/getstudentstaffcodeinfo?';
  static COMPONENT_SAVE_STUDENT_STAFF_CODE = 'v1/studentstaffcode/savestudentstaffcode';
  //student identifier
  static COMPONENT_STUDENT_IDENTIFIER_SAVE = 'v1/studentstaffidentfier/savestudentstaffidentfier';
  static COMPONENT_STUDENT_IDENTIFIER_UPDATE = 'v1/studentstaffidentfier/updatestudentstaffidentfier';
  static COMPONENT_DELETE_STUDENT_IDENTIFIER = 'v1/studentstaffidentfier/deletestudentstaffidentfier';
  static COMPONENT_GET_IDENTIFIER_LIST = 'v1/studentstaffidentfier/getallidentifierbydistrictid';
  static COMPONENT_IDENTIFIER_BY_IDENTIFIER_ID = 'v1/studentstaffidentfier/getidentifierbyid?';
  static COMPONENT_STUDENT_GET_VALIDATION_FOR_STUDENT_DELETE = 'v1/studentstaffidentfier/getvalidationforstudentandstaffdatadelete?IdentityType=student&LookUpFieldType=';
  static COMPONENT_STUDENT_GET_VALIDATION_FOR_STAFF_DELETE = 'v1/studentstaffidentfier/getvalidationforstudentandstaffdatadelete?IdentityType=staff&LookUpFieldType=';
  //student default country state language
  static COMPONENT_GET_DEFAULT_COUNTRY_STATE_LANGUAGE = 'v1/countrystatelanguage/getdefaultcountrystatelanguage';
  static COMPONENT_DEFAULT_COUNTRY_STATE_LANGUAGE_SAVE = 'v1/countrystatelanguage/savecountrystatelanguage';
  static COMPONENT_DEFAULT_COUNTRY_STATE_LANGUAGE_UPDATE = 'v1/countrystatelanguage/updatecountrystatelanguage';
  static COMPONENT_RESOURCE_GET_CURRENCY_BY_COUNTRY = 'v1/countrystatelanguage/getcurrencydetailsbycountrycode?countryCode=';
  static COMPONENT_RESOURCE_GET_CURRENCY_DETAILS_BY_COUNTRY = 'v1/countrystatelanguage/getcurrencycodebycountry?countryCode=';
  static COMPONENT_DEFAULT_GET_MOODLE = 'v1/lmssetup/getlmsmoodlesetupconfiguration?';
  static COMPONENT_DEFAULT_GET_CANVAS = 'v1/lmssetup/getlmscanvassetupconfiguration?';
  static COMPONENT_DEFAULT_GET_GCR = 'v1/lmssetup/getlmsgcrsetupconfiguration?';
  static COMPONENT_MOODLE_SAVE = 'v1/lmssetup/savelmsmoodlesetupconfiguration';
  static COMPONENT_CANVAS_SAVE = 'v1/lmssetup/savelmscanvassetupconfiguration';
  static COMPONENT_GCR_SAVE = 'v1/lmssetup/savelmsgcrsetupconfiguration';
  static COMPONENT_MOODLE_UPDATE = 'v1/lmssetup/savelmsmoodlesetupconfiguration';
  static COMPONENT_LMS_TEST_CONNECTION = 'v1/lmscore/testconnection';
  //District AllowAdminEditPreviousSessionComponent
  static COMPONENT_DISTRICT_GET_DISTRICT_SCHOOL_PREVIOUS_SESSION_ACESSS = 'v1/allowadmintoeditprevioussessiondata/getbydistrictid?';
  static COMPONENT_DISTRICT_SCHOOL_PREVIOUS_SESSION_ACESSS_SAVE = 'v1/allowadmintoeditprevioussessiondata/save';
  static COMPONENT_DISTRICT_SCHOOL_PREVIOUS_SESSION_ACESSS_UPDATE = 'v1/allowadmintoeditprevioussessiondata/update';
  //District Programs
  static COMPONENT_DISTRICT_PROGRAM_PLAN_SAVE = 'v1/districtprograms/savedistrictprograms';
  static COMPONENT_GET_DISTRICT_PROGRAM = 'v1/districtprograms/getall?';
  static COMPONENT_DELETE_DISTRICT_PROGRAM = 'v1/districtprograms/deletedistrictprograms';
  static COMPONENT_DISTRICT_PROGRAM_PLAN_UPDATE = 'v1/districtprograms/updatedistrictprograms';
  static COMPONENT_GET_DISTRICT_PROGRAMBY_ID = 'v1/districtprograms/getdistrictprogrambyid?Id=';
  //District AllowMultipleSchedule
  static COMPONENT_DISTRICT_GET_MULTIPLE_SCHEDULE = 'v1/roommultipleschedule/getbydistrictid?';
  static COMPONENT_DISTRICT_MULTIPLE_SCHEDULE_SAVE = 'v1/roommultipleschedule/save';
  static COMPONENT_DISTRICT_MULTIPLE_SCHEDULE_UPDATE = 'v1/roommultipleschedule/update';
  //District allow edit attendance
  static COMPONENT_DISTRICT_INSTRUCTIONAL_DAYS_SAVE = 'v1/instructionaldayscount/save';
  static COMPONENT_GET_DISTRICT_INSTRUCTIONAL_DAYS = 'v1/instructionaldayscount/getbydistrictid';
  static COMPONENT_DISTRICT_INSTRUCTIONAL_DAYS_UPDATE = 'v1/instructionaldayscount/update';
  // Service IDs
  static COMPONENT_DISTRICT_SERVICE_IDS_SAVE = 'v1/serviceidentifier/save';
  static COMPONENT_DISTRICT_SERVICE_IDS_UPDATE = 'v1/serviceidentifier/update';
  static COMPONENT_GET_DISTRICT_SERVICE_IDS_BY_ID = 'v1/serviceidentifier/getbyid';
  static COMPONENT_GET_ALL_DISTRICT_SERVICE_IDS = 'v1/serviceidentifier/getall';
  static COMPONENT_DELETE_DISTRICT_SERVICE_IDS = 'v1/serviceidentifier/delete';
  // Student Name Display Pattern
  static COMPONENT_GET_STUDENT_NAME_DISPLAY_PATTERN_BYID = 'v1/studentdisplaysortorder/getbyid?DistrictId=';
  static COMPONENT_STUDENT_NAME_DISPLAY_PATTERN_SAVE = 'v1/studentdisplaysortorder/savestudentdisplaysortorder';
  static COMPONENT_STUDENT_NAME_DISPLAY_PATTERN_UPDATE = 'v1/studentdisplaysortorder/updatestudentdisplaysortorder';
  // Student Name Display Pattern
  static COMPONENT_GET_STAFF_NAME_DISPLAY_PATTERN_BYID = 'v1/staffnamepattern/getstaffnamepatternbyid?DistrictId=';
  static COMPONENT_STAFF_NAME_DISPLAY_PATTERN_SAVE = 'v1/staffnamepattern/savestaffnamepattern';
  static COMPONENT_STAFF_NAME_DISPLAY_PATTERN_UPDATE = 'v1/staffnamepattern/updatestaffnamepattern';
  //District Add
  static COMPONENT_DISTRICT_ADD_GENERAL_INFO = 'v1/districtgeneralinfo/savedistrictgeneralinfo';
  static COMPONENT_DISTRICT_UPDATE_GENERAL_INFO = 'v1/districtgeneralinfo/updatedistrictgeneralinfo';
  static COMPONENT_DISTRICT_GET_GENERAL_INFO = 'v1/districtgeneralinfo/getbydistrictid?Id=';
  static COMPONENT_DISTRICT_ADD_ADDRESS_INFO = 'v1/districtgeneralinfo/savedistrictaddress';
  static COMPONENT_DISTRICT_UPDATE_ADDRESS_INFO = 'v1/districtgeneralinfo/updatedistrictaddress';
  static COMPONENT_DISTRICT_GET_ADDRESS_INFO = 'v1/districtgeneralinfo/getdistrictaddresses?districtId=';
  static COMPONENT_DISTRICT_DELETE_ADDRESS_INFO = 'v1/districtgeneralinfo/deletedistrictaddress';
  static COMPONENT_DISTRICT_GET_ALL_IDENTIFIER = 'v1/districtidentifier/getdistrictidentifierbydistrictid?DistrictId=';
  static COMPONENT_DISTRICT_GET_IDENTIFIER_BY_ID = 'v1/districtidentifier/getdistrictidentifierbyid?Id=';
  static COMPONENT_DISTRICT_UPDATE_IDENTIFIER = 'v1/districtidentifier/updatedistrictidentifier';
  static COMPONENT_DISTRICT_SAVE_IDENTIFIER = 'v1/districtidentifier/savedistrictidentifier';
  static COMPONENT_DISTRICT_DELETE_IDENTIFIER = 'v1/districtidentifier/deletedistrictidentifier';
  static COMPONENT_DISTRICT_DELETE_LOGO = 'v1/districtgeneralinfo/deletefile';
  static COMPONENT_DISTRICT_GET_ALL_PHONE = 'v1/districtemailphone/getdistrictphonebydistrictid?DistrictId=';
  static COMPONENT_DISTRICT_GET_PHONE_BY_ID = 'v1/districtemailphone/getdistrictphonebyid?Id=';
  static COMPONENT_DISTRICT_UPDATE_PHONE = 'v1/districtemailphone/updatedistrictphone';
  static COMPONENT_DISTRICT_SAVE_PHONE = 'v1/districtemailphone/savedistrictphone';
  static COMPONENT_DISTRICT_DELETE_PHONE = 'v1/districtemailphone/deletedistrictphone';
  static COMPONENT_DISTRICT_GET_ALL_EMAIL = 'v1/districtemailphone/getdistrictemailbydistrictid?DistrictId=';
  static COMPONENT_DISTRICT_GET_EMAIL_BY_ID = 'v1/districtemailphone/getdistrictemailbyid?Id=';
  static COMPONENT_DISTRICT_UPDATE_EMAIL = 'v1/districtemailphone/updatedistrictemail';
  static COMPONENT_DISTRICT_SAVE_EMAIL = 'v1/districtemailphone/savedistrictemail';
  static COMPONENT_DISTRICT_DELETE_EMAIL = 'v1/districtemailphone/deletedistrictemail';
  // Graduation Plan
  static COMPONENT_GET_ALL_DISTRICT_GRADUATION_PLAN = 'v1/districtgraduationplan/getalldistrictgraduationplan?';
  static COMPONENT_DISTRICT_GRADUATION_PLAN_SAVE = 'v1/districtgraduationplan/savedistrictgraduationplan';
  static COMPONENT_DISTRICT_GRADUATION_PLAN_UPDATE = 'v1/districtgraduationplan/updatedistrictgraduationplan';
  static COMPONENT_DISTRICT_GRADUATION_PLAN_GET_BY_ID = 'v1/districtgraduationplan/getdistrictgraduationplanbyid?Id=';
  static COMPONENT_DISTRICT_GRADUATION_PLAN_DELETE = 'v1/districtgraduationplan/deletedistrictgraduationplan';
  //Course Explanation
  static COMPONENT_COURSE_EXPLANATION_SAVE = 'v1/courseexplanationdetail/save';
    static COMPONENT_GET_ALL_COURSE_EXPLANATION = 'v1/courseexplanationdetail/getbydistrictid?districtId=';
    static COMPONENT_GET_ALL_COURSE_EXPLANATION_NEW = 'v1/courseexplanationdetail/getcetitlebydistrictid?districtId=';

  static COMPONENT_GET_ALL_COURSE_EXPLANATION_BY_DISTRICT_ID = 'v1/courseexplanationdetail/GetByCourseExplanationDistrict?districtId=';
  static COMPONENT_COURSE_EXPLANATION_DELETE = 'v1/courseexplanationdetail/deletecourseexlanation';
  static COMPONENT_COURSE_EXPLANATION_GET_BYID = 'v1/courseexplanationdetail/getbyid?id=';
  static COMPONENT_COURSE_EXPLANATION_UPDATE = 'v1/courseexplanationdetail/update';
  //TranscriptSeal
  static COMPONENT_TRANSCRIPT_SEAL_SAVE = 'v1/transcriptseal/savetranscriptseal';
  static COMPONENT_TRANSCRIPT_SEAL_UPDATE = 'v1/transcriptseal/updatetranscriptseal';
  static COMPONENT_TRANSCRIPT_SEAL_GET_BYID = 'v1/transcriptseal/getbyid?districtId=';
  // Upload Maximum File Size
  static COMPONENT_UPLOAD_FILE_SIZE_GET_BYID = 'v1/uploadfilesize/getbydistrictid?DistrictId=';
  static COMPONENT_UPLOAD_FILE_SIZE_SAVE = 'v1/uploadfilesize/saveuploadfilesize';
  static COMPONENT_UPLOAD_FILE_SIZE_UPDATE = 'v1/uploadfilesize/updateuploadfilesize';
  static COMPONENT_COMMON_UPLOAD_FILE_SIZE_GET_ALL = 'v1/uploadfilesize/getfilesizesetupbydistrictid?DistrictId=';
  //district system values
  static COMPONENT_DISTRICT_GET_SYSTEM_VALUES = 'v1/systemlookupvalues/getbydistrictid?districtId=';
  static COMPONENT_DISTRICT_SYSTEM_VALUES_SAVE = 'v1/systemlookupvalues/savedistrictsystemlookupvalues';
  static COMPONENT_DISTRICT_SYSTEM_VALUES_UPDATE = 'v1/systemlookupvalues/updatedistrictsystemlookupvalues';
  static COMPONENT_DISTRICT_TEST_FORMAT_LIST = 'v1/testformat/getalltestformat?SchoolId=';
  static COMPONENT_DISTRICT_GET_TEST_FORMAT = 'v1/testformat/gettestformatbyid?SchoolId=';
  static COMPONENT_DISTRICT_GET_TEST_FORMAT_TYPES = 'v1/testformat/gettesttypebystatecode?CountryCode=';
  static COMPONENT_DISTRICT_GET_SUBJECT_FIELDS = 'v1/testformat/getsubjectfieldbytypeid?Type=';
  static COMPONENT_DISTRICT_SAVE_TEST_FORMAT = 'v1/testformat/savetestformat';
  static COMPONENT_DISTRICT_UPDATE_TEST_FORMAT = 'v1/testformat/updatetestformat';
  static COMPONENT_DISTRICT_DELETE_TEST_FORMAT = 'v1/testformat/deletetestformatbyid';
  static COMPONENT_DISTRICT_DELETE_INDIVIDUAL_TEST_FORMAT = 'v1/testformat/deletetestsubjectfieldid';
  //gradualtion plan
  static COMPONENT_GET_GRADUATION_PLAN_BY_SCHOOL_ID = 'v1/graduationcoursecategoryassoc/getgraduationplanbyschoolid?SchoolId=';
  static COMPONENT_GET_SUBJECT_LIST_BY_SCHOOL_ID = 'v1/graduationcoursecategoryassoc/getsubjectlistbyschoolid?SchoolId=';
  static COMPONENT_GET_CATEGORY_LIST_BY_GRADPLAN_ID = 'v1/graduationcoursecategoryassoc/getcategorylistbygradplanid?GradPlanId=';
  static COMPONENT_GET_ASSOC_COURSE_CATEGORY_lIST = 'v1/graduationcoursecategoryassoc/getassoccoursecategorylist?GradPlanId='
  static COMPONENT_ASSOC_SAVE_COURSE_CATEGORY = 'v1/graduationcoursecategoryassoc/savesinglecoursecategoryassoc'
  static COMPONENT_ASSOC_SAVE_BULK_COURSE_CATEGORY = 'v1/graduationcoursecategoryassoc/savebulkcoursecategoryassoc?CourseIds='
  static COMPONENT_DELETE_ASSOC_CATEGORY = 'v1/graduationcoursecategoryassoc/deletecoursecategoryassoc'
  static COMPONENT_SAVE_COPY_GRAD_PLAN = 'v1/graduationplan/copygraduationplan';
  //report
  static COMPONENT_DOWNLOAD_DISTRICT_ATTN_PERCENTAGE_RPT = "v1/attendancepercentagereport/downloaddistrictattendancepercentagereport";
}
export default commonDistrictConfig;
