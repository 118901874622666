import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { MarkersMultiLingual } from './markersMultiLingualComponent';
import ConvertDate from './markersConvertDateComponent';
import sessionStore from './sessionStoreComponent';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-fixedheader-bs4';
//import 'datatables.net-responsive-bs4';
import 'datatables.net-scroller-bs4';
import 'datatables.net-colreorder';
import 'jszip';
import Moment from 'react-moment';
import i18n from '../../../i18n';
import CommonFuncs from './markersCommonFunctionComponent';
const langObj = new MarkersMultiLingual();
const moment = require('moment');
const momentTimeZone = require('moment-timezone');
const commonFuncObj = new CommonFuncs();
var paginateFlag = 0;
var searchFlag = 0;
var pageCount = 1;
var searchValue = '';
var searchValue1 = '';
var searchBtn = 0;
function searchData(classObj, currentPage, value, sortColumn = '', sortOrder = '', defaultSearchFlag = null, resetDefaultOrder = true) {
    let searchField = '';
    let tempSortOrder = sortOrder;
    if (resetDefaultOrder === true)
        tempSortOrder = 'asc';
    classObj['paginate'](currentPage, value, searchField, sortColumn, tempSortOrder, defaultSearchFlag, false);
}
class extendedDataTable extends Component {
    state = {
        table: null,
        flag: 0
    }
    selectAll(event, classObj, clearUnchecked = true, tableClass = 'markers-datatable', checkUncheckFlag = false, unSelectedRows = []) {
        if (!!unSelectedRows && unSelectedRows.length > 0) {
            let notElement = [];
            unSelectedRows.forEach((data, key) => {
                notElement.push('input[type="checkbox"][value="' + data + '"]');
            })
            $('.' + tableClass + ' input[type="checkbox"]').not(notElement.toString()).prop('checked', !!event ? event.target.checked : checkUncheckFlag);
        } else {
            if (!!clearUnchecked && !!classObj['clearUncheckedList']) {
                classObj['clearUncheckedList'](!!event ? event.target.checked : '');
            }
            $('.' + tableClass + ' input[type="checkbox"]').prop('checked', !!event ? event.target.checked : checkUncheckFlag);
        }
    }
    // deSelectAll(event) {
    //     var rows = this.state.table.rows({ 'search': 'applied' }).nodes();
    //     $('input[type="checkbox"]', rows).prop('checked', false);   
    // }
    selectSpecificRows(tableClass = 'markers-datatable', selectedRows = [], unSelectedRows = []) {
        let element = [];
        !!selectedRows && selectedRows.length > 0 && selectedRows.forEach((data, key) => {
            element.push('input[type="checkbox"][value="' + data + '"]');
        })
        if (!!unSelectedRows && unSelectedRows.length > 0) {
            let notElement = [];
            unSelectedRows.forEach((data, key) => {
                notElement.push('input[type="checkbox"][value="' + data + '"]');
            })
            $('.' + tableClass + ' ' + element).not(notElement.toString()).prop('checked', true);
        } else {
            $('.' + tableClass + ' ' + element).prop('checked', true);
        }
    }
    rowClick = () => {
        if ($('.checkBoxClass:checked').length === $('.checkBoxClass').length) {
            $('#select-all').prop('checked', true)
        }
        if ($('.checkBoxClass:checked').length !== $('.checkBoxClass').length) {
            $('#select-all').prop('checked', false)
        }
    }
    getCheckedValue = () => {
        let values = [];
        $('input[type="checkbox"]').each(function () {
            if (this.checked) {
                values.push(this.value)
            }
        })
        return values;
    }
    setCheckedValue = (chackedValuesArray) => {
        chackedValuesArray.map((value) => {
            $('input[type="checkbox"][value="' + value + '"]').attr('checked', true);
        })
    }
    getRadioValue = () => {
        let value = $("input[name='radio']:checked").val();
        return value;
    }
    //Destroy DataTable...
    returnDestroyTable() {
        $('.markers-datatable').DataTable().destroy();
    }
    createArray(i) {
        return i ? this.createArray(i - 1).concat(i) : []
    }
    //Reset DataTable...
    returnResetTable(groupIndex = null, exportVisibility = false, classObj = {}, totalCount = 0, currentPage = 1, headerButtonFlag = 1, isSearch = 1, isExport = true, tableColumns = [], sortColumn = '', sortOrder = '') {
        const exportFileName = !!classObj && !!classObj.exportFileName ? classObj.exportFileName : document.title
        $(document).ready(function () {
            $('#btn1').click(function () {
                searchValue = $('#searchField').val();
                let defaultSearchFlag = 1;
                searchData(classObj, 1, $('#searchField').val(), sortColumn, sortOrder, defaultSearchFlag);
            });
            $('#btn2').click(function () {
                searchValue1 = $('#searchField1').val();
                searchData(classObj, 1, $('#searchField1').val(), sortColumn, sortOrder);
            });
            $(".sorting").click(function () {
                var colIdx = $('.markers-datatable').DataTable().order();
                if (colIdx.length != 0 && !!sortOrder) {
                    let tempOrderClass = '';
                    let removeClassName = 'ASC';
                    let fieldClass = $('#' + tableColumns[parseInt(colIdx[0][0])].name);
                    if (fieldClass && fieldClass.attr('class') && fieldClass.attr('class').includes("ASC") === true)
                        tempOrderClass = 'DESC';
                    else if (fieldClass && fieldClass.attr('class') && fieldClass.attr('class').includes("DESC") === true)
                        tempOrderClass = 'ASC';
                    else
                        tempOrderClass = 'DESC';
                    if (tempOrderClass === 'ASC')
                        removeClassName = 'DESC';
                    $('#' + tableColumns[parseInt(colIdx[0][0])].name).removeClass(removeClassName);
                    $('#' + tableColumns[parseInt(colIdx[0][0])].name).addClass(tempOrderClass);
                    searchData(classObj, 1, $('#searchField1').val(), tableColumns[parseInt(colIdx[0][0])].dbFieldName, tempOrderClass === 'ASC' ? 'asc' : 'desc', null, false);
                }
            });
            // var table = $('.markers-datatable').dataTable();
            // table.on('click', 'th', function () {
            //     if (table.fnSettings() !== null && !!sortOrder) {
            //         var colIdx = $('.markers-datatable').DataTable().order();
            //         searchData(classObj, 1, $('#searchField1').val(), tableColumns[parseInt(colIdx[0][0])].dbFieldName, colIdx[0][1]);
            //     }
            // });
        });
        $('.markers-datatable').on('page.dt', function () {
            searchFlag = 0;
        });
        $('.markers-datatable').on('search.dt', function () {
            var value = $('.dataTables_filter input').val();
            if (value != '') {
                searchFlag = 1;
                searchValue = value;
            }
            else
                searchValue = '';
        });
        $("#example_paginate").show();
        var groupColumn = groupIndex;
        var orderColumn = !!sortOrder ? tableColumns.map(e => e.dbFieldName).indexOf(sortColumn) : null;
        let headerButton = [];
        if (classObj.hasOwnProperty("tableHeaderButton") && !!classObj.tableHeaderButton && (typeof classObj.tableHeaderButton === 'object' || Array.isArray(classObj.tableHeaderButton))) {
            let headerButtonList = Array.isArray(classObj.tableHeaderButton) ? classObj.tableHeaderButton : new Array({ ...classObj.tableHeaderButton })
            headerButtonList.forEach(buttonObject => {
                headerButton.push({
                    text: `${!!buttonObject.icon ? `<i class="icon-${buttonObject.icon} mr-2"></i>` : ''}${!!buttonObject.title ? buttonObject.title : ''}`,
                    action: function (event) {
                        event.preventDefault();
                        classObj[buttonObject.functionName]();
                    }
                })
            })
        }
        if (classObj.hasOwnProperty("tableHeaderButtonGroup") && !!classObj.tableHeaderButtonGroup && (typeof classObj.tableHeaderButtonGroup === 'object' || Array.isArray(classObj.tableHeaderButtonGroup))) {
            let headerButtonList = Array.isArray(classObj.tableHeaderButtonGroup) ? classObj.tableHeaderButtonGroup : new Array({ ...classObj.tableHeaderButtonGroup })
            const buttonList = [];
            headerButtonList.forEach(buttonObject => {
                buttonList.push({
                    text: `${!!buttonObject.icon ? `<i class="icon-${buttonObject.icon} mr-2"></i>` : ''}${!!buttonObject.title ? buttonObject.title : ''}`,
                    action: function (event) {
                        event.preventDefault();
                        classObj[buttonObject.functionName](buttonObject.title);
                    }
                })
            })
            headerButton.push({
                extend: 'collection',
                text: '',
                buttons: buttonList
            })
        }
        if (headerButtonFlag === 1)
            headerButton.push({
                text: '<i class="icon-filter3"></i> More Filters',
                action: function (e, dt, node, config) {
                    e.preventDefault();
                    $('body').addClass('show-slidepanel-right')
                }
            })
        //This Option has to be used if require only if Excel Download is needed specificaly
        // headerButton.push({
        //   extend: 'excel',
        //   title: exportFileName,
        //   exportOptions: {
        //       columns: exportVisibility === false ? ':visible:not(:last-child)' : ''
        //   }
        //});
        if (isExport === true)
            headerButton.push({
                extend: 'collection',
                text: '',
                buttons: [
                    {
                        extend: 'excel',
                        title: exportFileName,
                        exportOptions: {
                            columns: exportVisibility === false ? ':visible:not(:last-child)' : ''
                        }
                    },
                    {
                        extend: 'copy',
                        title: exportFileName,
                    },
                    {
                        extend: 'csv',
                        title: exportFileName,
                        customize: function (csv) {
                            return exportFileName + "\n" + csv;
                        },
                        exportOptions: {
                            columns: exportVisibility === false ? ':visible:not(:last-child)' : ''
                        }
                    },
                    {
                        extend: 'pdfHtml5',
                        title: exportFileName,
                        exportOptions: {
                            columns: exportVisibility === false ? ':visible:not(:last-child)' : ''
                        },
                        //pageSize: 'A4',
                        customize: function (doc, config) {
                            var tableNode;
                            for (let i = 0; i < doc.content.length; ++i) {
                                if (doc.content[i].table !== undefined) {
                                    tableNode = doc.content[i];
                                    break;
                                }
                            }
                            var rowIndex = 0;
                            var tableColumnCount = tableNode.table.body[rowIndex].length;
                            if (tableColumnCount > 4) {
                                doc.pageOrientation = 'landscape';
                                doc.pageSize = 'A2';
                            }
                        }
                    }
                ]
            });
        this.state.table = $('.markers-datatable').DataTable({
            bPaginate: true,
            colReorder: true,
            //dom: "<'p-15'<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 text-right'B>>><'row'<'col-sm-12'tr>><'p-15'<'row'<'col-sm-12 col-md-6'i><'col-sm-12 col-md-6 text-right'>>>",
            dom: isExport === true || isExport === 'custom' ? "<'p-15'<'row'<'col-sm-12 col-md-4 custom-search'><'col-sm-12 col-md-8 text-right'B>>><'row'<'col-sm-12'<'table-responsive'tr>>><'p-15'<'row'<'col-sm-12 col-md-6'><'col-sm-12 col-md-6 text-right'>>>" : "<'p-15'<'row'<'col-sm-12 col-md-4 custom-search'><'col-sm-12 col-md-8 text-right'>>><'row'<'col-sm-12'<'table-responsive'tr>>><'p-15'<'row'<'col-sm-12 col-md-6'><'col-sm-12 col-md-6 text-right'>>>",
            columnDefs: [{
                'targets': 0,
                'searchable': false,
                'orderable': true,
                'className': 'dt-body-center',
                //"visible": false,
                "targets": groupIndex != null ? groupColumn : ''
            }],
            "pagingType": "full_numbers",
            select: {
                style: 'os',
                selector: 'td:first-child'
            },
            order: [
                // !!sortOrder ? [orderColumn, sortOrder] : [1, 'asc']
            ],
            "rowCallback": function (row, data) {
            },
            "infoCallback": function (settings, start, end, max, total, pre) {
                var table = $('.markers-datatable').DataTable();
                var info = table.page.info();
                let startCount = info.start;
                startCount = parseInt(startCount) + 1;
                let currentPage = info.page;
                currentPage = parseInt(currentPage) + 1;
                pageCount = currentPage;
                if (paginateFlag != 0 && searchFlag === 0) {
                    if (classObj)
                        classObj['paginate'](currentPage);
                }
                paginateFlag = paginateFlag + 1;
                return 'Showing ' + startCount + " to " + parseInt(info.end, 10) + ' of ' + totalCount + ' entries';
            },
            "footerCallback": function (row, data, start, end, display) {
                if (searchBtn === 0 && isSearch === 1) {
                    $(".custom-search").html('<div id="DataTables_Table_0_filter" class="input-group mb-xs-4"><input type="search" id="searchField" class="form-control" placeholder="Search" aria-controls="DataTables_Table_0"/><div class="input-group-append"><button class="btn btn-success" type="button" id="btn1">GO</button></div></div>');
                }
                searchBtn = searchBtn + 1;
            },
            "drawCallback": groupIndex != null ? function (settings) {
                var api = this.api();
                var rows = api.rows({ page: 'current' }).nodes();
                var last = null;
                api.column(groupColumn, { page: 'current' }).data().each(function (group, i) {
                    if (last !== group) {
                        $(rows).eq(i).before(
                            '<tr class="group"><td colspan="5">' + group + '</td></tr>'
                        );
                        last = group;
                    }
                });
            } : '',
            responsive: false,
            buttons: headerButton,
            //scrollY: contentH,
            //scroller: true
        });
    }
    //Returns dynamic DataTable with optional buttons...
    returnTable(tableColumns, tableRows, classObj, editFunc = '', deleteFunc = '', dateFlag = 0, viewFunc = '', checkedValuesArray = [], cancelFunc = '', totalCount = 0, currentPage = 0, searchString = '', sortColumn = '', sortOrder = '') {
        searchBtn = 0;
        var headers = tableColumns.map((value, key) =>
            <th key={key} id={value.name}>{value.header === 'checkall' ? <label className="fluidCheckbox mb-0"><input name="select_all" value="1" id="select-all" type="checkbox" onClick={(e) => this.selectAll(e, classObj)} /><span><i></i></span></label> : value.header === 'checkallnot' ? '' : langObj.returnLanguage(value.header)}</th>
        );
        var rows = null;
        //var pageArr = [];
        var paginationArray = [];
        var pageDetails = [];
        var totalRecords = parseInt(tableRows.length);
        var countOfPage = Math.ceil(totalCount / 10);
        //pageArr = this.createArray(Math.ceil(totalCount / 10));
        for (let l = currentPage; l <= parseInt(currentPage) + 2; l++) {
            if (l <= countOfPage)
                paginationArray.push(l);
        }
        tableRows && tableRows.length > 0 ?
            rows = tableRows.map((data, i) =>
                <tr key={i}>
                    {tableColumns.map((value1, i) => {
                        switch (value1.type) {
                            case 'radiobutton':
                                return <td><label className="fluid-radio mb-0 d-flex align-items-center"><input className="hidden" value={data[value1.value]} type="radio" name="radio" /><span className="label"></span></label></td>
                            case 'checkbox':
                                let checkBoxVal = '';
                                let checkBoxName = '';
                                let checkBoxClass = '';
                                if (value1.columnName)
                                    checkBoxName = value1.columnName;
                                if (value1.inputClass)
                                    checkBoxClass = value1.inputClass;
                                value1.params.map((paramVal, i) => {
                                    checkBoxVal = checkBoxVal + data[paramVal] + value1.separator;
                                });
                                if (checkBoxVal != '')
                                    checkBoxVal = checkBoxVal.slice(0, -1);
                                return !!checkBoxClass && checkBoxClass === 'checkBoxClass' ? <td key={i}><label className="fluidCheckbox"><input className={checkBoxClass} name={checkBoxName} value={checkBoxVal} type="checkbox" onClick={() => this.rowClick()} /><span><i></i></span></label></td>
                                    :
                                    <td key={i}><label className="fluidCheckbox"><input className={checkBoxClass} name={checkBoxName} value={checkBoxVal} type="checkbox" /><span><i></i></span></label></td>
                            case 'commaSeparatedString': return <td>{commonFuncObj.formatCommaSeparatedString(data[value1.name])}</td>
                            case 'date':
                                return <td key={i} data-order={!!value1.format ? value1.format : null}>{this.returnContent(value1.type, data, value1, classObj, editFunc, deleteFunc, dateFlag, viewFunc, cancelFunc)}</td>
                            default:
                                return <td key={i}>{this.returnContent(value1.type, data, value1, classObj, editFunc, deleteFunc, dateFlag, viewFunc, cancelFunc)}</td>
                        }
                    })}
                </tr>
            ) : rows = null;
        paginationArray && paginationArray.length > 0 ?
            pageDetails = paginationArray.map((pageValue, j) =>
                currentPage === pageValue
                    ?
                    <li className="paginate_button page-item active" key={j}><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(pageValue), searchString)}>{pageValue}</Link></li>
                    :
                    <li className="paginate_button page-item" key={j}><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(pageValue), searchString)}>{pageValue}</Link></li>
            )
            :
            pageDetails = null;
        if (totalRecords === 0) {
            $('#Empty_Table_1 tbody').empty();
            return <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="p-15"><div className="row"><div className="col-sm-12 col-md-4 custom-search"><div id=" DataTables_Table_0_filter" className="input-group mb-xs-4"><input type="search" id="searchField" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" /><div className="input-group-append"><button className="btn btn-success" type="button" id="btn1" onClick={() => this.defaultSearch(classObj, sortColumn, sortOrder)}>GO</button></div></div></div></div></div>
                <table id="Empty_Table_1" className="table markers-datatable">
                    <thead><tr>{headers}</tr></thead>
                    <tbody></tbody>
                    <caption style={{ textAlign: "center" }}>{langObj.returnLanguage('emptyDataTable')}</caption>
                </table>
            </div>
        }
        else
            return <div> <table className="table markers-datatable">
                <thead><tr>{headers}</tr></thead>
                <tbody>{rows}</tbody>
            </table>
                <div className="p-15">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing {1 + parseInt(currentPage - 1) * 10} to {totalRecords + parseInt(currentPage - 1) * 10} of {totalCount} entries</div>
                        </div>
                        <div className="col-sm-12 col-md-6 text-right">
                            <div className="dataTables_paginate paging_full_numbers" id="DataTables_Table_0_paginate">
                                {!!classObj.onlyShowNextPrev ?
                                    <ul className="pagination">
                                        <li className={`paginate_button page-item previous ${currentPage === 1 ? ' disabled' : ''}`} id="DataTables_Table_0_previous">
                                            <Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(currentPage - 1), searchString)}>Previous</Link>
                                        </li>
                                        <li className={`paginate_button page-item next ${currentPage === parseInt(countOfPage) ? ' disabled' : ''}`} id="DataTables_Table_0_next">
                                            <Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="9" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(currentPage + 1), searchString)}>Next</Link>
                                        </li>
                                    </ul>
                                    :
                                    <ul className="pagination">
                                        {
                                            currentPage === 1
                                                ?
                                                <li className="paginate_button page-item first disabled" id="DataTables_Table_0_first"><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](1, searchString)}>First</Link></li>
                                                :
                                                <li className="paginate_button page-item first" id="DataTables_Table_0_first"><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="0" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](1, searchString)}>First</Link></li>
                                        }
                                        {
                                            currentPage === 1
                                                ?
                                                <li className="paginate_button page-item previous disabled" id="DataTables_Table_0_previous"><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(currentPage - 1), searchString)}>Previous</Link></li>
                                                :
                                                <li className="paginate_button page-item previous" id="DataTables_Table_0_previous"><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="1" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(currentPage - 1), searchString)}>Previous</Link></li>
                                        }
                                        {pageDetails}
                                        <li className="paginate_button page-item disabled" id="DataTables_Table_0_ellipsis"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx="7" tabIndex="0" className="page-link">...</a></li>
                                        {
                                            currentPage === parseInt(countOfPage)
                                                ?
                                                <li className="paginate_button page-item active"><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(countOfPage), searchString)}>{countOfPage}</Link></li>
                                                :
                                                <li className="paginate_button page-item "><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="3" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(countOfPage), searchString)}>{countOfPage}</Link></li>
                                        }
                                        {
                                            currentPage === parseInt(countOfPage)
                                                ?
                                                <li className="paginate_button page-item next disabled" id="DataTables_Table_0_next"><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="9" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(currentPage + 1), searchString)}>Next</Link></li>
                                                :
                                                <li className="paginate_button page-item next" id="DataTables_Table_0_next"><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="9" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(currentPage + 1), searchString)}>Next</Link></li>
                                        }
                                        {
                                            currentPage === parseInt(countOfPage)
                                                ?
                                                <li className="paginate_button page-item last disabled" id="DataTables_Table_0_last"><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="10" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(countOfPage), searchString)}>Last</Link></li>
                                                :
                                                <li className="paginate_button page-item last" id="DataTables_Table_0_last"><Link to="#" aria-controls="DataTables_Table_0" data-dt-idx="10" tabIndex="0" className="page-link" onClick={() => classObj['paginate'](parseInt(countOfPage), searchString)}>Last</Link></li>
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }
    //Returns DataTable with no data...
    returnEmptyTable(tableColumns, classObj = {}) {
        const { isRemoveSearch } = classObj;
        var headers = tableColumns.map((value, key) =>
            <th key={key}>{value.header === 'checkall' ? <label class="fluidCheckbox"><input name="select_all" value="1" id="select-all" type="checkbox" onClick={(e) => this.selectAll(e, classObj)} /><span><i></i></span></label> : value.header === 'checkallnot' ? '' : langObj.returnLanguage(value.header)}</th>
        );
        var rows = null;
        let headerButtonList = [];
        if (classObj.hasOwnProperty("tableHeaderButton") && !!classObj.tableHeaderButton && (typeof classObj.tableHeaderButton === 'object' || Array.isArray(classObj.tableHeaderButton))) {
            headerButtonList = Array.isArray(classObj.tableHeaderButton) ? classObj.tableHeaderButton : new Array({ ...classObj.tableHeaderButton })
        }
        return <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="p-15">
                <div className="row">
                    <div className="col-sm-6 col-md-4 custom-search">
                        {!isRemoveSearch || isRemoveSearch === undefined &&
                            <div id="DataTables_Table_0_filter" className="input-group mb-xs-4">
                                <input type="search" id="searchField" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                <div className="input-group-append">
                                    <button className="btn btn-success" type="button" id="btn1" onClick={() => this.defaultSearch(classObj)}>GO</button>
                                </div>
                            </div>
                        }
                    </div>
                    {!!headerButtonList && headerButtonList.length !== 0 &&
                        <div className="col-sm-12 col-md-8 text-right">
                            <div className="dt-buttons btn-group flex-wrap">
                                {headerButtonList.map(buttonObject => {
                                    return <button aria-controls="DataTables_Table_2" className="btn btn-secondary" onClick={() => classObj[buttonObject.functionName]()}>
                                        <span>{!!buttonObject.icon && <i className={`icon-${buttonObject.icon} mr-2`}></i>} {!!buttonObject.title ? buttonObject.title : ''}</span>
                                    </button>
                                })}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <table className="table markers-datatable">
                <thead><tr>{headers}</tr></thead>
                <tbody>{rows}</tbody>
                <caption style={{ textAlign: "center" }}>{langObj.returnLanguage('emptyDataTable')}</caption>
            </table>
        </div>
    }
    //Returns search result...
    defaultSearch(classObj = {}, sortColumn = '', sortOrder = '') {
        searchValue = $('#searchField').val();
        let defaultSearchFlag = 1;
        searchData(classObj, 1, $('#searchField').val(), sortColumn, sortOrder, defaultSearchFlag);
    }
    //Returns dynamic content...
    returnContent(type, data, value1, classObj, editFunc, deleteFunc, dateFlag, viewFunc, cancelFunc) {
        var content = {
            'default':
                data[value1.name],
            'boolean':
                data[value1.name] === true ? 'Yes' : 'No',
            'modal':
                <Link to="#" data-toggle="modal" data-target="#titleSchoolSessionModal">{data[value1.name]}</Link>,
            'time':
                commonFuncObj.changeTimeFormat(data[value1.name]),
            'timeField':
                value1.methodName ? commonFuncObj[value1.methodName](data[value1.name]) : '',
            'date':
                value1.ConvertDate && value1.ConvertDate === true ?
                    dateFlag === 1 ? <ConvertDate calDate={data[value1.name]} /> : null
                    :
                    !!data[value1.name] && <Moment format={value1.format}>{data[value1.name]}</Moment>,
            'blank':
                '-',
            'linkUrl':
                <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.id)}>{data[value1.name]}</Link>
                        : null
                    }
                </div>,
            'linkUrlMultiField':
                <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.id)}>{data[value1.name]}</Link>
                        : null
                    }
                </div>,
            'multiField':
                data[value1.name],
            'plusIcon':
                <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={(e) => classObj[viewFunc](e, data.id)}><i className="icon-plus3"></i></Link>
                        : null
                    }
                </div>,
            'linkContent':
                <div>
                    {
                        data.isEditable === undefined
                            ?
                            <Link to={{ pathname: value1.linkUrl, state: { listId: !!data.id ? data.id : data.studentId, listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, fromLink: !!value1.fromLink ? value1.fromLink : '' } }}>{data[value1.name]}</Link>
                            :
                            <Link to={{ pathname: value1.linkUrl, state: { listId: !!data.id ? data.id : data.studentId, listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, fromLink: !!value1.fromLink ? value1.fromLink : '', editStatus: data.isEditable } }}>{data[value1.name]}</Link>
                    }
                </div>,
            'linkContentMultiField':
                <div>
                    {
                        data.isEditable === undefined
                            ?
                            <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0 } }}>{data[value1.name]}</Link>
                            :
                            <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, editStatus: data.isEditable } }}>{data[value1.name]}</Link>
                    }
                </div>,
            'toggleActionButton':
                <div className="text-nowrap">
                    {
                        data[value1.status] === 'Pending'
                            ?
                            (editFunc != '' ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) : null
                    }
                    {
                        data[value1.status] === 'Pending'
                            ?
                            (deleteFunc != '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) : null
                    }
                </div>,
            'buttonWithLink':
                <div className="text-nowrap">
                    {
                        viewFunc !== '' ?
                            <Link to="#" onClick={() => classObj[viewFunc](data.id)} className="mr-3"><i className="icon-eye f-s-14"></i></Link>
                            : null
                    }
                    {
                        data.isEditable === undefined ?
                            (editFunc != '' ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                            ((editFunc != '' && data.isEditable) ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {
                        data.isDelete === undefined ?
                            (deleteFunc != '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null) :
                            ((deleteFunc != '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>,
            'button':
                <div>
                    {
                        data.isEditable === undefined ?
                            (editFunc != '' ? <Link to="#" title={i18n.t("edit")} onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                            ((editFunc != '' && data.isEditable) ? <Link to="#" title={i18n.t("edit")} onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {
                        data.isDelete === undefined ?
                            (deleteFunc != '' ? <Link to='#' title={i18n.t("delete")} onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) :
                            ((deleteFunc != '' && data.isDelete) ? <Link to="#" title={i18n.t("delete")} onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                    {
                        cancelFunc !== '' ?
                            <Link to="#" onClick={() => classObj[cancelFunc](data.id)} className="mr-3"><i className="icon-blocked"></i></Link>
                            : null
                    }
                </div>,
            'status':
                commonFuncObj.formatStatus(data[value1.name]),
            'colorField':
                <div style={{ color: value1.colorCode }}> {data[value1.name]} </div>,
            'customRow':
                !!value1.functionName && typeof classObj[value1.functionName] === "function" ? classObj[value1.functionName]({ ...data }) : null
        };
        return content[type];
    }
}
export default extendedDataTable;
