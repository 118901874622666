import { updateObject } from "../../../shared/store/utility";
import commonConfig from "../../../common/commonConfig";
import { camelCase } from "lodash";
const initialState = {
    getStudentContactInfo: {},
    getStudentInfoList: [],
    studentList: [],
    getStudent: {},
    getStudentList: {},
    saveStudentGenralInfo: false,
    studentIdentifierList: [],
    saveStudentIdentifier: 0,
    getStudentIdentifier: {},
    studentDisabilityList: [],
    saveStudentGuardian: false,
    getStudentGuardian: {},
    studentGuardianList: [],
    saveStudentDisability: false,
    getStudentDisability: {},
    saveStudentReferral: false,
    getStudentReferral: {},
    getStudentImmunization: {},
    saveStudentImmunization: false,
    studentImmunizationList: [],
    saveStudentBirth: false,
    getStudentBirth: {},
    saveStudentEnrollmentInfo: false,
    studentSessionIdList: [],
    getStudentEnrollmentInfo: {},
    getStudentEnrollmentEligibility: {},
    saveStudentEnrollmentEligibility: false,
    getStudentEnrollmentProgramGift: {},
    saveStudentEnrollmentProgramGift: false,
    saveStudentCohort: false,
    studentEnrollmentInfoList: [],
    getStudentMiscstatus: {},
    saveStudentMiscstatus: false,
    saveStudentHealthInfo: false,
    getStudentHealthInsurance: {},
    studentVisaList: [],
    getStudentVisa: {},
    studentCourseMPList: [],
    saveStudentVisa: false,
    studentMedicalEmergencyList: [],
    deleteStudentMedicalEmergency: false,
    saveStudentMedicalEmergency: false,
    getStudentMedicalEmergency: [],
    saveStudentHealth: false,
    studentHealthList: [],
    getStudentHealth: {},
    getStudentDocument: [],
    studentDocumentList: [],
    saveStudentDocument: false,
    saveStudentExitStatus: false,
    studentExitStatusList: [],
    getStudentExitStatus: {},
    deleteStudentDocument: false,
    saveStudentMassAssign: false,
    studentSearchResultList: [],
    studentEnrollmentEligibilityList: [],
    studentEnrollmentProgramGiftList: [],
    studentParentAssociateStudentList: [],
    studentReferralList: [],
    saveStudentReEnroll: false,
    studentDropOutList: [],
    studentGradeLevelList: [],
    studentSearchList: [],
    studentFilterDataSubjectList: [],
    studentFilterDataCourseList: [],
    studentFilterDataCourseSectionList: [],
    studentCourseSectionDataList: [],
    studentAllocatedStudentDataList: [],
    getStudentOverview: {},
    studentHealthScreenList: [],
    studentDocumentVerificationList: [],
    studentAddressList: [],
    getStudentSchedule: {},
    studentLanguageList: [],
    getStudentSubmenuData: {},
    studentActualGradeLevelList: [],
    studentActualSessionList: [],
    studentPlanGradeLevelList: [],
    studentPlanSessionList: [],
    studentPlanSectionList: [],
    studentActualSectionList: [],
    studentEoyStatusList: [],
    saveStudentEoyStatus: {},
    getStudentEnrollmentCode: {},
    saveStudentClearEnrollment: {},
    saveStudentNoShowProcess: {},
    studentImmunizationNameList: [],
    studentImmunizationTradeNameList: [],
    getStudentCode: {},
    saveStudentMedication: false,
    studentMedicationList: [],
    getStudentMedication: {},
    studentInventoryList: [],
    saveStudentInventory: false,
    saveStudentSchedule: false,
    saveStudentScheduleupdate: false,
    studentHealthScreeningList: [],
    studentHealthVisitList: [],
    studentSpecialProgramMenuList: [],
    studentSpecialProgramList: [],
    getStudentSpecialProgram: {},
    saveStudentSpecialProgram: false,
    getStudentMedicationHealthVisit: {},
    saveStudentMedicationHealthVisit: false,
    saveStudentHealthVisit: false,
    getStudentHealthVisit: {},
    studentHealthVisitContactList: [],
    studentHealthVisitVitalList: [],
    studentMedicationSectionInfoList: [],
    getStudentCohortDetails: {},
    studentCohortList: [],
    getStudentCohort: {},
    getStudentDefaultStudentIdentifier: {},
    getStudentHealthInfo: {},
    getStudentEnrollInfo: {},
    studentScheduleList: [],
    getStudentActiveInactiveStudent: {},
    getStudentGraduationPlan: {},
    saveStudentGraduationPlan: false,
    studentSpecialProgramReportsList: [],
    studentSectionIdList: [],
    studentSiblingSearchList: [],
    saveStudentSibling: {},
    schoolSessionIdList: [],
    getStudentTransportDetails: {},
    studentTransportBreakDetailList: [],
    studentTransportAttendanceList: [],
    getStudentIdCard: {},
    studentContactAddressTypeList: [],
    studentCertificateList: [],
    getStudentCertificate: {},
    studentOverviewGradesList: [],
    studentTodaysAssignmentList: [],
    studentAssignmentCourseSectionList: [],
    studentAssessmentAssignmentList: [],
    studentMedicalEmergencyBySchoolList: [],
    getStudentGeneratedIdCard: {},
    studentGenerateedIdCardList: [],
    saveStudentIdCard: false,
    studentMedicationInfoList: [],
    getStudentIdentificationCode: {},
    studentUserAccessList: [],
    saveStudentUserAccess: false,
    getStudentDependencies: null,
    studentAttendanceLogList:[],
    studentAllAttendanceLogList:[],
    studentAllRecordedByList:[],
    studentStudentAllRecordedByListList:[],
    saveStudentAttendanceLog:{},
    getStudentAttendanceLogReport:{},
};
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
};
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
};
const reducerStudent = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case "STUDENT_LIST": return handleSuccess(state, action, camelCase(`student_${action.title}_list`));
        case "STUDENT_SAVE": return handleSuccess(state, action, camelCase(`save_student_${action.title}`));
        case "STUDENT_GET": return handleSuccess(state, action, camelCase(`get_student_${action.title}`));
        case "STUDENT_DELETE": return handleSuccess(state, action, camelCase(`delete_student_${action.title}`));
        case commonConfig.STUDENT_STORE_RESET: return handleSuccess(state, action, action.title);
        case 'HANDLE_STUDENT_FAIL': return handleFail(state);
        default: return state;
    }
};
export default reducerStudent;
