import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';
const initialState = {
    courseList: [],
    saveCourseSubject: false,
    courseSubjectAreaList: [],
    courseAcademicSubjectList: [],
    getCourseSubject: {},
    courseGeneralViewList: [],
    coursePartList: false,
    saveCourse: false,
    saveCourseSectionLevel: false,
    courseSectionLevelList: [],
    deleteCourseSectionLevel: false,
    getCourse: {},
    saveCourseSection: false,
    getCourseSection: {},
    deleteCourseSection: false,
    courseSubjectList: [],
    deleteCourseSubject: false,
    deleteCourse: false,
    courseOccurenceList: [],
    courseSectionList: [],
    saveCourseOccurence: false,
    courseLanguagesSectionList: [],
    courseTeacherList: [],
    getCourseOccurence: {},
    saveCourseSectionPeriodSchedule: false,
    getCourseSectionPeriodSchedule: {},
    courseFilterResultList: [],
    courseSectionScheduledStudentsList: [],
    courseGradeLevelList: [],
    courseMarkingPeriodList: [],
    saveCourseStudyMaterial: {},
    courseStudyMaterialList: [],
    courseCourseSectionStudyMaterialList: [],
    saveCourseCourseSectionStudyMaterial: {},
    saveCourseActiveInactive: false,
    getCourseMultipleDelete: {},
    courseEventStudySchoolMaterialList: [],
    courseEventStudyCourseMaterialList: [],
    courseEventStudyCoursesectionMaterialList: [],
    courseEventCourseMaterialList: []
}
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
}
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const reducerSchool = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'COURSE_LIST': return handleSuccess(state, action, camelCase(`course_${action.title}_list`));
        case 'COURSE_SAVE': return handleSuccess(state, action, camelCase(`save_course_${action.title}`));
        case 'COURSE_GET': return handleSuccess(state, action, camelCase(`get_course_${action.title}`));
        case 'COURSE_DELETE': return handleSuccess(state, action, camelCase(`delete_course_${action.title}`));
        case commonConfig.COURSE_STORE_RESET: return handleSuccess(state, action, action.title);
        case 'HANDLE_COURSE_FAIL': return handleFail(state);
        default: return state;
    }
};
export default reducerSchool;