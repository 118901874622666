import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';
const initialState = {
    saveDisciplineIncident: false,
    disciplineIncidentList: [],
    getDisciplineIncident: {},
    getDisciplineIncidentid: {},
    getDisciplineIncidentschoolid: {},
    disciplineCreateIncidentList: [],
    saveDisciplineCreateIncident: false,
    getDisciplineCreateIncident: {},
    saveDisciplineStudent: {},
    getDisciplineStudent: {},
    saveDisciplineIncidentPerson: false,
    disciplineIncidentPersonList: [],
    getDisciplineIncidentPerson: {},
    disciplineDepartmentList: [],
    disciplineStudentList: [],
    disciplineStaffList: [],
    disciplineGuardianList: [],
    getDisciplineCancelIncident: {},
    disciplineStudentIncidentList: [],
    disciplineStudentParentList: [],
    disciplinePerpetratorStudentByIncidentList: [],
    disciplineCreateIncidentApprovalList: null
}
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
}
const commonHandleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const reducerDiscipline = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'DISCIPLINE_LIST': return commonHandleSuccess(state, action, camelCase(`discipline_${action.title}_list`));
        case 'DISCIPLINE_SAVE': return commonHandleSuccess(state, action, camelCase(`save_discipline_${action.title}`));
        case 'DISCIPLINE_GET': return commonHandleSuccess(state, action, camelCase(`get_discipline_${action.title}`));
        case 'DISCIPLINE_DELETE': return commonHandleSuccess(state, action, camelCase(`delete_discipline_${action.title}`));
        case commonConfig.DISCIPLINE_STORE_RESET: return commonHandleSuccess(state, action, action.title);
        case 'HANDLE_DISCIPLINE_FAIL': return handleFail(state);
        default: return state;
    }
}
export default reducerDiscipline;