import CryptoAES from 'crypto-js/aes';
import { hostArray } from '../../../hostConfig';
import commonConfig from '../commonConfig';
import sessionStore from '../../common/component/sessionStoreComponent';
var SECRET_KEY = '8cfLJmCNUVFZkLcmT916tA9n91eAUepfB2';

export const returnSessionItems = () => {  
    let studentId = new sessionStore().returnStudentID();
    return 'token=' + new sessionStore().returnSessionToken() + '&l=' + new sessionStore().returnLanguage() + '&sc=' + CryptoAES.encrypt(new sessionStore().returnSchoolID().toString(), SECRET_KEY) +
        '&se=' + CryptoAES.encrypt(new sessionStore().returnSessionID().toString(), SECRET_KEY) + '&d=' + CryptoAES.encrypt(new sessionStore().returnDistrictID().toString(), SECRET_KEY) +
        '&sy=' + CryptoAES.encrypt(new sessionStore().returnSchoolYear().toString(), SECRET_KEY) + '&m=' + CryptoAES.encrypt(new sessionStore().returnMarkingperiodId().toString(), SECRET_KEY) + '&r=' + CryptoAES.encrypt(new sessionStore().returnUserRole().toString(), SECRET_KEY) +
        '&st=' + CryptoAES.encrypt(new sessionStore().returnStaffID().toString(), SECRET_KEY) + '&u=' + CryptoAES.encrypt(new sessionStore().returnUserID().toString(), SECRET_KEY) +
        '&mc=' + CryptoAES.encrypt(new sessionStore().returnCurrentMenuCode(), SECRET_KEY) +
        '&un=' + CryptoAES.encrypt(new sessionStore().returnUserName(), SECRET_KEY) +
        '&ri=' + CryptoAES.encrypt(new sessionStore().returnUserRoleID().toString(), SECRET_KEY) +
        '&ev=' + CryptoAES.encrypt(new sessionStore().returnHostEnvironment().toString(), SECRET_KEY) +
        '&stu=' + CryptoAES.encrypt(studentId.toString(), SECRET_KEY) +
        '&sed=' + CryptoAES.encrypt(new sessionStore().returnCurrentSchoolSessionEndDate().toString(), SECRET_KEY) +
        '&sdf=' + CryptoAES.encrypt(new sessionStore().returnDateFormat().toString(), SECRET_KEY) +
        '&lue=' + CryptoAES.encrypt(new sessionStore().returnLoggedUserEmail(), SECRET_KEY)
}

export const redirectBuild = (path, buildName, menuCode = "") => { 
    if (menuCode !== "") {
        new sessionStore().storeSecureData(commonConfig.SESSION_MENU_CODE, menuCode);
    }
    let currentHost = new sessionStore().returnHostEnvironment();
    let redirectPath = hostArray[currentHost][buildName];
    window.location.href = redirectPath + path + '?' + returnSessionItems();
}