import * as actionTypesShared from '../../store/actions/actionTypesShared';
import { SharedApiConfig } from "../../sharedApiConfig";
import { commonConfig } from '../../../common/commonConfig';
import { sessionStore } from '../../../common/component/sessionStoreComponent';



const axios = require('axios');
export const handleCommonApiFail = (error) => {
    return {
        type: actionTypesShared.HANDLE_COMMON_FAIL,
        error: error
    };
};
export const handleCommonApiSuccess = (fields, title, action, isResponseConvertWithJson = false) => {
    return {
        type: 'COMMON_' + action.toUpperCase(),
        responseData: fields,
        title: title,
        isResponseConvertWithJson
    };
};
export const callCommonListApi = (input, title, isResponseConvertWithJson = false) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleCommonApiSuccess(response.data, title, 'list', isResponseConvertWithJson));
        }).catch(error => {
            dispatch(handleCommonApiFail(error));
        });
    };
};
export const callCommonSaveApi = (input, title, isUpload = false) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig[isUpload ? 'API_TO_UPLOAD_AND_SAVE' : 'API_TO_CAll'],
            headers: isUpload ? {} : SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: isUpload ? input : JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleCommonApiSuccess(response.data, title, 'save'));
        }).catch(error => {
            dispatch(handleCommonApiFail(error));
        });
    };
};
export const callCommonGetByApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleCommonApiSuccess(response.data, title, 'get'));
        }).catch(error => {
            dispatch(handleCommonApiFail(error));
        });
    };
};
export const callCommonDeleteApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleCommonApiSuccess(response.data, title, 'delete'));
        }).catch(error => {
            dispatch(handleCommonApiFail(error));
        });
    };
};
export const getLookupSucess = (lookups, postedData) => {
    return {
        type: actionTypesShared.GET_LOOKUP_SUCESS,
        lookups,
        postedData
    };
};
export const callLookupApi = (categoryType, stateCode = '', countryCode = '', customerId = '', freshData = false) => {
    var storedLookups = new sessionStore().returnLookUpValues(categoryType);
    if (storedLookups && JSON.parse(storedLookups).length > 0 && categoryType !== commonConfig.LOOKUP_DISCIPLINARY_ACTION_TAKEN && categoryType !== commonConfig.LOOKUP_INCIDENT_BEHAVIOUR && categoryType !== commonConfig.LOOKUP_EXIT_WITHDRAWAL_TYPE && categoryType !== commonConfig.LOOKUP_STUDENT_PUBLIC_SCHOOL_RESIDENTIAL_STATUS && categoryType !== commonConfig.LOOKUP_SPECIAL_PROGRAMS && categoryType !== commonConfig.LOOKUP_STUDENT_PERSON_RELATIONSHIP && categoryType !== commonConfig.LOOKUP_STUDENT_RELATIONSHIP && categoryType !== commonConfig.LOOKUP_COURSE_SECTION_DELIVERY_MODE && categoryType !== commonConfig.LOOKUP_ENROLLMENT_ENTRY_TYPE && categoryType !== commonConfig.LOOKUP_SR_REPORTING_TERM && categoryType !== commonConfig.LOOKUP_STUDENT_IDENTIFICATION_SYSTEM && categoryType !== commonConfig.LOOKUP_STUDENT_IDENTIFICATION_SYSTEM_NM) {
        return dispatch => {
            dispatch(getLookupSucess(JSON.parse(storedLookups), { categoryType: categoryType }));
        }
    } else {
        return dispatch => {
            axios({
                method: commonConfig.METHOD_TYPE_POST,
                url: SharedApiConfig.API_TO_CAll,
                headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
                data: JSON.stringify({
                    component: commonConfig.COMPONENT_RESOURCE,
                    endPoint: categoryType === commonConfig.LOOKUP_SPECIAL_PROGRAMS ? commonConfig.COMPONENT_RESOURCE_GET_LOOKUPS_MERGED : commonConfig.COMPONENT_RESOURCE_GET_LOOKUPS,
                    method: commonConfig.METHOD_TYPE_POST,
                    postedData: { categoryType: categoryType, stateCode: stateCode, countryCode: countryCode, customerId: customerId, freshData: freshData }
                })
            }).then(function (response) {
                const lookups = response.data;
                if (lookups.success) {
                    dispatch(getLookupSucess(lookups.data, { categoryType: categoryType }));
                } else {
                    dispatch(handleCommonApiFail(lookups.message));
                }
            }).catch(err => {
                dispatch(handleCommonApiFail(err));
            });
        };
    }
};

export const callMergedLookupApi = (categoryType, stateCode = '', countryCode = '', customerId = '', freshData = false, isStateSpecific = false) => {
    // var storedLookups = new sessionStore().returnLookUpValues(categoryType);
    // if (storedLookups && JSON.parse(storedLookups).length > 0 && categoryType !== commonConfig.LOOKUP_SPECIAL_PROGRAMS && categoryType !== commonConfig.LOOKUP_SR_REPORTING_TERM && categoryType !== commonConfig.LOOKUP_COURSE_SEQUENCE_TYPE && categoryType !== commonConfig.LOOKUP_EMPLOYMENT_STATUS && categoryType !== commonConfig.LOOKUP_SEPERATION_REASON && categoryType !== commonConfig.LOOKUP_COURSE_LEVEL_CHARACTERISTIC) {
    //     return dispatch => {
    //         dispatch(getLookupSucess(JSON.parse(storedLookups), { categoryType: categoryType }));
    //     }
    // } else {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify({
                component: commonConfig.COMPONENT_RESOURCE,
                endPoint: !!isStateSpecific ? commonConfig.COMPONENT_RESOURCE_GET_STATESPECIFIC_LOOKUPS : commonConfig.COMPONENT_RESOURCE_GET_LOOKUPS_MERGED,
                method: commonConfig.METHOD_TYPE_POST,
                postedData: { categoryType, stateCode, countryCode, customerId, freshData }
            })
        }).then(function (response) {
            const lookups = response.data;
            if (lookups.success) {
                dispatch(getLookupSucess(lookups.data, { categoryType: categoryType }));
            } else {
                dispatch(handleCommonApiFail(lookups.message));
            }
        }).catch(err => {
            dispatch(handleCommonApiFail(err));
        });
    };
    // }
};
export const commonStoreStateReset = (input) => {
    return {
        type: commonConfig.COMMON_STORE_RESET,
        responseData: input,
        title: input.name
    };
};
export const showNotify = (message, type) => {
    return {
        type: actionTypesShared.SHOW_NOTIFY,
        notifyMessage: message,
        notifyType: type
    };
};