import React from 'react';
import Validation from './validationComponent';
import BaseComponent from '../../shared/BaseComponent';
import i18n from '../../../i18n';
import Select from "react-select";
import { isEqual, filter, map, groupBy } from 'lodash';
class MarkersCommonSelect extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            optionArray: !!this.props.optArray && Array.isArray(this.props.optArray) && this.props.optArray.length !== 0 ? this.props.optArray.map((item) => ({
                value: item[props.hasOwnProperty('optionLabelValue') ? props.optionLabelValue : 'id'],
                label: item[props.hasOwnProperty('optionLabelTitle') ? props.optionLabelTitle : 'title']
            })) : []
        }
    }
    componentDidMount() {
        if (this.props.hasOwnProperty('value')) {
            this.getMultiSelectSelectedOption(this.props.value);
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.hasOwnProperty('value'))
            this.getMultiSelectSelectedOption(nextProps.value)
        if (nextProps.optArray && !isEqual(nextProps.optArray, this.props.optArray)) {
            this.generateSelectedOption()
        }
    }
    getMultiSelectSelectedOption = (selectedValue) => {
        if (this.props.hasOwnProperty('multi') && !!this.props.multi && !!selectedValue) {
            let selectedValueArray = Array.isArray(selectedValue) ? selectedValue : selectedValue.toString().split(",")
            selectedValueArray.map((data, index) => {
                selectedValueArray[index] = this.props.hasOwnProperty('valueTypeString') && !!this.props.valueTypeString ? data : parseInt(data, 10)
            })
            const selectedOption = filter(this.state.optionArray, (object) => { return selectedValueArray.indexOf(object.value) !== -1 });
            this.setState({ selectedOption: selectedOption.length !== 0 ? selectedOption : null })
        } else {
            this.setState({ selectedOption: null })
        }
    }
    generateSelectedOption = () => {
        this.setState((prevState, props) => {
            if (!!props.optArray && Array.isArray(props.optArray) && props.optArray.length !== 0) {
                const tempList = []
                props.optArray.forEach((item) => {
                    let tempObject = {
                        value: item[props.hasOwnProperty('optionLabelValue') ? props.optionLabelValue : 'id'],
                        label: item[props.hasOwnProperty('optionLabelTitle') ? props.optionLabelTitle : 'title']
                    }
                    if (!!props.isOptionGroup && !!props.groupIndexTitle)
                        tempObject = { ...tempObject, [props.groupIndexTitle]: item[props.groupIndexTitle] }
                    if (props.hasOwnProperty('skippedValues') && !!props.skippedValues && Array.isArray(props.skippedValues) && props.skippedValues.length !== 0) {
                        if (props.skippedValues.indexOf(tempObject.value) === -1)
                            tempList.push(tempObject)
                    } else
                        tempList.push(tempObject)
                })
                return { optionArray: tempList }
            } else {
                return { optionArray: [] }
            }
        })
    }
    handleChangeMultiDropDown = (selectedOption, event) => {
        let selectedValue = !!selectedOption && selectedOption.length !== 0 ?
            selectedOption.map((data) => {
                return (data.value)
            }) : []
        let selectedTitle = !!selectedOption && selectedOption.length !== 0 ?
            selectedOption.map((data) => {
                return (data.label)
            }) : []
        this.setState({ selectedOption }, () => {
            const target = {
                ...event,
                type: 'multiSelect',
                value: selectedValue.toString(),
                valueTitle: selectedTitle.toString(),
                valueArray: selectedValue
            }
            this.props.handleChange({ target }, this.props.title)
        });
    };
    render() {
        const { title, label, id, value, name, multi, disabled, isError, handleChange, handleKeyPress, isRequired, onlySelect, removeFirstOption, style, isRenderSelectAllOption, selectAllOptionTitle, isOptionGroup, groupIndexTitle } = this.props;
        const { optionArray, selectedOption } = this.state;
        let groupArray = []
        if (!!isOptionGroup)
            groupArray = groupBy(optionArray, function (obj) { return obj[groupIndexTitle] })

        let selectAttr = { name: name }
        if (this.props.hasOwnProperty('handleChange'))
            selectAttr = { ...selectAttr, onChange: this.props.hasOwnProperty('multi') && !!multi ? this.handleChangeMultiDropDown : (e) => handleChange(e, title) }
        if (this.props.hasOwnProperty('handleKeyPress'))
            selectAttr = { ...selectAttr, onKeyDown: (e) => handleKeyPress(e, name) }
        if (this.props.hasOwnProperty('id') && !!id)
            selectAttr = { ...selectAttr, id }
        if (this.props.hasOwnProperty('disabled') && !!disabled)
            selectAttr = { ...selectAttr, disabled }
        if (this.props.hasOwnProperty('multi') && !!multi) {
            selectAttr = { ...selectAttr, isMulti: true, options: optionArray, value: this.state.selectedOption }
        } else {
            if (this.props.hasOwnProperty('value')) {
                selectAttr = { ...selectAttr, value: (!!value && value !== NaN && value !== 0) ? value : "" }
            }
            if (this.props.hasOwnProperty('style') && !!style && Object.keys(style).length !== 0) {
                selectAttr = { ...selectAttr, style }
            }
        }
        if (this.props.hasOwnProperty('multi') && !!multi) {
            selectAttr = { ...selectAttr, isMulti: true, isDisabled: this.props.hasOwnProperty('disabled') && !!disabled ? true : false, options: optionArray, value: this.state.selectedOption }
            if (this.props.hasOwnProperty('firstOptionTitle') && !!this.props.firstOptionTitle) {
                selectAttr = { ...selectAttr, placeholder: this.props.firstOptionTitle }
            }
        }
        if (this.props.hasOwnProperty('isRenderSelectAllOption') && this.props.hasOwnProperty('multi') && Array.isArray(optionArray) && optionArray.length > 0 && !!isRenderSelectAllOption && !!multi && !isEqual(selectedOption, optionArray)) {
            let filteredOptionArray = Array.isArray(selectedOption) && selectedOption.length > 0 ? filter(optionArray, ({ value }) => map(selectedOption, 'value').indexOf(value) === -1) : optionArray;
            let additionalOptionAll = [{ label: this.props.hasOwnProperty('selectAllOptionTitle') && !!selectAllOptionTitle ? i18n.t(selectAllOptionTitle) : i18n.t("selectAll"), value: map(filteredOptionArray, 'value') }];
            selectAttr = { ...selectAttr, options: [...additionalOptionAll, ...optionArray] };
        }
        const firstOptionTitle = this.props.hasOwnProperty('firstOptionTitle') ? this.props.firstOptionTitle : i18n.t('select');
        const firstOptionValue = this.props.hasOwnProperty('firstOptionValue') ? this.props.firstOptionValue : '';
        const selectResult = <React.Fragment>
            {this.props.hasOwnProperty('multi') && !!multi
                ? <Select {...selectAttr} />
                : <select {...selectAttr} className={this.props.hasOwnProperty('selectClassName') ? this.props.selectClassName : 'custom-select'}>
                    {!!removeFirstOption ? null : <option value={firstOptionValue}>{firstOptionTitle}</option>}
                    {!!isOptionGroup ?
                        !!optionArray.length && optionArray.length !== 0 && Object.keys(groupArray).map((data,index) => {
                            return <optgroup label={data} key={index}>
                                {groupArray[data].map((item, index) => {
                                    return <option key={index} value={item.value}>{item.label}</option>
                                })}
                            </optgroup>
                        })
                        : !!optionArray.length && optionArray.length !== 0 && optionArray.map((item, index) => {
                            return <option key={index} value={item.value}>{item.label}</option>
                        })}
                </select>
            }
            {(isRequired) && <Validation data={{
                field: label,
                errorClass: name + '_error',
                value: !!selectAttr.value && value !== NaN && value !== 0 ? selectAttr.value : null,
                isError: isError,
                validationType: 'selectfield'
            }} returnValidation={this.functionValidation} />}
        </React.Fragment>
        return (
            !!onlySelect ?
                selectResult :
                <div className={this.props.hasOwnProperty('divClassName') ? this.props.divClassName : "form-group"}>
                    <label className={this.props.hasOwnProperty('labelClassName') ? this.props.labelClassName : "col-form-label"}>{label}{(this.props.hasOwnProperty('value') && isRequired) && <span> *</span>}</label>
                    {selectResult}
                </div>
        )
    }
}
export default MarkersCommonSelect;
