import i18n from '../../../i18n';
class markersBreadCrumb {
    returnContent(url) {
        const content = {
            '/school/addSchool/general':
                { 'name': i18n.t('SchGenInfo'), 'url': '/school/addSchool/general', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/assessment/progressReportCard':
                { 'name': i18n.t('publishDownloadProgresseReportCard'), 'url': '/assessment/progressReportCard', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/progressReportCard' }] },
            '/assessment/generateProgressReportCard':
                { 'name': i18n.t('generateProgressReportCard'), 'url': '/assessment/generateProgressReportCard', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/generateProgressReportCard' }] },
            '/school/viewSchoolGeneralInfo':
                { 'name': i18n.t('generalInformation'), 'url': '/school/viewSchoolGeneralInfo', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/updateSchoolAddressInfo':
                { 'name': i18n.t('addressInformation'), 'url': '/school/updateSchoolAddressInfo', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/updateSchoolPhoneEmail':
                { 'name': i18n.t('SCHL_PHN_EML'), 'url': '/school/updateSchoolPhoneEmail', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/updateSchoolDesignators':
                { 'name': i18n.t('SCHL_DESIGNATOR'), 'url': '/school/updateSchoolDesignators', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/updateSchoolIdentifier':
                { 'name': i18n.t('STUD_ADD_IDE'), 'url': '/school/updateSchoolIdentifier', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/updateSchoolAccreditations':
                { 'name': i18n.t('SCHL_ADD_ACC'), 'url': '/school/updateSchoolAccreditations', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/staff/staffCredentialsVerification':
                { 'name': i18n.t('STAF_CRED_VERIFI'), 'url': '/staff/staffCredentialsVerification', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffCredentialsVerificationDetails':
                { 'name': i18n.t('StafCredVerifi'), 'url': '/staff/staffCredentialsVerificationDetails', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/school/viewSchoolAdministration':
                { 'name': i18n.t('administration'), 'url': '/school/viewSchoolAdministration', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/district/allowAdminEditPreviousSession':
                { 'name': i18n.t('allowAdminToEditPreviousSessionData'), 'url': 'district/allowAdminEditPreviousSession', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/allowAdminEditPreviousSession' }] },
            '/school/viewSchoolLunchProgram':
                { 'name': i18n.t('lunchProgram'), 'url': '/school/viewSchoolLunchProgram', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/district/showSchoolList':
                { 'name': i18n.t('schoolInformation'), 'url': '/district/showSchoolList', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/showSchoolList' }] },
            '/school/schoolField':
                { 'name': i18n.t('SchCusField'), 'url': '/school/schoolField', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/schoolMarkingPeriods':
                { 'name': i18n.t('markingPeriods'), 'url': '/school/schoolMarkingPeriods', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/calendar':
                { 'name': i18n.t('calendar'), 'url': '/school/calendar', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/calendarDays':
                { 'name': i18n.t('calendarDays'), 'url': '/school/calendarDays', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/schoolPeriods':
                { 'name': i18n.t('SchPeriod'), 'url': '/school/schoolPeriods', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/schoolGradeLevels':
                { 'name': i18n.t('gradeLevel'), 'url': '/school/schoolGradeLevels', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/schoolSection':
                { 'name': i18n.t('SchSec'), 'url': '/school/schoolSection', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/schoolRole':
                { 'name': i18n.t('schoolRole'), 'url': '/school/schoolRole', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/gradeScaleLevel':
                { 'name': i18n.t('SCHL_GRSC_LVL'), 'url': '/school/gradeScaleLevel', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/gradeScale':
                { 'name': i18n.t('gradeScale'), 'url': '/school/gradeScale', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/allowMultipleStudentSchedule':
                { 'name': i18n.t('allowMultipleStudentSchedule'), 'url': '/school/allowMultipleStudentSchedule', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/allowMultipleStudentSchedule' }] },
            '/school/defineGPA':
                { 'name': i18n.t('SCHL_DEF_GPA'), 'url': '/school/defineGPA', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/schoolSession':
                { 'name': i18n.t('schoolSession'), 'url': '/school/schoolSession', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/stateReportingExecution':
                { 'name': i18n.t('StateReportExe'), 'url': '/school/stateReportingExecution', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/schoolRoom':
                { 'name': i18n.t('SchRoom'), 'url': '/school/schoolRoom', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/calendarCrisis':
                { 'name': i18n.t('SCHL_CALCRIS'), 'url': '/school/calendarCrisis', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/showSchoolDepartment':
                { 'name': i18n.t('SchDepart'), 'url': 'school/showSchoolDepartment', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/SchoolHolidayManagement':
                { 'name': i18n.t('SCHL_HOLMGMT'), 'url': '/school/SchoolHolidayManagement', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/language':
                { 'name': i18n.t('SCHL_LANG'), 'url': '/school/language', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/rollover':
                { 'name': i18n.t('rollover'), 'url': '/school/rollover', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/rollover' }] },
            '/school/stateReporting':
                { 'name': i18n.t('SchlStRepo'), 'url': '/school/stateReporting', 'parent': [{ 'name': i18n.t('schools'), 'url': '/school/stateReporting' }] },
            '/messages/addAnnouncement':
                { 'name': i18n.t('announcements'), 'url': '/messages/addAnnouncement', 'parent': [{ 'name': i18n.t('COMM_MAIN'), 'url': '#' }] },
            '/messages/viewAnnouncementList':
                { 'name': i18n.t('announcements'), 'url': '/messages/viewAnnouncementList', 'parent': [{ 'name': i18n.t('COMM_MAIN'), 'url': '#' }] },
            '/school/cohortInfo':
                { 'name': i18n.t('cohortInfo'), 'url': '/school/cohortInfo', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/student/viewStudent/studentStateReporting':
                { 'name': i18n.t('studentStateReporting'), 'url': '/student/viewStudent/studentStateReporting', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studentSpecialEducation':
                { 'name': i18n.t('specialEducationEvents'), 'url': '/student/viewStudent/specialEducationEvents', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studentSpecialEducationService':
                { 'name': i18n.t('studentSpecialEducationService'), 'url': '/student/viewStudent/studentSpecialEducationService', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studentDigital':
                { 'name': i18n.t('studentDigital'), 'url': '/student/viewStudent/studentDigital', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studentResStateReporting':
                { 'name': i18n.t('studentResidentialFacility'), 'url': '/student/viewStudent/studentResStateReporting', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studentSchLeaveStateReporting':
                { 'name': i18n.t('studentSchoolLeaver'), 'url': '/student/viewStudent/studentSchLeaveStateReporting', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studentGraduationStateReporting':
                { 'name': i18n.t('studentGraduationProgramStateReporting'), 'url': '/student/viewStudent/studentGraduationStateReporting', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/school/schoolLabel':
                { 'name': i18n.t('label'), 'url': '/school/schoolLabel', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/school/masterTeacherAssociation':
                { 'name': i18n.t('masterTeacherAssociation'), 'url': 'school/masterTeacherAssociation', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/masterTeacherAssociation' }] },
            '/student/viewStudent/overview':
                { 'name': i18n.t('studentDetails'), 'url': '/student/viewStudent/overview', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/addStudent/general':
                { 'name': i18n.t('StudGenInfo'), 'url': '/student/addStudent/general', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/accountAccess':
                { 'name': i18n.t('StudAccAccess'), 'url': '/student/viewStudent/accountAccess', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studyMaterial':
                { 'name': i18n.t('studyMaterial'), 'url': '/student/viewStudent/studyMaterial', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/addStudent/accountAccess':
                { 'name': i18n.t('StudAccAccess'), 'url': '/student/addStudent/accountAccess', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/identifier':
                { 'name': i18n.t('StuIdenti'), 'url': '/student/viewStudent/identifier', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/documentUpload':
                { 'name': i18n.t('StuDocUpload'), 'url': '/student/viewStudent/documentUpload', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/addStudent/miscellaneousStatus':
                { 'name': i18n.t('StuMiscStatus'), 'url': '/student/addStudent/miscellaneousStatus', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/miscellaneousStatus':
                { 'name': i18n.t('StuMiscStatus'), 'url': '/student/viewStudent/miscellaneousStatus', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/refferal':
                { 'name': i18n.t('StuRefe'), 'url': '/student/viewStudent/refferal', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/visa':
                { 'name': i18n.t('studentVisa'), 'url': '//student/viewStudent/visa', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/healthInfo':
                { 'name': i18n.t('studentHealthInformation'), 'url': '/student/viewStudent/healthInfo', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/disability':
                { 'name': i18n.t('StuDisab'), 'url': '/student/viewStudent/disability', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/guardian':
                { 'name': i18n.t('StuContact'), 'url': '/student/viewStudent/guardian', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/birthInfo':
                { 'name': i18n.t('StuBirthInfo'), 'url': '/student/viewStudent/birthInfo', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/survey':
                { 'name': i18n.t('survey'), 'url': '/student/viewStudent/survey', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/addStudent/survey':
                { 'name': i18n.t('survey'), 'url': '/student/addStudent/survey', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/feedback':
                { 'name': i18n.t('feedbck'), 'url': '/student/viewStudent/feedback', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/addStudent/feedback':
                { 'name': i18n.t('feedbck'), 'url': '/student/addStudent/feedback', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/immunizationInfo':
                { 'name': i18n.t('StuImmuInfo'), 'url': '/student/viewStudent/immunizationInfo', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/enrollmentEligibility':
                { 'name': i18n.t('StuEnrollEligib'), 'url': '/student/viewStudent/enrollmentEligibility', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/permissions':
                { 'name': i18n.t('StuPermission'), 'url': '/student/viewStudent/permissions', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/enrollmentInfo':
                { 'name': i18n.t('StuEnrollExitInfo'), 'url': '/student/viewStudent/enrollmentInfo', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/medicalEmergency':
                { 'name': i18n.t('StuMediEmergency'), 'url': '/student/viewStudent/medicalEmergency', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/cohortInfo':
                { 'name': i18n.t('StudentCohortInfo'), 'url': '/student/viewStudent/cohortInfo', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/stateReporting':
                { 'name': i18n.t('stateReporting'), 'url': '/student/viewStudent/stateReporting', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/giftedProgram':
                { 'name': i18n.t('StuGiftedProgram'), 'url': '/student/viewStudent/giftedProgram', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/general':
                { 'name': i18n.t('StudGenInfo'), 'url': '/student/viewStudent/general', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/addStudent/healthInfo':
                { 'name': i18n.t('studentHealthInformation'), 'url': '/student/addStudent/healthInfo', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/updateStudent/general':
                { 'name': i18n.t('StudGenInfo'), 'url': '/student/updateStudent/general', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentMedicalEmergency':
                { 'name': i18n.t('STUD_MEDEMG'), 'url': '/student/studentMedicalEmergency', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentExitStatus':
                { 'name': i18n.t('StuExitStat'), 'url': '/student/studentExitStatus', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentDocumentsUpload':
                { 'name': i18n.t('stdDocumentUpload'), 'url': '/student/studentDocumentsUpload', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentDocumentVerification':
                { 'name': i18n.t('documentVerification'), 'url': '/student/studentDocumentVerification', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentDocumentVerificationDetails':
                { 'name': i18n.t('documentVerification'), 'url': '/student/studentDocumentVerificationDetails', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentHealthScreening':
                { 'name': i18n.t('StuHealthScreening'), 'url': '/student/studentHealthScreening', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentHealthScreeningDetails':
                { 'name': i18n.t('StuHealthScreening'), 'url': '/student/studentHealthScreeningDetails', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/showStudentList':
                { 'name': i18n.t('StuInfo'), 'url': '/student/showStudentList', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/showStudentListRoute':
                { 'name': i18n.t('StuInfo'), 'url': '/student/showStudentListRoute', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentMassAssign':
                { 'name': i18n.t('StuMassAssign'), 'url': '/student/studentMassAssign', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentReEnroll':
                { 'name': i18n.t('StuReenroll'), 'url': '/student/studentReEnroll', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentEnrollmentCodes':
                { 'name': i18n.t('StuEnrollCodes'), 'url': '/student/studentEnrollmentCodes', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentPromotion':
                { 'name': i18n.t('StuPromot'), 'url': '/student/studentPromotion', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentCustomField':
                { 'name': i18n.t('StuCustField'), 'url': '/student/studentCustomField', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/noShow':
                { 'name': i18n.t('STUD_NO_SHOW'), 'url': '/student/noShow', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/endOfYearStatus':
                { 'name': i18n.t('endOfYearStatus'), 'url': '/student/endOfYearStatus', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/TrexStateReporting':
                { 'name': i18n.t('trex'), 'url': '/student/TrexStateReporting', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentHealthVisit':
                { 'name': i18n.t('healthVisit'), 'url': '/student/studentHealthVisit', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/student/searchStudentHealthVisit':
                { 'name': i18n.t('healthVisit'), 'url': '/student/searchStudentHealthVisit', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/student/healthVisitAddAdminister':
                { 'name': i18n.t('healthVisit'), 'url': '/student/healthVisitAddAdminister', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/student/healthAddVisit':
                { 'name': i18n.t('healthVisit'), 'url': '/student/healthAddVisit', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/student/studentHealthVisitAddMedication':
                { 'name': i18n.t('healthVisit'), 'url': '/student/studentHealthVisitAddMedication', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/student/studentEnrollmentAdaEligibility':
                { 'name': i18n.t('enrollmentAdaEligibility'), 'url': '/student/studentEnrollmentAdaEligibility', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/mailingLabels':
                { 'name': i18n.t('mailingLabels'), 'url': '/student/mailingLabels', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/student/enrollmentDropReport':
                { 'name': i18n.t('enrollmentDropReport'), 'url': '/student/enrollmentDropReport', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/student/viewStudent/studentGraduationPlan':
                { 'name': i18n.t('STUD_GRAD_PLN'), 'url': '/student/viewStudent/studentGraduationPlan', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/manageSpecialProgram':
                { 'name': i18n.t('studentSpecialProgram'), 'url': '/student/viewStudent/manageSpecialProgram', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studentRestraintStateReporting':
                { 'name': i18n.t('studentRestraint'), 'url': '/student/viewStudent/studentRestraintStateReporting', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studentDisabilityLangStateReporting':
                { 'name': i18n.t('studentDisabilityLangAcquisition'), 'url': '/student/viewStudent/studentDisabilityLangStateReporting', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/studentExtendedYearStateReporting':
                { 'name': i18n.t('studentExtendedSchoolYearServices'), 'url': '/student/viewStudent/studentExtendedYearStateReporting', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            'student/viewStudent/studyMaterial':
                { 'name': i18n.t('StuStudMat'), 'url': '/student/viewStudent/studyMaterial', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/asyleeRefugee':
                { 'name': i18n.t('asyleeRefugee'), 'url': '/student/asyleeRefugee', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/careerAndTechnicalEducation':
                { 'name': i18n.t('careerAndTechnicalEducation'), 'url': '/student/careerAndTechnicalEducation', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/atRisk':
                { 'name': i18n.t('atRisk'), 'url': '/student/atRisk', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/disability':
                { 'name': i18n.t('disability'), 'url': '/student/disability', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/displaced':
                { 'name': i18n.t('displaced'), 'url': '/student/displaced', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/dyslexia':
                { 'name': i18n.t('dyslexia'), 'url': '/student/dyslexia', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/earlyChildhoodNewMexico':
                { 'name': i18n.t('earlyChildhoodNewMexico'), 'url': '/student/earlyChildhoodNewMexico', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/economicDisadvantagedFoodServiceNewMexico':
                { 'name': i18n.t('economicDisadvantagedFoodServiceNewMexico'), 'url': '/student/economicDisadvantagedFoodServiceNewMexico', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/englishProficiencyNewMexico':
                { 'name': i18n.t('englishProficiencyNewMexico'), 'url': '/student/englishProficiencyNewMexico', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/federalProgramsNewMexico':
                { 'name': i18n.t('federalProgramsNewMexico'), 'url': '/student/federalProgramsNewMexico', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/fosterCare':
                { 'name': i18n.t('fosterCare'), 'url': '/student/fosterCare', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/gifted':
                { 'name': i18n.t('gifted'), 'url': '/student/gifted', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/homeless':
                { 'name': i18n.t('homeless'), 'url': '/student/homeless', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/immigrant':
                { 'name': i18n.t('immigrant'), 'url': '/student/immigrant', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/limitedEnglishProficiency':
                { 'name': i18n.t('limitedEnglishProficiency'), 'url': '/student/limitedEnglishProficiency', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/migrant':
                { 'name': i18n.t('migrant'), 'url': '/student/migrant', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/migrantNewMexico':
                { 'name': i18n.t('migrantNewMexico'), 'url': '/student/migrantNewMexico', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/militaryConnected':
                { 'name': i18n.t('militaryConnected'), 'url': '/student/militaryConnected', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/pregnancyRelatedServices':
                { 'name': i18n.t('pregnancyRelatedServices'), 'url': '/student/pregnancyRelatedServices', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/programsFactOtherNewMexico':
                { 'name': i18n.t('programsFactOtherNewMexico'), 'url': '/student/programsFactOtherNewMexico', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/section504':
                { 'name': i18n.t('section504'), 'url': '/student/section504', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/specialEducationNewMexico':
                { 'name': i18n.t('specialEducationNewMexico'), 'url': '/student/specialEducationNewMexico', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/titlePartA':
                { 'name': i18n.t('titlePartA'), 'url': '/student/titlePartA', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/viewStudent/specialProgram/truancy':
                { 'name': i18n.t('truancy'), 'url': '/student/truancy', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/specialProgramGeneralListing':
                { 'name': i18n.t('specialProgramGeneralListing'), 'url': '/student/specialProgramGeneralListing', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/student/specialProgramStudentListing':
                { 'name': i18n.t('specialProgramStudentListing'), 'url': '/student/specialProgramStudentListing', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/student/studentWithdrawalForm':
                { 'name': i18n.t('withdrawalForm'), 'url': '/student/studentWithdrawalForm', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '#' }] },
            '/staff/addStaff/demographic':
                { 'name': i18n.t('StaffDemoInfo'), 'url': '/staff/addStaff/demographic', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/addStaff/schoolRoleAssignment':
                { 'name': i18n.t('StaffRoleAssign'), 'url': '/staff/addStaff/schoolRoleAssignment', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/accountAccess':
                { 'name': 'Staff Account Access', 'url': '/staff/viewStaff/viewStaffAccountAccess', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/addStaff/accountAccess':
                { 'name': 'Staff Account Access', 'url': '/staff/addStaff/addstaffAccountAccess', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffStateReporting':
                { 'name': i18n.t('STF_INF_SR'), 'url': '/staff/staffStateReporting', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffResStateReporting':
                { 'name': i18n.t('STF_RES_SR'), 'url': '/staff/staffResStateReporting', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/addStaff/employmentInfo':
                { 'name': i18n.t('StaffEmpInfo'), 'url': '/staff/addStaff/employmentInfo', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/addStaff/staffContact':
                { 'name': i18n.t('StafContact'), 'url': '/staff/addStaff/staffContact', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/addStaff/staffCertification':
                { 'name': i18n.t('staffCertification'), 'url': '/staff/addStaff/staffCertification', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/addStaff/documentVerification':
                { 'name': i18n.t('StaffDocuVerifi'), 'url': '/staff/addStaff/documentVerification', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/addStaff/visa':
                { 'name': i18n.t('staffVisa'), 'url': '/staff/addStaff/visa', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/addStaff/teachingExperience':
                { 'name': i18n.t('experience'), 'url': '/staff/addStaff/teachingExperience', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/addStaff/staffCredential':
                { 'name': i18n.t('StafCredential'), 'url': '/staff/addStaff/staffCredential', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/demographic':
                { 'name': i18n.t('StaffDemoInfo'), 'url': '/staff/viewStaff/demographic', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/schoolRoleAssignment':
                { 'name': i18n.t('StaffSchRoleAssign'), 'url': '/staff/viewStaff/schoolRoleAssignment', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/employmentInfo':
                { 'name': i18n.t('StafEmpInfo'), 'url': '/staff/viewStaff/employmentInfo', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/staffContact':
                { 'name': i18n.t('StafContact'), 'url': '/staff/viewStaff/staffContact', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/qualificationCertificates':
                { 'name': i18n.t('StaffEdu'), 'url': '/staff/viewStaff/qualificationCertificates', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/personalDocumentVerification':
                { 'name': i18n.t('staffPerDocuVerifi'), 'url': '/staff/viewStaff/personalDocumentVerification', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/viewStaffAuthorization':
                { 'name': i18n.t('staffInfo'), 'url': '/staff/viewStaff/viewStaffAuthorization', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/viewStaffMiscSkills':
                { 'name': i18n.t('staffMiscSkills'), 'url': '/staff/viewStaff/viewStaffMiscSkills', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/updateStaff/updateStaffMiscSkills':
                { 'name': i18n.t('staffMiscSkills'), 'url': '/staff/updateStaff/updateStaffMiscSkills', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/updateStaff/updateStaffAuthorization':
                { 'name': i18n.t('addNewStaff'), 'url': '/staff/updateStaff/updateStaffAuthorization', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/visa':
                { 'name': i18n.t('staffVisa'), 'url': '/staff/viewStaff/visa', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/teachingExperience':
                { 'name': i18n.t('experience'), 'url': '/staff/viewStaff/teachingExperience', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/staffCredential':
                { 'name': i18n.t('StafCredential'), 'url': '/staff/viewStaff/staffCredential', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/identifier':
                { 'name': i18n.t('staffIdentifier'), 'url': '/staff/viewStaff/identifier', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/viewStaff/staffPersonalCredential':
                { 'name': i18n.t('staffPersoCred'), 'url': '/staff/viewStaff/staffPersonalCredential', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/showStaffList':
                { 'name': i18n.t('stafInfo'), 'url': '/staff/showStaffList', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/showStaffListRoute':
                { 'name': i18n.t('stafInfo'), 'url': '/staff/showStaffListRoute', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffCustomFields':
                { 'name': i18n.t('STAF_FIELD'), 'url': '/staff/staffCustomFields', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffMassAssign':
                { 'name': i18n.t('StaffMassAssign'), 'url': '/staff/staffMassAssign', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffJobs':
                { 'name': i18n.t('STAF_JOB'), 'url': '/staff/staffJobs', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffJobCategory':
                { 'name': i18n.t('STAF_JOB_CATEGORY'), 'url': '/staff/staffJobCategory', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffCalender':
                { 'name': i18n.t('STAF_CALENDER'), 'url': '/staff/staffCalender', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffUpskillAssistance':
                { 'name': i18n.t('staffUpskillAssistance'), 'url': '/staff/staffUpskillAssistance', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/jobCertification':
                { 'name': i18n.t('STAF_JOB_CERTIF'), 'url': '/staff/jobCertification', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/leaveRequests':
                { 'name': i18n.t('StaffLeaveReq'), 'url': '/staff/leaveRequests', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/credentials':
                { 'name': i18n.t('StaffCreden'), 'url': '/staff/credentials', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/leaveRequestDetails':
                { 'name': i18n.t('staffLeaveReqDetails'), 'url': '/staff/leaveRequestDetails', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffMyLeaves':
                { 'name': i18n.t('staffLeave'), 'url': '/staff/staffMyLeaves', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/staffMyLeaveDetails':
                { 'name': i18n.t('staffLeaveDetails'), 'url': '/staff/staffMyLeaveDetails', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/certificateVerification':
                { 'name': i18n.t('education'), 'url': '/staff/certificateVerification', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/certificateVerificationDetails':
                { 'name': i18n.t('education'), 'url': '/staff/certificateVerificationDetails', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/documentVerification':
                { 'name': i18n.t('staffPerDocuVerifi'), 'url': '/staff/documentVerification', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/documentVerificationDetails':
                { 'name': i18n.t('staffPersoDocuVerifiDetails'), 'url': '/staff/documentVerificationDetails', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/updateStaff/demographic':
                { 'name': i18n.t('StaffDemoInfo'), 'url': '/staff/updateStaff/demographic', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/updateStaff/employmentInfo':
                { 'name': i18n.t('StaffEmpInfo'), 'url': '/staff/updateStaff/employmentInfo', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/holidayListPublication':
                { 'name': i18n.t('STAF_HOLP'), 'url': '/staff/holidayListPublication', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/staff/holidayListPublicationView':
                { 'name': i18n.t('StafHolp'), 'url': '/staff/holidayListPublicationView', 'parent': [{ 'name': i18n.t('staff'), 'url': '/staff/showStaffList' }] },
            '/course/coursemanager':
                { 'name': i18n.t('COUR_MGR'), 'url': '/course/coursemanager', 'parent': [{ 'name': i18n.t('course'), 'url': '/course/coursemanager' }] },
            '/course/programManager':
                { 'name': i18n.t('programManager'), 'url': '/course/programManager', 'parent': [{ 'name': i18n.t('course'), 'url': '/course/coursemanager' }] },
            '/course/studentCourseRequests':
                { 'name': i18n.t('SHDL_STCC'), 'url': '/course/studentCourseRequests', 'parent': [{ 'name': i18n.t('course'), 'url': '/course/coursemanager' }] },
            '/course/adminCourseRequests':
                { 'name': i18n.t('SHDL_ADCC'), 'url': '/course/adminCourseRequests', 'parent': [{ 'name': i18n.t('course'), 'url': '/course/coursemanager' }] },
            '/scheduling/individualStudentSchedule':
                { 'name': i18n.t('SHDL_ISTS'), 'url': '/scheduling/individualScheduleStudentList', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/massStudentSchedule' }] },
            '/scheduling/individualScheduleStudentDetails':
                { 'name': i18n.t('SHDL_ISTS'), 'url': '/scheduling/individualScheduleStudentDetails', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '#' }] },
            '/scheduling/teacherReassignment':
                { 'name': i18n.t('SHDL_TERA'), 'url': '/scheduling/teacherReassignment', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/massStudentSchedule' }] },
            '/scheduling/massStudentDrop':
                { 'name': i18n.t('SHDL_MASD'), 'url': '/scheduling/massStudentDrop', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/massStudentSchedule' }] },
            '/scheduling/requestApproval':
                { 'name': i18n.t('SchedReqApp'), 'url': '/scheduling/requestApproval', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/massStudentSchedule' }] },
            '/scheduling/studentCourseRequests':
                { 'name': i18n.t('SHDL_STCC'), 'url': '/scheduling/studentCourseRequests', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '#' }] },
            '/scheduling/adminCourseRequests':
                { 'name': i18n.t('adminCourseRequests'), 'url': '/scheduling/adminCourseRequests', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/adminCourseRequests' }] },
            '/scheduling/courseSectionWiseAllocation':
                { 'name': i18n.t('ShdlCswa'), 'url': '/scheduling/courseSectionWiseAllocation', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/courseSectionWiseAllocation' }] },
            '/scheduling/autoSchedulerQueueAndProcess':
                { 'name': i18n.t('ShdlSeap'), 'url': '/scheduling/sectionAllocationProcess', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/sectionAllocationProcess' }] },
            '/scheduling/studentWiseAllocation':
                { 'name': i18n.t('studentWiseAllocation'), 'url': 'scheduling/studentWiseAllocation', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/massStudentSchedule' }] },
            '/scheduling/allocateCourseSection':
                { 'name': i18n.t('allocateCourseSection'), 'url': 'scheduling/allocateCourseSection', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/massStudentSchedule' }] },
            '/scheduling/massStudentSchedule':
                { 'name': i18n.t('massStudentSchedule'), 'url': 'scheduling/massStudentSchedule', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/studentMassSchedule' }] },
            '/scheduling/studentScheduler':
                { 'name': i18n.t('studentScheduler'), 'url': 'scheduling/studentScheduler', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/studentMassSchedule' }] },
            '/scheduling/studentSchedulerResults':
                { 'name': i18n.t('studentScheduler'), 'url': 'scheduling/studentSchedulerResults', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/studentMassSchedule' }] },
            '/scheduling/studentSchedulerUnscheduledCourses':
                { 'name': i18n.t('studentScheduler'), 'url': 'scheduling/studentSchedulerUnscheduledCourses', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/studentMassSchedule' }] },
            '/scheduling/studentSchedulerUnscheduledRequests':
                { 'name': i18n.t('studentScheduler'), 'url': 'scheduling/studentSchedulerUnscheduledRequestsComponent', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/studentMassSchedule' }] },
            '/scheduling/studentSchedulerResultsStudentDetails':
                { 'name': i18n.t('studentScheduler'), 'url': 'scheduling/studentSchedulerResultsStudentDetails', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/studentMassSchedule' }] },
            '/scheduling/studentsMissingCourseRequestsOrSchedule':
                { 'name': i18n.t('studentsMissingCourseRequestsOrSchedule'), 'url': 'scheduling/studentsMissingCourseRequestsOrSchedule', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/studentMassSchedule' }] },
            '/assessment/gradeListing':
                { 'name': i18n.t('gradeListing'), 'url': '/assessment/gradeListing', 'parent': [{ 'name': i18n.t('ASMT_MAIN'), 'url': '/assessment/gradeListing' }] },
            '/scheduling/studentScheduleReport':
                { 'name': i18n.t('studentSchedule'), 'url': 'scheduling/studentScheduleReport', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/studentMassSchedule' }] },
            '/scheduling/masterScheduleGrid':
                { 'name': i18n.t('masterScheduleGrid'), 'url': 'scheduling/masterScheduleGrid', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/studentMassSchedule' }] },
            '/attendance/teacherMissingAttendance':
                { 'name': i18n.t('teacherMissingAttendance'), 'url': '/attendance/teacherMissingAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/takeTeacherAttendance':
                { 'name': i18n.t('teacherMissingAttendance'), 'url': '/attendance/takeTeacherAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/dailyAttendanceStaff':
                { 'name': i18n.t('staffDailyAttendance'), 'url': '/attendance/dailyAttendanceStaff', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/dailyAttendanceStudent':
                { 'name': i18n.t('studentDailyAttendance'), 'url': '/attendance/dailyAttendanceStudent', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/takeAttendance':
                { 'name': i18n.t('TRAN_TAATD'), 'url': '/attendance/takeAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/missingAttendance':
                { 'name': i18n.t('DshbMissAtt'), 'url': '/attendance/missingAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/takeAttendanceTeacherView':
                { 'name': i18n.t('DshbMissAtt'), 'url': '/attendance/takeAttendanceTeacherView', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/teacherAttendance':
                { 'name': i18n.t('ATTD_TEAT'), 'url': '/attendance/teacherAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/studentMissingAttendance':
                { 'name': i18n.t('ATTD_VSMA'), 'url': '/attendance/studentMissingAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/adminMissingAttendance':
                { 'name': i18n.t('AttdVsma'), 'url': '/attendance/adminMissingAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/missingAttendance' }] },
            '/attendance/adminMissingAttendanceTakeAttendance':
                { 'name': i18n.t('TeaAtt'), 'url': '/attendance/adminMissingAttendanceTakeAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/adminMissingAttendanceTakeAttendance' }] },
            '/attendance/eventAttendance':
                { 'name': i18n.t('ATTD_EVAT'), 'url': '/attendance/eventAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/eventAttendance' }] },
            '/attendance/adminMissingAttendanceTeacherList':
                { 'name': i18n.t('TakeStuAtt'), 'url': '/attendance/adminMissingAttendanceTeacherList', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/adminMissingAttendanceTeacherList' }] },
            '/attendance/studentSectionAttendance':
                { 'name': i18n.t('ATTD_STAT'), 'url': '/attendance/studentSectionAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/studentAttendanceAdminView' }] },
            '/attendance/takeStudentSectionAttendanceAdminView':
                { 'name': i18n.t('ATTD_STAT'), 'url': '/attendance/takeStudentSectionAttendanceAdminView', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/studentAttendanceAdminView' }] },
            '/attendance/viewAttendance':
                { 'name': i18n.t('AttdViAtt'), 'url': '/attendance/viewAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/viewAttendance' }] },
            '/attendance/viewStudentAttendance':
                { 'name': i18n.t('ATTD_VISA'), 'url': '/attendance/viewStudentAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/viewStudentAttendance' }] },
            '/attendance/viewTeacherAttendance':
                { 'name': i18n.t('viewStaffAttendance'), 'url': 'attendance/viewTeacherAttendance', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/viewStudentAttendance' }] },
            '/attendance/viewStaffAttendance':
                { 'name': i18n.t('viewStaffAttendance'), 'url': 'attendance/viewStaffAttendance', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/viewStaffAttendance' }] },
            '/attendance/viewStaffAttendanceYearView':
                { 'name': i18n.t('viewStaffAttendance'), 'url': 'attendance/viewStaffAttendanceYearView', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/viewStaffAttendanceYearView' }] },
            '/attendance/examAttendance':
                { 'name': i18n.t('examAttendance'), 'url': 'attendance/examAttendance', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/examAttendance' }] },
            '/attendance/examAttendanceAdmin':
                { 'name': i18n.t('studentExamAttendance'), 'url': 'attendance/examAttendanceAdmin', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/examAttendance' }] },
            '/attendance/examAttendanceTakeAttendance':
                { 'name': i18n.t('examAttendance'), 'url': 'attendance/examAttendanceTakeAttendance', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/examAttendance' }] },
            '/attendance/massStudentAttendance':
                { 'name': i18n.t('massStudentAttendance'), 'url': '/staff/massStudentAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/staffMyAttendancePeriodView' }] },
            '/staff/myAttendance':
                { 'name': i18n.t('AttdStfAttm'), 'url': '/staff/myAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/staffMyAttendancePeriodView' }] },
            '/attendance/studentPeriodwiseAttendance':
                { 'name': i18n.t('ATTD_STUD_PERIOD'), 'url': 'attendance/studentPeriodwiseAttendance', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/viewStudentAttendance' }] },
            '/attendance/studentAttendanceMonthView':
                { 'name': i18n.t('ATTD_STUD_PERIOD'), 'url': 'attendance/studentAttendanceMonthView', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/viewStudentAttendance' }] },
            '/reports/adHocReporting':
                { 'name': i18n.t('adHocReporting'), 'url': 'reports/adHocReporting', 'parent': [{ 'name': i18n.t('reports'), 'url': '/reports/adHocReporting' }] },
            '/discipline/reportIncident':
                { 'name': i18n.t('MenuReportIncid'), 'url': 'discipline/reportIncident', 'parent': [{ 'name': i18n.t('DISC_MAIN'), 'url': '/discipline/reportIncident' }] },
            '/discipline/studentDiscipline':
                { 'name': i18n.t('DISC_STDI'), 'url': 'discipline/studentDiscipline', 'parent': [{ 'name': i18n.t('DISC_MAIN'), 'url': '/discipline/studentDiscipline' }] },
            '/discipline/manageIncident':
                { 'name': i18n.t('DISC_MANI'), 'url': 'discipline/manageIncident', 'parent': [{ 'name': i18n.t('DISC_MAIN'), 'url': '/discipline/manageIncident' }] },
            '/discipline/incidentDetails':
                { 'name': i18n.t('incidentDetails'), 'url': 'discipline/incidentDetails', 'parent': [{ 'name': i18n.t('DISCI_MANI'), 'url': '/discipline/manageIncident' }] },
            '/discipline/incidentApproval':
                { 'name': i18n.t('DISC_INAP'), 'url': 'discipline/incidentApproval', 'parent': [{ 'name': i18n.t('DISC_MAIN'), 'url': '/discipline/manageIncident' }] },
            '/assessment/assessmentGroup':
                { 'name': i18n.t('assessmentGroup'), 'url': 'assessment/assessmentGroup', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assessmentInfo':
                { 'name': i18n.t('assessmentGroup'), 'url': 'assessment/assessmentInfo', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assessmentInfoDetails':
                { 'name': i18n.t('assessmentGroup'), 'url': 'assessment/assessmentInfoDetails', 'parent': [{ 'name': i18n.t('assessment'), 'url': 'assessment/assessmentGroup' }] },
            '/assessment/assessmentSession':
                { 'name': i18n.t('assessmentSession'), 'url': 'assessment/assessmentSession', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/assessmentSession' }] },
            '/assessment/assessmentAdministration':
                { 'name': i18n.t('assessmentAdministration'), 'url': 'assessment/assessmentAdministration', 'parent': [{ 'name': i18n.t('assessment'), 'url': 'assessment/assessmentAdministration' }] },
            '/assessment/assessmentAccomodation':
                { 'name': i18n.t('assessmentAccommodation'), 'url': 'assessment/assessmentAccomodation', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assessmentSessionExam':
                { 'name': i18n.t('assessmentSessionExam'), 'url': 'assessment/assessmentSessionExam', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/assessmentSessionExam' }] },
            '/assessment/assessmentAssets':
                { 'name': i18n.t('AssesmentAssets'), 'url': 'assessment/assessmentAssets', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/assessmentSessionExam' }] },
            '/assessment/adminAssessmentRegistration':
                { 'name': i18n.t('assessmentRegistration'), 'url': 'assessment/adminAssessmentRegistration', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/adminAssessmentRegistration' }] },
            '/assessment/assessmentResult':
                { 'name': i18n.t('result'), 'url': 'assessment/assessmentResult', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assessmentEndOfYearSummary':
                { 'name': i18n.t('EndOfYearAssesmSumma'), 'url': 'assessment/assessmentEndOfYearSummary', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/transcript':
                { 'name': i18n.t('TransC'), 'url': 'assessment/transcript', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/transcript' }] },
            '/assessment/viewTranscript':
                { 'name': i18n.t('ViewTransC'), 'url': 'assessment/viewTranscript', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/viewTranscript' }] },
            '/assessment/publishTranscript':
                { 'name': i18n.t('PubliTransC'), 'url': 'assessment/publishTranscript', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/publishTranscript' }] },
            '/assessment/reportCard':
                { 'name': i18n.t('generateReportCard'), 'url': 'assessment/reportCard', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/gradebookReport':
                { 'name': i18n.t('gradebookReport'), 'url': '/assessment/gradebookReport', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/gradebookReport' }] },
            '/assessment/viewReportCard':
                { 'name': i18n.t('viewReportCard'), 'url': 'assessment/viewReportCard', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/publishProgressReportCard':
                { 'name': i18n.t('publishProgressReportCard'), 'url': 'assessment/publishProgressReportCard', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/publishReportCard':
                { 'name': i18n.t('publishReportCard'), 'url': 'assessment/publishReportCard', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/gradebook':
                { 'name': i18n.t('gradeBook'), 'url': 'assessment/gradebook', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/viewSectionGrading':
                { 'name': i18n.t('sectionGrading'), 'url': 'assessment/viewSectionGrading', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/updateSectionGrading':
                { 'name': i18n.t('sectionGrading'), 'url': 'assessment/updateSectionGrading', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/sectionGradingAdminView':
                { 'name': i18n.t('sectionGrading'), 'url': 'assessment/sectionGradingAdminView', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/viewProgressGrades':
                { 'name': i18n.t('progressGrades'), 'url': 'assessment/viewProgressGrades', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/updateProgressGrades':
                { 'name': i18n.t('progressGrades'), 'url': 'assessment/updateProgressGrades', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/progressGradeAdminView':
                { 'name': i18n.t('progressGrades'), 'url': 'assessment/progressGradeAdminView', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assignmentCategory':
                { 'name': i18n.t('assignmentCategory'), 'url': 'assessment/assignmentCategory', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assessmentRubric':
                { 'name': i18n.t('assessmentRubric'), 'url': 'assessment/assessmentRubric', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentRubric' }] },
            '/assessment/assignmentDetails':
                { 'name': i18n.t('assignment'), 'url': 'assessment/assignmentDetails', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assignmentBank':
                { 'name': i18n.t('assignmentBank'), 'url': 'assessment/assignmentBank', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assignmentGroup':
                { 'name': i18n.t('assignmentBank'), 'url': 'assessment/assignmentGroup', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assignmentGroup' }] },
            '/assessment/assignmentItem':
                { 'name': i18n.t('assignmentBank'), 'url': 'assessment/assignmentItem', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assignmentItem' }] },
            '/assessment/assignmentItemsChild':
                { 'name': i18n.t('assignmentBank'), 'url': 'assessment/assignmentItemsChild', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assignmentItemsChild' }] },
            '/assessment/calculateRank':
                { 'name': i18n.t('calculateRank'), 'url': 'assessment/calculateRank', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/calculateRankResult':
                { 'name': i18n.t('calculateRank'), 'url': 'assessment/calculateRankResult', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/maintainRank':
                { 'name': i18n.t('maintainRank'), 'url': 'assessment/maintainRank', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/maintainRankResult':
                { 'name': i18n.t('maintainRank'), 'url': 'assessment/maintainRankResult', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/studentGPARank':
                { 'name': i18n.t('studentGpaRank'), 'url': 'assessment/studentGPARank', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/studentGpaRankDetails':
                { 'name': i18n.t('studentGpaRank'), 'url': 'assessment/studentGpaRankDetails', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/transcriptMassDelete':
                { 'name': i18n.t('transcriptMassDelete'), 'url': 'assessment/transcriptMassDelete', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/transcriptGrades':
                { 'name': i18n.t('transcriptGrades'), 'url': 'assessment/transcriptGrades', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/studentTranscript':
                { 'name': i18n.t('studentTranscript'), 'url': 'assessment/studentTranscript', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/studentTranscriptDetails':
                { 'name': i18n.t('studentTranscript'), 'url': 'assessment/studentTranscriptDetails', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/calculateGpa':
                { 'name': i18n.t('calGpa'), 'url': 'assessment/calculateGpa', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/gpaProcessGpaResults':
                { 'name': i18n.t('calGpa'), 'url': 'assessment/gpaProcessGpaResults', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assignmentActivity':
                { 'name': i18n.t('assignmentActivity'), 'url': 'assessment/assignmentActivity', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGroup' }] },
            '/assessment/assessmentGrading':
                { 'name': i18n.t('assessmentGrading'), 'url': 'assessment/assessmentGrading', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGrading' }] },
            '/assessment/assessmentGradingTeacherDetails':
                { 'name': i18n.t('assessmentGrading'), 'url': 'assessment/assessmentGradingTeacherDetails', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assessmentGradingTeacherDetails' }] },
            '/assessment/assignments':
                { 'name': i18n.t('assignments'), 'url': 'assessment/assignments', 'parent': [{ 'name': i18n.t('ASMT_ASMT'), 'url': '/assessment/assignments' }] },
            '/system/managePerson':
                { 'name': i18n.t('manageContactsDesignator'), 'url': 'system/managePerson', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/managePerson' }] },
            '/system/roleBasedAccessControl':
                { 'name': i18n.t('roleBasedAccessControl'), 'url': 'system/roleBasedAccessControl', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/roleBasedAccessControl' }] },
            '/system/lmsSync':
                { 'name': i18n.t('syncLmsData'), 'url': 'system/lmsSync', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/roleBasedAccessControl' }] },
            '/system/lmsSetup':
                { 'name': i18n.t('lmsSetup'), 'url': 'system/lmsSetup', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/lmsSetup' }] },
            '/system/permissions':
                { 'name': i18n.t('permissions'), 'url': 'system/permissions', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/permissions' }] },
            '/system/rollover':
                { 'name': i18n.t('rollover'), 'url': 'system/rollover', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/rollover' }] },
            '/system/uploadReportCardTemplate':
                { 'name': i18n.t('uploadTemplate'), 'url': 'system/uploadReportCardTemplate', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/uploadReportCardTemplate' }] },
            '/system/dayCode':
                { 'name': i18n.t('dayCode'), 'url': 'system/dayCode', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/uploadReportCardTemplate' }] },
            '/system/systemMaintenanceMode':
                { 'name': i18n.t('systemMaintenanceMode'), 'url': 'system/systemMaintenanceMode', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/systemMaintenanceMode' }] },
            '/district/setPasswordPattern':
                { 'name': i18n.t('setPasswordPattern'), 'url': 'district/setPasswordPattern', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/setPasswordPattern' }] },
            '/system/defaultDateFormat':
                { 'name': i18n.t('defaultDateFormat'), 'url': 'system/defaultDateFormat', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/defaultDateFormat' }] },
            '/system/uploadProfileImage':
                { 'name': i18n.t('uploadProfileImage'), 'url': 'system/uploadProfileImage', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/uploadProfileImage' }] },
            '/messages/sentMessages':
                { 'name': i18n.t('messages'), 'url': 'messages/sentMessages', 'parent': [{ 'name': i18n.t('COMM_MAIN'), 'url': '#' }] },
            '/messages/receivedMessages':
                { 'name': i18n.t('receivedMessages'), 'url': 'messages/receivedMessages', 'parent': [{ 'name': i18n.t('messaging'), 'url': '/messages/receivedMessages' }] },
            '/messages/sentEmail':
                { 'name': i18n.t('sentEmail'), 'url': 'messages/sentEmail', 'parent': [{ 'name': i18n.t('messaging'), 'url': '/messages/sentMessages' }] },
            '/district/studentStaffCode':
                { 'name': i18n.t('studentStaffCode'), 'url': 'district/studentStaffCode', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/studentStaffCode' }] },
            '/district/districtControlPanel':
                { 'name': i18n.t('studentStaffCode'), 'url': 'district/districtControlPanel', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/districtControlPanel' }] },
            '/district/studentIdentifiers':
                { 'name': i18n.t('studentIdentifiers'), 'url': 'district/studentIdentifiers', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/studentIdentifiers' }] },
            '/district/staffIdentifiers':
                { 'name': i18n.t('staffIdentifiers'), 'url': 'district/staffIdentifiers', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/staffIdentifiers' }] },
            '/district/setDefaultValues':
                { 'name': i18n.t('setDefaultValues'), 'url': 'district/setDefaultValues', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/setDefaultValues' }] },
            '/studymaterial/courseMaterial':
                { 'name': i18n.t('courseMaterial'), 'url': 'studymaterial/courseMaterial', 'parent': [{ 'name': i18n.t('studyMaterial'), 'url': '/studymaterial/courseMaterial' }] },
            '/studymaterial/assignmentMaterial':
                { 'name': i18n.t('assignmentMaterial'), 'url': 'studymaterial/assignmentMaterial', 'parent': [{ 'name': i18n.t('studyMaterial'), 'url': '/studymaterial/assignmentMaterial' }] },
            '/district/districtPrograms':
                { 'name': i18n.t('districtPrograms'), 'url': 'district/districtPrograms', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/districtPrograms' }] },
            '/district/allowTeacherEditAttendance':
                { 'name': i18n.t('allowTeacherToEditAttendance'), 'url': 'district/allowTeacherEditAttendance', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/allowTeacherEditAttendance' }] },
            '/system/failedLoginAttemptLimit':
                { 'name': i18n.t('failedLoginAttemptLimit'), 'url': 'system/failedLoginAttemptLimit', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/failedLoginAttemptLimit' }] },
            '/district/serviceIDs':
                { 'name': i18n.t('serviceIDs'), 'url': 'district/serviceIDs', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/serviceIDs' }] },
            '/district/studentNameDisplayPattern':
                { 'name': i18n.t('studentNameDisplayPattern'), 'url': 'district/studentNameDisplayPattern', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/studentNameDisplayPattern' }] },
            '/district/staffNameDisplayPattern':
                { 'name': i18n.t('staffPersonNameDisplayPattern'), 'url': 'district/staffNameDisplayPattern', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/staffNameDisplayPattern' }] },
            '/district/addTranscriptSeal':
                { 'name': i18n.t('transcriptSeal'), 'url': 'district/addTranscriptSeal', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/addTranscriptSeal' }] },
            '/district/addDistrictGeneralInfo':
                { 'name': i18n.t('addDistrict'), 'url': 'district/addDistrictGeneralInfo', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/addDistrictGeneralInfo' }] },
            '/district/addDistrictAddressInfo':
                { 'name': i18n.t('addDistrict'), 'url': 'district/addDistrictAddressInfo', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/addDistrictAddressInfo' }] },
            '/district/districtPhoneEmail':
                { 'name': i18n.t('addDistrict'), 'url': 'district/districtPhoneEmail', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/districtPhoneEmail' }] },
            '/district/districtDesignators':
                { 'name': i18n.t('addDistrict'), 'url': 'district/districtDesignators', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/districtDesignators' }] },
            '/district/districtIdentifiers':
                { 'name': i18n.t('addDistrict'), 'url': 'district/districtIdentifiers', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/districtIdentifiers' }] },
            '/district/districtStateReporting':
                { 'name': i18n.t('addDistrict'), 'url': 'district/districtStateReporting', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/districtStateReporting' }] },
            '/district/districtStateReportingExecution':
                { 'name': i18n.t('stateReporting'), 'url': '/district/districtStateReportingExecution', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '#' }] },
            '/district/allowMultipleSchedule':
                { 'name': i18n.t('allowMultipleSchedule'), 'url': 'district/allowMultipleSchedule', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/allowMultipleSchedule' }] },
            '/attendance/attendancePercentage':
                { 'name': i18n.t('attendancePercentage'), 'url': 'attendance/attendancePercentage', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/attendancePercentage' }] },
            '/district/graduationPlan':
                { 'name': i18n.t('graduationPlan'), 'url': 'district/graduationPlan', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/graduationPlan' }] },
            '/district/failedLoginAttemptLimit':
                { 'name': i18n.t('failedLoginAttemptLimit'), 'url': '/district/failedLoginAttemptLimit', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/failedLoginAttemptLimit' }] },
            '/system/selectScheduleType':
                { 'name': i18n.t('selectScheduleType'), 'url': 'system/selectScheduleType', 'parent': [{ 'name': i18n.t('system'), 'url': 'system/selectScheduleType' }] },
            '/attendance/perfectAttendance':
                { 'name': i18n.t('perfectAttendance'), 'url': 'attendance/perfectAttendance', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/perfectAttendance' }] },
            '/attendance/setupCode':
                { 'name': i18n.t('setupCode'), 'url': 'attendance/setupCode', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/setupCode' }] },
            '/attendance/setupCodeDetails':
                { 'name': i18n.t('setupCode'), 'url': 'attendance/setupCodeDetails', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/setupCodeDetails' }] },
            '/student/specialProgramDetailListing':
                { 'name': i18n.t('specialProgramDetailListing'), 'url': '/student/specialProgramDetailListing', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/showStudentList' }] },
            '/student/studentGenerateIdCard':
                { 'name': i18n.t('generateIdCard'), 'url': 'student/studentGenerateIdCard', 'parent': [{ 'name': i18n.t('student'), 'url': '/student/studentGenerateIdCard' }] },
            '/student/studentDesignIdCard':
                { 'name': i18n.t('designIdCard'), 'url': 'student/studentDesignIdCard', 'parent': [{ 'name': i18n.t('student'), 'url': '/student/studentDesignIdCard' }] },
            '/staff/staffGenerateIdCard':
                { 'name': i18n.t('generateIdCard'), 'url': 'staff/studentGenerateIdCard', 'parent': [{ 'name': i18n.t(i18n.t('staff')), 'url': '/staff/staffGenerateIdCard' }] },
            '/staff/staffDesignIdCard':
                { 'name': i18n.t('designIdCard'), 'url': 'staff/staffDesignIdCard', 'parent': [{ 'name': i18n.t(i18n.t('staff')), 'url': '/student/staffDesignIdCard' }] },
            '/student/conductCertificate':
                { 'name': i18n.t('STUD_CERT'), 'url': 'student/conductCertificate', 'parent': [{ 'name': i18n.t('student'), 'url': '/student/conductCertificate' }] },
            '/attendance/courseAttendanceSummary':
                { 'name': i18n.t('courseAttendanceSummary'), 'url': '/attendance/courseAttendanceSummary', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/courseAttendanceSummary' }] },
            '/attendance/periodDailyAttendanceSummary':
                { 'name': i18n.t('periodDailyAttendanceSummary'), 'url': '/attendance/periodDailyAttendanceSummary', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/periodDailyAttendanceSummary' }] },
            '/assessment/generateTranscript':
                { 'name': i18n.t('generateTranscript'), 'url': '/assessment/generateTranscript', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/generateTranscript' }] },
            '/school/commentCodeSetup':
                { 'name': i18n.t('commentCodeSetup'), 'url': '/school/commentCodeSetup', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/viewSchoolGeneralInfo' }] },
            '/district/courseExplanation':
                { 'name': i18n.t('courseExplanation'), 'url': '/district/courseExplanation', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/courseExplanation' }] },
            '/messages/textMessages':
                { 'name': i18n.t('smsTextMessages'), 'url': '/messages/textMessages', 'parent': [{ 'name': i18n.t('COMM_MAIN'), 'url': '/messages/textMessages' }] },
            '/district/uploadMaximumFileSize':
                { 'name': i18n.t('uploadMaximumFileSize'), 'url': '/district/uploadMaximumFileSize', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/uploadMaximumFileSize' }] },
            '/student/addStudentDocumentUpload':
                { 'name': i18n.t('stdDocumentUpload'), 'url': '/student/addStudentDocumentUpload', 'parent': [{ 'name': i18n.t('LMS_STUD'), 'url': '/student/addStudentDocumentUpload' }] },
            '/school/standardGradingSet':
                { 'name': i18n.t('standardSet'), 'url': '/school/standardGradingSet', 'parent': [{ 'name': i18n.t('schools'), 'url': '/school/standardGradingSet' }] },
            '/school/standardGradingScale':
                { 'name': i18n.t('standardScale'), 'url': '/school/standardGradingScale', 'parent': [{ 'name': i18n.t('schools'), 'url': '/school/standardGradingScale' }] },
            '/school/standardGroup':
                { 'name': i18n.t('standardGroup'), 'url': '/school/standardGroup', 'parent': [{ 'name': i18n.t('schools'), 'url': '/school/standardGroup' }] },
            '/assessment/standardGrading':
                { 'name': i18n.t('standardGrading'), 'url': '/assessment/standardGrading', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/standardGrading' }] },
            '/school/addStandards':
                { 'name': i18n.t('standards'), 'url': '/school/addStandards', 'parent': [{ 'name': i18n.t('schools'), 'url': '/school/addStandards' }] },
            '/school/studentUsernameSetup':
                { 'name': i18n.t('Student Username Setup'), 'url': 'school/studentUsernameSetup', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/studentUsernameSetup' }] },
            '/district/districtSyncEdfi':
                { 'name': i18n.t('DIST_SYNC_EDFI'), 'url': 'district/districtSyncEdfi', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '#' }] },
            '/scheduling/teacherClassRoster':
                { 'name': i18n.t('SHDL_CLRSTR'), 'url': '/scheduling/teacherClassRoster', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/teacherClassRoster' }] },
            '/district/systemLookupValues':
                { 'name': i18n.t('systemLookupValues'), 'url': 'district/systemLookupValues', 'parent': [{ 'name': i18n.t('district'), 'url': '/district/systemLookupValues' }] },
            '/attendance/attendanceCountReport':
                { 'name': i18n.t('attendanceCountReport'), 'url': 'attendance/attendanceCountReport', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/attendanceCountReport' }] },
            '/attendance/staffAttendanceReport':
                { 'name': i18n.t('ATTD_STATR'), 'url': '/attendance/staffAttendanceReport', 'parent': [{ 'name': i18n.t('SOVIEW_ATTD'), 'url': '/attendance/staffAttendanceReport' }] },
            '/assessment/studentTestData':
                { 'name': i18n.t('studentTestData'), 'url': '/assessment/studentTestData', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/studentTestData' }] },
            '/scheduling/classRoster':
                { 'name': i18n.t('SHDL_CLRSTR'), 'url': '/scheduling/classRoster', 'parent': [{ 'name': i18n.t('SHDL_MAIN'), 'url': '/scheduling/classRoster' }] },
            '/district/testFormat':
                { 'name': i18n.t('testFormat'), 'url': '/district/testFormat', 'parent': [{ 'name': i18n.t('District'), 'url': '/district/testFormat' }] },
            '/school/schoolOptions':
                { 'name': i18n.t('schoolOptions'), 'url': '/school/schoolOptions', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/schoolOptions' }] },
            '/district/graduationPlanNew':
                { 'name': i18n.t('graduationPlan'), 'url': '/district/graduationPlanNew', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/graduationPlanNew' }] },
            '/district/categories':
                { 'name': i18n.t('categories'), 'url': '/district/categories', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/categories' }] },
            '/district/courseAndCategoryAssociate':
                { 'name': i18n.t('courseAndCategoryAssociate'), 'url': '/district/courseAndCategoryAssociate', 'parent': [{ 'name': i18n.t('DIST_MAIN'), 'url': '/district/courseAndCategoryAssociate' }] },
            '/school/timezoneSetup':
                { 'name': i18n.t('timezoneSetup'), 'url': '/school/timezoneSetup', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/timezoneSetup' }] },
            '/assessment/gpaReport':
                { 'name': i18n.t('gpaReport'), 'url': '/assessment/gpaReport', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/gpaReport' }] },
            '/assessment/rankReport':
                { 'name': i18n.t('rankReport'), 'url': '/assessment/rankReport', 'parent': [{ 'name': i18n.t('assessment'), 'url': '/assessment/rankReport' }] },
            '/student/studentsMassAssignNew':
                { 'name': i18n.t('studentMassAssign'), 'url': '/assessment/rankReport', 'parent': [{ 'name': i18n.t('student'), 'url': '/student/studentsMassAssignNew' }] },
            '/student/counselorLog':
                { 'name': i18n.t('counselorLog'), 'url': '/student/counselorLog', 'parent': [{ 'name': i18n.t('student'), 'url': '/student/counselorLog' }] },
            '/student/counselorLogDetails':
                { 'name': i18n.t('counselorLog'), 'url': '/student/counselorLogDetails', 'parent': [{ 'name': i18n.t('student'), 'url': '/student/counselorLogDetails' }] },
            '/attendance/attendanceLog':
                { 'name': i18n.t('attendanceLog'), 'url': '/attendance/attendanceLog', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/attendanceLog' }] },
            '/attendance/attendanceLogDetails':
                { 'name': i18n.t('attendanceLog'), 'url': '/attendance/attendanceLogDetails', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/attendanceLogDetails' }] },
            '/attendance/studentAttendancePercentage':
                { 'name': i18n.t('studentAttendancePercentage'), 'url': '/attendance/studentAttendancePercentage', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/studentAttendancePercentage' }] },
            '/district/districtAttendancePercentageReport':
                { 'name': i18n.t('DIST_ATPR'), 'url': '/district/districtAttendancePercentageReport', 'parent': [{ 'name': i18n.t('District'), 'url': '/district/districtAttendancePercentageReport' }] },
            '/school/principalSignatureUpload':
                { 'name': i18n.t('principalSignatureUpload'), 'url': '/school/principalSignatureUpload', 'parent': [{ 'name': i18n.t('school'), 'url': '/school/principalSignatureUpload' }] },
            '/attendance/absenceLetter':
                { 'name': i18n.t('absenceLetter'), 'url': '/attendance/absenceLetter', 'parent': [{ 'name': i18n.t('attendance'), 'url': '/attendance/absenceLetter' }] }

        };
        return content[url];
    }
}
export default markersBreadCrumb;
