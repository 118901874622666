import BaseComponent from '../shared/BaseComponent';
import ExecuteArgs from '../shared/model/executeArgs';
import commonMessageConfig from './commonMessagesConfig';
import commonConfig from '../common/commonConfig';
import commonSchoolConfig from '../school/commonSchoolConfig';
import commonScheduleConfig from '../schedule/commonScheduleConfig';
import commonAttendanceConfig from '../attendance/commonAttendanceConfig';
import commonCourseConfig from '../course/commonCourseConfig';
import i18n from '../../i18n';
import sessionStore from '../common/component/sessionStoreComponent';
import Validation from '../common/component/validationComponent';
import * as actions from './store/actions/index';
import * as actionsCommon from '../shared/store/actions/index';
import * as actionsLogin from '../common/store/actions/index';
import * as actionsSchools from '../school/store/actions/index';
import * as actionsSchedules from '../schedule/store/actions/index';
import * as actionsAttendance from '../attendance/store/actions/index';
import * as actionsCourse from '../course/store/actions/index';
import MarkersMultiLingual from '../common/component/markersMultiLingualComponent';
import dataTable from '../common/component/markersDataTableComponent';
import MarkersCommonSelect from '../common/component/markersSelect';
import ConvertDate from '../common/component/markersConvertDateComponent';
import CommonFuncs from '../common/component/markersCommonFunctionComponent';
export {
    BaseComponent,
    Validation,
    ExecuteArgs,
    commonMessageConfig,
    commonConfig,
    commonSchoolConfig,
    i18n,
    sessionStore,
    dataTable,
    MarkersMultiLingual,
    actions,
    actionsCommon,
    actionsLogin,
    actionsSchools,
    MarkersCommonSelect,
    ConvertDate,
    CommonFuncs,
    commonScheduleConfig,
    actionsSchedules,
    commonAttendanceConfig,
    actionsAttendance,
    commonCourseConfig,
    actionsCourse
}