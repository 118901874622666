import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';
const initialState = {
  getDistrictStudentStaffCode: {},
  saveDistrictStudentStaffCode: false,
  districtIdentifiersList: [],
  saveDistrictIdentifiers: false,
  getDistrictIdentifier: {},
  getDistrictCountryStateLanguage: {},
  saveDistrictCountryStateLanguage: false,
  deleteDistrict: false,
  getDistrictMoodle: {},
  getDistrictCanvas: {},
  getDistrictGcr: {},
  saveDistrictMoodletest: 0,
  saveDistrictGcrtest: 0,
  saveDistrictCanvastest: 0,
  saveDistrictMoodleedit: 0,
  saveDistrictCanvasedit: 0,
  saveDistrictGcredit: 0,
  saveDistrictMoodleintegration: [],
  saveDistrictProgram: false,
  districtProgramList: [],
  saveDistrictInstructionalDays: false,
  getDistrictInstructionalDaysCount: {},
  districtServiceIdsList: [],
  getDistrictServiceIds: {},
  saveDistrictServiceIds: false,
  getDistrictStudentNameDisplayPattern: {},
  saveDistrictStudentNameDisplayPattern: {},
  saveDistrictStaffNameDisplayPattern: false,
  getDistrictStaffNameDisplayPattern: {},
  saveDistrictLmsTest: false,
  getDistrictGeneralInfo: {},
  saveDistrictGeneralInfo: {},
  districtAddressList: [],
  saveDistrictAddressInfo: {},
  getDistrictIdentifier: {},
  districtIdentifierList: [],
  saveDistrictIdentifier: {},
  districtPhoneList: [],
  districtEmailList: [],
  getDistrictPhone: {},
  getDistrictEmail: {},
  districtCourseExplanationList: [],
  saveDistrictPhone: {},
  saveDistrictEmail: {},
  getDistrictMultipleSchedule: {},
  saveDistrictMultipleSchedule: null,
  districtGraduationPlanList: [],
  saveDistrictGraduationPlan: {},
  getDistrictGraduationPlan: {},
  getDistrictProgramPlan: {},
  getDistrictSchoolEditPermissionsForAdmin: {},
  saveDistrictSchoolEditPermissionsForAdmin: null,
  districtCurrencyByCountryList: [],
  districtCourseList: [],
  saveDistrictCourse: false,
  getDistrictCourseExplanation: {},
  saveDistrictTranscriptSeal: false,
  getDistrictTranscriptSeal: {},
  getDistrictUploadFileSize: {},
  saveDistrictUploadFileSize: false,
  getDistrictSystemValues: {},
  saveDistrictSystemValues: false,
  districtTestFormatList: [],
  getDistrictTestFormat: null,
  getDistrictTestFormatTypes: [],
  getDistrictSubjectFields: [],
  saveDistrictTestFormat: {},
  getDistrictDependencies: null
};
const handleFail = (state) => {
  return updateObject(state, { isLoading: false });
};
const commonHandleSuccess = (state, action, storeStateName) => {
  return updateObject(state, {
    loading: false,
    [storeStateName]: action.responseData.data, //FROM HERE IT WILL GO TO MAIN PAGE LMS
  });
};
const reducerDistrict = (state = initialState, action) => {
  state = state || initialState;
  switch (action.type) {
    //case 'DISTRICT_MOODLELIST': return commonHandleSuccess(state, action, camelCase(`district_${action.title}_list`));
    case 'DISTRICT_LIST':
      return commonHandleSuccess(state, action, camelCase(`district_${action.title}_list`));
    case 'DISTRICT_SAVE':
      return commonHandleSuccess(state, action, camelCase(`save_district_${action.title}`));
    case 'DISTRICT_GET':
      return commonHandleSuccess(state, action, camelCase(`get_district_${action.title}`));
    case 'DISTRICT_DELETE':
      return commonHandleSuccess(state, action, camelCase(`delete_district_${action.title}`));
    case commonConfig.DISTRICT_STORE_RESET:
      return commonHandleSuccess(state, action, action.title);
    case 'HANDLE_DISTRICT_FAIL':
      return handleFail(state);
    default:
      return state;
  }
};
export default reducerDistrict;
