import React, { Component } from 'react';
import ReactNotification, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import i18n from '../../../i18n';
class MarkersNotify extends Component {
    constructor(props) {
        super(props);
    }
    notif = () => {
        store.addNotification({
            title: !!this.props.type ? i18n.t(this.props.type) : '',
            message: !!this.props.message ? this.props.message : '',
            width: 270,
            type: this.props.type === 'error' ? 'danger' : this.props.type,
            showIcon: true,
            container: "top-right",
            dismiss: {
                duration: 5000,
                showIcon: true
            },
            onRemoval: (id, removedBy) => {
                if (this.props.hasOwnProperty('isShowNotify')) {
                    this.props.isShowNotify()
                }
            }
        })
    }
    render() {
        return <ReactNotification ref={this.notif} />
    }
}
export default MarkersNotify;