import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MarkersMultiLingual } from './markersMultiLingualComponent';
const langObj = new MarkersMultiLingual();
class Paginate extends Component {
    constructor(props) {
        super(props);
    }
    createArray(i) {
        return i ? this.createArray(i - 1).concat(i) : []
    }
    returnContent = () => {
        var pageArr = [];
        var paginationArray = [];
        var pageDetails = [];
        pageArr = this.createArray(Math.ceil(this.props.data.totalCount / this.props.data.perPage));
        for (let l = this.props.data.currentPage; l <= parseInt(this.props.data.currentPage) + 2; l++) {
            if (l <= pageArr.length)
                paginationArray.push(l);
        }
        paginationArray && paginationArray.length > 0 ?
            pageDetails = paginationArray.map((pageValue, j) =>
                this.props.data.currentPage === pageValue ?
                    <li key={j} className="page-item active"><Link className="page-link" to="#" onClick={() => this.props.data.classObj[this.props.data.method](pageValue)}>{pageValue}</Link></li>
                    :
                    <li className="page-item"><Link className="page-link" to="#" onClick={() => this.props.data.classObj[this.props.data.method](pageValue)}>{pageValue}</Link></li>
            )
            :
            pageDetails = null;
        return <div className="row">
            <div className="col-md-12 d-flex">
                <nav className="ml-auto">
                    <ul className="pagination">
                        <li className={this.props.data.currentPage > 1 ? "page-item" : "page-item disabled"}>
                            <Link className="page-link" to="#" onClick={() => this.props.data.classObj[this.props.data.method](this.props.data.currentPage > 3 ? this.props.data.currentPage - 3 : this.props.data.currentPage - 1)}><i className="icon-arrow-left4 mr-2"></i>{langObj.returnLanguage('previous')}</Link>
                        </li>
                        {pageDetails}
                        <li className={pageArr.length > this.props.data.currentPage ? "page-item" : "page-item disabled"}>
                            <Link className="page-link" to="#" onClick={() => this.props.data.classObj[this.props.data.method](paginationArray[paginationArray.length - 1] + 1)}>{langObj.returnLanguage('next')}<i className="icon-arrow-right4 ml-2"></i></Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    }
    render() {
        return (<div>{this.returnContent()}</div>);
    }
}
export default (Paginate);