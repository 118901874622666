import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    ExecuteArgs, commonConfig, Validation, i18n, actions, actionsSchool, actionsDistrict, commonSchoolConfig, commonDistrictConfig, sessionStore, CommonFuncs
} from '../commonLoginindex';
import Swal from 'sweetalert2';
import { find, findIndex } from 'lodash';
import base64ImageConfig from '../base64ImageConfig';
const commonFuncObj = new CommonFuncs();
class LoginComponent extends Component {
    initialForgotPasswordFields = {
        userRoleType: 'student',
        userId: '',
        email: ''
    }
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                Username: '',
                Password: '',
                UserIpAddress: '',
                language: ''
            },
            forgotPasswordFields: { ...this.initialForgotPasswordFields },
            forgotPasswordRequiredFields: [
                { fieldName: "email", fieldType: "email" }
            ],
            requiredPasswordFields: [
                { fieldName: 'reTypeNewPassword', fieldType: 'repeatField', parentFieldName: 'newPassword' }
            ],
            passwordFields: {},
            loginView: 'login',
            otp: '',
            newPassword: '',
            reTypeNewPassword: '',
            usernameError: '',
            passwordError: '',
            validCredential: 1,
            isError: 0,
            isErrorForgotPassword: 0,
            isProcessFlag: false,
            userToken: '',
            isAuthenticated: false,
            isErrorPassword: 0,
            isErrorOtp: 0,
            getSchoolBasedRoleListByUserIdFlag: false,
            schoolListFlag: false,
            isLoading: false,
            userSchoolRoles: [],
            menuList: [],
            showSelectProfileFlag: false,
            isLoadDistrictMenuCallRequired: false,
            isLoadDistrictMenuCallDone: false,
            isDistrictAdmin: false,
            distRedirect: false,
            getPasswordPatternFlag: false,
            regex: "",
            countryCodeFlag: false,
            stateCodeFlag: false,
            districtLogoRedirectFlag: false,
            getInfoFlag: false
        }
    }
    forgotPassword = () => {
        this.props.onResetLoginProps({ name: 'saveConfirmPassword', data: false });
        this.props.onResetLoginProps({ name: 'getForgotPasswordOtp', data: {} });
        this.props.onResetLoginProps({ name: 'confirmForgotPasswordOtp', data: {} });
        this.setState({ loginView: 'forgotPassword' });
    }
    loginGo = () => {
        this.props.onResetLoginProps({ name: 'saveConfirmPassword', data: false });
        this.props.onResetLoginProps({ name: 'getForgotPasswordOtp', data: {} });
        this.props.onResetLoginProps({ name: 'confirmForgotPasswordOtp', data: {} });
        this.setState({
            loginView: 'login', isErrorForgotPassword: 0, otp: '',
            forgotPasswordFields: this.initialForgotPasswordFields, newPassword: '',
            isErrorPassword: 0, isErrorOtp: 0, reTypeNewPassword: ''
        });
    }
    getPasswordPattern = (userId) => {
        this.setState({ getPasswordPatternFlag: true }, () => {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_GET_PASSWORD_PATTERN_BY_USER_ID,
                commonConfig.METHOD_TYPE_POST, userId);
            this.props.onGetByUserIdSuccess(executedArgument);
        })
    }
    componentDidMount = () => {
        var n = sessionStorage.length;
        while (n--) {
            var key = sessionStorage.key(n);
            sessionStorage.removeItem(key);
        }
    }
    getDistrictInfo = () => {
        this.props.onResetDistrictProps({ name: 'getDistrictGeneralInfo', data: {} });
        this.setState({ getInfoFlag: true }, () => {
            let executedArgument = new ExecuteArgs(commonDistrictConfig.COMPONENT_CONTROL_PANEL,
                commonDistrictConfig.COMPONENT_DISTRICT_GET_GENERAL_INFO + new sessionStore().returnDistrictID(),
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetByIdSucess(executedArgument, 'generalInfo');
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.getPasswordPatternByUserId && Object.keys(nextProps.getPasswordPatternByUserId).length !== 0 && this.state.getPasswordPatternFlag) {
            let requiredPasswordFields = [...this.state.requiredPasswordFields];
            let minValue = nextProps.getPasswordPatternByUserId.minCharacterLength
            let maxValue = nextProps.getPasswordPatternByUserId.maxCharacterLength
            let CHANGE_PASSWORD_REGEX = ""
            var pattern = ''
            if (nextProps.getPasswordPatternByUserId.minOneSpecialCharacter) {
                pattern = '(?=.*[!@#$%^&*])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (nextProps.getPasswordPatternByUserId.minOneNumericCharacter) {
                pattern = '(?=.*[0-9])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (nextProps.getPasswordPatternByUserId.minOneUpperCase) {
                pattern = '(?=.*[A-Z])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (nextProps.getPasswordPatternByUserId.minOneLowerCase) {
                pattern = '(?=.*[a-z])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (nextProps.getPasswordPatternByUserId.minOneLetter) {
                pattern = '(?=.*[A-Za-z])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (!!nextProps.getPasswordPatternByUserId.minCharacterLength && !!nextProps.getPasswordPatternByUserId.maxCharacterLength) {
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + '[a-zA-Z0-9!@#$%^&*]{' + minValue + ',' + maxValue + '}';
            }
            CHANGE_PASSWORD_REGEX = new RegExp('^' + CHANGE_PASSWORD_REGEX + '$');
            requiredPasswordFields.push({ fieldName: 'newPassword', fieldType: 'password', regExp: CHANGE_PASSWORD_REGEX });
            this.setState({ requiredPasswordFields, regex: CHANGE_PASSWORD_REGEX, passwordFields: nextProps.getPasswordPatternByUserId, getPasswordPatternFlag: false })
        }
        if (nextProps.isAuthenticated && !nextProps.isResourcesLoaded && this.state.getValidateFlag) {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_USER_LAST_SESSION,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetLastSession(executedArgument);
            new sessionStore().storeSecureData(commonConfig.SESSION_LOGGED_USER_EMAIL, this.state.fields.Username);
            this.setState({ getValidateFlag: false, schoolListFlag: true, isLoading: true }, () => {
                let executedArgument = new ExecuteArgs(commonSchoolConfig.COMPONENT_SCHOOL,
                    commonSchoolConfig.COMPONENT_SCHOOL_SESSION_GETALL_FOR_USER,
                    commonSchoolConfig.METHOD_TYPE_GET);
                this.props.onGetAllSchoolForUser(executedArgument);
            });
        }
        if (nextProps.schoolTimeZoneNameList && nextProps.schoolTimeZoneNameList.length > 0) {
            new sessionStore().storeSecureData(commonConfig.SESSION_TIME_ZONE, nextProps.schoolTimeZoneNameList[0].timeZoneName);
            this.props.onResetSchoolProps({ name: 'schoolTimeZoneNameList', data: null });
        }
        if (nextProps.getSchoolCountryCode && nextProps.getSchoolCountryCode !== '') {
            new sessionStore().storeSecureData(commonConfig.SESSION_COUNTRYCODE, nextProps.getSchoolCountryCode)
            this.props.onResetSchoolProps({ name: 'getSchoolCountryCode', data: null });
            this.setState({ countryCodeFlag: true })
        }
        if (nextProps.getSchoolStateCode && nextProps.getSchoolStateCode !== '') {
            new sessionStore().storeSecureData(commonConfig.SESSION_STATECODE, nextProps.getSchoolStateCode)
            this.props.onResetSchoolProps({ name: 'getSchoolStateCode', data: null });
            this.setState({ stateCodeFlag: true })
        }
        if (nextProps.getForgotPasswordOtp && Object.keys(nextProps.getForgotPasswordOtp).length > 0 && nextProps.getForgotPasswordOtp.userId === 0) {
            this.setState({ isProcessFlag: false })
        }
        if (nextProps.getForgotPasswordOtp && !!nextProps.getForgotPasswordOtp.userId && !!nextProps.getForgotPasswordOtp.verificationCode) {
            this.setState({ loginView: 'otpValidation', isProcessFlag: false, otp: '' })
        }
        if (nextProps.confirmForgotPasswordOtp && !!nextProps.confirmForgotPasswordOtp.isValid && !!nextProps.confirmForgotPasswordOtp.id) {
            this.setState({ loginView: 'resetPassword' })
        }
        if (nextProps.saveConfirmPassword && !!nextProps.saveConfirmPassword.isValid) {
            this.setState({ loginView: 'login', newPassword: '', reTypeNewPassword: '', forgotPasswordFields: this.initialForgotPasswordFields })
        }
        if (nextProps.schoolList && nextProps.schoolList.length > 0 && this.state.schoolListFlag) {
            let setStateObject = {
                schoolList: commonFuncObj.removeDuplicates(nextProps.schoolList, 'schoolId'),
                schoolSessionList: nextProps.schoolList,
                //getSchoolFlag: false
            }
            let lastSessionObject = new sessionStore().returnLastUserSessionDetails();
            if (lastSessionObject && Object.keys(lastSessionObject).length > 0 && !!lastSessionObject.schoolId) {
                if (!new sessionStore().returnSchoolID()) {
                    new sessionStore().storeSecureData(commonConfig.SESSION_USER_ID, lastSessionObject.userId);
                    new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLID, lastSessionObject.schoolId);
                }
                let lastSchoolObject = {};
                if (lastSessionObject.schoolSessionId)
                    lastSchoolObject = find(setStateObject.schoolSessionList, ['id', lastSessionObject.schoolSessionId]);
                else
                    lastSchoolObject = find(setStateObject.schoolSessionList, ['schoolId', lastSessionObject.schoolId]);
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLNAME, lastSchoolObject.schoolname);
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLYEAR, lastSessionObject.schoolYear);
                new sessionStore().storeSecureData(commonConfig.SESSION_MARKINGPERIOD_ID, lastSessionObject.markingPeriodId);
                new sessionStore().storeSecureData(commonConfig.SESSION_ID, lastSessionObject.schoolSessionId);
                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLSESSION, lastSchoolObject.title);
                new sessionStore().storeSecureData(commonConfig.SESSION_ENVIRONMENT, commonFuncObj.getCurrentEnvironment());
            } else {
                if (setStateObject.schoolSessionList.length > 0) {
                    let index = setStateObject.schoolSessionList.findIndex(k => k.schoolId === setStateObject.schoolSessionList[0].schoolId && k.isDefault === true)
                    var objectSelected = setStateObject.schoolSessionList[index === -1 ? 0 : index];
                    if (objectSelected && Object.keys(objectSelected).length !== 0) {
                        if (!new sessionStore().returnSchoolID()) {
                            new sessionStore().storeSecureData(commonConfig.SESSION_USER_ID, objectSelected.userId);
                            if (!!objectSelected.schoolId) {
                                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLID, objectSelected.schoolId);
                                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLNAME, objectSelected.schoolname);
                                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLYEAR, objectSelected.schoolYear);
                                //new sessionStore().storeSecureData(commonConfig.SESSION_MARKINGPERIOD_ID, objectSelected.markingPeriodId);
                                new sessionStore().storeSecureData(commonConfig.SESSION_ID, objectSelected.id);
                                new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOLSESSION, objectSelected.title);
                            }
                        }
                    }
                }
            }
            if (new sessionStore().returnSchoolID()) {
                this.props.onResetSchoolProps({ name: 'schoolBasedRoleList', data: null })
                this.setState({ schoolListFlag: false, getSchoolBasedRoleListByUserIdFlag: true }, () => {
                    let executedArgument = new ExecuteArgs(commonSchoolConfig.COMPONENT_SCHOOL,
                        commonSchoolConfig.COMPONENT_GET_SCHOOL_BASED_ROLE_LIST_BY_USERID,
                        commonSchoolConfig.METHOD_TYPE_POST, parseInt(new sessionStore().returnUserID(), 10));
                    this.props.onGetSchoolBasedRoleListByUserId(executedArgument);
                    this.props.onResetSchoolProps({ name: 'schoolTimeZoneNameList', data: null });
                    executedArgument = new ExecuteArgs(commonSchoolConfig.COMPONENT_SCHOOL,
                        commonSchoolConfig.COMPONENT_GET_SCHOOL_TIMEZONE_NAME + parseInt(new sessionStore().returnSchoolID(), 10),
                        commonConfig.METHOD_TYPE_GET);
                    this.props.onGetTimeZone(executedArgument);
                    executedArgument = new ExecuteArgs(commonSchoolConfig.COMPONENT_SCHOOL,
                        commonSchoolConfig.COMPONENT_GET_SCHOOL_COUNTRY_CODE + parseInt(new sessionStore().returnSchoolID(), 10),
                        commonConfig.METHOD_TYPE_GET);
                    this.props.onGetCountryCode(executedArgument);
                    executedArgument = new ExecuteArgs(commonSchoolConfig.COMPONENT_SCHOOL,
                        commonSchoolConfig.COMPONENT_GET_SCHOOL_STATE_CODE + parseInt(new sessionStore().returnSchoolID(), 10),
                        commonConfig.METHOD_TYPE_GET);
                    this.props.onGetStateCode(executedArgument);
                    // get school code here
                })
            }
        }
        if (!!nextProps.schoolBasedRoleList && nextProps.schoolBasedRoleList.length > 0 && this.state.getSchoolBasedRoleListByUserIdFlag) {
            let schoolBasedRoleSession = [];
            let selectedSchoolRoles = [];
            new sessionStore().storeSecureData(commonConfig.SESSION_DISTRICTID, nextProps.schoolBasedRoleList[0].districtId);
            if (nextProps.schoolBasedRoleList.length === 1 && nextProps.schoolBasedRoleList[0].lookupSchoolRole === commonConfig.SCHOOL_ROLE_DIST_ADMIN) {
                this.setState({ getSchoolBasedRoleListByUserIdFlag: false, isLoading: false, distRedirect: true });
            }
            else {
                nextProps.schoolBasedRoleList.forEach((data, index) => {
                    if (data.schoolId === new sessionStore().returnSchoolID()) {
                        selectedSchoolRoles.push({ schoolId: data.schoolId, schoolRole: data.lookupSchoolRoleTitle, lookupRoleId: data.lookupSchoolRole, roleId: data.id, studentId: data.studentId, staffId: data.staffId, studentEnrollmentId: data.studentEnrollmentId });
                    }
                    schoolBasedRoleSession.push({ schoolId: data.schoolId, schoolRole: data.lookupSchoolRoleTitle, roleId: data.id });
                })
                if (selectedSchoolRoles.length > 1) {
                    let lastSessionObject = new sessionStore().returnLastUserSessionDetails();
                    if (lastSessionObject && Object.keys(lastSessionObject).length > 0 && !!lastSessionObject.schoolId) {
                        let schoolRoleObj = {};
                        schoolRoleObj = find(selectedSchoolRoles, ['roleId', lastSessionObject.schoolDefaultRoleID]);
                        if (schoolRoleObj !== undefined && Object.keys(schoolRoleObj).length > 0) {
                            new sessionStore().storeSecureData(commonConfig.SESSION_USER_ROLE, lastSessionObject.schoolDefaultRoleID);
                            new sessionStore().storeSecureData(commonConfig.SESSION_LOGGED_USER_ROLE, lastSessionObject.lookupRoleId);
                            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                                commonConfig.COMPONENT_GET_MENUS + new sessionStore().returnSchoolID() + '&schoolRoleId=' + new sessionStore().returnUserRole(),
                                commonConfig.METHOD_TYPE_GET);
                            this.props.onGetAllMenuSuccess(executedArgument);
                        }
                    }
                    new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_BASED_MULTI_ROLE_LIST, JSON.stringify(selectedSchoolRoles));
                    if (selectedSchoolRoles[0].staffId)
                        new sessionStore().storeSecureData(commonConfig.SESSION_STAFFID, selectedSchoolRoles[0].staffId);
                    this.setState({ getSchoolBasedRoleListByUserIdFlag: false, showSelectProfileFlag: true, isLoading: false }, () => {
                        new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_BASED_ROLE_LIST, JSON.stringify(schoolBasedRoleSession));
                        this.getDistrictInfo();
                    })
                }
                if (selectedSchoolRoles.length === 1) {
                    new sessionStore().storeSecureData(commonConfig.SESSION_LOGGED_USER_ROLE, selectedSchoolRoles[0].lookupRoleId);
                    new sessionStore().storeSecureData(commonConfig.SESSION_USER_ROLE, selectedSchoolRoles[0].roleId);
                    if (selectedSchoolRoles[0].studentGradeLevelId)
                        new sessionStore().storeSecureData(commonConfig.SESSION_STUDENT_GRADE_LEVEL_ID, selectedSchoolRoles[0].studentGradeLevelId);
                    if (selectedSchoolRoles[0].studentId)
                        new sessionStore().storeSecureData(commonConfig.SESSION_STUDENTID, selectedSchoolRoles[0].studentId);
                    if (selectedSchoolRoles[0].staffId)
                        new sessionStore().storeSecureData(commonConfig.SESSION_STAFFID, selectedSchoolRoles[0].staffId);
                    if (selectedSchoolRoles[0].studentEnrollmentId)
                        new sessionStore().storeSecureData(commonConfig.SESSION_STUDENT_ENROLLMENT_ID, selectedSchoolRoles[0].studentEnrollmentId);
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                        commonConfig.COMPONENT_GET_MENUS + new sessionStore().returnSchoolID() + '&schoolRoleId=' + new sessionStore().returnUserRole(),
                        commonConfig.METHOD_TYPE_GET);
                    this.props.onGetAllMenuSuccess(executedArgument);
                    this.setState({ getSchoolBasedRoleListByUserIdFlag: false, isLoading: false, showSelectProfileFlag: true }, () => {
                        new sessionStore().storeSecureData(commonConfig.SESSION_SCHOOL_BASED_ROLE_LIST, JSON.stringify(schoolBasedRoleSession));
                        this.getDistrictInfo();
                    })
                }
            }
        }
        if (!!nextProps.schoolBasedRoleList && nextProps.schoolBasedRoleList.length === 0 && !!this.state.getSchoolBasedRoleListByUserIdFlag) {
            this.setState({ isLoading: false, getSchoolBasedRoleListByUserIdFlag: false, isLoadDistrictMenuCallDone: false, isDistrictAdmin: false, isAuthenticated: false, getSchoolBasedRoleListByUserIdFlag: false, countryCodeFlag: false }, () => {
                Swal.fire({
                    title: "MarkersPro!",
                    text: i18n.t('unAuthorizedAccessTwo'),
                    type: 'error',
                }).then(() => {
                    window.location.reload();
                })
            })
        }
        if (nextProps.getDistrictGeneralInfo && Object.keys(nextProps.getDistrictGeneralInfo).length !== 0 && this.state.getInfoFlag) {
            new sessionStore().storeSecureData(commonConfig.SESSION_IS_DASHBOARD, nextProps.getDistrictGeneralInfo.isUseOnDashboard);
            new sessionStore().storeSecureData(commonConfig.SESSION_IS_SCHOOLREPORT, nextProps.getDistrictGeneralInfo.isUseInSchoolReports);
            new sessionStore().storeSecureData(commonConfig.SESSION_IS_DISTRICTREPORT, nextProps.getDistrictGeneralInfo.isUseInDistrictReports);
            new sessionStore().storeSecureData(commonConfig.SESSION_DISTRICT_LOGO, nextProps.getDistrictGeneralInfo.fileByteArray);
            this.setState({ districtLogoRedirectFlag: true, getInfoFlag: false });
        }
        if (nextProps.isLoadDistrictMenuCallRequired && nextProps.isAuthenticated && nextProps.isResourcesLoaded &&
            !this.state.isLoadDistrictMenuCallDone) {
            this.props.onResetSchoolProps({ name: 'schoolBasedRoleList', data: null })
            this.setState({ isLoadDistrictMenuCallDone: true, getSchoolBasedRoleListByUserIdFlag: true });
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_GET_MENUS + new sessionStore().returnSchoolID() + '&schoolRoleId=' + new sessionStore().returnUserRole(),
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetAllMenuSuccess(executedArgument);
            executedArgument = new ExecuteArgs(commonSchoolConfig.COMPONENT_SCHOOL,
                commonSchoolConfig.COMPONENT_GET_SCHOOL_BASED_ROLE_LIST_BY_USERID,
                commonSchoolConfig.METHOD_TYPE_POST, parseInt(new sessionStore().returnUserID(), 10));
            this.props.onGetSchoolBasedRoleListByUserId(executedArgument);
        }
        if (nextProps.menuList && nextProps.menuList.length > 0 && this.state.isLoadDistrictMenuCallDone) {
            this.setState({ isDistrictAdmin: true });
        }
    }
    handleChange = event => {
        let fields = Object.assign({}, this.state.fields);
        fields[event.target.id] = event.target.value;
        this.setState({ fields: fields }, () => { });
    }
    handleChangeForgotPassword = event => {
        let fields = Object.assign({}, this.state.forgotPasswordFields);
        fields[event.target.name] = event.target.value;
        this.setState({ forgotPasswordFields: fields });
    }
    handleSubmit = event => {
        event.preventDefault();
        if (this.state.fields.Username === '' || this.state.fields.Password === '') {
            this.setState({ isError: 1 });
        } else {
            this.setState({ isError: 0, getValidateFlag: true }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_LOGIN,
                    commonConfig.COMPONENT_LOGIN_VALIDATE_SAVE, commonConfig.METHOD_TYPE_POST,
                    this.state.fields);
                this.props.onValidateLogin(executedArgument);
            })
        }
    }
    handleSubmitForgotPassword = event => {
        event.preventDefault();
        let validationObj = new Validation();
        let requiredFields = this.state.forgotPasswordRequiredFields;
        if (this.state.forgotPasswordFields.userRoleType === 'student') {
            requiredFields.push({ fieldName: "userId", fieldType: "default" });
        } else {
            const indexValue = findIndex(requiredFields, { fieldName: "userId" });
            if (indexValue !== -1) {
                requiredFields.splice(indexValue, 1)
            }
        }
        let submitStatus = validationObj.fieldValidation(this.state.forgotPasswordFields, requiredFields);
        if (submitStatus === 0) {
            this.setState({ isErrorForgotPassword: 1 });
        } else {
            this.setState({ isErrorForgotPassword: 0, isProcessFlag: true }, () => {
                let userId = null;
                if (this.state.forgotPasswordFields.userId && this.state.forgotPasswordFields.userRoleType !== 'other')
                    userId = this.state.forgotPasswordFields.userId;
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_RESOURCE_FORGOT_PASSWORD, commonConfig.METHOD_TYPE_POST,
                    { userType: this.state.forgotPasswordFields.userRoleType, userId: userId, userName: this.state.forgotPasswordFields.userRoleType !== 'other' ? userId : this.state.forgotPasswordFields.email, ParentEmail: this.state.forgotPasswordFields.userRoleType !== 'other' ? this.state.forgotPasswordFields.email : null });
                this.props.onSendOtpSuccess(executedArgument);
            })
        }
    }
    confirmOtp = (event) => {
        event.preventDefault();
        if (this.state.otp === '') {
            this.setState({ isErrorOtp: 1 });
        } else {
            this.setState({ isErrorOtp: 0, isProcessFlag: true }, () => {
                const { userId } = this.props.getForgotPasswordOtp;
                this.getPasswordPattern(userId);
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_RESOURCE_CONFIRM_OTP, commonConfig.METHOD_TYPE_POST,
                    { userId: this.props.getForgotPasswordOtp.userId, verificationCode: this.state.otp });
                this.props.onConfirmForgotPasswordOtpSuccess(executedArgument);
            })
        }
    }
    changePassword = event => {
        event.preventDefault();
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation({
            newPassword: this.state.newPassword,
            reTypeNewPassword: this.state.reTypeNewPassword
        }, this.state.requiredPasswordFields);
        if (submitStatus === 0) {
            this.setState({ isErrorPassword: 1 });
        } else {
            this.setState({ isErrorPassword: 0, isProcessFlag: true }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_RESOURCE_RESET_PASSWORD, commonConfig.METHOD_TYPE_POST,
                    {
                        password: this.state.newPassword,
                        changedPassword: this.state.newPassword,
                        isForgotPassword: true,
                        id: this.props.confirmForgotPasswordOtp.id
                    });
                this.props.onSaveConfirmPasswordSuccess(executedArgument);
            })
        }
    }
    render() {
        let { loggingIn, loginError, isLoginPending } = this.props;
        if (this.props.isAuthenticated && this.props.isResourcesLoaded && new sessionStore().returnSchoolID()
            && this.state.showSelectProfileFlag && this.state.districtLogoRedirectFlag && this.state.stateCodeFlag && this.state.countryCodeFlag) {
            return (<Redirect to="/dashboard" />)
        } else if (this.props.isAuthenticated && this.props.isResourcesLoaded && this.state.isDistrictAdmin && this.state.distRedirect) {
            return (<Redirect to="/dashboard" />)
        }
        return (
            <div className="loginpage">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                            <header>
                                <a href="#/" className="logomain"><img src={base64ImageConfig.LOGIN_PAGE_PRODUCT_LOGO} alt="" /></a>
                            </header>
                            {this.state.loginView === 'login' &&
                                <React.Fragment>
                                    <form className="login-form" action="#" autoComplete="off">
                                        <h2 className="mb-0">{i18n.t('welcomeBack')}</h2>
                                        <p>{i18n.t('enterYourCredential')}</p>
                                        <Validation data={{ field: this.state.validCredential, validationType: 'credential' }} returnValidation={this.functionValidation} />
                                        <div className="form-group">
                                            <label htmlFor="Username" className="col-form-label">{i18n.t('username')}</label>
                                            <input type="text" className="form-control" id="Username" placeholder="Enter Username" onChange={this.handleChange} tabIndex="1" autoFocus={true} autoComplete="off" />
                                            <Validation data={{
                                                field: 'Username', errorClass: 'usernameError',
                                                value: this.state.fields.Username, isError: this.state.isError, validationType: 'field'
                                            }} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="Password" className="col-form-label">{i18n.t('password')}</label><Link to="#" onClick={() => this.forgotPassword()} className="forrgot-password-text f-s-13">{i18n.t('forgotPassword')}?</Link>
                                            <input type="password" className="form-control" id="Password" placeholder="Password" onChange={this.handleChange} tabIndex="2" autoComplete="off" />
                                            <Validation data={{
                                                field: 'Password', errorClass: 'passwordError',
                                                value: this.state.fields.Password, isError: this.state.isError, validationType: 'field'
                                            }} />
                                        </div>
                                        <div className="input-group mb-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">{i18n.t('language')}:</span>
                                            </div>
                                            <select className="custom-select" defaultValue="en" onChange={this.handleChange} name="language" id="language" tabIndex="3">
                                                <option value="en">{i18n.t('english')}</option>
                                                <option value="es">{i18n.t('spanish')}</option>
                                            </select>
                                        </div>
                                        {isLoginPending || this.state.isLoading ?
                                            <button type="submit" className="btn btn-success btn-block" disabled tabIndex="4">{i18n.t('pleaseWait')}...</button>
                                            :
                                            <button type="submit" className="btn btn-success btn-block" onClick={this.handleSubmit} tabIndex="4">{i18n.t('submit')}</button>
                                        }
                                        {!loggingIn && <div className="mt-2"><b>{loginError}</b></div>}
                                    </form>
                                </React.Fragment>
                            }
                            {this.state.loginView === 'forgotPassword' &&
                                <React.Fragment>
                                    <form className="login-form" action="#">
                                        <h2 className="mb-0">{i18n.t('forgotPassword')}?</h2>
                                        <p>{i18n.t('enterYourCredentialPassword')}</p>
                                        <div className="form-group">
                                            <label className="col-form-label">{i18n.t('userType')} <span>*</span></label>
                                            <select className="custom-select" name="userRoleType" value={this.state.forgotPasswordFields.userRoleType} onChange={this.handleChangeForgotPassword}>
                                                <option value="student">{i18n.t("student")}</option>
                                                <option value="other">{i18n.t("other")}</option>
                                            </select>
                                        </div>
                                        {this.state.forgotPasswordFields.userRoleType === 'student' &&
                                            <div className="form-group">
                                                <label className="col-form-label">{i18n.t('userId')} <span>*</span></label>
                                                <input type="text" className="form-control" name="userId" value={this.state.forgotPasswordFields.userId} onChange={this.handleChangeForgotPassword} placeholder={i18n.t('enterUserId')} />
                                                <Validation data={{
                                                    field: i18n.t('userId'), errorClass: 'userIdError',
                                                    value: this.state.forgotPasswordFields.userId, isError: this.state.isErrorForgotPassword, validationType: 'field'
                                                }} returnValidation={this.functionValidation} />
                                            </div>
                                        }
                                        <div className="form-group">
                                            <label className="col-form-label">{this.state.forgotPasswordFields.userRoleType === 'student' ? i18n.t('associatedParentEmailId') : i18n.t('loginEmailId')} <span>*</span></label>
                                            <input type="text" className="form-control" name="email" value={this.state.forgotPasswordFields.email} onChange={this.handleChangeForgotPassword} placeholder={i18n.t('enterEmailId')} />
                                            <Validation data={{
                                                field: this.state.forgotPasswordFields.userRoleType === 'student' ? i18n.t('associatedParentEmailId') : i18n.t('loginEmailId'), errorClass: 'emailError',
                                                value: this.state.forgotPasswordFields.email, isError: this.state.isErrorForgotPassword, validationType: 'email'
                                            }} returnValidation={this.functionValidation} />
                                        </div>
                                        {this.state.isProcessFlag ?
                                            <button type="submit" className="btn btn-success btn-block" disabled tabIndex="4">{i18n.t('pleaseWait')}...</button>
                                            : <button type="submit" className="btn btn-success btn-block" onClick={this.handleSubmitForgotPassword}>{i18n.t('sendOtp')}</button>
                                        }
                                        <div className="text-center mt-2">
                                            <Link to="#" onClick={this.loginGo} className="f-s-13"><u>{i18n.t('cancelBackToLogin')}</u></Link>
                                        </div>
                                    </form>
                                </React.Fragment>
                            }
                            {this.state.loginView === 'otpValidation' &&
                                <React.Fragment>
                                    <form className="login-form" action="#">
                                        <h2 className="mb-0">{i18n.t('otpVerfication')}</h2>
                                        <p className="mb-0">{i18n.t('anOtpsentMsg')}:</p>
                                        <p><b className="text-black-primary">{this.state.forgotPasswordFields.email} </b> {i18n.t('checkOtpMsg')}</p>
                                        <div className="form-group">
                                            <label className="col-form-label">{i18n.t('Otp')} <span>*</span></label>
                                            <input type="text" className="form-control" value={this.state.otp || ''} placeholder={i18n.t('enterOtp')} onChange={(e) => { this.setState({ otp: e.target.value }) }} />
                                            <Validation data={{
                                                field: i18n.t('otp'), errorClass: 'otpError', value: this.state.otp, isError: this.state.isErrorOtp, validationType: 'field'
                                            }} returnValidation={this.functionValidation} />
                                        </div>
                                        {this.state.isProcessFlag ?
                                            <button type="submit" className="btn btn-success btn-block" disabled tabIndex="4">{i18n.t('pleaseWait')}...</button>
                                            :
                                            <button type="submit" className="btn btn-success btn-block" onClick={(e) => this.confirmOtp(e)}>{i18n.t('verify')}</button>
                                        }
                                        <div className="text-center mt-2">
                                            <Link to="#" onClick={this.loginGo} className="f-s-13"><u>{i18n.t('cancelBackToLogin')}</u></Link>
                                        </div>
                                    </form>
                                </React.Fragment>
                            }
                            {(this.state.loginView === 'resetPassword' && this.props.confirmForgotPasswordOtp) &&
                                <React.Fragment>
                                    <React.Fragment>
                                        <form className="login-form" action="#">
                                            <h2 className="mb-0">{i18n.t('resetYourPass')}</h2>
                                            <p>{i18n.t('resetPassMsg')}</p>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t("newPassword")} <span>*</span></label>
                                                        <input type="password" id="changedpassword"
                                                            className="form-control newPasswordField"
                                                            value={this.state.newPassword}
                                                            placeholder={i18n.t("enterNewPassword")}
                                                            onChange={(e) => { this.setState({ newPassword: e.target.value }) }}
                                                        />
                                                        <Validation data={{
                                                            field: i18n.t('newPassword'), errorClass: 'newPasswordError', regExp: this.state.regex, minLength: this.state.passwordFields.minCharacterLength,
                                                            maxLength: this.state.passwordFields.maxCharacterLength, value: this.state.newPassword, isError: this.state.isErrorPassword, validationType: 'password'
                                                        }} returnValidation={this.functionValidation} />
                                                    </div>
                                                    <div className="tip text-muted f-s-14">
                                                        <p className="m-0">{i18n.t('psasswordShouldHave')}:</p>
                                                        <ol className="p-l-15">
                                                            <li>Minimum {this.state.passwordFields.minCharacterLength} char and Maximum {this.state.passwordFields.maxCharacterLength}</li>
                                                            {this.state.passwordFields.minOneLowerCase && <li>{i18n.t('minOneSmallLowercaseLetter')}</li>}
                                                            {this.state.passwordFields.minOneUpperCase && <li>{i18n.t('minOneCapitalUppercaseLetter')}</li>}
                                                            {this.state.passwordFields.minOneNumericCharacter && <li>{i18n.t('minOneNumericChar')}</li>}
                                                            {this.state.passwordFields.minOneLetter && <li>{i18n.t('minOneLetter')}</li>}
                                                            {this.state.passwordFields.minOneSpecialCharacter && <li>{i18n.t('minOneSpecialChar') + ' @ # * -'}</li>}
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group mb-2">
                                                        <label className="col-form-label">{i18n.t("reTypeNewPassword")} <span>*</span></label>
                                                        <input type="password" id="reTypeNewPassword"
                                                            value={this.state.reTypeNewPassword}
                                                            className="form-control" placeholder={i18n.t("reTypeNewPassword")}
                                                            onChange={(e) => { this.setState({ reTypeNewPassword: e.target.value }) }}
                                                        />
                                                        <Validation data={{
                                                            field: i18n.t('reTypeNewPassword'), errorClass: 'reTypeNewPasswordError', isError: this.state.isErrorPassword,
                                                            value: this.state.reTypeNewPassword, parentFieldValue: this.state.newPassword, validationType: 'repeatField'
                                                        }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.isProcessFlag ?
                                                <button type="submit" className="btn btn-success btn-block" disabled tabIndex="4">{i18n.t('pleaseWait')}...</button>
                                                :
                                                <button type="submit" className="btn btn-success btn-block" onClick={(e) => this.changePassword(e)}>{i18n.t('updatePassword')}</button>
                                            }
                                            <div className="text-center mt-2">
                                                <Link to="#" onClick={this.loginGo} className="f-s-13"><u>{i18n.t('cancelBackToLogin')}</u></Link>
                                            </div>
                                        </form>
                                    </React.Fragment>
                                </React.Fragment>
                            }
                            <footer className="text-center">
                                <p className="copyrightText">© 2019 wefivesoft. All rights reserved.</p>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.loginStore.token,
        isAuthenticated: state.loginStore.isAuthenticated,
        loggingIn: state.loginStore.isAuthenticated,
        loginError: state.loginStore.errorMessage,
        isLoginPending: state.loginStore.isLoginPending,
        isResourcesLoaded: state.loginStore.isResourcesLoaded,
        getForgotPasswordOtp: state.loginStore.getForgotPasswordOtp,
        confirmForgotPasswordOtp: state.loginStore.confirmForgotPasswordOtp,
        saveConfirmPassword: state.loginStore.saveConfirmPassword,
        isLoadDistrictMenuCallRequired: (state.schoolStore.schoolList && state.schoolStore.schoolList.length === 0) ? true : false,
        schoolList: state.schoolStore.schoolList,
        menuList: state.loginStore.menuList,
        schoolBasedRoleList: state.schoolStore.schoolBasedRoleList,
        schoolTimeZoneNameList: state.schoolStore.schoolTimeZoneNameList,
        getSchoolCountryCode: state.schoolStore.getSchoolCountryCode,
        getSchoolStateCode: state.schoolStore.getSchoolStateCode,
        getDistrictGeneralInfo: state.districtStore.getDistrictGeneralInfo,
        getPasswordPatternByUserId: state.loginStore.getPasswordPatternByUserId
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onResetSchoolProps: (executeArgs) => dispatch(actionsSchool.schoolStoreStateReset(executeArgs)),
        onGetAllSchoolForUser: (executeArgs) => dispatch(actionsSchool.callSchoolListApi(executeArgs, '')),
        onGetByIdSucess: (executeArgs, title) => dispatch(actionsDistrict.callDistrictGetByApi(executeArgs, title)),
        onResetDistrictProps: (executeArgs) => dispatch(actionsDistrict.districtStoreStateReset(executeArgs)),
        onValidateLogin: (executeArgs) => dispatch(actions.validateLogin(executeArgs)),
        onGetLastSession: (executeArgs) => dispatch(actions.getLastSessionOfaUser(executeArgs)),
        onSendOtpSuccess: (executeArgs) => dispatch(actions.getForgotPasswordOtp(executeArgs)),
        onConfirmForgotPasswordOtpSuccess: (executeArgs) => dispatch(actions.confirmForgotPasswordOtp(executeArgs)),
        onSaveConfirmPasswordSuccess: (executeArgs) => dispatch(actions.saveConfirmPassword(executeArgs)),
        onGetLookups: (categoryType) => dispatch(actions.callLookupApi(categoryType)),
        onGetAllMenuSuccess: (executeArgs) => dispatch(actions.getAllMenus(executeArgs)),
        onGetSchoolBasedRoleListByUserId: (executeArgs) => dispatch(actionsSchool.callSchoolListApi(executeArgs, 'Based Role')),
        onGetTimeZone: (executeArgs) => dispatch(actionsSchool.callSchoolListApi(executeArgs, 'Time Zone Name')),
        onResetSchoolProps: (executeArgs) => dispatch(actionsSchool.schoolStoreStateReset(executeArgs)),
        onResetLoginProps: (executeArgs) => dispatch(actions.loginStoreStateReset(executeArgs)),
        onGetCountryCode: (executedArgs) => dispatch(actionsSchool.callSchoolGetByApi(executedArgs, 'Country Code')),
        onGetStateCode: (executedArgs) => dispatch(actionsSchool.callSchoolGetByApi(executedArgs, 'State Code')),
        onGetByUserIdSuccess: (executeArgs) => dispatch(actions.getPasswordPatternByUserId(executeArgs))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);