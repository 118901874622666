import { SharedApiConfig } from "../../../shared/sharedApiConfig";
import commonConfig from '../../../common/commonConfig';
const axios = require('axios');
export const handleDistrictApiFail = (error) => {
    return {
        type: 'HANDLE_DISTRICT_FAIL',
        error: error
    };
};
export const handleDistrictApiSuccess = (fields, title, action) => {
    return {
        type: 'DISTRICT_' + action.toUpperCase(),
        responseData: fields,
        title: title
    };
};
export const callDistrictListApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleDistrictApiSuccess(response.data, title, 'list'));
        }).catch(error => {
            dispatch(handleDistrictApiFail(error));
        });
    };
};
export const callDistrictSaveApi = (input, title, isUpload = false) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig[isUpload ? 'API_TO_UPLOAD_AND_SAVE' : 'API_TO_CAll'],
            headers: isUpload ? {} : SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: isUpload ? input : JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleDistrictApiSuccess(response.data, title, 'save'));
        }).catch(error => {
            dispatch(handleDistrictApiFail(error));
        });
    };
};
export const callDistrictGetByApi = (input, title) => {
   
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleDistrictApiSuccess(response.data, title, 'get'));
        }).catch(error => {
            dispatch(handleDistrictApiFail(error));
        });
    };
};
export const callDistrictDeleteApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleDistrictApiSuccess(response.data, title, 'delete'));
        }).catch(error => {
            dispatch(handleDistrictApiFail(error));
        });
    };
};
export const districtStoreStateReset = (input) => {
    return {
        type: commonConfig.DISTRICT_STORE_RESET,
        responseData: input,
        title: input.name
    };
};