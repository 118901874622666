export class commonSystemConfig {
    static COMPONENT_SCHOOL = 'school';
    static COMPONENT_COURSE = 'course';
    static COMPONENT_SCHEDULE = 'schedule';
    static COMPONENT_STAFF = 'staff';
    static COMPONENT_STUDENT = 'student';
    static COMPONENT_SYSTEM = 'system';
    static COMPONENT_CONTROLPANEL = 'controlpanel';
    static COMPONENT_RESOURCE = 'resource';
    static METHOD_TYPE_POST = 'POST';
    static METHOD_TYPE_GET = 'GET';
    static COMPONENT_GET_SYSTEM_ROLE_LIST = 'v1/manageuser/getrolebyschool?';
    static COMPONENT_GET_SYSTEM_DEPARTMENT_LIST = 'v1/manageuser/getdepartmentbyschool?';
    static COMPONENT_GET_ALL_SUBJECT = 'v1/coursesubjectsoffered/getallcoursesubjectsoffered?';
    static COMPONENT_GET_ALL_COURSE_BY_SUBJECT_IDS = 'v1/courseinfo/getcoursesbysubjectids?SubjectIds=';
    static COMPONENT_GET_ALL_COURSE_SECTION_BY_COURSE_IDS = 'v1/courseinfo/getcoursesectionbycourseids?CourseIds=';
    static COMPONENT_GET_SYSTEM_GRADE_LIST = 'v1/manageuser/getschoolgradelevel?';
    static COMPONENT_GET_SYSTEM_TEACHER_LIST_BY_GRADE_ID = 'v1/manageuser/getparentbygrade?';
    static COMPONENT_GET_SYSTEM_TEACHER_LIST_BY_DEPARTMENT_ID = 'v1/manageuser/getteacherbyschool?';
    static COMPONENT_GET_SYSTEM_TEACHER_LIST_BY_ADMIN = 'v1/manageuser/getdesignatorbyrole?';
    static COMPONENT_GET_SYSTEM_STUDENT_CONTACT_LIST = 'v1/manageuser/getstudentpersoncontactlist?';
    static COMPONENT_GET_SYSTEM_PERSON_BY_ID = 'v1/person/getpersonbyid?';
    static COMPONENT_GET_STUDENT_LIST = 'v1/manageuser/getstudentlist?';
    static COMPONENT_PERSON_UPDATE = 'v1/person/updateperson';
    static COMPONENT_GET_STUDENT_ENROLLMENT_BY_DATE_ID = 'v1/studentenrollment/getstudentenrollmentinfo';
    // lms sync
    static COMPONENT_GET_ALL_SUBJECT_LMS = 'v1/subjectforlmspush/getallsubjectforlmspush';
    static COMPONENT_GET_ALL_COURSE_LMS = 'v1/courseforlmspush/getcourseforlmspushbysubjectid';
    static COMPONENT_GET_ALL_COURSE_SECTION_LMS = 'v1/coursesectionforlmspush/getcoursesectionbycourseid';
    static COMPONENT_GET_ALL_STUDENT_LMS = 'v1/studentforlmspush/getallstudentforlmspush?schoolId=';
    static COMPONENT_GET_ALL_TEACHER_LMS = 'v1/staffforlmspush/getallstaffforlmspush?schoolId=';
    static COMPONENT_GET_ALL_ADMIN_LMS = 'v1/adminforlmspush/getalladminusersforlmspush?schoolId=';
    static COMPONENT_GET_ALL_SYNC_LMS = 'v1/lmssync/getlmssyncdatabyschoolid?schoolId=';
    static COMPONENT_GET_SYNC_LMS_BY_ID = 'v1/lmssync/getlmssyncdatabyid?id=';
    static COMPONENT_GET_ALL_ASSIGNMENT_CATEGORY_LMS = 'v1/assignmentcategoryfromlmspull/getallassignmentcategoryfromlmspull';
    static COMPONENT_GET_ALL_ASSIGNMENT_LMS = 'v1/assignmentfromlmspull/getallassignmentbyassignmentcategoryid';
    static COMPONENT_GET_ALL_GRADE_LMS = 'v1/integrationgradespull/getintegrationgradespulldetails?schoolId=';
    //static COMPONENT_SAVE_SYNC_LMS = 'v1/lmsprocess/lmsprocesssynchronization';
    static COMPONENT_SAVE_SYNC_LMS = 'v1/lmsprocess/lmsprocess';
    static COMPONENT_GET_EXCEL_BY_EXECUTION_ID = 'v1/lmsexecutiondetail/getlmsexecutiondetailexcelbylmsexecutionid?lmsExecutionId=';
    // System Failed Login Attempt Limit
    static COMPONENT_SYSTEM_FAIL_LOGIN_ATTEMPT_SAVE = 'v1/failedloginattemptlimit/savefailedloginattemptlimit';
    static COMPONENT_SYSTEM_FAIL_LOGIN_ATTEMPT_UPDATE = 'v1/failedloginattemptlimit/updatefailedloginattemptlimit';
    // static COMPONENT_SYSTEM_FAIL_LOGIN_ATTEMPT_GET_BY_ID = 'v1/failedloginattemptlimit/getbyschoolid?schoolId=';
    static COMPONENT_SYSTEM_FAIL_LOGIN_ATTEMPT_GET_BY_ID = 'v1/failedloginattemptlimit/getbydistrictid?DistrictId=';
    // password pattern
    static COMPONENT_SAVE_PASSWORD_PATTERN = 'v1/passwordpattern/savepasswordpattern';
    static COMPONENT_UPDATE_PASSWORD_PATTERN = 'v1/passwordpattern/updatepasswordpattern';
    static COMPONENT_GET_PASSWORD_PATTERN_BY_DISTRICT_ID = 'v1/passwordpattern/getbydistrictid?DistrictId=';
    // System Maintenance 
    static COMPONENT_SAVE_SYSTEM_MAINTENANCE = 'v1/systemmaintenance/savesystemmaintenance';
    static COMPONENT_UPDATE_SYSTEM_MAINTENANCE = 'v1/systemmaintenance/updatesystemmaintenance';
    static COMPONENT_GET_SYSTEM_MAINTENANCE_BY_DISTRICTID = 'v1/systemmaintenance/getsystemmaintenancebydistrictid?DistrictId=';
    // System Date Format
    static COMPONENT_SAVE_SYSTEM_DATEFORMAT = 'v1/schooldateformat/saveschooldateformat';
    static COMPONENT_UPDATE_SYSTEM_DATEFORMAT = 'v1/schooldateformat/updateschooldateformat';
    static COMPONENT_GET_SYSTEM_DATEFORMAT_BY_SCHOOLID = 'v1/schooldateformat/getschooldateformatbyschoolid?schoolId=';
    // SMS Permission
    static COMPONENT_SMS_PERMISSION_SAVE = 'v1/smspermission/save';
    static COMPONENT_SMS_PERMISSION_UPDATE = 'v1/smspermission/update';
    static COMPONENT_GET_SMS_PERMISSION_BY_SCHOOL_ID = 'v1/smspermission/getpermissionbyschoolid?schoolId=';
    //mfa permission
    static COMPONENT_GET_MFA_PERMISSION_BY_SCHOOL_ID = 'v1/multifactorauthenticationpermission/getpermissionbyschoolid?schoolId=';
    static COMPONENT_MFA_PERMISSION_SAVE = 'v1/multifactorauthenticationpermission/save';
    static COMPONENT_MFA_PERMISSION_UPDATE = 'v1/multifactorauthenticationpermission/update';
    // Residential facility
    static COMPONENT_STATE_REPORTING_RESIDENTIAL_FACILITY_GET_ALL = 'v1/residentialfacility/getresidentialfacility';
    //upload profile image
    static COMPONENT_SYSTEM_PROFILE_PHOTO_UPLOAD = 'v1/profilephotoupload/updateprofilephoto';
}
export default commonSystemConfig;