import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';
const initialState = {
    systemRoleList: [],
    systemDepartmentList: [],
    systemGradeList: [],
    systemTeacherList: [],
    systemParentList: [],
    systemStudentContactList: [],
    systemAdminList: [],
    systemStudentList: [],
    getSystemPerson: {},
    systemSubjectList: [],
    systemCourseList: [],
    systemCourseSectionList: [],
    systemUserTypeList: [],
    systemSyncList: [],
    getSystemSync: {},
    systemAssignmentCategoryList: [],
    systemAssignmentList: [],
    saveSystemLmsSync: false,
    getSystemExcel: {},
    saveSystemPasswordPattern: false,
    getSystemPasswordPattern: {},
    saveSystemFailedLoginAttemptLimit: {},
    getSystemFailedLoginAttemptLimit: {},
    saveSystemMaintenance: {},
    getSystemMaintenance: {},
    getSystemDateFormat: {},
    saveSystemDateFormat: false,
    saveSystemPermission: false,
    getSystemPermission: {},
    getSystemMfaPermission: {},
    saveSystemMfaPermission: {}
}
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
}
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const reducerSystem = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'SYSTEM_LIST': return handleSuccess(state, action, camelCase(`system_${action.title}_list`));
        case 'SYSTEM_SAVE': return handleSuccess(state, action, camelCase(`save_system_${action.title}`));
        case 'SYSTEM_GET': return handleSuccess(state, action, camelCase(`get_system_${action.title}`));
        case commonConfig.SYSTEM_STORE_RESET: return handleSuccess(state, action, action.title);
        case 'HANDLE_SYSTEM_FAIL': return handleFail(state);
        default: return state;
    }
}
export default reducerSystem;