export class commonAttendanceConfig {
  static COMPONENT_ATTENDANCE = 'attendance';
  static METHOD_TYPE_POST = 'POST';
  static METHOD_TYPE_GET = 'GET';
  //absense letter format
  static COMPONENT_GET_ALL_ABSENSE_LETTER_FORMAT = 'v1/absenceletterformat/getallabsenceletter';
  static COMPONENT_SAVE_ABSENSE_LETTER_FORMAT = 'v1/absenceletterformat/saveabsenceletter';
  static COMPONENT_UPDATE_ABSENSE_LETTER_FORMAT = 'v1/absenceletterformat/updateabsenceletter';
  static COMPONENT_GET_ALL_ATTENDANCE_CATEGORY_SUBCATEGORY = 'v1/perfectattendancereport/getattendancecategorydetails';
  static COMPONENT_GET_ALL_ABSENSE_LETTER_STUDENT = 'v1/absenceletterrun/getabsenceletterviewstudentlists';
  static COMPONENT_GET_ABSENSE_LETTER_FORMAT_BY_ID = 'v1/absenceletterformat/getabsenceletterbyid';
  static COMPONENT_DELETE_ABSENT_LETTER_FORMAT = 'v1/absenceletterformat/deleteabsenceletter';
  static COMPONENT_RUN_ABSENSE_LETTER = 'v1/absenceletterrun/generateabsenceletterrunprocess';
  static COMPONENT_GET_ALL_ABSENSE_LETTER_STUDENT_BY_RUNDATE = 'v1/absenceletterrun/getgeneratedabsenceletterstudentlists';
  static COMPONENT_PUBLISH_ABSENSE_LETTER = 'v1/absenceletterrun/publishbulkstudentabsenceletter';
  static COMPONENT_DELETE_ABSENT_LETTER_SINGLE_RUN = 'v1/absenceletterrun/deletestudentabsenceletter';
  static COMPONENT_DELETE_ABSENT_LETTER_BULK_RUN = 'v1/absenceletterrun/deletebulkstudentabsenceletter';
  static COMPONENT_VIEW_PRINT_ABSENSE_LETTER = 'v1/absenceletterrun/generateconsolidatedpdffileinfos';
  static COMPONENT_GENERATE_ABSENSE_LETTER_EMAIL = 'v1/absenceletterrun/sendabsencelettermailtostudent';
  static COMPONENT_DELETE_ABSENT_LETTER_TEMPLATE = 'v1/absenceletterformat/deletetemplate';
  //get teacher Info for attendance
  static COMPONENT_GET_TEACHER_LIST = 'v1/teacherperiodattendance/getteacherperiodattendancebyfilter';
  static COMPONENT_ATTENDANCE_DOWNLOAD_ATTENDANCE_COUNT_REPORT = 'v1/attendancecountreport/downloadattendancecountreport';
  //get teacher Info for missing attendance
  static COMPONENT_GET_MISSING_ATTN_TEACHER_LIST = 'v1/missingstudentattendance/getteachersbyschoolid?schoolId=';
  //get teacher course Info for missing attendance
  static COMPONENT_GET_MISSING_ATTN_TEACHER_COURSE_LIST = 'v1/missingstudentattendance/getmissingattendanceperiodsbyteacherid?teacherId=';
  static COMPONENT_STUDENT_ATTENDANCE_FILTER = 'v1/studentattendance/getstudentattendancefilters';
  static COMPONENT_STUDENT_ATTENDANCE_LIST = 'v1/studentattendance/getstudentattendancelist';
  //event attendance info
  static COMPONENT_EVENT_ATTENDANCE_SAVE = 'v1/eventattendance/saveeventattendance';
  static COMPONENT_EVENT_ATTENDANCE_GET_EVENT_BY_DATE = 'v1/eventattendance/getalleventbydate?';
  static COMPONENT_EVENT_ATTENDANCE_GET_PROFILE_BY_EVENT = 'v1/eventattendance/getallprofilebyevent?';
  static COMPONENT_EVENT_ATTENDANCE_GET_EVENT_PERSONS = 'v1/eventattendance/geteventpersonsbyfilter?';
  static COMPONENT_EVENT_ATTENDANCE_GET_GRADE_BY_EVENT = 'v1/eventattendance/getassociatedgradesbyevent?';
  static COMPONENT_EVENT_ATTENDANCE_GET_DEPARTMENT_BY_EVENT_ROLE = 'v1/eventattendance/getdepartmentbyeventrole?';
  static COMPONENT_EVENT_ATTENDANCE_GET_PERSON_BY_EVENT_ID = 'v1/eventattendance/getallpersonsbyeventid?';
  //Attendace delete APIS
  static COMPONENT_STUDENT_SECTION_ATTENDANCE_DELETE = 'v1/studentattendance/deletestudentattendance';
  static COMPONENT_STUDENT_STAFF_DAILY_ATTENDANCE_DELETE = 'v1/staffattendance/deletedailyattendance';
  static COMPONENT_TEACHER_PERIOD_WISE_ATTENDANCE_DELETE = 'v1/teacherperiodattendance/deleteteacherperiodattendance';
  static COMPONENT_STUDENT_EXAM_ATTENDANCE_DELETE = 'v1/studentexamattendance/deletestudentexamattendance';
  //Exam Attendance
  static COMPONENT_EXAM_ATTENDANCE_GET_ASSESSMENT_ADMINISTRATION = 'v1/studentexamattendance/getassessmentadministration?schoolSessionId=';
  static COMPONENT_EXAM_ATTENDANCE_GET_ASSESSMENT_SESSION = 'v1/studentexamattendance/getassessmentsession?assessmentAdministrationId=';
  static COMPONENT_EXAM_ATTENDANCE_GET_ASSESSMENT_SESSION_EXAM = 'v1/studentexamattendance/getassessmentsessionexam?assessmentSessionId=';
  static COMPONENT_EXAM_ATTENDANCE_GET_ASSESSMENT_COURSE = 'v1/studentexamattendance/getassessmentcourse?assessmentSessionExamId=';
  static COMPONENT_EXAM_ATTENDANCE_GET_STUDENT_LIST = 'v1/studentexamattendance/getstudentexamattendancedata';
  static COMPONENT_EXAM_ATTENDANCE_SAVE = 'v1/studentexamattendance/savestudentexamattendance';
  static COMPONENT_EXAM_ATTENDANCE_GET_TEACHER_LIST = 'v1/studentexamattendance/getteacherlistforexamattendance';
  static COMPONENT_TEACHER_SAVE_ATTENDANCE = 'v1/teacherperiodattendance/bulkupdateteacherperiodattendance';
  static COMPONENT_STUDENT_SAVE_ATTENDANCE = 'v1/studentattendance/savestudentattendance';
  //View teacher attendance
  static COMPONENT_GET_GRADE_LEVEL_BY_TEACHER_ID = 'v1/attendanceviewfilter/getallgradesforteacherbyid?teacherId=';
  static COMPONENT_GET_SUBJECT_BY_TEACHER_ID = 'v1/attendanceviewfilter/getallsubjectsforteacherbygradeid?teacherId=';
  static COMPONENT_GET_COURSE_BY_SUBJECT_ID = 'v1/attendanceviewfilter/getallcoursesforteacherbysubjectid?teacherId=';
  static COMPONENT_GET_COURSE_SECTION_BY_COURSE_ID = 'v1/attendanceviewfilter/getallcoursesectionsforteacherbycourseid?teacherId=';
  static COMPONENT_GET_PERIOD_BY_COURSE_SECTION_ID = 'v1/attendanceviewfilter/getallperiodsforteacherbycoursesectionid?teacherId=';
  static COMPONENT_GET_ATTENDANCE_BY_FILTER = 'v1/attendanceviewfilter/getviewattendancebyfilters';
  static COMPONENT_GET_STAFF_ATTENDANCE_BY_FILTER = 'v1/staffattendance/getstafflist?schoolId=';
  static COMPONENT_GET_STAFF_ATTENDANCE_INFO_DAYWISE = 'v1/staffattendance/getdayattendancefornonteachingstaff';
  static COMPONENT_GET_ALL_COURSES_FOR_TEACHER_BY_SUBJECTID = 'v1/attendanceviewfilter/getallcoursesforteacherbysubjectid';
  //get subject course Info
  static COMPONENT_GET_SUBJECT_BY_DATE = 'v1/attendanceviewfilter/getallsubjectsbydate?schoolId=';
  static COMPONENT_GET_COURSE_BY_SUBJECT_ID_DATE = 'v1/attendanceviewfilter/getallcoursebysubjectdate?subjectId=';
  static COMPONENT_GET_COURSE_SECTION_PERIOD_BY_COURSE_SECTION_ID_DATE = 'v1/attendanceviewfilter/getallperiodbycoursesectiondate?courseSectionId=';
  //View teacher period attendance
  static COMPONENT_GET_PERIOD = 'v1/teacherperiodattendance/getteacherperiodlist?teacherId=';
  static COMPONENT_GET_SUBJECT = 'v1/teacherperiodattendance/getsubjectsbyperiod?teacherId=';
  static COMPONENT_GET_COURSE = 'v1/teacherperiodattendance/getcoursesbysubject?teacherId=';
  static COMPONENT_GET_COURSE_SECTION = 'v1/teacherperiodattendance/getcoursesectionsbycourse?teacherId=';
  static COMPONENT_GET_ATTENDANCE_LIST = 'v1/teacherperiodattendance/getallperiodforteacher';
  //Missing Teacher Attendance
  static COMPONENT_GET_SUBJECT_BY_PREVIOUS_DATE = 'v1/attendanceviewfilter/getallsubjectsbypreviousdate?schoolId=';
  static COMPONENT_GET_COURSE_BY_SUBJECT_ID_PREVIOUS_DATE = 'v1/attendanceviewfilter/getallcoursebysubjectpreviousdate?subjectId=';
  static COMPONENT_GET_COURSE_SECTION_BY_COURSE_ID_PREVIOUS_DATE = 'v1/attendanceviewfilter/getallcoursesectionbycoursepreviousdate?courseId=';
  static COMPONENT_GET_COURSE_SECTION_PERIOD_BY_COURSE_SECTION_ID_PREVIOUS_DATE = 'v1/attendanceviewfilter/getallperiodbycoursesectionpreviousdate?courseSectionId=';
  static COMPONENT_GET_ALL_MISSING_ATTENDANCE = 'v1/attendanceviewfilter/getmissingattendanceperiodsforteacher';
  //teacher daily attendance
  static COMPONENT_TEACHER_DAILY_ATTENDANCE_LIST = 'v1/staffattendance/getstaffattendancelist';
  static COMPONENT_STUDENT_DAILY_ATTENDANCE_LIST = 'v1/staffattendance/getstudentattendancelist';
  static COMPONENT_SAVE_DAILY_ATTENDANCE = 'v1/staffattendance/saveupdatestaffstudentattendance';
  // student period wise Attendance
  static COMPONENT_GET_STUDENT_PERIODWISE_ATTENDANCE_LIST = 'v1/StudentPeriodWiseAttendance/GetStudentList';
  static COMPONENT_GET_STUDENT_WISE_ATTENDANCE_LIST = 'v1/studentperiodwiseattendance/getstudentwiseattendancelist';
  static COMPONENT_SAVE_STUDENT_PERIOD_WISE_ATTENDANCE_LIST = 'v1/studentperiodwiseattendance/SavePeriodWiseStudentAttendance'
  static COMPONENT_STUDENT_PERIOD_WISE_FILTER_ATTENDANCE_LIST = 'v1/staffinfo/getstaffinfopaginated';
  static COMPONENT_GET_ALL_COURSELIST_BY_SCHOOL_ID = 'v1/studentmassattendance/getallcourseslistbyschoolid?schoolId=';
  static COMPONENT_GET_ALL_COURSESECTION_BY_COURSE_DATE = 'v1/attendanceviewfilter/getallcoursesectionbycoursedate?courseId=';
  static COMPONENT_GET_ALL_PERIODDAILY_ATTENDANCE_SUMMARY = "v1/studentperiodwiseattendance/getstudentperioddailyattendancedata";
  static COMPONENT_GET_ALL_COURSE_ATTENDANCE_SUMMARY = "v1/studentperiodwiseattendance/getstudentcoursesectionwiseattendancedata";
  static COMPONENT_GET_ALL_STUDENT_ATTENDANCE_SUMMARY_LIST = "v1/studentperiodwiseattendance/getfilterstudentwiseattendancedata";
  // student wise period list
  static COMPONENT_GET_STUDENT_WISE_PERIOD_LIST = 'v1/studentperiodwiseattendance/getstudentwiseperiodlist';
  static COMPONENT_GET_STUDENT_ATTENDANCE_DETAILS_LIST = 'v1/studentperiodwiseattendance/getstudentattendancedetailslist';
  static COMPONENT_SAVE_PERIOD_WISE_STUDENT_DETAILS_ATTENDANCE = 'v1/studentperiodwiseattendance/saveperiodwisestudentdetailsattendance';
  // student mass attendance
  static COMPONENT_GET_STUDENT_MASS_ATTENDANCE_LIST = 'v1/studentmassattendance/getmassattendancestudentlist?attendanceDate='
  static COMPONENT_GET_SCHOOL_PERIOD_BY_SESSIONID_LIST = 'v1/schoolperiod/getallperiodbyschoolsessionid';
  static COMPONENT_REVIEW_MASS_STUDENT_ATT_DETAILS = 'v1/studentmassattendance/reviewmassattendancedetails';
  static COMPONENT_SAVE_MASS_STUDENT_ATT_DETAILS = 'v1/studentmassattendance/savemassattendancedetails';
  static COMPONENT_STUDENT_MASS_ATT_EXPORT_INFO = 'v1/studentmassattendance/getstudentattendanceinfoexport'
  static COMPONENT_GET_PERIOD_MASS_ATTENDANCE_LIST = 'v1/SchoolPeriod/GetAllPeriodByAllowAttendance?schoolSessionId='
  // student attendance status and categories
  static COMPONENT_GET_STUDENT_ATTENDANCE_CATEGORY = 'v1/studentattendance/getattendancecategory?lookupCategory=';
  // get course section period list 
  static COMPONENT_GET_COURSE_SECTION_PERIOD_BY_DATE = 'v1/attendanceviewfilter/GetAllCourseSectionPeriodsByDate?schoolId=';
  //reports
  // attendance code setup
  static COMPONENT_ATTENDANCE_CATEGORY_GET_ALL_BY_SCHOOL_ID = 'v1/attendancecategory/getattendancecategorybyschoolid?schoolId=';
  static COMPONENT_ATTENDANCE_CATEGORY_SAVE = 'v1/attendancecategory/saveattendancecategory';
  static COMPONENT_ATTENDANCE_CATEGORY_UPDATE = 'v1/attendancecategory/updateattendancecategory';
  static COMPONENT_ATTENDANCE_CATEGORY_GET_BYID = 'v1/attendancecategory/getattendancecategorybyid?id=';
  static COMPONENT_ATTENDANCE_SUB_CATEGORY_GET_ALL_BY_CATEGORY_ID = 'v1/attendancesubcategory/getallattendancesubcategory?AttendanceCategoryId=';
  static COMPONENT_ATTENDANCE_SUB_CATEGORY_GET_BYID = 'v1/attendancesubcategory/getattendancesubcategorybyid?id=';
  static COMPONENT_ATTENDANCE_SUB_CATEGORY_SAVE = 'v1/attendancesubcategory/saveattendancesubcategory';
  static COMPONENT_ATTENDANCE_SUB_CATEGORY_UPDATE = 'v1/attendancesubcategory/updateattendancesubcategory';
  static COMPONENT_ATTENDANCE_CATEGORY_DELETE = 'v1/attendancecategory/deleteattendancecategory';
  static COMPONENT_ATTENDANCE_SUB_CATEGORY_DELETE = 'v1/attendancesubcategory/deleteattendancesubcategory';
  // attendance category and sub-category
  static COMPONENT_GET_ATTENDANCE_CATEGORY_BY_SCHOOL_ID = 'v1/attendancecategory/getallactiveattendancecategorybyschoolid?schoolId=';
  static COMPONENT_GET_ATTENDANCE_SUB_CATEGORY_BY_CATEGORY_ID = 'v1/attendancesubcategory/getallactiveattendancesubcategory?AttendanceCategoryId=';
  static DOWNLOAD_STAFF_ATTENDANCE_REPORT = "v1/staffattendancereport/downloadstaffattendancereport?";
  // attendance Log and anttendance log details
  static COMPONENT_STUDENT_GET_ALL_BY_SCHOOL_ID = 'v1/studentattendancelog/getstudentattendancelogpaginated';
  static COMPONENT_STUDENT_GET_BY_SCHOOL_ID = 'v1/studentattendancelog/getstudentinfobyid?id=';
  static COMPONENT_STUDENT_SAVE_ATTENDANCE_LOG = 'v1/studentattendancelog/savestudentattendancelog';
  static COMPONENT_STUDENT_UPDATE_ATTENDANCE_LOG = 'v1/studentattendancelog/updatestudentattendancelog';
  static COMPONENT_STUDENT_DELETE_ATTENDANCE_LOG = 'v1/studentattendancelog/deletestudentattendancelog';
  static COMPONENT_STUDENT_GET_ATTENDANCE_LOG_REPORT = 'v1/studentattendancelog/downloadattendancelogreport';
}
export default commonAttendanceConfig;