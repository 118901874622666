import { Link } from 'react-router-dom';
import React from 'react';
import { BaseComponent } from '../../shared/BaseComponent';
import i18n from './../../../i18n';
class SchoolCommonComponant extends BaseComponent {
    // Active menu selection
    getNavLinkClass = (path, exact = true) => {
        if (exact)
            return this.props.location.pathname === path ? 'active' : '';
        else {
            return ((this.props.location.pathname).indexOf(path) !== -1) ? 'active' : '';
        }
    }
    render() {
        return (
            // Add school Info Common Menu
            <aside className="innerpage-nav">
                <ul>
                    <li>
                        <Link to="/school/addSchool/general" className={this.getNavLinkClass("/school/addSchool/general")}> {i18n.t('SCHL_GEN_INFO')} </Link>
                    </li>
                    <li>
                        <Link to="#" className={this.getNavLinkClass("/school/addSchool/addressInfo")}>{i18n.t('SCHL_ADDR_INFO')}</Link>
                    </li>
                    <li>
                        <Link to="#" className={this.getNavLinkClass("/school/addSchool/phoneEmail")}> {i18n.t('SCHL_PHN_EML')} </Link>
                    </li>
                    <li>
                        <Link to="#" className={this.getNavLinkClass("/school/addSchool/designator")}> {i18n.t('SCHL_DESIGNATOR')} </Link>
                    </li>
                    <li>
                        <Link to="#" className={this.getNavLinkClass("/school/addSchool/identifier")}> {i18n.t('SCHL_IDENTIFIER')} </Link>
                    </li>
                    <li>
                        <Link to="#" className={this.getNavLinkClass("/school/addSchool/accreditation")}> {i18n.t('SCHL_ACCREDATIONS')} </Link>
                    </li>
                    <li>
                        <Link to="#" className={this.getNavLinkClass("/school/addSchool/administration")}> {i18n.t('SCHL_ADMINSTRATION')} </Link>
                    </li>
                </ul>
            </aside>
        );
    }
}
export default SchoolCommonComponant;