import { Route } from 'react-router';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sharedActionCreators } from '../components/shared/store/sharedAction';
import asyncComponent from './asyncComponent';
import sessionStore from '../../src/components/common/component/sessionStoreComponent';
import { routeList } from '../components/student/pages/specialProgram/dataStructureConfig';
import commonConfig from '../components/common/commonConfig';
let countryCode = '';
if (!!new sessionStore().returnCountryCode() && new sessionStore().returnCountryCode() === commonConfig.INDIA_COUNTRY_CODE) {
    countryCode = new sessionStore().returnCountryCode() + '/';
}
//SCHOOL
//generalInfo
const addSchoolGenComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/addSchoolGeneralComponent').then(module => module.default)
);
const viewSchoolGenComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/viewSchoolInfoComponent').then(module => module.default)
);
const viewSchoolGeneralInfoComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/viewSchoolGeneralInfoComponent').then(module => module.default)
);
const updateSchoolAddressInfoComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/updateAddressInfoComponent').then(module => module.default)
);
const updateSchoolPhoneEmailComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/updateSchoolPhoneEmailComponent').then(module => module.default)
);
const updateSchoolAdministrationComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/updateSchoolAdministrationComponent').then(module => module.default)
);
const updateSchoolLunchProgramComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/updateSchoolLunchProgramComponent').then(module => module.default)
);
const updateSchoolIdentifierComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/updateSchoolIdentifierComponent').then(module => module.default)
);
const updateSchoolDesignatorComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/updateSchoolDesignatorComponent').then(module => module.default)
);
const updateSchoolAccreditationComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/updateSchoolAccreditationsComponent').then(module => module.default)
);
const updateSchoolStateReportingComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/updateSchoolStateReportingComponent').then(module => module.default)
);
const viewSchoolAdministrationComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/viewSchoolAdministrationComponent').then(module => module.default)
);
const viewSchoolLunchProgramComponent = asyncComponent(() =>
    import('../components/school/pages/generalInfo/viewSchoolLunchProgramComponent').then(module => module.default)
);
const schoolLabelComponent = asyncComponent(() =>
    import('../components/school/pages/label/schoolLabelComponent').then(module => module.default)
);
//customfields
const viewSchoolFieldsComponent = asyncComponent(() =>
    import('../components/school/pages/customfields/viewSchoolFieldsComponent').then(module => module.default)
);
//department
const viewSchoolDepartmentComponent = asyncComponent(() =>
    import('../components/school/pages/department/viewSchoolDepartmentComponent').then(module => module.default)
);
//periods
const viewSchoolMarkingPeriodsComponent = asyncComponent(() =>
    import('../components/school/pages/periods/viewSchoolMarkingPeriodsComponent').then(module => module.default)
);
const viewSchoolPeriodsComponent = asyncComponent(() =>
    import('../components/school/pages/periods/viewSchoolPeriodsComponent').then(module => module.default)
);
//calendars
const calendar = asyncComponent(() =>
    import('../components/school/pages/calendars/viewSchoolCalendarComponent').then(module => module.default)
);
const schoolCalendarCrisis = asyncComponent(() =>
    import('../components/school/pages/calendars/viewSchoolCalendarCrisisComponent').then(module => module.default)
);
const schoolCalendarDays = asyncComponent(() =>
    import('../components/school/pages/calendars/viewSchoolCalendarDaysComponent').then(module => module.default)
);
//rooms
const schoolRooms = asyncComponent(() =>
    import('../components/school/pages/rooms/viewSchoolRoomsComponent').then(module => module.default)
);
//role
const schoolRole = asyncComponent(() =>
    import('../components/school/pages/role/viewSchoolRoleComponent').then(module => module.default)
);
//grades
const schoolGradeLevels = asyncComponent(() =>
    import('../components/school/pages/grades/viewSchoolGradeLevelsComponent').then(module => module.default)
);
const schoolGradeScale = asyncComponent(() =>
    import('../components/school/pages/grades/gradeScaleComponent').then(module => module.default)
);
const schoolGradeScaleLevel = asyncComponent(() =>
    import('../components/school/pages/grades/gradeScaleLevelComponent').then(module => module.default)
);
const schoolDefineGPA = asyncComponent(() =>
    import('../components/school/pages/grades/defineGPAComponent').then(module => module.default)
);
//section
const schoolSection = asyncComponent(() =>
    import('../components/school/pages/section/viewSchoolSectionComponent').then(module => module.default)
);
//session
const schoolSession = asyncComponent(() =>
    import('../components/school/pages/session/viewSchoolSessionComponent').then(module => module.default)
);
//holidays
const SchoolHolidayManagement = asyncComponent(() =>
    import('../components/school/pages/holidays/viewSchoolHolidayManagementComponent').then(module => module.default)
);
const SchoolHolidayManagementDetails = asyncComponent(() =>
    import('../components/school/pages/holidays/viewSchoolHolidayManagementDetailsComponent').then(module => module.default)
);
const schoolListComponent = asyncComponent(() =>
    import('../components/school/pages/schoolListComponent').then(module => module.default)
);
//language
const languageComponent = asyncComponent(() =>
    import('../components/school/pages/language/languageComponent').then(module => module.default)
);
//Announcement List
const addAnnouncementComponent = asyncComponent(() =>
    import('../components/messages/pages/announcement/addAnnouncementComponent').then(module => module.default)
);
const viewAnnouncementListComponent = asyncComponent(() =>
    import('../components/messages/pages/announcement/viewAnnouncementListComponent').then(module => module.default)
);
//State reporting 
const stateReportingExecutionComponent = asyncComponent(() =>
    import('../components/school/pages/statereporting/stateReportingExecutionComponent').then(module => module.default)
);
//District State reporting 
const districtStateReportingExecutionComponent = asyncComponent(() =>
    import('../components/district/pages/statereporting/stateReportingExecutionComponent').then(module => module.default)
);
//District State reporting 
const districtSyncEdfiComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/syncEdfiComponent').then(module => module.default)
);
const districtEdfiSetupComponent = asyncComponent(() =>
    import('../components/district/pages/edfiSetup/edfiSetupComponent').then(module => module.default)
);
//cohort info
const schoolCohortInfoComponent = asyncComponent(() =>
    import('../components/school/pages/cohortInfo/schoolCohortInfoComponent').then(module => module.default)
);
const masterTeacherAssociationComponent = asyncComponent(() =>
    import('../components/school/pages/masterteacher/masterTeacherAssociationComponent').then(module => module.default)
);
//STUDENT
//Add Student
const viewStudentOverviewComponent = asyncComponent(() =>
    import('../components/student/pages/overview/viewStudentOverviewComponent').then(module => module.default)
);
const addStudentGeneralComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/' + countryCode + 'addStudentGeneralComponent').then(module => module.default)
);
const addStudentIdentifierComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/' + countryCode + 'addStudentIdentifierComponent').then(module => module.default)
);
const viewStudentMiscStatusComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/' + countryCode + 'viewStudentMiscStatusComponent').then(module => module.default)
);
const addStudentMiscStatusComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/' + countryCode + 'addStudentMiscStatusComponent').then(module => module.default)
);
const addStudentRefferalComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/addStudentRefferalComponent').then(module => module.default)
);
const addStudentVisaComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/addStudentVisaComponent').then(module => module.default)
);
const addStudentDisabilityComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/addStudentDisabilityComponent').then(module => module.default)
);
const addStudentGuardianComponent = asyncComponent(() =>
    import(`../components/student/pages/generalinfo/${countryCode}addStudentGuardianComponent`).then(module => module.default)
);
const addStudentHealthComponent = asyncComponent(() =>
    import('../components/student/pages/healthinfo/' + countryCode + 'addStudentHealthComponent').then(module => module.default)
);
const viewStudentHealthComponent = asyncComponent(() =>
    import('../components/student/pages/healthinfo/' + countryCode + 'viewStudentHealthComponent').then(module => module.default)
);
const addStudentBirthInfoComponent = asyncComponent(() =>
    import('../components/student/pages/birthinfo/addStudentBirthInfoComponent').then(module => module.default)
);
const viewStudentBirthInfoComponent = asyncComponent(() =>
    import('../components/student/pages/birthinfo/viewStudentBirthInfoComponent').then(module => module.default)
);
const addTeacherSurveyComponent = asyncComponent(() =>
    import('../components/student/pages/survey/addTeacherSurveyComponent').then(module => module.default)
);
const viewTeacherSurveyComponent = asyncComponent(() =>
    import('../components/student/pages/survey/viewTeacherSurveyComponent').then(module => module.default)
);
const addFeedbackComponent = asyncComponent(() =>
    import('../components/student/pages/feedback/addFeedbackComponent').then(module => module.default)
);
const viewFeedbackComponent = asyncComponent(() =>
    import('../components/student/pages/feedback/viewFeedbackComponent').then(module => module.default)
);
const addStudentImmunizationInfoComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/addStudentImmunizationInfoComponent').then(module => module.default)
);
const addStudentEnrollmentInfoComponent = asyncComponent(() =>
    import('../components/student/pages/enrollment/' + countryCode + 'addStudentEnrollmentInfoComponent').then(module => module.default)
);
const viewStudentCohortInfoComponent = asyncComponent(() =>
    import('../components/student/pages/cohort/viewStudentCohortInfoComponent').then(module => module.default)
);
const addStudentEnrollmentProgramGiftComponent = asyncComponent(() =>
    import('../components/student/pages/enrollment/addStudentEnrollmentProgramGiftComponent').then(module => module.default)
);
const viewStudentGeneralComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/' + countryCode + 'viewStudentGeneralComponent').then(module => module.default)
);
const studyMaterial = asyncComponent(() =>
    import('../components/student/pages/studyMaterial/studyMaterialComponent').then(module => module.default)
);
//const addStudentStateReportingComponent = asyncComponent(() =>
//    import('../components/student/pages/statereporting/addStudentStateReportingComponent').then(module => module.default)
//);
const noShowComponent = asyncComponent(() =>
    import('../components/student/pages/noshow/noShowComponent').then(module => module.default)
);
const endYearStatusComponent = asyncComponent(() =>
    import('../components/student/pages/endyearstatus/' + countryCode + 'endYearStatusComponent').then(module => module.default)
);
const specialProgramComponent = asyncComponent(() =>
    import('../components/student/pages/specialProgram/specialProgramComponent').then(module => module.default)
);
const specialProgramModuleComponent = asyncComponent(() =>
    import('../components/student/pages/specialProgram/moduleComponent').then(module => module.default)
);
//Misc Student
const studentListComponent = asyncComponent(() =>
    import('../components/student/pages/' + countryCode + 'studentListComponent').then(module => module.default)
);
const studentMedicalEmergencyComponent = asyncComponent(() =>
    import('../components/student/pages/medicalemergency/studentMedicalEmergencyComponent').then(module => module.default)
);
const studentExitStatusComponent = asyncComponent(() =>
    import('../components/student/pages/exitstatus/studentExitStatusComponent').then(module => module.default)
);
const studentDocumentsUploadComponent = asyncComponent(() =>
    import('../components/student/pages/documentupload/studentDocumentsUploadComponent').then(module => module.default)
);
const studentDocumentVerificationComponent = asyncComponent(() =>
    import('../components/student/pages/documentupload/studentDocumentVerificationComponent').then(module => module.default)
);
const studentDocumentVerificationDetailsComponent = asyncComponent(() =>
    import('../components/student/pages/documentupload/studentDocumentVerificationDetailsComponent').then(module => module.default)
);
const studentHealthScreeningComponent = asyncComponent(() =>
    import('../components/student/pages/healthscreening/studentHealthScreeningComponent').then(module => module.default)
);
const studentHealthScreeningDetailsComponent = asyncComponent(() =>
    import('../components/student/pages/healthscreening/studentHealthScreeningDetailsComponent').then(module => module.default)
);
const studentMassAssignComponent = asyncComponent(() =>
    import('../components/student/pages/massassign/studentMassAssignComponent').then(module => module.default)
);
const studentMassAssignComponentNew = asyncComponent(() =>
    import('../components/student/pages/studentsMassAssign/homeComponent').then(module => module.default)
);
const studentReenrollComponent = asyncComponent(() =>
    import('../components/student/pages/reenroll/studentReenrollComponent').then(module => module.default)
);
const updateStudentGeneralComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/' + countryCode + 'updateStudentGeneralComponent').then(module => module.default)
);
const addStudentEnrollmentEligibilityComponent = asyncComponent(() =>
    import('../components/student/pages/enrollment/addStudentPermissionComponent').then(module => module.default)
);
const studentPromotionComponent = asyncComponent(() =>
    import('../components/student/pages/promotion/' + countryCode + 'studentPromotionComponent').then(module => module.default)
);
const studentCustomFieldComponent = asyncComponent(() =>
    import('../components/student/pages/customfield/studentCustomFieldComponent').then(module => module.default)
);
const studentHealthVisitComponent = asyncComponent(() =>
    import('../components/student/pages/healthVisit/studentHealthVisitComponent').then(module => module.default)
);
const studentHealthVisitAddAdministerComponent = asyncComponent(() =>
    import('../components/student/pages/healthVisit/studentHealthVisitAddAdministerComponent').then(module => module.default)
);
const studentHealthVisitSearchStudentComponent = asyncComponent(() =>
    import('../components/student/pages/healthVisit/studentHealthVisitSearchStudentComponent').then(module => module.default)
);
const studentHealthAddVisitComponent = asyncComponent(() =>
    import('../components/student/pages/healthVisit/studentHealthAddVisitComponent').then(module => module.default)
);
const studentHealthVisitAddMedicationComponent = asyncComponent(() =>
    import('../components/student/pages/healthVisit/studentHealthVisitAddMedicationComponent').then(module => module.default)
);
const studentEnrollmentAdaEligibilityComponent = asyncComponent(() =>
    import('../components/student/pages/report/studentEnrollmentAdaEligibilityComponent').then(module => module.default)
);
const viewStudentAccountAccessComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/viewStudentAccountAccessComponent').then(module => module.default)
);
const addstudentAccountAccessComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/addStudentAccountAccessComponent').then(module => module.default)
);

const mailingLabelsComponent = asyncComponent(() =>
    import('../components/student/pages/report/mailingLabelsComponent').then(module => module.default)
);
const enrollmentDropReportComponent = asyncComponent(() =>
    import('../components/student/pages/report/enrollmentDropReportComponent').then(module => module.default)
);
const studentGraduationPlanComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/studentGraduationPlanComponent').then(module => module.default)
);
const specialProgramGeneralListingComponent = asyncComponent(() =>
    import('../components/student/pages/report/specialProgramGeneralListingComponent').then(module => module.default)
);
const specialProgramDetailListingComponent = asyncComponent(() =>
    import('../components/student/pages/report/specialProgramDetailListingComponent').then(module => module.default)
);
const specialProgramStudentListingComponent = asyncComponent(() =>
    import('../components/student/pages/report/specialProgramStudentListingComponent').then(module => module.default)
);
const studentWithdrawalFormComponent = asyncComponent(() =>
    import('../components/student/pages/report/studentWithdrawalFormComponent').then(module => module.default)
);
const TrexComponent = asyncComponent(() =>
    import('../components/student/pages/statereporting/stateReportingTrexComponent').then(module => module.default)
);
/* Only for Indian School */
const studentGenerateIdCardComponentIN = asyncComponent(() =>
    import('../components/student/pages/idcard/studentGenerateIdCardComponent').then(module => module.default)
);
const studentDesignIdCardComponentIN = asyncComponent(() =>
    import('../components/student/pages/idcard/studentDesignIdCardComponent').then(module => module.default)
);
/************/
const studentStateReportingComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/studentStateReportingComponent').then(module => module.default)
);
const conductCertificateComponent = asyncComponent(() =>
    import('../components/student/pages/report/conductCertificateComponent').then(module => module.default)
);
// Course
const courseManagerSubjectComponent = asyncComponent(() =>
    import('../components/course/pages/courseManagerSubjectComponent').then(module => module.default)
);
const studentCourseCurriculumComponent = asyncComponent(() =>
    import('../components/schedule/pages/studentCourseCurriculumComponent').then(module => module.default)
);
const adminCourseCurriculumComponent = asyncComponent(() =>
    import('../components/schedule/pages/adminCourseCurriculumComponent').then(module => module.default)
);
//Add Staff
const addStaffDemographicComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/' + countryCode + 'addStaffDemographicComponent').then(module => module.default)
);
const addStaffAccountAccessComponent = asyncComponent(() =>
    import('../components/staff/pages/accountaccess/addStaffAccountAccessComponent').then(module => module.default)
);
// const addStaffPhoneComponent = asyncComponent(() =>
//     import('../components/staff/pages/addStaffPhoneComponent').then(module => module.default)
// );
// const addStaffEmailComponent = asyncComponent(() =>
//     import('../components/staff/pages/addStaffEmailComponent').then(module => module.default)
// );
// const addStaffAddressInfoComponent = asyncComponent(() =>
//     import('../components/staff/pages/addStaffAddressInfoComponent').then(module => module.default)
// );
const staffCredentialsVerificationComponent = asyncComponent(() =>
    import('../components/staff/pages/credentialsverification/staffCredentialsVerificationComponent').then(module => module.default)
);
const staffCredentialsVerificationDetailsComponent = asyncComponent(() =>
    import('../components/staff/pages/credentialsverification/staffCredentialsVerificationDetailsComponent').then(module => module.default)
);
const addStaffSchoolRoleAssignmentComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/addStaffSchoolRoleAssignmentComponent').then(module => module.default)
);
// const addStaffAccessInfoComponent = asyncComponent(() =>
//     import('../components/staff/pages/addStaffAccessInfoComponent').then(module => module.default)
// );
const addStaffEmploymentInfoComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/addStaffEmploymentInfoComponent').then(module => module.default)
);
const addStaffContactComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/addStaffContactComponent').then(module => module.default)
);
const addStaffCertificationComponent = asyncComponent(() =>
    import('../components/staff/pages/certificateverification/addStaffCertificationComponent').then(module => module.default)
);
const addStaffDocumentVerificationComponent = asyncComponent(() =>
    import('../components/staff/pages/documentverification/addStaffDocumentVerificationComponent').then(module => module.default)
);
const addStaffVisaComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/addStaffVisaComponent').then(module => module.default)
);
const addStaffTeachingExperienceComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/addStaffTeachingExperienceComponent').then(module => module.default)
);
const addStaffCredentialComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/addStaffCredentialComponent').then(module => module.default)
);
const updateStaffAuthorizationComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/updateStaffAuthorizationComponent').then(module => module.default)
);
const updateStaffMiscSkillsComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/updateStaffMiscSkillsComponent').then(module => module.default)
);
const addStaffPersonalCredentialComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/addStaffPersonalCredentialComponent').then(module => module.default)
);
const addStaffIdentifierComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/' + countryCode + 'addStaffIdentifierComponent').then(module => module.default)
);
const staffCredentialsComponent = asyncComponent(() =>
    import('../components/staff/pages/credentials/homeComponent').then(module => module.default)
);
//view Staff
const viewStaffDemographicComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/' + countryCode + 'viewStaffDemographicComponent').then(module => module.default)
);
const viewStaffAccountAccessComponent = asyncComponent(() =>
    import('../components/staff/pages/accountaccess/viewStaffAccountAccessComponent').then(module => module.default)
);
const viewStaffEmploymentInfoComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/viewStaffEmploymentInfoComponent').then(module => module.default)
);
const viewStaffTeachingExperienceComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/viewStaffTeachingExperienceComponent').then(module => module.default)
);
const viewStaffAuthorizationComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/viewStaffAuthorizationComponent').then(module => module.default)
);
const viewStaffMiscSkillsComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/viewStaffMiscSkillsComponent').then(module => module.default)
);
//Misc Staff
const staffListComponent = asyncComponent(() =>
    import('../components/staff/pages/' + countryCode + 'staffListComponent').then(module => module.default)
);
const staffCustomFieldComponent = asyncComponent(() =>
    import('../components/staff/pages/customfields/staffCustomFieldComponent').then(module => module.default)
);
const staffCalendarComponent = asyncComponent(() =>
    import('../components/staff/pages/calendar/staffCalendarComponent').then(module => module.default)
);
const updateStaffDemographicInfo = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/' + countryCode + 'updateStaffDemographicInfoComponent').then(module => module.default)
);
const updateStaffStateReportingComponent = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/updateStaffStateReportingComponent').then(module => module.default)
);
const updateStaffEmploymentInfo = asyncComponent(() =>
    import('../components/staff/pages/demographicInfo/updateStaffEmploymentInfoComponent').then(module => module.default)
);
const staffJobsComponent = asyncComponent(() =>
    import('../components/staff/pages/jobs/homeComponent').then(module => module.default)
);
const staffJobCategoryComponent = asyncComponent(() =>
    import('../components/staff/pages/jobcategory/staffJobCategoryComponent').then(module => module.default)
);
const staffJobCertificationComponent = asyncComponent(() =>
    import('../components/staff/pages/jobeducation/homeComponent').then(module => module.default)
);
const staffLeaveRequestsComponent = asyncComponent(() =>
    import('../components/staff/pages/leaverequests/staffLeaveRequestsComponent').then(module => module.default)
);
const staffLeaveRequestDetailsComponent = asyncComponent(() =>
    import('../components/staff/pages/leaverequests/staffLeaveRequestDetailsComponent').then(module => module.default)
);
const staffMyLeavesComponent = asyncComponent(() =>
    import('../components/staff/pages/leavedetails/staffMyLeavesComponent').then(module => module.default)
);
const staffMyLeaveDetailsComponent = asyncComponent(() =>
    import('../components/staff/pages/leavedetails/staffMyLeaveDetailsComponent').then(module => module.default)
);
const staffCertificateVerificationComponent = asyncComponent(() =>
    import('../components/staff/pages/certificateverification/staffCertificateVerificationComponent').then(module => module.default)
);
const staffCertificateVerificationDetailsComponent = asyncComponent(() =>
    import('../components/staff/pages/certificateverification/staffCertificateVerificationDetailsComponent').then(module => module.default)
);
const staffDocumentVerificationComponent = asyncComponent(() =>
    import('../components/staff/pages/documentverification/staffDocumentVerificationComponent').then(module => module.default)
);
const staffDocumentVerificationDetailsComponent = asyncComponent(() =>
    import('../components/staff/pages/documentverification/staffDocumentVerificationDetailsComponent').then(module => module.default)
);
const holidayListPublicationComponent = asyncComponent(() =>
    import('../components/staff/pages/holidayconfirmation/holidayListPublicationComponent').then(module => module.default)
);
const holidayListPublicationViewComponent = asyncComponent(() =>
    import('../components/staff/pages/holidayconfirmation/holidayListPublicationViewComponent').then(module => module.default)
);
/* Only for Indian School */
const staffGenerateIdCardComponentIN = asyncComponent(() =>
    import('../components/staff/pages/idcard/staffGenerateIdCardComponent').then(module => module.default)
);
const staffDesignIdCardComponentIN = asyncComponent(() =>
    import('../components/staff/pages/idcard/staffDesignIdCardComponent').then(module => module.default)
);
/**********/
const MarkersCommonFileUpload = asyncComponent(() =>
    import('../components/common/component/markersFileUpload').then(module => module.default)
);
const MarkersCommonRoute = asyncComponent(() =>
    import('../components/common/component/markersCommonRoute').then(module => module.default)
);
//Schedule
const individualScheduleStudentListComponent = asyncComponent(() =>
    import('../components/schedule/pages/individualScheduleStudentListComponent').then(module => module.default)
);
const individualScheduleStudentDetailsComponent = asyncComponent(() =>
    import('../components/schedule/pages/individualScheduleStudentDetailsComponent').then(module => module.default)
);
const teacherReassignmentComponent = asyncComponent(() =>
    import('../components/schedule/pages/teacherReassignmentComponent').then(module => module.default)
);
const requestApprovalComponent = asyncComponent(() =>
    import('../components/schedule/pages/requestApprovalComponent').then(module => module.default)
);
const massStudentDropComponent = asyncComponent(() =>
    import('../components/schedule/pages/massStudentDropComponent').then(module => module.default)
);
const courseSectionWiseAllocationComponent = asyncComponent(() =>
    import('../components/schedule/pages/courseSectionWiseAllocationComponent').then(module => module.default)
);
const sectionAllocationProcessComponent = asyncComponent(() =>
    import('../components/schedule/pages/sectionAllocationProcessComponent').then(module => module.default)
);
const studentWiseAllocationComponent = asyncComponent(() =>
    import('../components/schedule/pages/studentWiseAllocationComponent').then(module => module.default)
);
const allocateCourseSectionComponent = asyncComponent(() =>
    import('../components/schedule/pages/allocateCourseSectionComponent').then(module => module.default)
);
const massStudentScheduleComponent = asyncComponent(() =>
    import('../components/schedule/pages/massStudentScheduleComponent').then(module => module.default)
);
const studentSchedulerComponent = asyncComponent(() =>
    import('../components/schedule/pages/studentSchedulerComponent').then(module => module.default)
);
const studentSchedulerResultsComponent = asyncComponent(() =>
    import('../components/schedule/pages/studentSchedulerResultsComponent').then(module => module.default)
);
const studentSchedulerResultsStudentDetailsComponent = asyncComponent(() =>
    import('../components/schedule/pages/studentSchedulerResultsStudentDetailsComponent').then(module => module.default)
);
const studentsMissingCourseRequestsOrScheduleComponent = asyncComponent(() =>
    import('../components/schedule/pages/studentsMissingCourseRequestsOrScheduleComponent').then(module => module.default)
);
const studentScheduleReportComponent = asyncComponent(() =>
    import('../components/schedule/pages/studentScheduleReportComponent').then(module => module.default)
);
const gradeListingComponent = asyncComponent(() =>
    import('../components/schedule/pages/gradeListingComponent').then(module => module.default)
);
const masterScheduleGridComponent = asyncComponent(() =>
    import('../components/schedule/pages/masterScheduleGridComponent').then(module => module.default)
);
//Atendance 
const takeAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/takeAttendanceComponent').then(module => module.default)
);
const missingAttendanceStudentTeacherViewComponent = asyncComponent(() =>
    import('../components/attendance/pages/missingAttendanceStudentTeacherViewComponent').then(module => module.default)
);
const takeTeacherAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/takeTeacherAttendanceComponent').then(module => module.default)
);
const takeTeacherAttendanceAddComponent = asyncComponent(() =>
    import('../components/attendance/pages/takeTeacherAttendanceAddComponent').then(module => module.default)
);
const adminMissingAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/adminMissingAttendanceComponent').then(module => module.default)
);
const teacherMissingAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/teacherMissingAttendanceComponent').then(module => module.default)
);
const eventAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/eventAttendanceComponent').then(module => module.default)
);
const adminMissingAttendanceTeacherListComponent = asyncComponent(() =>
    import('../components/attendance/pages/adminMissingAttendanceTeacherListComponent').then(module => module.default)
);
const takeStudentAttendanceAdminViewComponent = asyncComponent(() =>
    import('../components/attendance/pages/takeStudentAttendanceAdminViewComponent').then(module => module.default)
);
const studentAttendanceAdminViewComponent = asyncComponent(() =>
    import('../components/attendance/pages/studentAttendanceAdminViewComponent').then(module => module.default)
);
const viewAttendanceTeacherComponent = asyncComponent(() =>
    import('../components/attendance/pages/viewAttendanceTeacherComponent').then(module => module.default)
);
const adminViewAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/adminViewAttendanceComponent').then(module => module.default)
);
const staffMyAttendancePeriodViewComponent = asyncComponent(() =>
    import('../components/attendance/pages/staffMyAttendancePeriodViewComponent').then(module => module.default)
);
const viewStaffAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/viewStaffAttendanceComponent').then(module => module.default)
);
const viewStaffAttendanceYearViewComponent = asyncComponent(() =>
    import('../components/attendance/pages/viewStaffAttendanceYearViewComponent').then(module => module.default)
);
const examAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/examAttendanceComponent').then(module => module.default)
);
const examAttendanceAdminComponent = asyncComponent(() =>
    import('../components/attendance/pages/examAttendanceAdminComponent').then(module => module.default)
);
const examAttendanceTakeAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/examAttendanceTakeAttendanceComponent').then(module => module.default)
);
const dailyAttendanceStaffComponent = asyncComponent(() =>
    import('../components/attendance/pages/dailyAttendanceStaffComponent').then(module => module.default)
);
const dailyAttendanceStudentComponent = asyncComponent(() =>
    import('../components/attendance/pages/dailyAttendanceStudentComponent').then(module => module.default)
);
const studentPeriodwiseAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/studentPeriodwiseAttendanceComponent').then(module => module.default)
);
const studentAttendanceMonthViewComponent = asyncComponent(() =>
    import('../components/attendance/pages/studentAttendanceMonthViewComponent').then(module => module.default)
);
const massStudentAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/massStudentAttendanceComponent').then(module => module.default)
);
const attendancePercentageComponent = asyncComponent(() =>
    import('../components/attendance/pages/report/attendancePercentageComponent').then(module => module.default)
);
const setupCodeComponent = asyncComponent(() =>
    import('../components/attendance/pages/setupCodeComponent').then(module => module.default)
);
const setupCodeDetailsComponent = asyncComponent(() =>
    import('../components/attendance/pages/setupCodeDetailsComponent').then(module => module.default)
);
const perfectAttendanceComponent = asyncComponent(() =>
    import('../components/attendance/pages/report/perfectAttendanceComponent').then(module => module.default)
);
const periodDailyAttendanceSummaryComponent = asyncComponent(() =>
    import('../components/attendance/pages/periodDailyAttendanceSummaryComponent').then(module => module.default)
);
const courseAttendanceSummaryComponent = asyncComponent(() =>
    import('../components/attendance/pages/courseAttendanceSummaryComponent').then(module => module.default)
);
//Reports
const adHocReportingComponent = asyncComponent(() =>
    import('../components/reports/pages/adHocReportingNewComponent').then(module => module.default)
);
//Discipline
const reportIncidentComponent = asyncComponent(() =>
    import('../components/discipline/pages/reportIncidentComponent').then(module => module.default)
);
const studentDisciplineComponent = asyncComponent(() =>
    import('../components/discipline/pages/studentDisciplineComponent').then(module => module.default)
);
const manageIncidentComponent = asyncComponent(() =>
    import('../components/discipline/pages/manageIncidentComponent').then(module => module.default)
);
const incidentDetailsComponent = asyncComponent(() =>
    import('../components/discipline/pages/incidentDetailsComponent').then(module => module.default)
);
const incidentApprovalComponent = asyncComponent(() =>
    import('../components/discipline/pages/incidentApprovalComponent').then(module => module.default)
);
//Assessment
const assessmentGroupComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentGroupComponent').then(module => module.default)
);
const assessmentInfoComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentInfoComponent').then(module => module.default)
);
const assessmentAccomodationComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentAccomodationComponent').then(module => module.default)
);
const assessmentInfoDetailsComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentInfoDetailsComponent').then(module => module.default)
);
const assessmentSessionComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentSessionComponent').then(module => module.default)
);
const assessmentAdministrationComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentAdministrationComponent').then(module => module.default)
);
const assessmentSessionExamComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentSessionExamComponent').then(module => module.default)
);
const assessmentAssetsComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentAssetsComponent').then(module => module.default)
);
const adminAssessmentRegistrationComponent = asyncComponent(() =>
    import('../components/assessment/pages/adminAssessmentRegistrationComponent').then(module => module.default)
);
const assessmentResultComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentResultComponent').then(module => module.default)
);
const assessmentEndOfYearSummaryComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentEndOfYearSummaryComponent').then(module => module.default)
);
const viewtranscriptComponent = asyncComponent(() =>
    import('../components/assessment/pages/viewTranscriptComponent').then(module => module.default)
);
const transcriptComponent = asyncComponent(() =>
    import('../components/assessment/pages/transcriptComponent').then(module => module.default)
);
const publishTranscriptComponent = asyncComponent(() =>
    import('../components/assessment/pages/publishTranscriptComponent').then(module => module.default)
);
const reportCardComponent = asyncComponent(() =>
    import('../components/assessment/pages/reportCardComponent').then(module => module.default)
);
const gradebookReportComponent = asyncComponent(() =>
    import('../components/assessment/pages/gradebookReport/homeComponent').then(module => module.default)
);
const viewReportCardComponent = asyncComponent(() =>
    import('../components/assessment/pages/viewReportCardComponent').then(module => module.default)
);
const publishReportCardComponent = asyncComponent(() =>
    import('../components/assessment/pages/publishReportCardComponent').then(module => module.default)
);
const assignmentCategoryComponent = asyncComponent(() =>
    import('../components/assessment/pages/assignmentCategoryComponent').then(module => module.default)
);
const assessmentRubricComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentRubric/assessmentRubricComponent').then(module => module.default)
);
const assignmentDetailsComponent = asyncComponent(() =>
    import('../components/assessment/pages/assignmentDetailsComponent').then(module => module.default)
);
const gradebookComponent = asyncComponent(() =>
    import('../components/assessment/pages/gradebookComponent').then(module => module.default)
);
const calculateRankComponent = asyncComponent(() =>
    import('../components/assessment/pages/GPA/calculateRankComponent').then(module => module.default)
);
const rankResultComponent = asyncComponent(() =>
    import('../components/assessment/pages/GPA/calculateRankResultComponent').then(module => module.default)
);
const maintainRankComponent = asyncComponent(() =>
    import('../components/assessment/pages/GPA/maintainRankComponent').then(module => module.default)
);
const studentGpaRankComponent = asyncComponent(() =>
    import('../components/assessment/pages/GPA/studentGpaRankComponent').then(module => module.default)
);
const studentGpaRankDetailsComponent = asyncComponent(() =>
    import('../components/assessment/pages/GPA/studentGpaRankDetailsComponent').then(module => module.default)
);
const transcriptMassDeleteComponent = asyncComponent(() =>
    import('../components/assessment/pages/transcript/transcriptMassDeleteComponent').then(module => module.default)
);
const transcriptGradesComponent = asyncComponent(() =>
    import('../components/assessment/pages/transcript/transcriptGradesComponent').then(module => module.default)
);
const studentTranscriptComponent = asyncComponent(() =>
    import('../components/assessment/pages/transcript/studentTranscriptComponent').then(module => module.default)
);
const studentTranscriptDetailsComponent = asyncComponent(() =>
    import('../components/assessment/pages/transcript/studentTranscriptDetailsComponent').then(module => module.default)
);
const calculateGpaComponent = asyncComponent(() =>
    import('../components/assessment/pages/calculateGpaComponent').then(module => module.default)
);
const gpaProcessGpaResultsComponent = asyncComponent(() =>
    import('../components/assessment/pages/gpaProcessGpaResultsComponent').then(module => module.default)
);
const assignmentActivityComponent = asyncComponent(() =>
    import('../components/assessment/pages/assignmentActivityComponent').then(module => module.default)
);
const assignmentsComponent = asyncComponent(() =>
    import('../components/assessment/pages/assignmentsComponent').then(module => module.default)
);
const assessmentGradingTeacherComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentGradingTeacherComponent').then(module => module.default)
);
const assessmentGradingTeacherDetailsComponent = asyncComponent(() =>
    import('../components/assessment/pages/assessmentGradingTeacherDetailsComponent').then(module => module.default)
);
// Dashboard 
const dashboardComponents = asyncComponent(() =>
    import('../components/dashboard/pages/dashboardComponents').then(module => module.default)
);
// System 
const roleBasedAccessControlComponent = asyncComponent(() =>
    import('../components/system/pages/roleBasedAccessControlComponent').then(module => module.default)
);
const uploadProfileImageComponent = asyncComponent(() =>
    import('../components/system/pages/uploadProfileImageComponent').then(module => module.default)
);
const managePersonComponent = asyncComponent(() =>
    import('../components/system/pages/managePersonComponent').then(module => module.default)
);
const rolloverComponent = asyncComponent(() =>
    import('../components/school/pages/rollover/rolloverComponent').then(module => module.default)
);
const uploadReportCardTemplateComponent = asyncComponent(() =>
    import('../components/system/pages/uploadReportCardTemplateComponent').then(module => module.default)
);
const dayCodeComponent = asyncComponent(() =>
    import('../components/system/pages/dayCodeComponent').then(module => module.default)
);
const lmsSetupComponent = asyncComponent(() =>
    import('../components/system/pages/lmsSetupComponent').then(module => module.default)
);
const lmsSyncComponent = asyncComponent(() =>
    import('../components/system/pages/lmsSynchronizationComponent').then(module => module.default)
);
const permissionsComponent = asyncComponent(() =>
    import('../components/system/pages/permissionsComponent').then(module => module.default)
);
const selectScheduleTypeComponent = asyncComponent(() =>
    import('../components/system/pages/controlpanel/selectScheduleTypeComponent').then(module => module.default)
);

const failedLoginAttemptLimitComponent = asyncComponent(() =>
    import('../components/system/pages/controlpanel/failedLoginAttemptLimitComponent').then(module => module.default)
);
const systemMaintenanceModeComponent = asyncComponent(() =>
    import('../components/system/pages/controlpanel/systemMaintenanceModeComponent').then(module => module.default)
);
const setPasswordPatternComponent = asyncComponent(() =>
    import('../components/system/pages/controlpanel/setPasswordPatternComponent').then(module => module.default)
);
//const dcpStudentIdentifiersComponent = asyncComponent(() =>
//    import('../components/system/pages/dcpStudentIdentifiersComponent').then(module => module.default)
//);
const defaultDateFormatComponent = asyncComponent(() =>
    import('../components/system/pages/defaultDateFormatComponent').then(module => module.default)
);
{/*Progress Report Card*/ }
const progressReportCardComponent = asyncComponent(() =>
    import('../components/assessment/pages/publishTranscript/progressReportCardComponent').then(module => module.default)
);
{/* Progress Report Card*/ }
const generateProgressReportCardComponent = asyncComponent(() =>
    import('../components/assessment/pages/publishTranscript/homeComponent').then(module => module.default)
);
const publishProgressReportCardComponent = asyncComponent(() =>
    import('../components/assessment/pages/publishProgressReportCardComponent').then(module => module.default)
);
// Messages
const sentMessagesComponent = asyncComponent(() =>
    import('../components/messages/pages/sentMessagesComponent').then(module => module.default)
);
const receivedMessagesComponent = asyncComponent(() =>
    import('../components/messages/pages/receivedMessagesComponent').then(module => module.default)
);
const sentEmailComponent = asyncComponent(() =>
    import('../components/messages/pages/sentEmailComponent').then(module => module.default)
);
const textMessageComponent = asyncComponent(() =>
    import('../components/messages/pages/textMessage/homeComponent').then(module => module.default)
);
const dynamicFormComponents = asyncComponent(() =>
    import('../components/common/component/dynamicForm/dynamicFormCheck').then(module => module.default)
);
// section grading
const sectionGradingAdminViewComponent = asyncComponent(() =>
    import('../components/assessment/pages/sectionGradingAdminViewComponent').then(module => module.default)
);
const updateSectionGradingComponent = asyncComponent(() =>
    import('../components/assessment/pages/updateSectionGradingComponent').then(module => module.default)
);
const viewSectionGradingComponent = asyncComponent(() =>
    import('../components/assessment/pages/viewSectionGradingComponent').then(module => module.default)
);
// District 
const studentStaffCodeComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/studentStaffCodeComponent').then(module => module.default)
);
const allowAdminEditPreviousSessionComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/allowAdminEditPreviousSessionComponent').then(module => module.default)
);
const dcpStudentIdentifiersComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/' + countryCode + 'dcpStudentIdentifiersComponent').then(module => module.default)
);
const dcpStaffIdentifiersComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/' + countryCode + 'dcpStaffIdentifiersComponent').then(module => module.default)
);
const setDefaultValuesComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/setDefaultValuesComponent').then(module => module.default)
);
const districtProgramsComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/districtProgramsComponent').then(module => module.default)
);
const allowTeacherEditAttendanceComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/allowTeacherEditAttendanceComponent').then(module => module.default)
);
const serviceIDsComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/serviceIDsComponent').then(module => module.default)
);
const studentNameDisplayPatternComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/studentNameDisplayPatternComponent').then(module => module.default)
);
const staffNameDisplayPatternComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/staffNameDisplayPatternComponent').then(module => module.default)
);
const courseExplanationComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/courseExplanationComponent').then(module => module.default)
);
const addTranscriptSealComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/addTranscriptSealComponent').then(module => module.default)
);
const districtAttendancePercentageReport = asyncComponent(() =>
    import('../components/district/pages/report/districtAttendancePercentageReport/homeComponent.jsx').then(module => module.default)
);

// Assignment Bank
const assignmentBankComponent = asyncComponent(() =>
    import('../components/assessment/pages/assignmentBankComponent').then(module => module.default)
);
// Assignment Group
const assignmentGroupComponent = asyncComponent(() =>
    import('../components/assessment/pages/assignmentGroupComponent').then(module => module.default)
);
//STUDY MATERIAL
const courseMaterialComponent = asyncComponent(() =>
    import('../components/studymaterial/pages/courseMaterialComponent').then(module => module.default)
);
const assignmentMaterialComponent = asyncComponent(() =>
    import('../components/studymaterial/pages/assignmentMaterialComponent').then(module => module.default)
);
const assignmentItemsComponent = asyncComponent(() =>
    import('../components/assessment/pages/assignmentItemsComponent').then(module => module.default)
);
const assignmentItemsChildComponent = asyncComponent(() =>
    import('../components/assessment/pages/assignmentItemsChildComponent').then(module => module.default)
);
const addDistrictGeneralInfoComponent = asyncComponent(() =>
    import('../components/district/pages/adddistrict/addDistrictGeneralInfoComponent').then(module => module.default)
);
const addDistrictAddressInfoComponent = asyncComponent(() =>
    import('../components/district/pages/adddistrict/addDistrictAddressInfoComponent').then(module => module.default)
);
const districtPhoneEmailComponent = asyncComponent(() =>
    import('../components/district/pages/adddistrict/districtPhoneEmailComponent').then(module => module.default)
);
const districtDesignatorsComponent = asyncComponent(() =>
    import('../components/district/pages/adddistrict/districtDesignatorsComponent').then(module => module.default)
);
const districtIdentifiersComponent = asyncComponent(() =>
    import('../components/district/pages/adddistrict/districtIdentifiersComponent').then(module => module.default)
);
const districtStateReportingComponent = asyncComponent(() =>
    import('../components/district/pages/adddistrict/districtStateReportingComponent').then(module => module.default)
);
const allowMultipleScheduleComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/allowMultipleScheduleComponent').then(module => module.default)
);
const graduationPlanComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/graduationPlanComponent').then(module => module.default)
);
{/*Generate transcript*/ }
const generateTranscriptComponent = asyncComponent(() =>
    import('../components/assessment/pages/transcript/generateTranscript/homeComponent').then(module => module.default)
);

{/*Standard Grading - Add Standards*/ }
const addStandardsComponent = asyncComponent(() =>
    import('../components/school/pages/addStandards/addStandardsComponent').then(module => module.default)
);

{/*Standard Grading - Add Standard Set*/ }
const addStandardSetComponent = asyncComponent(() =>
    import('../components/school/pages/addStandardSet/addStandardSetComponent').then(module => module.default)
);
{/*Standard Grading - Add Standard Group*/ }
const standardGroupComponent = asyncComponent(() =>
    import('../components/school/pages/standardGroup/standardGroupComponent').then(module => module.default)
);

{/*School > Control Panel > Comment Code Setup*/ }
const commentCodeSetupComponent = asyncComponent(() =>
    import('../components/school/pages/controlPanel/commentCodeSetupComponent').then(module => module.default)
);
const allowMultipleStudentScheduleComponent = asyncComponent(() =>
    import('../components/school/pages/controlPanel/allowMultipleStudentScheduleComponent').then(module => module.default)
);
const uploadMaximumFileComponent = asyncComponent(() =>
    import('../components/district/pages/districtcontrolpanel/defaultFileSizeComponent').then(module => module.default)
);
const studentUsernameSetupComponent = asyncComponent(() =>
    import('../components/school/pages/controlPanel/studentUsernameSetupComponent').then(module => module.default)
);
{/*Document Upload menu under the Student 360 view*/ }
const addStudentDocumentUploadComponent = asyncComponent(() =>
    import('../components/student/pages/generalinfo/addStudentDocumentUploadComponent').then(module => module.default)
);

{/*Standard Grading - Add Standard Scale*/ }
const addStandardScaleComponent = asyncComponent(() =>
    import('../components/school/pages/addStandardScale/addStandardScaleComponent').then(module => module.default)
);

{/*Standard Grading - Teacher View - Grade by Student*/ }
const StandardGradingComponent = asyncComponent(() =>
    import('../components/assessment/pages/standardGrading/standardGradingComponent').then(module => module.default)
);
{/* Scheduling > Report > Class Roster */ }
const classRosterComponent = asyncComponent(() =>
    import('../components/schedule/pages/teacherClassRoster/classRosterComponent').then(module => module.default)
);

// District System Lookup Values
const systemLookupvaluesComponent = asyncComponent(() =>
    import('../components/district/pages/systemLookupValues/systemLookupvaluesComponent').then(module => module.default)
);

//Attendance > Report > Attendance count Report
const attendanceCountReportComponent = asyncComponent(() =>
    import('../components/attendance/pages/attendanceCountReport/homeComponent').then(module => module.default)
);

const staffAttendanceReportComponent = asyncComponent(() =>
    import('../components/attendance/pages/staffAttendanceReport/homeComponent').then(module => module.default)
);

const studentTestDataComponent = asyncComponent(() =>
    import('../components/assessment/pages/studentTestData/homeComponent').then(module => module.default)
);
{/* Scheduling > Report > Class Roster */ }
const classRosterAdminComponent = asyncComponent(() =>
    import('../components/schedule/pages/classRoster/homeComponent').then(module => module.default)
);
{
    /*District > Tests > Test Format*/
}
const testFormatComponent = asyncComponent(() => import("../components/district/pages/testFormat/testFormatComponent").then((module) => module.default));
/*School > ControPanel > School Options*/
const schoolOptionsComponent = asyncComponent(() =>
    import('../components/school/pages/controlPanel/schoolOptions/homeComponent').then(module => module.default)
);
{/* District > District Control Panel > Graduation Plan */ }
const graduationPlanNewComponent = asyncComponent(() =>
    import('../components/district/pages/graduationPlanNew/homeComponent').then(module => module.default)
);
const courseAndCategoryAssociateComponent = asyncComponent(() =>
    import('../components/district/pages/graduationPlanNew/courseAndCategoryAssociate').then(module => module.default)
);
{/* school > timezoneSetup */ }
const timezoneSetupComponent = asyncComponent(() =>
    import('../components/school/pages/timezoneSetup/homeComponent').then(module => module.default)
);
{/* Assessment > GPA Report */ }
const homeComponent = asyncComponent(() =>
    import('../components/assessment/pages/gpaReport/homeComponent').then(module => module.default)
);


const categoriesComponent = asyncComponent(() => import('../components/district/pages/graduationPlanNew/categoriesComponent').then(module => module.default));




{/* Assessment > Report > Rank Report */ }
const rankReportComponent = asyncComponent(() =>
    import('../components/assessment/pages/rankReport/homeComponent').then(module => module.default)
);
{/* Student > MassAssign */ }
const studentsMassAssignComponent = asyncComponent(() =>
    import('../components/student/pages/studentsMassAssign/homeComponent').then(module => module.default)
);
{/* Student > Counselor Log */ }
const counselorLogComponent = asyncComponent(() =>
    import('../components/student/pages/counselorLog/homeComponent').then(module => module.default)
);
{/* Student > Counselor Log Details */ }
const counselorLogDetailsComponent = asyncComponent(() =>
    import('../components/student/pages/counselorLog/logDetailsComponent').then(module => module.default)
);
{/* Attendance  > Attendance Log */ }
const attendanceLogComponent = asyncComponent(() =>
    import('../components/attendance/pages/attendanceLog/homeComponent').then(module => module.default)
);
{/* Attendance  > Attendance Log Details*/ }
const attendanceLogDetailsComponent = asyncComponent(() =>
    import('../components/attendance/pages/attendanceLogDetails/homeComponent').then(module => module.default)
);
{/* Attendance  > Student Attendance Percentage Report*/ }
const studentAttendancePercentageComponent = asyncComponent(() =>
    import('../components/attendance/pages/report/studentAttendancePercentage/homeComponent').then(module => module.default)
);
{/* Attendance > Absence Letter*/ }
const absenceLetterHomeComponent = asyncComponent(() =>
    import('../components/attendance/pages/absenceLetter/homeComponent').then(module => module.default)
);
{/* School > Control Panel > Principal Signature Upload */ }
const principalSignatureUploadComponent = asyncComponent(() =>
    import('../components/school/pages/principalSignatureUpload/homeComponent').then(module => module.default)
);
export class PrivateRoute extends Route {
    render() {
        if (new sessionStore().returnSessionToken() == null) {
            return <Redirect to="/sso" />;
        }
        if (new sessionStore().returnCountryCode() && new sessionStore().returnCountryCode() === commonConfig.INDIA_COUNTRY_CODE) {
            countryCode = new sessionStore().returnCountryCode() + '/';
        } else if (new sessionStore().returnCountryCode() && new sessionStore().returnCountryCode() === commonConfig.DEFAULT_COUNTRY_CODE) {
            countryCode = ''
        }
        return (
            <div>
                <Route exact path="/dashboard" component={dashboardComponents} />
                <Route exact path="/school/addSchool/general" component={addSchoolGenComponent} />
                <Route path="/school/viewSchoolGeneralInfo" component={viewSchoolGeneralInfoComponent} />
                <Route exact path="/school/schoolField" component={viewSchoolFieldsComponent} />
                <Route exact path="/school/showSchoolDepartment" component={viewSchoolDepartmentComponent} />
                <Route exact path="/school/viewGeneralInfo" component={viewSchoolGenComponent} />
                <Route exact path="/school/schoolMarkingPeriods" component={viewSchoolMarkingPeriodsComponent} />
                <Route exact path="/school/calendar" component={calendar} />
                <Route exact path="/school/viewSchoolAdministration" component={viewSchoolAdministrationComponent} />
                <Route exact path="/school/viewSchoolLunchProgram" component={viewSchoolLunchProgramComponent} />
                <Route exact path="/school/schoolLabel" component={schoolLabelComponent} />
                <Route exact path="/school/schoolPeriods" component={viewSchoolPeriodsComponent} />
                <Route exact path="/school/updateSchoolAddressInfo" component={updateSchoolAddressInfoComponent} />
                <Route exact path="/school/updateSchoolPhoneEmail" component={updateSchoolPhoneEmailComponent} />
                <Route exact path="/school/updateSchoolAdministration" component={updateSchoolAdministrationComponent} />
                <Route exact path="/school/updateSchoolLunchProgram" component={updateSchoolLunchProgramComponent} />
                <Route exact path="/school/updateSchoolIdentifier" component={updateSchoolIdentifierComponent} />
                <Route exact path="/school/updateSchoolDesignators" component={updateSchoolDesignatorComponent} />
                <Route exact path="/school/updateSchoolAccreditations" component={updateSchoolAccreditationComponent} />
                <Route exact path="/school/updateSchoolStateReportingComponent" component={updateSchoolStateReportingComponent} />
                <Route exact path="/school/updateSchoolResStateReportingComponent" component={updateSchoolStateReportingComponent} />
                <Route exact path="/staff/staffStateReporting" component={updateStaffStateReportingComponent} />
                <Route exact path="/staff/staffResStateReporting" component={updateStaffStateReportingComponent} />
                <Route exact path="/district/showSchoolList" component={schoolListComponent} />
                <Route exact path="/district/courseExplanation" component={courseExplanationComponent} />
                <Route exact path="/school/showGeneralInfoRoute" component={viewSchoolGeneralInfoComponent} />
                <Route exact path="/system/lmsSetup" component={lmsSetupComponent} />
                <Route exact path="/system/lmsSync" component={lmsSyncComponent} />
                <Route exact path="/system/permissions" component={permissionsComponent} />
                <Route exact path="/school/schoolGradeLevels" component={schoolGradeLevels} />
                <Route exact path="/school/schoolRole" component={schoolRole} />
                <Route exact path="/school/gradeScaleLevel" component={schoolGradeScaleLevel} />
                <Route exact path="/school/defineGPA" component={schoolDefineGPA} />
                <Route exact path="/school/schoolSection" component={schoolSection} />
                <Route exact path="/school/schoolRoom" component={schoolRooms} />
                <Route exact path="/school/schoolSession" component={schoolSession} />
                <Route exact path="/school/schoolHolidayManagement" component={SchoolHolidayManagement} />
                <Route exact path="/school/SchoolHolidayManagementDetails" component={SchoolHolidayManagementDetails} />
                <Route exact path="/school/calendarCrisis" component={schoolCalendarCrisis} />
                <Route exact path="/school/calendarDays" component={schoolCalendarDays} />
                <Route exact path="/school/gradeScale" component={schoolGradeScale} />
                <Route exact path="/school/language" component={languageComponent} />
                <Route exact path="/messages/addAnnouncement" component={addAnnouncementComponent} />
                <Route exact path="/messages/viewAnnouncementList" component={viewAnnouncementListComponent} />
                <Route exact path="/school/dynamicform" component={dynamicFormComponents} />
                <Route exact path="/school/stateReportingExecution" component={stateReportingExecutionComponent} />
                <Route exact path="/school/cohortInfo" component={schoolCohortInfoComponent} />
                <Route exact path="/school/masterTeacherAssociation" component={masterTeacherAssociationComponent} />
                <Route exact path="/school/studentUsernameSetup" component={studentUsernameSetupComponent} />
                {/* Add Student route*/}
                {/* Special Program Start */}
                {Object.keys(routeList).map((routeName, i) => {
                    return <Route key={i} exact path={`/student/viewStudent/specialProgram/${routeName}`} component={specialProgramModuleComponent} />
                })}
                <Route exact path="/student/viewStudent/manageSpecialProgram" component={specialProgramComponent} />
                {/* Special Program End */}
                <Route exact path="/student/viewStudent/overview" component={viewStudentOverviewComponent} />
                <Route exact path="/student/addStudent/general" component={addStudentGeneralComponent} />
                <Route exact path="/student/viewStudent/identifier" component={addStudentIdentifierComponent} />
                <Route exact path="/student/viewStudent/miscellaneousStatus" component={viewStudentMiscStatusComponent} />
                <Route exact path="/student/addStudent/miscellaneousStatus" component={addStudentMiscStatusComponent} />
                <Route exact path="/student/viewStudent/refferal" component={addStudentRefferalComponent} />
                <Route exact path="/student/viewStudent/visa" component={addStudentVisaComponent} />
                <Route exact path="/student/viewStudent/disability" component={addStudentDisabilityComponent} />
                <Route exact path="/student/viewStudent/guardian" component={addStudentGuardianComponent} />
                <Route exact path="/student/viewStudent/healthInfo" component={viewStudentHealthComponent} />
                <Route exact path="/student/addStudent/birthInfo" component={addStudentBirthInfoComponent} />
                <Route exact path="/student/viewStudent/birthInfo" component={viewStudentBirthInfoComponent} />
                <Route exact path="/student/addStudent/survey" component={addTeacherSurveyComponent} />
                <Route exact path="/student/viewStudent/survey" component={viewTeacherSurveyComponent} />
                <Route exact path="/student/addStudent/feedback" component={addFeedbackComponent} />
                <Route exact path="/student/viewStudent/feedback" component={viewFeedbackComponent} />
                <Route exact path="/student/viewStudent/immunizationInfo" component={addStudentImmunizationInfoComponent} />
                <Route exact path="/student/viewStudent/permissions" component={addStudentEnrollmentEligibilityComponent} />
                <Route exact path="/student/viewStudent/accountAccess" component={viewStudentAccountAccessComponent} />
                <Route exact path="/student/addStudent/accountAccess" component={addstudentAccountAccessComponent} />
                <Route exact path="/student/viewStudent/enrollmentInfo" component={addStudentEnrollmentInfoComponent} />
                <Route exact path="/student/viewStudent/cohortInfo" component={viewStudentCohortInfoComponent} />
                <Route exact path="/student/viewStudent/studentGraduationPlan" component={studentGraduationPlanComponent} />
                <Route exact path="/student/TrexStateReporting" component={TrexComponent} />
                {/*<Route exact path="/student/addStudent/cohortInfo" component={addStudentCohortInfoComponent} />*/}
                {/* <Route exact path="/dashboard/addstudent/enrollment_eligibility" component={addStudentEnrollmentEligibilityComponent} />*/}
                <Route exact path="/student/viewStudent/giftedProgram" component={addStudentEnrollmentProgramGiftComponent} />
                {/* View Student route*/}
                <Route exact path="/student/viewStudent/general" component={viewStudentGeneralComponent} />
                <Route exact path="/student/viewStudent/studyMaterial" component={studyMaterial} />
                <Route exact path="/student/addStudent/healthInfo" component={addStudentHealthComponent} />
                <Route exact path="/student/noshow" component={noShowComponent} />
                {/* Update Student route*/}
                <Route exact path="/student/updateStudent/general" component={updateStudentGeneralComponent} />
                {/* Misc route*/}
                <Route exact path="/student/studentMedicalEmergency" component={studentMedicalEmergencyComponent} />
                <Route exact path="/student/studentExitStatus" component={studentExitStatusComponent} />
                <Route exact path="/student/studentDocumentsUpload" component={studentDocumentsUploadComponent} />
                <Route exact path="/student/studentDocumentVerification" component={studentDocumentVerificationComponent} />
                <Route exact path="/student/studentDocumentVerificationDetails" component={studentDocumentVerificationDetailsComponent} />
                <Route exact path="/student/studentHealthScreening" component={studentHealthScreeningComponent} />
                <Route exact path="/student/studentHealthScreeningDetails" component={studentHealthScreeningDetailsComponent} />
                <Route exact path="/student/showStudentList" component={studentListComponent} />
                <Route exact path="/student/showStudentListRoute" component={studentListComponent} />
                <Route exact path="/student/studentMassAssign" component={studentMassAssignComponentNew} />
                <Route exact path="/student/studentReenroll" component={studentReenrollComponent} />
                <Route exact path="/student/studentPromotion" component={studentPromotionComponent} />
                <Route exact path="/student/studentCustomField" component={studentCustomFieldComponent} />
                <Route exact path="/student/dynamicform" component={dynamicFormComponents} />
                <Route exact path="/student/endOfYearStatus" component={endYearStatusComponent} />
                <Route exact path="/student/studentHealthVisit" component={studentHealthVisitComponent} />
                <Route exact path="/student/searchStudentHealthVisit" component={studentHealthVisitSearchStudentComponent} />
                <Route exact path="/student/healthVisitAddAdminister" component={studentHealthVisitAddAdministerComponent} />
                <Route exact path="/student/healthAddVisit" component={studentHealthAddVisitComponent} />
                <Route exact path="/student/studentHealthVisitAddMedication" component={studentHealthVisitAddMedicationComponent} />
                <Route exact path="/student/studentEnrollmentAdaEligibility" component={studentEnrollmentAdaEligibilityComponent} />
                <Route exact path="/student/mailingLabels" component={mailingLabelsComponent} />
                <Route exact path="/student/enrollmentDropReport" component={enrollmentDropReportComponent} />
                <Route exact path="/student/specialProgramGeneralListing" component={specialProgramGeneralListingComponent} />
                <Route exact path="/student/specialProgramStudentListing" component={specialProgramStudentListingComponent} />
                <Route exact path="/student/studentWithdrawalForm" component={studentWithdrawalFormComponent} />
                <Route exact path="/student/specialProgramDetailListing" component={specialProgramDetailListingComponent} />
                <Route exact path="/student/studentGenerateIdCard" component={new sessionStore().returnCountryCode() && new sessionStore().returnCountryCode() === commonConfig.INDIA_COUNTRY_CODE ? studentGenerateIdCardComponentIN : null} />
                <Route exact path="/student/studentDesignIdCard" component={new sessionStore().returnCountryCode() && new sessionStore().returnCountryCode() === commonConfig.INDIA_COUNTRY_CODE ? studentDesignIdCardComponentIN : null} />
                <Route exact path="/student/viewStudent/studentStateReporting" component={studentStateReportingComponent} />
                <Route exact path="/student/viewStudent/studentResStateReporting" component={studentStateReportingComponent} />
                <Route exact path="/student/viewStudent/studentGraduationStateReporting" component={studentStateReportingComponent} />
                <Route exact path="/student/viewStudent/studentSchLeaveStateReporting" component={studentStateReportingComponent} />
                <Route exact path="/student/viewStudent/studentRestraintStateReporting" component={studentStateReportingComponent} />
                <Route exact path="/student/viewStudent/studentExtendedYearStateReporting" component={studentStateReportingComponent} />
                <Route exact path="/student/viewStudent/studentSpecialEducation" component={studentStateReportingComponent} />
                <Route exact path="/student/viewStudent/studentSpecialEducationService" component={studentStateReportingComponent} />
                <Route exact path="/student/viewStudent/studentDigital" component={studentStateReportingComponent} />
                <Route exact path="/student/viewStudent/studentDisabilityLangStateReporting" component={studentStateReportingComponent} />
                {/* Course route*/}
                <Route exact path="/course/coursemanager" component={courseManagerSubjectComponent} />
                {/* Add Staff route*/}
                <Route exact path="/staff/addStaff/demographic" component={addStaffDemographicComponent} />
                <Route exact path="/staff/addStaff/accountAccess" component={addStaffAccountAccessComponent} />
                {/* <Route exact path="/dashboard/addstaff/phone" component={addStaffPhoneComponent} />
                <Route exact path="/dashboard/addstaff/email" component={addStaffEmailComponent} />
                <Route exact path="/dashboard/addstaff/addressInfo" component={addStaffAddressInfoComponent} /> */}
                <Route exact path="/staff/addStaff/schoolRoleAssignment" component={addStaffSchoolRoleAssignmentComponent} />
                {/* <Route exact path="/dashboard/addstaff/accessInfo" component={addStaffAccessInfoComponent} /> */}
                <Route exact path="/staff/addStaff/employmentInfo" component={addStaffEmploymentInfoComponent} />
                <Route exact path="/staff/addStaff/staffContact" component={addStaffContactComponent} />
                <Route exact path="/staff/staffCredentialsVerification" component={staffCredentialsVerificationComponent} />
                <Route exact path="/staff/staffCredentialsVerificationDetails" component={staffCredentialsVerificationDetailsComponent} />
                <Route exact path="/staff/addStaff/staffCertification" component={addStaffCertificationComponent} />
                <Route exact path="/staff/addStaff/documentVerification" component={addStaffDocumentVerificationComponent} />
                <Route exact path="/staff/addStaff/visa" component={addStaffVisaComponent} />
                <Route exact path="/staff/addStaff/teachingExperience" component={addStaffTeachingExperienceComponent} />
                {/* View Staff route*/}
                <Route exact path="/staff/viewStaff/demographic" component={viewStaffDemographicComponent} />
                <Route exact path="/staff/viewStaff/accountAccess" component={viewStaffAccountAccessComponent} />
                {/* <Route exact path="/dashboard/viewstaff/phone" component={addStaffPhoneComponent} />
                <Route exact path="/dashboard/viewstaff/email" component={addStaffEmailComponent} />
                <Route exact path="/dashboard/viewstaff/addressInfo" component={addStaffAddressInfoComponent} /> */}
                <Route exact path="/staff/viewStaff/schoolRoleAssignment" component={addStaffSchoolRoleAssignmentComponent} />
                <Route exact path="/staff/viewStaff/employmentInfo" component={viewStaffEmploymentInfoComponent} />
                <Route exact path="/staff/viewStaff/staffContact" component={addStaffContactComponent} />
                <Route exact path="/staff/viewStaff/qualificationCertificates" component={addStaffCertificationComponent} />
                <Route exact path="/staff/viewStaff/personalDocumentVerification" component={addStaffDocumentVerificationComponent} />
                <Route exact path="/staff/viewStaff/visa" component={addStaffVisaComponent} />
                <Route exact path="/staff/viewStaff/teachingExperience" component={viewStaffTeachingExperienceComponent} />
                <Route exact path="/staff/viewStaff/staffCredential" component={addStaffCredentialComponent} />
                <Route exact path="/staff/viewStaff/staffPersonalCredential" component={addStaffPersonalCredentialComponent} />
                <Route exact path="/staff/viewStaff/viewStaffAuthorization" component={viewStaffAuthorizationComponent} />
                <Route exact path="/staff/viewStaff/viewStaffMiscSkills" component={viewStaffMiscSkillsComponent} />
                <Route exact path="/staff/viewStaff/identifier" component={addStaffIdentifierComponent} />
                <Route exact path="/staff/credentials" component={staffCredentialsComponent} />
                {/* staff Misc route*/}
                <Route exact path="/staff/showStaffList" component={staffListComponent} />
                <Route exact path="/staff/showStaffListRoute" component={staffListComponent} />
                <Route exact path="/staff/staffCustomFields" component={staffCustomFieldComponent} />
                <Route exact path="/staff/staffJobs" component={staffJobsComponent} />
                <Route exact path="/staff/staffJobCategory" component={staffJobCategoryComponent} />
                <Route exact path="/staff/staffCalender" component={staffCalendarComponent} />
                <Route exact path="/staff/jobCertification" component={staffJobCertificationComponent} />
                <Route exact path="/staff/leaveRequests" component={staffLeaveRequestsComponent} />
                <Route exact path="/staff/leaveRequestDetails" component={staffLeaveRequestDetailsComponent} />
                <Route exact path="/staff/staffMyLeaves" component={staffMyLeavesComponent} />
                <Route exact path="/staff/staffMyLeaveDetails" component={staffMyLeaveDetailsComponent} />
                <Route exact path="/staff/certificateVerification" component={staffCertificateVerificationComponent} />
                <Route exact path="/staff/certificateVerificationDetails" component={staffCertificateVerificationDetailsComponent} />
                <Route exact path="/staff/documentVerification" component={staffDocumentVerificationComponent} />
                <Route exact path="/staff/documentVerificationDetails" component={staffDocumentVerificationDetailsComponent} />
                <Route exact path="/staff/holidayListPublication" component={holidayListPublicationComponent} />
                <Route exact path="/staff/holidayListPublicationView" component={holidayListPublicationViewComponent} />
                <Route exact path="/staff/staffGenerateIdCard" component={new sessionStore().returnCountryCode() && new sessionStore().returnCountryCode() === commonConfig.INDIA_COUNTRY_CODE ? staffGenerateIdCardComponentIN : null} />
                <Route exact path="/staff/staffDesignIdCard" component={new sessionStore().returnCountryCode() && new sessionStore().returnCountryCode() === commonConfig.INDIA_COUNTRY_CODE ? staffDesignIdCardComponentIN : null} />
                {/* Update Student route*/}
                <Route exact path="/staff/updateStaff/demographic" component={updateStaffDemographicInfo} />
                <Route exact path="/staff/updateStaff/employmentInfo" component={updateStaffEmploymentInfo} />
                <Route exact path="/staff/updateStaff/updateStaffAuthorization" component={updateStaffAuthorizationComponent} />
                <Route exact path="/staff/updateStaff/updateStaffMiscSkills" component={updateStaffMiscSkillsComponent} />
                <Route exact path="/staff/dynamicform" component={dynamicFormComponents} />
                <Route exact path="/staff/myAttendance" component={staffMyAttendancePeriodViewComponent} />
                <Route exact path="/dashboard/upload" component={MarkersCommonFileUpload} />
                {/* Schedule route*/}
                <Route exact path="/scheduling/individualStudentSchedule" component={individualScheduleStudentListComponent} />
                <Route exact path="/scheduling/individualScheduleStudentDetails" component={individualScheduleStudentDetailsComponent} />
                {/*<Route exact path="/scheduling/individualStudentSchedule" component={individualStudentScheduleComponent} />*/}
                <Route exact path="/scheduling/teacherReassignment" component={teacherReassignmentComponent} />
                <Route exact path="/scheduling/requestApproval" component={requestApprovalComponent} />
                <Route exact path="/scheduling/massStudentDrop" component={massStudentDropComponent} />
                <Route exact path="/scheduling/studentCourseRequests" component={studentCourseCurriculumComponent} />
                <Route exact path="/scheduling/adminCourseRequests" component={adminCourseCurriculumComponent} />
                <Route exact path="/scheduling/courseSectionWiseAllocation" component={courseSectionWiseAllocationComponent} />
                <Route exact path="/scheduling/autoSchedulerQueueAndProcess" component={sectionAllocationProcessComponent} />
                <Route exact path="/scheduling/studentWiseAllocation" component={studentWiseAllocationComponent} />
                <Route exact path="/scheduling/allocateCourseSection" component={allocateCourseSectionComponent} />
                <Route exact path="/scheduling/massStudentSchedule" component={massStudentScheduleComponent} />
                <Route exact path="/scheduling/dynamicform" component={dynamicFormComponents} />
                <Route exact path="/scheduling/studentScheduler" component={studentSchedulerComponent} />
                <Route exact path="/scheduling/studentSchedulerResults" component={studentSchedulerResultsComponent} />
                <Route exact path="/scheduling/studentSchedulerResultsStudentDetails" component={studentSchedulerResultsStudentDetailsComponent} />
                <Route exact path="/scheduling/studentsMissingCourseRequestsOrSchedule" component={studentsMissingCourseRequestsOrScheduleComponent} />
                <Route exact path="/scheduling/studentScheduleReport" component={studentScheduleReportComponent} />
                <Route exact path="/scheduling/masterScheduleGrid" component={masterScheduleGridComponent} />
                {/* Attendance route*/}
                <Route exact path="/attendance/takeAttendance" component={takeAttendanceComponent} />
                <Route exact path="/attendance/missingAttendance" component={missingAttendanceStudentTeacherViewComponent} />
                <Route exact path="/attendance/teacherAttendance" component={takeTeacherAttendanceAddComponent} />
                <Route exact path="/attendance/adminMissingAttendance" component={adminMissingAttendanceComponent} />
                <Route exact path="/attendance/eventAttendance" component={eventAttendanceComponent} />
                <Route exact path="/attendance/studentMissingAttendance" component={adminMissingAttendanceTeacherListComponent} />
                <Route exact path="/attendance/takeStudentSectionAttendanceAdminView" component={takeStudentAttendanceAdminViewComponent} />
                <Route exact path="/attendance/studentSectionAttendance" component={studentAttendanceAdminViewComponent} />
                <Route exact path="/attendance/viewAttendance" component={viewAttendanceTeacherComponent} />
                <Route exact path="/attendance/viewStudentAttendance" component={adminViewAttendanceComponent} />
                <Route exact path="/attendance/dynamicform" component={dynamicFormComponents} />
                <Route exact path="/attendance/viewTeacherAttendance" component={viewAttendanceTeacherComponent} />
                <Route exact path="/attendance/teacherMissingAttendance" component={teacherMissingAttendanceComponent} />
                <Route exact path="/attendance/takeTeacherAttendance" component={takeTeacherAttendanceComponent} />
                <Route exact path="/attendance/viewStaffAttendance" component={viewStaffAttendanceComponent} />
                <Route exact path="/attendance/viewStaffAttendanceYearView" component={viewStaffAttendanceYearViewComponent} />
                <Route exact path="/attendance/examAttendance" component={examAttendanceComponent} />
                <Route exact path="/attendance/examAttendanceAdmin" component={examAttendanceAdminComponent} />
                <Route exact path="/attendance/examAttendanceTakeAttendance" component={examAttendanceTakeAttendanceComponent} />
                <Route exact path="/attendance/dailyAttendanceStaff" component={dailyAttendanceStaffComponent} />
                <Route exact path="/attendance/dailyAttendanceStudent" component={dailyAttendanceStudentComponent} />
                <Route exact path="/attendance/studentPeriodwiseAttendance" component={studentPeriodwiseAttendanceComponent} />
                <Route exact path="/attendance/studentAttendanceMonthView" component={studentAttendanceMonthViewComponent} />
                <Route exact path="/attendance/massStudentAttendance" component={massStudentAttendanceComponent} />
                <Route exact path="/attendance/attendancePercentage" component={attendancePercentageComponent} />
                <Route exact path="/attendance/perfectAttendance" component={perfectAttendanceComponent} />
                <Route exact path="/attendance/setupCode" component={setupCodeComponent} />
                <Route exact path="/attendance/setupCodeDetails" component={setupCodeDetailsComponent} />
                <Route exact path="/attendance/periodDailyAttendanceSummary" component={periodDailyAttendanceSummaryComponent} />
                <Route exact path="/attendance/courseAttendanceSummary" component={courseAttendanceSummaryComponent} />
                {/* Reports route*/}
                <Route exact path="/reports/adHocReporting" component={adHocReportingComponent} />
                {/* Discipline route*/}
                <Route exact path="/discipline/reportIncident" component={reportIncidentComponent} />
                <Route exact path="/discipline/studentDiscipline" component={studentDisciplineComponent} />
                <Route exact path="/discipline/manageIncident" component={manageIncidentComponent} />
                <Route exact path="/discipline/incidentDetails" component={incidentDetailsComponent} />
                <Route exact path="/discipline/incidentApproval" component={incidentApprovalComponent} />
                <Route exact path="/discipline/dynamicform" component={dynamicFormComponents} />
                {/* Assessment route*/}
                <Route exact path="/assessment/assessmentGroup" component={assessmentGroupComponent} />
                <Route exact path="/assessment/assessmentInfo" component={assessmentInfoComponent} />
                <Route exact path="/assessment/assessmentInfoDetails" component={assessmentInfoDetailsComponent} />
                <Route exact path="/assessment/assessmentSession" component={assessmentSessionComponent} />
                <Route exact path="/assessment/assessmentAdministration" component={assessmentAdministrationComponent} />
                <Route exact path="/assessment/assessmentAccomodation" component={assessmentAccomodationComponent} />
                <Route exact path="/assessment/assessmentSessionExam" component={assessmentSessionExamComponent} />
                <Route exact path="/assessment/assessmentAssets" component={assessmentAssetsComponent} />
                <Route exact path="/assessment/adminAssessmentRegistration" component={adminAssessmentRegistrationComponent} />
                <Route exact path="/assessment/assessmentResult" component={assessmentResultComponent} />
                <Route exact path="/assessment/assessmentEndOfYearSummary" component={assessmentEndOfYearSummaryComponent} />
                <Route exact path="/assessment/viewTranscript" component={viewtranscriptComponent} />
                <Route exact path="/assessment/publishTranscript" component={publishTranscriptComponent} />
                <Route exact path="/assessment/gradeListing" component={gradeListingComponent} />
                <Route exact path="/assessment/reportCard" component={reportCardComponent} />
                {/* Assesment > Report > Gradebook - Report */}
                <Route exact path="/assessment/gradebookReport" component={gradebookReportComponent} />

                <Route exact path="/assessment/viewReportCard" component={viewReportCardComponent} />
                <Route exact path="/assessment/publishReportCard" component={publishReportCardComponent} />
                <Route exact path="/assessment/dynamicform" component={dynamicFormComponents} />
                <Route exact path="/assessment/transcript" component={transcriptComponent} />
                <Route exact path="/assessment/assignmentCategory" component={assignmentCategoryComponent} />
                <Route exact path="/assessment/assessmentRubric" component={assessmentRubricComponent} />
                <Route exact path="/assessment/assignmentDetails" component={assignmentDetailsComponent} />
                <Route exact path="/assessment/gradebook" component={gradebookComponent} />
                <Route exact path="/assessment/assignmentBank" component={assignmentBankComponent} />
                <Route exact path="/assessment/assignmentGroup" component={assignmentGroupComponent} />
                <Route exact path="/assessment/assignmentItem" component={assignmentItemsComponent} />
                <Route exact path="/assessment/assignmentItemsChild" component={assignmentItemsChildComponent} />
                <Route exact path="/assessment/assignments" component={assignmentsComponent} />
                {/* Section grading */}
                <Route exact path="/assessment/sectionGradingAdminView" component={sectionGradingAdminViewComponent} />
                <Route exact path="/assessment/updateSectionGrading" component={updateSectionGradingComponent} />
                <Route exact path="/assessment/viewSectionGrading" component={viewSectionGradingComponent} />
                {/* Progress grading */}
                <Route exact path="/assessment/viewProgressGrades" component={viewSectionGradingComponent} />
                <Route exact path="/assessment/updateProgressGrades" component={updateSectionGradingComponent} />
                <Route exact path="/assessment/progressGradeAdminView" component={sectionGradingAdminViewComponent} />
                <Route exact path="/assessment/calculateRank" component={calculateRankComponent} />
                <Route exact path="/assessment/calculateRankResult" component={rankResultComponent} />
                <Route exact path="/assessment/maintainRankResult" component={rankResultComponent} />
                <Route exact path="/assessment/maintainRank" component={maintainRankComponent} />
                <Route exact path="/assessment/studentGpaRank" component={studentGpaRankComponent} />
                <Route exact path="/assessment/studentGpaRankDetails" component={studentGpaRankDetailsComponent} />
                <Route exact path="/assessment/transcriptMassDelete" component={transcriptMassDeleteComponent} />
                <Route exact path="/assessment/transcriptGrades" component={transcriptGradesComponent} />
                <Route exact path="/assessment/studentTranscript" component={studentTranscriptComponent} />
                <Route exact path="/assessment/studentTranscriptDetails" component={studentTranscriptDetailsComponent} />
                <Route exact path="/assessment/calculateGpa" component={calculateGpaComponent} />
                <Route exact path="/assessment/gpaProcessGpaResults" component={gpaProcessGpaResultsComponent} />
                <Route exact path="/assessment/assignmentActivity" component={assignmentActivityComponent} />
                <Route exact path="/assessment/assessmentGrading" component={assessmentGradingTeacherComponent} />
                <Route exact path="/assessment/assessmentGradingTeacherDetails" component={assessmentGradingTeacherDetailsComponent} />
                {/* System route*/}
                <Route exact path="/system/roleBasedAccessControl" component={roleBasedAccessControlComponent} />
                <Route exact path="/system/managePerson" component={managePersonComponent} />
                <Route exact path="/school/rollover" component={rolloverComponent} />
                <Route exact path="/system/uploadReportCardTemplate" component={uploadReportCardTemplateComponent} />
                <Route exact path="/system/dynamicform" component={dynamicFormComponents} />
                <Route exact path="/system/dayCode" component={dayCodeComponent} />
                <Route exact path="/system/selectScheduleType" component={selectScheduleTypeComponent} />
                <Route exact path="/system/systemMaintenanceMode" component={systemMaintenanceModeComponent} />
                <Route exact path="/system/uploadProfileImage" component={uploadProfileImageComponent} />
                {/* <Route exact path="/system/failedLoginAttemptLimit" component={failedLoginAttemptLimitComponent} /> */}
                <Route exact path="/district/setPasswordPattern" component={setPasswordPatternComponent} />
                <Route exact path="/system/defaultDateFormat" component={defaultDateFormatComponent} />
                {/* Messages Route*/}
                <Route exact path="/messages/sentMessages" component={sentMessagesComponent} />
                <Route exact path="/messages/receivedMessages" component={receivedMessagesComponent} />
                <Route exact path="/messages/sentEmail" component={sentEmailComponent} />
                <Route exact path="/messages/textMessages" component={textMessageComponent} />
                {/* Progress Report Card*/}
                <Route exact path="/assessment/generateProgressReportCard" component={generateProgressReportCardComponent} />
                <Route exact path="/assessment/publishProgressReportCard" component={publishProgressReportCardComponent} />
                <Route exact path="/assessment/progressReportCard" component={progressReportCardComponent} />
                {/* Common Route*/}
                <Route exact path="/school/commonRoute" component={MarkersCommonRoute} />
                {/* District Route*/}
                <Route exact path="/district/addSchool" component={addSchoolGenComponent} />
                <Route exact path="/district/districtStateReportingExecution" component={districtStateReportingExecutionComponent} />
                <Route exact path="/district/districtSyncEdfi" component={districtSyncEdfiComponent} />
                <Route exact path="/district/EdfiSetup" component={districtEdfiSetupComponent} />
                <Route exact path="/district/districtControlPanel" component={studentStaffCodeComponent} />
                <Route exact path="/district/allowAdminEditPreviousSession" component={allowAdminEditPreviousSessionComponent} />
                <Route exact path="/district/studentIdentifiers" component={dcpStudentIdentifiersComponent} />
                <Route exact path="/district/staffIdentifiers" component={dcpStaffIdentifiersComponent} />
                <Route exact path="/district/setDefaultValues" component={setDefaultValuesComponent} />
                <Route exact path="/district/studentNameDisplayPattern" component={studentNameDisplayPatternComponent} />
                <Route exact path="/district/districtPrograms" component={districtProgramsComponent} />
                <Route exact path="/district/allowTeacherEditAttendance" component={allowTeacherEditAttendanceComponent} />
                <Route exact path="/district/serviceIDs" component={serviceIDsComponent} />
                <Route exact path="/district/staffNameDisplayPattern" component={staffNameDisplayPatternComponent} />
                <Route exact path="/district/addDistrictGeneralInfo" component={addDistrictGeneralInfoComponent} />
                <Route exact path="/district/addDistrictAddressInfo" component={addDistrictAddressInfoComponent} />
                <Route exact path="/district/addTranscriptSeal" component={addTranscriptSealComponent} />
                <Route exact path="/district/districtPhoneEmail" component={districtPhoneEmailComponent} />
                <Route exact path="/district/districtDesignators" component={districtDesignatorsComponent} />
                <Route exact path="/district/districtIdentifiers" component={districtIdentifiersComponent} />
                <Route exact path="/district/districtStateReporting" component={districtStateReportingComponent} />
                <Route exact path="/district/allowMultipleSchedule" component={allowMultipleScheduleComponent} />
                <Route exact path="/district/graduationPlan" component={graduationPlanComponent} />
                <Route exact path="/district/failedLoginAttemptLimit" component={failedLoginAttemptLimitComponent} />
                <Route exact path="/district/districtAttendancePercentagereport" component={districtAttendancePercentageReport} />
                {/*District - System Lookup Values*/}
                <Route exact path="/district/systemLookupValues" component={systemLookupvaluesComponent} />
                {/*Study Material*/}
                <Route exact path="/studymaterial/courseMaterial" component={courseMaterialComponent} />
                <Route exact path="/studymaterial/assignmentMaterial" component={assignmentMaterialComponent} />
                {/*Student*/}
                <Route exact path="/student/conductCertificate" component={conductCertificateComponent} />
                {/*Generate transcript*/}
                <Route exact path="/assessment/generateTranscript" component={generateTranscriptComponent} />
                {/*School > Control Panel > Comment Code Setup*/}
                <Route exact path="/school/commentCodeSetup" component={commentCodeSetupComponent} />
                <Route exact path="/school/allowMultipleStudentSchedule" component={allowMultipleStudentScheduleComponent} />
                <Route exact path="/district/uploadMaximumFileSize" component={uploadMaximumFileComponent} />

                {/*Document Upload menu under the Student 360 view*/}
                <Route exact path="/student/addStudentDocumentUpload" component={addStudentDocumentUploadComponent} />
                {/* Scheduling > Report > Class Roster */}
                <Route exact path="/scheduling/teacherClassRoster" component={classRosterComponent} />
                {/*Standard Grading - Add Standard Set*/}
                <Route exact path="/school/standardGradingSet" component={addStandardSetComponent} />

                {/*Standard Grading - Add Standard Scale*/}
                <Route exact path="/school/standardGradingScale" component={addStandardScaleComponent} />
                {/*Standard Group - Add Standard Group*/}
                <Route exact path="/school/standardGroup" component={standardGroupComponent} />
                {/*Standard Grading - Teacher View - Grade by Student*/}
                <Route exact path="/assessment/standardGrading" component={StandardGradingComponent} />
                {/*Standard Grading - Add Standards*/}
                <Route exact path="/school/addStandards" component={addStandardsComponent} />
                {/*Attendance > Report > Attendance count Report*/}
                <Route exact path="/attendance/attendanceCountReport" component={attendanceCountReportComponent} />
                {/* Attendance > Report > Staff Attendance Report */}
                <Route exact path="/attendance/staffAttendanceReport" component={staffAttendanceReportComponent} />
                <Route exact path="/assessment/studentTestData" component={studentTestDataComponent} />
                {/* Scheduling > Report > Class Roster */}
                <Route exact path="/scheduling/classRoster" component={classRosterAdminComponent} />
                {/*District > Tests > Test Format*/}
                <Route exact path="/district/testFormat" component={testFormatComponent} />
                {/*School > ControPanel > School Options*/}
                <Route exact path="/school/schoolOptions" component={schoolOptionsComponent} />
                {/* District > District Control Panel > Graduation Plan */}
                <Route exact path="/district/graduationPlanNew" component={graduationPlanNewComponent} />
                <Route exact path="/district/categories" component={categoriesComponent} />
                <Route exact path="/district/courseAndCategoryAssociate" component={courseAndCategoryAssociateComponent} />
                {/* school > timezoneSetup */}
                <Route exact path="/school/timezoneSetup" component={timezoneSetupComponent} />
                {/* Assessment > GPA Report */}
                <Route exact path="/assessment/gpaReport" component={homeComponent} />
                {/* Assessment > Report > Rank Report */}
                <Route exact path="/assessment/rankReport" component={rankReportComponent} />
                {/* Student > Mass Assign */}
                <Route exact path="/student/studentsMassAssignNew" component={studentsMassAssignComponent} />
                {/* Student > Counselor Log */}
                <Route exact path="/student/counselorLog" component={counselorLogComponent} />
                <Route exact path="/student/counselorLogDetails" component={counselorLogDetailsComponent} />
                {/*Attendance > Attendance Log*/}
                <Route exact path="/attendance/attendanceLog" component={attendanceLogComponent} />
                {/*Attendance > Attendance Log Details*/}
                <Route exact path="/attendance/attendanceLogDetails" component={attendanceLogDetailsComponent} />
                {/*Attendance > Student Attendance Percentage Report*/}
                <Route exact path="/attendance/studentAttendancePercentage" component={studentAttendancePercentageComponent} />
                {/*Attendance > Absence Letter*/}
                <Route exact path="/attendance/absenceLetter" component={absenceLetterHomeComponent} />
                {/* School > Control Panel > Principal Signature Upload */}
                <Route exact path="/school/principalSignatureUpload" component={principalSignatureUploadComponent} />
            </div>
        )
    }
}
export default connect(
    (state) => { const { sharedStore } = state; return { state: { sharedStore } }; },
    (dispatch) => { return { actions: bindActionCreators(Object.assign({}, sharedActionCreators), dispatch) }; }
)(PrivateRoute);
