import { SharedApiConfig } from "../../../shared/sharedApiConfig";
import commonConfig from '../../../common/commonConfig';
const axios = require('axios');
export const handleScheduleApiFail = (error) => {
    return {
        type: 'HANDLE_SCHEDULE_FAIL',
        error: error
    };
};
export const handleScheduleApiSuccess = (fields, title, action) => {
    return {
        type: 'SCHEDULE_' + action.toUpperCase(),
        responseData: fields,
        title: title
    };
};
export const callScheduleListApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleScheduleApiSuccess(response.data, title, 'list'));
        }).catch(error => {
            dispatch(handleScheduleApiFail(error));
        });
    };
};
export const callScheduleSaveApi = (input, title, isUpload = false) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig[isUpload ? 'API_TO_UPLOAD_AND_SAVE' : 'API_TO_CAll'],
            headers: isUpload ? {} : SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: isUpload ? input : JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleScheduleApiSuccess(response.data, title, 'save'));
        }).catch(error => {
            dispatch(handleScheduleApiFail(error));
        });
    };
};
export const callScheduleGetByApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleScheduleApiSuccess(response.data, title, 'get'));
        }).catch(error => {
            dispatch(handleScheduleApiFail(error));
        });
    };
};
export const callScheduleDeleteApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        }).then(function (response) {
            dispatch(handleScheduleApiSuccess(response.data, title, 'delete'));
        }).catch(error => {
            dispatch(handleScheduleApiFail(error));
        });
    };
};
export const scheduleStoreStateReset = (input) => {
    return {
        type: commonConfig.SCHEDULE_STORE_RESET,
        responseData: input,
        title: input.name
    };
};