import React, { Component } from "react";
import commonConfig from '../components/common/commonConfig';
import sessionStore from '../components/common/component/sessionStoreComponent';

//Dynamically import compnents using code splitting.
export default function asyncComponent(getComponent) {
    class AsyncComponent extends Component {
        static Component = null;
        state = {
            Component: AsyncComponent.Component,
            countryCode: ''
        };

        componentWillMount() {
            if (this.props.location.state === undefined) {
                let returnRoute = '';
                let path_split = this.props.location.pathname.split("/");
                if (path_split.length > 3) {
                    if (this.props.location.pathname && this.props.location.pathname.includes("school") === true) {
                        if (this.props.location.pathname === '/school/addSchool/general')
                            returnRoute = this.props.location.pathname;
                        else
                            returnRoute = '/school/viewSchoolGeneralInfo';
                    }
                    if (this.props.location.pathname && this.props.location.pathname.includes("student") === true) {
                        if (this.props.location.pathname === '/student/addStudent/general')
                            returnRoute = this.props.location.pathname;
                        else
                            returnRoute = '/student/showStudentList';
                    }
                    if (this.props.location.pathname && this.props.location.pathname.includes("staff") === true) {
                        if (this.props.location.pathname === '/staff/addStaff/demographic')
                            returnRoute = this.props.location.pathname;
                        else
                            returnRoute = '/staff/showStaffList'
                    }
                }
                else {
                    if (this.props.location.pathname === '/school/viewSchoolGeneralInfo' || this.props.location.pathname === '/school/viewGeneralInfo' || this.props.location.pathname === '/school/updateSchoolAddressInfo' || this.props.location.pathname === '/school/updateSchoolPhoneEmail' || this.props.location.pathname === '/school/updateSchoolDesignators' || this.props.location.pathname === '/school/updateSchoolIdentifier' || this.props.location.pathname === '/school/updateSchoolAccreditations' || this.props.location.pathname === '/school/viewSchoolAdministration' || this.props.location.pathname === '/school/updateSchoolAdministration')
                        returnRoute = '/school/viewSchoolGeneralInfo';
                    if (this.props.location.pathname !== '/school/viewSchoolGeneralInfo' && this.props.location.pathname !== '/school/viewGeneralInfo' && this.props.location.pathname !== '/school/updateSchoolAddressInfo' && this.props.location.pathname !== '/school/updateSchoolPhoneEmail' && this.props.location.pathname !== '/school/updateSchoolDesignators' && this.props.location.pathname !== '/school/updateSchoolIdentifier' && this.props.location.pathname !== '/school/updateSchoolAccreditations' && this.props.location.pathname !== '/school/viewSchoolAdministration' && this.props.location.pathname !== '/school/updateSchoolAdministration' && this.props.location.pathname !== '/school/commonRoute')
                        returnRoute = this.props.location.pathname;
                }
                new sessionStore().storeSecureData(commonConfig.SESSION_ROUTEPATH, returnRoute);
            }
            if (!this.state.Component) {
                getComponent().then(Component => {
                    AsyncComponent.Component = Component
                    this.setState({ Component })
                })
            }
        }
        componentDidUpdate() {
            if (new sessionStore().returnCountryCode() !== this.state.countryCode) {
                getComponent().then(Component => {
                    AsyncComponent.Component = Component
                    this.setState({ Component, countryCode: new sessionStore().returnCountryCode() })
                })
            }
        }
        render() {
            const { Component } = this.state
            if (Component) {
                return <Component {...this.props} />
            }
            return null
        }
    }
    return AsyncComponent;
}
