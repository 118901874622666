import commonConfig from "../../../common/commonConfig";
import i18n from '../../../../i18n';
const getData = [
    {
        programType: [19701, 19702, 19703, 19704, 19713, 19712, 19711, 19709, 19708, 19707, 19705, 19722, 19720, 19718, 19716, 19714, 19706, 19729, 19728, 19727, 19715, 19717, 19723, 19724, 19725, 19726, 19731],
        title: i18n.t("effectiveDate"),
        attribute: {
            name: "effectiveDate",
            type: "date",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "effectiveDateCallback",
        dataType: "string",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19710, 19719, 19721, 19732, 19733, 19734, 19736, 19737, 19738, 19739],
        title: i18n.t("effectiveDate"),
        attribute: {
            name: "effectiveDate",
            type: "date",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "endDateRuleCallback",
        dataType: "string",
        required: true,
        validationRegex: null,
        validationMessage: null,
        validationType: "date",
        validationStart: 'effectiveDate',
        validationEnd: 'endDate',
        endDate: 'endDate',
        value: "",
        defaultValue: ""
    },
    {
        programType: [19710, 19719, 19721, 19732, 19733, 19734, 19736, 19737, 19738, 19739],
        title: i18n.t("endDate"),
        attribute: {
            name: "endDate",
            type: "date",
            minLength: null,
            maxLength: null
        },
        executeFunctionName: '_setRequiredField',
        dataType: "string",
        required: true,
        validationRegex: null,
        validationMessage: null,
        validationType: "dateCompNotRequired",
        validationStart: 'effectiveDate',
        validationEnd: 'endDate',
        startDate: 'effectiveDate',
        value: "",
        defaultValue: ""
    },
    {
        programType: [19701, 19702, 19725, 19731, 19732, 19733, 19736, 19737, 19738],
        title: i18n.t("copyValuesFromPreviousRecord"),
        attribute: {
            name: "copyValuesFromPreviousRecord",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "copyPreviousRecord",
        activeForm: "ADD",
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19701],
        title: i18n.t("disabled"),
        attribute: {
            name: "isDisabled",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19702],
        title: i18n.t("disabled"),
        attribute: {
            name: "isDisabled",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "onDisabilityTexasDisabled",
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19702],
        title: i18n.t("iepIndicator"),
        attribute: {
            name: "isIepIndicator",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19701],
        title: i18n.t("determinedBy"),
        attribute: {
            name: "lookupDeterminedBy",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_DETERMINED_BY,
        storeStateName: "lookupDeterminedBy",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: '',
        childActiveValue: 4299,
        childField: {
            title: i18n.t("other"),
            attribute: {
                name: "determinedByOther",
                type: "text",
                minLength: null,
                maxLength: null,
                placeholder: i18n.t("other")
            },
            onlyField: true,
            dataType: "string",
            required: false,
            validationRegex: null,
            validationMessage: null,
            value: "",
            defaultValue: ""
        }
    },
    {
        programType: [19702],
        title: i18n.t("determinedBy"),
        attribute: {
            name: "lookupDisabilityTexasDeterminedBy",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_TEXAS_DETERMINED_BY,
        storeStateName: "lookupDisabilityTexasDeterminedByList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: '',
        childActiveValue: 22999,
        childField: {
            title: i18n.t("other"),
            attribute: {
                name: "determinedByOther",
                type: "text",
                minLength: null,
                maxLength: null,
                placeholder: i18n.t("other")
            },
            onlyField: true,
            dataType: "string",
            required: false,
            validationRegex: null,
            validationMessage: null,
            value: "",
            defaultValue: ""
        }
    },
    {
        programType: [19701],
        title: i18n.t("primaryDisabilityType"),
        attribute: {
            name: "primaryDisabilityType",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_DISABILITY_TYPE,
        storeStateName: "lookupDisabilityType",
        dataType: "integer",
        isStateSpecific: true,
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19702],
        title: i18n.t("primaryDisabilityType"),
        attribute: {
            name: "primaryDisabilityType",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_TEXAS_PRIMARY_TYPE,
        storeStateName: "lookupDisabilityTexasPrimaryTypeList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19701],
        title: i18n.t("secondaryDisabilityType"),
        attribute: {
            name: "secondaryDisabilityType",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_DISABILITY_TYPE,
        storeStateName: "lookupDisabilityType",
        dataType: "integer",
        isStateSpecific: true,
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19702],
        title: i18n.t("secondaryDisabilityType"),
        attribute: {
            name: "lookupDisabilityTexasSecondaryType",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_TEXAS_PRIMARY_TYPE,
        storeStateName: "lookupDisabilityTexasPrimaryTypeList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19701],
        title: i18n.t("tertiaryDisabilityType"),
        attribute: {
            name: "tertiaryDisabilityType",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_DISABILITY_TYPE,
        storeStateName: "lookupDisabilityType",
        dataType: "integer",
        isStateSpecific: true,
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19702],
        title: i18n.t("tertiaryDisabilityType"),
        attribute: {
            name: "lookupDisabilityTexasTertiaryType",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_TEXAS_PRIMARY_TYPE,
        storeStateName: "lookupDisabilityTexasPrimaryTypeList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19701],
        title: i18n.t("educationalEnvironment"),
        attribute: {
            name: "lookupDisabilityEducationalEnvironment",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_EDUCATIONAL_ENVIRONMENT,
        storeStateName: "lookupDisabilityEducationalEnvironmentList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19702],
        title: i18n.t("educationalEnvironment"),
        attribute: {
            name: "lookupDisabilityTexasEducationalEnvironment",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_TEXAS_EDUCATIONAL_ENVIRONMENT,
        storeStateName: "lookupDisabilityTexasEducationalEnvironmentList",
        dataType: "integer",
        required: false,
        requiredOn: "isDisabled",
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19701],
        title: i18n.t("supportServices"),
        attribute: {
            name: "lookupDisabilitySupportSerivices",
            type: "select",
            minLength: null,
            maxLength: null,
            isMulti: true,
            placeholder: i18n.t("select")
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_SUPPORT_SERIVICES,
        storeStateName: "lookupDisabilitySupportSerivicesList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19702],
        title: i18n.t("supportServices"),
        attribute: {
            name: "lookupDisabilityTexasSupportSerivices",
            type: "select",
            minLength: null,
            maxLength: null,
            isMulti: true,
            placeholder: i18n.t("select")
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_TEXAS_SUPPORT_SERIVICES,
        storeStateName: "lookupDisabilityTexasSupportSerivicesList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19701],
        title: i18n.t("preschoolDisabilityServiceLocation"),
        attribute: {
            name: "lookupDisabilityPreschoolServiceLocation",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_PRESCHOOL_SERVICE_LOCATION,
        storeStateName: "lookupDisabilityPreschoolServiceLocationList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19702],
        title: i18n.t("preschoolDisabilityServiceLocation"),
        attribute: {
            name: "lookupDisabilityTexasPreschoolServiceLocation",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_TEXAS_PRESCHOOL_SERVICE_LOCATION,
        storeStateName: "lookupDisabilityTexasPreschoolServiceLocationList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19701, 19702],
        title: i18n.t("interpretingServices"),
        attribute: {
            name: "lookupDisabilityInterpretingServices",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_INTERPRETING_SERVICES,
        storeStateName: "lookupDisabilityInterpretingServicesList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19701, 19702],
        title: i18n.t("disabilityConditionType"),
        attribute: {
            name: "lookupDisabilityConditionType",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_DISABILITY_CONDITION_TYPE,
        storeStateName: "lookupDisabilityConditionType",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19701, 19702],
        title: i18n.t("accommodationNeedType"),
        attribute: {
            name: "lookupAccommodationNeedType",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_ACCOMMODATION_NEED_TYPE,
        storeStateName: "lookupAccommodationNeedType",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: '',
        childActiveValue: 4099,
        childField: {
            title: i18n.t("other"),
            attribute: {
                name: "accommodationNeedTypeOther",
                type: "text",
                minLength: null,
                maxLength: null,
                placeholder: i18n.t("enterOtherType")
            },
            onlyField: true,
            dataType: "string",
            required: false,
            validationRegex: null,
            validationMessage: null,
            value: "",
            defaultValue: ""
        }
    },
    {
        programType: [19701, 19702],
        title: i18n.t("disabilityConditionNature"),
        attribute: {
            name: "lookupDisabilityConditionNature",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_DISABILITY_CONDITION_NATURE,
        storeStateName: "lookupDisabilityConditionNature",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19701, 19702],
        title: i18n.t("significantCognitiveDisability"),
        attribute: {
            name: "isSignificantCognitiveDisability",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19702],
        title: i18n.t("childCountFunding"),
        attribute: {
            name: "lookupDisabilityChildCountFunding",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_CHILD_COUNT_FUNDING,
        storeStateName: "lookupDisabilityChildCountFundingList",
        dataType: "integer",
        required: false,
        requiredOn: "isDisabled",
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19702],
        title: i18n.t("multiplyDisabled"),
        attribute: {
            name: "isMultiplyDisabled",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19702],
        title: i18n.t("medicallyFragile"),
        attribute: {
            name: "isMedicallyFragile",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19702],
        title: i18n.t("regionalDaySchoolProgramForDeaf"),
        attribute: {
            name: "lookupDisabilityRegionalDaySchoolProgramForDeaf",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_REGIONAL_DAY_SCHOOL_PROGRAM_FOR_DEAF,
        storeStateName: "lookupDisabilityRegionalDaySchoolProgramForDeafList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19702],
        title: i18n.t("regionalDaySchoolProgramForDeafServiceDistrict"),
        attribute: {
            name: "regionalDaySchoolProgramForDeafServiceDistrict",
            type: "text",
            minLength: null,
            maxLength: 6
        },
        dataType: "string",
        required: false,
        validationRegex: commonConfig.ALPHA_WITH_SPECIAL_CHAR,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19702],
        title: i18n.t("iepContinuer"),
        attribute: {
            name: "isIepContinuer",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19704],
        title: i18n.t("studentMilitaryConnection"),
        attribute: {
            name: "lookupStudentMilitaryConnection",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_MILITARY_CONNECTION,
        storeStateName: "lookupStudentMilitaryConnectionList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19705],
        title: i18n.t("studentMilitaryConnection"),
        attribute: {
            name: "lookupStudentMilitaryConnection",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_MILITARY_CONNECTION_TEXAS,
        storeStateName: "lookupMilitaryConnectionTexusList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19704, 19705],
        title: i18n.t("militaryBranch"),
        attribute: {
            name: "lookupMilitaryBranch",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_MILITARY_BRANCH,
        storeStateName: "lookupStudentMilitaryBranchList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19703],
        title: i18n.t("enrollmentgiftedprogram"),
        attribute: {
            name: "lookupGiftedprogram",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_ENROLLMENT_GIFTED_PROGRAM,
        storeStateName: "lookupEnrollmentGiftedProgramList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19703],
        title: i18n.t("giftedTalentIndicator"),
        attribute: {
            name: "giftedTalentIndicator",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19713],
        title: i18n.t("starTexas"),
        attribute: {
            name: "starOfTexas",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19712],
        title: i18n.t("associateDegreeEarnedWhileAtDistrict"),
        attribute: {
            name: "associateDegreeEarnedWhileAtDistrict",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19711],
        title: i18n.t("migrant"),
        attribute: {
            name: "migrant",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19710],
        title: i18n.t("immigrant"),
        attribute: {
            name: "immigrant",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19709],
        title: i18n.t("atRisk"),
        attribute: {
            name: "atRisk",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19708],
        title: i18n.t("truant"),
        attribute: {
            name: "truant",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19708],
        title: i18n.t("truancyPreventionMeasure"),
        attribute: {
            name: "truancyPreventionMeasure",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19708],
        title: i18n.t("truancyComplaint"),
        attribute: {
            name: "truancyComplaint",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19707],
        title: i18n.t("displaced"),
        attribute: {
            name: "displaced",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: true,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19722],
        title: i18n.t("asyleeRefugee"),
        attribute: {
            name: "lookupAsyleeRefugee",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_ASYLEE_REFUGEE,
        storeStateName: "lookupAsyleeRefugeeList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19720],
        title: i18n.t("pregnancyRelatedServices"),
        attribute: {
            name: "pregnancyRelatedServices",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19718],
        title: i18n.t("interventionStrategy"),
        attribute: {
            name: "interventionStrategy",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19716],
        title: i18n.t("charterWaitlist"),
        attribute: {
            name: "charterWaitlist",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19714],
        title: i18n.t("pkEligiblePreviousYear"),
        attribute: {
            name: "pkEligiblePreviousYear",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19706],
        title: i18n.t("economicDisadvantage"),
        attribute: {
            name: "lookupEconomicDisadvantage",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_ECONOMIC_DISADVANTAGE,
        storeStateName: "lookupEconomicDisadvantageList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19706],
        title: i18n.t("foodServiceEligibility"),
        attribute: {
            name: "lookupFoodServiceEligibility",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY,
        storeStateName: "lookupFoodServiceEligibility",
        onChangeCallback: "getFoodServiceEligibilityTypeLookup",
        callChangeFuncOnLoad: true,
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19706],
        title: i18n.t("foodServiceEligibilityType"),
        attribute: {
            name: "lookupFoodServiceEligibilityType",
            type: "select",
            minLength: null,
            maxLength: null,
            isMulti: true,
            placeholder: i18n.t("select")
        },
        lookupType: null,
        storeStateName: "lookupFoodServiceEligibilityTypeList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19729],
        title: i18n.t("crisis"),
        attribute: {
            name: "lookupCrisis",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_CRISIS,
        storeStateName: "lookupCrisisList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19728],
        title: "T-STEM",
        attribute: {
            name: "isTStem",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19728],
        title: "ECHS",
        attribute: {
            name: "isEchs",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-10 mb-3"
    },
    {
        programType: [19728],
        title: "P-TECH",
        attribute: {
            name: "isPTech",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-10 mb-3"
    },
    {
        programType: [19728],
        title: i18n.t("newTech"),
        attribute: {
            name: "isNewTech",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-10 mb-3"
    },
    {
        programType: [19727],
        title: i18n.t("displacedHomemaker"),
        attribute: {
            name: "isDisplacedHomemaker",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19727],
        title: i18n.t("singleParentPregnantTeen"),
        attribute: {
            name: "lookupSingleParentPregnantTeen",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_SINGLE_PARENT_PREGNANT_TEEN,
        storeStateName: "lookupSingleParentPregnantTeenList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19727],
        title: i18n.t("transportationCteSupportService"),
        attribute: {
            name: "isTransportationCteSupportService",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19715],
        title: i18n.t("adultPreviousAttendance"),
        attribute: {
            name: "isAdultPreviousAttendance",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19717],
        title: i18n.t("additionalDaysProgramParticipation"),
        attribute: {
            name: "isAdditionalDaysProgramParticipation",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19719],
        title: i18n.t("section504"),
        attribute: {
            name: "isSection504",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19721],
        title: i18n.t("homelessStatus"),
        attribute: {
            name: "lookupHomelessStatus",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_HOMELESS_STATUS,
        storeStateName: "lookupHomelessStatusList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19721],
        title: i18n.t("unaccompaniedYouthStatus"),
        attribute: {
            name: "lookupHomelessUnaccompaniedYouthStatus",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_HOMELESS_UNACCOMPANIED_YOUTH_STATUS,
        storeStateName: "lookupHomelessUnaccompaniedYouthStatusList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19723],
        title: i18n.t("fosterCare"),
        attribute: {
            name: "lookupFosterCare",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_FOSTER_CARE,
        storeStateName: "lookupFosterCareList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19724],
        title: i18n.t("dyslexia"),
        attribute: {
            name: "isDyslexia",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19724],
        title: i18n.t("dyslexiaRisk"),
        attribute: {
            name: "lookupDyslexiaRisk",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DYSLEXIA_RISK,
        storeStateName: "lookupDyslexiaRiskList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19724],
        title: i18n.t("dyslexiaServices"),
        attribute: {
            name: "lookupDyslexiaServices",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DYSLEXIA_SERVICES,
        storeStateName: "lookupDyslexiaServicesList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("lepIndicator"),
        attribute: {
            name: "lookupLepIndicator",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_LEP_INDICATOR,
        storeStateName: "lookupLepIndicatorList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("homeLanguageSurveyDate"),
        attribute: {
            name: "homeLanguageSurveyDate",
            type: "date",
            minLength: null,
            maxLength: null
        },
        dataType: "string",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19725],
        title: i18n.t("parentalPermission"),
        attribute: {
            name: "lookupLepParentalPermission",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_LEP_PARENTAL_PERMISSION,
        storeStateName: "lookupLepParentalPermissionList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("yearsInUsSchools"),
        attribute: {
            name: "lookupYearsInUsSchools",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_YEARS_IN_US_SCHOOLS,
        storeStateName: "lookupYearsInUsSchoolsList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("bilingualProgram"),
        attribute: {
            name: "lookupLepBilingualProgram",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_LEP_BILINGUAL_PROGRAM,
        storeStateName: "lookupLepBilingualProgramList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("eslProgram"),
        attribute: {
            name: "lookupLepEslProgram",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_LEP_ESL_PROGRAM,
        storeStateName: "lookupLepEslProgramList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("alternateLanguageProgram"),
        attribute: {
            name: "lookupLepAlternateLanguageProgram",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_LEP_ALTERNATE_LANGUAGE_PROGRAM,
        storeStateName: "lookupLepAlternateLanguageProgramList",
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("bilingualEslSummerSchool"),
        attribute: {
            name: "isBilingualEslSummerSchool",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19725],
        title: i18n.t("priorYearBilingualEslSummerSchool"),
        attribute: {
            name: "isPriorYearBilingualEslSummerSchool",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19725],
        title: i18n.t("bilingualEslFunding"),
        attribute: {
            name: "lookupLepBilingualEslFunding",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_LEP_BILINGUAL_ESL_FUNDING,
        storeStateName: "lookupLepBilingualEslFundingList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("dualLanguageImmersionLanguage"),
        attribute: {
            name: "lookupLepDualImmersionLanguage",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_LEP_DUAL_IMMERSION_LANGUAGE,
        storeStateName: "lookupLepDualImmersionLanguageList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("dualLanguageImmersionYear"),
        attribute: {
            name: "lookupLepDualLanguageImmersionYear",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_LEP_DUAL_LANGUAGE_IMMERSION_YEAR,
        storeStateName: "lookupLepDualLanguageImmersionYearList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19725],
        title: i18n.t("dualLanguageImmersionAssessmentMetDate"),
        attribute: {
            name: "dualLanguageImmersionAssessmentMetDate",
            type: "date",
            minLength: null,
            maxLength: null
        },
        dataType: "string",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19725],
        title: i18n.t("dualLanguageImmersionCompletionSchoolYear"),
        attribute: {
            name: "lookupLepDualLanguageCompletionYear",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_LEP_DUAL_LANGUAGE_COMPLETION_YEAR,
        storeStateName: "lookupLepDualLanguageCompletionYearList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19726],
        title: i18n.t("titlePartA"),
        attribute: {
            name: "lookupTitleIPartA",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_TITLE_I_PART_A,
        storeStateName: "lookupTitleIPartAList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19731],
        title: i18n.t("studentWithDisabilitiesResidingAtResidentialFacility"),
        attribute: {
            name: "isStudentWithDisabilitiesResidingAtResidentialFacility",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "onResidentialFacilitySWDRARF",
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19731],
        title: i18n.t("residentialFacility"),
        attribute: {
            name: "residentialFacilityId",
            type: "select",
            minLength: null,
            maxLength: null
        },
        executeFunctionName: '_getResidentialFacilityList',
        storeStateName: "spResidentialFacilityList",
        optionLabelValue: 'TX-ResidentialFacilityID',
        optionLabelTitle: 'TX-ResidentialFacilityName',
        dbTableMap: 'district.state_reporting_field_value.id',
        dataType: "integer",
        required: false,
        requiredOn: "isStudentWithDisabilitiesResidingAtResidentialFacility",
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        titleIndexName: 'residentialFacilityTitle'
    },
    {
        programType: [19731],
        title: i18n.t("schoolEnrolledIn"),
        attribute: {
            name: "enrolledInSchoolId",
            type: "select",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "getGradeLevelList",
        executeFunctionName: '_getSchoolList',
        storeStateName: "spSchoolList",
        optionLabelTitle: 'name',
        dbTableMap: 'school.school_info.id',
        dataType: "integer",
        required: false,
        requiredOn: "isStudentWithDisabilitiesResidingAtResidentialFacility",
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        // getDefaultValueFunction: '_getDefaultEnrolledInSchoolId',
        titleIndexName: 'enrolledInSchoolTitle'
    },
    {
        programType: [19731],
        title: i18n.t("gradeLevel"),
        attribute: {
            name: "gradeLevelId",
            type: "select",
            minLength: null,
            maxLength: null
        },
        executeFunctionName: '_getGradeLevelListBySchoolId',
        storeStateName: "spGradeLevelList",
        optionLabelTitle: 'gradeTitle',
        dependentParam: 'enrolledInSchoolId',
        dbTableMap: 'school.school_grades_offered.id',
        dataType: "integer",
        required: false,
        requiredOn: "isStudentWithDisabilitiesResidingAtResidentialFacility",
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        // getDefaultValueFunction: '_getDefaultGradeLevelId',
        titleIndexName: 'gradeLevelTitle'
    },
    {
        programType: [19731],
        title: i18n.t("attendanceZoneSchool"),
        attribute: {
            name: "attendanceZoneSchoolId",
            type: "select",
            minLength: null,
            maxLength: null
        },
        executeFunctionName: '_getSchoolList',
        storeStateName: "spSchoolList",
        optionLabelTitle: 'name',
        dbTableMap: 'school.school_info.id',
        dataType: "integer",
        required: false,
        requiredOn: "isStudentWithDisabilitiesResidingAtResidentialFacility",
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        titleIndexName: 'attendanceZoneSchoolTitle'
    },
    {
        programType: [19731],
        title: i18n.t("educatedAtResidentialFacility"),
        attribute: {
            name: "isEducatedAtResidentialFacility",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19731],
        title: i18n.t("surrogateParentAssigned"),
        attribute: {
            name: "isSurrogateParentAssigned",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "onSurrogateParentAssigned",
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19731],
        title: i18n.t("numberOfOtherStudentsAssignedToSameSurrogateParent"),
        attribute: {
            name: "numberOfOtherStudentsAssignedToSameSurrogateParent",
            type: "text",
            minLength: null,
            maxLength: 2
        },
        dataType: "string",
        required: false,
        requiredOn: "isSurrogateParentAssigned",
        validationRegex: commonConfig.PHONE_EXT,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19731],
        title: i18n.t("residentialFacilityStudentSchoolDayLength"),
        attribute: {
            name: "residentialFacilityStudentSchoolDayLength",
            type: "text",
            minLength: null,
            maxLength: 3
        },
        dataType: "string",
        required: false,
        requiredOn: "isStudentWithDisabilitiesResidingAtResidentialFacility",
        validationRegex: commonConfig.PHONE_EXT,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19731],
        title: i18n.t("enrollmentSchoolDayLength"),
        attribute: {
            name: "enrollmentSchoolDayLength",
            type: "text",
            minLength: null,
            maxLength: 3
        },
        dataType: "string",
        required: false,
        requiredOn: "isStudentWithDisabilitiesResidingAtResidentialFacility",
        validationRegex: commonConfig.PHONE_EXT,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19731],
        title: i18n.t("priorEducationEnvironment"),
        attribute: {
            name: "lookupPriorEducationEnvironment",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_DISABILITY_TEXAS_EDUCATIONAL_ENVIRONMENT,
        storeStateName: "lookupDisabilityTexasEducationalEnvironmentList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19734],
        title: i18n.t("economicDisadvantageNM"),
        attribute: {
            name: "lookupEconomicDisadvantage",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_ECONOMIC_DISADVANTAGE,
        storeStateName: "lookupEconomicDisadvantageList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19734],
        title: i18n.t("foodServiceEligibility"),
        attribute: {
            name: "lookupFoodServiceEligibility",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY,
        storeStateName: "lookupFoodServiceEligibility",
        onChangeCallback: "getFoodServiceEligibilityTypeLookup2",
        callChangeFuncOnLoad: true,
        isStateSpecific: true,
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19734],
        title: i18n.t("foodServiceType"),
        attribute: {
            name: "lookupFoodServiceType",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: null,
        storeStateName: "lookupFoodServiceEligibilityTypeList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19734],
        title: i18n.t("foodServiceParticipation"),
        attribute: {
            name: "lookupFoodServiceParticipation",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_FOOD_SERVICE_PARTICIPATION,
        storeStateName: "lookupFoodServiceParticipationList",
        isStateSpecific: true,
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19733],
        title: i18n.t("englishProficiency"),
        attribute: {
            name: "lookupEnglishProficiency",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_ENGLISH_PROFICIENCY,
        storeStateName: "lookupEnglishProficiencyList",
        isStateSpecific: true,
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19733],
        title: i18n.t("titleIII"),
        attribute: {
            name: "isTitleIII",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "onEnglishLearner",
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30",
    },
    {
        programType: [19733],
        title: i18n.t("englishLearnerParticipation"),
        attribute: {
            name: "lookupElProgram",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_EL_PROGRAM,
        storeStateName: "lookupElProgramList",
        isStateSpecific: true,
        dataType: "integer",
        requiredOn: "isTitleIII",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19733],
        title: i18n.t("bilingualEducation"),
        attribute: {
            name: "isBilingualEducation",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "onBilingualEducationChange",
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30",
    },
    {
        programType: [19733],
        title: i18n.t("bilingualEducationParticipation"),
        attribute: {
            name: "lookupLepBilingualProgram",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_LEP_BILINGUAL_PROGRAM,
        storeStateName: "lookupLepBilingualProgramList",
        isStateSpecific: true,
        dataType: "integer",
        requiredOn: "isBilingualEducation",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19733],
        title: i18n.t("bepProgramLanguage"),
        attribute: {
            name: "lookupBepProgramLanguage",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_BEP_PROGRAM_LANGUAGE,
        storeStateName: "lookupBepProgramLanguageList",
        isStateSpecific: true,
        dataType: "integer",
        requiredOn: "isBilingualEducation",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19733],
        title: i18n.t("bepProgramIntensity"),
        attribute: {
            name: "lookupBepProgramIntensity",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_BEP_PROGRAM_INTENSITY,
        storeStateName: "lookupBepProgramIntensityList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19733],
        title: i18n.t("yearsInUsSchools"),
        attribute: {
            name: "lookupYearsInUsSchools",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_YEARS_IN_US_SCHOOLS,
        storeStateName: "lookupYearsInUsSchoolsList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19733],
        title: i18n.t("dateEnteredUs"),
        attribute: {
            name: "dateEnteredUs",
            type: "date",
            minLength: null,
            maxLength: null
        },
        dataType: "string",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19732],
        title: i18n.t("specialEducation"),
        attribute: {
            name: "lookupSpecialEducation",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_SPECIAL_EDUCATION,
        storeStateName: "lookupSpecialEducationList",
        isStateSpecific: true,
        dataType: "integer",
        required: true,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("gifted"),
        attribute: {
            name: "isGifted",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30",
    },
    {
        programType: [19732],
        title: i18n.t("primaryAreaOfExceptionality"),
        attribute: {
            name: "lookupPrimaryAreaOfExceptionality",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_PRIMARY_AREA_OF_EXCEPTIONALITY,
        storeStateName: "lookupPrimaryAreaOfExceptionalityList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("currentIep"),
        attribute: {
            name: "currentIepDate",
            type: "date",
            minLength: null,
            maxLength: null
        },
        dataType: "string",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19732],
        title: i18n.t("currentEvaluation"),
        attribute: {
            name: "currentEvaluationDate",
            type: "date",
            minLength: null,
            maxLength: null
        },
        dataType: "string",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: "",
        defaultValue: ""
    },
    {
        programType: [19732],
        title: i18n.t("specialEducationReferral"),
        attribute: {
            name: "lookupSpecialEducationReferral",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_SPECIAL_EDUCATION_REFERRAL,
        storeStateName: "lookupSpecialEducationReferralList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("primaryServiceProvider"),
        attribute: {
            name: "specialEducationTeacherId",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        executeFunctionName: '_getTeacherList',
        storeStateName: "spTeacherList",
        optionLabelValue: 'id',
        optionLabelTitle: 'staffFullName',
        dbTableMap: 'staff.staff_info.id',
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        titleIndexName: 'specialEducationTeacherTitle'
    },
    {
        programType: [19732],
        title: i18n.t("levelOfIntegration"),
        attribute: {
            name: "lookupLevelOfIntegration",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_LEVEL_OF_INTEGRATION,
        storeStateName: "lookupLevelOfIntegrationList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("additionalServiceProviders"),
        attribute: {
            name: "additionalServiceProviders",
            type: "multiSelect",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        executeFunctionName: '_getTeacherList',
        storeStateName: "spTeacherList",
        optionLabelValue: 'id',
        optionLabelTitle: 'staffFullName',
        dbTableMap: 'staff.staff_info.id',
        dataType: "string",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        titleIndexName: 'specialEducationTeacherTitle'
    },
    {
        programType: [19732],
        title: i18n.t("primarySetting"),
        attribute: {
            name: "lookupPrimarySetting",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_PRIMARY_SETTING,
        storeStateName: "lookupPrimarySettingList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("primaryDisability"),
        attribute: {
            name: "lookupPrimaryDisability",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_STUDENT_DISABILITY_TYPE,
        storeStateName: "lookupDisabilityType",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("secondaryDisability"),
        attribute: {
            name: "lookupSecondaryDisability",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_STUDENT_DISABILITY_TYPE,
        storeStateName: "lookupDisabilityType",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("tertiaryDisability"),
        attribute: {
            name: "lookupTertiaryDisability",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_STUDENT_DISABILITY_TYPE,
        storeStateName: "lookupDisabilityType",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("alternateAssessment"),
        attribute: {
            name: "isAlternateAssessment",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30",
    },
    {
        programType: [19732],
        title: i18n.t("brailleInstruction"),
        attribute: {
            name: "isBrailleInstruction",
            type: "switch",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30",
    },
    {
        programType: [19732],
        title: i18n.t("expectedGraduationOption"),
        attribute: {
            name: "lookupExpectedGraduationOption",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_EXPECTED_GRADUATION_OPTION,
        storeStateName: "lookupExpectedGraduationOptionList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("plannedPostGraduationActivity"),
        attribute: {
            name: "lookupPlannedPostGradActivity",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_PLANNED_POST_GRAD_ACTIVITY,
        storeStateName: "lookupPlannedPostGradActivityList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("annualReviewDelayReason"),
        attribute: {
            name: "lookupAnnualReviewDelayReason",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_ANNUAL_REVIEW_DELAY_REASON,
        storeStateName: "lookupAnnualReviewDelayReasonList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19732],
        title: i18n.t("annualTriennialDelayReason"),
        attribute: {
            name: "lookupTriennialReviewDelayReason",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_TRIENNIAL_REVIEW_DELAY_REASON,
        storeStateName: "lookupTriennialReviewDelayReasonList",
        isStateSpecific: true,
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19736],
        title: i18n.t("nmPreK"),
        attribute: {
            name: "isNmPreK",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "onNMPreKChange",
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19736],
        title: i18n.t("nmPreKParticipation"),
        attribute: {
            name: "lookupNmprekParticipation",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_NMPREK_PARTICIPATION,
        storeStateName: "lookupNmprekParticipationList",
        isStateSpecific: true,
        dataType: "integer",
        requiredOn: "isNmPreK",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19736],
        title: i18n.t("ceisCoordinatedEarlyInterveningServices"),
        attribute: {
            name: "isCEIS",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        onChangeCallback: "onCEISChange",
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19736],
        title: i18n.t("ceisParticipation"),
        attribute: {
            name: "lookupCeisParticipation",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        lookupType: commonConfig.LOOKUP_CEIS_PARTICIPATION,
        storeStateName: "lookupCeisParticipationList",
        isStateSpecific: true,
        dataType: "integer",
        requiredOn: "isCEIS",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    },
    {
        programType: [19736],
        title: i18n.t("faceFamilyAndChildrenEducationProgram"),
        attribute: {
            name: "isFACE",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19736],
        title: i18n.t("headStart"),
        attribute: {
            name: "isHeadStart",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19737],
        title: i18n.t("titleIPartA"),
        attribute: {
            name: "isTitleIPartA",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19737],
        title: i18n.t("titleIPartAPrivateSchool"),
        attribute: {
            name: "isTitleIPartAPS",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19737],
        title: i18n.t("titleIPartANeglectedAndDelinquent"),
        attribute: {
            name: "isTitleIPartANaD",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19737],
        title: i18n.t("titleIPartAFundedPk"),
        attribute: {
            name: "isTitleIPartAFundedPK",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19737],
        title: i18n.t("titleVII"),
        attribute: {
            name: "isTitleVII",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19737],
        title: i18n.t("titleVIII"),
        attribute: {
            name: "isTitleVIII",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19738],
        title: i18n.t("homeSchoolStudentParticipatingInDistrictActivity"),
        attribute: {
            name: "isHSSPDA",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19738],
        title: i18n.t("locationOverrideForHsda"),
        attribute: {
            name: "locationOverrideForHsdaId",
            type: "select",
            minLength: null,
            maxLength: null,
            isClearable: true
        },
        executeFunctionName: '_getSchoolByState',
        storeStateName: "spSchoolList",
        optionLabelValue: 'schoolId',
        optionLabelTitle: 'name',
        dbTableMap: 'school.school_info.id',
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        titleIndexName: 'locationOverrideForHsdaTitle'
    },
    {
        programType: [19738],
        title: i18n.t("charterSchoolStudentParticipationInDistrictActivity"),
        attribute: {
            name: "isCSSPDA",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19738],
        title: i18n.t("workforceReadinessProgram"),
        attribute: {
            name: "isWorkforceReadinessProgram",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19738],
        title: i18n.t("earlyCollegeHighSchool"),
        attribute: {
            name: "isEarlyCollegeHighSchool",
            type: "checkbox",
            minLength: null,
            maxLength: null
        },
        dataType: "bool",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: false,
        defaultValue: false,
        divClassName: "form-group m-t-30"
    },
    {
        programType: [19739],
        title: i18n.t("migrantPrg"),
        attribute: {
            name: "lookupMigrantProgram",
            type: "select",
            minLength: null,
            maxLength: null
        },
        lookupType: commonConfig.LOOKUP_MIGRANT_PROGRAM,
        storeStateName: "lookupMigrantProgramList",
        dataType: "integer",
        required: false,
        validationRegex: null,
        validationMessage: null,
        value: null,
        defaultValue: null,
        selectedOptionTitle: ''
    }
]
const descriptionList = {
    19736: {
        title: i18n.t("SPEarlyChildhoodNMDescription"),
        isPositionTop: false,
        columnSize: 12
    }
}
const routeList = {
    disability: 19701,
    disabilityTexas: 19702,
    gifted: 19703,
    militaryConnected: 19704,
    militaryConnectedTexas: 19705,
    foodService: 19706,
    displaced: 19707,
    truancy: 19708,
    atRisk: 19709,
    immigrant: 19710,
    migrant: 19711,
    associateDegreeTexas: 19712,
    starOfTexas: 19713,
    eligiblePreviousYearTexas: 19714,
    adultPreviousAttendanceTexas: 19715,
    charterWaitlistTexas: 19716,
    additionalDaysProgramTexas: 19717,
    interventionTexas: 19718,
    section504: 19719,
    pregnancyRelatedServices: 19720,
    homeless: 19721,
    asyleeRefugee: 19722,
    fosterCare: 19723,
    dyslexia: 19724,
    limitedEnglishProficiency: 19725,
    titlePartA: 19726,
    careerAndTechnicalEducation: 19727,
    specializedSchoolTexas: 19728,
    crisisTexas: 19729,
    residentialFacilityTexas: 19731,
    specialEducationNewMexico: 19732,
    englishProficiencyNewMexico: 19733,
    economicDisadvantagedFoodServiceNewMexico: 19734,
    earlyChildhoodNewMexico: 19736,
    federalProgramsNewMexico: 19737,
    programsFactOtherNewMexico: 19738,
    migrantNewMexico: 19739
}
export { getData, routeList, descriptionList }