import * as actionTypesShared from "../actions/actionTypesShared"
import { updateObject } from '../utility';
import commonConfig from '../../../common/commonConfig';
import { sessionStore } from '../../../common/component/sessionStoreComponent';
import { camelCase } from 'lodash';
const initialState = {
    saveDashboardSettings: {},
    getDashboardSettings: {},
    getSpecialProgramMenuPermissions: {},
    isLoading: false,
    notifyType: 'success',
    notifyMessage: '',
    errorMessage: '',
    commonDelete: false,
    countryList: [],
    saveCustomFieldValues: false,
    receiveMessagesList: [],
    getCustomField: {},
    customFieldList: null,
    menuList: [],
    userMenuList: [],
    getFileUrl: {},
    getMenuPermissions: {},
    searchResultList: [],
    searchFieldList: [],
    fileDelete: false,
    saveUserPreference: false,
    isActiveComposeEmailPopup: false,
    getUserProfilePicture: false,
    isActiveComposeMessagePopup: false,
    isSaveEmailPopup: false,
    dynamicFormList: [],
    saveDynamicForm: false,
    getDynamicFormData: {},
    stateByCountryList: [],
    countyByStateList: [],
    colourList: [],
    messageProps: {},
    pendingPasswordStudentList: [],
    saveGenerateRegenerateStudentPassword: false,
    saveGenerateNotifyStudentPassword: false,
    studentNotifyList: [],
    /* lookup state */
    lookupSchoolType: [],
    lookupSchoolLevel: [],
    lookupSchoolFocusType: [],
    lookupAddressTypeList: [],
    lookupPersonAddressTypeList: [],
    lookupSchoolIdentificationSystemList: [],
    lookupPhoneTypeList: [],
    lookupEmailTypeList: [],
    lookupDesignationTypeList: [],
    lookupAccredationAgencyTypeList: [],
    lookupHeAccredationTypeList: [],
    lookupAdminFundControlList: [],
    lookupSchoolOperationalStatusList: [],
    lookupSchoolImprovementStatusList: [],
    lookupSchoolGradeList: [],
    lookupStudentEthnicityList: [],
    filterdata: null,
    lookupStudentHispanicLatinoEthnicityList: [],
    lookupStudentHispanicLatinoRaceEthList: [],
    lookupStudentLanguageList: [],
    lookupStudentRollingRetentionList: [],
    lookupVisibility: [],
    lookupSchoolFieldType: [],
    lookupSchoolFieldDataType: [],
    lookupSchoolFieldDateFormat: [],
    lookupRoleTypeList: [],
    lookupStudentIdentificationSystem: [],
    lookupStudentPublicSchoolResidentialStatusList: [],
    lookupStudentOtherBackwardCommunityList: [],
    lookupStudentMilitaryBranchList: [],
    lookupStudentMilitaryConnectionList: [],
    lookupStudentVerifiableInfoList: [],
    lookupDocumentVerificationCertificateList: [],
    lookupStudentVisaTypeList: [],
    lookupPersonRelationshipList: [],
    lookupRelationList: [],
    lookupSalutationList: [],
    lookupDisabilityType: [],
    lookupAccommodationNeedType: [],
    lookupDisabilityConditionType: [],
    lookupDisabilityConditionNature: [],
    lookupDeterminedBy: [],
    lookupIdeaDisabilityType: [],
    lookUpMarkingPeriodList: [],
    lookupFoodServiceEligibility: [],
    lookupSchoolLunchProgramType: [],
    lookupFoodServiceEligibilityFree: [],
    lookupFoodServiceEligibilityFull: [],
    lookupFoodServiceEligibilityReduced: [],
    lookupFoodServiceEligibilityOther: [],
    lookupStudentReferralType: [],
    lookupStudentReferralOutcome: [],
    lookupVisionStatusList: [],
    lookupHearingStatusList: [],
    lookupDentalStatusList: [],
    lookupHealthInsuranceCoverageList: [],
    lookupDentalInsuranceCoverageList: [],
    lookupMedicalAlertTypeList: [],
    lookupStudentHealthScreeningStatusList: [],
    lookupStudentHealthScreeningTypeList: [],
    lookupStudentMultipleBirthList: [],
    lookupNonPromotionReasonList: [],
    lookupPromotionReasonList: [],
    lookupExitWithdrawalTypeList: [],
    lookupExitWithdrawalStatusList: [],
    lookupEnrollmentStatusList: [],
    lookupRollingRetentionList: [],
    lookupEnrollmentEntryTypeList: [],
    lookupEnrollmentGiftedProgramList: [],
    LookUpSchoolSessionTitle: [],
    LookupCrisisType: [],
    lookupSchoolCalendarEventType: [],
    lookupEntityType: [],
    lookupCredentialTypeList: [],
    lookupStaffVisaTypeList: [],
    lookupCertificateAccreditationStatusList: [],
    lookupRelationship: [],
    lookupTeachingCredentialTypeList: [],
    lookupTeachingCredentialBasisList: [],
    lookupStaffCertSponsorTypeList: [],
    lookupStudentInsuranceTypeList: [],
    lookupLeaveTypeList: [],
    lookupPublicationStatusList: [],
    lookupCourseLevelCharacteristic: [],
    lookupCourseCreditUnit: [],
    lookupCourseWblOpportunityType: [],
    lookupAditionalCreditType: [],
    lookupCourseGpaApplicability: [],
    lookupCurriculumFrameworkType: [],
    lookupCourseCreditTypeEarned: [],
    lookupCourseCareerCluster: [],
    lookupCourseBlndLearningModelType: [],
    lookupCourseExamRequirement: [],
    lookupAdvancedPlacementCourseCode: [],
    lookupCertificationTypeList: [],
    lookupPeriodScheduleList: [],
    lookupSingleSexClassList: [],
    lookupCourseDeliveryList: [],
    lookupCourseSectionLevelTypeList: [],
    lookupCertificateVerificationMethodList: [],
    lookupDocumentVerificationStatusList: [],
    lookupStaffLeaveStatusList: [],
    lookupCourseType: [],
    lookupMathOperatorList: [],
    lookupReportOperatorList: [],
    lookupAttendanceStatusList: [],
    lookupPresentAttendanceCategoryList: [],
    lookupAbsentAttendanceCategoryList: [],
    lookupIncidentTimeType: [],
    lookupIncidentBehaviourType: [],
    lookupIncidentBehaviour: [],
    lookupWeaponType: [],
    lookupInjuryType: [],
    lookupIncidentLocation: [],
    lookupFireArmType: [],
    lookupMultipleOffenceType: [],
    lookupGradeScaleDefination: [],
    lookupAssessmentAssetTypeList: [],
    lookupSearchType: [],
    lookupAccommodationTypeList: [],
    lookupAccommodationCategoryList: [],
    lookupDisciplineReason: [],
    lookupDisciplinaryActionTaken: [],
    lookupDisciplineLengthDifference: [],
    lookupIdeaInterimRemoval: [],
    lookupIdeaInterimRemovalReason: [],
    lookupFirearmDisciplineMethod: [],
    lookupIdeaFirearmDisciplineMethod: [],
    lookupDmForDiabeticChildren: [],
    lookupIncidentPersonRole: [],
    lookupIncidentPersonType: [],
    lookupAssessmentPurposeList: [],
    lookupAssessmentTypeList: [],
    lookupAssessmentTypeWithDisabilityList: [],
    lookupAssessmentRegistrationCompletionStatusList: [],
    lookupAssessmentParticipationList: [],
    lookupAssessmentReasonNotTestedList: [],
    lookupAssessmentReasonNotCompletedList: [],
    lookupAssessmentReportingMethodList: [],
    lookupAssessmentSessionType: [],
    lookupAssessmentFormSecIdentificationSystemList: [],
    lookupScoreMetricTypeList: [],
    lookupAssessmentOutcomeList: [],
    lookupResultScoreTypeList: [],
    lookupCourseSectionExitType: [],
    lookupAnnouncementType: [],
    lookupEntryType: [],
    lookupCourseSequenceType: [],
    lookupPersonOccupationTypeList: [],
    lookupAssignmentAggregationList: [],
    lookupAssignmentGradingTypeList: [],
    lookupGPACalCBasedOn: [],
    lookupAdaEligibilityTypeList: [],
    lookupGradeTypeList: [],
    lookupNameSuffixList: [],
    lookupStudentImmunizationRouteList: [],
    lookupStudentImmunizationStatusList: [],
    lookupStudentInjectionSiteList: [],
    lookupStudentMedicalConditionList: [],
    lookupStudentBehaviorConditionList: [],
    lookupStudentMedicationList: [],
    lookupStudentMedicationRouteList: [],
    lookupStudentMedicationFrequencyList: [],
    lookupStaffDCPIdentificationSystemList: [],
    lookupStudentMedicationFormList: [],
    lookupStudentMedicationDoseUnitList: [],
    lookupStudentMedicationReasonList: [],
    lookupCourseStudyMaterialType: [],
    stateCodeList: [],
    stateReportTermList: [],
    stateReportList: [],
    getStateReport: {},
    getStateReportFile: {},
    stateReportHistoryList: null,
    stateFieldsList: [],
    getStudentRestraintInfo: {},
    getStateFieldView: null,
    getResStateFieldView: null,
    saveStateFieldsValues: {},
    lookupStudentDentalScreeningStatusList: [],
    lookupStudentHealthReferralResultList: [],
    lookupStudentHearingTestList: [],
    lookupStudentEyePowerList: [],
    lookupStudentScreeningTestResultList: [],
    lookupStudentSpinalActionTakenList: [],
    lookupStudentSpinalDiagnosisList: [],
    lookupStudentSpinalTreatmentList: [],
    lookupStudentVisionTestList: [],
    lookupStudentSpecialProgramsList: [],
    lookupStudentVisitReasonMissedList: [],
    lookupStudentVisitReasonList: [],
    lookupStudentActionTakenList: [],
    lookupDaysInWeekList: [],
    lookupSchoolDayEventList: [],
    lookupAttendanceReportTypeList: [],
    lookupAbsenceLetterOperatorList: [],
    lookupCannedRptAttendanceCategoryList: [],
    lookuplookupCannedRptAttendanceStudentList: [],
    lookupCalendarWeiverEventList: [],
    lookupFoodServiceEligibilityTypeList: [],
    lookupDisabilityEducationalEnvironmentList: [],
    lookupDisabilitySupportSerivicesList: [],
    lookupDisabilityPreschoolServiceLocationList: [],
    lookupDisabilityInterpretingServicesList: [],
    lookupDisabilityTexasDeterminedByList: [],
    lookupDisabilityTexasPrimaryTypeList: [],
    lookupDisabilityTexasEducationalEnvironmentList: [],
    lookupDisabilityTexasSupportSerivicesList: [],
    lookupDisabilityTexasPreschoolServiceLocationList: [],
    lookupDisabilityChildCountFundingList: [],
    lookupDisabilityRegionalDaySchoolProgramForDeafList: [],
    lookupHomelessStatusList: [],
    lookupSubmitTypeList: [],
    lookupHomelessUnaccompaniedYouthStatusList: [],
    lookupAsyleeRefugeeList: [],
    lookupFosterCareList: [],
    lookupDyslexiaRiskList: [],
    lookupDyslexiaServicesList: [],
    lookupLepIndicatorList: [],
    lookupLepParentalPermissionList: [],
    lookupLepBilingualProgramList: [],
    lookupLepEslProgramList: [],
    lookupLepAlternateLanguageProgramList: [],
    lookupLepBilingualEslFundingList: [],
    lookupTitleIPartAList: [],
    lookupSingleParentPregnantTeenList: [],
    lookupCrisisList: [],
    lookupEconomicDisadvantageList: [],
    lookupMilitaryConnectionTexusList: [],
    lookupAssignmentQuestionTypeList: [],
    lookupAssignmentBankPublicationStatusList: [],
    lookupDCPProgramType: [],
    lookupDistrictServiceIdCourseUnits: [],
    lookupDistrictServiceIdCareerCourse: [],
    lookupStudentNameDisplayPattern: [],
    lookupStudentNameDisplaySort: [],
    lookupAdhocScheduleTypeList: [],
    lookupCannedSortOrderList: [],
    lookupCannedOptionList: [],
    generateReportList: [],
    getGenerateReport: {},
    getPercentageReport: {},
    getWithdrawalReport: {},
    withdrawalStudentList: [],
    lookupDistrictOparationStatusList: [],
    lookupDistrictTypeList: [],
    lookupDistrictAddressType: [],
    lookupCannedRptGradeListingGradeTypeList: [],
    lookupCannedRptAdaEligibilityDisplayList: [],
    lookupCannedRptAdaEligibilitySortOrderList: [],
    lookupReportFrequencyTypeList: [],
    lookupCannedRptMstSchSortOrderList: [],
    lookupActiveStatusList: [],
    lookupStaffNameDisplayPattern: [],
    lookupDistrictEmailTypeList: [],
    lookupLabelLengthUnitList: [],
    lookupLabelRowList: [],
    lookupLabelColumnList: [],
    lookupLabelPageTypeList: [],
    lookupLabelPageOrientationList: [],
    lookupCannedRptMailingLabelPrintOptions: [],
    lookupCannedRptMailingLabelOptions: [],
    saveSchoolLabel: false,
    schoolLabelList: [],
    getSchoolLabel: {},
    labelOrientationList: [],
    lookupCannedRptStudentEnrollmentDropType: [],
    lookupCannedRptStudentEnrollmentSort: [],
    lookupCannedRptStudentEnrollmentSortBy: [],
    lookupCertificationStatusList: [],
    lookupBloodGroupTypeList: [],
    lookupReligionList: [],
    lookupEwsCategory: [],
    lookupHighestLevelEducationList: [],
    lookupGraduationPlanTypeList: [],
    lookupCannedRptAttendanceStudentList: [],
    getPerfectAttendancePercentage: {},
    lookupAbsentAttendanceUnexcusedCategoryList: [],
    lookupAbsentAttendanceExcusedCategoryList: [],
    lookupAttendanceCategoryTypesList: [],
    lookupCredentialUseList: [],
    lookupDateformatList: [],
    lookupDateformatDelimiterList: [],
    lookupCourseExplanationList: [],
    lookupStudentCertificateTypeList: [],
    lookupIdCardOrientationTypeList: [],
    lookupIdCardSizeVerticalList: [],
    lookupIdCardSizeHorizontalList: [],
    getLmsSubscriptionFlag: null,
    lookupStaffAddressTypeList: [],
    lookUpCommentTypeList: [],
    lookUpStMassAssignCategoryList: [],
    lookupCourseExplanationTypeList: [],
    lookupYearsInUsSchoolsList: [],
    lookupLepDualImmersionLanguageList: [],
    lookupLepDualLanguageImmersionYearList: [],
    lookupLepDualLanguageCompletionYearList: [],
    lookupClassRoomType: [],
    lookupSrReportingTermList: [],
    commonUploadFileSizeList: [],
    lookupEconomicDisadvantagedList: [],
    lookupFoodServiceParticipationList: [],
    lookupEnglishProficiencyList: [],
    lookupElProgramList: [],
    lookupBepProgramLanguageList: [],
    lookupSpecialEducationList: [],
    lookupPrimaryAreaOfExceptionalityList: [],
    lookupSpecialEducationReferralList: [],
    lookupLevelOfIntegrationList: [],
    lookupPrimarySettingList: [],
    lookupDisabilityList: [],
    lookupExpectedGraduationOptionList: [],
    lookupPlannedPostGradActivityList: [],
    lookupAnnualReviewDelayReasonList: [],
    lookupTriennialReviewDelayReasonList: [],
    lookupBepProgramIntensityList: [],
    lookupNmprekParticipationList: [],
    lookupCeisParticipationList: [],
    lookUpSystemDistrictValues: [],
    lookupReportBasedOnList: [],
    lookupReportTypeList: [],
    lookupSortOrderList: [],
    lookupMigrantProgramList: [],
    saveDistrictTestFormat: {},
    unpaidStudentList: [],
    getStudentPercentageReport: {}
}
const handleFail = (state) => {
    return updateObject(state, {
        isLoading: false
    });
}
const handleSuccess = (state, action, storeStateName) => {
    if (storeStateName === 'stateFieldsList') {
        return updateObject(state, {
            loading: false,
            [storeStateName]: action.responseData.data.uiResult
        });
    } else if (!!action.isResponseConvertWithJson) {
        return updateObject(state, {
            loading: false,
            [storeStateName]: !!action.responseData.data ? JSON.parse(action.responseData.data) : action.responseData.data
        });
    } else {
        return updateObject(state, {
            loading: false,
            [storeStateName]: action.responseData.data
        });
    }
}
const storeLookupToSession = (state, action) => {
    var lookups = action.lookups;
    var postedData = action.postedData["categoryType"];
    if (lookups !== null && lookups !== "") {
        new sessionStore().storeSecureData(commonConfig.SESSION_LOOKUPS + postedData, JSON.stringify(lookups));
        switch (postedData) {
            case commonConfig.LOOKUP_ABSENT_ATTENDANCE_EXCUSED_CATEGORY: return updateObject(state, { isLoading: false, lookupAbsentAttendanceExcusedCategoryList: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_ATTENDANCE_TYPE: return updateObject(state, { isLoading: false, lookupAttendanceReportTypeList: lookups });
            case commonConfig.LOOKUP_ABSENCE_LETTER_OPERATOR: return updateObject(state, { isLoading: false, lookupAbsenceLetterOperatorList: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_ATTENDANCE_CATEGORY: return updateObject(state, { isLoading: false, lookupCannedRptAttendanceCategoryList: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_ATTENDANCE_STUDENTS: return updateObject(state, { isLoading: false, lookupCannedRptAttendanceStudentList: lookups });
            case commonConfig.LOOKUP_MATH_OPERATOR: return updateObject(state, { isLoading: false, lookupMathOperatorList: lookups });
            case commonConfig.LOOKUP_REPORT_OPERATOR: return updateObject(state, { isLoading: false, lookupReportOperatorList: lookups });
            case commonConfig.LOOKUP_ENTITY_TYPE: return updateObject(state, { isLoading: false, lookupEntityType: lookups });
            case commonConfig.LOOKUP_SCHOOL_TYPE: return updateObject(state, { isLoading: false, lookupSchoolType: lookups });
            case commonConfig.LOOKUP_SCHOOL_LEVEL: return updateObject(state, { isLoading: false, lookupSchoolLevel: lookups });
            case commonConfig.LOOKUP_SCHOOL_FOCUS: return updateObject(state, { isLoading: false, lookupSchoolFocusType: lookups });
            case commonConfig.LOOKUP_ADDRESS_TYPE: return updateObject(state, { isLoading: false, lookupAddressTypeList: lookups });
            case commonConfig.LOOKUP_PERSON_ADDRESS_TYPE: return updateObject(state, { isLoading: false, lookupPersonAddressTypeList: lookups });
            case commonConfig.LOOKUP_SCHOOL_IDENTIFICATION_SYSTEM: return updateObject(state, { isLoading: false, lookupSchoolIdentificationSystemList: lookups });
            case commonConfig.LOOKUP_PHONE_TYPE: return updateObject(state, { isLoading: false, lookupPhoneTypeList: lookups });
            case commonConfig.LOOKUP_EMAIL_TYPE: return updateObject(state, { isLoading: false, lookupEmailTypeList: lookups });
            case commonConfig.LOOKUP_SCHOOL_DESIGNATION_TYPE: return updateObject(state, { isLoading: false, lookupDesignationTypeList: lookups });
            case commonConfig.LOOKUP_ACCREDITATION_AGENCY: return updateObject(state, { isLoading: false, lookupAccreditationAgencyList: lookups });
            case commonConfig.LOOKUP_HE_ACCREDITATION_STATUS: return updateObject(state, { isLoading: false, lookupHeAccreditationStatusList: lookups });
            case commonConfig.LOOKUP_ADMIN_FUND_CONTROL: return updateObject(state, { isLoading: false, lookupAdminFundControlList: lookups });
            case commonConfig.LOOKUP_SCHOOL_OPERATIONAL_STATUS: return updateObject(state, { isLoading: false, lookupSchoolOperationalStatusList: lookups });
            case commonConfig.LOOKUP_SCHOOL_IMPROVEMENT_STATUS: return updateObject(state, { isLoading: false, lookupSchoolImprovementStatusList: lookups });
            case commonConfig.LOOKUP_SCHOOL_GRADE_LEVEL: return updateObject(state, { isLoading: false, lookupSchoolGradeList: lookups });
            case commonConfig.LOOKUP_SCHOOL_CALENDAR_VISIBILITY: return updateObject(state, { isLoading: false, lookupVisibility: lookups });
            case commonConfig.LOOKUP_SCHOOL_FIELD_TYPE: return updateObject(state, { isLoading: false, lookupSchoolFieldType: lookups });
            case commonConfig.LOOKUP_SCHOOL_FIELD_DATA_TYPE: return updateObject(state, { isLoading: false, lookupSchoolFieldDataType: lookups });
            case commonConfig.LOOKUP_SCHOOL_FIELD_DATE_FORMAT: return updateObject(state, { isLoading: false, lookupSchoolFieldDateFormat: lookups });
            case commonConfig.LOOKUP_ROLE_TYPE: return updateObject(state, { isLoading: false, lookupRoleTypeList: lookups });
            case commonConfig.LOOKUP_SALUTATION_TYPE: return updateObject(state, { isLoading: false, lookupSalutationList: lookups });
            case commonConfig.LOOKUP_SALUTATION_STAFF_TYPE: return updateObject(state, { isLoading: false, lookupSalutationList: lookups });
            case commonConfig.LOOKUP_MARKINGPERIOD_TYPE: return updateObject(state, { isLoading: false, lookUpMarkingPeriodList: lookups });
            case commonConfig.LOOKUP_SCHOOL_SESSION_TITLE: return updateObject(state, { isLoading: false, LookUpSchoolSessionTitle: lookups });
            case commonConfig.LOOKUP_CRISIS_TYPE: return updateObject(state, { isLoading: false, LookupCrisisType: lookups });
            case commonConfig.LOOKUP_SCHOOL_CALENDAR_EVENT_TYPE: return updateObject(state, { isLoading: false, lookupSchoolCalendarEventType: lookups });
            case commonConfig.LOOKUP_GRADE_SCALE_DEFINITION: return updateObject(state, { isLoading: false, lookupGradeScaleDefination: lookups });
            case commonConfig.LOOKUP_SEARCH_TYPE: return updateObject(state, { isLoading: false, lookupSearchType: lookups });
            case commonConfig.LOOKUP_GPA_CALC_BASED_ON: return updateObject(state, { isLoading: false, lookupGPACalCBasedOn: lookups });
            case commonConfig.LOOKUP_GRADE_TYPE: return updateObject(state, { isLoading: false, lookupGradeTypeList: lookups });
            case commonConfig.LOOKUP_DAYS_IN_WEEK: return updateObject(state, { isLoading: false, lookupDaysInWeekList: lookups });
            case commonConfig.LOOKUP_BS_SCHOOL_DAY_EVENT: return updateObject(state, { isLoading: false, lookupSchoolDayEventList: lookups });
            case commonConfig.LOOKUP_BS_CALENDAR_WAIVER_EVENT: return updateObject(state, { isLoading: false, lookupCalendarWeiverEventList: lookups });
            case commonConfig.LOOKUP_ADHOC_SCHEDULE_TYPE: return updateObject(state, { isLoading: false, lookupAdhocScheduleTypeList: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_GRADE_LISTING_GRADE_TYPE: return updateObject(state, { isLoading: false, lookupCannedRptGradeListingGradeTypeList: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_MST_SCH_SORT_ORDER: return updateObject(state, { isLoading: false, lookupCannedRptMstSchSortOrderList: lookups });
            case commonConfig.LOOKUP_REPORT_FREQUENCY_TYPE: return updateObject(state, { isLoading: false, lookupReportFrequencyTypeList: lookups });
            case commonConfig.LOOKUP_ACTIVE_STATUS: return updateObject(state, { isLoading: false, lookupActiveStatusList: lookups });
            case commonConfig.LOOKUP_DISTRICT_EMAIL_TYPE: return updateObject(state, { isLoading: false, lookupDistrictEmailTypeList: lookups });
            case commonConfig.LOOKUP_RELIGION_TYPE: return updateObject(state, { isLoading: false, lookupReligionList: lookups });
            case commonConfig.LOOKUP_ABSENT_ATTENDANCE_UNEXCUSED_CATEGORY: return updateObject(state, { isLoading: false, lookupAbsentAttendanceUnexcusedCategoryList: lookups });
            case commonConfig.LOOKUP_ATTENDANCE_CATEGORY_TYPES: return updateObject(state, { isLoading: false, lookupAttendanceCategoryTypesList: lookups });
            case commonConfig.LOOKUP_DATEFORMAT: return updateObject(state, { isLoading: false, lookupDateformatList: lookups });
            case commonConfig.LOOKUP_DATEFORMAT_DELIMITER: return updateObject(state, { isLoading: false, lookupDateformatDelimiterList: lookups });
            case commonConfig.LOOKUP_ID_CARD_ORIENTATION_TYPE: return updateObject(state, { isLoading: false, lookupIdCardOrientationTypeList: lookups });
            case commonConfig.LOOKUP_ID_CARD_SIZE_VERTICAL: return updateObject(state, { isLoading: false, lookupIdCardSizeVerticalList: lookups });
            case commonConfig.LOOKUP_ID_CARD_SIZE_HORIZONTAL: return updateObject(state, { isLoading: false, lookupIdCardSizeHorizontalList: lookups });
            /*Student section*/
            case commonConfig.LOOKUP_STUDENT_ETHNICITY: return updateObject(state, { isLoading: false, lookupStudentEthnicityList: lookups });
            case commonConfig.LOOKUP_STUDENT_HISPANIC_LATINO_ETHNICITY: return updateObject(state, { isLoading: false, lookupStudentHispanicLatinoEthnicityList: lookups });
            case commonConfig.LOOKUP_STUDENT_HISPANIC_LATINO_RACE: return updateObject(state, { isLoading: false, lookupStudentHispanicLatinoRaceEthList: lookups });
            case commonConfig.LOOKUP_NM_RACE: return updateObject(state, { isLoading: false, lookupStudentHispanicLatinoRaceEthList: lookups });
            case commonConfig.LOOKUP_STUDENT_LANGUAGE: return updateObject(state, { isLoading: false, lookupStudentLanguageList: lookups });
            case commonConfig.LOOKUP_STUDENT_ROLLING_RETENTION: return updateObject(state, { isLoading: false, lookupStudentRollingRetentionList: lookups });
            case commonConfig.LOOKUP_STUDENT_IDENTIFICATION_SYSTEM: return updateObject(state, { isLoading: false, lookupStudentIdentificationSystem: lookups });
            case commonConfig.LOOKUP_STUDENT_IDENTIFICATION_SYSTEM_NM: return updateObject(state, { isLoading: false, lookupStudentIdentificationSystem: lookups });
            case commonConfig.LOOKUP_STUDENT_PUBLIC_SCHOOL_RESIDENTIAL_STATUS: return updateObject(state, { isLoading: false, lookupStudentPublicSchoolResidentialStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_OTHER_BACKWARD_COMMUNITY: return updateObject(state, { isLoading: false, lookupStudentOtherBackwardCommunity: lookups });
            case commonConfig.LOOKUP_STUDENT_MILITARY_BRANCH: return updateObject(state, { isLoading: false, lookupStudentMilitaryBranchList: lookups });
            case commonConfig.LOOKUP_STUDENT_MILITARY_CONNECTION: return updateObject(state, { isLoading: false, lookupStudentMilitaryConnectionList: lookups });
            case commonConfig.LOOKUP_MILITARY_CONNECTION_TEXAS: return updateObject(state, { isLoading: false, lookupMilitaryConnectionTexusList: lookups });
            case commonConfig.LOOKUP_STUDENT_VERIFIABLE_INFO: return updateObject(state, { isLoading: false, lookupStudentVerifiableInfoList: lookups });
            case commonConfig.LOOKUP_STUDENT_DOCUMENT_VERIFICATION_CERTIFICATE: return updateObject(state, { isLoading: false, lookupDocumentVerificationCertificateList: lookups });
            case commonConfig.LOOKUP_STUDENT_VISA_TYPE: return updateObject(state, { isLoading: false, lookupStudentVisaTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_PERSON_RELATIONSHIP: return updateObject(state, { isLoading: false, lookupPersonRelationshipList: lookups });
            case commonConfig.LOOKUP_STUDENT_DISABILITY_TYPE: return updateObject(state, { isLoading: false, lookupDisabilityType: lookups });
            case commonConfig.LOOKUP_STUDENT_ACCOMMODATION_NEED_TYPE: return updateObject(state, { isLoading: false, lookupAccommodationNeedType: lookups });
            case commonConfig.LOOKUP_STUDENT_DISABILITY_CONDITION_TYPE: return updateObject(state, { isLoading: false, lookupDisabilityConditionType: lookups });
            case commonConfig.LOOKUP_STUDENT_DISABILITY_CONDITION_NATURE: return updateObject(state, { isLoading: false, lookupDisabilityConditionNature: lookups });
            case commonConfig.LOOKUP_STUDENT_DETERMINED_BY: return updateObject(state, { isLoading: false, lookupDeterminedBy: lookups });
            case commonConfig.LOOKUP_STUDENT_IDEA_DISABILITY_TYPE: return updateObject(state, { isLoading: false, lookupIdeaDisabilityType: lookups });
            case commonConfig.LOOKUP_SCHOOL_LUNCH_PROGRAM_TYPE: return updateObject(state, { isLoading: false, lookupSchoolLunchProgramType: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY: return updateObject(state, { isLoading: false, lookupFoodServiceEligibility: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FREE: return updateObject(state, { isLoading: false, lookupFoodServiceEligibilityFree: lookups, lookupFoodServiceEligibilityTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FULL: return updateObject(state, { isLoading: false, lookupFoodServiceEligibilityFull: lookups, lookupFoodServiceEligibilityTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_REDUCED: return updateObject(state, { isLoading: false, lookupFoodServiceEligibilityReduced: lookups, lookupFoodServiceEligibilityTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_OTHER: return updateObject(state, { isLoading: false, lookupFoodServiceEligibilityOther: lookups, lookupFoodServiceEligibilityTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_REFERRAL_TYPE: return updateObject(state, { isLoading: false, lookupStudentReferralType: lookups });
            case commonConfig.LOOKUP_STUDENT_REFERRAL_OUTCOME: return updateObject(state, { isLoading: false, lookupStudentReferralOutcome: lookups });
            case commonConfig.LOOKUP_STUDENT_VISION_STATUS: return updateObject(state, { isLoading: false, lookupVisionStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_HEARING_STATUS: return updateObject(state, { isLoading: false, lookupHearingStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_DENTAL_STATUS: return updateObject(state, { isLoading: false, lookupDentalStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_HEALTH_INSURANCE_COVERAGE: return updateObject(state, { isLoading: false, lookupHealthInsuranceCoverageList: lookups });
            case commonConfig.LOOKUP_STUDENT_DENTAL_INSURANCE_COVERAGE: return updateObject(state, { isLoading: false, lookupDentalInsuranceCoverageList: lookups });
            case commonConfig.LOOKUP_STUDENT_MEDICAL_ALERT_TYPE: return updateObject(state, { isLoading: false, lookupMedicalAlertTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_HEALTH_SCREENING_STATUS: return updateObject(state, { isLoading: false, lookupStudentHealthScreeningStatusList: lookups });
            case commonConfig.LOOKUP_STUDENT_MULTIPLE_BIRTH: return updateObject(state, { isLoading: false, lookupStudentMultipleBirthList: lookups });
            case commonConfig.LOOKUP_NON_PROMOTION_REASON: return updateObject(state, { isLoading: false, lookupNonPromotionReasonList: lookups });
            case commonConfig.LOOKUP_PROMOTION_REASON: return updateObject(state, { isLoading: false, lookupPromotionReasonList: lookups });
            case commonConfig.LOOKUP_EXIT_WITHDRAWAL_TYPE: return updateObject(state, { isLoading: false, lookupExitWithdrawalTypeList: lookups });
            case commonConfig.LOOKUP_EXIT_WITHDRAWAL_STATUS: return updateObject(state, { isLoading: false, lookupExitWithdrawalStatusList: lookups });
            case commonConfig.LOOKUP_EXIT_ENROLLMENT_STATUS: return updateObject(state, { isLoading: false, lookupEnrollmentStatusList: lookups });
            case commonConfig.LOOKUP_EXIT_ROLLING_RETENTION: return updateObject(state, { isLoading: false, lookupRollingRetentionList: lookups });
            case commonConfig.LOOKUP_ENROLLMENT_ENTRY_TYPE: return updateObject(state, { isLoading: false, lookupEnrollmentEntryTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_ENROLLMENT_GIFTED_PROGRAM: return updateObject(state, { isLoading: false, lookupEnrollmentGiftedProgramList: lookups });
            case commonConfig.LOOKUP_STUDENT_INSURANCE_TYPE: return updateObject(state, { isLoading: false, lookupStudentInsuranceTypeList: lookups });
            case commonConfig.LOOKUP_IMMUNIZATION_ROUTE: return updateObject(state, { isLoading: false, lookupStudentImmunizationRouteList: lookups });
            case commonConfig.LOOKUP_IMMUNIZATION_STATUS: return updateObject(state, { isLoading: false, lookupStudentImmunizationStatusList: lookups });
            case commonConfig.LOOKUP_INJECTION_SITE: return updateObject(state, { isLoading: false, lookupStudentInjectionSiteList: lookups });
            case commonConfig.LOOKUP_MEDICAL_CONDITION: return updateObject(state, { isLoading: false, lookupStudentMedicalConditionList: lookups });
            case commonConfig.LOOKUP_BEHAVIOR_CONDITION: return updateObject(state, { isLoading: false, lookupStudentBehaviorConditionList: lookups });
            case commonConfig.LOOKUP_MEDICATION: return updateObject(state, { isLoading: false, lookupStudentMedicationList: lookups });
            case commonConfig.LOOKUP_MEDICATION_ROUTE: return updateObject(state, { isLoading: false, lookupStudentMedicationRouteList: lookups });
            case commonConfig.LOOKUP_MEDICATION_FREQUENCY: return updateObject(state, { isLoading: false, lookupStudentMedicationFrequencyList: lookups });
            case commonConfig.LOOKUP_MEDICATION_FORM: return updateObject(state, { isLoading: false, lookupStudentMedicationFormList: lookups });
            case commonConfig.LOOKUP_MEDICATION_DOSE_UNIT: return updateObject(state, { isLoading: false, lookupStudentMedicationDoseUnitList: lookups });
            case commonConfig.LOOKUP_MEDICATION_INVENTORY_REASON: return updateObject(state, { isLoading: false, lookupStudentMedicationReasonList: lookups });
            case commonConfig.LOOKUP_HEALTH_SCREENING_TYPE: return updateObject(state, { isLoading: false, lookupStudentHealthScreeningTypeList: lookups });
            case commonConfig.LOOKUP_DENTAL_SCREENING_STATUS: return updateObject(state, { isLoading: false, lookupStudentDentalScreeningStatusList: lookups });
            case commonConfig.LOOKUP_HEALTH_REFERRAL_RESULT: return updateObject(state, { isLoading: false, lookupStudentHealthReferralResultList: lookups });
            case commonConfig.LOOKUP_HEARING_TEST: return updateObject(state, { isLoading: false, lookupStudentHearingTestList: lookups });
            case commonConfig.LOOKUP_HEALTH_SCREENING_EYE_POWER: return updateObject(state, { isLoading: false, lookupStudentEyePowerList: lookups });
            case commonConfig.LOOKUP_SCREERNNG_TEST_RESULT: return updateObject(state, { isLoading: false, lookupStudentScreeningTestResultList: lookups });
            case commonConfig.LOOKUP_SPINAL_ACTION_TAKEN: return updateObject(state, { isLoading: false, lookupStudentSpinalActionTakenList: lookups });
            case commonConfig.LOOKUP_SPINAL_DIAGNOSIS: return updateObject(state, { isLoading: false, lookupStudentSpinalDiagnosisList: lookups });
            case commonConfig.LOOKUP_SPINAL_TREATMENT: return updateObject(state, { isLoading: false, lookupStudentSpinalTreatmentList: lookups });
            case commonConfig.LOOKUP_VISION_TEST: return updateObject(state, { isLoading: false, lookupStudentVisionTestList: lookups });
            case commonConfig.LOOKUP_SPECIAL_PROGRAMS: return updateObject(state, { isLoading: false, lookupStudentSpecialProgramsList: lookups });
            case commonConfig.LOOKUP_VISIT_REASON_MISSED: return updateObject(state, { isLoading: false, lookupStudentVisitReasonMissedList: lookups });
            case commonConfig.LOOKUP_VISIT_REASON: return updateObject(state, { isLoading: false, lookupStudentVisitReasonList: lookups });
            case commonConfig.LOOKUP_ACTION_TAKEN: return updateObject(state, { isLoading: false, lookupStudentActionTakenList: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_ADA_ELIGIBILITY_DISPLAY: return updateObject(state, { isLoading: false, lookupCannedRptAdaEligibilityDisplayList: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_ADA_ELIGIBILITY_SORTORDER: return updateObject(state, { isLoading: false, lookupCannedRptAdaEligibilitySortOrderList: lookups });
            case commonConfig.LOOKUP_REPORT_LABEL_LENGTH_UNIT: return updateObject(state, { isLoading: false, lookupLabelLengthUnitList: lookups });
            case commonConfig.LOOKUP_REPORT_LABEL_ROW: return updateObject(state, { isLoading: false, lookupLabelRowList: lookups });
            case commonConfig.LOOKUP_REPORT_LABEL_COLUMN: return updateObject(state, { isLoading: false, lookupLabelColumnList: lookups });
            case commonConfig.LOOKUP_REPORT_LABEL_PAGE_TYPE: return updateObject(state, { isLoading: false, lookupLabelPageTypeList: lookups });
            case commonConfig.LOOKUP_REPORT_LABEL_PAGE_ORIENTATION: return updateObject(state, { isLoading: false, lookupLabelPageOrientationList: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_MAILING_LABEL_PRINTOPT: return updateObject(state, { isLoading: false, lookupCannedRptMailingLabelPrintOptions: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_MAILING_LABEL_OPTIONS: return updateObject(state, { isLoading: false, lookupCannedRptMailingLabelOptions: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_SE_DROP_TYPE: return updateObject(state, { isLoading: false, lookupCannedRptStudentEnrollmentDropType: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_SE_DROP_SORTBY: return updateObject(state, { isLoading: false, lookupCannedRptStudentEnrollmentSort: lookups });
            case commonConfig.LOOKUP_CANNED_RPT_IDENTITY_SPS_SORTBY: return updateObject(state, { isLoading: false, lookupCannedRptStudentEnrollmentSortBy: lookups });
            case commonConfig.LOOKUP_BLOOD_GROUP_TYPE: return updateObject(state, { isLoading: false, lookupBloodGroupTypeList: lookups });
            case commonConfig.LOOKUP_STUDENT_CERTIFICATE_TYPE: return updateObject(state, { isLoading: false, lookupStudentCertificateTypeList: lookups });
            /*Student Special Program*/
            case commonConfig.LOOKUP_DISABILITY_EDUCATIONAL_ENVIRONMENT: return updateObject(state, { isLoading: false, lookupDisabilityEducationalEnvironmentList: lookups });
            case commonConfig.LOOKUP_DISABILITY_SUPPORT_SERIVICES: return updateObject(state, { isLoading: false, lookupDisabilitySupportSerivicesList: lookups });
            case commonConfig.LOOKUP_DISABILITY_PRESCHOOL_SERVICE_LOCATION: return updateObject(state, { isLoading: false, lookupDisabilityPreschoolServiceLocationList: lookups });
            case commonConfig.LOOKUP_DISABILITY_INTERPRETING_SERVICES: return updateObject(state, { isLoading: false, lookupDisabilityInterpretingServicesList: lookups });
            case commonConfig.LOOKUP_DISABILITY_TEXAS_DETERMINED_BY: return updateObject(state, { isLoading: false, lookupDisabilityTexasDeterminedByList: lookups });
            case commonConfig.LOOKUP_DISABILITY_TEXAS_PRIMARY_TYPE: return updateObject(state, { isLoading: false, lookupDisabilityTexasPrimaryTypeList: lookups });
            case commonConfig.LOOKUP_DISABILITY_TEXAS_EDUCATIONAL_ENVIRONMENT: return updateObject(state, { isLoading: false, lookupDisabilityTexasEducationalEnvironmentList: lookups });
            case commonConfig.LOOKUP_DISABILITY_TEXAS_SUPPORT_SERIVICES: return updateObject(state, { isLoading: false, lookupDisabilityTexasSupportSerivicesList: lookups });
            case commonConfig.LOOKUP_DISABILITY_TEXAS_PRESCHOOL_SERVICE_LOCATION: return updateObject(state, { isLoading: false, lookupDisabilityTexasPreschoolServiceLocationList: lookups });
            case commonConfig.LOOKUP_DISABILITY_CHILD_COUNT_FUNDING: return updateObject(state, { isLoading: false, lookupDisabilityChildCountFundingList: lookups });
            case commonConfig.LOOKUP_DISABILITY_REGIONAL_DAY_SCHOOL_PROGRAM_FOR_DEAF: return updateObject(state, { isLoading: false, lookupDisabilityRegionalDaySchoolProgramForDeafList: lookups });
            case commonConfig.LOOKUP_HOMELESS_STATUS: return updateObject(state, { isLoading: false, lookupHomelessStatusList: lookups });
            case commonConfig.LOOKUP_HOMELESS_UNACCOMPANIED_YOUTH_STATUS: return updateObject(state, { isLoading: false, lookupHomelessUnaccompaniedYouthStatusList: lookups });
            case commonConfig.LOOKUP_ASYLEE_REFUGEE: return updateObject(state, { isLoading: false, lookupAsyleeRefugeeList: lookups });
            case commonConfig.LOOKUP_FOSTER_CARE: return updateObject(state, { isLoading: false, lookupFosterCareList: lookups });
            case commonConfig.LOOKUP_DYSLEXIA_RISK: return updateObject(state, { isLoading: false, lookupDyslexiaRiskList: lookups });
            case commonConfig.LOOKUP_DYSLEXIA_SERVICES: return updateObject(state, { isLoading: false, lookupDyslexiaServicesList: lookups });
            case commonConfig.LOOKUP_LEP_INDICATOR: return updateObject(state, { isLoading: false, lookupLepIndicatorList: lookups });
            case commonConfig.LOOKUP_LEP_PARENTAL_PERMISSION: return updateObject(state, { isLoading: false, lookupLepParentalPermissionList: lookups });
            case commonConfig.LOOKUP_LEP_BILINGUAL_PROGRAM: return updateObject(state, { isLoading: false, lookupLepBilingualProgramList: lookups });
            case commonConfig.LOOKUP_LEP_ESL_PROGRAM: return updateObject(state, { isLoading: false, lookupLepEslProgramList: lookups });
            case commonConfig.LOOKUP_LEP_ALTERNATE_LANGUAGE_PROGRAM: return updateObject(state, { isLoading: false, lookupLepAlternateLanguageProgramList: lookups });
            case commonConfig.LOOKUP_LEP_BILINGUAL_ESL_FUNDING: return updateObject(state, { isLoading: false, lookupLepBilingualEslFundingList: lookups });
            case commonConfig.LOOKUP_TITLE_I_PART_A: return updateObject(state, { isLoading: false, lookupTitleIPartAList: lookups });
            case commonConfig.LOOKUP_SINGLE_PARENT_PREGNANT_TEEN: return updateObject(state, { isLoading: false, lookupSingleParentPregnantTeenList: lookups });
            case commonConfig.LOOKUP_CRISIS: return updateObject(state, { isLoading: false, lookupCrisisList: lookups });
            case commonConfig.LOOKUP_ECONOMIC_DISADVANTAGE: return updateObject(state, { isLoading: false, lookupEconomicDisadvantageList: lookups });
            case commonConfig.LOOKUP_YEARS_IN_US_SCHOOLS: return updateObject(state, { isLoading: false, lookupYearsInUsSchoolsList: lookups });
            case commonConfig.LOOKUP_LEP_DUAL_IMMERSION_LANGUAGE: return updateObject(state, { isLoading: false, lookupLepDualImmersionLanguageList: lookups });
            case commonConfig.LOOKUP_LEP_DUAL_LANGUAGE_IMMERSION_YEAR: return updateObject(state, { isLoading: false, lookupLepDualLanguageImmersionYearList: lookups });
            case commonConfig.LOOKUP_LEP_DUAL_LANGUAGE_COMPLETION_YEAR: return updateObject(state, { isLoading: false, lookupLepDualLanguageCompletionYearList: lookups });
            case commonConfig.LOOKUP_ECONOMIC_DISADVANTAGED: return updateObject(state, { isLoading: false, lookupEconomicDisadvantagedList: lookups });
            case commonConfig.LOOKUP_FOOD_SERVICE_PARTICIPATION: return updateObject(state, { isLoading: false, lookupFoodServiceParticipationList: lookups });
            case commonConfig.LOOKUP_ENGLISH_PROFICIENCY: return updateObject(state, { isLoading: false, lookupEnglishProficiencyList: lookups });
            case commonConfig.LOOKUP_EL_PROGRAM: return updateObject(state, { isLoading: false, lookupElProgramList: lookups });
            case commonConfig.LOOKUP_BEP_PROGRAM_LANGUAGE: return updateObject(state, { isLoading: false, lookupBepProgramLanguageList: lookups });
            case commonConfig.LOOKUP_SPECIAL_EDUCATION: return updateObject(state, { isLoading: false, lookupSpecialEducationList: lookups });
            case commonConfig.LOOKUP_PRIMARY_AREA_OF_EXCEPTIONALITY: return updateObject(state, { isLoading: false, lookupPrimaryAreaOfExceptionalityList: lookups });
            case commonConfig.LOOKUP_SPECIAL_EDUCATION_REFERRAL: return updateObject(state, { isLoading: false, lookupSpecialEducationReferralList: lookups });
            case commonConfig.LOOKUP_LEVEL_OF_INTEGRATION: return updateObject(state, { isLoading: false, lookupLevelOfIntegrationList: lookups });
            case commonConfig.LOOKUP_PRIMARY_SETTING: return updateObject(state, { isLoading: false, lookupPrimarySettingList: lookups });
            case commonConfig.LOOKUP_DISABILITY: return updateObject(state, { isLoading: false, lookupDisabilityList: lookups });
            case commonConfig.LOOKUP_EXPECTED_GRADUATION_OPTION: return updateObject(state, { isLoading: false, lookupExpectedGraduationOptionList: lookups });
            case commonConfig.LOOKUP_PLANNED_POST_GRAD_ACTIVITY: return updateObject(state, { isLoading: false, lookupPlannedPostGradActivityList: lookups });
            case commonConfig.LOOKUP_ANNUAL_REVIEW_DELAY_REASON: return updateObject(state, { isLoading: false, lookupAnnualReviewDelayReasonList: lookups });
            case commonConfig.LOOKUP_TRIENNIAL_REVIEW_DELAY_REASON: return updateObject(state, { isLoading: false, lookupTriennialReviewDelayReasonList: lookups });
            case commonConfig.LOOKUP_BEP_PROGRAM_INTENSITY: return updateObject(state, { isLoading: false, lookupBepProgramIntensityList: lookups });
            case commonConfig.LOOKUP_NMPREK_PARTICIPATION: return updateObject(state, { isLoading: false, lookupNmprekParticipationList: lookups });
            case commonConfig.LOOKUP_CEIS_PARTICIPATION: return updateObject(state, { isLoading: false, lookupCeisParticipationList: lookups });
            case commonConfig.LOOKUP_MIGRANT_PROGRAM: return updateObject(state, { isLoading: false, lookupMigrantProgramList: lookups });
            /*Staff section*/
            case commonConfig.LOOKUP_RELATIONSHIP: return updateObject(state, { isLoading: false, lookupPersonRelationshipList: lookups });
            case commonConfig.LOOKUP_HIGHEST_LEVEL_EDUCATION_COMPLETED: return updateObject(state, { isLoading: false, lookupHighestLevelEducationList: lookups });
            case commonConfig.LOOKUP_STAFF_CERTIFICATE_ACCREDITATION_STATUS: return updateObject(state, { isLoading: false, lookupCertificateAccreditationStatusList: lookups });
            case commonConfig.LOOKUP_STAFF_CREDENTIAL_TYPE: return updateObject(state, { isLoading: false, lookupCredentialTypeList: lookups });
            case commonConfig.LOOKUP_STAFF_VISA_TYPE: return updateObject(state, { isLoading: false, lookupStaffVisaTypeList: lookups });
            case commonConfig.LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_TYPE: return updateObject(state, { isLoading: false, lookupTeachingCredentialTypeList: lookups });
            case commonConfig.LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_BASIS: return updateObject(state, { isLoading: false, lookupTeachingCredentialBasisList: lookups });
            case commonConfig.LOOKUP_STAFF_CREDENTIAL_CERTIFICATE_SPONSOR_TYPE: return updateObject(state, { isLoading: false, lookupStaffCertSponsorTypeList: lookups });
            case commonConfig.LOOKUP_STAFF_LEAVE_TYPE: return updateObject(state, { isLoading: false, lookupLeaveTypeList: lookups });
            case commonConfig.DOC_CERTIFICATION_STATUS: return updateObject(state, { isLoading: false, lookupCertificationStatusList: lookups });
            case commonConfig.LOOKUP_STAFF_PERSONAL_DOCUMENT_VATIFICATION: return updateObject(state, { isLoading: false, lookupStaffPersonalDocumentVerificationList: lookups });
            case commonConfig.LOOKUP_SCHOOL_PUBLICATION_STATUS: return updateObject(state, { isLoading: false, lookupPublicationStatusList: lookups });
            case commonConfig.LOOKUP_LEAVE_STATUS_TYPE: return updateObject(state, { isLoading: false, lookupStaffLeaveStatusList: lookups });
            case commonConfig.LOOKUP_EWS_CATEGORY: return updateObject(state, { isLoading: false, lookupEwsCategory: lookups });
            case commonConfig.LOOKUP_STAFF_CREDENTIAL_USE: return updateObject(state, { isLoading: false, lookupCredentialUseList: lookups });
            case commonConfig.LOOKUP_STAFF_ADDRESS_TYPE: return updateObject(state, { isLoading: false, lookupStaffAddressTypeList: lookups });
            /*Course section*/
            case commonConfig.LOOKUP_COURSE_LEVEL_CHARACTERISTIC: return updateObject(state, { isLoading: false, lookupCourseLevelCharacteristic: lookups });
            case commonConfig.LOOKUP_COURSE_CREDIT_UNIT: return updateObject(state, { isLoading: false, lookupCourseCreditUnit: lookups });
            case commonConfig.LOOKUP_COURSE_WBL_OPPORTUNITY_TYPE: return updateObject(state, { isLoading: false, lookupCourseWblOpportunityType: lookups });
            case commonConfig.LOOKUP_ADITIONAL_CREDIT_TYPE: return updateObject(state, { isLoading: false, lookupAditionalCreditType: lookups });
            case commonConfig.LOOKUP_COURSE_GPA_APPLICABILITY: return updateObject(state, { isLoading: false, lookupCourseGpaApplicability: lookups });
            case commonConfig.LOOKUP_CURRICULUM_FRAMEWORK_TYPE: return updateObject(state, { isLoading: false, lookupCurriculumFrameworkType: lookups });
            case commonConfig.LOOKUP_COURSE_CREDIT_TYPE_EARNED: return updateObject(state, { isLoading: false, lookupCourseCreditTypeEarned: lookups });
            case commonConfig.LOOKUP_COURSE_CAREER_CLUSTER: return updateObject(state, { isLoading: false, lookupCourseCareerCluster: lookups });
            case commonConfig.LOOKUP_COURSE_BLND_LEARNING_MODEL_TYPE: return updateObject(state, { isLoading: false, lookupCourseBlndLearningModelType: lookups });
            case commonConfig.LOOKUP_COURSE_EXAM_REQUIREMENT: return updateObject(state, { isLoading: false, lookupCourseExamRequirement: lookups });
            case commonConfig.LOOKUP_ADVANCED_PLACEMENT_COURSE_CODE: return updateObject(state, { isLoading: false, lookupAdvancedPlacementCourseCode: lookups });
            case commonConfig.LOOKUP_STAFF_CERTIFICATION_TYPE: return updateObject(state, { isLoading: false, lookupCertificationTypeList: lookups });
            case commonConfig.LOOKUP_PERIOD_SCHEDULE_TYPE: return updateObject(state, { isLoading: false, lookupPeriodScheduleList: lookups });
            case commonConfig.LOOKUP_SINGLE_SEX_CLASS_STATUS: return updateObject(state, { isLoading: false, lookupSingleSexClassList: lookups });
            case commonConfig.LOOKUP_COURSE_SECTION_DELIVERY_MODE: return updateObject(state, { isLoading: false, lookupCourseDeliveryList: lookups });
            case commonConfig.LOOKUP_COURSE_SECTION_LEVEL_TYPE: return updateObject(state, { isLoading: false, lookupCourseSectionLevelTypeList: lookups });
            case commonConfig.LOOKUP_CERTIFICATE_VERIFICATION_METHOD: return updateObject(state, { isLoading: false, lookupCertificateVerificationMethodList: lookups });
            case commonConfig.LOOKUP_DOCUMENT_VERIFICATION_STATUS: return updateObject(state, { isLoading: false, lookupDocumentVerificationStatusList: lookups });
            case commonConfig.LOOKUP_COURSE_TYPE: return updateObject(state, { isLoading: false, lookupCourseType: lookups });
            case commonConfig.LOOKUP_COURSE_APPROVAL_STATUS: return updateObject(state, { isLoading: false, lookupCourseApprovalStatus: lookups });
            case commonConfig.LOOKUP_COURSE_SECTION_EXIT_TYPE: return updateObject(state, { isLoading: false, lookupCourseSectionExitType: lookups });
            case commonConfig.LOOKUP_ATTENDANCE_STATUS: return updateObject(state, { isLoading: false, lookupAttendanceStatusList: lookups });
            case commonConfig.LOOKUP_PRESENT_ATTENDANCE_CATEGORY: return updateObject(state, { isLoading: false, lookupPresentAttendanceCategoryList: lookups });
            case commonConfig.LOOKUP_ABSENT_ATTENDANCE_CATEGORY: return updateObject(state, { isLoading: false, lookupAbsentAttendanceCategoryList: lookups });
            case commonConfig.LOOKUP_COURSE_SECTION_ENTRY_TYPE: return updateObject(state, { isLoading: false, lookupEntryType: lookups });
            case commonConfig.LOOKUP_COURSE_SEQUENCE_TYPE: return updateObject(state, { isLoading: false, lookupCourseSequenceType: lookups });
            case commonConfig.LOOKUP_COURSE_STUDY_MATERIAL_TYPE: return updateObject(state, { isLoading: false, lookupCourseStudyMaterialType: lookups });
            case commonConfig.LOOKUP_COURSE_EXPLANATION: return updateObject(state, { isLoading: false, lookupCourseExplanationList: lookups });
            case commonConfig.LOOKUP_CLASS_ROOM_TYPE: return updateObject(state, { isLoading: false, lookupClassRoomType: lookups });
            //Discipline
            case commonConfig.LOOKUP_INCIDENT_TIME_TYPE: return updateObject(state, { isLoading: false, lookupIncidentTimeType: lookups });
            case commonConfig.LOOKUP_INCIDENT_BEHAVIOUR_TYPE: return updateObject(state, { isLoading: false, lookupIncidentBehaviourType: lookups });
            case commonConfig.LOOKUP_INCIDENT_BEHAVIOUR: return updateObject(state, { isLoading: false, lookupIncidentBehaviour: lookups });
            case commonConfig.LOOKUP_WEAPON_TYPE: return updateObject(state, { isLoading: false, lookupWeaponType: lookups });
            case commonConfig.LOOKUP_INJURY_TYPE: return updateObject(state, { isLoading: false, lookupInjuryType: lookups });
            case commonConfig.LOOKUP_INCIDENT_LOCATION: return updateObject(state, { isLoading: false, lookupIncidentLocation: lookups });
            case commonConfig.LOOKUP_FIREARM_TYPE: return updateObject(state, { isLoading: false, lookupFireArmType: lookups });
            case commonConfig.LOOKUP_MULTIPLE_OFFENCE_TYPE: return updateObject(state, { isLoading: false, lookupMultipleOffenceType: lookups });
            case commonConfig.LOOKUP_DISCIPLINE_REASON: return updateObject(state, { isLoading: false, lookupDisciplineReason: lookups });
            case commonConfig.LOOKUP_DISCIPLINARY_ACTION_TAKEN: return updateObject(state, { isLoading: false, lookupDisciplinaryActionTaken: lookups });
            case commonConfig.LOOKUP_DISCIPLINE_LENGTH_DIFFERENCE: return updateObject(state, { isLoading: false, lookupDisciplineLengthDifference: lookups });
            case commonConfig.LOOKUP_IDEA_INTERIM_REMOVAL: return updateObject(state, { isLoading: false, lookupIdeaInterimRemoval: lookups });
            case commonConfig.LOOKUP_IDEA_INTERIM_REMOVAL_REASON: return updateObject(state, { isLoading: false, lookupIdeaInterimRemovalReason: lookups });
            case commonConfig.LOOKUP_FIREARM_DISCIPLINE_METHOD: return updateObject(state, { isLoading: false, lookupFirearmDisciplineMethod: lookups });
            case commonConfig.LOOKUP_IDEA_FIREARM_DISCIPLINE_METHOD: return updateObject(state, { isLoading: false, lookupIdeaFirearmDisciplineMethod: lookups });
            case commonConfig.LOOKUP_DM_FOR_DIABETIC_CHILDREN: return updateObject(state, { isLoading: false, lookupDmForDiabeticChildren: lookups });
            case commonConfig.LOOKUP_INCIDENT_PERSON_ROLE: return updateObject(state, { isLoading: false, lookupIncidentPersonRole: lookups });
            case commonConfig.LOOKUP_INCIDENT_PERSON_TYPE: return updateObject(state, { isLoading: false, lookupIncidentPersonType: lookups });
            //Assessment
            case commonConfig.LOOKUP_ASSESSMENT_ASSET_TYPE: return updateObject(state, { isLoading: false, lookupAssessmentAssetTypeList: lookups });
            case commonConfig.LOOKUP_PUBLICATION_STATUS: return updateObject(state, { isLoading: false, lookupPublicationStatusList: lookups });
            case commonConfig.LOOKUP_SUBMISSION_TYPE: return updateObject(state, { isLoading: false, lookupSubmitTypeList: lookups });
            case commonConfig.LOOKUP_ACCOMMODATION_TYPE: return updateObject(state, { isLoading: false, lookupAccommodationTypeList: lookups });
            case commonConfig.LOOKUP_ACCOMMODATION_CATEGORY: return updateObject(state, { isLoading: false, lookupAccommodationCategoryList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_PURPOSE: return updateObject(state, { isLoading: false, lookupAssessmentPurposeList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_TYPE: return updateObject(state, { isLoading: false, lookupAssessmentTypeList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_TYPE_WITH_DISABILITY: return updateObject(state, { isLoading: false, lookupAssessmentTypeWithDisabilityList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_REGISTRATION_COMPLETION_STATUS: return updateObject(state, { isLoading: false, lookupAssessmentRegistrationCompletionStatusList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_PARTICIPATION: return updateObject(state, { isLoading: false, lookupAssessmentParticipationList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_REASON_NOT_TESTED: return updateObject(state, { isLoading: false, lookupAssessmentReasonNotTestedList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_REASON_NOT_COMPLETED: return updateObject(state, { isLoading: false, lookupAssessmentReasonNotCompletedList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_REPORTING_METHOD: return updateObject(state, { isLoading: false, lookupAssessmentReportingMethodList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_SESSION_TYPE: return updateObject(state, { isLoading: false, lookupAssessmentSessionType: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_FORM_SEC_IDENTIFICATION_SYSTEM: return updateObject(state, { isLoading: false, lookupAssessmentFormSecIdentificationSystemList: lookups });
            case commonConfig.LOOKUP_SCORE_METRIC_TYPE: return updateObject(state, { isLoading: false, lookupScoreMetricTypeList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_OUTCOME: return updateObject(state, { isLoading: false, lookupAssessmentOutcomeList: lookups });
            case commonConfig.LOOKUP_ASSESSMENT_RESULT_SCORE_TYPE: return updateObject(state, { isLoading: false, lookupResultScoreTypeList: lookups });
            case commonConfig.LOOKUP_ANNOUNCEMENT_TYPE: return updateObject(state, { isLoading: false, lookupAnnouncementType: lookups });
            case commonConfig.LOOKUP_PERSON_OCCUPATION_TYPE: return updateObject(state, { isLoading: false, lookupPersonOccupationTypeList: lookups });
            case commonConfig.LOOKUP_ASSIGNMENT_AGGREGATION: return updateObject(state, { isLoading: false, lookupAssignmentAggregationList: lookups });
            case commonConfig.LOOKUP_ASSIGNMENT_GRADING_TYPE: return updateObject(state, { isLoading: false, lookupAssignmentGradingTypeList: lookups });
            case commonConfig.LOOKUP_ADA_ELIGIBILITY_TYPE: return updateObject(state, { isLoading: false, lookupAdaEligibilityTypeList: lookups });
            case commonConfig.LOOKUP_ASSIGNMENT_BANK_STATUS: return updateObject(state, { isLoading: false, lookupAssignmentBankPublicationStatusList: lookups });
            case commonConfig.LOOKUP_NAME_SUFFIX_TYPE: return updateObject(state, { isLoading: false, lookupNameSuffixList: lookups });
            case commonConfig.LOOKUP_ASSIGNMENT_QUESTION_TYPE: return updateObject(state, { isLoading: false, lookupAssignmentQuestionTypeList: lookups });
            case commonConfig.LOOKUP_CANNED_REPORT_SCHEDULE_SORT_ORDER: return updateObject(state, { isLoading: false, lookupCannedSortOrderList: lookups });
            case commonConfig.LOOKUP_CANNED_REPORT_SCHEDULE_OPTIONS: return updateObject(state, { isLoading: false, lookupCannedOptionList: lookups });
            //district
            case commonConfig.LOOKUP_DCP_STAFF_IDENTIFICATIONSYSTEM: return updateObject(state, { isLoading: false, lookupStaffDCPIdentificationSystemList: lookups });
            case commonConfig.LOOKUP_DCP_PROGRAM_TYPE: return updateObject(state, { isLoading: false, lookupDCPProgramType: lookups });
            case commonConfig.LOOKUP_DISTRICT_SERVICEID_COURSE_UNITS: return updateObject(state, { isLoading: false, lookupDistrictServiceIdCourseUnits: lookups });
            case commonConfig.LOOKUP_DISTRICT_SERVICEID_CAREER_TECH_COURSE: return updateObject(state, { isLoading: false, lookupDistrictServiceIdCareerCourse: lookups });
            case commonConfig.LOOKUP_STUDENT_NAME_DISPLAY_PATTERN: return updateObject(state, { isLoading: false, lookupStudentNameDisplayPattern: lookups });
            case commonConfig.LOOKUP_STAFF_NAME_DISPLAY_PATTERN: return updateObject(state, { isLoading: false, lookupStaffNameDisplayPattern: lookups });
            case commonConfig.LOOKUP_STUDENT_NAME_DISPLAY_SORT: return updateObject(state, { isLoading: false, lookupStudentNameDisplaySort: lookups });
            case commonConfig.LOOKUP_TYPEOF_DISTRICT: return updateObject(state, { isLoading: false, lookupDistrictTypeList: lookups });
            case commonConfig.LOOKUP_DISTRICT_OPARATION_STATUS: return updateObject(state, { isLoading: false, lookupDistrictOparationStatusList: lookups });
            case commonConfig.LOOKUP_DISTRICT_ADDRESS_TYPE: return updateObject(state, { isLoading: false, lookupDistrictAddressType: lookups });
            case commonConfig.LOOKUP_GRADUATION_PLAN_TYPE: return updateObject(state, { isLoading: false, lookupGraduationPlanTypeList: lookups });
            case commonConfig.LOOKUP_GRADUATION_REQUIREMENT_TYPE: return updateObject(state, { isLoading: false, lookupRequirementType: lookups });
            case commonConfig.LOOKUP_GRADUATION_REQUIREMENT_YEAR: return updateObject(state, { isLoading: false, lookupYear: lookups });
            case commonConfig.LOOKUP_COMMENT_TYPE: return updateObject(state, { isLoading: false, lookUpCommentTypeList: lookups });
            case commonConfig.LOOKUP_ST_MASS_ASSIGN_CATEGORY: return updateObject(state, { isLoading: false, lookUpStMassAssignCategoryList: lookups });
            case commonConfig.LOOKUP_COURSE_EXPLANATION_TYPE: return updateObject(state, { isLoading: false, lookupCourseExplanationTypeList: lookups });
            case commonConfig.LOOKUP_SR_REPORTING_TERM: return updateObject(state, { isLoading: false, lookupSrReportingTermList: lookups });
            case commonConfig.LOOKUP_EMPLOYMENT_STATUS: return updateObject(state, { isLoading: false, lookupEmploymentStatusList: lookups });
            case commonConfig.LOOKUP_SEPERATION_REASON: return updateObject(state, { isLoading: false, lookupSeparationReasonList: lookups });
            case commonConfig.LOOKUP_DISPLAY_TYPE: return updateObject(state, { isLoading: false, lookUpSystemDistrictValues: lookups });
            case commonConfig.LOOKUP_REPORT_BASED_ON: return updateObject(state, { isLoading: false, lookupReportBasedOnList: lookups });
            case commonConfig.LOOKUP_REPORT_TYPE: return updateObject(state, { isLoading: false, lookupReportTypeList: lookups });
            case commonConfig.LOOKUP_NM_RELATION: return updateObject(state, { isLoading: false, lookupRelationList: lookups });
            case commonConfig.LOOKUP_ADMIN_CLASS_ROASTER_RPT_SORTORDER: return updateObject(state, { isLoading: false, lookupAdminClassRoasterSortOrderList: lookups });
            case commonConfig.LOOKUP_DIPLOMA_TYPE: return updateObject(state, { isLoading: false, lookupDiplomaTypeList: lookups });
            default: return updateObject(state, { isLoading: false })
        }
    }
}
const handleSetNotify = (state, type, message) => {
    return updateObject(state, {
        notifyType: type,
        notifyMessage: message
    });
}
const reducerShared = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'COMMON_LIST': return handleSuccess(state, action, camelCase(`${action.title}_list`));
        case 'COMMON_SAVE': return handleSuccess(state, action, camelCase(`save_${action.title}`));
        case 'COMMON_GET': return handleSuccess(state, action, camelCase(`get_${action.title}`));
        case 'COMMON_DELETE': return handleSuccess(state, action, camelCase(`${action.title}_delete`));
        case actionTypesShared.GET_LOOKUP_SUCESS: return storeLookupToSession(state, action);
        case actionTypesShared.SHOW_NOTIFY: return handleSetNotify(state, action.notifyType, action.notifyMessage);
        case actionTypesShared.REMOVE_NOTIFY: return handleSetNotify(state, 'success', '');
        case actionTypesShared.HANDLE_COMMON_FAIL: return handleFail(state);
        case commonConfig.COMMON_STORE_RESET: return handleSuccess(state, action, action.title);
        default: return state;
    }
}
export default reducerShared;
