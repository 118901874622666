import React from 'react';
import sessionStore from './sessionStoreComponent';
const momentTimeZone = require('moment-timezone');
const moment = require('moment');
class convertTimeZone extends React.Component {
    returnTime(timeString, format = 'hh:mm A') {
        if (typeof timeString === 'string' && !!timeString) {
            if (new sessionStore().returnSchoolTimeZone() !== 'N/A') {
                return momentTimeZone(timeString).tz(new sessionStore().returnSchoolTimeZone()).format(format)
            } else {
                return momentTimeZone(timeString).local().format(format)
            }
        } else
            return timeString;
    }
    returnSchoolTimeZoneDate(timeString) {
        if (typeof timeString === 'string' && !!timeString) {
            if (!!new sessionStore().returnSchoolTimeZone()) {
                return momentTimeZone(timeString).tz(new sessionStore().returnSchoolTimeZone()).format('YYYY-MM-DD')
            } else {
                return momentTimeZone(timeString).local().format('YYYY-MM-DD')
            }
        } else
            return timeString;
    }
    returnSelectedTimeZoneDate(timeString) {
        if (typeof timeString === 'string' && !!timeString) {
            if (!!new sessionStore().returnSchoolTimeZone()) {
                return moment.tz(timeString, new sessionStore().returnSchoolTimeZone()).utc().format('YYYY-MM-DD')
            } else {
                return momentTimeZone(timeString).local().format('YYYY-MM-DD')
            }
        } else
            return timeString;
    }
    returnUtcDate(date, time) {
        if (!!date && !!time && time !== '00:00:00') {
            let newDateFormat = moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
            let utcdate = new Date(newDateFormat + ' ' + time).toISOString();
            return utcdate.slice(0, 10);
        } else {
            return date;
        }
    }
    returnDate(date) {
        if (!!date) {
            let stillUtc = moment.utc(date).toDate();
            let local = moment(stillUtc).local().format('YYYY-MM-DD');
            return local;
        } else {
            return date;
        }
    }
    returnUtcTime(timeString) {
        if (typeof timeString === 'string' && !!timeString) {
            timeString = String(timeString).toLowerCase().replace(/\s/g, '');
            var has_am = timeString.indexOf('am') >= 0;
            var has_pm = timeString.indexOf('pm') >= 0;
            timeString = timeString.replace('am', '').replace('pm', '');
            if (timeString.indexOf(':') < 0) timeString = timeString + ':00';
            if (has_am) timeString += ' am';
            if (has_pm) timeString += ' pm';
            var doubleDigits = function (n) {
                return (parseInt(n) < 10) ? "0" + n : String(n);
            };
            var today = new Date();
            var dd = String(today.getDate());
            var mm = String(today.getMonth() + 1); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + '/' + dd + '/' + yyyy;
            var d = new Date(today + " " + timeString);
            var newTime = doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes())
            if (new sessionStore().returnSchoolTimeZone() !== 'N/A') {
                var a = moment.tz(moment(new Date().toISOString().slice(0, 10)).format('YYYY-MM-DD') + " " + newTime, new sessionStore().returnSchoolTimeZone());
                var x1 = a.format();
                var x3 = a.utc().format('hh:mm A');
                return x3;
            } else {
                return momentTimeZone(d).utc().format('hh:mm A')
            }
        } else
            return timeString;
    }
}
export default convertTimeZone;
