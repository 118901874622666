import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';

const initialState = {
    reportList: [],
    reportEntityList: [],
    reportColumnDataList: [],
    getReportCount: [],
    reportCannedList: [],
    getReportCanned: {},
    saveReportCanned: false,
    reportSchedulerList: [],
    saveReportScheduler: [],
    getReportScheduler: {}
}
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
}
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const reducerStudent = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'REPORT_LIST': return handleSuccess(state, action, camelCase(`report_${action.title}_list`));
        case 'REPORT_SAVE': return handleSuccess(state, action, camelCase(`save_report_${action.title}`));
        case 'REPORT_GET': return handleSuccess(state, action, camelCase(`get_report_${action.title}`));
        case commonConfig.REPORT_STORE_RESET: return handleSuccess(state, action, action.title);
        case 'HANDLE_REPORT_FAIL': return handleFail(state);
        default: return state;
    }
}
export default reducerStudent;