import { SharedApiConfig } from "../../../shared/sharedApiConfig";
import commonConfig from "../../../common/commonConfig";
const axios = require("axios");

export const handleStudentApiFail = error => {
    return {
        type: 'HANDLE_STUDENT_FAIL',
        error: error
    };
};
export const handleStudentApiSuccess = (fields, title, action) => {
    return {
        type: "STUDENT_" + action.toUpperCase(),
        responseData: fields,
        title: title
    };
};
export const callStudentListApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        })
            .then(function (response) {
                dispatch(handleStudentApiSuccess(response.data, title, "list"));
            })
            .catch(error => {
                dispatch(handleStudentApiFail(error));
            });
    };
};
export const callStudentSaveApi = (input, title, isUpload = false) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig[isUpload ? "API_TO_UPLOAD_AND_SAVE" : "API_TO_CAll"],
            headers: isUpload ? {} : SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: isUpload ? input : JSON.stringify(input)
        })
            .then(function (response) {
                dispatch(handleStudentApiSuccess(response.data, title, "save"));
            })
            .catch(error => {
                dispatch(handleStudentApiFail(error));
            });
    };
};
export const callStudentGetByApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        })
            .then(function (response) {
                dispatch(handleStudentApiSuccess(response.data, title, "get"));
            })
            .catch(error => {
                dispatch(handleStudentApiFail(error));
            });
    };
};
export const callStudentDeleteApi = (input, title) => {
    return dispatch => {
        axios({
            method: commonConfig.METHOD_TYPE_POST,
            url: SharedApiConfig.API_TO_CAll,
            headers: SharedApiConfig.JSON_CONTENT_TYPE_HEADER,
            data: JSON.stringify(input)
        })
            .then(function (response) {
                dispatch(handleStudentApiSuccess(response.data, title, "delete"));
            })
            .catch(error => {
                dispatch(handleStudentApiFail(error));
            });
    };
};
export const studentStoreStateReset = input => {
    return {
        type: commonConfig.STUDENT_STORE_RESET,
        responseData: input,
        title: input.name
    };
};
export const handleStudentFail = error => {
    return {
        type: 'HANDLE_STUDENT_FAIL',
        error: error
    };
};