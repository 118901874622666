import $ from 'jquery'
import React from 'react';
import { Link } from 'react-router-dom';
import { sessionStore, MarkersCommonSelect, actionsDistrict, commonDistrictConfig } from '../../school/commonSchoolindex';
import { connect } from 'react-redux';
import i18n from '../../../i18n'
import ExecuteArgs from '../../shared/model/executeArgs';
import commonConfig from '../commonConfig';
import { filter, remove, pick } from 'lodash';
import * as actions from '../../school/store/actions/index';
import * as actionsCommon from '../../shared/store/actions/index';
import * as actionsStaff from '../../staff/store/actions/index';
import * as actionsStudent from '../../student/store/actions/index';
import Validation from '../component/validationComponent';
import MarkersFileUpload from './markersFileUpload';
import commonScheduleConfig from '../../schedule/commonScheduleConfig';
import commonStaffConfig from '../../staff/commonStaffConfig';
import commonStudentConfig from '../../student/commonStudentConfig';
import MarkersDatepicker from '../component/markersDatepicker';
import base64ImageConfig from '../base64ImageConfig';
import markersCommonPhoneComponent from '../../common/component/markersCommonPhoneComponent';
import CommonFuncs from '../../common/component/markersCommonFunctionComponent';
let moment = require('moment');
const commonFuncObj = new CommonFuncs();
class MarkersCommonPerson extends React.Component {
    initializeFields = {
        firstName: '',
        middleName: '',
        lastName: '',
        nameSuffix: '',
        lookupNameSuffix: null,
        homePhoneNumber: '',
        workPhoneNumber: '',
        mobileNumber: '',
        primaryEmail: '',
        alternateEmail: '',
        gender: '',
        profilePicId: null,
        lookupSalutation: 0,
        listAddress: [],
        selectedFileList: null,
        lookupOccupationType: '',
        occupationTitle: '',
        designation: '',
        monthlyIncome: null,
        workEmail: '',
        listCredential: [],
        dateOfBirth: '',
        mobilePhoneNumberJsonList: [],
        workPhoneNumberJsonList: [],
        homePhoneNumberJsonList: []
    }
    initializeAddressFields = {
        lookupAddressType: null,
        addressLine: '',
        city: "",
        state: "",
        county: "",
        countryCode: "",
        zip: "",
        streetNoName: "",
        aprtSuiteRoomNo: "",
        buildingSiteNo: "",
        geoLocation: null,
        isDefault: true,
        isActive: true
    }
    initializeCredentialFields = {
        credentialName: '',
        lookupCredentialType: null,
        issuranceDate: '',
        expirationDate: '',
        issuingState: '',
        certificateOrLicenseNo: '',
        issuingAuthority: ''
    }
    constructor(props) {
        super(props);
        this.state = {
            phNumberSameFlag: false,
            getPersonPostedDataField: {
                schoolId: new sessionStore().returnSchoolID(),
                type: this.props.hasOwnProperty('type') ? this.props.type : '',
                isIncludeDesignator: this.props.hasOwnProperty('isIncludeDesignator') ? this.props.isIncludeDesignator : false
            },
            getPersonByDistrict: {
                districtId: new sessionStore().returnDistrictID()
            },
            fields: { ...this.initializeFields },
            newAddress: { ...this.initializeAddressFields },
            newCredential: { ...this.initializeCredentialFields },
            genderTypeList: [
                { "id": "M", "title": "Male" },
                { "id": "F", "title": "Female" },
                { "id": "O", "title": "Other" }
            ],
            phoneTypeList: [
                { "id": 901, "title": "Work Phone" },
                { "id": 902, "title": "Mobile Phone" },
                { "id": 903, "title": "Home Phone" }
            ],
            lookupSalutationList: [],
            isError: 0,
            delfields: { id: null, deleteionReason: '' },
            getPersonInfoFlag: false,
            requiredFieldsAddress: [
                { fieldName: 'lookupAddressType', fieldType: 'default' },
                // { fieldName: 'addressLine', fieldType: 'regExp', regExp: commonConfig.ADDRESS_SCHOOL },
                { fieldName: 'city', fieldType: 'regExp', regExp: commonConfig.ADDRESS_COUNTY_REGEX },
                { fieldName: 'state', fieldType: 'regExp', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY },
                { fieldName: 'county', fieldType: 'regExpNotRequired', regExp: commonConfig.ADDRESS_COUNTY_REGEX },
                { fieldName: 'countryCode', fieldType: 'default' },
                { fieldName: 'zip', fieldType: 'regExp', regExp: commonConfig.POSTAL_CODE_ZIP },
                { fieldName: 'streetNoName', fieldType: 'regExp', regExp: commonConfig.ADDRESS_SCHOOL }
            ],
            requiredFieldsCredential: [
                { fieldName: 'credentialName', fieldType: 'default' },
                { fieldName: 'issuingState', fieldType: 'regExpNotRequired', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY },
                { fieldName: 'certificateOrLicenseNo', fieldType: 'regExpNotRequired', regExp: commonConfig.LICENCE_NO },
                { fieldName: 'issuingAuthority', fieldType: 'regExpNotRequired', regExp: commonConfig.CERTIFICATE_NUMBER },
                { fieldName: 'issuranceDate', fieldType: 'default' },
            ],
            requiredFieldsDeleteReason: [
                { fieldName: 'deletionReason', fieldType: 'default' },
            ],
            requiredFieldsPhone: [
                { fieldName: 'tempPhoneNumber', fieldType: 'regExp', regExp: commonConfig.PHONE_NUMBER_FORMAT_V2, minLength: commonConfig.PHONE_NUMBER_MIN_LENGTH_V2 },
                { fieldName: 'tempPhoneType', fieldType: 'default' },
            ],
            requiredFields: [
                { fieldName: 'firstName', fieldType: 'regExp', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO },
                { fieldName: 'lastName', fieldType: 'regExp', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO },
                { fieldName: 'middleName', fieldType: 'regExpNotRequired', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO },
                { fieldName: 'primaryEmail', fieldType: 'regExp', regExp: commonConfig.EMAIL },
                { fieldName: 'alternateEmail', fieldType: 'regExpNotRequired', regExp: commonConfig.EMAIL },
                { fieldName: 'selectedFileList', fieldType: 'onlyFileType', fileType: commonConfig.FILE_TYPE_IMAGE, fileMaxSize: new sessionStore().returnMaxFileSize(), fileMinSize: new sessionStore().returnMinFileSize() },
                { fieldName: 'occupationTitle', fieldType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION },
                { fieldName: 'designation', fieldType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION },
                { fieldName: 'monthlyIncome', fieldType: 'regExpNotRequired', regExp: commonConfig.MONTHLY_INCOME },
                { fieldName: 'dateOfBirth', fieldType: 'default' },
                { fieldName: 'workEmail', fieldType: 'regExpNotRequired', regExp: commonConfig.EMAIL }
            ],
            requiredFields1: [
                { fieldName: 'firstName', fieldType: 'regExp', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO },
                { fieldName: 'lastName', fieldType: 'regExp', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO },
                { fieldName: 'middleName', fieldType: 'regExpNotRequired', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO },
                { fieldName: 'alternateEmail', fieldType: 'regExpNotRequired', regExp: commonConfig.EMAIL },
                { fieldName: 'selectedFileList', fieldType: 'onlyFileType', fileType: commonConfig.FILE_TYPE_IMAGE, fileMaxSize: new sessionStore().returnMaxFileSize(), fileMinSize: new sessionStore().returnMinFileSize() },
                { fieldName: 'occupationTitle', fieldType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION },
                { fieldName: 'designation', fieldType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION },
                { fieldName: 'monthlyIncome', fieldType: 'regExpNotRequired', regExp: commonConfig.MONTHLY_INCOME },
                { fieldName: 'workEmail', fieldType: 'regExpNotRequired', regExp: commonConfig.EMAIL }
            ],
            integerFieldCredential: ['lookupCredentialType'],
            integerField: ["lookupOccupationType"],
            integerFieldAddress: ['lookupAddressType'],
            tempPhoneType: '',
            tempPhoneNumber: '',
            tempGenderType: '',
            tempPhoneArray: [],
            form_data: {},
            showAddNotify: false,
            savePersonData: { id: null, fullName: '' },
            isSearch: true,
            allPersonSearch: [],
            allPerson: [],
            personId: null,
            personName: '',
            isErrorCredential: 0,
            isErrorPhone: 0,
            isAddressError: 0,
            editAddress: false,
            addressIndex: null,
            editCredential: false,
            isOccupationFieldsNotEmpty: false,
            credentialIndex: null,
            countryStateLanValueFlag: false,
            addPhone: true,
            key: null,
            deleteId: null,
            tempPhoneType: null,
            deletionReason: '',
            update: false,
            editPhoneType: '',
            editPhoneNumber: '',
            smsCheckFlag: false,
            isErrorDeleteReason: 0,
            can_recieve_sms: false,
            recieveMsgFlag: false,
            homePhoneNumberJsonList: [],
            workPhoneNumberJsonList: [],
            mobilePhoneNumberJsonList: [],
            editCanreceiveMessage: false,
            editPhone: false,
            homePhoneNumberTypeId: 903,
            workPhoneNumberTypeId: 901,
            mobileNumberTypeId: 902,
        }
        this.props.onCommonResetProps({ name: 'stateByCountryList', data: [] });
        this.props.onCommonResetProps({ name: 'countyByStateList', data: [] });
        this.props.onResetDistrictProps({ name: 'getDistrictCountryStateLanguage', data: [] });
        this.props.onGetLookup(commonConfig.LOOKUP_STAFF_CREDENTIAL_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_SALUTATION_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_PERSON_OCCUPATION_TYPE);
        this.props.onGetLookup(commonConfig.LOOKUP_NAME_SUFFIX_TYPE);
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_RESOURCE_GET_COUNTRY,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetCountryList(executedArgument);
        executedArgument = new ExecuteArgs(commonStudentConfig.COMPONENT_STUDENT,
            commonStudentConfig.COMPONENT_STUDENT_CONTACT_ADDRESS_TYPE,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetStudentContactAddressTypeList(executedArgument);
    }
    getPersonInfo = () => {
        this.setState({ showAddNotify: false }, () => {
            if (this.state.getPersonPostedDataField.type === 'teacher') {
                let executedArgument = new ExecuteArgs(commonStaffConfig.COMPONENT_STAFF,
                    commonScheduleConfig.COMPONENT_SCHEDULE_TEACHER_GETALL + new sessionStore().returnSchoolID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetPersonSucess(executedArgument);
            }
            if (this.state.getPersonPostedDataField.type === 'approvedTeacher') {
                let executedArgument = new ExecuteArgs(commonStaffConfig.COMPONENT_STAFF,
                    commonStaffConfig.COMPONENT_STAFF_GET_STAFF_HOLIDAY_LIST + new sessionStore().returnSchoolID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetHolidayStaffSucess(executedArgument);
            }
            else {
                if (this.props.fromPage == "studentGuardian") {
                    if (this.props.isWithAddress && this.props.isWithAddress === true) {
                        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                            commonConfig.COMPONENT_PERSON_GET_PERSON_BY_DISTRICT,
                            commonConfig.METHOD_TYPE_POST, { districtId: new sessionStore().returnDistrictID(), isWithAddress: true });
                        this.props.onGetAllPersonSucess(executedArgument);
                    }
                    else {
                        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                            commonConfig.COMPONENT_PERSON_GET_PERSON_BY_DISTRICT,
                            commonConfig.METHOD_TYPE_POST, this.state.getPersonByDistrict);
                        this.props.onGetAllPersonSucess(executedArgument);
                    }
                }
                else {
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                        commonConfig.COMPONENT_PERSON_GETALL,
                        commonConfig.METHOD_TYPE_POST, this.state.getPersonPostedDataField);
                    this.props.onGetPersonSucess(executedArgument);
                }
            }
        });
    }
    concatPhoneValue = (fieldName, prevValue, currentValue) => {
        if (prevValue && prevValue.length > 0) {
            return { [fieldName]: prevValue + "," + currentValue }
        }
        return { [fieldName]: currentValue }
    }
    clearPhone = () => {
        this.setState({
            tempPhoneType: null,
            tempPhoneNumber: "",
            can_recieve_sms: false
        });
    }
    handleChangePhoneType = event => {
        (event.target.value == 902) ? this.setState({ smsCheckFlag: true }) : this.setState({ smsCheckFlag: false })
        let tempPhoneType = !!event.target.value ? parseInt(event.target.value, 10) : null;
        this.setState({ tempPhoneType });
    }
    phoneFieldSet = () => {
        if (this.state.tempPhoneArray.length === 0) {
            this.setState({ fields: { ...this.state.fields, homePhoneNumber: "", workPhoneNumber: "", mobileNumber: "" } }, () => {
                this.savePhone(this.state.tempPhoneArray, this.state.fields.mobileNumber, this.state.fields.homePhoneNumber, this.state.fields.workPhoneNumber, this.state.tempPhoneNumber, this.state.tempPhoneType, this.state.homePhoneNumberJsonList, this.state.workPhoneNumberJsonList, this.state.mobilePhoneNumberJsonList)
            })
        } else {
            this.setState({ fields: { ...this.state.fields, homePhoneNumber: "", workPhoneNumber: "", mobileNumber: "" } });
            this.state.tempPhoneArray.map((phoneDetails, key) => {
                this.setState((prevState) => {
                    let { homePhoneNumber, workPhoneNumber, mobileNumber } = prevState.fields;
                    let phoneType = this.search(phoneDetails.phoneType, this.state.phoneTypeList);;
                    let phonefields = {}
                    if (phoneType === commonConfig.IS_HOME_PHONE) {
                        phonefields = this.concatPhoneValue("homePhoneNumber", homePhoneNumber, phoneDetails.phoneNumber)
                    }
                    if (phoneType === commonConfig.IS_WORK_PHONE) {
                        phonefields = this.concatPhoneValue("workPhoneNumber", workPhoneNumber, phoneDetails.phoneNumber)
                    }
                    if (phoneType === commonConfig.IS_MOBILE_PHONE) {
                        phonefields = this.concatPhoneValue("mobileNumber", mobileNumber, phoneDetails.phoneNumber)
                    }
                    return { fields: { ...prevState.fields, ...phonefields } };
                }, () => {
                    this.clearPhone()
                })
            });
        }
    }
    componentDidMount() {
        this.getPersonInfo();
        this.getCountryStateLanguage();
        let executedArgument = new ExecuteArgs(commonStaffConfig.COMPONENT_STAFF,
            commonStaffConfig.COMPONENT_STAFF_PERSONAL_TYPE_CREDENTIAL + new sessionStore().returnSchoolID(),
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetPersonalCredentials(executedArgument);
        window.$("#addschooldesignatorsPersonModal").on('hidden.bs.modal', () => {
            let setStateObj = { clearFile: true, tempPhoneNumber: '', tempPhoneType: '', isOccupationFieldsNotEmpty: false, isErrorPhone: 0 };
            if (!window.$('#addschooldesignatorsPersonModal').hasClass('show')) {
                setStateObj = { ...setStateObj, fields: { ...this.initializeFields } }
            }
            let removeOccupationRequiredFields = this.state.requiredFields.filter(requiredField => requiredField.fieldName === "occupationTitle" ? requiredField.fieldType !== "regExp" : requiredField.fieldName !== 'lookupOccupationType');
            setStateObj = { ...setStateObj, requiredFields: removeOccupationRequiredFields }
            this.setState(setStateObj, () => {
                document.getElementById("addschooldesig").reset();
                if (window.$('.modal:visible').length > 0) { window.$('body').addClass('modal-open'); }
            });
        });
        window.$(document).on('click', '[data-toggle="toggle-visibility"]', function (e) {
            e.preventDefault();
            var toggleElement = window.$(this).attr('data-targetlayer');
            var selfDestruct = window.$(this).attr('data-destructlayer');
            if (selfDestruct !== 'undefined') {
                window.$(selfDestruct).hide();
            }
            window.$(toggleElement).show();
        });
        window.$('#deleteModalPhone').modal({
            backdrop: 'static',
            keyboard: false,
            show: false
        });
    }
    componentWillUnmount() {
        this.props.onResetDistrictProps({ name: 'getDistrictCountryStateLanguage', data: [] });
    }

    closeDeletePhoneModal = (e) => {
        e.preventDefault();
        this.setState({ deletionReason: '', deleteId: null, isErrorDeleteReason: 0 }, () => {
            window.$("#deleteModalPhone").modal('hide');
            window.$("#deleteModalPhone").trigger("reset");
        });
    }
    getCountryStateLanguage = () => {
        this.props.onResetDistrictProps({ name: 'getDistrictCountryStateLanguage', data: [] });
        this.setState({ countryStateLanValueFlag: true }, () => {
            let executedArgument = new ExecuteArgs(commonDistrictConfig.COMPONENT_CONTROL_PANEL,
                commonDistrictConfig.COMPONENT_GET_DEFAULT_COUNTRY_STATE_LANGUAGE + "?districtId=" + new sessionStore().returnDistrictID() + '&Key=' + commonConfig.DEFAULT_COUNTRY_STATE_LANGUAGE,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetCountryStateLanguage(executedArgument);
        })
    }
    setFileField = (selectedFileList) => {
        let fields = Object.assign({}, this.state.fields);
        fields["selectedFileList"] = selectedFileList;
        this.setState({ fields: fields });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.lookupSalutationList) {
            this.setState({ lookupSalutationList: nextProps.lookupSalutationList })
        }
        if (nextProps.saveSchoolPerson && JSON.stringify(nextProps.saveSchoolPerson.Id) && this.state.getPersonInfoFlag) {
            this.setState({ getPersonInfoFlag: false, fields: this.initializeFields, isSearch: false, personId: JSON.stringify(nextProps.saveSchoolPerson.Id), personName: JSON.stringify(nextProps.saveSchoolPerson.FullName).replace(/\"/g, "") }, () => {
                this.props.setPersonData(this.state.personId, this.state.personName, 'NEW');
                window.$("#addschooldesig").trigger("reset");
                window.$('#addschooldesignatorsPersonModal').modal('hide');
                this.getPersonInfo();
            });
        }
        if (!!this.state.fields.listAddress && this.state.fields.listAddress.length < 1) {
            let newAddress = Object.assign({}, this.state.newAddress);
            newAddress['isDefault'] = true;
            this.setState({ newAddress: newAddress });
        }
        if (nextProps.schoolPersonList && nextProps.schoolPersonList.length > 0) {
            this.setState({ allPerson: nextProps.schoolPersonList });
        }
        if (nextProps.schoolAllPersonList && nextProps.schoolAllPersonList.length > 0) {
            this.setState({ allPerson: nextProps.schoolAllPersonList });
        }
        if (nextProps.staffHolidayStaffList && nextProps.staffHolidayStaffList.length > 0) {
            this.setState({ allPerson: nextProps.staffHolidayStaffList });
        }
        if (nextProps.staffHolidayStaffList && nextProps.staffHolidayStaffList.length == 0 && this.props.type === 'approvedTeacher') {
            this.setState({ allPerson: [] })
        }
        if (nextProps.personInfo && !!nextProps.personInfo.id && !!nextProps.personInfo.name) {
            this.setState({ personId: nextProps.personInfo.id, personName: nextProps.personInfo.name });
        }
        let requiredFields1 = [...this.state.requiredFields1];
        if (this.props.fromPage === "studentGuardian") {
            commonFuncObj.removeElement(requiredFields1, 'primaryEmail');
            if (!!this.props.isNotifiable) {
                requiredFields1.push({ fieldName: 'primaryEmail', fieldType: 'regExp', regExp: commonConfig.EMAIL })
            } else {
                requiredFields1.push({ fieldName: 'primaryEmail', fieldType: 'regExpNotRequired', regExp: commonConfig.EMAIL })
            }
            this.setState({ requiredFields1: requiredFields1 });
        }
        if (nextProps.personInfo && !nextProps.personInfo.id && !nextProps.personInfo.name && nextProps.isReset) {
            this.setState({ personId: null, personName: '' }, () => {
                window.$('.autocomplete-dropdown').hide();
            });
        }
        if (nextProps.getDistrictCountryStateLanguage && Object.keys(nextProps.getDistrictCountryStateLanguage).length > 0 && this.state.countryStateLanValueFlag) {
            let newAddress = Object.assign({}, this.state.newAddress);
            let jsonData = {};
            jsonData = JSON.parse(nextProps.getDistrictCountryStateLanguage.jsonData);
            let countryName;
            let countryNameSuffix;
            countryName = jsonData.DefaultCountry;
            newAddress['state'] = jsonData.DefaultState
            this.props.countryList.map((data) => {
                if (countryName == data.title) {
                    countryNameSuffix = data.id
                    newAddress['countryCode'] = data.id
                }
            })
            this.setState({ newAddress, countryStateLanValueFlag: false, }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY + countryNameSuffix,
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetStateByCountry(executedArgument);
                executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_RESOURCE_GET_COUNTY_BY_STATE + this.state.newAddress.state,
                    commonConfig.METHOD_TYPE_GET)
                this.props.onGetCountyByState(executedArgument);
            })
        }
        if (nextProps.getContactDetails && Object.keys(nextProps.getContactDetails).length > 0 && (!this.state.update || this.props.isView)) {
            this.setState({ fields: { ...nextProps.getContactDetails }, tempPhoneArray: [], update: true }, () => {
                this.setPhoneArray("homePhoneNumber");
                this.setPhoneArray("workPhoneNumber");
                this.setPhoneArray("mobileNumber");
            })
        }

        if (!!this.props.recieveMsgFlag) {
            this.setState({ recieveMsgFlag: true })
        }
    }
    //This method checks weather any data is populated in any of the occupation sub-component fields and push requiredFieldsOfOccupation if data is populated
    updateOccupationRequiredFields = (eventName) => {
        let occupationFieldArray = ['workEmail', 'occupationTitle', 'lookupOccupationType', 'designation', 'monthlyIncome'];
        if (occupationFieldArray.indexOf(eventName) !== -1) {
            let occupationFields = pick(this.state.fields, occupationFieldArray);
            let isOccupationFieldsNotEmpty = Object.values(occupationFields).some(occupationField => (occupationField !== null && occupationField !== ''));
            let requiredFieldsOfOccupation = [{ fieldName: 'lookupOccupationType', fieldType: 'default' }]
            let removeOccupationRequiredFields = (requiredFields) => requiredFields.filter(requiredField => requiredField.fieldName === "occupationTitle" ? requiredField.fieldType !== "regExp" : requiredField.fieldName !== 'lookupOccupationType');
            let requiredFields = !!isOccupationFieldsNotEmpty ? [...this.state.requiredFields, ...requiredFieldsOfOccupation] : removeOccupationRequiredFields(this.state.requiredFields);
            this.setState({ requiredFields, isOccupationFieldsNotEmpty }, () => {
                //isOccupationFieldsNotEmpty returns false if there is no data in the occupation sub-component if found returns true
            });
        }
    }
    handleChange = event => {
        let fields = Object.assign({}, this.state.fields);
        let integerField = this.state.integerField;
        let eventName = event.target.name;
        if (integerField.indexOf(event.target.name) === -1) {
            if (event.target.name === 'primaryEmail' || event.target.name === 'alternateEmail') {
                fields[event.target.name] = event.target.value.trim();
            } else {
                fields[event.target.name] = event.target.value.trimStart();

            }
        } else {
            if (isNaN(parseFloat(event.target.value))) {
                fields[event.target.name] = null;
            } else {
                fields[event.target.name] = parseInt(event.target.value, 10);
            }
        }
        this.setState({ fields: fields }, () => {
            this.updateOccupationRequiredFields(eventName);
        });
    }
    setPhoneArray = (phoneFieldType) => {
        if (this.state.fields[phoneFieldType] && this.state.fields[phoneFieldType] !== "" && this.state.fields[phoneFieldType] !== undefined) {
            let tempPhoneList = this.state.fields[phoneFieldType].split(',');
            let workjson = this.state.fields.workPhoneNumberJsonList;
            let mobilejson = this.state.fields.mobilePhoneNumberJsonList;
            let homejson = this.state.fields.homePhoneNumberJsonList;
            this.setState((prevState) => {
                let tempArray = prevState.tempPhoneArray;
                tempPhoneList.map((value, index) => {
                    let x = [];
                    if (this.state[phoneFieldType + "TypeId"] == 901) {
                        x = filter(workjson, { 'phoneNumber': value });
                    }
                    if (this.state[phoneFieldType + "TypeId"] == 902) {
                        x = filter(mobilejson, { 'phoneNumber': value });
                    }
                    if (this.state[phoneFieldType + "TypeId"] == 903) {
                        x = filter(homejson, { 'phoneNumber': value });
                    }

                    return tempArray.push({ phoneType: this.state[phoneFieldType + "TypeId"], phoneNumber: value, can_recieve_sms: (x.length != 0 ? x[0].can_recieve_sms : false) })


                })
                return { tempPhoneArray: tempArray }
            }, () => {
                if (!this.props.isView) {
                    const { mobileNumber, homePhoneNumber, workPhoneNumber } = this.state.fields;
                }
            })
        }
    }

    setPhoneJsonArray = (event) => {
        let workPhoneCount = 0;
        let homePhoneCount = 0;
        let mobilePhoneCount = 0;
        let tempPhoneArray = this.state.tempPhoneArray;
        let homePhoneNumberJsonList = this.state.homePhoneNumberJsonList;
        let mobilePhoneNumberJsonList = this.state.mobilePhoneNumberJsonList;
        let workPhoneNumberJsonList = this.state.workPhoneNumberJsonList;
        if (!this.state.mobilePhoneNumberJsonList) {
            mobilePhoneNumberJsonList = [];
        }
        if (!this.state.workPhoneNumberJsonList) {
            workPhoneNumberJsonList = [];
        }
        if (!this.state.homePhoneNumberJsonList) {
            homePhoneNumberJsonList = [];
        }
        let allphoneLength = (homePhoneNumberJsonList.length + workPhoneNumberJsonList.length + mobilePhoneNumberJsonList.length)
        if (this.state.tempPhoneArray.length >= allphoneLength) {
            for (let i = 0; i < this.state.tempPhoneArray.length; i++) {
                if (tempPhoneArray[i].phoneType == 901) {
                    workPhoneCount++;
                }
                if (tempPhoneArray[i].phoneType == 902) {
                    mobilePhoneCount++;
                }
                if (tempPhoneArray[i].phoneType == 903) {
                    homePhoneCount++;
                }
            }
            let phoneArray = [...this.state.tempPhoneArray]
            if (workPhoneCount >= workPhoneNumberJsonList.length) {

                let workPhoneNumberJsonList = filter(phoneArray, { 'phoneType': 901 });
                let workJson = workPhoneNumberJsonList.map((data, key) => {
                    let tempData = { ...data }
                    if (tempData.phoneType) {
                        delete tempData.phoneType;

                    }
                    tempData['isd_code'] = "";
                    return tempData;
                })
                this.setState({ workPhoneNumberJsonList: workJson })
            }
            if (mobilePhoneCount >= mobilePhoneNumberJsonList.length) {
                let mobilePhoneNumberJsonList = filter(phoneArray, { 'phoneType': 902 });
                mobilePhoneNumberJsonList.map((data, key) => {
                    data['isd_code'] = "";

                })
                this.setState({ mobilePhoneNumberJsonList })
            }
            if (homePhoneCount >= this.state.homePhoneNumberJsonList.length) {
                let homePhoneNumberJsonList = filter(phoneArray, { 'phoneType': 903 });
                homePhoneNumberJsonList.map((data, key) => {
                    data['isd_code'] = "";

                })
            }
        }

    }

    defaultvalue = event => {

        let obj = {
            isd_code: "",
            mobile_number: "",
            can_recieve_sms: false
        }
        if (!!this.state.homePhoneNumberJsonList == "" || this.state.homePhoneNumberJsonList == undefined || this.state.homePhoneNumberJsonList == null) {

            this.setState({ homePhoneNumberJsonList: [...this.state.homePhoneNumberJsonList, obj] })
        }
        if (!!this.state.mobilePhoneNumberJsonList == "" || this.state.mobilePhoneNumberJsonList == undefined || this.state.mobilePhoneNumberJsonList == null) {
            let mobilePhoneNumberJsonList = [];
            mobilePhoneNumberJsonList.push(obj);
            this.setState({ mobilePhoneNumberJsonList })
        }
        if (!!this.state.workPhoneNumberJsonList == "" || this.state.workPhoneNumberJsonList == undefined || this.state.workPhoneNumberJsonList == null) {
            this.setState({ workPhoneNumberJsonList: [...this.state.workPhoneNumberJsonList, obj] })
        }
    }

    deletePhone = (phoneIndex) => {
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state, this.state.requiredFieldsDeleteReason);
        let workPhoneNumberJsonList = this.state.workPhoneNumberJsonList;
        let mobilePhoneNumberJsonList = this.state.mobilePhoneNumberJsonList;
        let homePhoneNumberJsonList = this.state.homePhoneNumberJsonList;
        if (submitStatus === 0) {
            this.setState({ isErrorDeleteReason: 1 });
        }
        else {
            let clonePhoneArray = [...this.state.tempPhoneArray];
            clonePhoneArray.splice(phoneIndex, 1);
            let setStateObj = {};
            if (this.state.tempPhoneArray[phoneIndex].phoneType == 901) {
                let tempPhone = this.state.tempPhoneArray[phoneIndex].mobile_number;

                let removed = remove(workPhoneNumberJsonList, function (n) { return n.mobile_number == tempPhone });
                setStateObj = { ...setStateObj, workPhoneNumberJsonList }

            }
            if (this.state.tempPhoneArray[phoneIndex].phoneType == 902) {
                let tempPhone = this.state.tempPhoneArray[phoneIndex].mobile_number;
                let removed = remove(mobilePhoneNumberJsonList, function (n) { return n.mobile_number == tempPhone });
                setStateObj = { ...setStateObj, mobilePhoneNumberJsonList }
            }
            if (this.state.tempPhoneArray[phoneIndex].phoneType == 903) {
                let tempPhone = this.state.tempPhoneArray[phoneIndex].mobile_number;
                let removed = remove(homePhoneNumberJsonList, function (n) { return n.mobile_number == tempPhone });
                setStateObj = { ...setStateObj, homePhoneNumberJsonList }
            }
            setStateObj = { ...setStateObj, tempPhoneArray: clonePhoneArray, deletionReason: '', deleteId: null, isErrorDeleteReason: 0 }
            this.setState(setStateObj, () => {
                window.$("#deleteModalPhone").modal('hide');
                this.phoneFieldSet();
            })
        }
    }
    handleSMSChange = event => {
        this.setState({ can_recieve_sms: event.target.checked })
    }
    handleChangePhone = event => {
        let tempPhoneNumber = event.target.value.replace(/^\s+/g, '');
        this.setState({ tempPhoneNumber: tempPhoneNumber });
    }
    savePhone = (e) => {
        //e.preventDefault();
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation({ 'tempPhoneNumber': this.state.tempPhoneNumber, 'tempPhoneType': this.state.tempPhoneType }, this.state.requiredFieldsPhone);
        const { tempPhoneType, tempPhoneNumber, tempPhoneArray, can_recieve_sms } = this.state.requiredFieldsPhone;
        if (submitStatus === 0 || this.isDuplicatePhoneNumber(tempPhoneType, tempPhoneNumber, tempPhoneArray)) {
            this.setState({ isErrorPhone: 1 })
        } else {
            if (this.state.editPhone) {
                let obj = {
                    phoneType: this.state.tempPhoneType,
                    mobile_number: this.state.tempPhoneNumber,
                    can_recieve_sms: this.state.can_recieve_sms
                }
                if (obj.phoneType == 901 || obj.phoneType == 903) {
                    obj.can_recieve_sms = false;
                }
                let workPhoneNumberJsonList = this.state.workPhoneNumberJsonList;
                let key = this.state.key;
                let tempPhoneArray = this.state.tempPhoneArray;
                tempPhoneArray.splice(key, 1, obj);
                this.setState({ tempPhoneArray: tempPhoneArray, isErrorPhone: 0, editPhone: false, key: null, editPhoneNumber: '', editPhoneType: '', editCanReceiveMessage: false }, () => {
                    this.phoneFieldSet()
                    this.setPhoneJsonArray();
                    this.setEditPhoneArray(tempPhoneArray);
                    this.clearPhone()
                })
            } else {
                this.setState((prevState) => {
                    let obj = {
                        phoneType: prevState.tempPhoneType,
                        mobile_number: prevState.tempPhoneNumber,
                        can_recieve_sms: prevState.can_recieve_sms
                    }
                    if (this.state.tempPhoneType == 901) {
                        let obj1 = {
                            isd_code: "",
                            mobile_number: this.state.tempPhoneNumber,
                            can_recieve_sms: false
                        }

                        if (prevState.workPhoneNumberJsonList.length == 1 && !!prevState.workPhoneNumberJsonList[0].mobile_number == "") {
                            prevState.workPhoneNumberJsonList.shift();
                        }
                        let tempPhoneArraysOne = [...prevState.workPhoneNumberJsonList];
                        let workPhoneNumberJsonList = [...tempPhoneArraysOne, obj1];
                        this.setState({ workPhoneNumberJsonList })
                    }
                    if (this.state.tempPhoneType == 902) {
                        let obj2 = {
                            isd_code: "",
                            mobile_number: this.state.tempPhoneNumber,
                            can_recieve_sms: this.state.can_recieve_sms
                        }
                        if (prevState.mobilePhoneNumberJsonList.length == 1 && !!prevState.mobilePhoneNumberJsonList[0].mobile_number == "") {
                            prevState.mobilePhoneNumberJsonList.shift();
                        }
                        let tempPhoneArraysTwo = [...prevState.mobilePhoneNumberJsonList];
                        let mobilePhoneNumberJsonList = [...tempPhoneArraysTwo, obj2];
                        this.setState({ mobilePhoneNumberJsonList })
                    }
                    if (this.state.tempPhoneType == 903) {
                        let obj3 = {
                            isd_code: "",
                            mobile_number: this.state.tempPhoneNumber,
                            can_recieve_sms: false
                        }
                        if (prevState.homePhoneNumberJsonList.length == 1 && !!prevState.homePhoneNumberJsonList[0].mobile_number == "") {
                            prevState.homePhoneNumberJsonList.shift();
                        }
                        let tempPhoneArraysThree = [...prevState.homePhoneNumberJsonList];
                        let homePhoneNumberJsonList = [...tempPhoneArraysThree, obj3];

                        this.setState({ homePhoneNumberJsonList })
                    }

                    let cloneOfTempPhoneArray = [...prevState.tempPhoneArray];

                    let newPhoneListArray = [
                        ...cloneOfTempPhoneArray, obj
                    ];

                    return { tempPhoneArray: newPhoneListArray, isErrorPhone: 0 }
                }, () => {
                    this.phoneFieldSet()
                    if (!!this.props.isUpdateFlag) {

                        this.setUpdatePhoneArray(this.state.tempPhoneArray)
                    }
                    this.clearPhone()
                })
            }
        }
    }

    isDuplicatePhoneNumber = (tempPhoneType, tempPhoneNumber, tempPhoneArray) => {
        const { editPhoneNumber, editPhoneType } = this.state;
        return tempPhoneArray && tempPhoneArray.length > 0 && (editPhoneNumber !== tempPhoneNumber || editPhoneType !== tempPhoneType) ? tempPhoneArray && tempPhoneArray.length > 0 && tempPhoneArray.some(phoneObj => phoneObj.phoneType === tempPhoneType && phoneObj.mobile_number === tempPhoneNumber) : false;
    }
    editPhone = (data, key) => {
        var tempPhoneType = data['phoneType'];
        var tempPhoneNumber = data['mobile_number'];
        var can_recieve_sms = data['can_recieve_sms']
        var smsCheckFlag = this.state.smsCheckFlag;
        (tempPhoneType == 902) ? smsCheckFlag = true : smsCheckFlag = false

        this.setState({ editPhone: true, can_recieve_sms, tempPhoneType, tempPhoneNumber, key, editPhoneNumber: tempPhoneNumber, editPhoneType: tempPhoneType, editCanReceiveMessage: can_recieve_sms, smsCheckFlag }, () => {
        })
    }
    setEditPhoneArray = (tempPhoneArray) => {
        let workPhoneNumberJsonList = filter(tempPhoneArray, { 'phoneType': 901 });
        let mobilePhoneNumberJsonList = filter(tempPhoneArray, { 'phoneType': 902 });
        let homePhoneNumberJsonList = filter(tempPhoneArray, { 'phoneType': 903 });
        let workJson = workPhoneNumberJsonList.map((data, key) => {
            let tempData = { ...data }
            if (tempData.phoneType) {
                delete tempData.phoneType;

            }

            tempData['isd_code'] = "";
            return tempData;
        })
        let mobileJson = mobilePhoneNumberJsonList.map((data, key) => {
            let tempData = { ...data }
            if (tempData.phoneType) {
                delete tempData.phoneType;

            }
            tempData['isd_code'] = "";
            return tempData;
        })
        let homeJson = homePhoneNumberJsonList.map((data, key) => {
            let tempData = { ...data }
            if (tempData.phoneType) {
                delete tempData.phoneType;

            }

            tempData['isd_code'] = "";
            return tempData;
        })
        let tempPhone = tempPhoneArray.map((phoneData, i) => {
            let tempphnData = { ...phoneData }
            if (tempphnData.phoneType == 901) {
                return { isd_code: "", phoneType: 901, can_recieve_sms: false, mobile_number: phoneData.mobile_number };
            }
            if (phoneData.phoneType == 903) {
                return { isd_code: "", phoneType: 903, can_recieve_sms: false, mobile_number: phoneData.mobile_number };
            }
            if (phoneData.phoneType == 902) {
                return { isd_code: "", phoneType: 902, can_recieve_sms: phoneData.can_recieve_sms, mobile_number: phoneData.mobile_number };
            }


        })
        this.setState({ homePhoneNumberJsonList: homeJson, workPhoneNumberJsonList: workJson, mobilePhoneNumberJsonList: mobileJson, tempPhoneArray: tempPhone })
    }
    setUpdatePhoneArray = (tempPhoneArray) => {

        let workPhoneNumberJsonList = filter(tempPhoneArray, { 'phoneType': 901 });
        let mobilePhoneNumberJsonList = filter(tempPhoneArray, { 'phoneType': 902 });
        let homePhoneNumberJsonList = filter(tempPhoneArray, { 'phoneType': 903 });
        let workJson = workPhoneNumberJsonList.map((data, key) => {
            let tempData = { ...data }
            if (tempData.phoneType) {
                delete tempData.phoneType;

            }

            tempData['isd_code'] = "";
            return tempData;
        })
        let mobileJson = mobilePhoneNumberJsonList.map((data, key) => {
            let tempData = { ...data }
            if (tempData.phoneType) {
                delete tempData.phoneType;

            }
            tempData['isd_code'] = "";
            return tempData;
        })
        let homeJson = homePhoneNumberJsonList.map((data, key) => {
            let tempData = { ...data }
            if (tempData.phoneType) {
                delete tempData.phoneType;

            }

            tempData['isd_code'] = "";
            return tempData;
        })
        this.setState({ homePhoneNumberJsonList: homeJson, workPhoneNumberJsonList: workJson, mobilePhoneNumberJsonList: mobileJson })

    }
    handleDelete = event => {
        this.setState({ deletionReason: event.target.value.replace(/^\s+/g, '') });
    }
    deleteTempPhone = (deleteId) => {
        this.setState({ deleteId }, () => {
            window.$("#deleteModalPhone").modal('show');
        })
    }
    search = (nameKey, myArray) => {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].id === parseInt(nameKey, 10)) {
                return myArray[i].title;
            }
        }
    }

    phoneComponent = () => {
        return <React.Fragment>
            <h6 className="text-primary"><b>{i18n.t('phone')}</b></h6>
            <div className="panel-default">
                <div className="panel-body p-0">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th>
                                    <div className="text-capitalize">
                                        <label className="mb-0">{i18n.t('phoneType')} <span>*</span></label>
                                    </div>
                                </th>
                                <th style={{ width: 165 }}>
                                    <div className="text-capitalize">
                                        <label className="mb-0">{i18n.t('phoneNumber')} <span>*</span></label>
                                    </div>
                                </th>
                                <th>
                                    <div className="text-capitalize">
                                        <label className="mb-0">{i18n.t('receiveSms')}</label>
                                    </div>
                                </th>
                                <th>
                                    <div className="text-capitalize">
                                        <label className="mb-0">{i18n.t('action')}</label>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tempPhoneArray && this.state.tempPhoneArray.length > 0 ?
                                this.state.tempPhoneArray.map((phoneDetails, key) => {
                                    return (
                                        this.state.key !== key ?
                                            <tr id={key}>
                                                <td>{this.search(phoneDetails.phoneType, this.state.phoneTypeList)}</td>
                                                <td>{new markersCommonPhoneComponent().getPhoneFormat(phoneDetails.mobile_number)}</td>
                                                <td>{!phoneDetails.can_recieve_sms ? "" : <i className="icon-checkmark2 text-success"></i>}</td>
                                                <td>
                                                    <div className="action-icons">
                                                        <Link to="#" onClick={() => this.editPhone(phoneDetails, key)}><i className="icon-pen6"></i></Link>
                                                        <Link to="#" onClick={() => this.deleteTempPhone(key)}><i className="icon-bin2"></i></Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            : '')
                                }) : null}
                            <tr>
                                <td>
                                    <div className="form-group mb-0">
                                        <label className="d-block d-lg-none f-s-12 text-muted m-b-0">{i18n.t('phoneType')} <span>*</span></label>
                                        <select className="custom-select" name="tempPhoneNumber" value={this.state.tempPhoneType || ''} onChange={this.handleChangePhoneType}>
                                            <option value="">{i18n.t('select')}</option>
                                            {this.state.phoneTypeList.map((lookup, key) => {
                                                return <option key={key} value={lookup.id}>{lookup.title}</option>
                                            })}
                                        </select>
                                        <Validation data={{
                                            field: i18n.t("PhoneType"),
                                            errorClass: 'tempPhoneType_error',
                                            value: this.state.tempPhoneType, isError: !!this.props.isErrorPhone && this.state.tempPhoneArray.length === 0 ? this.props.isErrorPhone : this.state.isErrorPhone, validationType: 'selectfield'
                                        }} returnValidation={this.functionValidation} />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group mb-0">
                                        <label className="d-block d-lg-none f-s-12 text-muted m-b-0">{i18n.t('phoneNumber')} <span>*</span></label>
                                        <input type="text" maxLength={14} className="form-control" name="tempPhoneNumber" value={this.state.tempPhoneNumber !== '' ? new markersCommonPhoneComponent().getPhoneFormat(this.state.tempPhoneNumber) : ''} placeholder={i18n.t('enterPhoneNo')} onChange={this.handleChangePhone} />
                                        <Validation data={{
                                            field: i18n.t("phoneNumber"),
                                            errorClass: 'tempPhoneNumber_error',
                                            value: this.state.tempPhoneNumber, isError: !!this.props.isErrorPhone && this.state.tempPhoneArray.length === 0 ? this.props.isErrorPhone : this.state.isErrorPhone, validationType: 'phone', regExp: commonConfig.PHONE_NUMBER_FORMAT_V2, minLength: commonConfig.PHONE_NUMBER_MIN_LENGTH_V2
                                        }} returnValidation={this.functionValidation} />
                                        {(!!this.state.tempPhoneNumber && this.isDuplicatePhoneNumber(this.state.tempPhoneType, this.state.tempPhoneNumber, this.state.tempPhoneArray)) &&
                                            <div className="alert alert-danger m-t-5" role="alert"><i className="icon-warning f-s-13"></i> Duplicate {i18n.t('phoneNumber')} and {i18n.t('phoneType')}</div>
                                        }
                                    </div>
                                </td>

                                <td>
                                    <div className="form-group mb-0">
                                        {!!this.state.smsCheckFlag &&
                                            <label className="fluidCheckbox mt-2 mb-0">
                                                <input name="can_recieve_sms" id="" type="checkbox" checked={this.state.can_recieve_sms} onChange={this.handleSMSChange} />
                                                <span><i></i></span>
                                            </label>
                                        }
                                    </div>
                                </td>

                                <td>
                                    <div className="">
                                        <Link to="#" className="btn btn-outline-success m-r-10 btn-block" onClick={(e) => this.savePhone(e)}><i className="icon-checkmark2"></i> Add</Link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    }
    phoneComponentView = () => {
        return <React.Fragment>
            <table className="table mb-0">
                <thead>
                    <tr>
                        <th>{i18n.t("phoneType")}</th>
                        <th>{i18n.t("phoneNumber")}</th>
                        <th>{i18n.t('receiveSms')}</th>
                    </tr>
                </thead>
                <tbody>
                    {(this.state.tempPhoneArray && this.state.tempPhoneArray.length > 0) ?
                        this.state.tempPhoneArray.map((phoneDetails, key) => {
                            return <tr key={key}>
                                <td>{this.search(phoneDetails.phoneType, this.state.phoneTypeList)}</td>
                                <td>{new markersCommonPhoneComponent().getPhoneFormat(phoneDetails.mobile_number)}</td>
                                <td>{!phoneDetails.can_recieve_sms ? "" : <i className="icon-checkmark2 text-success"></i>}</td>

                            </tr>
                        }) :
                        <tr>
                            <td colSpan="2" className="text-danger text-center f-s-13">
                                <i className="icon-info2 m-r-5"></i> {i18n.t('noPhoneNumbersFound')}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </React.Fragment>
    }
    handleChangeGenderType = event => {
        let fields = Object.assign({}, this.state.fields);
        fields['gender'] = event.target.value;
        this.setState({ tempGenderType: event.target.value, fields: fields });
    }
    handleClearDate = (fieldName) => {
        this.setState({
            fields: { ...this.state.fields, [fieldName]: '' }
        })
    }
    handleChangeAddress = event => {
        let newAddress = this.state.newAddress;
        if (event.target.name === 'countryCode') {
            newAddress['state'] = '';
            newAddress['county'] = '';
            this.props.onCommonResetProps({ name: 'countyByStateList', data: [] });
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY + event.target.value,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetStateByCountry(executedArgument);
        }
        if (event.target.name === 'state' && !!this.state.newAddress.countryCode && this.state.newAddress.countryCode === 'US') {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_COUNTY_BY_STATE + event.target.value,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetCountyByState(executedArgument);
        }
        if (event.target.name === "isDefault" || event.target.name === "isActive") {
            newAddress[event.target.name] = event.target.checked;
        } else {
            if (this.state.integerFieldAddress.indexOf(event.target.name) === -1) {
                newAddress[event.target.name] = event.target.value.trimStart();
            }
            else {
                newAddress[event.target.name] = parseInt(event.target.value, 10);
            }
        }
        //newAddress[event.target.name] = event.target.value.replace(/^\s+/g, ''); 
        this.setState({ newAddress: newAddress });
    }
    handleChangeCredential = event => {
        let newCredential = this.state.newCredential;
        newCredential[event.target.name] = event.target.value.replace(/^\s+/g, '');
        if (event.target.name === "credentialName") {
            let credentialType = this.props.staffPersonalTypeCredentialList.filter(item => item.title === newCredential.credentialName)
            if (credentialType.length > 0)
                newCredential['lookupCredentialType'] = credentialType[0].lookupCredentialType
        }
        this.setState({ newCredential: newCredential });
    }
    handleSubmit = event => {
        event.preventDefault();
        let validationObj = new Validation();
        let submitStatus;
        if (this.props.fromPage === 'studentGuardian' || this.props.fromPage === 'staffContact') {
            submitStatus = validationObj.fieldValidation(this.state.fields, this.state.requiredFields1);
        }
        else {
            submitStatus = validationObj.fieldValidation(this.state.fields, this.state.requiredFields);
        }
        let isErrorPhone = 0;
        if (this.state.tempPhoneArray.length === 0) {
            isErrorPhone = 1;
        }
        if (submitStatus === 0) {
            this.setState({ isError: 1, isErrorPhone: isErrorPhone });
        } else {
            let fields = Object.assign({}, this.state.fields);
            let arr = new Array();
            this.state.homePhoneNumberJsonList && this.state.homePhoneNumberJsonList.length > 0 && this.state.homePhoneNumberJsonList.map(item => {
                arr.push(item.mobile_number);
            })
            fields.homePhoneNumber = arr.join(",");
            arr = [];
            this.state.workPhoneNumberJsonList && this.state.workPhoneNumberJsonList.length > 0 && this.state.workPhoneNumberJsonList.map(item => {
                arr.push(item.mobile_number);
            })
            fields.workPhoneNumber = arr.join(",");
            arr = [];
            this.state.mobilePhoneNumberJsonList && this.state.mobilePhoneNumberJsonList.length > 0 && this.state.mobilePhoneNumberJsonList.map(item => {
                arr.push(item.mobile_number);
            })
            fields.mobileNumber = arr.join(",");
            arr = [];
            fields.mobilePhoneNumberJsonList = this.state.mobilePhoneNumberJsonList;
            fields.workPhoneNumberJsonList = this.state.workPhoneNumberJsonList;
            fields.homePhoneNumberJsonList = this.state.homePhoneNumberJsonList;
            fields.tempPhoneArray = this.state.tempPhoneArray;
            if (this.state.tempPhoneType === commonConfig.IS_HOME_PHONE) {
                if (fields['homePhoneNumber'] !== '') {
                    fields['homePhoneNumber'] = fields['homePhoneNumber'] + ',' + this.state.tempPhoneNumber;
                } else {
                    fields['homePhoneNumber'] = this.state.tempPhoneNumber;
                }
            }
            if (this.state.tempPhoneType === commonConfig.IS_WORK_PHONE) {
                if (fields['workPhoneNumber'] !== '') {
                    fields['workPhoneNumber'] = fields['workPhoneNumber'] + ',' + this.state.tempPhoneNumber;
                } else {
                    fields['workPhoneNumber'] = this.state.tempPhoneNumber;
                }
            }
            if (this.state.tempPhoneType === commonConfig.IS_MOBILE_PHONE) {
                if (fields['mobileNumber'] !== '') {
                    fields['mobileNumber'] = fields['mobileNumber'] + ',' + this.state.tempPhoneNumber;
                } else {
                    fields['mobileNumber'] = this.state.tempPhoneNumber;
                }
            }
            this.setState({ showAddNotify: false, fields: fields, getPersonInfoFlag: true, isError: 0, isErrorPhone: 0 }, () => {
                const fileData = new FormData()
                fileData.append('inputData', JSON.stringify(this.state.fields));
                fileData.append('component', commonConfig.COMPONENT_RESOURCE);
                fileData.append('endpoint', commonConfig.COMPONENT_PERSON_SAVE);
                if (this.state.fields.selectedFileList) {
                    for (let file of this.state.fields.selectedFileList) {
                        fileData.append("postedFile", file);
                    }
                }
                this.props.onSavePersonSucess(fileData);
            });
        }

    }
    saveCredential = () => {
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.newCredential, this.state.requiredFieldsCredential);
        if (submitStatus === 0) {
            this.setState({ isErrorCredential: 1 });
        } else {
            this.setState({ isErrorCredential: 0 }, () => {
                let fields = Object.assign({}, this.state.fields);
                if (this.state.editCredential) {
                    var credential = { ...this.state.newCredential }
                    var key = this.state.credentialIndex
                    fields['listCredential'].splice(key, 1, credential)
                    this.setState({ fields, editCredential: false, credentialIndex: null }, () => {
                        this.clearCredentialFields()
                    })
                }
                else {
                    if (this.state.newCredential.length !== '' || this.state.newCredential.length !== 'undefined' || this.state.newCredential.length !== null) {
                        let cred = this.state.newCredential;
                        fields['listCredential'].push(cred);
                    }
                    this.setState({ fields, editCredential: false, credentialIndex: null }, () => {
                        this.clearCredentialFields()
                    })
                }
            })
        }
    }
    closeModal = (e) => {
        e.preventDefault();
        this.setState({ field: this.initializeFields }, () => {
            window.$('#addschooldesignatorsPersonModal').modal('hide');
        });
    }
    clearAddressFields = () => {
        this.setState({
            ...this.state, isAddressError: 0, addressIndex: null, editAddress: false,
            newAddress: {
                lookupAddressType: null,
                addressLine: '',
                city: "",
                state: "",
                county: "",
                countryCode: "",
                zip: "",
                streetNoName: "",
                aprtSuiteRoomNo: "",
                buildingSiteNo: "",
                geoLocation: '',
                isDefault: false,
                isActive: true
            }
        }, () => {
            window.$("#addNewAddress").hide();
            window.$("#btnAddModalAddress").show();
        })
    }
    //This function is called for delete address from temporary array.
    removeAddress = (event, key) => {
        event.preventDefault();
        let fields = Object.assign({}, this.state.fields);
        fields['listAddress'].splice(key, 1)
        this.setState({ fields: fields }, () => {
            this.clearAddressFields()
        })
    }
    editAddress = (item, addressIndex) => {
        let newAddress = { ...item };
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY + newAddress.countryCode,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetStateByCountry(executedArgument);
        if (!!newAddress.countryCode && newAddress.countryCode === 'US') {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_RESOURCE_GET_COUNTY_BY_STATE + newAddress.state,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetCountyByState(executedArgument);
        }
        this.setState({ newAddress, editAddress: true, addressIndex }, () => {
            window.$("#addNewAddress").show();
            window.$("#btnAddModalAddress").hide();
        })
    }
    changeDefaultAddress = (addressIndex) => {
        this.setState((prevState) => {
            let listAddress = [...prevState.fields.listAddress]
            listAddress.map((item, key) =>
                listAddress[key].isDefault = false
            )
            listAddress[addressIndex].isDefault = true
            return { fields: { ...prevState.fields, listAddress: listAddress } }
        });
    }
    saveAddress = () => {
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.newAddress, this.state.requiredFieldsAddress);
        if (submitStatus === 0) {
            this.setState({ isAddressError: 1 });
        } else {
            this.setState({ isAddressError: 0 }, () => {
                let fields = Object.assign({}, this.state.fields);
                if (this.state.editAddress) {
                    var address = { ...this.state.newAddress }
                    var key = this.state.addressIndex
                    fields['listAddress'].splice(key, 1, address)
                    this.setState({ fields, editAddress: false, addressIndex: null }, () => {
                        this.clearAddressFields()
                    })
                }
                else {
                    if (this.state.newAddress.length !== '' || this.state.newAddress.length !== 'undefined' || this.state.newAddress.length !== null) {
                        fields['listAddress'].push(this.state.newAddress);
                    }
                    this.setState({ fields: fields }, () => {
                        this.clearAddressFields()
                    });
                }
            })
        }
    }
    addressShow = () => {
        var address = null;
        this.state.fields.listAddress && this.state.fields.listAddress.length > 0 ?
            address = this.state.fields.listAddress.map((item, key) => {
                let tempViewAddress = [item.streetNoName, item.aprtSuiteRoomNo, item.buildingSiteNo, item.city, item.state, item.zip];
                return <div className="panel-default">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-2 col-md-2 col-lg-3">
                                <label className="fluid-radio m-l-15 d-flex align-items-center">
                                    <input className="hidden" type="radio" name="radio" checked={item.isDefault} onChange={() => this.changeDefaultAddress(key)} />
                                    <span className="label"></span><span className="d-none d-lg-inline-block">{i18n.t('default')}</span>
                                </label>
                            </div>
                            <div className="col-6 col-md-6 col-lg-7">
                                <div className="address">
                                    <label className="d-block d-md-none f-s-12 text-muted m-b-5">{i18n.t('address')}</label>
                                    <h4>
                                        {!!this.props.studentContactAddressTypeList && this.props.studentContactAddressTypeList.map((lookup, key) => {
                                            return item.lookupAddressType === lookup.id ? lookup.title : '';
                                        })}
                                        {item.isActive === true && <label className="badge badge-success text-uppercase ml-3 mb-0">{i18n.t('Active')}</label>}
                                    </h4>
                                    <p>{tempViewAddress.filter(dt => !!dt).join(commonConfig.COMMON_COMMA)}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 ">
                                <div className="action-icons text-right text-md-left">
                                    <Link to="#" onClick={() => this.editAddress(item, key)}><i className="icon-pen6"></i></Link>
                                    <Link to="#" onClick={(e) => this.removeAddress(e, key)}><i className="icon-bin2"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }) : address = <div className="panel-default">
                <div className="panel-body">
                    <div className="text-danger f-s-12 text-center" role="alert">
                        <i className="icon-info2 m-r-5"></i> {i18n.t('noAddressFound')}
                    </div>
                </div>
            </div>;
        return address;
    }
    editCredential = (item, credentialIndex) => {
        let newCredential = { ...item }
        this.setState({ newCredential, editCredential: true, credentialIndex }, () => {
            window.$("#addNewCredential").show();
            window.$("#btnAddCredential").hide();
        })
    }
    credentialShow = () => {
        var credential = null;
        this.state.fields.listCredential && this.state.fields.listCredential.length > 0 ?
            credential = this.state.fields.listCredential.map((data, i) =>
                <div className="panel-default">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-10 col-md-7 col-lg-10">
                                <div className="address">
                                    <label className="d-block d-md-none f-s-12 text-muted m-b-5">{i18n.t('address')}</label>
                                    <h4>{data.credentialName}</h4>
                                    <p>{data.issuingState}{!!data.certificateOrLicenseNo ? commonConfig.COMMON_COMMA : ''}{data.certificateOrLicenseNo}{!!data.issuingAuthority ? commonConfig.COMMON_COMMA : ''}{data.issuingAuthority}</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-lg-2 ">
                                <div className="action-icons text-right text-md-left">
                                    <Link to="#" onClick={() => this.editCredential(data, i)}><i className="icon-pen6"></i></Link>
                                    <Link to="#" onClick={(e) => this.removeCredential(e, i)}><i className="icon-bin2 "></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : credential = <div className="panel-default">
                <div className="panel-body">
                    <div className="text-danger f-s-12 text-center" role="alert">
                        <i className="icon-info2 m-r-5"></i> {i18n.t('noCredentialFound')}
                    </div>
                </div>
            </div>;
        return credential;
    }
    clearCredentialFields = () => {
        this.setState({
            ...this.state, isErrorCredential: 0, credentialIndex: null, editCredential: false,
            newCredential: {
                credentialName: '',
                lookupCredentialType: null,
                issuranceDate: '',
                expirationDate: '',
                issuingState: '',
                certificateOrLicenseNo: '',
                issuingAuthority: ''
            }
        }, () => {
            window.$("#addNewCredential").hide();
            window.$("#btnAddCredential").show();
        })
    }
    removeCredential = (event, key) => {
        event.preventDefault();
        let fields = Object.assign({}, this.state.fields);
        fields['listCredential'].splice(key, 1)
        this.setState({ fields: fields }, () => {
            this.clearCredentialFields()
        })
    }
    searchPerson = (event) => {
        if (!!event.target.value) {
            const personName = event.target.value.trimStart();
            const dropDown = event.target.name;
            let filterData = this.state.allPerson && this.state.allPerson.length > 0 ? this.state.allPerson.filter(item => item[this.state.getPersonPostedDataField.type === 'teacher' ? 'staffFullName' : 'fullName'].toLowerCase().includes(personName.toLowerCase())) : '';
            let setStateObject = { allPersonSearch: filterData, personName: personName }
            if (personName.length === 0) {
                setStateObject = { ...setStateObject, personId: null }
            }
            this.setState(setStateObject, () => {
                this.props.setPersonData(this.state.personId, this.state.personName, 'SEARCH')
                window.$('.autocomplete-dropdown-' + dropDown).show();
            })
        } else {
            this.setState({ personId: null, personName: '' }, () => {
                this.props.setPersonData(0, '', 'SEARCH')
                window.$('.autocomplete-dropdown').hide();
            });
        }
    }
    setPersonId = (data) => {
        if (this.state.getPersonPostedDataField.type === 'teacher') {
            this.setState({ personName: data.teacherName, personId: data.teacherId }, () => {
                this.props.setPersonData(data.teacherId, data.teacherName, 'SEARCH')
                window.$('.autocomplete-dropdown').hide();
            });
        }
        else if (this.props.hasOwnProperty('type') && this.props.type === 'staff' && this.props.hasOwnProperty('includeStaffId') && !!this.props.includeStaffId) {
            this.props.setPersonData(data.staffId, data.fullName, 'SEARCH')
            window.$('.autocomplete-dropdown').hide();
        }
        else if (this.props.hasOwnProperty('type') && this.props.type === 'staff' && !!this.props.schoolDesignatorFlag) {
            this.props.setPersonData(data.id, data.fullName, 'SEARCH')
            window.$('.autocomplete-dropdown').hide();
        }
        else if (this.props.hasOwnProperty('type') && this.props.type === 'staff') {
            this.props.setPersonData(data.staffId, data.fullName, 'SEARCH')
            window.$('.autocomplete-dropdown').hide();
        }
        else {
            this.setState({ personName: data.fullName, personId: data.id }, () => {
                this.props.setPersonData(this.props.hasOwnProperty('type') && this.props.type === 'staff' && !this.props.hasOwnProperty('showAddPerson') ? data.staffId : data.id, data.fullName, 'SEARCH')
                window.$('.autocomplete-dropdown').hide();
            });
        }
    }
    occupationShow = () => {
        return <React.Fragment>
            <h6 className="text-primary m-t-10"><b>{i18n.t('occupation')}</b></h6>
            <div className="panel-default">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('type')} {this.state.isOccupationFieldsNotEmpty && <span>*</span>}</label>
                                <select className="custom-select" name="lookupOccupationType" onChange={this.handleChange} value={this.state.fields.lookupOccupationType}>
                                    <option value="">{i18n.t('select')}</option>
                                    {this.props.lookupPersonOccupationTypeList.map((lookup, key) => {
                                        return <option key={key} value={lookup.id}>{lookup.title}</option>
                                    })}
                                </select>
                                {this.state.isOccupationFieldsNotEmpty && <Validation data={{
                                    field: i18n.t("type"),
                                    errorClass: 'type_error',
                                    value: this.state.fields.lookupOccupationType,
                                    isError: this.state.isError,
                                    validationType: 'selectfield'
                                }} returnValidation={this.functionValidation} />}
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('companyEmployer')} </label>
                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterCompanyEmployer')} name="occupationTitle" onChange={this.handleChange} value={this.state.fields.occupationTitle} />
                                <Validation data={{ field: i18n.t('companyEmployer'), errorClass: 'occupationTitle_error', value: this.state.fields.occupationTitle, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION }} returnValidation={this.functionValidation} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-12 col-lg-4"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('jobTitleDesignation')}</label>
                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterJobTitleDesignation')} name="designation" onChange={this.handleChange} value={this.state.fields.designation} />
                                <Validation data={{ field: i18n.t('jobTitleDesignation'), errorClass: 'designation_error', value: this.state.fields.designation, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.OCCUPATION_TITLE_DESIGNATION }} returnValidation={this.functionValidation} />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('monthlyIncome')}</label>
                                <input type="text" maxLength={40} className="form-control" placeholder={i18n.t('enterMonthlyIncome')} name="monthlyIncome" onChange={this.handleChange} value={this.state.fields.monthlyIncome || ''} />
                                <Validation data={{ field: i18n.t('monthlyIncome'), errorClass: 'monthlyIncome_error', value: this.state.fields.monthlyIncome, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.MONTHLY_INCOME }} returnValidation={this.functionValidation} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-12 col-lg-6">
                            <div className="form-group">
                                <label className="col-form-label">{i18n.t('workEmail')}</label>
                                <input type="text" maxLength={40} className="form-control" placeholder={i18n.t('enterWorkEmail')} name="workEmail" onChange={this.handleChange} value={this.state.fields.workEmail} />
                                <Validation data={{ field: i18n.t('workEmail'), errorClass: 'workEmail_error', value: this.state.fields.workEmail, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
    render() {
        const { isError, isRequired, dobFlag, isNotifiable } = this.props;
        let fieldAttr = {
            className: 'form-control',
            type: "text",
            placeholder: this.props.hasOwnProperty('placeholder') ? this.props.placeholder : i18n.t('searchPerson'),
            name: this.props.hasOwnProperty('name') && !!this.props.name ? this.props.name : "personName",
            value: this.state.personName || ''
        }
        const labelTitle = this.props.hasOwnProperty('labelTitle') ? this.props.labelTitle : i18n.t('person');
        const searchPersonValidationResult = !!isRequired ? <Validation data={{
            field: labelTitle,
            errorClass: 'personId_error',
            value: this.state.personId || '',
            isError: isError,
            validationType: 'selectfield'
        }} returnValidation={this.functionValidation} /> : null;

        return (
            <React.Fragment>
                {!this.state.isSearch ?
                    <div className="form-group">
                        <label className="col-form-label">{labelTitle}{!!isRequired && <span> *</span>}</label>
                        <input {...fieldAttr} data-minchar="0" readOnly={true} />
                        {searchPersonValidationResult}
                    </div>
                    :
                    <div className="form-group dropdown-autocomplete">
                        <label className="col-form-label">{labelTitle}{!!isRequired && <span> *</span>}</label>
                        <input {...fieldAttr} data-minchar="0" autoComplete="off" onChange={(event) => this.searchPerson(event)} />
                        {searchPersonValidationResult}
                        <div className={`autocomplete-dropdown autocomplete-dropdown-${this.props.hasOwnProperty('name') && !!this.props.name ? this.props.name : 'personName'}`}>
                            <div className="autocomplete-dropdown-body">
                                {/*this.props.personInfo.name !== null && this.props.personInfo.name !== '' && */this.state.allPersonSearch && this.state.allPersonSearch.length > 0 ? this.state.allPersonSearch.map((data, i) =>
                                    <Link className="media" to="#" onClick={() => this.setPersonId(data)} key={i}>
                                        <img className="mr-3 rounded-circle" alt="img" src={base64ImageConfig.USER_DEFAULT_IMG} />
                                        <div className="media-body">
                                            <h5 className="mt-0">{this.state.getPersonPostedDataField.type === 'teacher' ? data.staffFullName : data.fullName}</h5>
                                        </div>
                                    </Link>
                                ) : ''}
                            </div>
                            {(this.state.getPersonPostedDataField.type === '' || this.state.getPersonPostedDataField.type === 'exceptstudent' || ((this.state.getPersonPostedDataField.type === 'staff' || this.state.getPersonPostedDataField.type === 'staffwithstaffcontacts') && this.props.hasOwnProperty('showAddPerson') && this.props.showAddPerson)) &&
                                <Link className="autocomplete-dropdown-footer" to="#" data-toggle="modal" data-target="#addschooldesignatorsPersonModal">
                                    <i className="icon-plus3 m-r-10"></i> {i18n.t('addNewPerson')}
                                </Link>
                            }
                            {this.props.showAddStaff && !this.props.showAddPerson
                                ? <Link to="/staff/addStaff/demographic" className="autocomplete-dropdown-footer">
                                    <i className="icon-plus3 m-r-10"></i>{i18n.t("addNewStaff")}
                                </Link>
                                : null
                            }
                        </div>
                    </div>
                }
                {(this.state.getPersonPostedDataField.type === '' || this.state.getPersonPostedDataField.type === 'exceptstudent' || ((this.state.getPersonPostedDataField.type === 'staff' || this.state.getPersonPostedDataField.type === 'staffwithstaffcontacts') && this.props.hasOwnProperty('showAddPerson') && this.props.showAddPerson)) &&
                    <div id="addschooldesignatorsPersonModal" className="modal fade">
                        <form action="" id="addschooldesig" name="addschooldesig">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="CreateCalendarModal">{i18n.t('addPerson')}</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => this.closeModal(e)}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <h6 className="text-primary m-t-10"><b>{i18n.t('name')}</b></h6>
                                        <div className="panel-default">
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('salutation')}</label>
                                                            <select className="custom-select" name="lookupSalutation" onChange={this.handleChange} value={this.state.fields.salutation}>
                                                                <option value="">{i18n.t('select')}</option>
                                                                {this.state.lookupSalutationList.map((lookup, key) => {
                                                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-4">
                                                        <div className="form-group">
                                                            <MarkersCommonSelect
                                                                label={i18n.t("nameSuffix")}
                                                                divClassName="mb-4  mb-lg-0"
                                                                name="lookupNameSuffix"
                                                                value={this.state.fields['lookupNameSuffix']}
                                                                optArray={this.props.lookupNameSuffixList}
                                                                handleChange={this.handleChange}
                                                                isError={this.state.isError}
                                                                isRequired={false}
                                                                optionLabelValue='id'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-4"></div>
                                                </div>
                                                {(this.props.fromPage === 'studentGuardian' || this.props.fromPage === 'staffContact') ?
                                                    <div className="row">
                                                        <div className="col-sm-6 col-md-12 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="col-form-label">{i18n.t('firstName')} <span>*</span></label>
                                                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterFirstName')} name="firstName" onChange={this.handleChange} value={this.state.fields.firstName} />
                                                                <Validation data={{ field: i18n.t('firstName'), errorClass: 'firstName_error', value: this.state.fields.firstName, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO }} returnValidation={this.functionValidation} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-12 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="col-form-label">{i18n.t('middleName')}</label>
                                                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterMiddleName')} name="middleName" onChange={this.handleChange} value={this.state.fields.middleName} />
                                                                <Validation data={{ field: i18n.t('middleName'), errorClass: 'middleName_error', value: this.state.fields.middleName, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO }} returnValidation={this.functionValidation} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-12 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="col-form-label">{i18n.t('lastName')} <span>*</span></label>
                                                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterLastName')} name="lastName" onChange={this.handleChange} value={this.state.fields.lastName} />
                                                                <Validation data={{ field: i18n.t('lastName'), errorClass: 'lastName_error', value: this.state.fields.lastName, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO }} returnValidation={this.functionValidation} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="row">
                                                        <div className="col-sm-6 col-md-12 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="col-form-label">{i18n.t('firstName')} <span>*</span></label>
                                                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterFirstName')} name="firstName" onChange={this.handleChange} value={this.state.fields.firstName} />
                                                                <Validation data={{ field: i18n.t('firstName'), errorClass: 'firstName_error', value: this.state.fields.firstName, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO }} returnValidation={this.functionValidation} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-12 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="col-form-label">{i18n.t('middleName')}</label>
                                                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterMiddleName')} name="middleName" onChange={this.handleChange} value={this.state.fields.middleName} />
                                                                <Validation data={{ field: i18n.t('middleName'), errorClass: 'middleName_error', value: this.state.fields.middleName, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO }} returnValidation={this.functionValidation} />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-12 col-lg-4">
                                                            <div className="form-group">
                                                                <label className="col-form-label">{i18n.t('lastName')} <span>*</span></label>
                                                                <input type="text" maxLength={50} className="form-control" placeholder={i18n.t('enterLastName')} name="lastName" onChange={this.handleChange} value={this.state.fields.lastName} />
                                                                <Validation data={{ field: i18n.t('lastName'), errorClass: 'lastName_error', value: this.state.fields.lastName, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.STUDENT_STAFF_GENERAL_INFO }} returnValidation={this.functionValidation} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <MarkersDatepicker
                                                    value={this.state.fields.dateOfBirth}
                                                    title={i18n.t('dateOfBirth')}
                                                    name="dateOfBirth"
                                                    isError={this.state.isError}
                                                    isRequired={!!dobFlag ? false : true}
                                                    onChange={this.handleChange}
                                                    endDate={moment().subtract(commonConfig.STAFF_MIN_AGE, 'years').format("YYYY-MM-DD")}
                                                    isClearable={this.state.fields.dateOfBirth ? true : false}
                                                    clearFunction={() => this.handleClearDate('dateOfBirth')}
                                                />
                                                <div className="form-group">
                                                    <label className="col-form-label">{i18n.t('gender')} </label>
                                                    <select className="custom-select" onChange={this.handleChangeGenderType} name="gender" value={this.state.tempGenderType}>
                                                        <option value="">{i18n.t('selectGenderType')}</option>
                                                        {this.state.genderTypeList.map((lookup, key) => {
                                                            return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <MarkersFileUpload
                                                    label={i18n.t("selectPhoto")}
                                                    isError={this.state.isError}
                                                    isRequired={false}
                                                    setFileField={this.setFileField}
                                                    fileType={commonConfig.FILE_TYPE_IMAGE}
                                                    isPreview={true}
                                                    savedFileList={[]}
                                                    fileMaxSize={commonConfig.FILE_TYPE_IMAGE_MAXSIZE}
                                                    fileMinSize={commonConfig.FILE_TYPE_IMAGE_MINSIZE}
                                                />
                                            </div>
                                        </div>
                                        <h6 className="text-primary m-t-10"><b>{i18n.t('email')}</b></h6>
                                        <div className="row">
                                            {(this.props.fromPage === 'studentGuardian' || this.props.fromPage === 'staffContact') ?
                                                <div className="col-md-6">
                                                    <div className="form-group mb-4  mb-md-0">
                                                        <label className="col-form-label">{i18n.t('primaryEmail')} {!!isNotifiable ? <span>*</span> : ''}</label>
                                                        <input type="text" maxLength={256} name="primaryEmail" value={this.state.fields.primaryEmail} className="form-control" onChange={this.handleChange} placeholder={i18n.t('enterPrimaryEmail')} />
                                                        <Validation data={{ field: i18n.t('primaryEmail'), errorClass: 'primaryEmail_error', value: this.state.fields.primaryEmail, isError: this.state.isError, validationType: !!isNotifiable ? 'regExp' : 'regExpNotRequired', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div> :
                                                <div className="col-sm-6 col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className="col-form-label">{i18n.t('primaryEmail')}  <span>*</span></label>
                                                        <input type="text" maxLength={256} name="primaryEmail" value={this.state.fields.primaryEmail} className="form-control" onChange={this.handleChange} placeholder={i18n.t('enterPrimaryEmail')} />
                                                        <Validation data={{ field: i18n.t('primaryEmail'), errorClass: 'primaryEmail_error', value: this.state.fields.primaryEmail, isError: this.state.isError, validationType: 'regExp', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-sm-6 col-md-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">{i18n.t('alternateEmail')}</label>
                                                    <input type="text" maxLength={256} name="alternateEmail" value={this.state.fields.alternateEmail} onChange={this.handleChange} className="form-control" placeholder={i18n.t('enterAlternateEmail')} />
                                                    <Validation data={{ field: i18n.t('alternateEmail'), errorClass: 'email_error', value: this.state.fields.alternateEmail, isError: this.state.isError, validationType: 'regExpNotRequired', regExp: commonConfig.EMAIL }} returnValidation={this.functionValidation} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {!this.props.isView ? this.phoneComponent() : this.phoneComponentView()}
                                            <div id="deleteModalPhone" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="deleteModalTitle" aria-modal="true">
                                                <div className="modal-dialog modal-dialog-centered" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="exampleModalCenterTitle">{i18n.t('areYouSureYouWantToDeleteTheRecord')}</h5>
                                                            <button type="button" className="close" aria-label="Close" onClick={(e) => this.closeDeletePhoneModal(e)}>
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <label className="col-form-label">{i18n.t('deletionReason')}</label>
                                                            <textarea name="deletionReason" value={this.state.deletionReason || ''} onChange={this.handleDelete} className="form-control" placeholder={i18n.t('enterDeletionReason')} style={{ height: '126px' }}></textarea>
                                                            <Validation data={{ field: i18n.t('deletionReason'), errorClass: 'deletionReason_error', value: this.state.deletionReason, isError: this.state.isErrorDeleteReason, validationType: 'field' }} returnValidation={this.functionValidation} />
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{i18n.t('noCancel')}</button>
                                                            <button type="button" className="btn btn-danger" onClick={(e) => this.deletePhone(this.state.deleteId)}>{i18n.t('yesdelete')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="text-primary m-t-10"><b>{i18n.t('address')}</b></h6>
                                        {this.addressShow()}
                                        <div className="text-center">
                                            <Link to="#" className="btn btn-primary m-b-15" id="btnAddModalAddress" data-toggle="toggle-visibility" data-targetlayer="#addNewAddress" data-destructlayer="#btnAddModalAddress" >
                                                <i className="icon-plus3 m-r-10"></i> {i18n.t('addNewAddress')}
                                            </Link>
                                        </div>
                                        <div className="panel-default" id="addNewAddress" style={{ display: 'none' }}>
                                            <div className="panel-header">
                                                <h6 className="panel-title">{i18n.t('address')} <label className="badge badge-primary text-uppercase m-l-15"> {!this.state.editAddress ? i18n.t('new') : i18n.t('update')}</label></h6>
                                            </div>
                                            <div className="panel-body pt-0 pb-0">
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('addressType')} <span>*</span></label>
                                                            <select className="custom-select" value={this.state.newAddress.lookupAddressType || ''} name="lookupAddressType" onChange={this.handleChangeAddress}>
                                                                <option>{i18n.t('selectType')}</option>
                                                                {!!this.props.studentContactAddressTypeList && this.props.studentContactAddressTypeList.map((lookup, key) => {
                                                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                                })}
                                                            </select>
                                                            <Validation data={{ field: i18n.t('addressType'), errorClass: 'addressType_error', value: this.state.newAddress.lookupAddressType, isError: this.state.isAddressError, validationType: 'field' }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('streetpostofficebox')} <span>*</span></label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterstreetNumberandName')} value={this.state.newAddress.streetNoName} name="streetNoName" onChange={this.handleChangeAddress} />
                                                            <Validation data={{ field: i18n.t('address'), errorClass: 'address_error', value: this.state.newAddress.streetNoName, isError: this.state.isAddressError, validationType: 'regExp', regExp: commonConfig.ADDRESS_SCHOOL }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('aprtSuiteRoomNo')} </label>
                                                            <input type="text" maxLength={40} className="form-control" placeholder={i18n.t('enterAprtSuiteRoomNo')} value={this.state.newAddress.aprtSuiteRoomNo} name="aprtSuiteRoomNo" onChange={this.handleChangeAddress} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('buildingSiteNo')} </label>
                                                            <input type="text" maxLength={40} className="form-control" placeholder={i18n.t('enterBuildingSiteNo')} value={this.state.newAddress.buildingSiteNo} name="buildingSiteNo" onChange={this.handleChangeAddress} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <MarkersCommonSelect
                                                            label={i18n.t("country")}
                                                            name="countryCode"
                                                            value={this.state.newAddress['countryCode']}
                                                            optArray={this.props.countryList}
                                                            handleChange={this.handleChangeAddress}
                                                            isError={this.state.isAddressError}
                                                            isRequired={true}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <MarkersCommonSelect
                                                            label={i18n.t("state")}
                                                            optionLabelValue="title"
                                                            name="state"
                                                            value={this.state.newAddress.state}
                                                            optArray={this.props.stateByCountryList}
                                                            handleChange={this.handleChangeAddress}
                                                            isError={this.state.isAddressError}
                                                            isRequired={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {!!this.state.newAddress.countryCode && this.state.newAddress.countryCode === 'US' &&
                                                        <div className="col-sm-6 col-md-12 col-lg-6">
                                                            <MarkersCommonSelect
                                                                label={i18n.t("county")}
                                                                optionLabelValue="title"
                                                                name="county"
                                                                value={this.state.newAddress.county}
                                                                optArray={this.props.countyByStateList}
                                                                handleChange={this.handleChangeAddress}
                                                                isError={this.state.isAddressError}
                                                                isRequired={false}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('city')} <span>*</span></label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterCity')} value={this.state.newAddress.city} name="city" onChange={this.handleChangeAddress} />
                                                            <Validation data={{ field: i18n.t('city'), errorClass: 'city_error', value: this.state.newAddress.city, isError: this.state.isAddressError, validationType: 'regExp', regExp: commonConfig.ADDRESS_COUNTY_REGEX }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('zipPostalCode')} <span>*</span></label>
                                                            <input type="text" maxLength={10} className="form-control" placeholder={i18n.t('enterZipPostalCode')} value={this.state.newAddress.zip} name="zip" onChange={this.handleChangeAddress} />
                                                            <Validation data={{ field: i18n.t('zipPostalCode'), errorClass: 'state_error', value: this.state.newAddress.zip, isError: this.state.isAddressError, validationType: 'regExp', regExp: commonConfig.POSTAL_CODE_ZIP }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Geolocation</label>
                                                            <input type="text" className="form-control" value={this.state.newAddress.geoLocation||""} name="geoLocation" placeholder={i18n.t('enterGeoLocation')} onChange={this.handleChangeAddress} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 d-flex flex-wrap">
                                                        <label className="d-flex align-items-center mr-4">
                                                            <div className="fluid-switch-toggle cursor-pointer">
                                                                <input className="switch-toggle-state" type="checkbox" checked={this.state.newAddress.isDefault} name="isDefault" onChange={this.handleChangeAddress} />
                                                                <div className="switch-indicator"></div>
                                                                <div className="switch-activebg"></div>
                                                            </div>
                                                            <span className="switch-text">{i18n.t("defaultAddress")}</span>
                                                        </label>
                                                        <label className="d-flex align-items-center mb-0">
                                                            <div className="fluid-switch-toggle cursor-pointer">
                                                                <input className="switch-toggle-state" type="checkbox" name="isActive" checked={this.state.newAddress.isActive} onChange={this.handleChangeAddress} />
                                                                <div className="switch-indicator"></div>
                                                                <div className="switch-activebg"></div>
                                                            </div>
                                                            <span className="switch-text">{i18n.t("active")}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="panel-body pt-0 text-right">
                                                    <Link to="#" className="btn btn-outline-success m-r-5 addNewFieldAreaaa showAddButton1" onClick={this.saveAddress}>
                                                        <i className="icon-checkmark2"></i><span className="sr-only">{i18n.t('done')}</span>
                                                    </Link>
                                                    <Link to="#" className="btn btn-outline-warning" data-toggle="toggle-visibility" data-targetlayer="#btnAddModal" data-destructlayer="#addNewAddress" onClick={this.clearAddressFields}>
                                                        <i className="icon-cross"></i><span className="sr-only">{i18n.t('cancel')}</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 className="text-primary m-t-10"><b>{i18n.t('credential')}</b></h6>
                                        {this.credentialShow()}
                                        <div className="text-center">
                                            <Link to="#" className="btn btn-primary m-b-15" id="btnAddCredential" data-toggle="toggle-visibility" data-targetlayer="#addNewCredential" data-destructlayer="#btnAddCredential">
                                                <i className="icon-plus3 m-r-10"></i> {i18n.t('addNewCredential')}
                                            </Link>
                                        </div>
                                        <div className="panel-default" id="addNewCredential" style={{ display: 'none' }}>
                                            <div className="panel-header">
                                                <h6 className="panel-title">{i18n.t('credential')} <label className="badge badge-primary text-uppercase m-l-15">{!this.state.editCredential ? i18n.t('new') : i18n.t('update')}</label></h6>
                                            </div>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <MarkersCommonSelect
                                                            label={i18n.t("credentialName")}
                                                            name="credentialName"
                                                            value={this.state.newCredential.credentialName}
                                                            optArray={this.props.staffPersonalTypeCredentialList}
                                                            handleChange={this.handleChangeCredential}
                                                            isError={this.state.isErrorCredential}
                                                            isRequired={true}
                                                            optionLabelValue="title"
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <MarkersDatepicker
                                                            title={i18n.t('issuingDate')}
                                                            value={this.state.newCredential.issuranceDate}
                                                            name="issuranceDate"
                                                            onChange={this.handleChangeCredential}
                                                            isRequired={true}
                                                            isError={this.state.isErrorCredential}
                                                            validationType="field"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <MarkersDatepicker
                                                            title={i18n.t('expirationDate')}
                                                            value={this.state.newCredential.expirationDate}
                                                            name="expirationDate"
                                                            isError={this.state.isErrorCredential}
                                                            onChange={this.handleChangeCredential}
                                                            startDate={this.state.newCredential.issuranceDate}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('issuingState')}</label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterIssuingState')} name="issuingState" value={this.state.newCredential.issuingState} onChange={this.handleChangeCredential} />
                                                            <Validation data={{
                                                                field: i18n.t("issuingState"),
                                                                errorClass: 'issuingState_error',
                                                                value: this.state.newCredential.issuingState,
                                                                isError: this.state.isErrorCredential,
                                                                validationType: 'regExpNotRequired', regExp: commonConfig.ADDRESS_CITY_STATE_COUNTRY
                                                            }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('certificateOrLicenseNo')}</label>
                                                            <input type="text" maxLength={30} className="form-control" placeholder={i18n.t('enterCertificateLicenceNo')} name="certificateOrLicenseNo" value={this.state.newCredential.certificateOrLicenseNo} onChange={this.handleChangeCredential} />
                                                            <Validation data={{
                                                                field: i18n.t("certificateLicenceNo"),
                                                                errorClass: 'cretificateLicenceNo_error',
                                                                value: this.state.newCredential.certificateOrLicenseNo,
                                                                isError: this.state.isErrorCredential,
                                                                validationType: 'regExpNotRequired', regExp: commonConfig.LICENCE_NO
                                                            }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label">{i18n.t('issuingAuthority')}</label>
                                                            <input type="text" maxLength={256} className="form-control" placeholder={i18n.t('enterIssuingAuthority')} name="issuingAuthority" value={this.state.newCredential.issuingAuthority} onChange={this.handleChangeCredential} />
                                                            <Validation data={{
                                                                field: i18n.t("issuingAuthority"),
                                                                errorClass: 'issuingAuthority_error',
                                                                value: this.state.newCredential.issuingAuthority,
                                                                isError: this.state.isErrorCredential,
                                                                validationType: 'regExpNotRequired', regExp: commonConfig.CERTIFICATE_NUMBER
                                                            }} returnValidation={this.functionValidation} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel-body pt-0 text-right">
                                                <Link to="#" className="btn btn-outline-success m-r-5 addNewFieldAreaaa showAddButton1" onClick={this.saveCredential}>
                                                    <i className="icon-checkmark2"></i><span className="sr-only">{i18n.t('done')}</span>
                                                </Link>
                                                <Link to="#" className="btn btn-outline-warning" data-toggle="toggle-visibility" data-targetlayer="#btnAddCredential" data-destructlayer="#addNewCredential" onClick={this.clearCredentialFields}>
                                                    <i className="icon-cross " ></i><span className="sr-only">{i18n.t('cancel')}</span>
                                                </Link>
                                            </div>
                                        </div>
                                        {this.props.hasOwnProperty('isOccupation') ?
                                            <div></div>
                                            :
                                            this.occupationShow()
                                        }
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-success" onClick={this.handleSubmit}>{i18n.t('submit')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                }
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lookupSalutationList: state.commonStore.lookupSalutationList,
        saveSchoolPerson: state.schoolStore.saveSchoolPerson,
        schoolPersonList: state.schoolStore.schoolPersonList,
        schoolAllPersonList: state.schoolStore.schoolAllPersonList,
        staffHolidayStaffList: state.staffStore.staffHolidayStaffList,
        lookupCredentialTypeList: state.commonStore.lookupCredentialTypeList,
        lookupPersonOccupationTypeList: state.commonStore.lookupPersonOccupationTypeList,
        countryList: state.commonStore.countryList,
        stateByCountryList: state.commonStore.stateByCountryList,
        countyByStateList: state.commonStore.countyByStateList,
        lookupNameSuffixList: state.commonStore.lookupNameSuffixList,
        getDistrictCountryStateLanguage: state.districtStore.getDistrictCountryStateLanguage,
        staffPersonalTypeCredentialList: state.staffStore.staffPersonalTypeCredentialList,
        studentContactAddressTypeList: state.studentStore.studentContactAddressTypeList
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onSavePersonSucess: (fileData) => dispatch(actions.callSchoolSaveApi(fileData, 'Person', true)),
        onGetPersonSucess: (executeArgs) => dispatch(actions.callSchoolListApi(executeArgs, 'Person')),
        onGetAllPersonSucess: (executeArgs) => dispatch(actions.callSchoolListApi(executeArgs, 'all person')),
        onGetHolidayStaffSucess: (executeArgs) => dispatch(actionsStaff.callStaffListApi(executeArgs, 'holiday staff')),
        onGetCountryList: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'country')),
        onGetLookup: (categoryType) => dispatch(actionsCommon.callLookupApi(categoryType)),
        onShowNotify: (message, type) => dispatch(actionsCommon.showNotify(message, type)),
        onGetStateByCountry: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'state by country')),
        onGetCountyByState: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'county by state')),
        onCommonResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs)),
        onGetCountryStateLanguage: (executeArgs) => dispatch(actionsDistrict.callDistrictGetByApi(executeArgs, 'CountryStateLanguage')),
        onGetPersonalCredentials: (executeArgs) => dispatch(actionsStaff.callStaffListApi(executeArgs, 'personal type credential')),
        onResetDistrictProps: (executeArgs) => dispatch(actionsDistrict.districtStoreStateReset(executeArgs)),
        onGetStudentContactAddressTypeList: (executeArgs) => dispatch(actionsStudent.callStudentListApi(executeArgs, 'contact address type'))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersCommonPerson);
