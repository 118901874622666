import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { MarkersMultiLingual } from './markersMultiLingualComponent';
import sessionStore from './sessionStoreComponent';
import ConvertDate from './markersConvertDateComponent';
import 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-fixedheader-bs4';
import CommonFuncs from './markersCommonFunctionComponent';
import MarkersDatepicker from '../component/markersDatepicker';
//import 'datatables.net-responsive-bs4';
import 'datatables.net-scroller-bs4';
import 'datatables.net-colreorder';
import 'jszip';
import Moment from 'react-moment';
import i18n from '../../../i18n';
import commonConfig from '../commonConfig';
const langObj = new MarkersMultiLingual();
const commonFuncObj = new CommonFuncs();
var totalRows = 0;
var tablePaginationValue = '';
var tableSearchValue = '';
class dataTable extends Component {
    state = {
        table: null,
    }
    csvHeader = 'Markerspro\n';
    defaultOrder = true;
    checkAllFlag = false;
    isPrint = false;
    pdfPageSize = '';
    pdfOrientation = '';
    selectAll(event, customTableClass, onChangeFunction, excludeCheckboxClass = '') {
        let table = $('.' + customTableClass).DataTable();
        var allPages = table.rows({ 'search': 'applied' }).nodes();
        if (!!excludeCheckboxClass) {
            $('input[type="checkbox"]:not(".' + excludeCheckboxClass + '")', allPages).prop('checked', event.target.checked);
        } else {
            $('input[type="checkbox"]', allPages).prop('checked', event.target.checked);
        }
        if (this.checkAllFlag === true) {
            this.returnDestroyTable(customTableClass);
            if ($('input[type="checkbox"]', allPages).length !== 0)
                this.returnResetTable(null, true, 1, 'desc', [], customTableClass);
        }
        if (!!onChangeFunction)
            onChangeFunction()
    }
    getCheckedValue = (customTableClass = 'markers-datatable', valueDataType = 'default') => {
        let listData = [];
        let table = $('.' + customTableClass).DataTable();
        var allPages = table.rows({ 'search': 'applied' }).nodes();
        $('input[type="checkbox"]', allPages).each(function () {
            if (this.checked) {
                listData.push(valueDataType === 'integer' ? parseInt(this.value, 10) : this.value)
            }
        })
        return listData;
    }
    //for datatable select without pagination
    getSelectedCheckedValue = () => {
        let values = [];
        $('input[type="checkbox"]').each(function () {
            if (this.checked) {
                values.push(this.value)
            }
        })
        return values;
    }
    setCheckedValue = (chackedValuesArray) => {
        chackedValuesArray.map((value) => {
            $('input[type="checkbox"][value="' + value + '"]').attr('checked', true);
        })
    }
    setCheckedValueV2 = (checkedValuesArray, fieldName, customTableClass = 'markers-datatable') => {
        let tableNode = $(`.${customTableClass}`).DataTable();
        let allPageNode = tableNode.rows({ 'search': 'applied' }).nodes();
        let inputCount = 0;
        $('input[type="checkbox"]', allPageNode).each(function () {
            $(`input[type="checkbox"][name="${fieldName}"][value="${this.value}"]`, allPageNode).prop('checked', checkedValuesArray.indexOf(this.value) !== -1);
            inputCount++;
        });
        $(`table.${customTableClass} input[type="checkbox"][name="select_all"][value="1"]`).prop('checked', inputCount === checkedValuesArray.length);
    }
    setCheckedValueV3 = (checkedValuesArray, isSelectAllCheck = false, customTableClass = 'markers-datatable') => {
        let tableNode = $(`.${customTableClass}`).DataTable();
        let allPageNode = tableNode.rows({ 'search': 'applied' }).nodes();
        let inputCount = 0;
        !!checkedValuesArray && checkedValuesArray.length > 0 && checkedValuesArray.forEach((data, key) => {
            $(`input[type="checkbox"][id="${data.fieldId}"]`, allPageNode).prop('checked', data.isChecked);
            if (data.isChecked) {
                inputCount++
            }
        })
        if (isSelectAllCheck) {
            $(`table.${customTableClass} input[type="checkbox"][name="select_all"][value="1"]`).prop('checked', inputCount === checkedValuesArray.length);
        }
    }
    getRadioValue = () => {
        let value = $("input[name='radio']:checked").val();
        return value;
    }
    storeSearchPagination = (tableClass = 'markers-datatable', paginationValue = false, searchValue = false) => {
        tablePaginationValue = !!paginationValue ? '' : $(".pagination-" + tableClass).find(".dataTables_paginate").find("ul.pagination").find("li.paginate_button.page-item.active").find('a.page-link').text();
        tableSearchValue = !!searchValue ? '' : $(".search-" + tableClass).find(".dataTables_filter").find("label").find("input[type=search]").val();
    }
    //Destroy DataTable...
    returnDestroyTable(tableClass = 'markers-datatable') {
        let table = $('.' + tableClass).DataTable();
        var allPages = table.rows({ 'search': 'applied' }).nodes();
        $('input[type="checkbox"]', allPages).prop('checked', false);
        $('input#select-all[type="checkbox"]').prop('checked', false);
        $('.' + tableClass).DataTable().destroy();
    }
    //Reset DataTable...
    returnResetTable(groupIndex = null, exportVisibility = false, orderIndex = 1, orderSequence = 'desc', headers = [], tableClass = 'markers-datatable', searchFilterFlag = 0, classObj = {}, isSortable = false, functionName = '', isSearchable = true, isExportable = true) {
        const exportFileName = !!classObj && !!classObj.exportFileName ? classObj.exportFileName : document.title
        $(document).ready(function () {
            //Clear DT Search...
            $('#btn1').click(function () {
                if (window.location.href) {
                    let filterRouteList = commonFuncObj.returnFilterRoutes();
                    let tempStr = window.location.href;
                    tempStr = tempStr.split("/");
                    if (tempStr.length > 2) {
                        let currentRoute = tempStr[tempStr.length - 2] + '/' + tempStr[tempStr.length - 1];
                        let includedRoutes = (filterRouteList.filter(str => str.includes(currentRoute)));
                        if (filterRouteList && includedRoutes && includedRoutes.length > 0) {
                            let filterDetailsArray = [];
                            if (new sessionStore().returnFilterDetails())
                                filterDetailsArray = [...new sessionStore().returnFilterDetails()];
                            let tempFilterObj = filterDetailsArray.find(o => o.routeName === currentRoute);
                            if (tempFilterObj) {
                                let tempFilterIndex = filterDetailsArray.findIndex(x => x.routeName === currentRoute);
                                tempFilterObj.searchValue = '';
                                tempFilterObj.paginationValue = 1;
                                filterDetailsArray[tempFilterIndex] = tempFilterObj;
                            }
                            new sessionStore().storeSecureData(commonConfig.SESSION_DT_FILTER_DETAILS, filterDetailsArray);
                        }
                    }
                }
                let searchField = $(".search-" + tableClass).find(".dataTables_filter_" + tableClass).find("label").find("input[type=search]");
                $(searchField).val('');
                $(searchField).trigger("input");
            });
            //End of Clear DT Search...
            //Datatable drag & drop...
            if (isSortable === true) {
                var id = null;
                window.$("." + tableClass + " tbody").sortable({
                    cursor: "move",
                    placeholder: "sortable-placeholder",
                    helper: function (e, tr) {
                        var $originals = tr.children();
                        var $helper = tr.clone();
                        $helper.children().each(function (index) {
                            window.$(this).width($originals.eq(index).width());
                        });
                        return $helper;
                    },
                    update: function (event, ui) {
                        let inputArray = $("." + tableClass).DataTable().rows().data()[ui.item.index()];
                        let endPosition = (ui.item.index() + parseInt(10 * $("." + tableClass).DataTable().page.info().page) + 1);
                        classObj[functionName](id, endPosition, inputArray);
                    },
                    start: function (event, ui) {
                        id = $("." + tableClass).DataTable().rows().data()[ui.item.index()][0];
                    }
                }).disableSelection();
            }
            //Hold DT Search...
            let tempStr = window.location.href;
            tempStr = tempStr.split("/");
            if (tempStr.length > 2 && new sessionStore().returnFilterDetails()) {
                let filterDetailsArray = new sessionStore().returnFilterDetails();
                let currentRoute = tempStr[tempStr.length - 2] + '/' + tempStr[tempStr.length - 1];
                let tempFilterObj = filterDetailsArray.find(o => o.routeName === currentRoute);
                if (tempFilterObj && Object.keys(tempFilterObj).length > 0) {
                    if (tempFilterObj.searchValue)
                        tableSearchValue = tempFilterObj.searchValue;
                    else
                        tableSearchValue = '';
                    if (tempFilterObj.paginationValue)
                        tablePaginationValue = tempFilterObj.paginationValue;
                    else
                        tablePaginationValue = '';
                }
            }
            else {
                tableSearchValue = '';
                tablePaginationValue = '';
            }

            if (!!tableSearchValue) {
                let searchField = $(".search-" + tableClass).find(".dataTables_filter_" + tableClass).find("label").find("input[type=search]");
                $(searchField).val(tableSearchValue);
                $(searchField).trigger("input");
            }
            if (!!tablePaginationValue && tablePaginationValue !== 1) {
                $(".pagination-" + tableClass).find(".dataTables_paginate").find("ul.pagination").find("li.paginate_button.page-item").find('a.page-link').filter(function () {
                    return this.innerHTML == tablePaginationValue;
                }).trigger("click");
            }
        });
        $('.' + tableClass).on('search.dt', function () {
            var filterValue = $('.dataTables_filter_' + tableClass + ' input').val();
            var paginateValue = window.$(".pagination-" + tableClass).find(".dataTables_paginate").find("ul.pagination").find("li.paginate_button.page-item.active").find('a.page-link').text();
            if (window.location.href) {
                let filterRouteList = commonFuncObj.returnFilterRoutes();
                let tempStr = window.location.href;
                tempStr = tempStr.split("/");
                if (tempStr.length > 2) {
                    let currentRoute = tempStr[tempStr.length - 2] + '/' + tempStr[tempStr.length - 1];
                    let includedRoutes = (filterRouteList.filter(str => str.includes(currentRoute)));
                    if (filterRouteList && includedRoutes && includedRoutes.length > 0) {


                        let filterDetailsArray = [];
                        if (new sessionStore().returnFilterDetails())
                            filterDetailsArray = [...new sessionStore().returnFilterDetails()];
                        let tempFilterObj = filterDetailsArray.find(o => o.routeName === currentRoute);
                        if (tempFilterObj) {
                            let tempFilterIndex = filterDetailsArray.findIndex(x => x.routeName === currentRoute);
                            // if (new sessionStore().returnRedirectDetails() === true)
                            //     new sessionStore().storeSecureData(commonConfig.SESSION_DT_REDIRECT_FLAG, false);
                            // else
                            tempFilterObj.searchValue = filterValue != null ? filterValue : tempFilterObj.searchValue ? tempFilterObj.searchValue : '';
                            tempFilterObj.paginationValue = paginateValue ? paginateValue : '';
                            // if (tempFilterObj.searchValue.length === 1)
                            // tempFilterObj.searchValue = '';
                            filterDetailsArray[tempFilterIndex] = tempFilterObj;
                        }
                        else {
                            filterDetailsArray.push({ routeName: currentRoute, tableClassName: tableClass, searchValue: filterValue ? filterValue : '', paginationValue: paginateValue ? paginateValue : '' });
                        }
                        // if (filterValue)
                        new sessionStore().storeSecureData(commonConfig.SESSION_DT_FILTER_DETAILS, filterDetailsArray);
                    }
                }
            }
        });
        //End of Hold DT Search...
        var groupColumn = groupIndex;
        var columnArr = [];
        var csvHeader = this.csvHeader;
        //Reset pagination count...
        var splitUrl = window.location.href.split("/");
        if (splitUrl.length > 0 && commonConfig.PAGINATION_ROUTES.findIndex(element => element.includes(splitUrl[splitUrl.length - 1])) === -1) {
            new sessionStore().removeSessionItem(commonConfig.SESSION_DT_PAGE_COUNT);
        }
        if (headers.length != 0) {
            //Show or hide visibility of column...
            columnArr = new Array(headers.length).fill(null);
            columnArr[0] = { "visible": false };
            this.state.table = $("." + tableClass).DataTable({
                //destroy: true,
                colReorder: true,
                bFilter: isSearchable,
                bAutoWidth: false,
                dom: "<'p-15'<'row'<'col-sm-12 col-md-6 search-" + tableClass + "'f><'col-sm-12 col-md-6 text-right'B>>><'row'<'col-sm-12'<'table-responsive'tr>>><'p-15'<'row'<'col-sm-12 col-md-6 custom-info-" + tableClass + "'><'col-sm-12 col-md-6 pagination-" + tableClass + "'p>>>",
                columns: columnArr,
                columnDefs: [{
                    'searchable': true,
                    'orderable': true,
                    'className': 'dt-body-center',
                    //"visible": false,
                    "targets": groupIndex != null ? groupColumn : ''
                }],
                "pagingType": "full_numbers",
                "displayStart": new sessionStore().returnPageCount() ? new sessionStore().returnPageCount() : 0,
                select: {
                    style: 'os',
                    selector: 'td:first-child'
                },
                order: this.defaultOrder ? [[orderIndex, orderSequence]] : [],
                "drawCallback": groupIndex != null ? function (settings) {
                    var api = this.api();
                    var rows = api.rows({ page: 'current' }).nodes();
                    var last = null;
                    api.column(groupColumn, { page: 'current' }).data().each(function (group, i) {
                        if (last !== group) {
                            $(rows).eq(i).before(
                                '<tr className="group"><td colspan="5">' + group + '</td></tr>'
                            );
                            last = group;
                        }
                    });
                }
                    :
                    ''
                ,
                "footerCallback": function (row, data, start, end, display) {
                    var table = $("." + tableClass).DataTable();
                    var info = table.page.info();
                    let totalRecords = 0;
                    let startCount = info.start;
                    new sessionStore().storeSecureData(commonConfig.SESSION_DT_PAGE_COUNT, startCount);
                    startCount = parseInt(startCount) + 1;
                    let endCount = info.end;
                    //Hold DT Search...
                    if (window.location.href) {
                        let filterRouteList = commonFuncObj.returnFilterRoutes();
                        let tempStr = window.location.href;
                        tempStr = tempStr.split("/");
                        if (tempStr.length > 2) {
                            let includedRoutes = (filterRouteList.filter(str => str.includes(tempStr[tempStr.length - 2] + '/' + tempStr[tempStr.length - 1])));
                            if (filterRouteList && includedRoutes && includedRoutes.length > 0) {
                                new sessionStore().storeSecureData(commonConfig.SESSION_DT_PAGE_END_COUNT, endCount);
                            }
                        }
                    }
                    //End of Hold DT Search...
                    if (info.recordsTotal)
                        totalRecords = info.recordsTotal;
                    $(".custom-info-" + tableClass).html('<div className="dataTables_info" role="status" aria-live="polite">Showing ' + startCount + ' to ' + endCount + ' of ' + totalRecords + ' entries</div>');
                    $('.dataTables_filter').each(function () {
                        if ($('#btn1').length === 0)
                            $(this).append('<button class="btn btn-success" type="button" id="btn1">Clear</button>');
                    });
                    $('.dataTables_filter').addClass('dataTables_filter_' + tableClass);
                },
                responsive: false,
                buttons: isExportable === true ? [{
                    extend: 'collection',
                    text: '',
                    buttons: [
                        {
                            extend: 'excel',
                            title: exportFileName,
                            exportOptions: {
                                columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                format: {
                                    body: function (data, row, column, node) {
                                        if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                            if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                return $(data).val();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                return $(node.firstElementChild).children(':selected').text()
                                            }
                                            else if ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time')) {
                                                return $(data).text();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                return '';
                                            }
                                            else {
                                                return data;
                                            }
                                        else
                                            return data;
                                    }
                                }
                            }
                        },
                        {
                            extend: 'copy',
                            title: exportFileName
                        },
                        this.isPrint ? { extend: 'print', title: exportFileName } : {},
                        {
                            extend: 'csv',
                            title: exportFileName,
                            customize: function (csv) {
                                return !!csvHeader ? csvHeader + csv : csv;
                            },
                            exportOptions: {
                                columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                format: {
                                    body: function (data, row, column, node) {
                                        if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                            if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                return $(data).val();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                return $(node.firstElementChild).children(':selected').text()
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time'))) {
                                                return $(data).text();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                return '';
                                            }
                                            else {
                                                return data;
                                            }
                                        else
                                            return data;
                                    }
                                }
                            }
                        },
                        {
                            extend: 'pdf',
                            title: exportFileName,
                            pageSize: this.pdfPageSize !== '' ? this.pdfPageSize : '',
                            orientation: this.pdfOrientation !== '' ? this.pdfOrientation : '',
                            exportOptions: {
                                columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                format: {
                                    body: function (data, row, column, node) {
                                        if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                            if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                return $(data).val();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                return $(node.firstElementChild).children(':selected').text()
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time'))) {
                                                return $(data).text();
                                            }
                                            else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                return '';
                                            }
                                            else {
                                                return data;
                                            }
                                        else
                                            return data;
                                    }
                                }
                            }
                        }
                    ]
                }
                ] : [],
                //scrollY: contentH,
                //scroller: true
            });
        }
        else {
            this.state.table = $('.' + tableClass).DataTable({
                //destroy: true,
                colReorder: true,
                bFilter: isSearchable,
                bAutoWidth: false,
                dom: "<'p-15'<'row'<'col-sm-12 col-md-6 search-" + tableClass + "'f><'col-sm-12 col-md-6 text-right'B>>><'row'<'col-sm-12'<'table-responsive'tr>>><'p-15'<'row'<'col-sm-12 col-md-6 custom-info-" + tableClass + "'><'col-sm-12 col-md-6 text-right pagination-" + tableClass + "'p>>>",
                columnDefs: [{
                    'searchable': true,
                    'orderable': true,
                    'className': 'dt-body-center',
                    //"visible": false,
                    "targets": groupIndex != null ? groupColumn : ''
                }],
                "pagingType": "full_numbers",
                'displayStart': new sessionStore().returnPageCount() ? new sessionStore().returnPageCount() : 0,
                select: {
                    style: 'os',
                    selector: 'td:first-child'
                },
                order: this.defaultOrder ? [[orderIndex, orderSequence]] : [],
                "drawCallback": groupIndex != null ? function (settings) {
                    var api = this.api();
                    var rows = api.rows({ page: 'current' }).nodes();
                    var last = null;
                    api.column(groupColumn, { page: 'current' }).data().each(function (group, i) {
                        if (last !== group) {
                            $(rows).eq(i).before(
                                '<tr className="group"><td colspan="5">' + group + '</td></tr>'
                            );
                            last = group;
                        }
                    });
                }
                    :
                    ''
                ,
                "footerCallback": function (row, data, start, end, display) {
                    var table = $("." + tableClass).DataTable();
                    var info = table.page.info();
                    let totalRecords = 0;
                    let startCount = info.start;
                    new sessionStore().storeSecureData(commonConfig.SESSION_DT_PAGE_COUNT, startCount);
                    startCount = parseInt(startCount) + 1;
                    let endCount = info.end;
                    //Hold DT Search...
                    if (window.location.href) {
                        let filterRouteList = commonFuncObj.returnFilterRoutes();
                        let tempStr = window.location.href;
                        tempStr = tempStr.split("/");
                        if (tempStr.length > 2) {
                            let includedRoutes = (filterRouteList.filter(str => str.includes(tempStr[tempStr.length - 2] + '/' + tempStr[tempStr.length - 1])));
                            if (filterRouteList && includedRoutes && includedRoutes.length > 0) {
                                new sessionStore().storeSecureData(commonConfig.SESSION_DT_PAGE_END_COUNT, endCount);
                            }
                        }
                    }
                    //End of Hold DT Search...
                    if (info.recordsTotal)
                        totalRecords = info.recordsTotal;
                    $(".custom-info-" + tableClass).html('<div className="dataTables_info" role="status" aria-live="polite">Showing ' + startCount + ' to ' + endCount + ' of ' + totalRecords + ' entries</div>');
                    $('.dataTables_filter').each(function () {
                        if ($('#btn1').length === 0)
                            $(this).append('<button class="btn btn-success" type="button" id="btn1">Clear</button>');
                    });
                    $('.dataTables_filter').addClass('dataTables_filter_' + tableClass);
                },
                responsive: false,
                buttons: isExportable === true ? searchFilterFlag === 1 ?
                    [
                        {
                            text: '<i className="icon-filter3"></i>',
                            action: function (e, dt, node, config) {
                                window.$('body').addClass('show-slidepanel-right');
                            }
                        },
                        , {
                            extend: 'collection',
                            text: '',
                            buttons: [
                                {
                                    extend: 'excel',
                                    title: exportFileName,
                                    exportOptions: {
                                        columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                        format: {
                                            body: function (data, row, column, node) {
                                                if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                                    if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                        return $(data).val();
                                                    }
                                                    else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                        return $(node.firstElementChild).children(':selected').text()
                                                    }
                                                    else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time'))) {
                                                        return $(data).text();
                                                    }
                                                    else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                        return '';
                                                    }
                                                    else {
                                                        return data;
                                                    }
                                                else
                                                    return data;
                                            }
                                        }
                                    }
                                },
                                {
                                    extend: 'copy',
                                    title: exportFileName,
                                },
                                this.isPrint ? { extend: 'print', title: exportFileName } : {},
                                {
                                    extend: 'csv',
                                    title: exportFileName,
                                    customize: function (csv) {
                                        return !!csvHeader ? csvHeader + csv : csv;
                                    },
                                    exportOptions: {
                                        columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                        format: {
                                            body: function (data, row, column, node) {
                                                if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                                    if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                        return $(data).val();
                                                    }
                                                    else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                        return $(node.firstElementChild).children(':selected').text()
                                                    }
                                                    else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time'))) {
                                                        return $(data).text();
                                                    }
                                                    else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                        return '';
                                                    }
                                                    else {
                                                        return data;
                                                    }
                                                else
                                                    return data;
                                            }
                                        }
                                    }
                                },
                                {
                                    extend: 'pdf',
                                    title: exportFileName,
                                    pageSize: this.pdfPageSize !== '' ? this.pdfPageSize : '',
                                    orientation: this.pdfOrientation !== '' ? this.pdfOrientation : '',
                                    exportOptions: {
                                        columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                        format: {
                                            body: function (data, row, column, node) {
                                                if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                                    if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                        return $(data).val();
                                                    }
                                                    else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                        return $(node.firstElementChild).children(':selected').text()
                                                    }
                                                    else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time'))) {
                                                        return $(data).text();
                                                    }
                                                    else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                        return '';
                                                    }
                                                    else {
                                                        return data;
                                                    }
                                                else
                                                    return data;
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ] :
                    [{
                        extend: 'collection',
                        text: '',
                        buttons: [
                            {
                                extend: 'excel',
                                title: exportFileName,
                                exportOptions: {
                                    columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                    format: {
                                        body: function (data, row, column, node) {
                                            if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                                if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                    return $(data).val();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                    return $(node.firstElementChild).children(':selected').text()
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time'))) {
                                                    return $(data).text();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                    return '';
                                                }
                                                else {
                                                    return data;
                                                }
                                            else
                                                return data;
                                        }
                                    }
                                }
                            },
                            {
                                extend: 'copy',
                                title: exportFileName,
                                exportOptions: {
                                    columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                    format: {
                                        body: function (data, row, column, node) {
                                            if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                                if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                    return $(data).val();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                    return $(node.firstElementChild).children(':selected').text()
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time'))) {
                                                    return $(data).text();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                    return '';
                                                }
                                                else {
                                                    return data;
                                                }
                                            else
                                                return data;
                                        }
                                    }
                                }
                            },
                            this.isPrint ? { extend: 'print', title: exportFileName } : {},
                            {
                                extend: 'csv',
                                title: exportFileName,
                                customize: function (csv) {
                                    return !!csvHeader ? csvHeader + csv : csv;
                                },
                                exportOptions: {
                                    columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                    format: {
                                        body: function (data, row, column, node) {
                                            if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                                if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                    return $(data).val();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                    return $(node.firstElementChild).children(':selected').text()
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time'))) {
                                                    return $(data).text();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                    return '';
                                                }
                                                else {
                                                    return data;
                                                }
                                            else
                                                return data;
                                        }
                                    }
                                }
                            },
                            {
                                extend: 'pdf',
                                title: exportFileName,
                                pageSize: this.pdfPageSize !== '' ? this.pdfPageSize : '',
                                orientation: this.pdfOrientation !== '' ? this.pdfOrientation : '',
                                exportOptions: {
                                    columns: exportVisibility === false ? ':visible:not(:last-child)' : '',
                                    format: {
                                        body: function (data, row, column, node) {
                                            if (data.includes("<") && data.includes(">") && data.includes("/")) //check tag or not
                                                if (data.includes("(") === false && data.includes(")") === false && $(data).is("input")) {
                                                    return $(data).val();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && $(data).is("select")) {
                                                    return $(node.firstElementChild).children(':selected').text()
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($($.parseHTML(data)[0]).is('a') || $(data).is("div:has(a)") || $(data).is("div") || $(data).is("label") || $(data).is('time'))) {
                                                    return $(data).text();
                                                }
                                                else if (data.includes("(") === false && data.includes(")") === false && ($(data).is("label:has(input[type='checkbox'])") || $(data).is("label:has(input[type='radio'])"))) {
                                                    return '';
                                                }
                                                else {
                                                    return data;
                                                }
                                            else
                                                return data;
                                        }
                                    }
                                }
                            }
                        ]
                    }] : [],
                //scrollY: contentH,
                //scroller: true
            });
        }
    }
    //Returns dynamic DataTable with optional buttons...
    returnTable(tableColumns, tableRows, classObj, editFunc = '', deleteFunc = '', dateFlag = 0, viewFunc = '', checkedValuesArray = [], cancelFunc = '', customTableClass = 'markers-datatable', redirectFunc = '', pageStartNumber = '', pageEndNumber = '') {
        var headers = tableColumns.map((value, key) =>
            <th key={key}>
                {value.header === 'checkall' || value.header === 'checkall-jquery' ?
                    <label className="fluidCheckbox mb-0">
                        <input name="select_all" className="dt-checkbox" value="1" id="select-all" type="checkbox" onClick={(e) => this.selectAll(e, customTableClass, !!classObj[value.onChangeFunction] ? () => classObj[value.onChangeFunction]() : null, !!value.excludeCheckboxClass ? value.excludeCheckboxClass : '')} />
                        <span><i></i></span>
                    </label>
                    : value.header === 'checkallnot' ? '' : langObj.returnLanguage(value.header)
                }
            </th>
        );
        var rows = null;
        var tableCaption = null;
        var table = customTableClass
        if (tableRows) {
            totalRows = tableRows.length;
        }
        pageStartNumber = pageStartNumber ? pageStartNumber : 1
        pageEndNumber = pageEndNumber ? pageEndNumber : 10
        $(".custom-info-" + customTableClass).html(totalRows >= 10 ? '<div className="dataTables_info" role="status" aria-live="polite">Showing ' + pageStartNumber + ' to ' + (new sessionStore().returnPageEndCount() ? new sessionStore().returnPageEndCount() : pageEndNumber) + ' of ' + totalRows + ' entries</div>' : totalRows > 0 ? '<div className="dataTables_info" role="status" aria-live="polite">Showing 1 to ' + (new sessionStore().returnPageEndCount() ? new sessionStore().returnPageEndCount() : totalRows) + ' of ' + totalRows + ' entries</div>' : '<div className="dataTables_info" role="status" aria-live="polite">Showing 0 of ' + totalRows + ' entries</div>');
        tableRows && tableRows.length > 0 ?
            rows = tableRows.map((data, rowIndex) =>
                <tr key={rowIndex} >
                    {tableColumns.map((value1, i) => {
                        switch (value1.type) {
                            case 'markRow':
                                return <td key={i}>
                                    <label className="fluidCheckbox mb-0">
                                        <input type="checkbox" className="dt-checkbox" name="markRow" id={`markRow_${rowIndex}`} value={rowIndex} />
                                        <span><i></i></span>
                                    </label>
                                </td>
                            case 'radiobutton':
                                return <td key={i}><label className="fluid-radio mb-0 d-flex align-items-center">
                                    <input className="hidden" value={data[value1.value]} type="radio" name="radio" />
                                    <span className="label"></span>
                                </label></td>
                            case 'checkbox':
                                let checkBoxVal = '';
                                let checkBoxName = '';
                                let checkBoxClass = '';
                                if (value1.columnName)
                                    checkBoxName = value1.columnName;
                                if (value1.inputClass)
                                    checkBoxClass = value1.inputClass;
                                value1.params.map((paramVal, i) => {
                                    checkBoxVal = checkBoxVal + data[paramVal] + value1.separator;
                                });
                                if (checkBoxVal != '')
                                    checkBoxVal = checkBoxVal.slice(0, -1);
                                return <td key={i}>{(value1.columnName === 'selectAssignmentItem' && !data.isPublished) ? null : <label className="fluidCheckbox mb-0"><input className={checkBoxClass} name={checkBoxName} value={checkBoxVal} onChange={!!classObj[value1.onChangeFunction] ? () => classObj[value1.onChangeFunction]() : null} type="checkbox" /><span><i></i></span></label>}</td>
                            case 'checkboxCalendar':
                                let checkBoxValC = '';
                                let checkBoxNameC = '';
                                let checkBoxClassC = '';
                                if (value1.columnName)
                                    checkBoxNameC = value1.columnName;
                                if (value1.inputClass)
                                    checkBoxClassC = value1.inputClass;
                                value1.params.map((paramVal, i) => {
                                    checkBoxValC = checkBoxValC + data[paramVal] + value1.separator;
                                });
                                if (checkBoxValC != '')
                                    checkBoxValC = checkBoxValC.slice(0, -1);
                                return <td key={i}>
                                    {data.id === 0 &&
                                        <label className="fluidCheckbox mb-0">
                                            <input className={checkBoxClassC} name={checkBoxNameC} value={checkBoxValC} onChange={!!classObj[value1.onChangeFunction] ? () => classObj[value1.onChangeFunction]() : null} type="checkbox" />
                                            <span><i></i></span>
                                        </label>
                                    }
                                </td>
                            case 'commaSeparatedString': return <td>{commonFuncObj.formatCommaSeparatedString(data[value1.name])}</td>
                            default:
                                return <td key={i}>{this.returnContent(value1.type, data, value1, classObj, editFunc, deleteFunc, dateFlag, viewFunc, cancelFunc, rowIndex, redirectFunc, i)}</td>
                        }
                    })}
                </tr>
            ) : rows = null;
        tableCaption = totalRows === 0 ? <caption style={{ textAlign: "center" }}>{langObj.returnLanguage('emptyDataTable')}</caption> : null;
        if (totalRows === 0) {
            $('#Empty_Table_1 tbody').empty();
            return <table id="Empty_Table_1" className={"table " + customTableClass}>
                <thead><tr>{headers}</tr></thead>
                <tbody></tbody>
                {tableCaption}
            </table>
        }
        else
            return <table className={"table " + customTableClass}>
                <thead><tr>{headers}</tr></thead>
                <tbody>{rows}</tbody>
                {tableCaption}
            </table>
    }
    //Returns dynamic content...
    returnContent(type, data, value1, classObj, editFunc, deleteFunc, dateFlag, viewFunc, cancelFunc, index, redirectFunc, parentKey) {
        let returnJsx = '';
        switch (type) {
            case 'boolean':
                const booleanTitle = !!value1.booleanTitle ? value1.booleanTitle : 'Yes|No';
                const titleArray = booleanTitle.split('|');
                returnJsx = data[value1.name] === true ? titleArray[0] : titleArray[1]
                break;
            case 'booleanStatus':
                returnJsx = data[value1.name] === true ? 'Active' : 'Inactive'
                break;
            case 'booleanSuccessFail':
                returnJsx = data[value1.name] === false ? 'Success' : 'Failure'
                break;
            case 'booleanPassFail':
                returnJsx = data[value1.name] === true ? 'Pass' : 'Fail'
                break;
            case 'holiday':
                returnJsx = data[value1.name] === true ? 'Vacation' : 'Holiday'
                break;
            case 'lmsType':
                returnJsx = data[value1.name] === 3 ? 'Both' : data[value1.name] === 1 ? 'Push' : 'Pull'
                break;
            case 'modal':
                returnJsx = <Link to="#" data-toggle="modal" data-target="#titleSchoolSessionModal">{data[value1.name]}</Link>
                break;
            case 'time':
                returnJsx = !!data[value1.name] ? commonFuncObj.changeTimeFormat(data[value1.name]) : '-'
                break;
            case 'time12h':
                returnJsx = data[value1.name] !== null ? commonFuncObj.convertTime(data[value1.name]) : ''
                break;
            case 'timeNoTimeZone':
                returnJsx = commonFuncObj.convertTime(data[value1.name])
                break;
            case 'timeField':
                let method = value1.methodName;
                returnJsx = commonFuncObj[method](data[value1.name])
                break;
            case 'timeFieldDstNonDst':
                returnJsx = commonFuncObj[value1.methodName](data[value1.name], value1.format, data.isModifiedOnDstTime)
                break;
            case 'date':
                value1.ConvertDate && value1.ConvertDate === true ?
                    returnJsx = dateFlag === 1 ? <ConvertDate calDate={data[value1.name]} /> : null
                    :
                    returnJsx = !!data[value1.name] ? <Moment format={value1.format}>{data[value1.name]}</Moment> : ''
                break;
            case 'blank':
                returnJsx = '-'
                break;
            case 'formattedText':
                let textVal = '';
                value1.params.map((paramVal, i) => {
                    textVal = textVal + data[paramVal] + value1.separator;
                });
                if (textVal != '')
                    textVal = textVal.slice(0, -1);
                returnJsx = textVal
                break;
            case 'link':
                let linkVal = '';
                let linkText = '';
                let separator = !!value1.separator ? value1.separator : ' ';
                value1.params.map((paramValue, i) => {
                    linkVal = linkVal + data[paramValue] + separator;
                });
                if (linkVal != '')
                    linkVal = linkVal.slice(0, -1);
                if (!!value1.displayFormat) {
                    linkText = data[value1.name].replace(" ", value1.displayFormat + " ");
                }
                else {
                    linkText = data[value1.name];
                }
                returnJsx = <div>
                    {redirectFunc !== '' ?
                        <Link to="#" onClick={() => classObj[redirectFunc](linkVal)}>{linkText}</Link>
                        :
                        viewFunc !== '' ?
                            <Link to="#" onClick={() => classObj[viewFunc](linkVal)}>{linkText}</Link>
                            :
                            null
                    }
                </div>
                break;
            case 'linkDownload':
                returnJsx = <div>
                    <Link to="#" onClick={() => classObj[viewFunc](data.outputFilePath, data.reportName)} className="mr-3" data-toggle="tooltip" data-placement="top" title={i18n.t("download")}><i className="icon-download2 f-s-16"></i></Link>
                </div>
                break;
            case 'multipleLink':
                returnJsx = <div className="text-nowrap">
                    {viewFunc !== '' ?
                        <div>
                            {
                                data.fileDetails !== undefined ?
                                    data.fileDetails.map((val, key) => {
                                        return <p className="mb-1"><Link to="#" data-toggle="tooltip" data-placement="top" title={i18n.t("view")} onClick={() => classObj[viewFunc](val.id, val.studentId, val.gradeId, val.markingPeriodId)} className="mr-3">{val.name + "  "}</Link></p>
                                    }) : null
                            }
                        </div>
                        : null
                    }
                </div>
                break;
            case 'linkUrlMissingAttendance':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, courseSection: data.courseSectionTitle, attendanceDate: data.attendanceDate, period: data.periodTitle, courseSectionId: data.courseSectionId, courseSectionPeriodId: data.periodId } }}><i className="icon-user-check"></i>{data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'plusIcon':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={(e) => classObj[viewFunc](e, data.id)}><i className="icon-plus3"></i></Link>
                        : null
                    }
                </div>
                break;
            case 'linkUrlMultiParamAttenDance':
                returnJsx = <div>
                    {viewFunc !== '' ?
                        <Link to="#" onClick={value1.isMissingAttendanceStudentTeacherView ? () => classObj[viewFunc](data.id) : () => classObj[viewFunc](data.gradeLevelId, data.courseSectionId, data.periodId, data.courseCodeTitle, data.gradeLevelTitle, data.periodTitle, data.attendanceDate)}><i className="icon-user-check">{data[value1.name]}</i></Link>
                        : null
                    }
                </div>
                break;
            case 'linkContentMultiParam':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { param1: data[value1.param1], paramName: data[value1.name], param2: data[value1.param2] } }}>{data[value1.name]}</Link>
                        :
                        <Link to={{ pathname: value1.linkUrl, state: { param1: data[value1.param1], paramName: data[value1.name], param2: data[value1.param2] } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'linkContent':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, assessmentId: data.assessmentId || 0, actualEndDate: data.actualEndDate || '', assessmentStartDate: data.actualStartDate || data.scheduledStartDate, assessmentEndDate: data.actualEndDate || data.scheduledEndDate, uploadOn: data.uploadOn, isActiveState: data.isActiveState } }}>{value1.separator ? data[value1.name].replace(" ", value1.separator + " ") : data[value1.name]}</Link>
                        :
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, assessmentId: data.assessmentId || 0, actualEndDate: data.actualEndDate || '', editStatus: data.isEditable, assessmentStartDate: data.actualStartDate || data.scheduledStartDate, assessmentEndDate: data.actualEndDate || data.scheduledEndDate, isActiveState: data.isActiveState } }}>{value1.separator ? data[value1.name].replace(" ", value1.separator + " ") : data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'linkGrading':
                returnJsx = <div>
                    {
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], subjectId: value1.subjectId, courseId: value1.courseId } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'linkUrlAssignmentBank':
                returnJsx = <div>
                    {
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], courseSectionId: data.courseSectionId, courseSectionTitle: data.courseSectionTitle, subjectId: data.subjectId, subjectTitle: data.subjectTitle } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'linkAssessmentTeacherGrading':
                returnJsx = <div>
                    {
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], courseId: data.courseId, assignmentId: data.assignmentId, assignmentActivityId: data.activityId, activityTitle: data.activityTitle } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'linkUrlAssignmentBankChildItem':
                returnJsx = <div>
                    {
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], courseSectionId: data.courseSectionId, courseSectionTitle: data.courseSectionTitle, subjectId: data.subjectId, subjectTitle: data.subjectTitle, assignmentBankId: data.assignmentBankId } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'takeAttendancelinkUrl':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, assessmentId: data.assessmentId || 0, actualEndDate: data.actualEndDate || '' } }}><i className="icon-user-check"></i> {data[value1.name]}</Link>
                        : null
                    }
                </div>
                break;
            case 'linkContentHoliday':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.holidayInfoId, listName: data[value1.name] } }}>{data[value1.name]}</Link>
                        :
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.holidayInfoId, listName: data[value1.name], editStatus: data.isEditable } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'linkContentAssessment':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, title: data.title, startTime: data.startTime, endTime: data.endTime, endDate: data.endDate, startDate: data.startDate } }}>{data[value1.name]}</Link>
                        :
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data.id, title: data.title, startTime: data.startTime, endTime: data.endTime, endDate: data.endDate, startDate: data.startDate, editStatus: data.isEditable } }}>{data[value1.name]}</Link>
                    }
                </div>
                break;
            case 'toggleActionButton':
                returnJsx = <div className="text-nowrap">
                    {data[value1.status] === 'Pending' && (commonConfig.SCHOOL_ROLE_ADMIN === new sessionStore().returnUserRoleID() || data[value1.user] === new sessionStore().returnUserID() || (!!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission)) ?
                        (editFunc != '' ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) : null
                    }
                    {data[value1.status] === 'Pending' && (commonConfig.SCHOOL_ROLE_ADMIN === new sessionStore().returnUserRoleID() || data[value1.user] === new sessionStore().returnUserID() || (!!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().deletePermission)) ?
                        (deleteFunc != '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) : null
                    }
                </div>
                break;
            case 'deleteButton':
                returnJsx = <div className="text-nowrap">
                    {data.isDelete === undefined ?
                        (deleteFunc != '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc != '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'statusChange':
                returnJsx = <div className="text-nowrap">
                    {data[value1.name] === 'Pending' ?
                        (editFunc != '' ? <React.Fragment><button type="button" className="btn btn-success mr-3" onClick={() => classObj[editFunc](data.id, 'approve')}>{i18n.t('approve')}</button>
                            <button type="button" className="btn btn-danger mr-3" onClick={() => classObj[deleteFunc](data.id, 'cancel')}>{i18n.t('cancel')}</button></React.Fragment>
                            : null) : <span className={data[value1.name] === 'Approved' ? 'text-success' : 'text-danger'}> {data[value1.name]}</span>
                    }
                </div>
                break;
            case 'buttonWithLink':
                returnJsx = <div className="text-nowrap">
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.id)} className="mr-3"><i className="icon-eye f-s-14"></i></Link>
                        : null
                    }
                    {data.isEditable === undefined ?
                        (editFunc != '' ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc != '' && data.isEditable) ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc != '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc != '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'buttonWithViewDelete':
                returnJsx = <div className="text-nowrap">
                    {viewFunc !== '' ?
                        <Link to="#" onClick={() => classObj[viewFunc](data.id)} className="mr-3"><i className="icon-eye f-s-14"></i></Link>
                        : null
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc != '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc != '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.id)}><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'buttonWithDownloadView':
                returnJsx = <div className="text-nowrap">
                    {viewFunc !== '' ?
                        <Link to="#" data-toggle="tooltip" data-placement="top" title={i18n.t("view")} onClick={() => classObj[viewFunc](data.id)} className="mr-3"><i className="icon-eye f-s-14"></i></Link>
                        : null
                    }
                    {viewFunc !== '' ?
                        <Link to="#" className="mr-3" data-toggle="tooltip" data-placement="top" title={i18n.t("download")}><i className="icon-download2 f-s-14"></i></Link>
                        : null
                    }
                </div>
                break;
            case 'buttonWithUpload':
                returnJsx = <div className="text-nowrap">
                    {redirectFunc !== '' ?
                        <Link to="#" className="mr-3" data-toggle="tooltip" data-placement="top" title={i18n.t("upload")} onClick={() => classObj[redirectFunc](data.id)}><i className="icon-upload2 f-s-14"></i></Link>
                        : null
                    }
                </div>
                break;
            case 'button':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        (editFunc != '' ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc != '' && data.isEditable) ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc != '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc != '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.id)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                    {data.lookupIncidentStatusTitle !== "Cancelled" && data.lookupIncidentStatusTitle !== "Closed" && data.lookupIncidentStatusTitle !== "Action Taken" ?
                        (cancelFunc !== '' ? <Link to="#" onClick={() => classObj[cancelFunc](data.id)} className="mr-3"><i className="icon-blocked"></i></Link> : null) : null
                    }
                </div>
                break;
            case 'onlyEditButton':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        (editFunc != '' && data.id > 0 ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc != '' && data.isEditable) ? <Link to="#" onClick={() => classObj[editFunc](data.id)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'viewButton':
                returnJsx = <div>
                    <Link to="#" onClick={() => classObj[editFunc](data.reportingCode)} className="mr-3" data-toggle="tooltip" data-placement="top" title={i18n.t('viewHistory')}><i className="icon-database-time f-s-20"></i></Link>
                </div>
                break;
            case 'buttonStudentDiscipline':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        (editFunc != '' ? <Link to="#" onClick={() => classObj[editFunc](data.id, data.disciplineId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc != '' && data.isEditable) ? <Link to="#" onClick={() => classObj[editFunc](data.id, data.disciplineId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc != '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.disciplineId)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc != '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.disciplineId)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'buttonHoliday':
                returnJsx = <div>
                    {data.isEditable === undefined ?
                        (editFunc != '' ? !!data.holidayListId ? !!data.fromDate && (data.fromDate.slice(0, 10) > new Date().toISOString().slice(0, 10)) ? <Link to="#" onClick={() => classObj[editFunc](data.holidayListId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null : <Link to="#" onClick={() => classObj[editFunc](data.holidayInfoId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null) :
                        ((editFunc != '' && data.isEditable) ? !!data.holidayListId ? !!data.fromDate && (data.fromDate.slice(0, 10) > new Date().toISOString().slice(0, 10)) ? <Link to="#" onClick={() => classObj[editFunc](data.holidayListId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null : <Link to="#" onClick={() => classObj[editFunc](data.holidayInfoId)} className="mr-3"><i className="icon-pencil4 f-s-14"></i></Link> : null)
                    }
                    {data.isDelete === undefined ?
                        (deleteFunc != '' ? <Link to='#' onClick={() => classObj[deleteFunc](data.holidayInfoId || data.holidayListId)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null) :
                        ((deleteFunc != '' && data.isDelete) ? <Link to="#" onClick={() => classObj[deleteFunc](data.holidayInfoId || data.holidayListId)} className="mr-3"><i className="icon-cross f-s-14"></i></Link> : null)
                    }
                </div>
                break;
            case 'status':
                returnJsx = commonFuncObj.formatStatus(data[value1.name], !!value1.customDeclineColor ? value1.customDeclineColor : null, !!value1.customDeclineTextFormat ? value1.customDeclineTextFormat : null)
                break;
            case 'colorField':
                returnJsx = <div style={{ color: value1.colorCode }}> {data[value1.name]} </div>
                break;
            case 'colorFieldWithCondition':
                returnJsx = <div style={{ color: !!data[value1.isColorCode] ? value1.colorCode : null }}> {data[value1.name]} </div>
                break;
            case 'transcript-dropdown':
                returnJsx = <select className="custom-select transcript" name="lookupPublicationStatus">
                    <option value={'9502|' + data.studentId}>Draft</option>
                    {data.publicationStatus && data.publicationStatus.length > 0 ?
                        data.publicationStatus.map((val, key) => {
                            return <option key={key} value={val.id + "|" + val.studentId}>{val.title}</option>
                        }) : ''
                    }
                </select>
                break;
            case 'selectField':
                let inputValue = data[value1.name]
                if (!!value1.default && data[value1.name] === 0)
                    inputValue = value1.default
                let displayValue = value1.subField.displayOn.indexOf(inputValue);
                returnJsx = <React.Fragment>
                    <select className="custom-select" onChange={(e) => classObj.handleChange(e, index)} name={value1.name} value={inputValue}>
                        {!!classObj.props && !!classObj.props[value1.selectArray] && classObj.props[value1.selectArray] !== 0 ?
                            classObj.props[value1.selectArray].map((val, key) => {
                                return <option value={val.id}>{val.title}</option>
                            }) : ''
                        }
                    </select>
                    {displayValue === 0 ?
                        <textarea type="text" id={`${value1.subField.name}_${index}`} name={`${value1.subField.name}_${index}`} onChange={(e) => classObj.handleChange(e, index)} className="form-control" placeholder={!!value1.subField.title ? value1.subField.title : i18n.t(value1.subField.name)}></textarea>
                        : ''
                    }
                </React.Fragment>
                break;
            case 'dateField':
                returnJsx = <MarkersDatepicker name={value1.name} value={data[value1.name]} onChange={(e) => classObj[value1.functionName](e, index)} isRequired={!!value1.isRequired} onlyField={true} />
                break;
            case 'fileDownload':
                returnJsx = !!data.fileId && data.fileId !== 0 ? <Link to="#" onClick={() => classObj.fileDownload(data.fileId)}>{data[value1.name]}</Link> : ''
                break;
            case 'perpetratorName':
                let perpetratorName = '-';
                if (!!data.internalPerpetratorName && !!data.externalPerpetratorName) {
                    perpetratorName = `${data.internalPerpetratorName}, ${data.externalPerpetratorName}`;
                } else if (!!data.internalPerpetratorName) {
                    perpetratorName = `${data.internalPerpetratorName}`;
                } else if (!!data.externalPerpetratorName) {
                    perpetratorName = `${data.externalPerpetratorName}`;
                }
                returnJsx = `${perpetratorName}`
                break;
            case 'buttonV2':
                returnJsx = <div>
                    {!!value1.properties && Array.isArray(value1.properties) ? value1.properties.map((propsData, key) => {
                        const paramsArray = !!propsData.params ? (Array.isArray(propsData.params) ? [propsData.params.map((paramIndex) => { return data[paramIndex] })] : [data[propsData.params]]) : [data.id]
                        let linkAttr = { to: "#", className: "mr-3" }
                        if (!!propsData.functionName && typeof classObj[propsData.functionName] === "function" && paramsArray.length !== 0) {
                            linkAttr = { ...linkAttr, onClick: paramsArray.length === 1 ? () => classObj[propsData.functionName](paramsArray[0]) : () => classObj[propsData.functionName](...paramsArray) }
                        }
                        if (propsData.hasOwnProperty('tooltip') && !!propsData.tooltip)
                            linkAttr = { ...linkAttr, 'data-toggle': "tooltip", 'data-placement': "top", 'title': i18n.t(propsData.tooltip) }
                        switch (propsData.icon) {
                            case 'edit':
                                return !!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission && (!data.hasOwnProperty('isEditable') || (data.hasOwnProperty('isEditable') && data.isEditable)) ?
                                    <Link key={`${parentKey}_${key}`} {...linkAttr}><i className="icon-pencil4 f-s-14"></i></Link> : null
                            case 'delete':
                                if (propsData.hasOwnProperty('conditionFunctionName') && !!propsData.conditionFunctionName && typeof classObj[propsData.conditionFunctionName] === "function") {
                                    let deleteFlag = classObj[propsData.conditionFunctionName]({ ...data })
                                    return !!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().deletePermission && (!data.hasOwnProperty('isDelete') || (data.hasOwnProperty('isDelete') && data.isDelete)) && deleteFlag ?
                                        <Link {...linkAttr}><i className="icon-cross f-s-14"></i></Link> : null
                                }
                                else {
                                    return !!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().deletePermission && (!data.hasOwnProperty('isDelete') || (data.hasOwnProperty('isDelete') && data.isDelete)) ?
                                        <Link key={`${parentKey}_${key}`} {...linkAttr}><i className="icon-cross f-s-14"></i></Link> : null
                                }
                            case 'view':
                                return <Link {...linkAttr}><i className="icon-eye f-s-14"></i></Link>
                            default:
                                if (propsData.hasOwnProperty('disableLink')) {
                                    if (!!data[propsData.disableLink]) {
                                        return <span className="mr-3"><i className={`icon-${propsData.icon} f-s-14`}></i></span>
                                    }
                                    else {
                                        return <Link key={`${parentKey}_${key}`} {...linkAttr}><i className={`icon-${propsData.icon} f-s-14`}></i></Link>
                                    }
                                }
                                else
                                    return <Link key={`${parentKey}_${key}`} {...linkAttr}><i className={`icon-${propsData.icon} f-s-14`}></i></Link>
                        }
                    }) : null}
                </div>
                break;
            case 'buttonV2WithLink':
                returnJsx = <div>
                    {!!value1.properties && Array.isArray(value1.properties) ? value1.properties.map((propsData) => {
                        const paramsArray = !!propsData.params ? (Array.isArray(propsData.params) ? [propsData.params.map(paramIndex => { return data[paramIndex] })] : [data[propsData.params]]) : [data.id]
                        let linkAttr = "";
                        let stateObj = {};
                        if (propsData.hasOwnProperty('urlStateIndex') && !!propsData.urlStateIndex && propsData.hasOwnProperty('urlStateValues') && !!propsData.urlStateValues && propsData.hasOwnProperty('linkVisibility') && !!propsData.linkVisibility && data[propsData.linkVisibility]) {
                            let urlStateIndex = propsData.urlStateIndex.split(",");
                            let urlStateValues = propsData.urlStateValues.split(",");
                            for (let i = 0; i < urlStateIndex.length; i++) {
                                stateObj[urlStateIndex[i]] = data[urlStateValues[i]];
                            }
                        }
                        if (propsData.hasOwnProperty('linkUrl') && !!propsData.linkUrl)
                            linkAttr = { to: { pathname: propsData.linkUrl, state: Object.keys(stateObj).length > 0 ? stateObj : {} }, className: "mr-3" }
                        else
                            linkAttr = { to: "#", className: "mr-3" }
                        if (!!propsData.functionName && typeof classObj[propsData.functionName] === "function" && paramsArray.length !== 0) {
                            linkAttr = { ...linkAttr, onClick: paramsArray.length === 1 ? () => classObj[propsData.functionName](paramsArray[0]) : () => classObj[propsData.functionName](...paramsArray) }
                        }
                        if (propsData.hasOwnProperty('tooltip') && !!propsData.tooltip)
                            linkAttr = { ...linkAttr, 'data-toggle': "tooltip", 'data-placement': "top", 'title': propsData.tooltip }
                        switch (propsData.icon) {
                            case 'edit':
                                return !!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().editPermission && (!data.hasOwnProperty('isEditable') || (data.hasOwnProperty('isEditable') && data.isEditable)) ?
                                    <Link {...linkAttr}><i className="icon-pencil4 f-s-14"></i></Link> : null
                            case 'delete':
                                return !!new sessionStore().returnSessionCurrentPagePermission() && new sessionStore().returnSessionCurrentPagePermission().deletePermission && (!data.hasOwnProperty('isDelete') || (data.hasOwnProperty('isDelete') && data.isDelete)) ?
                                    <Link {...linkAttr}><i className="icon-cross f-s-14"></i></Link> : null
                            case 'view':
                                return <Link {...linkAttr}><i className="icon-eye f-s-14"></i></Link>
                            default:
                                if (propsData.hasOwnProperty('linkVisibility') && !!propsData.linkVisibility && data[propsData.linkVisibility] && propsData.icon)
                                    return <Link {...linkAttr}><i className={`icon-${propsData.icon} f-s-14`}></i></Link>
                                else if (propsData.hasOwnProperty('linkVisibility') && !!propsData.linkVisibility && data[propsData.linkVisibility] && propsData.linkText && !propsData.hasOwnProperty('linkDisable'))
                                    return <Link {...linkAttr}>{propsData.linkText}</Link>
                                else if (propsData.hasOwnProperty('linkVisibility') && !!propsData.linkVisibility && data[propsData.linkVisibility] && propsData.linkText && (propsData.hasOwnProperty('linkDisable') && !data[propsData.linkDisable]))
                                    return <Link {...linkAttr}>{propsData.linkText}</Link>
                                else if (propsData.hasOwnProperty('linkVisibility') && !!propsData.linkVisibility && data[propsData.linkVisibility] && propsData.linkText && (propsData.hasOwnProperty('linkDisable') && data[propsData.linkDisable]))
                                    return <span>{propsData.linkText}</span>
                                else
                                    return null
                        }
                    }) : null}
                </div>
                break;
            case 'linkV2':
                returnJsx = <div>
                    {!!value1.properties && Array.isArray(value1.properties) ? value1.properties.map((propsData) => {
                        let linkAttr = { to: "#", className: "mr-3" }
                        if (!!propsData.isRowPassAsParams && !!propsData.functionName && typeof classObj[propsData.functionName] === "function") {
                            linkAttr.onClick = () => classObj[propsData.functionName](data, index);
                        } else {
                            const paramsArray = !!propsData.params ? (Array.isArray(propsData.params) ? [propsData.params.map(paramIndex => { return data[paramIndex] })] : [data[propsData.params]]) : [data.id];
                            if (!!propsData.functionName && typeof classObj[propsData.functionName] === "function" && paramsArray.length !== 0) {
                                linkAttr = { ...linkAttr, onClick: paramsArray.length === 1 ? () => classObj[propsData.functionName](paramsArray[0]) : () => classObj[propsData.functionName](...paramsArray) }
                            }
                        }
                        return <Link {...linkAttr}>{!!propsData.columnName && !!data[propsData.columnName] ? data[propsData.columnName] : propsData.text}</Link>
                    }) : null}
                </div>
                break;
            case 'days':
                returnJsx = commonFuncObj.convertNumberListToSpacedDaysOfTheWeek(data.workingDays);
                break;
            case 'linkContentOrButton':
                returnJsx = <div>
                    {!data.isEditable ?
                        <Link to={{ pathname: value1.linkUrl, state: { listId: data[value1.parentId], listName: data[value1.name], markingPeriodId: data.markingPeriodId || 0, assessmentId: data.assessmentId || 0, actualEndDate: data.actualEndDate || '', editStatus: data.isEditable } }}>{value1.separator ? data[value1.name].replace(" ", value1.separator + " ") : data[value1.name]}</Link>
                        :
                        <Link to={{ pathname: value1.linkEditUrl, state: { listId: data[value1.parentId], listName: data[value1.studentName], editStatus: data.isEditable, isEditHealthVisit: true } }}><i className="icon-pencil4 f-s-14"></i></Link>
                    }
                </div>
                break;
            case 'activeInactive':
                returnJsx = !!data[value1.name] ? "Active" : "Inactive"
                break;
            case 'textShortener':
                returnJsx = <div className="text-nowrap">
                    {commonFuncObj.textShortener(data[value1.name], 60)}
                </div>
                break;
            case 'customRow':
                returnJsx = !!value1.functionName && typeof classObj[value1.functionName] === "function" ? !!value1.columnName ? classObj[value1.functionName]({ ...data }, value1.columnName) : !!value1.isIncludeIndex ? classObj[value1.functionName]({ ...data }, index) : classObj[value1.functionName]({ ...data }) : null
                break;
            case 'buttonV3':
                returnJsx = <div>
                    {!!value1.properties && Array.isArray(value1.properties) ? value1.properties.map((propsData) => {
                        const paramsArray = !!propsData.params ? (Array.isArray(propsData.params) ? [propsData.params.map(paramIndex => { return data[paramIndex] })] : [data[propsData.params]]) : [data.id]
                        let linkAttr = { to: "#", className: "mr-3" }
                        if (!!propsData.functionName && typeof classObj[propsData.functionName] === "function" && paramsArray.length !== 0) {
                            linkAttr = { ...linkAttr, onClick: paramsArray.length === 1 ? () => classObj[propsData.functionName](paramsArray[0]) : () => classObj[propsData.functionName](...paramsArray) }
                        }
                        switch (propsData.icon) {
                            case 'play':
                                return <Link {...linkAttr}><i className="icon-play4 f-s-14"></i></Link>
                            case 'retake':
                                return <Link {...linkAttr}><i className="icon-reset f-s-14"></i></Link>
                            default:
                                return <Link {...linkAttr}><i className={`icon-${propsData.icon} f-s-14`}></i></Link>
                        }
                    }) : null}
                </div>
                break;
            case 'customRowWithIndex':
                returnJsx = !!value1.functionName && typeof classObj[value1.functionName] === "function" ? classObj[value1.functionName]({ ...data }, index) : null
                break;
            default:
                returnJsx = !!data[value1.name] ? data[value1.name] : commonConfig.DASH_VALUE
        }
        return returnJsx;
    }
}
export default dataTable;
