import { updateObject } from '../../../shared/store/utility';
import { camelCase } from 'lodash';
import commonConfig from '../../../common/commonConfig';
const initialState = {
    staffList: [],
    staffMembersList: [],
    getStaffDemographicInfo: {},
    getStaffInfo: {},
    saveStaffDemographicInfo: false,
    getStaffCertificate: {},
    staffCertificateList: [],
    saveStaffCertificate: false,
    staffJobCertificationList: [],
    saveStaffCertificateVerification: {},
    staffCertificateVerificationList: [],
    saveStaffPersonalDocumentVerification: false,
    getStaffPersonalDocumentVerification: {},
    staffPersonalDocumentVerificationList: [],
    staffContactInfoList: [],
    getStaffContactInfo: {},
    saveStaffContactInfo: false,
    saveStaffSchoolRoleAssignment: false,
    staffSchoolRoleAssignmentList: [],
    getStaffSchoolRoleAssignment: {},
    staffJobList: [],
    saveStaffJob: false,
    getStaffJob: {},
    staffJobCategoryList: [],
    staffJobCategoriesList: [],
    saveStaffEmploymentInfo: false,
    getStaffEmploymentInfo: {},
    saveStaffJobCertification: false,
    getStaffJobCertification: {},
    staffPersonalCredentialList: [],
    saveStaffPersonalCredential: false,
    getStaffPersonalCredential: {},
    saveStaffVisa: false,
    getStaffVisa: {},
    staffVisaList: [],
    staffCalendarList: [],
    saveStaffCalendarEvent: false,
    saveStaffCredentialVerifyCertify: {},
    staffCalendarEventList: [],
    getStaffCalendarEvent: {},
    saveStaffCredential: false,
    getStaffCredential: {},
    saveStaffLeave: false,
    staffLeaveList: [],
    getStaffLeave: {},
    staffLeaveRequestList: [],
    getStaffPersonalDocumentVerification: {},
    staffDocumentVerificationList: [],
    saveStaffJobCategory: false,
    getStaffJobCategory: {},
    saveStaffPersonalDocumentVerify: false,
    staffTeacherList: [],
    staffCredentialVerificationList: [],
    getStaffTeachingExperience: {},
    saveStaffTeachingExperience: false,
    saveStaffAuthorization: false,
    getStaffAuthorization: {},
    staffHolidayStaffList: [],
    getStaffCode: {},
    saveStaffIdentifier: false,
    getStaffIdentifier: {},
    getStaffDefaultIdentifier: {},
    staffIdentifierList: [],
    getStaffMenuData: {},
    getStaffMiscSkills: {},
    saveStaffMiscSkills: false,
    staffCredentialList: [],
    staffPersonalTypeCredentialList: [],
    staffTypeCredentialList: [],
    getStaffIdCard: {},
    saveStaffIdCard: false,
    getStaffGeneratedIdCard: {},
    staffGenerateedIdCardList: [],
    staffUserAccessList: [],
    saveStaffUserAccess: false,
    getStaffDependencies: null,
    staffAllRecordedByList: [],
}
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
}
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const reducerStaff = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'STAFF_LIST': return handleSuccess(state, action, camelCase(`staff_${action.title}_list`));
        case 'STAFF_SAVE': return handleSuccess(state, action, camelCase(`save_staff_${action.title}`));
        case 'STAFF_GET': return handleSuccess(state, action, camelCase(`get_staff_${action.title}`));
        case 'STAFF_DELETE': return handleSuccess(state, action, camelCase(`delete_staff_${action.title}`));
        case commonConfig.STAFF_STORE_RESET: return handleSuccess(state, action, action.title);
        case 'HANDLE_STAFF_FAIL': return handleFail(state, action);
        default: return state;
    }
};
export default reducerStaff;