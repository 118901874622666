export class commonCourseConfig {
    static COMPONENT_COURSE = 'course';
    static COMPONENT_SCHEDULE = 'schedule';
    static METHOD_TYPE_POST = 'POST';
    static METHOD_TYPE_GET = 'GET';
    //Subject
    static COMPONENT_COURSE_SUBJECT_SAVE = 'v1/coursesubjectsoffered/savecoursesubjectsoffered';
    static COMPONENT_COURSE_SUBJECT_AREA_GET = 'v1/coursesubjectsoffered/getsubjectarea';
    static COMPONENT_COURSE_ACADEMIC_SUBJECT_GET = 'v1/coursesubjectsoffered/getacedemicsubjects';
    static COMPONENT_COURSE_SUBJECT_GET_BY_ID = 'v1/coursesubjectsoffered/getcoursesubjectsofferedbyid';
    static COMPONENT_COURSE_GENERAL_VIEW_GET = 'v1/coursesubjectsoffered/getcoursesubjectgeneralview?schoolId=';
    static COMPONENT_COURSE_GENERAL_VIEW_GET_V2 = 'v2/coursesubjectsoffered/getcoursesubjectgeneralview?schoolId=';
    static COMPONENT_COURSE_TREE_VIEW_GET = 'v1/coursesubjectsoffered/getcoursemanagertreeview?schoolId=';
    static COMPONENT_COURSE_SUBJECT_DELETE = 'v1/coursesubjectsoffered/deletecoursesubjectsoffered';
    static COMPONENT_COURSE_GET_COURSE_SUBJECT_VIEW = 'v1/coursesubjectsoffered/getcoursesubjectgeneralview?';
    //Course
    static COMPONENT_COURSE_SAVE = 'v1/courseinfo/savecourseinfo';
    static COMPONENT_COURSE_GET_BY_ID = 'v1/courseinfo/getcourseinfobyid';
    static COMPONENT_COURSE_SUBJECT_UPDATE = 'v1/coursesubjectsoffered/updatecoursesubjectsoffered';
    static COMPONENT_COURSE_UPDATE = 'v1/courseinfo/updatecourseinfo';
    static COMPONENT_COURSE_DELETE = 'v1/courseinfo/deletecourseinfo';
    //course section
    static COMPONENT_GET_ALL_COURSE_PART_BY_COURSE_ID = 'v1/courseparts/getbycourseid?courseId=';
    static COMPONENT_COURSE_SECTION_SAVE = 'v1/coursesection/savecoursesection';
    static COMPONENT_COURSE_SECTION_UPDATE = 'v1/coursesection/updatecoursesection';
    static COMPONENT_COURSE_SECTION_GET_BY_ID = 'v1/coursesection/getcoursesectionbyid?id=';
    static COMPONENT_COURSE_SECTION_DELETE = 'v1/coursesection/deletecoursesection';
    static COMPONENT_COURSE_SECTION_GET_LANGUAGES = 'v1/coursesection/getlanguagesforcoursesection';
    static COMPONENT_COURSE_SECTION_GET_ALL_BY_FILTER = 'v1/coursesection/getcoursesectionbyfilter';
    static COMPONENT_COURSE_GRADE_LEVEL_ID = 'v1/coursesection/GetGradeLevelsByCourse?';
    static COMPONENT_COURSE_SECTION_GET_BY_DEPARTMENT_ID = 'v1/coursesection/getallcoursesectionbydepartmentid'
    static COMPONENT_COURSE_SECTION_GET_BY_GRADE_ID = 'v1/coursesection/getallcoursesectionbygradelevelid';
    static COMPONENT_COURSE_SECTION_GET_BY_COURSE_ID = 'v1/communication/getallcoursesectionbycourseid';
    //course section level
    static COMPONENT_COURSE_SECTION_LEVEL_SAVE = 'v1/coursesectionlevel/savecoursesectionlevel';
    static COMPONENT_COURSE_SECTION_LEVEL_UPDATE = 'v1/coursesectionlevel/updatecoursesectionlevel';
    static COMPONENT_COURSE_SECTION_LEVEL_GET = 'v1/coursesectionlevel/getcoursesectionlevelbycoursesectionid?id=';
    static COMPONENT_COURSE_SECTION_LEVEL_GET_BY_ID = 'v1/coursesectionlevel/getcoursesectionlevelbyid';
    static COMPONENT_COURSE_SECTION_LEVEL_DELETE = 'v1/coursesectionlevel/deletecoursesectionlevel';
    //get all subject
    static COMPONENT_GET_ALL_SUBJECT = 'v1/coursesubjectsoffered/getallcoursesubjectsoffered';
    //get course by subject id
    static COMPONENT_GET_ALL_COURSE_BY_SUBJECT_ID = 'v1/courseinfo/getcoursesbysubjectid?SubjectId=';
    //course section occurence
    static COMPONENT_GET_ALL_COURSE_SECTION_OCCURENCE = 'v1/coursesectionperiod/getcoursesectionperiodbysectionid?id=';
    static COMPONENT_GET_COURSE_SECTION_OCCURENCE_BY_ID = 'v1/coursesectionperiod/getcoursesectionperiodbyid?id=';
    static COMPONENT_SAVE_COURSE_SECTION_OCCURENCE = 'v1/coursesectionperiod/savecoursesectionperiod';
    static COMPONENT_UPDATE_COURSE_SECTION_OCCURENCE = 'v1/coursesectionperiod/updatecoursesectionperiod';
    static COMPONENT_DELETE_COURSE_SECTION_OCCURENCE = 'v1/coursesectionperiod/deletecoursesectionperiod';
    //course section schedule
    static COMPONENT_COURSE_SECTION_PERIOD_SCHEDULE = 'v1/coursesectionperiodschedule/savecoursesectionperiodschedule';
    static COMPONENT_UPDATE_COURSE_SECTION_PERIOD_SCHEDULE = 'v1/coursesectionperiodschedule/updatecoursesectionperiodschedule';
    static COMPONENT_GET_COURSE_SECTION_PERIOD_SCHEDULE = 'v1/coursesectionperiodschedule/getcoursesectionperiodschedulebycoursesectionid?id=';
    static COMPONENT_COURSE_COURSE_LIST = 'v1/courseinfo/getcourselistbyfilter?=';
    static COMPONENT_COURSE_COURSE_INFO_LIST = 'v1/courseinfo/getcourseinfolistbyfilter?';
    static COMPONENT_GET_TEACHER_LIST_BY_COURSE_SECTION_ID = 'v1/coursesectionperiodscheduleavailteacher/getcoursesectionperiodscheduleavailteacherbycoursesection?cspScheduleId=';
    static COMPONENT_GET_COURSE_SECTION_SCHEDULED_STUDENT = 'v1/coursesection/getscheduledstudents?courseSectionId=';
    static COMPONENT_GET_COURSE_SECTION_SCHEDULED_STUDENT_SECTION_GRADEWISE = 'v1/coursesection/getscheduledstudentssectiongradewise?courseSectionId=';
    //course Teachers
    static COMPONENT_GET_TEACHER_LIST_BY_COURSE_ID = 'v1/coursesectionperiodschedule/getteacherlistbycourseid?courseId=';
    //course Marking Periods
    static COMPONENT_GET_MARKING_PERIOD_LIST_BY_COURSE_ID = 'v1/coursesection/getmarkingperiodbycourseid?courseId=';
    //course Study Material
    static COMPONENT_SAVE_COURSE_STUDY_MATERIAL = 'v1/coursestudymaterial/savecoursestudymaterialinfo';
    static COMPONENT_UPDATE_COURSE_STUDY_MATERIAL = 'v1/coursestudymaterial/updatecoursestudymaterialinfo';
    static COMPONENT_GET_COURSE_STUDY_MATERIAL_BY_COURSE_ID = 'v1/coursestudymaterial/getstudymaterialbycourseid?';
    static COMPONENT_DELETE_STUDY_MATERIAL = 'v1/coursestudymaterial/deletestudymaterial';
    static COMPONENT_ACTIVE_INACTIVE_STUDY_MATERIAL = 'v1/coursestudymaterial/activeinactivestudymaterial';
    static COMPONENT_MASS_DELETE_STUDY_MATERIAL = 'v1/coursestudymaterial/deletemultiplestudymaterial';
    //course section Study Material
    static COMPONENT_SAVE_COURSE_SECTION_STUDY_MATERIAL = 'v1/coursesectionstudymaterial/savecoursesectionstudymaterialinfo';
    static COMPONENT_UPDATE_COURSE_SECTION_STUDY_MATERIAL = 'v1/coursesectionstudymaterial/updatecoursesectionstudymaterialinfo';
    static COMPONENT_GET_COURSE_SECTION_STUDY_MATERIAL_BY_COURSE_SECTION_ID = 'v1/coursesectionstudymaterial/getstudymaterialbycoursesectionid?';
    //study material
    static COMPONENT_DEFAULT_SUBJECTLIST = 'v1/coursesubjectsoffered/getallsubjectsbystudentid?';
    static COMPONENT_DEFAULT_COURSELIST = 'v1/courseinfo/getallcoursebystudent?';
    static COMPONENT_DEFAULT_COURSE_SECTIONLIST = 'v1/coursesection/getallcoursesectionbystudentid?'
    static COMPONENT_GETALL_COURSE = 'v1/coursestudymaterial/getcoursemateriallistbystudentid?';
    static COMPONENT_COURSE_GET_ALL_COURSE_COURSESECTION_MATERIAL = 'v1/coursestudymaterial/getallcoursematerial?';
    //SMS Text Messages
    static COMPONENT_GET_ALL_COURSE_BY_SUBJECT_IDS = 'v1/courseinfo/getcoursesbysubjectids?SubjectIds=';
    static COMPONENT_GET_ALL_COURSE_SECTION_BY_COURSE_IDS = 'v1/courseinfo/getcoursesectionbycourseids?CourseIds=';
    //SMS offer 
    static COMPONENT_DEFAULT_COURSE_SECTIONLIST_TWO = 'v1/coursesection/getallcoursesectionbystudentid?';
}
export default commonCourseConfig;