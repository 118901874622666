export class commonConfig {
    static DASH_VALUE = "--";
    static YES_VALUE = "Y";
    static NO_VALUE = "N";
    static METHOD_TYPE_POST = "POST";
    static METHOD_TYPE_GET = "GET";
    static TEXUS_STATE_CODE = "TX";
    static USA_COUNTRY_CODE = "US";
    static NEWMAXICO_STATE_CODE = "NM";
    static COMPONENT_LOGIN = 'authentication';
    static COMPONENT_CONTROLPANEL = 'controlpanel';
    static COMPONENT_SCHOOL = 'school';
    static COMPONENT_EDFI = 'edfi';
    static EDFI_KEY = 'EDFI_CONFIGURATION';
    static EDFI_SCHEDULER_KEY = 'SCHEDULER_CONFIGURATION';
    static PHONE_TYPE_FAX = 904;
    static PHONE_TYPE_WORK_PHONE = 901;
    static PHONE_TYPE_FAX_NM = 106604;
    static PHONE_TYPE_WORK_PHONE_NM = 106608;
    static COMPONENT_LOGIN_VALIDATE_SAVE = 'v1/authentication/authenticate';
    static COMPONENT_REGENARATE_TOKEN = 'v1/authentication/regenaratetoken';
    static COMPONENT_LOGIN_SSO_VALIDATE_SAVE = 'v1/authentication/authenticatessouser';
    static COMPONENT_GET_PROFILE_PICTURE_BY_USER_ID = 'v1/resource/getprofilepicbyuserid?id=';
    static COMPONENT_RESOURCE = 'resource';
    static COMPONENT_STATEREPORTING = 'statereporting';
    static COMPONENT_GET_RECEIVE_MESSAGES_LIST = 'v1/communication/getallreceivemessagesbyroleid?roleid=';
    static COMPONENT_RESOURCE_GET_USER_LAST_SESSION = 'v1/appmenu/GetLastSessionOfUser';
    static COMPONENT_RESOURCE_SET_USER_LAST_SESSION = 'v1/appmenu/SetLastSessionOfUser';
    static COMPONENT_RESOURCE_UPDATE_USER_PREFERENCE = 'v1/userpreference/updateuserpreference';
    static COMPONENT_RESOURCE_GET_USER_PREFERENCE_OTP = 'v1/userpreference/sendresetpassswordotp';
    static COMPONENT_RESOURCE_FORGOT_PASSWORD = 'v1/userpreference/forgotpassword';
    static COMPONENT_RESOURCE_CONFIRM_OTP = 'v1/userpreference/forgotpasswordconfirmation';
    static COMPONENT_GET_PASSWORD_PATTERN_BY_USER_ID = 'v1/userpreference/getbyuserid';
    static COMPONENT_RESOURCE_RESET_PASSWORD = 'v1/userpreference/resetpassword';
    static COMPONENT_RESOURCE_GET_LOOKUPS = 'v1/lookup/GetByLookupCategory';
    static COMPONENT_RESOURCE_GET_COUNTRY = 'v1/lookup/GetAllCountryList';
    static COMPONENT_SAVE_FILE = 'v1/filemanager/upload';
    static COMPONENT_GET_MENUS = 'v1/appmenu/getusermodulesandmenus?schoolid=';
    static COMPONENT_GET_USER_MENUS = 'v1/appmenu/getusermoduledetails?schoolid=';
    static COMPONENT_GET_SCHOOL_BY_USERID = 'v1/schoolrole/getschoolbyuser?userId=';
    //static COMPONENT_GET_NOTIFICATIONS = 'v1/notification/getMessages?schoolid=';
    static COMPONENT_MARKASREAD_NOTIFICATIONS = 'v1/notification/markAsRead';
    static COMPONENT_DOWNLOAD_FILE = 'v1/resource/downloadfile?fileInfoId=';
    static COMPONENT_DOWNLOAD_EMAIL_FILE = 'v1/adminsendemail/downloadattachmentfile?fileId=';
    static COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY = 'v1/lookup/getallstatebycountry?countryCode=';
    static COMPONENT_RESOURCE_GET_COUNTY_BY_STATE = 'v1/lookup/getallcountybystate?state=';
    static COMPONENT_RESOURCE_GET_COLOUR = 'v1/colour/getallcolour';
    static COMPONENT_GETSTATEREPORTFIELD_VALUE = 'v1/dynamicuicomponent/getdynamicuicomponentdetails';
    static COMPONENT_SAVE_STATEREPORTFIELD_VALUE = 'v1/dynamicuicomponent/postdynamicuicomponentfieldvalues';
    static COMPONENT_SAVE_NM_STATEREPORTFIELD_VALUE = 'v1/dynamicuicomponent/savenmconfigurationdata';
    static COMPONENT_SR_GENERATE_REPORT_BY_REPORTNAME = 'v1/reportingexecution/generatereportbyreportname';
    static COMPONENT_SR_VALIDATE_SESSION_PERIOD = 'v1/dynamicuicomponent/validatesessionreportingperiod';
    static COMPONENT_SR_GET_STATE_SCHOOL_YEAR_LIST = 'v1/reportingexecution/getschoolyearlist?districtId=';
    static COMPONENT_SR_DISTRICT_GENERATE_REPORT_BY_REPORTNAME = 'v1/reportingexecution/schedulerentryforreportgeneration';
    static COMPONENT_SR_DISTRICT_GENERATE_REPORT_BY_REPORTNAME_NM = 'v1/reportingexecution/schedulerentryforreportgenerationnm';
    static COMPONENT_SR_DISTRICT_GENERATE_REPORT_DATA_BY_REPORTNAME = 'v1/reportingexecution/getreportdata?reportName=';
    static COMPONENT_SR_DISTRICT_GENERATE_NM_REPORT_DATA_BY_REPORTNAME = 'v1/reportingexecution/getreportdatanm?reportName=';
    static COMPONENT_SR_GET_STUDENT_TRANSCRIPT_STATEREPORING_FIELDS = 'v1/dynamicuicomponent/getstudenttranscriptdetails';
    static COMPONENT_SR_GET_STUDENT_ENROLLMENT_STATEREPORING_FIELDS = 'v1/dynamicuicomponent/getstudentenrollmentinfouicomponentdetails';
    static COMPONENT_SR_GET_ATTENDANCE_SUBCATEGORY_STATEREPORING_FIELDS = 'v1/dynamicuicomponent/getattendancedynamicdata';
    static COMPONENT_SR_GET_SCHOOL_CAMPUS_LIST = 'v1/dynamicuicomponent/getschoolcampusdetails?schoolId=';
    static COMPONENT_SR_VIEW_HISTORY_REPORT = 'v1/reportingexecution/getreporthistory';
    static COMPONENT_SR_DELETE_HISTORY_REPORT = 'v1/dynamicuicomponent/deletestatereportingexecution';
    static COMPONENT_SR_GET_STATE_LIST = 'v1/reportingexecution/getstatelist';
    static COMPONENT_SR_DISTRICT_GET_STATE_LIST = 'v1/reportingexecution/getstatelistbasedondistrict?districtId=';
    static COMPONENT_SR_GET_STATE_REPORT_LIST = 'v1/reportingexecution/getstatereportlist';
    static COMPONENT_SR_GET_REPORT_HISTORY_LIST = 'v1/reportingexecution/getstatereporthistorylist';
    static COMPONENT_SR_GET_DOWNLOAD_HISTORY_REPORT = 'v1/reportingexecution/downloadreportfile?fileid=';
    static COMPONENT_SR_DISPLAY_STATE_REPORT_COMPONENT = 'v1/dynamicuicomponent/getdisplaystatereportcomponent?stateCode=';
    static COMPONENT_GET_SYSTEM_MAINTENANCE_BY_USER_ID = 'v1/systemmaintenance/getsystemmaintenancevaluebyuserid?UserId=';
    static COMPONENT_SR_GET_STATE_SCHOOL_LIST = 'v1/dynamicuicomponent/getschoolinfobystate?stateCode=';
    static COMPONENT_SR_GET_STATE_SCHOOL_SESSION_LIST = 'v1/dynamicuicomponent/getactiveschoolsession?districtId=';
    static COMPONENT_SR_GET_STATE_SCHOOL_STAFF_LIST = 'v1/dynamicuicomponent/getstafflistbyschoolid?schoolId=';
    static COMPONENT_EDFI_GET_CONFIGURATION_DATA = 'v1/EdfiSync/GetEdfiConfigurationData?districtId=';
    static COMPONENT_EDFI_SAVE_CONFIGURATION_DATA = 'v1/EdfiSync/SaveEdfiConfigurationData';
    static COMPONENT_EDFI_GET_ENTITIES_FOR_EDFI = 'v1/EdfiSync/GetEntityListByDistrict?districtId=';
    static COMPONENT_EDFI_SAVE_EXCLUDE_ENTITIES_FOR_EDFI = 'v1/EdfiSync/SaveEntityListByDistrict';
    static COMPONENT_EDFI_SAVE_SCHEDULER_CONFIGURATION = 'v1/EdfiSync/SaveSchedulerConfigurationData';
    static COMPONENT_EDFI_SAVE_SYNC_REQUEST = 'v1/EdfiSync/SaveEdfiSyncRequest';
    static COMPONENT_EDFI_GET_HISTORY_DATA = 'v1/EdfiSync/GetHistoryData?orderByColumn=';
    static COMPONENT_EDFI_CHILD_GET_HISTORY_DATA = 'v1/EdfiSync/GetChildHistoryData?requestId=';
    static COMPONENT_EDFI_GET_HISTORY_DOWNLOAD_LOG = 'v1/EdfiSync/DownloadFile?requestId=';
    static COMPONENT_EDFI_GET_LAST_SYNC_DATA = 'v1/EdfiSync/GetLastSyncDate?districtId=';
    static COMPONENT_EDFI_GET_RECONCILE_INFO = 'v1/EdfiSync/CompareData';
    static COMPONENT_EDFI_SAVE_CHILD_SYNC_REQUEST = 'v1/EdfiSync/SaveEdfiChildSyncRequest';
    static COMPONENT_EDFI_GET_INFO_PREVIEW = 'v1/EdfiSync/GetInfoPreviewFile?requestId=';
    static COMPONENT_EDFI_GET_ERROR_PREVIEW = 'v1/EdfiSync/GetErrorPreviewFile?requestId=';
    static COMPONENT_EDFI_DELETED_EDFI_RECORDS = 'v1/dynamicuicomponent/insertrepeaterfieldvaluesedfidata';
    static COMPONENT_DASHBOARD_SAVE_DASHBOARD_SETTINGS = 'v1/dashboarduserpreference/savedashboarduserpreference';
    static COMPONENT_DASHBOARD_UPDATE_DASHBOARD_SETTINGS = 'v1/dashboarduserpreference/updatedashboarduserpreference';
    static COMPONENT_DASHBOARD_GET_DASHBOARD_SETTINGS_BY_USERID = 'v1/dashboarduserpreference/getdashboarduserpreferencebyuserid?userId=';
    //Person
    static COMPONENT_PERSON_GETALL = 'v1/Person/GetAllPersonList?schoolId=';
    static COMPONENT_PERSON_GETBYID = 'v1/Person/GetPersonById?id=';
    static COMPONENT_PERSON_SAVE = 'v1/Person/SavePerson';
    static COMPONENT_PERSON_UPDATE = 'v1/Person/UpdatePerson';
    static COMPONENT_PERSON_DELETE = 'v1/Person/DeletePerson';
    static COMPONENT_CUSTOMFIELD_SAVE = 'v1/customfieldvalue/saveupdatecustomfieldvalue';
    static COMPONENT_GETCUSTOMFIELD_VALUE = 'v1/customfieldgroup/GetCustomFieldGroupValueBySchoolIdAndMenuCode';
    static COMPONENT_CUSTOM_FIELD_CATEGORY_GET_ALL = 'v1/appmenu/GetUserMenuDetailsForMenuCode?menuCode=';
    static COMPONENT_PERSON_CREDENTIAL_DELETE_BYID = 'v1/Person/DeletePersonCredential';
    static COMPONENT_GET_PENDING_PASSWORD_STUDENT_LIST = 'v1/studentinfo/getpendingpasswordstudentlist?schoolId=';
    static COMPONENT_GENERATE_REGENERATE_STUDENT_PASSWORD = 'v1/studentinfo/generateselectivestudentusername';
    //LMS  
    static IS_LMS_FLAG = 'isLmsFlag';
    static LMS = 'LMS';
    //validate subscription
    static VALIDATE_SUBSCRIPTION_BY_MODULE_KEY = 'v1/subscription/getvalidatesubscription?moduleKey=';
    //Person address 
    static COMPONENT_ADDRESS_DELETE_BYID = 'v1/Person/DeletePersonAddress';
    //Global Search
    static COMPONENT_SEARCH_RESULT = 'v1/Search/GetSearchResults';
    static COMPONENT_ADVANCED_SEARCH_FIELDS = 'v1/Search/GetAdvancedSearchFields';
    //DashBoard
    static COMPONENT_GET_DASHBOARD_ADMIN_SCHOOL = 'v1/Dashboard/GetDashboardDetail';
    static COMPONENT_GET_DASHBOARD_ADMIN_ATTN_SCHOOL = 'v1/Dashboard/GetAttendanceCountAdminDashboard?schoolId=';
    static COMPONENT_GET_DASHBOARD_ANNOUNCEMENT = 'v1/Dashboard/GetAnnouncementByUserRole?roleId=';
    static COMPONENT_GET_DASHBOARD_ANNOUNCEMENT_ROLEWISE = 'v1/announcement/getrolewisereceivedannouncement?';
    static COMPONENT_DASHBOARD_CALENDAR_EVENT_CRISIS_OF_DAY = 'v1/Dashboard/GetCalendarEventsandCrisisForDay';
    static COMPONENT_DASHBOARD_CALENDAR_EVENT_CRISIS_OF_MONTH = 'v1/Dashboard/GetCalendarEventsandCrisisForMonth';
    //Dynamic Form
    static COMPONENT_GET_SCHOOL_WISE_DYNAMIC_FORM = 'v1/dynamicform/getschoolwisedynamicform';
    static COMPONENT_GET_ALL_SCHOOL_WISE_DISTINCT_FORM = 'v1/dynamicform/getdistinctformschoolwise';
    static COMPONENT_SAVE_DYNAMIC_FORM_VALUE = 'v1/dynamicform/savedynamicformvalue';
    //Generate Student Password Scheduler
    static COMPONENT_STUDENT = 'student';
    static COMPONENT_GENERATE_STUDENT_PASSWORD_SCHEDULER = 'v1/StudentInfo/TaskForStudentPasswordGeneration';
    static COMPONENT_STUDENT_GET_NOTIFIABLE_CONTACTS = 'v1/studentinfo/getstudentnotifiablecontactbysearch?';
    static COMPONENT_STUDENT_SEND_NOTIFIABLE_CONTACTS = 'v1/studentinfo/generatepasswordforstudentnotifiablecontact?personIds=';
    //Canned Report
    static COMPONENT_CANNED = 'cannedreport';
    static COMPONENT_CANNED_GET_ALL_STUDENT_SCHEDULE_MISSING_COURSE_REQUEST = 'v1/studentmissingscheduledcourserequest/getallstudentmissingscheduledcourserequest?schoolId=';
    static COMPONENT_CANNED_DOWNLOAD_MISSING_STUDENT_SCHEDULE_REPORT = 'v1/studentmissingscheduledcourserequest/downloadstudentschedulerequestreport?schoolId=';
    static COMPONENT_CANNED_GET_ALL_LABEL_ORIENTATION = 'v1/labelorientation/getalllabelorientationbyschoolid?schoolId='
    static COMPONENT_CANNED_DOWNLOAD_MAILING_LABEL_REPORT = 'v1/mailingreport/downloadmailingreportpdf'
    static COMPONENT_CANNED_DOWNLOAD_STUDENT_ENROLLMENT_REPORT = 'v1/studentenrollmentreport/downloadstudentenrollmentreport'
    static COMPONENT_CANNED_DOWNLOAD_SPECIAL_PROGRAM_GENERAL_REPORT = 'v1/specialprogramgenerallistreport/downloadspecialprogramgenerallistreport'
    static COMPONENT_CANNED_GET_ALL_STUDENT_SPECIAL_PROGRAM_STUDENT_LIST = 'v1/studentspecialprogramreport/getallspecialprogramstudentlist';
    static COMPONENT_CANNED_DOWNLOAD_STUDENT_SPECIAL_PROGRAM_REPORTS = 'v1/studentspecialprogramreport/downloadstudentspecialprogramreports';
    static COMPONENT_DOWNLOAD_ATTENDANCE_PERCENT_REPORT = 'v1/attendancepercentagereport/downloadattendancepercentagereport';
    static COMPONENT_GET_ATTENDANCE_REPORT = 'v1/attendancepercentagereport/getattendancepercentagereport?';
    static COMPONENT_DOWNLOAD_PERFECT_ATTENDANCE_PERCENT_REPORT = 'v1/perfectattendancereport/downloadallperfectattendancereport?';
    static COMPONENT_DOWNLOAD_WITHDRAW_FORM_REPORT = 'v1/withdrawformreport/downloadwithdrawformreport';
    static COMPONENT_GET_WITHDRAWAL_FORM_STUDENT_LIST = 'v1/withdrawformreport/getallwithdrawformstudentlist?';
    static COMPONENT_CANNED_DOWNLOAD_SPECIAL_PROGRAM_DETAIL_REPORT = 'v1/specialprogramdetaillistreport/downloadspecialprogramdetaillistreport';
    static COMPONENT_GET_ATTENDANCE_CATEGORY_SHCOOLID = 'v1/perfectattendancereport/getattendancecategory?schoolId=';
    static COMPONENT_GET_ATTENDANCE_CATEGORY_DETAILS_SHCOOLID = 'v1/perfectattendancereport/getattendancecategorydetails?schoolid=';
    static COMPONENT_DOWNLOAD_STUDENT_ATTENDANCE_PERCENT_REPORT = 'v1/attendancepercentagereport/downloadstudentattendancepercentagereport';
    //Session Storage
    static COMPONENET_CANNED_DOWNLOAD_STUDENT_ELIGIBILITY_REPORT = 'v1/studenteligibilityinfo/downloadstudenteligibilityreport?schoolId='
    static COMPONENT_CANNED_DOWNLOAD_STUDENT_SCHEDULED_REPORT = 'v1/studentscheduledreport/downloadstudentscheduledreport?schoolId='
    static COMPONENT_GET_SYSTEM_DATEFORMAT_BY_SCHOOLID = 'v1/schooldateformat/getschooldateformatbyschoolid?schoolId=';
    //Session Storage
    //static SESSION_LOOKUPS = 'markersLookups';
    static COMPONENT_STUDENT_SPECIAL_PROGRAM_PERMISSION = 'markersSpecialProgramPermissions';
    static SESSION_CUSTOM_CATEGORY = 'markersCustomCategory';
    static SESSION_LAST_USER_DETAILS = 'markersLastUserSessionDetails';
    static SESSION_DISTRICTID = 'markersDistrictId';
    static SESSION_CUSTOMERID = 1001;
    static SESSION_SCHOOLID = 'markersSchoolId';
    static SESSION_SCHOOLYEAR = 'markersSchoolYear';
    static SESSION_SCHOOLSESSION = 'markersSchoolSession';
    static SESSION_ID = 'markersSessionId';
    static SESSION_MARKINGPERIOD_ID = 'markersSessionMarkingPeriodId';
    static SESSION_DISTRICT_LOGO = 'markersDistrictLogo';
    static SESSION_IS_DASHBOARD = 'markersDashBoardLogoShow';
    static SESSION_IS_SCHOOLREPORT = 'markersSchoolReportLogoShow';
    static SESSION_IS_DISTRICTREPORT = 'markersDistrictReportLogoShow';
    static SESSION_SCHOOLNAME = 'markersSchoolName';
    static SESSION_SCHOOL_PHOTO = 'markersSchoolPhoto';
    static SESSION_SCHOOLADDRESS = 'markersSchoolAddress';
    static SESSION_TOKEN = 'markersToken';
    static SESSION_SSO_TOKEN = 'markersSsoToken';
    static SESSION_LOGGED_USER_EMAIL = 'markersLoggedUserEmail';
    static SESSION_LANGUAGE = 'i18nextLng';
    static SESSION_DATE_FORMAT = 'markersDateFormat';
    static DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
    static SESSION_CALENDARID = 'markersCalendarId';
    static SESSION_ROUTEPATH = 'markersRoutePath';
    static SESSION_USER_ID = 'markersUserId';
    static SESSION_USER_NAME = 'markersUserName';
    static SESSION_PHONE_FORMAT = 'markersPhoneFormat';
    static SESSION_NEWPERSONID = 'markersNewPersonId';
    static SESSION_NEWPERSONNAME = 'markersNewPersonName';
    static SESSION_IDLETIMER = 'markersIdleTimer';
    static SESSION_ROLE_BASED_PERMISSION_LIST = 'markersRoleBasedPersmissionList';
    static ADMIN_SCHL_PREV_SESSION_PERMISSION = 'adminSchlPrevSessionPermission';
    static IS_CURRENT_SESSION_ACTIVE = 'isCurrentSessionActive';
    static CURRENT_SCHOOL_SESSION_END_DATE = 'currentSchoolSessionEndDate';
    static SESSION_CURRENT_PAGE_PERMISSION = 'markersCurrentPagePermission';
    static SESSION_SCHOOL_BASED_ROLE_LIST = 'markersSchoolBasedRoleList';
    static SESSION_SCHOOL_BASED_MULTI_ROLE_LIST = 'markersUserSelectedSchoolRoleList';
    static SESSION_SELECT_SCHOOL = "markersSchoolSelect";
    static SESSION_MP_SCHOOL = "markersSchoolSessionMp";
    static SESSION_LOGGED_USER_ROLE = 'markersUserRoleId';
    static SESSION_USER_ROLE = 'markersUserRole';
    static SESSION_MANAGE_INCIDENT_TYPE = 'markersManageIncidentType';
    static SESSION_MICRO_SERVICE = 'markersService';
    static SESSION_MENU_CODE = 'markersMenuCode';
    static SESSION_NEXT_CLASS_SHOW = 'markersNextClass';
    static SESSION_NEXT_CLASS_START = 'markersNextClassStart';
    static SESSION_LOGIN_URL = 'markersLoginUrl';
    static SESSION_ENVIRONMENT = 'markersEnvironment';
    static SESSION_TIME_ZONE = 'markersTimeZone'
    static SESSION_STATECODE = 'markersStateCode';
    static SESSION_COUNTRYCODE = 'markersCountryCode';
    static SESSION_NOTIFICATON_STATUS = 'markersNotificationStatus';
    static SESSION_DT_PAGE_COUNT = 'markersDataTablePageCount';
    static SESSION_DT_PAGE_END_COUNT = 'markersDataTablePageEndCount';
    static SESSION_DT_PAGE_NUMBER = 'markersDataTablePageNumber';
    static SESSION_DT_FILTER_DETAILS = 'markersDataTableFilterDetails';
    static SESSION_DT_FILTER_API_DETAILS = 'markersDataTableFilterAPIDetails';
    static SESSION_DT_FILTER_CRITERIA = 'markersDataTableFilterCriteria';
    static SESSION_SELECTED_STAFF_USER_ID = 'markersSelectedStaffUserId';
    static SESSION_TEACHER_EDIT_ATTENDANCE = 'markersTeacherEditAttendance';
    static SESSION_TEACHER_EDIT_ALL_ASSIGNMENT = 'markersTeacherEditAllAssignment';
    //Students
    static SESSION_STUDENT_GRADE_LEVEL_ID = 'markersStudentGradeLevelId';
    static SESSION_STUDENTID = 'markersStudentId';
    static SESSION_STUDENTNAME = 'markersStudentName';
    static SESSION_STUDENT_ENROLLMENT_ID = 'studentEnrollmentId';
    static SAVE_TYPE = 'updated';
    static SESSION_STUDENT_PHOTO = 'markersStudentPhoto';
    static SESSION_STUDENT_PHONE = 'markersStudentPhone';
    static SESSION_STUDENTVISAFLAG = 'markersStudentVisaFlag';
    static ADDRESS_COUNTY = "county";
    static ADDRESS_STREET = "streetNoName";
    static ADDRESS_LINE = "addressLine";
    static SESSION_GUARDIAN_ID = 'markersGuardianId';
    static SESSION_STUDENT_CODE = "markersStudentCode";
    static SESSION_STUDENT_GRADE_LEVEL_TITLE = 'markersStudentGradeLevelTitle';
    static SESSION_STUDENT_NM_SPE_EDU_SR_SHOW = 'markersStuNmSpecialEducationEvent';
    static SESSION_STUDENT_NM_SPE_EDU_SER_SR_SHOW = 'markersStuNmSpecialEducationService';
    static SESSION_STUDENT_NM_STU_DIGI_SR_SHOW = 'markersStuNmStudentDigital';
    static LOOKUP_EXIT_WITHDRAWAL_STATUS_DEFAULT_PERMANENT = 5201;
    static DEFAULT_ENROLLMENT_STATUS = 5002;
    static LOOKUP_IDENTIFICATION_SYSTEM_STATE_ASSIGNED_NUMBER = 19808;
    static LOOKUP_ENROLLMENT_STATUS_NO_SHOW = 5005;
    static LOOKUP_ABSENCE_LETTER_OPERATOR = 'ABSENCE_LETTER_OPERATOR';
    static SHOW_STUDENT_LIST_URL = "/student/showStudentList";
    static DASHBOARD_URL = "/dashboard";
    //Staffs
    static SESSION_STAFFID = 'markersStaffId';
    static SESSION_STAFFNAME = 'markersStaffName';
    static SESSION_PERSONID = 'markersPersonId';
    static SESSION_STAFF_PHOTO = 'markersStaffPhoto';
    static JOB_CATEGORY_ID = "jobCategoryId"
    static SESSION_STAFF_CONTACT_ID = 'markersGuardianId'
    static LEAVE_STATUS_TYPE_DISCARDED = 'Discarded';
    static ADDRESS_APARTMENT = 'aprtSuiteRoomNo';
    static ADDRESS_BUILDING = 'buildingSiteNo';
    static LOOKUP_HIGHEST_LEVEL_EDUCATION_COMPLETED = 'HIGHEST_LEVEL_EDUCATION_COMPLETED';
    static SESSION_PROFILE_PICTURE = 'markersProfilePicture'
    //LookUp Category
    static LOOKUP_ST_MASS_ASSIGN_CATEGORY = 'ST_MASS_ASSIGN_CATEGORY';
    static LOOKUP_COMMENT_TYPE = 'COMMENT_TYPE';
    static LOOKUP_ENTITY_TYPE = 'ENTITY_TYPE';
    static LOOKUP_SCHOOL_TYPE = 'SCHOOL_TYPE';
    static LOOKUP_SCHOOL_LEVEL = 'SCHOOL_LEVEL';
    static LOOKUP_SCHOOL_FOCUS = 'SCHOOL_FOCUS_TYPE';
    static LOOKUP_ADDRESS_TYPE = 'OFFICE_ADDRESS_TYPE';
    static LOOKUP_PERSON_ADDRESS_TYPE = 'ADDRESS_TYPE';
    static LOOKUP_DISTRICT_ADDRESS_TYPE = 'DISTRICT_ADDRESS_TYPE';
    static LOOKUP_PHONE_TYPE = 'PHONE_TYPE';
    static LOOKUP_EMAIL_TYPE = 'EMAIL_TYPE';
    static LOOKUP_EMAIL_DISTRICT_TYPE = 'DISTRICT_EMAIL_TYPE';
    static LOOKUP_SCHOOL_DESIGNATION_TYPE = 'SCHOOL_DESIGNATION_TYPE';
    static LOOKUP_SCHOOL_IDENTIFICATION_SYSTEM = 'SCHOOL_IDENTIFICATION_SYSTEM';
    static LOOKUP_ACCREDITATION_AGENCY = 'ACCREDITATION_AGENCY';
    static LOOKUP_HE_ACCREDITATION_STATUS = 'HE_ACCREDITATION_STATUS';
    static LOOKUP_ADMIN_FUND_CONTROL = 'ADMIN_FUND_CONTROL';
    static LOOKUP_SCHOOL_OPERATIONAL_STATUS = 'ORGANIZATION_OPERATIONAL_STATUS';
    static LOOKUP_SCHOOL_IMPROVEMENT_STATUS = 'SCHOOL_IMPROVEMENT_STATUS';
    static LOOKUP_SCHOOL_GRADE_LEVEL = 'SCHOOL_GRADES';
    static LOOKUP_SCHOOL_CALENDAR_VISIBILITY = 'SCHOOL_ROLE';
    static LOOKUP_SCHOOL_FIELD_DATA_TYPE = 'DATA_TYPE';
    static LOOKUP_SCHOOL_FIELD_DATE_FORMAT = 'SCHOOL_FIELD_DATE_FORMAT';
    static LOOKUP_SCHOOL_FIELD_TYPE = 'FIELD_TYPE';
    static LOOKUP_ROLE_TYPE = 'SCHOOL_ROLE';
    static LOOKUP_SALUTATION_TYPE = 'SALUTATION';
    static LOOKUP_SALUTATION_STAFF_TYPE = 'SALUTATION_STAFF';
    static LOOKUP_MARKINGPERIOD_TYPE = 'MARKING_PERIOD_TYPE';
    static LOOKUP_CRISIS_TYPE = 'CALENDAR_CRISIS_TYPE';
    static LOOKUP_SCHOOL_CALENDAR_EVENT_TYPE = 'CALENDAR_EVENT_TYPE';
    static LOOKUP_STAFF_CREDENTIAL_TYPE = 'CREDENTIAL_TYPE';
    static LOOKUP_STAFF_VISA_TYPE = 'GENERAL_VISA_TYPE';
    static LOOKUP_SCHOOL_PUBLICATION_STATUS = 'PUBLICATION_STATUS';
    static LOOKUP_MATH_OPERATOR = 'MATH_OPERATOR';
    static LOOKUP_REPORT_OPERATOR = 'REPORT_OPERATOR';
    static LOOKUP_GRADE_SCALE_DEFINITION = 'GRADE_SCALE_DEFINITION';
    static LOOKUP_SEARCH_TYPE = 'SEARCH_ENTITY';
    static LOOKUP_ACCOMMODATION_TYPE = 'ACCOMMODATION_TYPE';
    static LOOKUP_ACCOMMODATION_CATEGORY = 'ACCOMMODATION_CATEGORY';
    static LOOKUP_PERSON_OCCUPATION_TYPE = 'PERSON_OCCUPATION_TYPE';
    static LOOKUP_GPA_CALC_BASED_ON = 'GPA_CALC_BASED_ON';
    static LOOKUP_GRADE_TYPE = 'GRADE_TYPE';
    static LOOKUP_DAYS_IN_WEEK = 'DAYS_IN_WEEK';
    static LOOKUP_BS_SCHOOL_DAY_EVENT = 'BS_SCHOOL_DAY_EVENT';
    static LOOKUP_BS_CALENDAR_WAIVER_EVENT = 'BS_CALENDAR_WAIVER_EVENT'
    static LOOKUP_ADHOC_SCHEDULE_TYPE = 'ADHOC_SCHEDULE_TYPE';
    static LOOKUP_CANNED_REPORT_SCHEDULE_OPTIONS = 'STU_MISSING_SCH_RPT_OPTIONS';
    static LOOKUP_CANNED_REPORT_SCHEDULE_SORT_ORDER = 'STU_MISSING_SCH_RPT_SORTORDER';
    static LOOKUP_CANNED_RPT_GRADE_LISTING_GRADE_TYPE = 'CANNED_RPT_GRADE_LISTING_GRADE_TYPE';
    static LOOKUP_CANNED_RPT_MST_SCH_SORT_ORDER = 'CANNED_RPT_MST_SCH_SORT_ORDER';
    static LOOKUP_CANNED_RPT_IDENTITY_SPS_SORTBY = 'CANNED_RPT_IDENTITY_SPS_SORTBY';
    static LOOKUP_ACTIVE_STATUS = 'ACTIVE_STATUS';
    static LOOKUP_DISTRICT_EMAIL_TYPE = 'DISTRICT_EMAIL_TYPE';
    static LOOKUP_STAFF_CREDENTIAL_USE = 'CREDENTIAL_USE';
    static LOOKUP_DATEFORMAT = 'DATEFORMAT';
    static LOOKUP_DATEFORMAT_DELIMITER = 'DATEFORMAT_DELIMITER';
    static LOOKUP_ID_CARD_ORIENTATION_TYPE = 'ID_CARD_ORIENTATION_TYPE';
    static LOOKUP_ID_CARD_SIZE_VERTICAL = 'ID_CARD_SIZE_VERTICAL';
    static LOOKUP_ID_CARD_SIZE_HORIZONTAL = 'ID_CARD_SIZE_HORIZONTAL';
    static LOOKUP_STAFF_ADDRESS_TYPE = 'STAFF_ADDRESS_TYPE';
    static LOOKUP_EMPLOYMENT_STATUS = 'EMPLOYMENT_STATUS';
    static LOOKUP_SEPERATION_REASON = 'SEPERATION_REASON';
    static LOOKUP_CANNED_REPORT_SCHEDULE_OPTIONS_ID = 26502


    /*School section*/
    static SESSION_SECTION_ID = 'markersSectionId';
    static SESSION_GRADE_LEVEL_ID = 'markersGradeLevelId';
    static SESSION_SCHOOL_SESSION_ID = 'markersSchoolSessionId';
    static SESSION_ROOM_ID = 'markersRoomId';
    static SESSION_DEPARTMENT_ID = 'markersDepartmentId'
    static SESSION_GRADE_SCALE_ID = 'markersDepartmentId'
    static SESSION_PERIOD_ID = 'markersPeriodId'
    static SESSION_ROLE_ID = 'markersRoleId'
    static SESSION_MARKING_PERIOD_ID = 'markersRoleId'
    static DURATION_IN_DAYS = 'durationInDays'
    static MINUTES_PER_DAY = 'minutesPerDay'
    /*Student section*/
    static SESSION_SECTION_ID = 'markersSectionId'
    /*Student section*/
    static LOOKUP_STUDENT_ETHNICITY = 'ETHNICITY';
    static LOOKUP_STUDENT_HISPANIC_LATINO_ETHNICITY = 'HISPANIC_LATINO_ETH_TYPE';
    static LOOKUP_STUDENT_HISPANIC_LATINO_RACE = 'HISPANIC_LATINO_RACE_TYPE';
    static LOOKUP_NM_RACE = 'NM_RACE';
    static STUDENT_HISPANIC_LATINO_RACE_TYPE_UNKNOWN = "17299";
    static LOOKUP_STUDENT_LANGUAGE = 'LANGUAGE';
    static LOOKUP_NM_RELATION = 'NM_RELATION';
    static LOOKUP_STUDENT_ROLLING_RETENTION = 'STUDENT_ROLLING_RETENTION';
    static LOOKUP_STUDENT_IDENTIFICATION_SYSTEM = 'STUDENT_IDENTIFICATION_SYSTEM';
    static LOOKUP_STUDENT_IDENTIFICATION_SYSTEM_NM = 'NM_STUDENT_IDENTIFICATION_SYSTEM';
    static LOOKUP_STUDENT_PUBLIC_SCHOOL_RESIDENTIAL_STATUS = 'PUBLIC_SCHOOL_RESIDENTIAL_STATUS';
    static LOOKUP_STUDENT_OTHER_BACKWARD_COMMUNITY = 'STUDENT_OTHER_BACKWARD_COMMUNITY';
    static LOOKUP_STUDENT_VERIFIABLE_INFO = 'STUDENT_VERIFIABLE_INFO';
    static LOOKUP_STUDENT_DOCUMENT_VERIFICATION_CERTIFICATE = 'DOCUMENT_VERIFICATION_CERTIFICATE';
    static LOOKUP_STUDENT_ISSUER_COUNTRY = 'STUDENT_ISSUER_COUNTRY';
    static LOOKUP_STUDENT_CERTIFIED_BY = 'STUDENT_CERTIFIED_BY';
    static LOOKUP_STUDENT_VERIFIED_BY = 'STUDENT_VERIFIED_BY';
    static LOOKUP_STUDENT_VISA_TYPE = 'STUDENT_VISA_TYPE';
    static LOOKUP_STUDENT_PERSON_RELATIONSHIP = 'PERSON_RELATIONSHIP';
    static LOOKUP_STUDENT_DISABILITY_TYPE = 'DISABILITY_TYPE';
    static LOOKUP_STUDENT_ACCOMMODATION_NEED_TYPE = 'ACCOMMODATION_NEED_TYPE';
    static LOOKUP_STUDENT_DISABILITY_CONDITION_TYPE = 'DISABILITY_CONDITION_TYPE';
    static LOOKUP_STUDENT_DISABILITY_CONDITION_NATURE = 'DISABILITY_CONDITION_NATURE';
    static LOOKUP_STUDENT_DETERMINED_BY = 'DISABILITY_DETERMINATION_SOURCE';
    static LOOKUP_STUDENT_IDEA_DISABILITY_TYPE = 'IDEA_DISABILITY_TYPE';
    static LOOKUP_SCHOOL_LUNCH_PROGRAM_TYPE = 'SCHOOL_LUNCH_PROGRAM_TYPE';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY = 'FOOD_SERVICE_ELIGIBILITY';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FREE = 'FOOD_SERVICE_ELIGIBILITY_TYPE_FREE';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FULL = 'FOOD_SERVICE_ELIGIBILITY_TYPE_FULL';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_REDUCED = 'FOOD_SERVICE_ELIGIBILITY_TYPE_REDUCED';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_OTHER = 'FOOD_SERVICE_ELIGIBILITY_TYPE_OTHER';
    static LOOKUP_STUDENT_ENROLLMENT_GIFTED_PROGRAM = 'ENROLLMENT_PROGRAM_GIFTED';
    static LOOKUP_STUDENT_ENROLLMENT_CODE = 'LOOKUP_STUDENT_ENROLLMENT_CODE';
    static LOOKUP_ENROLLMENT_ENTRY_TYPE = 'ENROLLMENT_ENTRY_TYPE';
    static LOOKUP_EXIT_WITHDRAWAL_STATUS = 'EXIT_WITHDRAWAL_STATUS';
    static LOOKUP_EXIT_ENROLLMENT_STATUS = 'ENROLLMENT_STATUS';
    static LOOKUP_EXIT_ROLLING_RETENTION = 'ROLLING_RETENTION_TYPE';
    static LOOKUP_EXIT_WITHDRAWAL_TYPE = 'EXIT_WITHDRAWAL_TYPE';
    static LOOKUP_PROMOTION_REASON = 'PROMOTION_REASON';
    static LOOKUP_NON_PROMOTION_REASON = 'NON_PROMOTION_REASON';
    static LOOKUP_STUDENT_REFERRAL_TYPE = 'STUDENT_REFERRAL_TYPE';
    static LOOKUP_STUDENT_REFERRAL_OUTCOME = 'STUDENT_REFERRAL_OUTCOME';
    static LOOKUP_STUDENT_VISION_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_HEARING_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_DENTAL_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_HEALTH_INSURANCE_COVERAGE = 'HEALTH_INSURANCE_COVERAGE';
    static LOOKUP_STUDENT_DENTAL_INSURANCE_COVERAGE = 'HEALTH_INSURANCE_COVERAGE';
    static LOOKUP_STUDENT_MEDICAL_ALERT_TYPE = 'MEDICAL_ALERT_TYPE';
    static LOOKUP_STUDENT_HEALTH_SCREENING_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_MULTIPLE_BIRTH = 'MULTIPLE_BIRTH';
    static LOOKUP_STUDENT_INSURANCE_TYPE = 'INSURANCE_TYPE';
    static LOOKUP_RELATIONSHIP = 'STUDENT_RELATIONSHIP';
    static LOOKUP_NAME_SUFFIX_TYPE = 'NAME_SUFFIX';
    static LOOKUP_IMMUNIZATION_ROUTE = 'IMMUNIZATION_ROUTE';
    static LOOKUP_IMMUNIZATION_STATUS = 'IMMUNIZATION_STATUS';
    static LOOKUP_INJECTION_SITE = 'INJECTION_SITE';
    static LOOKUP_MEDICAL_CONDITION = 'MEDICAL_CONDITION';
    static LOOKUP_BEHAVIOR_CONDITION = 'BEHAVIOR_CONDITION';
    static LOOKUP_MEDICATION = 'MEDICATION';
    static LOOKUP_MEDICATION_ROUTE = 'MEDICATION_ROUTE';
    static LOOKUP_MEDICATION_FREQUENCY = 'MEDICATION_FREQUENCY';
    static LOOKUP_MEDICATION_FORM = 'MEDICATION_FORM';
    static LOOKUP_HEALTH_SCREENING_TYPE = 'HEALTH_SCREENING_TYPE';
    static LOOKUP_DENTAL_SCREENING_STATUS = 'DENTAL_SCREENING_STATUS';
    static LOOKUP_HEALTH_REFERRAL_RESULT = 'HEALTH_REFERRAL_RESULT';
    static LOOKUP_HEARING_TEST = 'HEARING_TEST';
    static LOOKUP_HEALTH_SCREENING_EYE_POWER = 'HEALTH_SCREENING_EYE_POWER';
    static LOOKUP_SCREERNNG_TEST_RESULT = 'SCREERNNG_TEST_RESULT';
    static LOOKUP_SPINAL_ACTION_TAKEN = 'SPINAL_ACTION_TAKEN';
    static LOOKUP_SPINAL_DIAGNOSIS = 'SPINAL_DIAGNOSIS';
    static LOOKUP_SPINAL_TREATMENT = 'SPINAL_TREATMENT';
    static LOOKUP_VISION_TEST = 'VISION_TEST';
    static LOOKUP_SPECIAL_PROGRAMS = 'SPECIAL_PROGRAMS';
    static LOOKUP_VISIT_REASON_MISSED = 'VISIT_REASON_MISSED';
    static LOOKUP_VISIT_REASON = 'VISIT_REASON';
    static LOOKUP_ACTION_TAKEN = 'ACTION_TAKEN';
    static LOOKUP_REPORT_FREQUENCY_TYPE = 'REPORT_FREQUENCY_TYPE';
    static LOOKUP_BLOOD_GROUP_TYPE = 'BLOOD_GROUP_TYPE'
    /*Staff Section */
    static LOOKUP_STAFF_CERTIFICATE_ACCREDITATION_STATUS = 'CERTIFICATE_ACCREDITATION_STATUS';
    static LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_TYPE = "TEACHING_CREDENTIAL_TYPE";
    static LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_BASIS = "TEACHING_CREDENTIAL_BASIS";
    static LOOKUP_STAFF_CREDENTIAL_CERTIFICATE_SPONSOR_TYPE = "STAFF_CERT_SPONSOR_TYPE";
    static LOOKUP_STAFF_LEAVE_TYPE = "LEAVE_TYPE";
    static LOOKUP_STAFF_PERSONAL_DOCUMENT_VERIFICATION = 'DOCUMENT_VERIFICATION_CERTIFICATE';
    static LOOKUP_STAFF_JOB_CATEGORY_SCHOOL_ROLE = "SCHOOL_ROLE";
    static LOOKUP_STAFF_CERTIFICATION_TYPE = "CERTIFICATION_TYPE";
    static LOOKUP_CERTIFICATE_VERIFICATION_METHOD = 'CERTIFICATE_VERIFICATION_METHOD';
    static LOOKUP_DOCUMENT_VERIFICATION_STATUS = 'DOCUMENT_VERIFICATION_STATUS';
    static LOOKUP_LEAVE_STATUS_TYPE = 'LEAVE_STATUS_TYPE';
    static MASS_IMMUNIZATION_INFO = "massImmunizationInfo"
    static LOOKUP_CERTIFICATE_TYPE = 'lookupCertificationType'
    //Course lookup
    static LOOKUP_COURSE_LEVEL_CHARACTERISTIC = 'COURSE_LEVEL_CHARACTERISTIC';
    static LOOKUP_COURSE_CREDIT_UNIT = 'COURSE_CREDIT_UNIT';
    static LOOKUP_COURSE_WBL_OPPORTUNITY_TYPE = 'COURSE_WBL_OPPORTUNITY_TYPE';
    static LOOKUP_ADITIONAL_CREDIT_TYPE = 'ADDITIONAL_CREDIT_TYPE';
    static LOOKUP_COURSE_GPA_APPLICABILITY = 'COURSE_GPA_APPLICABILITY';
    static LOOKUP_CURRICULUM_FRAMEWORK_TYPE = 'CURRICULUM_FRAMEWORK_TYPE';
    static LOOKUP_COURSE_CREDIT_TYPE_EARNED = 'COURSE_CREDIT_TYPE_EARNED';
    static LOOKUP_COURSE_CAREER_CLUSTER = 'COURSE_CAREER_CLUSTER';
    static LOOKUP_COURSE_BLND_LEARNING_MODEL_TYPE = 'COURSE_BLND_LEARNING_MODEL_TYPE';
    static LOOKUP_COURSE_EXAM_REQUIREMENT = 'COURSE_EXAM_REQUIREMENT';
    static LOOKUP_ADVANCED_PLACEMENT_COURSE_CODE = 'ADVANCED_PLACEMENT_COURSE_CODE';
    static LOOKUP_PERIOD_SCHEDULE_TYPE = 'PERIOD_SCHEDULE_TYPE';
    static LOOKUP_SINGLE_SEX_CLASS_STATUS = 'SINGLE_SEX_CLASS_STATUS';
    static LOOKUP_COURSE_SECTION_DELIVERY_MODE = 'COURSE_SECTION_DELIVERY_MODE';
    static LOOKUP_COURSE_SECTION_LEVEL_TYPE = 'COURSE_SECTION_LEVEL_TYPE';
    static LOOKUP_COURSE_TYPE = 'COURSE_TYPE';
    static LOOKUP_COURSE_SECTION_EXIT_TYPE = 'COURSE_SECTION_EXIT_TYPE';
    static LOOKUP_COURSE_SECTION_ENTRY_TYPE = 'COURSE_SECTION_ENTRY_TYPE';
    static LOOKUP_COURSE_SEQUENCE_TYPE = 'COURSE_SEQUENCE_TYPE';
    static LOOKUP_COURSE_APPROVAL_STATUS = 'APPROVAL_STATUS';
    static LOOKUP_COURSE_STUDY_MATERIAL_TYPE = 'STUDY_MATERIAL_TYPE';
    static STUDY_MATERIAL_LEVEL_COURSE_SECTION = 'Course Section';
    // Attendance Section 
    static LOOKUP_ATTENDANCE_STATUS = 'ATTENDANCE_STATUS';
    static LOOKUP_PRESENT_ATTENDANCE_CATEGORY = 'PRESENT_ATTENDANCE_CATEGORY';
    static LOOKUP_ABSENT_ATTENDANCE_CATEGORY = 'ABSENT_ATTENDANCE_CATEGORY';
    static ATTENDANCE_STATUS = 'attendanceStatus';
    static EMAIL_SELECTED_TEACHER_ID = 'EmailSelectedTeacherId';
    static EMAIL_SELECTED_TEACHER = 'EmailSelectedTeacher';
    static LOOKUP_CANNED_RPT_ATTENDANCE_TYPE = 'CANNED_RPT_ATTD_PERCT_TYPETOUSE';
    static LOOKUP_CANNED_RPT_ATTENDANCE_CATEGORY = 'CANNED_RPT_ATTD_PERCT_CATEGORIES';
    static LOOKUP_CANNED_RPT_ATTENDANCE_STUDENTS = 'CANNED_RPT_ATTD_PERCT_STUDENTS';
    static LOOKUP_ABSENT_ATTENDANCE_UNEXCUSED_CATEGORY = 'ABSENT_ATTENDANCE_UNEXCUSED_CATEGORY';
    static LOOKUP_ABSENT_ATTENDANCE_EXCUSED_CATEGORY = 'ABSENT_ATTENDANCE_EXCUSED_CATEGORY';
    // Discipline Section 
    static LOOKUP_INCIDENT_TIME_TYPE = 'INCIDENT_TIME_TYPE';
    static LOOKUP_INCIDENT_BEHAVIOUR_TYPE = 'INCIDENT_BEHAVIOR_TYPE';
    static LOOKUP_INCIDENT_BEHAVIOUR = 'INCIDENT_BEHAVIOR';
    static LOOKUP_WEAPON_TYPE = 'WEAPON_TYPE';
    static LOOKUP_INJURY_TYPE = 'INJURY_TYPE';
    static LOOKUP_INCIDENT_LOCATION = 'INCIDENT_LOCATION';
    static LOOKUP_FIREARM_TYPE = 'FIREARM_TYPE';
    static LOOKUP_MULTIPLE_OFFENCE_TYPE = 'MULTIPLE_OFFENSE_TYPE';
    static LOOKUP_DISCIPLINE_REASON = 'DISCIPLINE_REASON';
    static LOOKUP_DISCIPLINARY_ACTION_TAKEN = 'DISCIPLINARY_ACTION_TAKEN';
    static LOOKUP_DISCIPLINE_LENGTH_DIFFERENCE = 'DISCIPLINE_LENGTH_DIFFERENCE';
    static LOOKUP_IDEA_INTERIM_REMOVAL = 'IDEA_INTERIM_REMOVAL';
    static LOOKUP_IDEA_INTERIM_REMOVAL_REASON = 'IDEA_INTERIM_REMOVAL_REASON';
    static LOOKUP_FIREARM_DISCIPLINE_METHOD = 'FIREARM_DISCIPLINE_METHOD';
    static LOOKUP_IDEA_FIREARM_DISCIPLINE_METHOD = 'IDEA_FIREARM_DISCIPLINE_METHOD';
    static LOOKUP_DM_FOR_DIABETIC_CHILDREN = 'DM_FOR_DIABETIC_CHILDREN';
    static LOOKUP_INCIDENT_PERSON_ROLE = 'INCIDENT_PERSON_ROLE';
    static LOOKUP_INCIDENT_PERSON_TYPE = 'INCIDENT_PERSON_TYPE';
    static INCIDENT_ID = 'incidentId';
    //Assessment Section
    static LOOKUP_ASSESSMENT_ASSET_TYPE = 'ASSESSMENT_ASSET_TYPE';
    static LOOKUP_PUBLICATION_STATUS = 'PUBLICATION_STATUS';
    static LOOKUP_ASSESSMENT_PURPOSE = 'ASSESSMENT_PURPOSE';
    static LOOKUP_ASSESSMENT_TYPE = 'ASSESSMENT_TYPE';
    static LOOKUP_PROGRESS_REPORT_CARD_TYPE = 32702;
    static LOOKUP_REPORT_CARD_TYPE = 32701;
    static LOOKUP_ASSESSMENT_TYPE_WITH_DISABILITY = 'ASSESSMENT_TYPE_WITH_DISABILITIES';
    static LOOKUP_ASSESSMENT_REGISTRATION_COMPLETION_STATUS = 'ASMT_REGISTRATION_COMPLETION_STATUS';
    static LOOKUP_ASSESSMENT_PARTICIPATION = 'ASSESSMENT_PARTICIPATION';
    static LOOKUP_ASSESSMENT_REASON_NOT_TESTED = 'ASSESSMENT_REASON_NOT_TESTED';
    static LOOKUP_ASSESSMENT_REASON_NOT_COMPLETED = 'ASSESSMENT_REASON_NOT_COMPLETED';
    static LOOKUP_ASSESSMENT_SESSION_TYPE = 'ASSESSMENT_SESSION_TYPE';
    static LOOKUP_ASSESSMENT_REPORTING_METHOD = 'ASSESSMENT_REPORTING_METHOD';
    static LOOKUP_ASSESSMENT_FORM_SEC_IDENTIFICATION_SYSTEM = 'ASMT_FORM_SEC_IDENTIFICATION_SYSTEM';
    static LOOKUP_SCORE_METRIC_TYPE = 'SCORE_METRIC_TYPE';
    static LOOKUP_ASSESSMENT_OUTCOME = 'ASSESSMENT_OUTCOME';
    static LOOKUP_ASSESSMENT_RESULT_SCORE_TYPE = 'ASSESSMENT_RESULT_SCORE_TYPE';
    static SESSION_ASSESSMENT_ADMINID = 'ASSESSMENT_ADMIN_ID';
    static LOOKUP_ASSIGNMENT_AGGREGATION = 'ASSIGNMENT_AGGREGATION_TYPE';
    static LOOKUP_ASSIGNMENT_GRADING_TYPE = 'ASSIGNMENT_GRADING_TYPE';
    static LOOKUP_ASSIGNMENT_QUESTION_TYPE = 'ASSIGNMENT_QUESTION_TYPE';
    static LOOKUP_ASSIGNMENT_GRADING_TYPE = 'ASSIGNMENT_GRADING_TYPE';
    static LOOKUP_ASSIGNMENT_BANK_STATUS = 'ASSIGNMENT_BANK_PUBLICATION_STATUS';
    static LOOKUP_QUESTION_TYPE_TRUE_FALSE = 25601;
    static LOOKUP_QUESTION_TYPE_MCQ = 25602;
    static LOOKUP_QUESTION_TYPE_SUBJECTIVE = 25603;
    // Announcement
    static LOOKUP_ANNOUNCEMENT_TYPE = 'ANNOUCEMENT_TYPE';
    //Application level Constant
    static BUTTON_SAVE = 'saveBtn'
    static IS_VIRTUAL = 'isVirtual';
    static IS_GRADED = 'isGraded';
    static HAS_EXAM = 'hasExam';
    static IS_ACTIVE = 'isActive';
    static IS_SCHEDULING_TERM = 'isSchedulingTerm';
    static MPGRADETYPE = 'mpGradeType';
    static MPGRADEOPTION = 'mpGradeOption';
    static IS_ROUNDUP = 'isRoundUp';
    static IS_DEFAULT = 'isDefault';
    static IS_ALL_DAY = 'isAllDayLong';
    static IS_VISIBLE_STUDENT = 'isVisibleToStudents';
    static IS_VISIBLE_STAFF = 'isVisibleToStaff';
    static WEEKDAYS = 'weekdays';
    static IS_ACRIDATED = 'accredited';
    static ACRIDATED = 'isAccredited';
    static IS_EXCLUDE_FROM_CALCULATION = 'isExcludeFromCalculation';
    static IS_ALLOWEDFORSCHOOL = 'isAllowedForSchool';
    static IS_ALLOWATTENDANCE = 'allowAttendance';
    static IS_IGNORESCHEDULING = 'ignoreScheduling';
    static IS_DELETED = 'isDeleted';
    static IS_VISIBLE = 'isVisibleToOtherSchools';
    static IS_ACTIVITY = 'isActivity';
    static IS_PUBLISHED = 'isPublished';
    static IS_AFFECT = 'isAffect';
    static AM = 'AM';
    static PM = 'PM';
    static SUCCESS_NOTIFY_TYPE = 1;
    static ERROR_NOTIFY_TYPE = 2;
    static IS_CLOSED = 'isClosed';
    static SORTORDER = 'sortOrder';
    static DFAULT_TIME = '0001-01-01T00:00:00';
    static UNPUBLISHED_COUNT = 0;
    static BILLING_APP_PATH = 'https://localhost:5004';
    static IS_BILLING = 'billing';
    static TRANSPORT_APP_PATH = 'https://localhost:5006';
    static IS_TRANSPORT = 'transport';
    static IS_LMS = 'lms';
    static lOADER_DELAY_TIME = 2000;
    static DEFAULT_COUNTRY_CODE = 'US';
    static INDIA_COUNTRY_CODE = 'IN';
    static COURSE_MANAGER = 'coursemanager';
    static ASSIGNMENT_DETAILS = 'assignmentDetails';
    static DOWNLOAD_FILE = 'downloadfile';
    static STAFF_AADHAAR_IDENTIFICATION = 19812;
    static STUDENT_AADHAAR_IDENTIFICATION = 3111;
    //Phone relate constants
    static PHONE_NUMBER_MIN_LENGTH = 11;
    static PHONE_NUMBER_MIN_LENGTH_V2 = 10;
    static HOME_NUMBER = 903;
    static WORK_NUMBER = 901;
    static MOBILE_NUMBER = 902;
    //School fields related Constant
    static IS_SEARCHABLE = 'searchable';
    static IS_ACADEMIC = 'isAcademic';
    static IS_REQUIRED = 'isRequired';
    static IS_MANDATORY = 'isMandatory';
    static IS_HIDE = 'hide';
    static IS_NUMERIC = 'numeric';
    static IS_VARCHAR = 'varchar';
    static IS_SPL_CHAR = 'specialCharacter';
    static IS_MAX_LENGTH = 'maxLength';
    static IS_DATE = 'date';
    static GEN_MENUCODE = 'SCHL_ADD_GEN';
    static ADMIN_MENUCODE = 'SCHL_ADD_ADM';
    static ACC_MENUCODE = 'SCHL_ADD_ACC';
    static DESIG_MENUCODE = 'SCHL_ADD_DES';
    static IDEN_MENUCODE = 'ADD_SCHOOL_IDENTIFIER';
    static STUGEN_MENUCODE = 'STUD_ADD_GEN';
    static STUIDEN_MENUCODE = 'STUD_ADD_IDE';
    static STUMISC_MENUCODE = 'STUD_ADD_MIS';
    static STUREF_MENUCODE = 'STUD_ADD_REF';
    static STUVISA_MENUCODE = 'STUD_ADD_VIS';
    static STUENROLL_MENUCODE = 'STUD_ADD_ENR';
    static STUDIS_MENUCODE = 'STUD_ADD_DIS';
    static SCHOOLADD_MENUCODE = 'SCHOOL_ADD_ADDRESS';
    static STUGUA_MENUCODE = 'STUD_ADD_GUA';
    static STUHEALTH_MENUCODE = 'STUD_ADD_HEA';
    static STUBIRTH_MENUCODE = 'STUD_ADD_BIR';
    static STUIMMU_MENUCODE = 'STUD_ADD_IMM';
    static STUCOHO_MENUCODE = 'STUD_ADD_COH';
    static STUELIGI_MENUCODE = 'STUD_ADD_ELI';
    static STUGIFT_MENUCODE = 'STUD_ADD_GIF';
    static STFDEM_MENUCODE = 'STAF_ADD_DEM';
    static STFEMP_MENUCODE = 'STAF_ADD_EMP';
    static STFROLE_MENUCODE = 'STAF_ADD_ROL';
    static STFCONT_MENUCODE = 'STAF_ADD_CON';
    static STFQUALI_MENUCODE = 'STAF_ADD_QUA';
    static STFDOCU_MENUCODE = 'STAF_ADD_DOC';
    static STFVISA_MENUCODE = 'STAF_ADD_VIS';
    static STFTEACH_MENUCODE = 'STAF_ADD_TEA';
    static STFCRED_MENUCODE = 'STAF_ADD_CRE';
    static STFPER_MENUCODE = 'STAF_ADD_PER';
    static CUSTOMFIELD_ADD = 'add';
    static CUSTOMFIELD_VIEW = 'view';
    static TEXTBOX = 301;
    static DROPDOWN = 302;
    static CHECKBOX = 303;
    static RADIO = 304;
    static TAREA = 305;
    static DATEFIELD = 306;
    static DATETIME = 307;
    static MULTI = 308;
    static STRING = 10001;
    static INT = 10002;
    static BIGINT = 10003;
    static DECIMAL = 10004;
    static DATE = 10005;
    static DATETM = 10006;
    static BOOLEAN = 10007;
    static CHAR = 10008;
    static OTHER = 399;
    static DEFAULT_PUBLICATION_STATUS = 9502;
    static PUBLISHED_PUBLICATION_STATUS = 9503;
    static ADOPTED_PUBLICATION_STATUS = 9501;
    static fieldName = 'fieldName';
    static CUSTOMFIELD = 'FIELD';
    static CUSTOMFIELDGROUP = 'FIELDGROUP';
    static IS_SCHEDULE = 'SCHEDULE';
    static IS_SESSION = 'SESSION';
    static LOOKUP_SUBMIT_TYPE_INCLASS = 32103;
    static CALENDAR_EVENT_TYPE_PERIOD = 'Period';
    //Department related Constant
    static IS_DELETED = 'deleted';
    static IS_CREATE_IN_ALL_SCHOOLS = 'createInAllSchools';
    static IS_REPORTER = 'isreporter';
    //Datatable id related constant
    static GRADELEVEL_DATATABLE = 'gradeLevelsTable';
    static SECTION_DATATABLE = 'sectionTable';
    static SESSION_DATATABLE = 'sessionTable';
    static ROOM_DATATABLE = 'roomTable';
    static PERIOD_DATATABLE = 'periodTable';
    static ROLE_DATATABLE = 'roleTable';
    static DEPARTMENT_DATATABLE = 'departmentTable';
    static STUDENT_LIST_DATATABLE = 'studentlist';
    static ACTIVITY_GROUP_DATATABLE = 'activityGroup';
    static ACTIVITY_GROUP_ADD_DATATABLE = 'activitygroupadd';
    static STAFF_LIST_DATATABLE = 'staffList';
    static SCHOOL_CUSTOM_CATEGORY = 'SCHL_FIELD';
    static STUDENT_CUSTOM_CATEGORY = 'STUD_FIELD';
    static STAFF_CUSTOM_CATEGORY = 'STAF_FIELD';
    static TABLE_RESET_COUNT = 10;
    //Staff related constant
    static IS_EMERGENCY_CONTACT = 'isEmergencyContact';
    static LOOKUP_CALENDAR_EVENT_TYPE = 'lookupCalendarEventType';
    static IS_ALL_DAY_LONG = 'isAllDayLong';
    static HOME_ADDRESS = 'Home Address';
    static OTHER_ADDRESS = 'Other Address';
    //Student related constant
    static ENROLLMENT_STATUS_NO_SHOW = 'No Show';
    static DEFAULT_GENDER = "M";
    //Person related constant
    static IS_HOME_PHONE = 'Home Phone';
    static IS_MOBILE_PHONE = 'Mobile Phone';
    static IS_WORK_PHONE = 'Work Phone';
    static IS_FAX = 'Fax';
    //Calendar Crisis related constant
    static IS_EFFECT_DAY_ACTIVITY = 'isInterruptDayActivity';
    //Staff Leave related constant
    static IS_PARTIAL_DAY_LEAVE = 'isPartialDayLeave';
    static IS_NOTIFIED = 'isNotified';
    //Course Manager
    static COURSEMANAGER_SUBJECT = 'subject';
    static COURSEMANAGER_COURSE = 'course';
    static COURSEMANAGER_COURSESECTION = 'courseSection';
    static DELETELEVEL = 'level';
    static IS_EXAM = 'isExam';
    static IS_ASYNCHRONOUS = 'isAsynchronous';
    static INSTRUCTIONAL_MINUTES = 'instructionalMinutes'
    static MAXIMUM_REPETABILITY = 'maximumRepeatibility';
    static COURSE_WEIGHT = 'courseWeight';
    static CARNAGE_UNIT_CREDIT = 'carnegieUnitCredit';
    static CREDIT_VALUE = 'creditValue';
    static COURSES_ID = 'markersCoursesId';
    static COURSES_SUBJECT_ID = 'markersCoursesSubjectId'
    static COURSE_SUBJECT_ID = 'markersSubjectId';
    static COURSE_ID = 'markersCourseId';
    static COURSE_SECTION_ID = 'markersCourseSectionId';
    static COURSE_SUBJECT_TITLE = 'markersSubjectTitle';
    static ROOM_TYPE_PHYSICAL = 34001;
    static ROOM_TYPE_VIRTUAL = 34002;
    static ROOM_TYPE_HYBRID = 34003;
    //Discipline
    static IS_DECLINE = "Declined";
    static IS_PENDING = 'Pending';
    static IS_APPROVED = 'Approved';
    static IS_CANCELLED = 'Cancelled';
    static IS_CERTIFIED = 'Certified';
    static IS_VERIFIED = 'Verified';
    static PERPETRATOR = 'perpetrator';
    static VICTIM = 'victim';
    static VERIFICATION_DECLINE_LOOKUP_ID = 10703;
    static CERTIFICATION_DECLINE_LOOKUP_ID = 10803;
    static VERIFICATION_APPROVED_LOOKUP_ID = 10702;
    static CERTIFICATION_APPROVED_LOOKUP_ID = 10802;
    //User Role
    static SCHOOL_ROLE_DIST_ADMIN = 802;
    static SCHOOL_ROLE_ADMIN = 2701;
    static SCHOOL_ROLE_TEACHER = 2702;
    static SCHOOL_ROLE_PARENT = 2703;
    static SCHOOL_ROLE_STUDENT = 2704;
    static SCHOOL_ROLE_COUNCILLOR = 2705;
    static SCHOOL_ROLE_ACCOUNTANT = 2706;
    static SCHOOL_ROLE_HR = 2707;
    static SCHOOL_ROLE_CUSTOM = 2799;
    static STAFF_ROLE_DRIVER = 2709;
    static STAFF_ROLE_CONDUCTOR = 2710;
    static SCHOOL_ROLE_TRANSPORTATION_ADMIN = 2708;
    //user role type
    static SCHOOL_USER_ROLE_TYPE_CUSTOM = 2799;
    static SCHOOL_USER_ROLE_TYPE_DEFAULT = 2701;
    //Transcript
    static IS_EXCLUDE = 'isExcludeMassDelete';
    static SYSTEM = 18501;
    static MANUAL_OUT = 18502;
    static MANUAL_IN = 18503;
    //State Reporting Entity Title
    static ORG_LEA_SR = 'Local Education Agency - State Reporting Page';
    static ORG_SCH_SR = 'School - State Reporting Page';
    static ORG_RSD_SR = 'Residential School - State Reporting Page';
    static ORG_PRD_PI = 'Period - Period Info Page';
    static ORG_CRS_CI = 'Course - Course Info Page';
    static ORG_PRG_PI = 'Program - Program Info Page';
    static SSN_PRD_SR = 'Session - State Reporting Page';
    //State Reporting Entity Key
    static STATE_ORG_LEA_SR = 'ORG_LEA_SR';
    static STATE_ORG_SCH_SR = 'ORG_SCH_SR';
    static STATE_ORG_RSD_SR = 'ORG_RSD_SR';
    static STATE_ORG_PRD_PI = 'ORG_PRD_PI';
    static STATE_ORG_CRS_CI = 'ORG_CRS_CI';
    static STATE_ORG_PRG_PI = 'ORG_PRG_PI';
    static STATE_SSN_PRD_SR = 'SSN_PRD_SR';
    static STATE_MSE_CSP_SR = 'MSE_CSP_SR';
    static STATE_STE_SC_EI = 'STE_SC_EI';
    static STATE_STF_SEC_AS = 'STF_SEC_AS';
    static STATE_ORG_CAL_CI = 'ORG_CAL_CI';
    static STATE_ORG_MI_SR = 'ORG_MI_SR';
    static STATE_STF_INF_SR = 'STF_INF_SR';
    static STATE_STF_RES_SR = 'STF_RES_SR';
    static STATE_STD_PRN_SR = 'STD_PRN_SR';
    static STATE_STU_EXT_SR = 'STU_EXT_SR';
    static STATE_STD_RES_FA = 'STD_RES_FA';
    static STATE_STD_SLE_SR = 'STD_SLE_SR';
    static STATE_STD_SG_PE = 'STD_SG_PE';
    static STATE_STU_RSD_SR = 'STU_RSD_SR';
    static STATE_STU_TRA_SR = 'STU_TRA_SR';
    static STATE_STD_DLA_SR = 'STD_DLA_SR';
    static STATE_STD_ESY_SR = 'STD_ESY_SR';
    static STATE_ORG_GP_SR = 'ORG_GP_SR';
    static STATE_STU_GEN_NM = 'STU_GEN_NM';
    static STATE_SP_EDU_EVE = 'SP_EDU_EVE';
    static STATE_SP_EDU_SER = 'SP_EDU_SER';
    static STATE_STU_DG_NM = 'STU_DG_NM';
    static STATE_STU_COH_NM = 'STU_COH_NM';
    static STATE_STA_GEN_NM = 'STA_GEN_NM';
    static STATE_SCH_ROL_NM = 'SCH_ROL_NM';
    static STATE_SSN_ASC_SR = 'SSN_ASC_SR';
    static STATE_STU_DIS_TX = 'STU_DIS_TX';
    //NM State Keys
    static STATE_ORG_LEA_NM = 'ORG_LEA_NM';
    static STATE_STU_DIS_NM = 'STU_DIS_NM';
    static STATE_ST_AWD_NM = 'ST_AWD_NM';
    //RegExp  
    static NOT_ALLOW_SELECT = /^(?!.*Select).*$/i;
    static NOT_ALLOW_ZERO = /^(?=.*[1-9])\d*\.?\d*$/;
    static RGX_COMPOSE_EMAIL_BODY = /^(?!\s)[0-9a-zA-Z-,<>/'.%#&@&nbsp;\s\^\']+$/i;
    static RGX_COMPOSE_EMAIL = /^(?!\s)[0-9a-zA-Z-,<>/'.%#&@&nbsp;\s\']+$/i;
    static RGX_COMPOSE_EMAIL_NEW = /^(?!\s)[0-9a-zA-Z\s\,.!-\=+_?<>@#$%&'/():;\\]+$/i;

    static SPECIAL_ALPHA_CHAR = /^.{ 1, 50 }$/;
    static SPECIAL_CHAR_ID = /^(?!\s)[a-z\d\-.:/\s]+$/i;  //special characters included -.:/
    static SPECIAL_CHAR_ID_DIST = /^(?!\s)[a-z\d\-.:&/\s]+$/i;
    static SPECIAL_CHAR_ID_MAX_SIX = /^(?!\s)[a-z\d\-.:/\s]{1,6}$/i;  //special characters included -.:/
    static SPECIAL_CHAR_ID_MAX_TEN = /^(?!\s)[a-z\d\-.:/\s]{1,10}$/i;  //special characters included -.:/
    static SPECIAL_CHAR_TEXTAREA = /^(?!\s)[a-z\d\,'-.:/\s]+$/i;  //special characters included ,'-.:/
    static SPECIAL_CHAR_NAME = /^(?!\s)[a-z\d\,.-\s]+$/i;  //special characters included ,.-
    static SPECIAL_CHAR_DESCRIPTION = /^(?!\s)[a-z\d\,'-./\s]+$/i;  //special characters included ,.-/'
    static SPECIAL_LISENCE_DESCRIPTION = /^(?!\s)[a-z\d\,-./\s]+$/i;  //special characters included ,.-/'
    static SPECIAL_CHAR_IDENTIFICATION_CODE = /^(?!\s)[a-z\d\'-/\s]+$/i;  //special characters included -/'
    static ISSUER_NAME = /^(?!\s)[a-zA-Z\s]+$/i; //only alphabates with space
    static ISSUER_AUTHORITY = /^(?!\s)[a-zA-Z,-./\s]+$/i; //only alphabets with sepcial characters ,.-/
    static CODE = /^(?!\s)[0-9a-zA-Z,-./#&@:\s]+$/i;
    static COMMENTS = /^(?!\s)[0-9a-zA-Z,-./#&@:\s]+$/i;
    static IDENTIFIER = /^[a-zA-Z\d]+$/i;
    static SPECIAL_TEXTAREA_ID = /^(?!\s)[a-z\d\-.:/,&\s]+$/i;
    static GRADE_REGEX = /^\d{0,4}$/;
    static WEBSITE_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z\d\/\\&.:\s][a-zA-Z\d\/\\&.:\s]+[a-zA-Z\d\/\\&.:\s]\.[^\s]{2,}|www\.[a-zA-Z\d\/\\&.:\s][a-zA-Z\d\/\\&.:\s]+[a-zA-Z\d\/\\&.:\s]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z\d\/\\&.:\s]+\.[^\s]{2,}|www\.[a-zA-Z\d\/\\&.:\s]+\.[^\s]{2,})/;
    static DISTRICT_WEBSITE_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z\d\/\\&.:\s][a-zA-Z\d\/\\&.:\s]+[a-zA-Z\d\/\\&.:\s]\.[^\s]{2,}|www\.[a-zA-Z\d\/\\&.:\s][a-zA-Z\d\/\\&.:\s]+[a-zA-Z\d\/\\&.:\s]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z\d\/\\&.:\s]+\.[^\s]{2,}|[-\s]|www\.[a-zA-Z\d\/\\&.:\s]+\.[^\s]{2,})/;
    //static LMS_URL = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    static LMS_URL = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9\_./-;&]+([\-\.]{1}[a-z0-9\./-;&]+)*\.[a-z\./-;&]{2,5}(:[0-9]{1,5})?(\/.*)?[^!#$%^]$/
    static SCHOOL_NAME_REGEX = /^(?!\s)[A-zÀ-ú\d\'^\s\-]+$/i;
    static STREET_NO_NAME = /^(?!\s)[0-9a-zA-Z\s\-:&'., /]+$/i;
    static ADDRESS_CITY_STATE_COUNTRY = /^(?!\s)[-(A-Za-z) ]+$/     //allows only alphabets and space
    static STREETNAME = /^(?!\s)[0-9a-zA-Z'#\s\-:&./]+$/i;
    static POSTAL_CODE_ZIP = /^(?!\s)[0-9-\s]*[0-9-\s][0-9-\s]*$/
    static POSTAL_CODE_ZIP_ADDRESS = /^(?!\s)[0-9-/\s]*[0-9-/\s][0-9-/\s]*$/
    static PHONE_NUMBER_FORMAT = /^(?!\s)[0-9-()\s]*[0-9-()\s][0-9-()\s]*$/;
    static PHONE_NUMBER_FORMAT_V2 = /^\d{10}$/;
    static AREA_CODE = /^(?!\s)[0-9+-\s]*[0-9+-\s][0-9+-\s]*$/;
    static AREA_CODE_PREMISE = /^[0-9+-]+$/;
    static GRADE_SCALE_CODE = /^[0-9a-zA-Z+-]+$/; //only accept alphanumeric with +- without space
    static PHONE_EXT = /^[0-9]+$/;   //only numbers
    static HOUR_EXT = /^(0[0-9]|1[0-9]|2[0-3])$/;   //only 24 hours Format
    static MINUTE_EXT = /^(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])$/;   //only Minute Format
    static AREA_CODE_DISTRICT = /^(?!\s)[0-9\s\-]+$/i;
    static LMS_SETUP = /^(?!\s)[0-9a-zA-Z\s\-;&'._,/]+$/i;
    static LMS_PROJECT_ID = /^[ A-Za-z0-9_@.,;/&+-]*$/;
    static EMAIL = /^([\.\_\-a-zA-Z0-9\@$]+)@([\.\_\-a-zA-Z0-9\.@$]+)\.([a-zA-Z]){2,8}$/;
    // static EMAIL = /^([a-zA-Z\d\.-_@$]+(-?[a-zA-Z\d\.-_@$])*\.)+[\w]{2,}(\/\S*)?$/
    static DESIGNATION = /^(?!\s)[a-zA-Z\'.\s]+$/i;
    //static GROUP_TITLE = /^(?!\s)[a-zA-Z\d\-&:/s]+$/i;
    //static GROUP_TITLE = /^(?!\s)[0-9a-zA-Z\s\-:&'/]+$/i;   //accepts alphanumeric and special characters &:/- and space 
    static FIELD_NAME_LABEL_VALIDATION_MESSAGE = /^(?!\s)[a-z\d\-.%#&\s]+$/i;
    static CALENDAR_TITLE = /^(?!\s)[a-z\d\.""\-'#&*()\/+\s]+$/i;
    static DESCRIPTION = /^(?!\s)[a-z\d\-.'&*""\s]+$/i;
    static EVENT_TITLE = /^(?!\s)[a-z\d\.""\-'&*()\/+\s]+$/i;
    static DESCRIPTION_EVENT = /^(?!\s)[a-z\d\.""\-'&*()\s]+$/i;
    static PERIOD_TITLE = /^(?!\s)[a-z\d\-.\s]+$/i;    //accepts charecters -.
    static PERIOD_CODE = /^(?!\s)[a-z\d\-./\s]+$/i;
    static EXIT_CODE = /^(?!\s)[a-z'#\d\-/.&:-\s]+$/i;
    static GRADE_CODE = /^(?!\s)[a-z\d\-'#/\s]+$/i;
    static SECTION_TITLE = /^(?!\s)[a-z\d\.-\s]+$/i;
    static SECTION_CODE = /^(?!\s)[a-z\d\-.+&\s]+$/i;
    static SECTION_DESCRIPTION = /^(?!\s)[a-z\d\.,-/'\s]+$/i;
    static SESSION_CODE = /^(?!\s)[a-z\d\-.""'&/\s]+$/i;
    static ROLLOVER_SESSION_CODE = /^(?!\s)[a-z\d\-/&/\s]+$/i;
    static PREMISES_TITLE = /^(?!\s)[a-z\d\-.'#&\s]+$/i;
    static DESCRIPTION_SESSION = /^(?!\s)[a-z\d\-.""'/,&#\s]+$/i;
    static ROOMS_DESCRIPTION = /^(?!\s)[a-z\d\-.'&()%$\s]+$/i;
    static CALENDAR_CRISIS_DESCRIPTION = /^(?!\s)[a-z\d\-.%#&\s]+$/i;
    static DEPARTMENT_TITLE = /^(?!\s)[a-z\d\-./&,#\s]+$/i;
    static DEPARTMENT_DESCRIPTION = /^(?!\s)[a-z\d\-./,&#\s]+$/i;
    static HOLIDAY_MANAGEMENT_TITLE_DESCRIPTION = /^(?!\s)[a-z\d\-&/:\s]+$/i;
    static HOLIDAY_TITLE = /^(?!\s)[a-z\d\-,.,%,#,&,/\s]+$/i;
    static PREMISE_ADDRESS = /^(?!\s)[a-z\d\-.:,&\s]+$/i;
    static PHONE_NUMBER_ADD_PERSON = /^(?!\s)[0-9+-\s]*[0-9+-\s][0-9+-\s]*$/
    static PHONE_NUMBER_ADD = /^(?!\s)[0-9+]*[0-9+][0-9+]*$/
    static NAME_SUFFIX = /^(?!\s)[a-zA-Z ]*$/;   //allows alphabets and space only
    static STUDENT_GENERAL_INFO = /^(?!\s)[A-zÀ-ú\d\'^\s\-]+$/i;
    static STUDENT_STAFF_GENERAL_INFO = /^[a-zÀ-úA-Z0-9 ^\'\-. ]+$/i;
    static STUDENT_CODE = /^(?!\s)[a-z\d\-/\s]+$/i;
    static STUDENT_CITY_STATE = /^(?!\s)[a-zA-Z\s()]*$/;
    static SYSTEM_NAME = /^(?!\s)[a-z\.,-\s]+$/i;
    static IDENTIFICATION_CODE = /^(?!\s)[0-9a-zA-Z\d\-/\s\_\&]+$/i;
    static SCHEDULE_CAST_NAME = /^(?!\s)[a-z\d\-.,\s]+$/i;
    static TRIBAL_CAST_NAME = /^(?!\s)[a-z\d\-.,'\s]+$/i;
    static REFERRAL_REGEX = /^(?!\s)[a-z\d\-.,'\s]+$/i;
    static MARKING_PERIOD_DESCRIPTION = /^(?!\s)[a-z\d\-.:&\s]+$/i;
    static INSURANCE_NAME = /^(?!\s)[a-z\d\-.&/\s]+$/i;
    static ALLERGIC_DRUG_DETAILS = /^(?!\s)[a-z\d\-.:&',/\s]+$/i;
    static REASON_FOR_MEDICATION = /^(?!\s)[a-z\d\-:./\s]+$/i;
    static DOCUMENT_NAME = /^(?!\s)[a-z\d\-.:/\s]+$/i;
    static IMMUNIZATION_NAME = /^(?!\s)[a-z\d\-.'\s]+$/i;
    static COHORT_DESCRIPTION = /^(?!\s)[a-z\d\-.,/'\s]+$/i;
    static DOCTORS_COMMENTS = /^(?!\s)[a-z\d\-.,/'\s]+$/i;
    static UNION_MEMBERSHIP_NAME = /^(?!\s)[a-z\d\-.,&\s]+$/i;
    static CERTIFICATE_TITLE = /^(?!\s)[a-z\d\-.,\s]+$/i;
    static INSTITUITION_NAME = /^(?!\s)[a-z\d\-.,\s]+$/i;
    static ISSUER_NAME_PERSON = /^(?!\s)[a-z\-.'\s]+$/i;
    static DOCUMENT_IDENTIFICATION_CODE = /^(?!\s)[a-z\d\-'/\s]+$/i;
    static CERTIFICATE_NUMBER = /^(?!\s)[a-z\-./\s]+$/i;
    static JOB_TITLE = /^(?!\s)[a-z\d\-'/\s]+$/i;
    static JOB_CODE = /^[a-zA-Z0-9\s]*$/i;
    static JOB_DESCRIPTION = /^(?!\s)[a-z\d\-.,#&/\s]+$/i;
    static LEAVE_DESCRIPTION = /^(?!\s)[a-z\d\-.,'&/\s]+$/i;
    static GRADE_SCALE_TITLE = /^(?!\s)[a-z\d\-:&/\s]+$/i;
    static GRADE_SCALE_DESCRIPTION_NEW = /^(?!\s)[a-z\d\-:;+,%<>=&/\s]+$/i;
    static EMPLOYEE_CODE = /^(?!\s)[a-z\d\-./\s]+$/i;
    static LICENCE_NO = /^(?!\s)[a-z\d\-./\s]+$/i;
    static ADDRESS_COUNTY_REGEX = /^(?!\s)[A-Za-z ]+$/   //matches alphabets and space only
    static SUBJECT_CODE = /^(?!\s)[a-z\d\-:&'_/\s]+$/i;
    static SUBJECT_DESCRIPTION = /^(?!\s)[a-z\d\-.&%#/\s]+$/i;
    static COURSE_DESCRIPTION = /^(?!\s)[,a-z\d\-.&%#/\s]+$/i;
    static CERIFICATE_DESCRIPTION = /^(?!\s)[a-z\d\-.&#\s]+$/i;
    static FUNDING_PROGRAM = /^(?!\s)[a-z\d\-&'\s]+$/i;
    static ASSESSMENT_CODE = /^(?!\s)[a-z\d\-/'\s]+$/i;
    static ASSESTS_TITLE = /^(?!\s)[a-z\d\-'&/\s]+$/i;
    static SEA_LEA_ID = /^(?!\s)[1-9-]*[0-9-][0-9-]*$/;
    static EVENT_ATENDANCE = /^(?!\s)[a-z\d\,&-./\s]+$/i;  // Special Characters Included here are '&, , , ., -, /,;
    static DECIMAL_VALUE = /^(?!^0*$)(?!^0*\.0*$)^\d{1,5}(\.\d{1,2})?$/; // Check a Decimal value Upto (5,6)
    static FLOAT_VAL = /^[0-9]*\.?[0-9]*$/; // Allow only number and single dot
    static DECIMAL_REGEX = /^[1-9]\d*(\.\d+)?$/i;    //matches decimals and numbers
    static DECIMAL_REGEX_WITH_ZERO = /^[0-9]\d*(\.\d+)?$/i;    //matches decimals and numbers
    static PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[!@#^&*])[a-zA-Z0-9!@#^&*]{3,20}$/;
    static INCIDENT_CODE_DESCRIPTION = /^(?!\s)[a-z\d\-.,;&/\s]+$/i;
    static LATE_REASON = /^(?!\s)[a-z\d\-.,&'/;\s]+$/i;
    static PRECISION_NINE_WITH_SCALE_THREE = /^\d{1,9}(\.\d{1,3})?$/       //Matches numeric digits with length 9 and scale of 3
    static OCCUPATION_TITLE_DESIGNATION = /^(?!\s)[a-z\d\-'\s]+$/i;
    static MONTHLY_INCOME = /^(?!\s)[0-9-.,\s]*[0-9-.,\s][0-9-.,\s]*$/
    static PRECISION_FIVE_WITH_SCALE_TWO = /^\d{0,5}(\.\d{1,2})?$/   //Matches numeric digits with length 5 and scale of 2
    static MIN_VALUE = /^[1-9]+$/; //only numbers 1 to 9
    static MIN_VALUE_EXPECTEDCOUNT = /[1-9]\d*/; //not acceptiong 0
    static GRADE_SCALE_VALUE = /^[0-9]+$/;//only numbers 0 to 9
    static STAFF_CONTACT_RESTRICTION_NOTES = /^(?!\s)[a-z\d\-./&@'\s]+$/i;
    static RUBRIC_TITLE = /^(?!\s)[0-9a-zA-Z\s\-._]+$/i; //special characters included a-z, A-Z, 0-9, space, ._-
    static RUBRIC_DESCRIPTION = /^(?!\s)[0-9a-zA-Z\s\-._&/#]+$/i; //special characters included a-z, A-Z, 0-9, space, ._-&/#
    static ASSIGNMENT_TITLE = /^(?!\s)[0-9a-zA-Z\s\-:&'/]+$/i; //special characters included a-z, A-Z, 0-9, space, -/:'&
    static MARKING_PERIOD_EXAM_CODE = /^(?!\s)[a-z\d\-:/#@\s]+$/i;
    static LEADING_CHAR = /^(?!\s)[0-9a-zA-Z\s\-./]+$/i; //accepts alphabets, 0-9, with charecters -./
    static COHORT_FIELDS = /^(?!\s)[0-9a-zA-Z\s\-,./':]+$/i; //special characters included ,.-/':
    static MESSAGE_FIELD = /^([a-zA-Z0-9 _-]+)$/;
    static COMPOSE_MESSAGE_FIELD = /^(?!\s)[0-9a-zA-Z\s-,.%@#&/]+$/i;
    static GRADUATION_PLAN_NAME_CODE = /^(?!\s)[a-z\d\-:.&/\s]+$/i;
    static CREDENTIAL_TITLE = /^(?!\s)[0-9a-zA-Z\s\-.&]+$/i; //special characters included a-z, A-Z, 0-9, space, &.-
    static ALPHANUMERIC = /^[a-zA-Z0-9]+$/;
    static CREDENTIAL_DESCRIPTION = /^(?!\s)[0-9a-zA-Z\s\-.&,#/]+$/i; //special characters included a-z, A-Z, 0-9, space, -/.,#&
    static COMMENT_CODE = /^(?!\s)[0-9a-zA-Z\s\-.&,#/@:]+$/i; //special characters included a-z, A-Z, 0-9, space, -/.,#&@:
    static SUBJECT_VALUE = /^(?!\s)[0-9a-zA-Z\s\-.&,#@""*']+$/i;
    static REGEX_ALPHA_NUM_SPACE_ND_QT1_DSH_2PW = /^(?!\s)[0-9a-zA-Z\s\-'^.,&]+$/i; //special characters included a-z, A-Z, 0-9, space, &'-^.,
    static ASSESSMENT__DESCRIPTION = /^(?!\s)[a-z\d\-,.,%,#,&,/\s]+$/i;
    static REPORT_CARD_RUN_NAME = /^(?!\s)[0-9a-zA-Z\s\-.&,#@""*'!=><?$%()|~:;\\+-`{}]+$/i; // Alphabets [A-Z] [a-z], Numeric [0-9], Spaces and All Special Character [" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~". 
    //File Types 
    static FILE_TYPE_DOCS = 'jpeg|jpg|png|pdf';
    static FILE_TYPE_DOCS_MAXSIZE = 2000000;
    static FILE_TYPE_DOCS_MINSIZE = 1024;
    static FILE_TYPE_EMAIL_ATTACHMENT_MAXSIZE = 10000000;
    static FILE_TYPE_EMAIL_ATTACHMENT_MINSIZE = 1024;
    static FILE_TYPE_ASSIGNMENT_ANSWER_MAXSIZE = 10485760;
    static FILE_TYPE_ASSIGNMENT_DOCS_MAXSIZE = 1249;
    static FILE_TYPE_ASSIGNMENT_DOCS_MINSIZE = 1;
    static FILE_TYPE_EXCEL = 'xls';
    static FILE_TYPE_PDF = 'pdf';
    static FILE_TYPE_QUALIFICATION_CERTIFICATE = 'docx|jpg|png|doc|pdf|jpeg|pptx|ppt|xls|xlsx';
    static FILE_TYPE_DOCS_COPY = 'doc|docx|docs|pdf';
    static FILE_TYPE_ALL_DOCS = 'doc|docx|docs|pdf|pptx|ppt|xls|xlsx|odt';
    static FILE_TYPE_ONLY_DOCS = 'doc|docx|docs';
    static FILE_TYPE_ALL_DOCS_EMAIL = 'doc|docx|pdf|xlsx|png|jpg';
    static FILE_TYPE_PERSONAL_DOCUMENT = 'docx|jpg|png|doc|pdf|jpeg|xlsx|xls'
    static FILE_TYPE_ASSESSMENT_ASSESTS = 'docx|doc|pdf|xlsx'
    static FILE_TYPE_STUDY_MATERIAL = 'pdf|doc|docx|xls|xlsx|avi|mov|mkv|mp4|flv|swf|wma|mpeg|mp3|wav|mpeg|ppt|pptx';
    static FILE_DEFAULT_ID = 173;
    static FILE_LOCATION_TYPE = 2101;
    static FILE_LOCATION_TYPE2 = 2301;
    //Reset store
    static COMMON_STORE_RESET = 'COMMON_STORE_STATE_RESET';
    static STUDENT_STORE_RESET = 'STUDENT_STORE_STATE_RESET';
    static STAFF_STORE_RESET = 'STAFF_STORE_STATE_RESET';
    static SCHOOL_STORE_RESET = 'SCHOOL_STORE_STATE_RESET';
    static COURSE_STORE_RESET = 'COURSE_STORE_STATE_RESET';
    static SCHEDULE_STORE_RESET = 'SCHEDULE_STORE_STATE_RESET';
    static REPORT_STORE_RESET = 'REPORT_STORE_STATE_RESET';
    static ATTENDANCE_STORE_RESET = 'ATTENDANCE_STORE_STATE_RESET';
    static DISCIPLINE_STORE_RESET = 'DISCIPLINE_STORE_RESET';
    static ASSESSMENT_STORE_RESET = 'ASSESSMENT_STORE_RESET';
    static DISTRICT_STORE_RESET = 'DISTRICT_STORE_RESET';
    static LOGIN_STORE_RESET = 'LOGIN_STORE_RESET';
    static SYSTEM_STORE_RESET = 'SYSTEM_STORE_RESET';
    static MESSAGE_STORE_RESET = 'MESSAGE_STORE_RESET';
    //DATEPICKER
    static END_DATE = "-1y";
    static BIRTH_DATE = "-14y";
    static END_DATE_AWARD = new Date();
    static AWARD_DATE_NAME = 'awardDate';
    //DISCIPLINE
    static REPORTED_TO_LAW_ENFORCEMENT = "isReportedToLawEnforcement";
    static DISABILITY_MANIFASTATION = "isDisabilityManifestation";
    static IS_FULL_YEAR_EXPULSION = "isFullYearExpulsion";
    static IS_EXPULSION_SHORTED = "isExpulsionShorted";
    static IS_ZERO_TOLLERANCE_RELATED = "isZeroTollerenceRelated";
    static IS_ES_AVAILABLE_AFTER_REMOVAL = "isESAvailableAfterEemoval";
    static IS_IEP_PLACEMENT_MEETING = "isIEPPlacementMeeting";
    static DISCIPLINARY_ACTION_DURATION = "disciplinaryActionDuration";
    //CUSTOM FIELD
    static ADOPTED_VALUE = 9501;
    static DRAFT_VALUE = 9502;
    static PUBLISHED_VALUE = 9503;
    //ASSESSMENT
    static INCLUDE_STUDENT_CODE = "includeStudentCode";
    static INCLUDE_DISTRICT_CODE = "includeDistrictCode";
    static INCLUDE_ENROLMENT_ID = "includeEnrolmentId";
    static INCLUDE_GRADE_LEVEL = "includeGradeLevel";
    static INCLUDE_STUDENT_PHOTO = "includeStudentPhoto";
    static INCLUDE_IDENTIFICATION_CODE = "includeIdentificationCode";
    static INCLUDE_ETHNICITY = "includeEthnicity";
    static INCLUDE_DATE_OF_BIRTH = "includeDateOfBirth";
    static SHOW_LETTER_GRADE = "showLetterGrade";
    static SHOW_PERCENTAGE = "showPercentage";
    static DISPLAY_BASE_GRADING_SCALE = "displayBaseGradingScale";
    static GRADE_PERCENTAGE = "gradePercentage";
    static STUDENT_INFO = "studentInfo";
    static IS_STUDENT_INFO = "isStudentInfo";
    static IS_STUDENT_CODE = "isStudentCode";
    static IS_INCLUDE_STUDENT_PHOTO = "isIncludeStudentPhoto";
    static IS_DISTRICT_CODE = "isDistrictCode";
    static IS_IDENTIFICATION_CODE = "isIdentificationCode";
    static IS_ENROLMENT_ID = "isEnrolmentID";
    static IS_ETHNICITY = "isEthnicity";
    static IS_GRADE_LEVEL = "isGradeLevel";
    static IS_DOB = "isDOB";
    static IS_GRADE_PERCENTAGE = "isGradePercentge";
    static IS_SHOW_LETTER_GRADE = "isShowLetterGrade";
    static IS_SHOW_PERCENTAGE = "isShowPercentage";
    static IS_INCLUDE_GPA = "isIncludeGPA";
    static IS_INCLUDE_CLASS_RANK = "isIncludeClassRank";
    static IS_SHOW_TEACHER = "isShowTeacher";
    static IS_TEACHER_COMMENTS_AND_SUGGESTIONS = "isTeacherCommentsAndSuggestions";
    static IS_ATTENDANCE = "isAttendance";
    static IS_DAILY_ATTENDANCE_SUMMARY = "isDailyAttendanceSummary";
    static IS_ATTENDANCE_YEAR_TO_DATE = "isAttendanceYearToDate";
    static IS_ATTENDANCE_CURRENT_MARKING_PERIOD = "isAttendanceCurrentMarkingPeriod";
    static IS_COURSE_WISE_ATTENDANCE_SUMMARY = "isCourseWiseAttendanceSummary";
    static IS_SIGNATURE = "isSignature";
    static IS_CLASS_TEACHER_COMMENTS_AND_SUGGESTIONS = "isClassTeacherCommentsAndSuggestions";
    static IS_PRINCIPAL_SIGNATURE = "isPrincipalSignature";
    static IS_PRINCIPAL_COMMENTS_AND_SUGGESTIONS = "isPrincipalCommentsAndSuggestions";
    static IS_STUDENT_SIGNATURE = "isStudentSignature";
    static IS_STUDENT_DATE = "isStudentDate";
    static IS_PARENT_GUARDIAN_SIGNATURE = "isParentGuardianSignature";
    static IS_PARENT_DATE = "isParentDate";
    static IS_INCLUDE_ISSUE_DATE = "isIncludeIssueDate";
    static IS_SHOW_GENERAL_ITEMS = "isShowGeneralItems";
    static IS_PROMOTION_STATUS = "isPromotionStatus";
    static IS_SHOW_BASE_GRADE_SCALE_DESCRIPTION = "isShowBaseGradeScaleDescription";
    static IS_INCLUDE_DISCIPLINE_RECORDS = "isIncludeDisciplineRecords";
    static IS_CLASS_TEACHER_SIGNATURE = "isClassTeacherSignature";
    static IS_MARKING_PERIOD = "isMarkingPeriod";
    static WEIGHTAGE_TO_FINAL_TRANSCRIPT = "weightageToFinalTranscript";
    static LEA_ID = "leaId";
    static SEA_ID = "seaId";
    static INCLUDE_GRADUATES = "includeWithdrawn";
    static INCLUDE_TRANSCRIPT = "onlyWithTranscript";
    //DISTRICT
    static DISTRICT_CONTROL_MODE_BOTH = "Both";
    static DISTRICT_CONTROL_MODE_MANUAL = "Manual";
    static DISTRICT_CONTROL_MODE_GENERATED = "Generated";
    static STUDENT_STAFF_ERROR_CODE = '-1';
    static LOOKUP_DCP_STAFF_IDENTIFICATIONSYSTEM = 'STAFF_IDENTIFICATION_SYSTEM';
    static LOOKUP_DCP_PROGRAM_TYPE = 'DCP_PROGRAM_TYPE';
    static LOOKUP_DCP_PROGRAM_TYPE_KEY = 'PROGRAM_TYPE';
    static LOOKUP_STUDENT_NAME_DISPLAY_PATTERN = 'STUDENT_NAME_DISPLAY_PATTERN';
    static LOOKUP_STUDENT_NAME_DISPLAY_SORT = 'STUDENT_NAME_DISPLAY_SORT';
    static LOOKUP_STAFF_NAME_DISPLAY_PATTERN = 'PERSON_NAME_DISPLAY_PATTERN';
    static DEFAULT_COUNTRY_STATE_LANGUAGE = 'DEFAULT_COUNTRY_STATE_LANGUAGE';
    static ALLOW_ROOM_MULTIPLE_SCHEDULE = 'ALLOW_ROOM_MULTIPLE_SCHEDULE';
    static LOOKUP_GRADUATION_PLAN_TYPE = 'GRADUATION_PLAN_TYPE';
    static LOOKUP_DIPLOMA_TYPE = 'NM_DIPLOMA_TYPE';
    static LOOKUP_GRADUATION_REQUIREMENT_TYPE = 'GRADUATION_REQUIREMENT_TYPE';
    static LOOKUP_GRADUATION_REQUIREMENT_YEAR = 'GRADUATION_REQUIREMENT_YEAR';
    static EDIT_PREVIOUS_SESSION_DATA = 'EDIT_PREVIOUS_SESSION_DATA';
    //staff Credentials Verification
    static DOC_CERTIFICATION_STATUS = "DOCUMENT_CERIFICATION_STATUS";
    //StateReporting LookUp
    static COMPONENT_SR_LOOKUP_FIELDTYPE_DATEFIELD = 306;
    static COMPONENT_SR_LOOKUP_DATATYPE_STRING = 10001;
    static COMPONENT_SR_LOOKUP_DATATYPE_INTEGER = 10002;
    static COMPONENT_SR_LOOKUP_DATATYPE_BIGINTEGER = 10003;
    static COMPONENT_SR_LOOKUP_DATATYPE_DECIMAL = 10004;
    static COMPONENT_SR_LOOKUP_DATATYPE_DATE = 10005;
    static COMPONENT_SR_LOOKUP_DATATYPE_DATETIME = 10006;
    static COMPONENT_SR_LOOKUP_DATATYPE_BOOL = 10007;
    static DASH_VALUE = "--";
    static METHOD_TYPE_POST = "POST";
    static METHOD_TYPE_GET = "GET";
    static COMPONENT_LOGIN = 'authentication';
    static COMPONENT_LOGIN_VALIDATE_SAVE = 'v1/authentication/authenticate';
    static COMPONENT_LOGIN_SSO_VALIDATE_SAVE = 'v1/authentication/authenticatessouser';
    static COMPONENT_RESOURCE = 'resource';
    static COMPONENT_STATEREPORTING = 'statereporting';
    static COMPONENT_RESOURCE_GET_USER_LAST_SESSION = 'v1/appmenu/GetLastSessionOfUser';
    static COMPONENT_RESOURCE_SET_USER_LAST_SESSION = 'v1/appmenu/SetLastSessionOfUser';
    static COMPONENT_RESOURCE_UPDATE_USER_PREFERENCE = 'v1/userpreference/updateuserpreference';
    static COMPONENT_RESOURCE_GET_USER_PREFERENCE_OTP = 'v1/userpreference/sendresetpassswordotp';
    static COMPONENT_RESOURCE_FORGOT_PASSWORD = 'v1/userpreference/forgotpassword';
    static COMPONENT_RESOURCE_CONFIRM_OTP = 'v1/userpreference/forgotpasswordconfirmation';
    static COMPONENT_RESOURCE_RESET_PASSWORD = 'v1/userpreference/resetpassword';
    static COMPONENT_RESOURCE_GET_LOOKUPS = 'v1/lookup/GetByLookupCategory';
    static COMPONENT_RESOURCE_GET_LOOKUPS_MERGED = 'v1/lookup/getbylookupcategorymerged';
    static COMPONENT_RESOURCE_GET_STATESPECIFIC_LOOKUPS = 'v1/lookup/getbylookupstatespecific';
    static COMPONENT_RESOURCE_GET_COUNTRY = 'v1/lookup/GetAllCountryList';
    static COMPONENT_SAVE_FILE = 'v1/filemanager/upload';
    static COMPONENT_GET_MENUS = 'v1/appmenu/getusermodulesandmenus?schoolid=';
    static COMPONENT_GET_USER_MENUS = 'v1/appmenu/getusermoduledetails?schoolid=';
    static COMPONENT_GET_NOTIFICATIONS = 'v1/notification/getMessages?schoolId=';
    static COMPONENT_GET_NOTIFICATIONS_V2 = 'v1/notification/getallreceivedmessages?schoolId=';
    static COMPONENT_MARKASREAD_NOTIFICATIONS = 'v1/notification/markAsRead';
    static COMPONENT_DOWNLOAD_FILE = 'v1/resource/downloadfile?fileInfoId=';
    static COMPONENT_DOWNLOAD_EMAIL_FILE = 'v1/adminsendemail/downloadattachmentfile?fileId=';
    static COMPONENT_RESOURCE_GET_STATE_BY_COUNTRY = 'v1/lookup/getallstatebycountry?countryCode=';
    static COMPONENT_RESOURCE_GET_COUNTY_BY_STATE = 'v1/lookup/getallcountybystate?state=';
    static COMPONENT_RESOURCE_GET_COLOUR = 'v1/colour/getallcolour';
    static COMPONENT_GETSTATEREPORTFIELD_VALUE = 'v1/dynamicuicomponent/getdynamicuicomponentdetails';
    static COMPONENT_GETCOURSESECTIONDETAILS_VALUE = 'v1/dynamicuicomponent/GetCourseSectionDetails';
    static COMPONENT_SAVE_STATEREPORTFIELD_VALUE = 'v1/dynamicuicomponent/postdynamicuicomponentfieldvalues';
    static COMPONENT_GET_STUDENT_RESTRAINT_DISABILITY_INFO = 'v1/dynamicuicomponent/getstudentrestraintdisabilityinfo?studentId=';
    static COMPONENT_GET_STUDENT_RESTRAINT_STATEREPORTFIELD_VALUE = 'v1/dynamicuicomponent/getstudentrestraintdetails';
    static COMPONENT_SAVE_STUDENT_RESTRAINT_VALUE = 'v1/dynamicuicomponent/savestudentrestraint';
    static COMPONENT_SR_GENERATE_REPORT_BY_REPORTNAME = 'v1/reportingexecution/generatereportbyreportname';
    static COMPONENT_SR_GENERATE_TREX_REPORT = 'v1/reportingexecution/generatetrexreport?rN=TREX&seId=';
    static COMPONENT_SR_GET_STATE_LIST = 'v1/reportingexecution/getstatelist';
    static COMPONENT_SR_GET_REPORT_TERMS_LIST = 'v1/reportingexecution/getreportingterms?stateCode=';
    static COMPONENT_SR_GET_STATE_REPORT_LIST = 'v1/reportingexecution/getstatereportlist';
    static COMPONENT_SR_GET_STATE_REPORT_NM_LIST = 'v1/reportingexecution/getstatetemplatelist';
    static COMPONENT_SR_GENERATE_NM_REPORT_BY_REPORTNAME = 'v1/reportingexecution/generatetemplatebytemplatename';
    static COMPONENT_SR_GET_REPORT_HISTORY_LIST = 'v1/reportingexecution/getstatereporthistorylist';
    static COMPONENT_SR_GET_DOWNLOAD_HISTORY_REPORT = 'v1/reportingexecution/downloadreportfile?fileid=';
    //Person
    static COMPONENT_PERSON_GETALL = 'v1/Person/GetAllPersonList';
    static COMPONENT_PERSON_GETBYID = 'v1/Person/GetPersonById?id=';
    static COMPONENT_GET_ROLEWISE_ALL_PERSON_LIST = 'v1/adminsendemail/getrolewiseallpersonlist?';
    static COMPONENT_GET_PERSON_ID_WITH_INCLUDE = 'v1/adminsendemail/getpersonidswithinclude'
    static COMPONENT_PERSON_GET_PERSON_BY_DISTRICT = 'v1/person/getallpersonlistdistrictwise';
    static COMPONENT_PERSON_SAVE = 'v1/Person/SavePerson';
    static COMPONENT_PERSON_UPDATE = 'v1/Person/UpdatePerson';
    static COMPONENT_PERSON_DELETE = 'v1/Person/DeletePerson';
    static COMPONENT_CUSTOMFIELD_SAVE = 'v1/customfieldvalue/saveupdatecustomfieldvalue';
    static COMPONENT_GETCUSTOMFIELD_VALUE = 'v1/customfieldgroup/GetCustomFieldGroupValueBySchoolIdAndMenuCode';
    static COMPONENT_CUSTOM_FIELD_CATEGORY_GET_ALL = 'v1/appmenu/GetUserMenuDetailsForMenuCode?menuCode=';
    static COMPONENT_PERSON_CREDENTIAL_DELETE_BYID = 'v1/Person/DeletePersonCredential';
    //Person address 
    static COMPONENT_ADDRESS_DELETE_BYID = 'v1/Person/DeletePersonAddress';
    //Global Search
    static COMPONENT_SEARCH_RESULT = 'v1/Search/GetSearchResults';
    static COMPONENT_ADVANCED_SEARCH_FIELDS = 'v1/Search/GetAdvancedSearchFields';
    //DashBoard
    static COMPONENT_GET_DASHBOARD_ADMIN_SCHOOL = 'v1/Dashboard/GetDashboardDetail';
    static COMPONENT_GET_DASHBOARD_ADMIN_ATTN_SCHOOL = 'v1/Dashboard/GetAttendanceCountAdminDashboard?schoolId=';
    static COMPONENT_GET_DASHBOARD_ANNOUNCEMENT = 'v1/Dashboard/GetAnnouncementByUserRole?roleId=';
    static COMPONENT_DASHBOARD_CALENDAR_EVENT_DAY = 'v1/Dashboard/GetCalendarEventsForDay';
    static COMPONENT_DASHBOARD_CALENDAR_EVENT_MONTH = 'v1/Dashboard/GetCalendarEventsForMonth';
    //Dynamic Form
    static COMPONENT_GET_SCHOOL_WISE_DYNAMIC_FORM = 'v1/dynamicform/getschoolwisedynamicform';
    static COMPONENT_GET_ALL_SCHOOL_WISE_DISTINCT_FORM = 'v1/dynamicform/getdistinctformschoolwise';
    static COMPONENT_SAVE_DYNAMIC_FORM_VALUE = 'v1/dynamicform/savedynamicformvalue';
    //Generate Student Password Scheduler
    static COMPONENT_STUDENT = 'student';
    static COMPONENT_GENERATE_STUDENT_PASSWORD_SCHEDULER = 'v1/StudentInfo/TaskForStudentPasswordGeneration';
    // app menu permissions
    static COMPONENT_STUDENT_GET_ROLE_BASED_MENU_PERMISSIONS = 'v1/appmenu/getrolebasedappmenu?moduleKey=';
    //UserLoginAccessHistory    
    static COMPONENT_RESOURCE_ACCOUNT_ACCESS = 'v1/userloginaccesshistory/getallbyuserid?userId=';
    static COMPONENT_RESOURCE_ACCOUNT_ACCESS_SAVE = 'v1/userloginaccesshistory/saveuseraccess';

    //Session Storage
    static SESSION_LOOKUPS = 'markersLookups';
    static SESSION_CUSTOM_CATEGORY = 'markersCustomCategory';
    static SESSION_LAST_USER_DETAILS = 'markersLastUserSessionDetails';
    static SESSION_DISTRICTID = 'markersDistrictId';
    static SESSION_CUSTOMERID = 1001;
    static SESSION_SCHOOLID = 'markersSchoolId';
    static SESSION_SCHOOLYEAR = 'markersSchoolYear';
    static SESSION_SCHOOLSESSION = 'markersSchoolSession';
    static SESSION_ID = 'markersSessionId';
    static SESSION_MARKINGPERIOD_ID = 'markersSessionMarkingPeriodId';
    static SESSION_SCHOOLNAME = 'markersSchoolName';
    static SESSION_SCHOOL_PHOTO = 'markersSchoolPhoto';
    static SESSION_SCHOOLADDRESS = 'markersSchoolAddress';
    static SESSION_TOKEN = 'markersToken';
    static SESSION_SSO_TOKEN = 'markersSsoToken';
    static SESSION_LANGUAGE = 'i18nextLng';
    static SESSION_DATE_FORMAT = 'markersDateFormat';
    static SESSION_CALENDARID = 'markersCalendarId';
    static SESSION_ROUTEPATH = 'markersRoutePath';
    static SESSION_USER_ID = 'markersUserId';
    static SESSION_USER_NAME = 'markersUserName';
    static SESSION_PHONE_FORMAT = 'markersPhoneFormat';
    static SESSION_NEWPERSONID = 'markersNewPersonId';
    static SESSION_NEWPERSONNAME = 'markersNewPersonName';
    static SESSION_IDLETIMER = 'markersIdleTimer';
    static SESSION_ROLE_BASED_PERMISSION_LIST = 'markersRoleBasedPersmissionList';
    static SESSION_CURRENT_PAGE_PERMISSION = 'markersCurrentPagePermission';
    static SESSION_SCHOOL_BASED_ROLE_LIST = 'markersSchoolBasedRoleList';
    static SESSION_SCHOOL_BASED_MULTI_ROLE_LIST = 'markersUserSelectedSchoolRoleList';
    static SESSION_SELECT_SCHOOL = "markersSchoolSelect";
    static SESSION_MP_SCHOOL = "markersSchoolSessionMp";
    static SESSION_LOGGED_USER_ROLE = 'markersUserRoleId';
    static SESSION_USER_ROLE = 'markersUserRole';
    static SESSION_MANAGE_INCIDENT_TYPE = 'markersManageIncidentType';
    static SESSION_MENU_CODE = 'markersMenuCode';
    static SESSION_NEXT_CLASS_SHOW = 'markersNextClass';
    static SESSION_NEXT_CLASS_START = 'markersNextClassStart';
    static SESSION_LOGIN_URL = 'markersLoginUrl';
    static SESSION_ENVIRONMENT = 'markersEnvironment';
    static SESSION_TIME_ZONE = 'markersTimeZone'
    static SESSION_STATECODE = 'markersStateCode';
    static SESSION_COUNTRYCODE = 'markersCountryCode';
    static SESSION_FILE_MAXSIZE = 'defaultMaxFileSize';
    static SESSION_FILE_MINSIZE = 'defaultMinFileSize';
    //Students
    static SESSION_STUDENT_GRADE_LEVEL_ID = 'markersStudentGradeLevelId';
    static SESSION_STUDENTID = 'markersStudentId';
    static SESSION_STUDENTNAME = 'markersStudentName';
    static SESSION_STUDENT_ENROLLMENT_ID = 'studentEnrollmentId';
    static SAVE_TYPE = 'updated';
    static SESSION_STUDENT_PHOTO = 'markersStudentPhoto';
    static SESSION_STUDENTVISAFLAG = 'markersStudentVisaFlag';
    static ADDRESS_COUNTY = "county"
    static SESSION_GUARDIAN_ID = 'markersGuardianId';
    static SESSION_STUDENT_CODE = "markersStudentCode";
    static SESSION_STUDENT_GRADE_LEVEL_TITLE = 'markersStudentGradeLevelTitle';
    //static SESSION_STUDENT_ENROLL_STATUS = 'markersStudentEnrollStatus';
    static SESSION_DISTRICT_SR_SHOW = 'markersDistrictStateReportShow';
    static SESSION_DISTRICT_NM_SR_SHOW = 'markersDistrictNMStateReportShow';
    static SESSION_SCHOOL_SR_SHOW = 'markersSchoolStateReportShow';
    static SESSION_SCHOOL_RES_SR_SHOW = 'markersSchoolResStateReportShow';
    static SESSION_STAFF_SR_SHOW = 'markersStaffStateReportShow';
    static SESSION_STAFF_RES_SR_SHOW = 'markersStaffResStateReportShow';
    static SESSION_STUDENT_SR_SHOW = 'markersStudentStateReportShow';
    static SESSION_STUDENT_RES_SR_SHOW = 'markersStudentResStateReportShow';
    static SESSION_STUDENT_LEAVE_SR_SHOW = 'markersStudentLeaveStateReportShow';
    static SESSION_STUDENT_GRAD_SR_SHOW = 'markersStudentGraduationStateReportShow';
    static SESSION_STUDENT_TRANSCRIPT_SR_SHOW = 'markersStudentTranscriptStateReportShow';
    static SESSION_STUDENT_ESYS_SR_SHOW = 'markersStudentExtendedYearStateReportShow';
    static SESSION_STUDENT_DLA_SR_SHOW = 'markersStudentDisabilityLangStateReportShow';
    static LOOKUP_EXIT_WITHDRAWAL_STATUS_DEFAULT_PERMANENT = 5201;
    static DEFAULT_ENROLLMENT_STATUS = 5002;
    static LOOKUP_IDENTIFICATION_SYSTEM_STATE_ASSIGNEDSTF_RES_SRSTF_RES_SR_NUMBER = 19808;
    static LOOKUP_IDENTIFICATION_STUDENT_SYSTEM_STATE_ASSIGNED_NUMBER = 3108;
    //Staffs
    static SESSION_STAFFNAME = 'markersStaffName';
    static SESSION_PERSONID = 'markersPersonId';
    static SESSION_STAFF_PHOTO = 'markersStaffPhoto';
    static JOB_CATEGORY_ID = "jobCategoryId"
    static SESSION_STAFF_CONTACT_ID = 'markersGuardianId'
    static LEAVE_STATUS_TYPE_DISCARDED = 'Discarded';
    //LookUp Category
    static LOOKUP_ENTITY_TYPE = 'ENTITY_TYPE';
    static LOOKUP_SCHOOL_TYPE = 'SCHOOL_TYPE';
    static LOOKUP_SCHOOL_LEVEL = 'SCHOOL_LEVEL';
    static LOOKUP_SCHOOL_FOCUS = 'SCHOOL_FOCUS_TYPE';
    static LOOKUP_ADDRESS_TYPE = 'OFFICE_ADDRESS_TYPE';
    static LOOKUP_PHONE_TYPE = 'PHONE_TYPE';
    static LOOKUP_EMAIL_TYPE = 'DISTRICT_EMAIL_TYPE';
    static LOOKUP_SCHOOL_DESIGNATION_TYPE = 'SCHOOL_DESIGNATION_TYPE';
    static LOOKUP_SCHOOL_IDENTIFICATION_SYSTEM = 'SCHOOL_IDENTIFICATION_SYSTEM';
    static LOOKUP_ACCREDITATION_AGENCY = 'ACCREDITATION_AGENCY';
    static LOOKUP_HE_ACCREDITATION_STATUS = 'HE_ACCREDITATION_STATUS';
    static LOOKUP_ADMIN_FUND_CONTROL = 'ADMIN_FUND_CONTROL';
    static LOOKUP_SCHOOL_OPERATIONAL_STATUS = 'ORGANIZATION_OPERATIONAL_STATUS';
    static LOOKUP_SCHOOL_IMPROVEMENT_STATUS = 'SCHOOL_IMPROVEMENT_STATUS';
    static LOOKUP_SCHOOL_GRADE_LEVEL = 'SCHOOL_GRADES';
    static LOOKUP_SCHOOL_CALENDAR_VISIBILITY = 'SCHOOL_ROLE';
    static LOOKUP_SCHOOL_FIELD_DATA_TYPE = 'DATA_TYPE';
    static LOOKUP_SCHOOL_FIELD_DATE_FORMAT = 'SCHOOL_FIELD_DATE_FORMAT';
    static LOOKUP_SCHOOL_FIELD_TYPE = 'FIELD_TYPE';
    static LOOKUP_ROLE_TYPE = 'SCHOOL_ROLE';
    static LOOKUP_SALUTATION_TYPE = 'SALUTATION';
    static LOOKUP_SALUTATION_STAFF_TYPE = 'SALUTATION_STAFF';
    static LOOKUP_MARKINGPERIOD_TYPE = 'MARKING_PERIOD_TYPE';
    static LOOKUP_CRISIS_TYPE = 'CALENDAR_CRISIS_TYPE';
    static LOOKUP_STAFF_CREDENTIAL_TYPE = 'CREDENTIAL_TYPE';
    static LOOKUP_STAFF_VISA_TYPE = 'GENERAL_VISA_TYPE';
    static LOOKUP_SCHOOL_PUBLICATION_STATUS = 'PUBLICATION_STATUS';
    static LOOKUP_MATH_OPERATOR = 'MATH_OPERATOR';
    static LOOKUP_REPORT_OPERATOR = 'REPORT_OPERATOR';
    static LOOKUP_GRADE_SCALE_DEFINITION = 'GRADE_SCALE_DEFINITION';
    static LOOKUP_SEARCH_TYPE = 'SEARCH_ENTITY';
    static LOOKUP_ACCOMMODATION_TYPE = 'ACCOMMODATION_TYPE';
    static LOOKUP_ACCOMMODATION_CATEGORY = 'ACCOMMODATION_CATEGORY';
    static LOOKUP_PERSON_OCCUPATION_TYPE = 'PERSON_OCCUPATION_TYPE';
    static LOOKUP_GPA_CALC_BASED_ON = 'GPA_CALC_BASED_ON';
    static LOOKUP_GRADE_TYPE = 'GRADE_TYPE';
    static LOOKUP_DAYS_IN_WEEK = 'DAYS_IN_WEEK';
    static LOOKUP_RELIGION_TYPE = 'RELIGION';
    static LOOKUP_EWS_CATEGORY = 'EWS_CATEGORY';
    static LOOKUP_SR_REPORTING_TERM = 'SR_REPORTING_TERM';
    /*School section*/
    static SESSION_SECTION_ID = 'markersSectionId';
    static SESSION_GRADE_LEVEL_ID = 'markersGradeLevelId';
    static SESSION_SCHOOL_SESSION_ID = 'markersSchoolSessionId';
    static SESSION_ROOM_ID = 'markersRoomId';
    static SESSION_DEPARTMENT_ID = 'markersDepartmentId'
    static SESSION_GRADE_SCALE_ID = 'markersDepartmentId'
    static SESSION_PERIOD_ID = 'markersPeriodId'
    static SESSION_ROLE_ID = 'markersRoleId'
    static SESSION_MARKING_PERIOD_ID = 'markersRoleId'
    static DURATION_IN_DAYS = 'durationInDays'
    static MINUTES_PER_DAY = 'minutesPerDay'
    static LMS_ACTIVITY_ASSIGNMENT = 31901;
    static LMS_ACTIVITY_QUIZ = 31902;
    static WEB_QUIZ = 32303;
    static SESSION_LANGUAGE_ID = 'markersLanguageId';
    /*Student section*/
    static SESSION_SECTION_ID = 'markersSectionId'
    /*Student section*/
    static LOOKUP_STUDENT_ETHNICITY = 'ETHNICITY';
    static LOOKUP_STUDENT_HISPANIC_LATINO_ETHNICITY = 'HISPANIC_LATINO_ETH_TYPE';
    static LOOKUP_STUDENT_HISPANIC_LATINO_RACE = 'HISPANIC_LATINO_RACE_TYPE';
    static STUDENT_HISPANIC_LATINO_RACE_TYPE_UNKNOWN = "17299";
    static LOOKUP_STUDENT_LANGUAGE = 'LANGUAGE';
    static LOOKUP_STUDENT_ROLLING_RETENTION = 'STUDENT_ROLLING_RETENTION';
    static LOOKUP_STUDENT_IDENTIFICATION_SYSTEM = 'STUDENT_IDENTIFICATION_SYSTEM';
    static LOOKUP_STUDENT_PUBLIC_SCHOOL_RESIDENTIAL_STATUS = 'PUBLIC_SCHOOL_RESIDENTIAL_STATUS';
    static LOOKUP_STUDENT_OTHER_BACKWARD_COMMUNITY = 'STUDENT_OTHER_BACKWARD_COMMUNITY';
    static LOOKUP_STUDENT_MILITARY_CONNECTION = 'STUDENT_MILITARY_CONNECTION';
    static LOOKUP_STUDENT_MILITARY_BRANCH = 'MILITARY_BRANCH';
    static LOOKUP_MILITARY_CONNECTION_TEXAS = 'MILITARY_CONNECTED_TEXAS';
    static LOOKUP_STUDENT_VERIFIABLE_INFO = 'STUDENT_VERIFIABLE_INFO';
    static LOOKUP_STUDENT_DOCUMENT_VERIFICATION_CERTIFICATE = 'DOCUMENT_VERIFICATION_CERTIFICATE';
    static LOOKUP_STUDENT_ISSUER_COUNTRY = 'STUDENT_ISSUER_COUNTRY';
    static LOOKUP_STUDENT_CERTIFIED_BY = 'STUDENT_CERTIFIED_BY';
    static LOOKUP_STUDENT_VERIFIED_BY = 'STUDENT_VERIFIED_BY';
    static LOOKUP_STUDENT_VISA_TYPE = 'STUDENT_VISA_TYPE';
    static LOOKUP_STUDENT_PERSON_RELATIONSHIP = 'PERSON_RELATIONSHIP';
    static LOOKUP_STUDENT_RELATIONSHIP = 'STUDENT_RELATIONSHIP';
    static LOOKUP_STUDENT_DISABILITY_TYPE = 'DISABILITY_TYPE';
    static LOOKUP_STUDENT_ACCOMMODATION_NEED_TYPE = 'ACCOMMODATION_NEED_TYPE';
    static LOOKUP_STUDENT_DISABILITY_CONDITION_TYPE = 'DISABILITY_CONDITION_TYPE';
    static LOOKUP_STUDENT_DISABILITY_CONDITION_NATURE = 'DISABILITY_CONDITION_NATURE';
    static LOOKUP_STUDENT_DETERMINED_BY = 'DISABILITY_DETERMINATION_SOURCE';
    static LOOKUP_STUDENT_IDEA_DISABILITY_TYPE = 'IDEA_DISABILITY_TYPE';
    static LOOKUP_SCHOOL_LUNCH_PROGRAM_TYPE = 'SCHOOL_LUNCH_PROGRAM_TYPE';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY = 'FOOD_SERVICE_ELIGIBILITY';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FREE = 'FOOD_SERVICE_ELIGIBILITY_TYPE_FREE';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_FULL = 'FOOD_SERVICE_ELIGIBILITY_TYPE_FULL';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_REDUCED = 'FOOD_SERVICE_ELIGIBILITY_TYPE_REDUCED';
    static LOOKUP_STUDENT_FOOD_SERVICE_ELIGIBILITY_OTHER = 'FOOD_SERVICE_ELIGIBILITY_TYPE_OTHER';
    static LOOKUP_STUDENT_ENROLLMENT_GIFTED_PROGRAM = 'ENROLLMENT_PROGRAM_GIFTED';
    static LOOKUP_STUDENT_ENROLLMENT_CODE = 'LOOKUP_STUDENT_ENROLLMENT_CODE';
    static LOOKUP_ENROLLMENT_ENTRY_TYPE = 'ENROLLMENT_ENTRY_TYPE';
    static LOOKUP_EXIT_WITHDRAWAL_STATUS = 'EXIT_WITHDRAWAL_STATUS';
    static LOOKUP_EXIT_ENROLLMENT_STATUS = 'ENROLLMENT_STATUS';
    static LOOKUP_EXIT_ROLLING_RETENTION = 'ROLLING_RETENTION_TYPE';
    static LOOKUP_EXIT_WITHDRAWAL_TYPE = 'EXIT_WITHDRAWAL_TYPE';
    static LOOKUP_PROMOTION_REASON = 'PROMOTION_REASON';
    static LOOKUP_NON_PROMOTION_REASON = 'NON_PROMOTION_REASON';
    static LOOKUP_STUDENT_REFERRAL_TYPE = 'STUDENT_REFERRAL_TYPE';
    static LOOKUP_STUDENT_REFERRAL_OUTCOME = 'STUDENT_REFERRAL_OUTCOME';
    static LOOKUP_STUDENT_VISION_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_HEARING_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_DENTAL_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_HEALTH_INSURANCE_COVERAGE = 'HEALTH_INSURANCE_COVERAGE';
    static LOOKUP_STUDENT_DENTAL_INSURANCE_COVERAGE = 'HEALTH_INSURANCE_COVERAGE';
    static LOOKUP_STUDENT_MEDICAL_ALERT_TYPE = 'MEDICAL_ALERT_TYPE';
    static LOOKUP_STUDENT_HEALTH_SCREENING_STATUS = 'HEALTH_SCREENING_STATUS';
    static LOOKUP_STUDENT_MULTIPLE_BIRTH = 'MULTIPLE_BIRTH';
    static LOOKUP_STUDENT_INSURANCE_TYPE = 'INSURANCE_TYPE';
    static LOOKUP_RELATIONSHIP = 'STUDENT_RELATIONSHIP';
    static LOOKUP_ADA_ELIGIBILITY_TYPE = 'ADA_ELIGIBILITY_TYPE';
    static LOOKUP_NAME_SUFFIX_TYPE = 'NAME_SUFFIX';
    static LOOKUP_IMMUNIZATION_ROUTE = 'IMMUNIZATION_ROUTE';
    static LOOKUP_IMMUNIZATION_STATUS = 'IMMUNIZATION_STATUS';
    static LOOKUP_INJECTION_SITE = 'INJECTION_SITE';
    static LOOKUP_MEDICAL_CONDITION = 'MEDICAL_CONDITION';
    static LOOKUP_BEHAVIOR_CONDITION = 'BEHAVIOR_CONDITION';
    static LOOKUP_MEDICATION = 'MEDICATION';
    static LOOKUP_MEDICATION_ROUTE = 'MEDICATION_ROUTE';
    static LOOKUP_MEDICATION_FREQUENCY = 'MEDICATION_FREQUENCY';
    static LOOKUP_MEDICATION_DOSE_UNIT = 'MEDICATION_DOSE_UNIT';
    static LOOKUP_MEDICATION_INVENTORY_REASON = 'MEDICATION_INVENTORY_REASON'
    static LOOKUP_HEALTH_SCREENING_TYPE = 'HEALTH_SCREENING_TYPE';
    static LOOKUP_DENTAL_SCREENING_STATUS = 'DENTAL_SCREENING_STATUS';
    static LOOKUP_HEALTH_REFERRAL_RESULT = 'HEALTH_REFERRAL_RESULT';
    static LOOKUP_HEARING_TEST = 'HEARING_TEST';
    static LOOKUP_HEALTH_SCREENING_EYE_POWER = 'HEALTH_SCREENING_EYE_POWER';
    static LOOKUP_SCREERNNG_TEST_RESULT = 'SCREERNNG_TEST_RESULT';
    static LOOKUP_SPINAL_ACTION_TAKEN = 'SPINAL_ACTION_TAKEN';
    static LOOKUP_SPINAL_DIAGNOSIS = 'SPINAL_DIAGNOSIS';
    static LOOKUP_SPINAL_TREATMENT = 'SPINAL_TREATMENT';
    static LOOKUP_VISION_TEST = 'VISION_TEST';
    static LOOKUP_SPECIAL_PROGRAMS = 'SPECIAL_PROGRAMS';
    static LOOKUP_VISIT_REASON_MISSED = 'VISIT_REASON_MISSED';
    static LOOKUP_VISIT_REASON = 'VISIT_REASON';
    static LOOKUP_ACTION_TAKEN = 'ACTION_TAKEN';
    static LOOKUP_CANNED_RPT_ADA_ELIGIBILITY_DISPLAY = 'CANNED_RPT_ADA_ELIGIBILITY_DISPLAY';
    static LOOKUP_CANNED_RPT_ADA_ELIGIBILITY_SORTORDER = 'CANNED_RPT_ADA_ELIGIBILITY_SORTORDER';
    static LOOKUP_REPORT_LABEL_LENGTH_UNIT = 'REPORT_LABEL_LENGTH_UNIT';
    static LOOKUP_REPORT_LABEL_ROW = 'REPORT_LABEL_ROW';
    static LOOKUP_REPORT_LABEL_COLUMN = 'REPORT_LABEL_COLUMN';
    static LOOKUP_REPORT_LABEL_PAGE_TYPE = 'REPORT_LABEL_PAGE_TYPE';
    static LOOKUP_REPORT_LABEL_PAGE_ORIENTATION = 'REPORT_LABEL_PAGE_ORIENTATION';
    static LOOKUP_CANNED_RPT_MAILING_LABEL_PRINTOPT = 'CANNED_RPT_MAILING_LABEL_PRINTOPT';
    static LOOKUP_CANNED_RPT_MAILING_LABEL_OPTIONS = 'CANNED_RPT_MAILING_LABEL_OPTIONS';
    static LOOKUP_CANNED_RPT_MAILING_LABEL_PRINTOPT_PRIMARY_PARENT = 29002;
    static REPORT_LABEL_PAGE_TYPE_CUSTOM = '28301';
    static REPORT_LABEL_PAGE_TYPE_LETTER = '28302';
    static REPORT_LABEL_PAGE_TYPE_A4 = '28303';
    static REPORT_LABEL_LENGTH_UNIT_INCH = '28001';
    static REPORT_LABEL_LENGTH_UNIT_CM = '28002';
    static LOOKUP_CANNED_RPT_SE_DROP_TYPE = 'CANNED_RPT_SE_DROP_TYPE'
    static LOOKUP_CANNED_RPT_SE_DROP_TYPE_TOTAL_ONLY = 29201;
    static LOOKUP_CANNED_RPT_SE_DROP_TYPE_DETAIL = 29202;
    static LOOKUP_CANNED_RPT_SE_DROP_SORTBY = 'CANNED_RPT_SE_DROP_SORTBY'
    static LOOKUP_CANNED_RPT_SE_DROP_SORTBY_DATE_GRADELEVEL = 29301;
    static LOOKUP_CANNED_RPT_SE_DROP_SORTBY_DATE_NAME = 29302;
    static LOOKUP_CANNED_RPT_SE_DROP_SORTBY_DATE = 29303;
    static LOOKUP_CANNED_RPT_SE_DROP_SORTBY_GRADELEVEL = 29304;
    static LOOKUP_SUBMISSION_TYPE = 'LMS_SUBMISSION_TYPE';
    /*Student Special Program*/
    static LOOKUP_DISABILITY_EDUCATIONAL_ENVIRONMENT = 'DISABILITY_EDUCATIONAL_ENVIRONMENT';
    static LOOKUP_DISABILITY_SUPPORT_SERIVICES = 'DISABILITY_SUPPORT_SERIVICES';
    static LOOKUP_DISABILITY_PRESCHOOL_SERVICE_LOCATION = 'PRESCHOOL_DISABILITY_SERVICE_LOCATION';
    static LOOKUP_DISABILITY_INTERPRETING_SERVICES = 'DISABAILITY_INTERPRETING_SERVICES';
    static LOOKUP_DISABILITY_TEXAS_DETERMINED_BY = 'DISABILITY_TX_DETERMINED_BY';
    static LOOKUP_DISABILITY_TEXAS_PRIMARY_TYPE = 'DISABILITY_TX_PRIMARY_DISABILITY_TYPE';
    static LOOKUP_DISABILITY_TEXAS_EDUCATIONAL_ENVIRONMENT = 'DISABILITY_TX_EDUCATIONAL_ENVIRONMENT';
    static LOOKUP_DISABILITY_TEXAS_SUPPORT_SERIVICES = 'DISABILITY_TX_SUPPORT_SERIVICES';
    static LOOKUP_DISABILITY_TEXAS_PRESCHOOL_SERVICE_LOCATION = 'DISABILITY_TX_PRESCHOOL_DISA_SRC_LOC';
    static LOOKUP_DISABILITY_CHILD_COUNT_FUNDING = 'DISABILITY_CHILD_COUNT_FUNDING';
    static LOOKUP_DISABILITY_REGIONAL_DAY_SCHOOL_PROGRAM_FOR_DEAF = 'DISABILITY_REG_DAY_SCHOOL_PGM_FOR_DEAF';
    static LOOKUP_HOMELESS_STATUS = 'HOMELESS_STATUS';
    static LOOKUP_HOMELESS_UNACCOMPANIED_YOUTH_STATUS = 'HOMELESS_UNACCOMPANIED_YOUTH_STATUS';
    static LOOKUP_ASYLEE_REFUGEE = 'ASYLEE_REFUGEE';
    static LOOKUP_FOSTER_CARE = 'FOSTER_CARE';
    static LOOKUP_DYSLEXIA_RISK = 'DYSLEXIA_RISK';
    static LOOKUP_DYSLEXIA_SERVICES = 'DYSLEXIA_SERVICES';
    static LOOKUP_LEP_INDICATOR = 'LEP_INDICATOR';
    static LOOKUP_LEP_PARENTAL_PERMISSION = 'LEP_PARENTAL_PERMISSION';
    static LOOKUP_LEP_BILINGUAL_PROGRAM = 'LEP_BILINGUAL_PROGRAM';
    static LOOKUP_LEP_ESL_PROGRAM = 'LEP_ESL_PROGRAM';
    static LOOKUP_LEP_ALTERNATE_LANGUAGE_PROGRAM = 'LEP_ALTERNATE_LANGUAGE_PROGRAM';
    static LOOKUP_LEP_BILINGUAL_ESL_FUNDING = 'LEP_BILINGUAL_ESL_FUNDING';
    static LOOKUP_TITLE_I_PART_A = 'TITLE_I_PART_A';
    static LOOKUP_SINGLE_PARENT_PREGNANT_TEEN = 'SINGLE_PARENT_PREGNANT_TEEN';
    static LOOKUP_CRISIS = 'CRISIS';
    static LOOKUP_ECONOMIC_DISADVANTAGE = 'ECONOMIC_DISADVANTAGE';
    static LOOKUP_STUDENT_CERTIFICATE_TYPE = 'CERTIFICATE_TYPE';
    static LOOKUP_YEARS_IN_US_SCHOOLS = 'YEARS_IN_US_SCHOOLS';
    static LOOKUP_LEP_DUAL_IMMERSION_LANGUAGE = 'LEP_DUAL_IMMERSION_LANGUAGE';
    static LOOKUP_LEP_DUAL_LANGUAGE_IMMERSION_YEAR = 'LEP_DUAL_LANGUAGE_IMMERSION_YEAR';
    static LOOKUP_LEP_DUAL_LANGUAGE_COMPLETION_YEAR = 'LEP_DUAL_LANGUAGE_COMPLETION_SYEAR';
    static LOOKUP_ECONOMIC_DISADVANTAGED = 'ECONOMIC_DISADVANTAGED';
    static LOOKUP_FOOD_SERVICE_PARTICIPATION = 'FOOD_SERVICE_PARTICIPATION';
    static LOOKUP_ENGLISH_PROFICIENCY = 'ENGLISH_PROFICIENCY';
    static LOOKUP_EL_PROGRAM = 'EL_PROGRAM';
    static LOOKUP_BEP_PROGRAM_LANGUAGE = 'BEP_PROGRAM_LANGUAGE';
    static LOOKUP_SPECIAL_EDUCATION = "SPECIAL_EDUCATION";
    static LOOKUP_PRIMARY_AREA_OF_EXCEPTIONALITY = "PRIMARY_AREA_OF_EXCEPTIONALITY";
    static LOOKUP_SPECIAL_EDUCATION_REFERRAL = "SPECIAL_EDUCATION_REFERRAL";
    static LOOKUP_LEVEL_OF_INTEGRATION = "LEVEL_OF_INTEGRATION";
    static LOOKUP_PRIMARY_SETTING = "PRIMARY_SETTING";
    static LOOKUP_DISABILITY = "DISABILITY";
    static LOOKUP_EXPECTED_GRADUATION_OPTION = "EXPECTED_GRADUATION_OPTION";
    static LOOKUP_PLANNED_POST_GRAD_ACTIVITY = "PLANNED_POST_GRAD_ACTIVITY";
    static LOOKUP_ANNUAL_REVIEW_DELAY_REASON = "ANNUAL_REVIEW_DELAY_REASON";
    static LOOKUP_TRIENNIAL_REVIEW_DELAY_REASON = "TRIENNIAL_REVIEW_DELAY_REASON";
    static LOOKUP_BEP_PROGRAM_INTENSITY = "BEP_PROGRAM_INTENSITY";
    static LOOKUP_NMPREK_PARTICIPATION = "NMPREK_PARTICIPATION";
    static LOOKUP_CEIS_PARTICIPATION = "CEIS_PARTICIPATION";
    static LOOKUP_MIGRANT_PROGRAM = "MIGRANT_PROGRAM";
    /*Staff Section */
    static LOOKUP_STAFF_CERTIFICATE_ACCREDITATION_STATUS = 'CERTIFICATE_ACCREDITATION_STATUS';
    static LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_TYPE = "TEACHING_CREDENTIAL_TYPE";
    static LOOKUP_STAFF_CREDENTIAL_TEACHING_CREDENTIAL_BASIS = "TEACHING_CREDENTIAL_BASIS";
    static LOOKUP_STAFF_CREDENTIAL_CERTIFICATE_SPONSOR_TYPE = "STAFF_CERT_SPONSOR_TYPE";
    static LOOKUP_STAFF_LEAVE_TYPE = "LEAVE_TYPE";
    static LOOKUP_STAFF_PERSONAL_DOCUMENT_VERIFICATION = 'DOCUMENT_VERIFICATION_CERTIFICATE';
    static LOOKUP_STAFF_JOB_CATEGORY_SCHOOL_ROLE = "SCHOOL_ROLE";
    static LOOKUP_STAFF_CERTIFICATION_TYPE = "CERTIFICATION_TYPE";
    static LOOKUP_CERTIFICATE_VERIFICATION_METHOD = 'CERTIFICATE_VERIFICATION_METHOD';
    static LOOKUP_DOCUMENT_VERIFICATION_STATUS = 'DOCUMENT_VERIFICATION_STATUS';
    static LOOKUP_LEAVE_STATUS_TYPE = 'LEAVE_STATUS_TYPE';
    static MASS_IMMUNIZATION_INFO = "massImmunizationInfo"
    static LOOKUP_CERTIFICATE_TYPE = 'lookupCertificationType'
    //Course lookup
    static LOOKUP_COURSE_LEVEL_CHARACTERISTIC = 'COURSE_LEVEL_CHARACTERISTIC';
    static LOOKUP_COURSE_CREDIT_UNIT = 'COURSE_CREDIT_UNIT';
    static LOOKUP_COURSE_WBL_OPPORTUNITY_TYPE = 'COURSE_WBL_OPPORTUNITY_TYPE';
    static LOOKUP_ADITIONAL_CREDIT_TYPE = 'ADDITIONAL_CREDIT_TYPE';
    static LOOKUP_COURSE_GPA_APPLICABILITY = 'COURSE_GPA_APPLICABILITY';
    static LOOKUP_CURRICULUM_FRAMEWORK_TYPE = 'CURRICULUM_FRAMEWORK_TYPE';
    static LOOKUP_COURSE_CREDIT_TYPE_EARNED = 'COURSE_CREDIT_TYPE_EARNED';
    static LOOKUP_COURSE_CAREER_CLUSTER = 'COURSE_CAREER_CLUSTER';
    static LOOKUP_COURSE_BLND_LEARNING_MODEL_TYPE = 'COURSE_BLND_LEARNING_MODEL_TYPE';
    static LOOKUP_COURSE_EXAM_REQUIREMENT = 'COURSE_EXAM_REQUIREMENT';
    static LOOKUP_ADVANCED_PLACEMENT_COURSE_CODE = 'ADVANCED_PLACEMENT_COURSE_CODE';
    static LOOKUP_PERIOD_SCHEDULE_TYPE = 'PERIOD_SCHEDULE_TYPE';
    static LOOKUP_SINGLE_SEX_CLASS_STATUS = 'SINGLE_SEX_CLASS_STATUS';
    static LOOKUP_COURSE_SECTION_DELIVERY_MODE = 'COURSE_SECTION_DELIVERY_MODE';
    static LOOKUP_COURSE_SECTION_LEVEL_TYPE = 'COURSE_SECTION_LEVEL_TYPE';
    static LOOKUP_COURSE_TYPE = 'COURSE_TYPE';
    static LOOKUP_COURSE_SECTION_EXIT_TYPE = 'COURSE_SECTION_EXIT_TYPE';
    static LOOKUP_COURSE_SECTION_ENTRY_TYPE = 'COURSE_SECTION_ENTRY_TYPE';
    static LOOKUP_COURSE_SEQUENCE_TYPE = 'COURSE_SEQUENCE_TYPE';
    static LOOKUP_COURSE_APPROVAL_STATUS = 'APPROVAL_STATUS';
    static LOOKUP_COURSE_STUDY_MATERIAL_TYPE = 'STUDY_MATERIAL_TYPE';
    static LOOKUP_COURSE_EXPLANATION = 'COURSE_EXPLANATION';
    static LOOKUP_COURSE_EXPLANATION_TYPE = 'COURSE_EXPLANATION_TYPE';
    static LOOKUP_CLASS_ROOM_TYPE = 'CLASSROOM_TYPE';
    // Attendance Section 
    static LOOKUP_ATTENDANCE_STATUS = 'ATTENDANCE_STATUS';
    static LOOKUP_PRESENT_ATTENDANCE_CATEGORY = 'PRESENT_ATTENDANCE_CATEGORY';
    static LOOKUP_ABSENT_ATTENDANCE_CATEGORY = 'ABSENT_ATTENDANCE_CATEGORY';
    static ATTENDANCE_STATUS = 'attendanceStatus';
    static EMAIL_SELECTED_TEACHER_ID = 'EmailSelectedTeacherId';
    static EMAIL_SELECTED_TEACHER = 'EmailSelectedTeacher';
    static LOOKUP_ATTENDANCE_CATEGORY_TYPES = 'ATTENDANCE_CATEGORY_TYPES';
    // Discipline Section 
    static LOOKUP_INCIDENT_TIME_TYPE = 'INCIDENT_TIME_TYPE';
    static LOOKUP_INCIDENT_BEHAVIOUR_TYPE = 'INCIDENT_BEHAVIOR_TYPE';
    static LOOKUP_INCIDENT_BEHAVIOUR = 'INCIDENT_BEHAVIOR';
    static LOOKUP_WEAPON_TYPE = 'WEAPON_TYPE';
    static LOOKUP_INJURY_TYPE = 'INJURY_TYPE';
    static LOOKUP_INCIDENT_LOCATION = 'INCIDENT_LOCATION';
    static LOOKUP_FIREARM_TYPE = 'FIREARM_TYPE';
    static LOOKUP_MULTIPLE_OFFENCE_TYPE = 'MULTIPLE_OFFENSE_TYPE';
    static LOOKUP_DISCIPLINE_REASON = 'DISCIPLINE_REASON';
    static LOOKUP_DISCIPLINARY_ACTION_TAKEN = 'DISCIPLINARY_ACTION_TAKEN';
    static LOOKUP_DISCIPLINE_LENGTH_DIFFERENCE = 'DISCIPLINE_LENGTH_DIFFERENCE';
    static LOOKUP_IDEA_INTERIM_REMOVAL = 'IDEA_INTERIM_REMOVAL';
    static LOOKUP_IDEA_INTERIM_REMOVAL_REASON = 'IDEA_INTERIM_REMOVAL_REASON';
    static LOOKUP_FIREARM_DISCIPLINE_METHOD = 'FIREARM_DISCIPLINE_METHOD';
    static LOOKUP_IDEA_FIREARM_DISCIPLINE_METHOD = 'IDEA_FIREARM_DISCIPLINE_METHOD';
    static LOOKUP_DM_FOR_DIABETIC_CHILDREN = 'DM_FOR_DIABETIC_CHILDREN';
    static LOOKUP_INCIDENT_PERSON_ROLE = 'INCIDENT_PERSON_ROLE';
    static LOOKUP_INCIDENT_PERSON_TYPE = 'INCIDENT_PERSON_TYPE';
    static INCIDENT_ID = 'incidentId';
    //Assessment Section
    static LOOKUP_ASSESSMENT_ASSET_TYPE = 'ASSESSMENT_ASSET_TYPE';
    static LOOKUP_PUBLICATION_STATUS = 'PUBLICATION_STATUS';
    static LOOKUP_ASSESSMENT_PURPOSE = 'ASSESSMENT_PURPOSE';
    static LOOKUP_ASSESSMENT_TYPE = 'ASSESSMENT_TYPE';
    static LOOKUP_ASSESSMENT_TYPE_WITH_DISABILITY = 'ASSESSMENT_TYPE_WITH_DISABILITIES';
    static LOOKUP_ASSESSMENT_REGISTRATION_COMPLETION_STATUS = 'ASMT_REGISTRATION_COMPLETION_STATUS';
    static LOOKUP_ASSESSMENT_PARTICIPATION = 'ASSESSMENT_PARTICIPATION';
    static LOOKUP_ASSESSMENT_REASON_NOT_TESTED = 'ASSESSMENT_REASON_NOT_TESTED';
    static LOOKUP_ASSESSMENT_REASON_NOT_COMPLETED = 'ASSESSMENT_REASON_NOT_COMPLETED';
    static LOOKUP_ASSESSMENT_SESSION_TYPE = 'ASSESSMENT_SESSION_TYPE';
    static LOOKUP_ASSESSMENT_REPORTING_METHOD = 'ASSESSMENT_REPORTING_METHOD';
    static LOOKUP_ASSESSMENT_FORM_SEC_IDENTIFICATION_SYSTEM = 'ASMT_FORM_SEC_IDENTIFICATION_SYSTEM';
    static LOOKUP_SCORE_METRIC_TYPE = 'SCORE_METRIC_TYPE';
    static LOOKUP_ASSESSMENT_OUTCOME = 'ASSESSMENT_OUTCOME';
    static LOOKUP_ASSESSMENT_RESULT_SCORE_TYPE = 'ASSESSMENT_RESULT_SCORE_TYPE';
    static SESSION_ASSESSMENT_ADMINID = 'ASSESSMENT_ADMIN_ID';
    static LOOKUP_ASSIGNMENT_AGGREGATION = 'ASSIGNMENT_AGGREGATION_TYPE';
    static LOOKUP_ASSIGNMENT_GRADING_TYPE = 'ASSIGNMENT_GRADING_TYPE';
    static SESSION_ASSIGNMENT_BANK_DETAILS = 'ASSIGNMENT_BANK_DETAILS'
    // Announcement
    static LOOKUP_ANNOUNCEMENT_TYPE = 'ANNOUCEMENT_TYPE';
    //Application level Constant
    static BUTTON_SAVE = 'saveBtn'
    static IS_VIRTUAL = 'isVirtual';
    static IS_GRADED = 'isGraded';
    static HAS_EXAM = 'hasExam';
    static IS_ACTIVE = 'isActive';
    static IS_SCHEDULING_TERM = 'isSchedulingTerm';
    static MPGRADETYPE = 'mpGradeType';
    static MPGRADEOPTION = 'mpGradeOption';
    static IS_ROUNDUP = 'isRoundUp';
    static IS_DEFAULT = 'isDefault';
    static IS_ALL_DAY = 'isAllDayLong';
    static IS_VISIBLE_STUDENT = 'isVisibleToStudents';
    static IS_VISIBLE_STAFF = 'isVisibleToStaff';
    static WEEKDAYS = 'weekdays';
    static IS_ACRIDATED = 'accredited';
    static ACRIDATED = 'isAccredited';
    static IS_EXCLUDE_FROM_CALCULATION = 'isExcludeFromCalculation';
    static IS_ALLOWEDFORSCHOOL = 'isAllowedForSchool';
    static IS_ALLOWATTENDANCE = 'allowAttendance';
    static IS_IGNORESCHEDULING = 'ignoreScheduling';
    static IS_DELETED = 'isDeleted';
    static IS_VISIBLE = 'isVisibleToOtherSchools';
    static IS_ACTIVITY = 'isActivity';
    static IS_PUBLISHED = 'isPublished';
    static IS_AFFECT = 'isAffect';
    static AM = 'AM';
    static PM = 'PM';
    static SUCCESS_NOTIFY_TYPE = 1;
    static ERROR_NOTIFY_TYPE = 2;
    static IS_CLOSED = 'isClosed';
    static SORTORDER = 'sortOrder';
    static DFAULT_TIME = '0001-01-01T00:00:00';
    static UNPUBLISHED_COUNT = 0;
    static BILLING_APP_PATH = 'https://localhost:5004';
    static IS_BILLING = 'billing';
    static TRANSPORT_APP_PATH = 'https://localhost:5006';
    static IS_TRANSPORT = 'transport';
    //Phone relate constants
    static PHONE_NUMBER_MIN_LENGTH = 11;
    static HOME_NUMBER = 903;
    static WORK_NUMBER = 901;
    static MOBILE_NUMBER = 902;
    //School fields related Constant
    static IS_SEARCHABLE = 'searchable';
    static IS_ACADEMIC = 'isAcademic';
    static IS_REQUIRED = 'isRequired';
    static IS_MANDATORY = 'isMandatory';
    static IS_HIDE = 'hide';
    static IS_NUMERIC = 'numeric';
    static IS_VARCHAR = 'varchar';
    static IS_SPL_CHAR = 'specialCharacter';
    static IS_MAX_LENGTH = 'maxLength';
    static IS_DATE = 'date';
    static GEN_MENUCODE = 'SCHL_ADD_GEN';
    static ADMIN_MENUCODE = 'SCHL_ADD_ADM';
    static ACC_MENUCODE = 'SCHL_ADD_ACC';
    static DESIG_MENUCODE = 'SCHL_ADD_DES';
    static IDEN_MENUCODE = 'ADD_SCHOOL_IDENTIFIER';
    static STUGEN_MENUCODE = 'STUD_ADD_GEN';
    static STUIDEN_MENUCODE = 'STUD_ADD_IDE';
    static STUMISC_MENUCODE = 'STUD_ADD_MIS';
    static STUREF_MENUCODE = 'STUD_ADD_REF';
    static STUVISA_MENUCODE = 'STUD_ADD_VIS';
    static STUENROLL_MENUCODE = 'STUD_ADD_ENR';
    static STUDIS_MENUCODE = 'STUD_ADD_DIS';
    static SCHOOLADD_MENUCODE = 'SCHOOL_ADD_ADDRESS';
    static STUGUA_MENUCODE = 'STUD_ADD_GUA';
    static STUHEALTH_MENUCODE = 'STUD_ADD_HEA';
    static STUBIRTH_MENUCODE = 'STUD_ADD_BIR';
    static STUIMMU_MENUCODE = 'STUD_ADD_IMM';
    static STUCOHO_MENUCODE = 'STUD_ADD_COH';
    static STUELIGI_MENUCODE = 'STUD_ADD_ELI';
    static STUGIFT_MENUCODE = 'STUD_ADD_GIF';
    static STFDEM_MENUCODE = 'STAF_ADD_DEM';
    static STFEMP_MENUCODE = 'STAF_ADD_EMP';
    static STFROLE_MENUCODE = 'STAF_ADD_ROL';
    static STFCONT_MENUCODE = 'STAF_ADD_CON';
    static STFQUALI_MENUCODE = 'STAF_ADD_QUA';
    static STFDOCU_MENUCODE = 'STAF_ADD_DOC';
    static STFVISA_MENUCODE = 'STAF_ADD_VIS';
    static STFTEACH_MENUCODE = 'STAF_ADD_TEA';
    static STFCRED_MENUCODE = 'STAF_ADD_CRE';
    static STFPER_MENUCODE = 'STAF_ADD_PER';
    static CUSTOMFIELD_ADD = 'add';
    static CUSTOMFIELD_VIEW = 'view';
    static TEXTBOX = 301;
    static DROPDOWN = 302;
    static CHECKBOX = 303;
    static RADIO = 304;
    static TAREA = 305;
    static DATEFIELD = 306;
    static DATETIME = 307;
    static MULTI = 308;
    static STRING = 10001;
    static INT = 10002;
    static BIGINT = 10003;
    static DECIMAL = 10004;
    static DATE = 10005;
    static DATETM = 10006;
    static BOOLEAN = 10007;
    static CHAR = 10008;
    static DEFAULT_PUBLICATION_STATUS = 9502;
    static PUBLISHED_PUBLICATION_STATUS = 9503;
    static ADOPTED_PUBLICATION_STATUS = 9501;
    static fieldName = 'fieldName';
    static CUSTOMFIELD = 'FIELD';
    static CUSTOMFIELDGROUP = 'FIELDGROUP';
    static IS_SCHEDULE = 'SCHEDULE';
    static IS_SESSION = 'SESSION';
    //Department related Constant
    static IS_DELETED = 'deleted';
    static IS_CREATE_IN_ALL_SCHOOLS = 'createInAllSchools';
    static IS_REPORTER = 'isreporter';
    //Datatable id related constant
    static GRADELEVEL_DATATABLE = 'gradeLevelsTable';
    static SECTION_DATATABLE = 'sectionTable';
    static SESSION_DATATABLE = 'sessionTable';
    static ROOM_DATATABLE = 'roomTable';
    static PERIOD_DATATABLE = 'periodTable';
    static ROLE_DATATABLE = 'roleTable';
    static DEPARTMENT_DATATABLE = 'departmentTable';
    static STUDENT_LIST_DATATABLE = 'studentlist';
    static ACTIVITY_GROUP_DATATABLE = 'activityGroup';
    static ACTIVITY_GROUP_ADD_DATATABLE = 'activitygroupadd';
    static STAFF_LIST_DATATABLE = 'staffList';
    static SCHOOL_CUSTOM_CATEGORY = 'SCHL_FIELD';
    static STUDENT_CUSTOM_CATEGORY = 'STUD_FIELD';
    static STAFF_CUSTOM_CATEGORY = 'STAF_FIELD';
    static TABLE_RESET_COUNT = 10;
    static PAGINATION_ROUTES = ['scheduling/individualStudentSchedule', 'staff/holidayListPublication'];
    //Staff related constant
    static IS_EMERGENCY_CONTACT = 'isEmergencyContact';
    static LOOKUP_CALENDAR_EVENT_TYPE = 'lookupCalendarEventType';
    static IS_ALL_DAY_LONG = 'isAllDayLong';
    static HOME_ADDRESS = 'Home Address';
    static OTHER_ADDRESS = 'Other Address';
    //Student related constant
    static ENROLLMENT_STATUS_NO_SHOW = 'No Show';
    static DEFAULT_GENDER = "M";
    //Person related constant
    static IS_HOME_PHONE = 'Home Phone';
    static IS_MOBILE_PHONE = 'Mobile Phone';
    static IS_WORK_PHONE = 'Work Phone';
    static IS_FAX = 'Fax';
    //Calendar Crisis related constant
    static IS_EFFECT_DAY_ACTIVITY = 'isInterruptDayActivity';
    //Staff Leave related constant
    static IS_PARTIAL_DAY_LEAVE = 'isPartialDayLeave';
    static IS_NOTIFIED = 'isNotified';
    //Course Manager
    static COURSEMANAGER_SUBJECT = 'subject';
    static COURSEMANAGER_COURSE = 'course';
    static COURSEMANAGER_COURSESECTION = 'courseSection';
    static DELETELEVEL = 'level';
    static IS_EXAM = 'isExam';
    static IS_ASYNCHRONOUS = 'isAsynchronous';
    static INSTRUCTIONAL_MINUTES = 'instructionalMinutes'
    static MAXIMUM_REPETABILITY = 'maximumRepeatibility';
    static COURSE_WEIGHT = 'courseWeight';
    static CARNAGE_UNIT_CREDIT = 'carnegieUnitCredit';
    static CREDIT_VALUE = 'creditValue';
    static COURSES_ID = 'markersCoursesId';
    static COURSES_SUBJECT_ID = 'markersCoursesSubjectId'
    static COURSE_SUBJECT_ID = 'markersSubjectId';
    static COURSE_ID = 'markersCourseId';
    static COURSE_SECTION_ID = 'markersCourseSectionId';
    static COURSE_SUBJECT_TITLE = 'markersSubjectTitle';
    //Discipline
    static IS_PENDING = 'Pending';
    static IS_APPROVED = 'Approved';
    static IS_CANCELLED = 'Cancelled';
    static PERPETRATOR = 'perpetrator';
    static VICTIM = 'victim';
    //User Role
    static SCHOOL_ROLE_DIST_ADMIN = 802;
    static SCHOOL_ROLE_ADMIN = 2701;
    static SCHOOL_ROLE_TEACHER = 2702;
    static SCHOOL_ROLE_PARENT = 2703;
    static SCHOOL_ROLE_STUDENT = 2704;
    static SCHOOL_ROLE_COUNCILLOR = 2705;
    static SCHOOL_ROLE_ACCOUNTANT = 2706;
    static SCHOOL_ROLE_HR = 2707;
    static SCHOOL_ROLE_CUSTOM = 2799;
    static SCHOOL_ROLE_SUPPORT = 2711;
    //static STAFF_ROLE_DRIVER = 20202;
    //static STAFF_ROLE_CONDUCTOR = 20203;
    //Transcript
    static IS_EXCLUDE = 'isExcludeMassDelete';
    static SYSTEM = 18501;
    static MANUAL_OUT = 18502;
    static MANUAL_IN = 18503;
    //RegExp  
    static NUMBERS_BETWEEN_1_TO_100 = /\b((100)|[1-9]\d?)\b/;
    static ASSIGNMENT_SUBJECT_TITLE = /^(?!\s)[0-9a-zA-Z\s\,.!-\\=+_?<>@#$%&'/():;]+$/i;
    static CALENDAR_DESCRIPTION = /^(?!\s)[a-z\d\.,""\-',&*()\/+\s]+$/i;
    static SPECIAL_ALPHA_CHAR = /^.{ 1, 50 }$/;
    static SPECIAL_CHAR_ID = /^(?!\s)[a-z\d\-.:/\s]+$/i;  //special characters included -.:/
    static SPECIAL_CHAR_TEXTAREA = /^(?!\s)[a-z\d\,'-.:/\s]+$/i;  //special characters included ,'-.:/
    static SPECIAL_CHAR_NAME = /^(?!\s)[a-z\d\,.-\s]+$/i;  //special characters included ,.-
    static SPECIAL_CHAR_DESCRIPTION = /^(?!\s)[a-z\d\,'-./\s]+$/i;  //special characters included ,.-/'
    static SPECIAL_CHAR_IDENTIFICATION_CODE = /^(?!\s)[a-z\d\'-/_&.\s]+$/i;  //special characters included -/_&.'
    static ISSUER_NAME = /^(?!\s)[a-zA-Z\s]+$/i; //only alphabates with space
    static IDENTIFIER = /^[a-zA-Z\d]+$/i;
    static SPECIAL_TEXTAREA_ID = /^(?!\s)[a-z\d\-.:/,&\s]+$/i;
    static GRADE_REGEX = /^\d{0,4}$/;
    //static WEBSITE_REGEX = /^(https ?: \/\/)?(www\.)?([a-zA-Z\d\/\\&.:\s]+(-?[a-zA-Z\d\/\\&.:\s])*\.)+[\w]{2,}(\/\S*)?$/;
    static SCHOOL_NAME_REGEX = /^(?!\s)[A-zÀ-ú\d\'^\s\-]+$/i;
    static SYSTEM_NAME_REGEX = /^(?!\s)[a-zA-Z\s\^'/]+$/i;
    static ADDRESS_SCHOOL = /^(?!\s)[a-zA-Z'#\d\/,&.:-\s]+$/i;
    static DISTRICT_ADDRESS_CITY_STATE_COUNTRY = /^(?!\s)[A-Za-z\. ]+$/     //allows only alphabets, dot and space
    static ADDRESS_CITY = /^(?!\s)[A-Za-z\s\.]+$/
    static POSTAL_CODE_ZIP = /^(?!\s)[0-9-\s]*[0-9-\s][0-9-\s]*$/
    static POSTAL_CODE_ZIP_ADDRESS = /^(?!\s)[0-9-/\s]*[0-9-/\s][0-9-/\s]*$/
    static PHONE_NUMBER_FORMAT = /^(?!\s)[0-9-()\s]*[0-9-()\s][0-9-()\s]*$/
    static AREA_CODE = /^(?!\s)[0-9+-\s]*[0-9+-\s][0-9+-\s]*$/;
    static PHONE_EXT = /^[0-9]+$/;   //only numbers
    static DISCIPLINE_EXT = /^\d{0,10}(\.\d{1,2})?$/
    //static EMAIL = /^([a-zA-Z\d\.-_@$]+(-?[a-zA-Z\d\.-_@$])*\.)+[\w]{2,}(\/\S*)?$/
    static DESIGNATION = /^(?!\s)[a-zA-Z\'.\s]+$/i;
    //static GROUP_TITLE = /^(?!\s)[a-zA-Z\d\-&:/s]+$/i;
    static GROUP_TITLE = /^(?!\s)[0-9a-zA-Z\s\-:&'/]+$/i;   //accepts alphanumeric and special characters &:/- and space 
    static MARKING_PERIOD_TITLE = /^(?!\s)[0-9a-zA-Z\s-]+$/i;
    static GRADE_LEVEL_TITLE = /^(?!\s)[0-9a-zA-Z\s]+$/i;
    static ANNOUNCEMENT_TITLE = /^(?!\s)[0-9a-zA-Z\s-,&'!%#@nbsp*()$/]+$/i;
    static ANNOUNCEMENT_DESCRIPTION = /^(?!\s)[0-9a-zA-Z\s-,.%#&/]+$/i;
    static TITLE1 = /^(?!\s)[0-9a-zA-Z\s\-\./]+$/i;  // only accepts special characters of . / -
    static FIELD_NAME_LABEL_VALIDATION_MESSAGE = /^(?!\s)[a-z\d\-.%#&\s]+$/i;
    static CALENDAR_TITLE = /^(?!\s)[a-z\d\.""\-'#&*()\/+\s]+$/i;
    static DESCRIPTION = /^(?!\s)[a-z\d\-.'&*""\s]+$/i;
    static EVENT_TITLE = /^(?!\s)[a-z\d\.""\-'&*()\/+\s]+$/i;
    static EVENT_TITLE_CALENDER = /^(?!\s)[a-z\d\.,""\-'&*()\/+\s]+$/i;
    static PERIOD_TITLE = /^(?!\s)[a-z\d\-.\s]+$/i;    //accepts charecters -.
    static PERIOD_CODE = /^(?!\s)[a-z\d\-./\s]+$/i;
    static GRADE_CODE = /^(?!\s)[a-z\d\-'#/\s]+$/i;
    static SECTION_TITLE = /^(?!\s)[a-z\d\.-\s]+$/i;
    static SECTION_CODE = /^(?!\s)[a-z\d\-.+&\s]+$/i;
    static SECTION_DESCRIPTION = /^(?!\s)[a-z\d\.,-/'\s]+$/i;
    static SESSION_CODE = /^(?!\s)[a-z\d\-.""'&/\s]+$/i;
    static ROLLOVER_SESSION_CODE = /^(?!\s)[a-z\d\-/&/\s]+$/i;
    static SESSION_DESCRIPTION = /^(?!\s)[a-z\d\-.""'&/\s]+$/i; //Follow this pattern if there are many charecters need to be allowed
    static PREMISES_TITLE = /^(?!\s)[a-z\d\-.'#&\s]+$/i;
    static ROOMS_DESCRIPTION = /^(?!\s)[a-z\d\-.'&()%$\s]+$/i;
    static CALENDAR_CRISIS_DESCRIPTION = /^(?!\s)[a-z\d\-.%#&\s]+$/i;
    static DEPARTMENT_TITLE = /^(?!\s)[a-z\d\-./&,#\s]+$/i;
    static DEPARTMENT_DESCRIPTION = /^(?!\s)[a-z\d\-./,&#\s]+$/i;
    static HOLIDAY_MANAGEMENT_TITLE_DESCRIPTION = /^(?!\s)[a-z\d\-&/:\s]+$/i;
    static PREMISE_ADDRESS = /^(?!\s)[a-z\d\-.:,&\s]+$/i;
    static PHONE_NUMBER_ADD_PERSON = /^(?!\s)[0-9+-\s]*[0-9+-\s][0-9+-\s]*$/
    static NAME_SUFFIX = /^(?!\s)[a-zA-Z ]*$/;   //allows alphabets and space only
    static STUDENT_GENERAL_INFO = /^(?!\s)[A-zÀ-ú\d\'^\s\-]+$/i;
    static STUDENT_CODE = /^(?!\s)[a-z\d\-/\s]+$/i;
    static STUDENT_CITY_STATE = /^(?!\s)[a-zA-Z\s()]*$/;
    static SYSTEM_NAME = /^(?!\s)[a-z\.,-\s]+$/i;
    static IDENTIFICATION_CODE = /^(?!\s)[a-z\d\-/_&.\s]+$/i;
    static SCHEDULE_CAST_NAME = /^(?!\s)[a-z\d\-.,\s]+$/i;
    static TRIBAL_CAST_NAME = /^(?!\s)[a-z\d\-.,'\s]+$/i;
    static REFERRAL_REGEX = /^(?!\s)[a-z\d\-.,'\s]+$/i;
    static MARKING_PERIOD_DESCRIPTION = /^(?!\s)[a-z\d\-.:&\s]+$/i;
    static INSURANCE_NAME = /^(?!\s)[a-z\d\-.&/\s]+$/i;
    static ALLERGIC_DRUG_DETAILS = /^(?!\s)[a-z\d\-.:&',/\s]+$/i;
    static REASON_FOR_MEDICATION = /^(?!\s)[a-z\d\-:./\s]+$/i;
    static DOCUMENT_NAME = /^(?!\s)[a-z\d\-.:/\s]+$/i;
    static IMMUNIZATION_NAME = /^(?!\s)[a-z\d\-.'\s]+$/i;
    static COHORT_DESCRIPTION = /^(?!\s)[a-z\d\-.,/'\s]+$/i;
    static DOCTORS_COMMENTS = /^(?!\s)[a-z\d\-.,/'\s]+$/i;
    static UNION_MEMBERSHIP_NAME = /^(?!\s)[a-z\d\-.,&\s]+$/i;
    static CERTIFICATE_TITLE = /^(?!\s)[a-z\d\-.,\s]+$/i;
    static INSTITUITION_NAME = /^(?!\s)[a-z\d\-.,\s]+$/i;
    static ISSUER_NAME_PERSON = /^(?!\s)[a-z\-.'\s]+$/i;
    static DOCUMENT_IDENTIFICATION_CODE = /^(?!\s)[a-z\d\-'/\s]+$/i;
    static CERTIFICATE_NUMBER = /^(?!\s)[a-z\-./\s]+$/i;
    static JOB_TITLE = /^(?!\s)[a-z\-.&/\s]+$/i;
    static JOB_DESCRIPTION = /^(?!\s)[a-z\d\-.,#&/\s]+$/i;
    static LEAVE_DESCRIPTION = /^(?!\s)[a-z\d\-.,'&/\s]+$/i;
    static GRADE_SCALE_TITLE = /^(?!\s)[a-z\d\-:&/\s]+$/i;
    static GRADE_SCALE_DESCRIPTION = /^(?!\s)[a-z\d\-:,%<>=&/\s]+$/i;
    static EMPLOYEE_CODE = /^(?!\s)[a-z\d\-./\s]+$/i;
    static LICENCE_NO = /^(?!\s)[a-z\d\-./\s]+$/i;
    static ADDRESS_COUNTY_REGEX = /^(?!\s)[A-Za-z ]+$/   //matches alphabets and space only
    static SUBJECT_TITLE = /^(?!\s)[a-z\d\-:,(',')&'/\s]+$/i;
    static COURSE_TITLE = /^(?!\s)[a-z\d\-:.,(',')&'/\s]+$/i;
    static SUBJECT_CODE = /^(?!\s)[a-z\d\-:&'_/\s]+$/i;
    static SUBJECT_DESCRIPTION = /^(?!\s)[a-z\d\-.&%#/\s]+$/i;
    static SUB_DESCRIPTION = /^(?!\s)[,a-z\d\-.&%#/\s]+$/i;
    static CERIFICATE_DESCRIPTION = /^(?!\s)[a-z\d\-.&#\s]+$/i;
    static CERIFICATION_DESCRIPTION = /^(?!\s)[,a-z\d\-.&#\s]+$/i;
    static FUNDING_PROGRAM = /^(?!\s)[a-z\d\-&'\s]+$/i;
    static ASSESSMENT_CODE = /^(?!\s)[a-z\d\-/'\s]+$/i;
    static ASSESTS_TITLE = /^(?!\s)[a-z\d\-'&/\s]+$/i;
    static SEA_LEA_ID = /^(?!\s)[1-9-]*[0-9-][0-9-]*$/;
    static ONLY_NUMBERS_AND_DECIMAL = /^(?!0\d|$)\d*(\.\d{1,4})?$/;
    static EVENT_ATENDANCE = /^(?!\s)[a-z\d\,&-./\s]+$/i;  // Special Characters Included here are '&, , , ., -, /,;
    static DECIMAL_VALUE = /^(?!^0*$)(?!^0*\.0*$)^\d{1,5}(\.\d{1,2})?$/; // Check a Decimal value Upto (5,6)
    static FLOAT_VAL = /^[0-9]*\.?[0-9]*$/; // Allow only number and single dot
    static DECIMAL_REGEX = /^[1-9]\d*(\.\d+)?$/i;    //matches decimals and numbers
    static INCIDENT_CODE_DESCRIPTION = /^(?!\s)[a-z\d\-.,:;&/\s]+$/i;
    static LATE_REASON = /^(?!\s)[a-z\d\-.,&'/;\s]+$/i;
    static PRECISION_NINE_WITH_SCALE_THREE = /^\d{1,9}(\.\d{1,3})?$/       //Matches numeric digits with length 9 and scale of 3
    static OCCUPATION_TITLE_DESIGNATION = /^(?!\s)[a-z\d\-'\s]+$/i;
    static MONTHLY_INCOME = /^(?!\s)[0-9-.,\s]*[0-9-.,\s][0-9-.,\s]*$/
    static PRECISION_FIVE_WITH_SCALE_TWO = /^\d{0,5}(\.\d{1,2})?$/   //Matches numeric digits with length 5 and scale of 2
    static MIN_VALUE = /^[1-9]+$/; //only numbers 1 to 9    
    static STAFF_CONTACT_RESTRICTION_NOTES = /^(?!\s)[a-z\d\-./&@'\s]+$/i;
    static ASSIGNMENT_TITLE = /^(?!\s)[0-9a-zA-Z\s\-:&'/]+$/i; //special characters included a-z, A-Z, 0-9, space, -/:'&
    static MARKING_PERIOD_EXAM_CODE = /^(?!\s)[a-z\d\-:/#@\s]+$/i;
    static ALPHA_WITH_SPECIAL_CHAR = /^(?!\s)[0-9a-zA-Z\s\-:&./]+$/i; //special characters included a-z, A-Z, 0-9, space, -/:.&
    static ALPHA_WITH_SPECIAL_CHAR_COURSEEXP = /^(?!\s)[0-9a-zA-Z\s\-/.,&():/]+$/i; //special characters included a-z, A-Z, 0-9, space, -/.:
    static PRECISION_FIVE_WITH_SCALE_FIVE = /^\d{0,5}(\.\d{1,5})?$/
    static COPY_TITLE = /^(?!\s)[0-9a-zA-Z\s\-_:&'/]+$/i
    //File Types
    static FILE_TYPE_IMAGE = 'jpeg|jpg|png';
    static FILE_TYPE_IMAGE_MAXSIZE = 2000000;
    static FILE_TYPE_IMAGE_MINSIZE = 1024;
    static FILE_TYPE_DOCS = 'jpeg|jpg|png|pdf';
    static FILE_TYPE_DOCS_MAXSIZE = 2000000;
    static FILE_TYPE_DOCS_MINSIZE = 1024;
    static FILE_TYPE_STUDY_MATERIAL_MAXSIZE = 103809024;     //equivalent to 99 MB
    static FILE_TYPE_DOCS_STUDY_MATERIAL_MINSIZE = 1048576;  //equivalent to 1 MB
    static FILE_TYPE_EXCEL = 'xls';
    static FILE_TYPE_PDF = 'pdf';
    static FILE_TYPE_QUALIFICATION_CERTIFICATE = 'docx|jpg|png|doc|pdf|jpeg|pptx|ppt|xls|xlsx';
    static FILE_TYPE_DOCS_COPY = 'doc|docx|docs|pdf';
    static FILE_TYPE_ALL_DOCS = 'doc|docx|docs|pdf|pptx|ppt|xls|xlsx|odt';
    static FILE_TYPE_ONLY_DOCS = 'doc|docx|docs';
    static FILE_TYPE_ALL_DOCS_EMAIL = 'doc|docx|pdf|xlsx|png|jpg';
    static FILE_TYPE_PERSONAL_DOCUMENT = 'docx|jpg|png|doc|pdf|jpeg|xlsx|xls'
    static FILE_TYPE_ASSESSMENT_ASSESTS = 'docx|doc|pdf|xlsx';
    static ALL_FILE_TYPE_WITH_ZIP = 'docx|jpg|png|doc|pdf|jpeg|pptx|ppt|xls|xlsx|odt|zip|rar';
    static FILE_DEFAULT_ID = 173;
    static FILE_LOCATION_TYPE = 2101;
    static FILE_LOCATION_TYPE2 = 2301;
    //Reset store
    static COMMON_STORE_RESET = 'COMMON_STORE_STATE_RESET';
    static STUDENT_STORE_RESET = 'STUDENT_STORE_STATE_RESET';
    static STAFF_STORE_RESET = 'STAFF_STORE_STATE_RESET';
    static SCHOOL_STORE_RESET = 'SCHOOL_STORE_STATE_RESET';
    static COURSE_STORE_RESET = 'COURSE_STORE_STATE_RESET';
    static SCHEDULE_STORE_RESET = 'SCHEDULE_STORE_STATE_RESET';
    static REPORT_STORE_RESET = 'REPORT_STORE_STATE_RESET';
    static ATTENDANCE_STORE_RESET = 'ATTENDANCE_STORE_STATE_RESET';
    static DISCIPLINE_STORE_RESET = 'DISCIPLINE_STORE_RESET';
    static ASSESSMENT_STORE_RESET = 'ASSESSMENT_STORE_RESET';
    static DISTRICT_STORE_RESET = 'DISTRICT_STORE_RESET';
    static LOGIN_STORE_RESET = 'LOGIN_STORE_RESET';
    static SYSTEM_STORE_RESET = 'SYSTEM_STORE_RESET';
    //DATEPICKER
    static END_DATE = "-1y";
    static BIRTH_DATE = "-14y";
    static END_DATE_AWARD = new Date();
    static AWARD_DATE_NAME = 'awardDate';
    static STAFF_MIN_AGE = 14;
    //DISCIPLINE
    static REPORTED_TO_LAW_ENFORCEMENT = "isReportedToLawEnforcement";
    static DISABILITY_MANIFASTATION = "isDisabilityManifestation";
    static IS_FULL_YEAR_EXPULSION = "isFullYearExpulsion";
    static IS_EXPULSION_SHORTED = "isExpulsionShorted";
    static IS_ZERO_TOLLERANCE_RELATED = "isZeroTollerenceRelated";
    static IS_ES_AVAILABLE_AFTER_REMOVAL = "isESAvailableAfterEemoval";
    static IS_IEP_PLACEMENT_MEETING = "isIEPPlacementMeeting";
    static DISCIPLINARY_ACTION_DURATION = "disciplinaryActionDuration";
    //ATTENDANCE
    static ATTN_PRESENT = 30001;
    static ATTN_EX_ABS = 30002;
    static ATTN_UNEX_ABS = 30003;
    static ATTN_LATE = 10104;
    static ATTN_FVMN_LATE = 10105;
    static ATTN_TARDY = 10106;
    static ATTN_ER_DEPT = 10107;
    static LOOKUP_ATTENDACE_STATUS_TARDY = 10106;
    static ATTN_LATE = 'Late';
    static ATTN_MIN_LATE = '5 mins late';
    static ATTN_ABSENT = 'Absent';
    static ATTN_UNEXCUSED_ABSENT = 'Unexcused Absence';
    static ATTN_EXCUSED_ABSENT = 'Excused Absence';
    static ATTN_TARDY = 'Tardy';
    static ATTN_EARLY_DEP = 'Early Departure';
    static NOTALLOACTED = 'NonAllocated';
    static SECTION_ALLOCATED = 'SectionAllocated';
    static SCHEDULED = 'Scheduled';
    //CUSTOM FIELD
    static ADOPTED_VALUE = 9501;
    static DRAFT_VALUE = 9502;
    static PUBLISHED_VALUE = 9503;
    //ASSESSMENT
    static INCLUDE_STUDENT_CODE = "includeStudentCode";
    static INCLUDE_DISTRICT_CODE = "includeDistrictCode";
    static INCLUDE_ENROLMENT_ID = "includeEnrolmentId";
    static INCLUDE_GRADE_LEVEL = "includeGradeLevel";
    static INCLUDE_STUDENT_PHOTO = "includeStudentPhoto";
    static INCLUDE_IDENTIFICATION_CODE = "includeIdentificationCode";
    static INCLUDE_ETHNICITY = "includeEthnicity";
    static INCLUDE_DATE_OF_BIRTH = "includeDateOfBirth";
    static SHOW_LETTER_GRADE = "showLetterGrade";
    static SHOW_PERCENTAGE = "showPercentage";
    static DISPLAY_BASE_GRADING_SCALE = "displayBaseGradingScale";
    static GRADE_PERCENTAGE = "gradePercentage";
    static STUDENT_INFO = "studentInfo";
    static IS_STUDENT_INFO = "isStudentInfo";
    static IS_STUDENT_CODE = "isStudentCode";
    static IS_INCLUDE_STUDENT_PHOTO = "isIncludeStudentPhoto";
    static IS_DISTRICT_CODE = "isDistrictCode";
    static IS_IDENTIFICATION_CODE = "isIdentificationCode";
    static IS_ENROLMENT_ID = "isEnrolmentID";
    static IS_ETHNICITY = "isEthnicity";
    static IS_GRADE_LEVEL = "isGradeLevel";
    static IS_DOB = "isDOB";
    static IS_GRADE_PERCENTAGE = "isGradePercentge";
    static IS_SHOW_LETTER_GRADE = "isShowLetterGrade";
    static IS_SHOW_PERCENTAGE = "isShowPercentage";
    static IS_INCLUDE_GPA = "isIncludeGPA";
    static IS_INCLUDE_CLASS_RANK = "isIncludeClassRank";
    static IS_SHOW_TEACHER = "isShowTeacher";
    static IS_TEACHER_COMMENTS_AND_SUGGESTIONS = "isTeacherCommentsAndSuggestions";
    static IS_ATTENDANCE = "isAttendance";
    static IS_DAILY_ATTENDANCE_SUMMARY = "isDailyAttendanceSummary";
    static IS_ATTENDANCE_YEAR_TO_DATE = "isAttendanceYearToDate";
    static IS_ATTENDANCE_CURRENT_MARKING_PERIOD = "isAttendanceCurrentMarkingPeriod";
    static IS_COURSE_WISE_ATTENDANCE_SUMMARY = "isCourseWiseAttendanceSummary";
    static IS_SIGNATURE = "isSignature";
    static IS_CLASS_TEACHER_COMMENTS_AND_SUGGESTIONS = "isClassTeacherCommentsAndSuggestions";
    static IS_PRINCIPAL_SIGNATURE = "isPrincipalSignature";
    static IS_PRINCIPAL_COMMENTS_AND_SUGGESTIONS = "isPrincipalCommentsAndSuggestions";
    static IS_STUDENT_SIGNATURE = "isStudentSignature";
    static IS_STUDENT_DATE = "isStudentDate";
    static IS_PARENT_GUARDIAN_SIGNATURE = "isParentGuardianSignature";
    static IS_PARENT_DATE = "isParentDate";
    static VTL_LOGIN = "Vitul Bansal";
    static ADMIN_LOGIN = "Admin MarkersPro";
    static IS_INCLUDE_ISSUE_DATE = "isIncludeIssueDate";
    static IS_SHOW_GENERAL_ITEMS = "isShowGeneralItems";
    static IS_PROMOTION_STATUS = "isPromotionStatus";
    static IS_SHOW_BASE_GRADE_SCALE_DESCRIPTION = "isShowBaseGradeScaleDescription";
    static IS_INCLUDE_DISCIPLINE_RECORDS = "isIncludeDisciplineRecords";
    static IS_CLASS_TEACHER_SIGNATURE = "isClassTeacherSignature";
    static IS_MARKING_PERIOD = "isMarkingPeriod";
    static WEIGHTAGE_TO_FINAL_TRANSCRIPT = "weightageToFinalTranscript";
    static LEA_ID = "leaId";
    static SEA_ID = "seaId";
    static INCLUDE_GRADUATES = "includeWithdrawn";
    static INCLUDE_TRANSCRIPT = "onlyWithTranscript";
    //DISTRICT
    static DISTRICT_CONTROL_MODE_BOTH = "Both";
    static DISTRICT_CONTROL_MODE_MANUAL = "Manual";
    static DISTRICT_CONTROL_MODE_GENERATED = "Generated";
    static STUDENT_STAFF_ERROR_CODE = '-1';
    static LOOKUP_DCP_STAFF_IDENTIFICATIONSYSTEM = 'STAFF_IDENTIFICATION_SYSTEM';
    static LOOKUP_DISTRICT_SERVICEID_COURSE_UNITS = 'SERVICEID_COURSE_UNITS';
    static LOOKUP_DISTRICT_SERVICEID_CAREER_TECH_COURSE = 'SERVICEID_CAREER_TECHNICAL_COURSE';
    static LOOKUP_DISTRICT_STUDENT_NAME_DISPLAY_PATTERN_LN_COMMA = 26005;
    static LOOKUP_DISTRICT_STUDENT_NAME_DISPLAY_PATTERN_FN = 26001;
    static LOOKUP_DISTRICT_STUDENT_NAME_DISPLAY_SORT_LN = 26102;
    static LOOKUP_DISTRICT_STUDENT_NAME_DISPLAY_SORT_FN = 26101;
    static LOOKUP_DISTRICT_STAFF_NAME_DISPLAY_PATTERN_LN_COMMA = 26208;
    static LOOKUP_DISTRICT_STAFF_NAME_DISPLAY_PATTERN_FN = 26205;
    static LOOKUP_DISTRICT_STUDENT_NAME_DISPLAY_PATTERN_FIELD_TYPE = 10001;
    static LOOKUP_DISTRICT_STUDENT_NAME_DISPLAY_PATTERN_FIELD_KEY = 'STUDENT_DISPLAY_NAME_SORT_ORDER';
    static LOOKUP_DISTRICT_STAFF_NAME_DISPLAY_PATTERN_FIELD_KEY = 'STAFF_DISPLAY_NAME_SORT_ORDER';
    static LOOKUP_TYPEOF_DISTRICT = 'TYPEOF_DISTRICT';
    static LOOKUP_DISTRICT_OPARATION_STATUS = 'ORGANIZATION_OPERATIONAL_STATUS';
    static LOOKUP_DISPLAY_TYPE = 'LOOKUP_DISPLAY_TYPE'
    static LOOKUP_SYSTEM_VALUES_KEY = 'SYSTEM_LOOKUP_VALUES'
    //TRANSPORT
    static LOOKUP_TRANSPORTATION_VEHICLE_LOG_TYPE = 'TRANSPORTATION_VEHICLE_LOG_TYPE';
    static LOOKUP_TRANSPORTATION_VEHICLE_IMAGE_TYPE = 'TRANSPORTATION_VEH_IMAGE_TYPE';
    //SYSTEM SELECT SCHEDULE TYPE
    static USE_SCHEDULE_REGULAR_WEEK_DAYS = 'useScheduleRegularWeekdays';
    static USER_SCHEDULE_DAY_CODE = 'useScheduleDayCode';
    //SYSTEM 
    static LOGIN_ATTEMPT_LIMIT = 'LOGIN_ATTEMPT_LIMIT';
    //ADDRESS
    static COMMON_COMMA = ',';
    //Contacts(Indian School)
    static FATHER_RELAITONSHIP = 'Father';
    static MOTHER_RELAITONSHIP = 'Mother';
    static SIBLING_RELATIONSHIP = 'Sibling';
    // Static Values
    static STATIC_VAL_TEACHER_TITLE = 'Teacher';
    static STATIC_VAL_PARENT_TITLE = 'Parent';
    static STATIC_VAL_STUDENT_TITLE = 'Student';
    static FILE_SIZE_100MB = 104857600;
    static FILE_SIZE_1KB = 1024;
    static STATIC_VAL_LOOKUP_SPECIAL_EDUCATION_OPTION1 = 37601;
    static LOOK_UP_CALENDAR_EVENT_TYPE = 2409;
    static COMPONENT_GET_MESSAGE_SENT_LIST = 'v1/adminsendemail/getsentemails';
    static ADMIN_ROLE_ID = 2701;
    static TEACHER_ROLE_ID = 2702;
    //address type
    static OFFICE_ADDRESS = 602;
    static MAILING_ADDRESS = 604;
    static PHYSICAL_ADDRESS = 605;
    //publication status
    static PUBLISHED_PUBLICATOIN_STATUS = "Published";
    static DRAFT_PUBLICATOIN_STATUS = "Draft";
    //Attendance admin constant
    static ADMIN_CONST = 1001;
    static LOOKUP_REPORT_BASED_ON = "STAFF_ATTD_RPT_BASED_ON";
    static LOOKUP_REPORT_TYPE = "STAFF_ATTD_RPT_TYPE";

    //Grade Plan
    static REQUIREMENT_COURSE = 41601
    static REQUIREMENT_OTHER = 41602

    static LOOKUP_ADMIN_CLASS_ROASTER_RPT_SORTORDER = 'ADMIN_CLASS_ROASTER_RPT_SORTORDER';
    static MASS_DELETE_MESSAGE = "v1/notification/massdeletemessages?messageIds=";
    static MASS_READ_MESSAGE = "v1/notification/massmarkasread?messageIds=";

    static SCHOOL_TIMEZONE_SETUP_DETAILS = 'schoolTimeZoneSetupDetails';

    //Unpaid list
    static COMPONENT_BILLING = "billing";
    static GETSTUDENT_ALLUNPAID_PAYABLES = "v1/studentpayable/getstudentsallunpaidpayables?"
    static DELETE_STUDENTS_SELECTED_UNPAID_PAYABLES = "v1/studentpayable/deletestudentsselectedunpaidpayables"
    //mass assign
    static ECONOMIC_DISADVANTAGE = 'ECONOMIC_DISADVANTAGE';
    static FSELIGIBILITY = 'FOOD_SERVICE_ELIGIBILITY';

    static FS_PARTICIPATION = 'FOOD_SERVICE_PARTICIPATION';

    static FS_ELIGIBILITY_TYPE_FREE = 'FOOD_SERVICE_ELIGIBILITY_TYPE_FREE';
    //category type
    static END_ECONOMIC_DISADVANTAGE_TYPE = 124605  //41806
    static ECONOMIC_DISADVANTAGE_TYPE = 124604  //41805
    static GENERAL_INFO_TYPE = 41801
    static STUDENT_DIGITAL_TYPE = 124603  //41804
    static BUS_RIDER_TYPE = 124602    //41803
    static STUDENT_ELTP_TYPE = 124601   //41802

    //student addendance percentage
    static PRESENT_ONLY = 29801
    static ALL_ENROLLED = 29901
    static OFFICIAL_ATTENDANCE = 29702
    static DAILY_ATTENDANCE = 29701
}
export default commonConfig;
