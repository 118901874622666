import React from 'react';
import i18n from '../../../i18n';
import { remove, map, filter, find } from 'lodash';
import sessionStore from './sessionStoreComponent';
import commonConfig from '../commonConfig';
const moment = require('moment');
const momentTimeZone = require('moment-timezone');
export class CommonFuncs {
    timeFormat(date) {
        var d = date;
        var hh = d.slice(0, 2);
        var m = d.slice(3, 2);
        var dd = "AM";
        var h = hh;
        if (h >= 12) {
            h = hh - 12;
            dd = "PM";
        }
        if (h === 0) {
            h = 12;
        }
        m = m < 10 ? "00" + m : m;
        var s = s < 10 ? "00" + s : s;
        /* if you want 2 digit hours:
        h = h<10?"0"+h:h; */
        var replacement = h + ":" + m;
        /* if you want to add seconds
        replacement += ":"+s;  */
        replacement += " " + dd;
        return replacement;
    }
    returnResetRoute(currentRoute) {
        let returnRoute = '';
        if (currentRoute && currentRoute.includes("school") === true)
            returnRoute = '/school/showGeneralInfoRoute';
        if (currentRoute && currentRoute.includes("student") === true)
            returnRoute = '/student/showStudentListRoute';
        if (currentRoute && currentRoute.includes("staff") === true)
            returnRoute = '/staff/showStaffListRoute'
        return returnRoute;
    }
    addClassToBody(className) {
        if (className !== '')
            window.$('body').addClass(className)
    }
    removeClassFromBody(className) {
        if (className !== '')
            window.$('body').removeClass(className)
    }
    compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }
            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];
            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }
    //Returns day of week...
    getDaysName = (id) => {
        switch (parseInt(id, 10)) {
            case 1:
                return i18n.t('monday');
            case 2:
                return i18n.t('tuesday');
            case 3:
                return i18n.t('wednesday');
            case 4:
                return i18n.t('thursday');
            case 5:
                return i18n.t('friday');
            case 6:
                return i18n.t('saturday');
            case 7:
                return i18n.t('sunday');
            default:
            // code block 
        }
    }
    //This will convert time into 12hr format
    convertTime = (tempTime) => {
        var am_pm = "AM";
        var arrHours = ['0', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
        var new_time = tempTime.split(":");
        var hours = parseInt(new_time[0], 10);
        var min = new_time[1];
        var hrs = arrHours[hours];
        if (hours > 12)
            am_pm = "PM";
        var cTime = hrs + ":" + min + " " + am_pm;
        return cTime;
    }
    //This will convert time into 12hr format with seconds
    convertTimeWithSeconds = (tempTime) => {
        let convertedTime = '';
        if (!!tempTime) {
            var am_pm = "AM";
            var arrHours = ['0', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
            var new_time = tempTime.split(":");
            var hours = parseInt(new_time[0], 10);
            var min = new_time[1];
            const sec = new_time.length > 2 ? new_time[2] : "00";
            var hrs = arrHours[hours];
            if (hours > 12) {
                am_pm = "PM";
            }
            convertedTime = hrs + ":" + min + ":" + sec + " " + am_pm;
        }
        return convertedTime;
    }
    //This function is used to convert 12 hr time format to 24 hr format for validating
    get24hTime = (str) => {
        str = String(str).toLowerCase().replace(/\s/g, '');
        var has_am = str.indexOf('am') >= 0;
        var has_pm = str.indexOf('pm') >= 0;
        str = str.replace('am', '').replace('pm', '');
        if (str.indexOf(':') < 0) str = str + ':00';
        if (has_am) str += ' am';
        if (has_pm) str += ' pm';
        var d = new Date("1/1/2011 " + str);
        var doubleDigits = function (n) {
            return (parseInt(n, 10) < 10) ? "0" + n : String(n);
        };
        return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes());
    }
    //This function is used to convert Date
    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }
    //Remove element from array
    removeElement = (array, name) => {
        remove(array, function (n) {
            return n.fieldName === name;
        });
    }
    //Remove element from array with custom name
    removeElementCustom = (array, name, customName) => {
        remove(array, function (n) {
            return n[customName] === name;
        });
    }
    //Get today's date in format
    getCurrentDate = () => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }
    //Get today's date for calendar event format
    getEventCurrentDate = (date) => {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var now = new Date(date);
        return days[now.getDay()] + ', ' + months[now.getMonth()] + ' ' + now.getDate() + ', ' + now.getFullYear();
    }
    //Search value from lookup array
    search = (nameKey, myArray) => {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].id === parseInt(nameKey, 10)) {
                return myArray[i].title;
            }
        }
    }
    //This function is called for modal show by id
    showModal = (id) => {
        window.$('#' + id).modal("show");
    }
    //This function is called for modal hide by id
    hideModal = (id) => {
        window.$('#' + id).modal("hide");
    }
    //This function is called for reset form attributes by id
    formTriggerReset = (id) => {
        window.$('#' + id).trigger("reset");
    }
    //Download file
    download = (filename, filetype, content) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:' + filetype + ';base64,' + encodeURIComponent(content));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    previewFile = (filename, filetype, content) => {
        // as per google's new security update through JS it is not allowing to open a base 64 URI directly into a browser thats why this new changes had made.
        let base64Uri = 'data:' + filetype + ';base64,' + encodeURIComponent(content);
        this.debugBase64(base64Uri)
    }
    previewFiles = (filename, filetype, content) => {
        var x = window.open();
        var iframe = x.document.createElement('iframe');
        iframe.width = '100%';
        iframe.height = '100%';
        iframe.name = filename;
        iframe.style = "border: 0";
        iframe.src = "data:" + filetype + ";base64, " + encodeURIComponent(content);
        x.document.body.appendChild(iframe);
    }
    //Remove duplicates from array
    removeDuplicates(myArr, prop) {
        return !!myArr && myArr.length > 0 && myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }
    //Change time format...
    changeTimeFormat(timeString) {
        let timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
            .toLocaleTimeString({},
                { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
            );
        return timeString12hr;
    }
    //Change time format...
    changeTimeFormatnew(timeString) {
        if (timeString !== null) {
            let timeString12hr = new Date(timeString).toLocaleTimeString({},
                { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
            );
            return timeString12hr.toLocaleUpperCase();
        }
        else {
            return timeString
        }
    }
    //Change time format as per time zone...
    changeTimeFormatTimeZone(timeString) {
        if (typeof timeString === 'string' && timeString != null) {
            if (!!new sessionStore().returnSchoolTimeZone()) {
                return momentTimeZone(timeString).tz(new sessionStore().returnSchoolTimeZone()).format('hh:mm A')
            }
            else {
                return momentTimeZone(timeString).local().format('hh:mm A')
            }
        }
        else
            return timeString;
    }
    //dst timezone
    //generate time as per dst/non dst timezone
    generateTimeFormat(timeString, format = 'hh:mm A', isModifiedOnDstTime = false) {
        if (typeof timeString === 'string' && timeString != null) {
            if (!!new sessionStore().returnSchoolTimeZone()) {
                let getTimeZoneSetupDetails = new sessionStore().returnSchoolTimeZoneSetupDetails();
                if (!!getTimeZoneSetupDetails) {
                    if (!!getTimeZoneSetupDetails.isDstEnabled && !!getTimeZoneSetupDetails.dstStartsFrom && !!getTimeZoneSetupDetails.dstEndsOn) {
                        if (!!isModifiedOnDstTime) {
                            return moment(timeString).utcOffset(getTimeZoneSetupDetails.dstUTCOffset).format(format)
                        } else {
                            return moment(timeString).utcOffset(getTimeZoneSetupDetails.utcOffset).format(format)
                        }
                    } else {
                        return momentTimeZone(timeString).tz(new sessionStore().returnSchoolTimeZone()).format(format)
                    }
                } else {
                    return momentTimeZone(timeString).tz(new sessionStore().returnSchoolTimeZone()).format(format)
                }
            }
            else {
                return momentTimeZone(timeString).local().format(format)
            }
        }
        else {
            return timeString;
        }
    }
    //convert time to minutes for comparison
    getTimeAsNumberOfMinutes = (time) => {
        var timeParts = time.split(":");
        var timeInMinutes = (timeParts[0] * 60) + timeParts[1];
        return timeInMinutes;
    }
    //dst timezone
    //Change time format updated...
    changeTimeFormatUpdated(timeString) {
        if (typeof timeString === 'string' && timeString != null) {
            if (timeString.substr(timeString.length - 5) === '00:00')
                return moment(timeString).utc().format('hh:mm A');
            else
                return moment(timeString).format('hh:mm A');
        }
        else
            return timeString;
    }
    //Format field status...
    formatStatus(status, customDeclineColor, customDeclineTextFormat) {
        switch (status) {
            case 'Approved':
                return <div style={{ color: 'green' }}> {status} </div>
            case 'Pending':
                return <div style={{ color: 'orange' }}> {status} </div>
            case 'Discarded':
            case 'Declined':
                return <div style={{ color: !!customDeclineColor ? customDeclineColor : 'gray', 'textDecoration': !!customDeclineTextFormat ? customDeclineTextFormat : 'line-through' }}> {status} </div>
            case 'Cancelled':
                return <div style={{ color: 'red', 'textDecoration': 'line-through' }}> {status} </div>
            default:
                return <div> {status} </div>
        }
    }
    //Format comma separated string...
    formatCommaSeparatedString(string) {
        if (string !== '' && string !== null) {
            let stringArray = string.split(",");
            let finalStringArray = [];
            if (stringArray && stringArray.length > 0) {
                stringArray.forEach((data, i) => {
                    let value;
                    value = this.getDaysName(data);
                    finalStringArray.push(value);
                })
            }
            return finalStringArray.toString();
        }
    }
    // checks if string is valid positive integer from 0 to 2,147,483,647
    isValidInt = stringNum => {
        const maxInt = 2147483647;
        if (stringNum.match(/\D/) || parseInt(stringNum, 10) > maxInt) return false;
        return true
    }
    // checks if string is valid positive bigInt from 0 to 9,223,372,036,854,775,807
    isValidBigInt = stringNum => {
        const maxBigInt = "9223372036854775807"
        if (stringNum.match(/\D/) || stringNum.length > maxBigInt.length) return false;
        if (stringNum.length < maxBigInt.length) return true;
        for (let i = 0; i < maxBigInt.length; i++) {
            if (stringNum[i] > maxBigInt[i]) return false;
        }
        return true;
    }
    // this function coverts a number string "1,2,3" into days of the week "M T W"
    convertNumberListToSpacedDaysOfTheWeek = numberString => {
        if (!numberString) return ''
        const conversionArray = [1, 2, 3, 4, 5, 6, 7];
        const dayArray = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
        for (let i = 0; i < conversionArray.length; i++) {
            conversionArray[i] = numberString.includes(conversionArray[i]) ? dayArray[i] : " ";
        }
        return `${conversionArray.join(" ")}`
    }
    getObjectIndexByValue = (object, value) => {
        let returnKey = "";
        Object.keys(object).forEach(key => {
            if (object[key] === value)
                returnKey = key
        })
        return returnKey
    }
    // takes a sentence and shortens it to meet a max character length using complete words
    textShortener = (longString, maxLength) => {
        const words = longString.split(/[\s\n]/)
        const summarizedWords = []
        let total = 0
        for (let i = 0; i < words.length; i++) {
            total += words[i].trim().length + 1;
            summarizedWords.push(words[i])
            if (total >= maxLength)
                break;
        }
        let newString = summarizedWords.join(" ")
        if (words.length > summarizedWords.length)
            newString += "..."
        return newString
    }
    IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    isBoolean = (val) => {
        let isBoolean = 'boolean' === typeof val;
        return isBoolean;
    }
    filterMenuList = (array, rejectedSubMenus = []) => {
        let listArray = map(array, (element) => {
            return { ...element, child: filter(element.child, ({ title }) => rejectedSubMenus.indexOf(title) === -1) };
        });
        return listArray;
    };

    getCurrentEnvironment = () => {
        let currentEnv;
        switch (true) {
            case window.location.hostname.includes('development'):
                currentEnv = 'mp-web-development';
                break;
            case window.location.hostname.includes('qa'):
                currentEnv = 'mp-web-qa';
                break;
            case window.location.hostname.includes('stage'):
                currentEnv = 'mp-web-stage';
                break;
            case window.location.hostname.includes('production'):
                currentEnv = 'mp-web-production';
                break;
            default:
                currentEnv = 'mp-web-local';
                break;
        }
        return currentEnv;
    }
    setPagePermissions = (relativeUrl, menuCode) => {
        let permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
        let currentPagePermissions = { viewPermission: false, editPermission: false, deletePermission: false, approvalPermission: false, verifyPermission: false, certifyPermission: false, publicationPermission: false };
        let checkCurrentUserRoleBySchool = find(new sessionStore().returnSessionSchoolBasedRoleList(), { 'schoolId': new sessionStore().returnSchoolID(), 'roleId': new sessionStore().returnUserRole() });
        if (!!menuCode && !!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Admin') {
            if (menuCode === 'STUD_GENERAL') {
                menuCode = 'STUD_GEN_INFO';
            }
            if (permissionList && permissionList.length > 0) {
                if (permissionList.includes(menuCode + "_VIEW")) {
                    currentPagePermissions.viewPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT")) {
                    currentPagePermissions.editPermission = true;
                }
                if (permissionList.includes(menuCode + "_DELETE")) {
                    currentPagePermissions.deletePermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_APPR")) {
                    currentPagePermissions.approvalPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_VERY")) {
                    currentPagePermissions.verifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_CERT")) {
                    currentPagePermissions.certifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_PUBL")) {
                    currentPagePermissions.publicationPermission = true;
                }
            }
        } else {
            let isAdminPrevSchlSessPermAccess = true;
            let adminPrevSessionPermissionList = new sessionStore().returnAdminSchlPrevSessionPermissionList();   //returns permission list for admin with respect to modifying/Add data in previous sessions
            let checkCurrentSchoolPermissionForAdmin = find(adminPrevSessionPermissionList, ['schoolId', new sessionStore().returnSchoolID()]);
            if (new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_ADMIN && !!checkCurrentSchoolPermissionForAdmin) {
                let isCurrentSchoolSessionActive = new sessionStore().returnIsCurrentSchoolSessionActive();
                isAdminPrevSchlSessPermAccess = !!isCurrentSchoolSessionActive ? true : !!checkCurrentSchoolPermissionForAdmin.isEdit ? true : false;
            }
            currentPagePermissions.viewPermission = true;
            currentPagePermissions.editPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.deletePermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.approvalPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.verifyPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.certifyPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.publicationPermission = isAdminPrevSchlSessPermAccess;
        }
        new sessionStore().removeSessionItem(commonConfig.SESSION_CURRENT_PAGE_PERMISSION);
        new sessionStore().removeSessionItem(commonConfig.SESSION_MENU_CODE);
        new sessionStore().storeSecureData(commonConfig.SESSION_MENU_CODE, menuCode);
        new sessionStore().storeSecureData(commonConfig.SESSION_CURRENT_PAGE_PERMISSION, JSON.stringify(currentPagePermissions));
    }

    debugBase64(base64URL) {
        var win = window.open();
        win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }
    // Return list of routes used for holding DT search filter
    returnFilterRoutes = () => {
        let returnArray = ['school/schoolSession', 'school/calendarDays', 'school/calendarCrisis', 'school/SchoolHolidayManagement', 'school/SchoolHolidayManagementDetails', 'school/schoolRoom', 'school/schoolSection', 'school/schoolGradeLevels', 'school/schoolRole', 'school/gradeScaleLevel', 'school/defineGPA', 'school/standardGradingSet', 'school/standardGroup', 'school/addStandards', 'school/standardGradingScale', 'school/cohortInfo', 'school/showSchoolDepartment', 'school/language', 'school/schoolLabel', 'school/schoolPeriods',
            'student/counselorLog', 'student/counselorLogDetails', 'student/studentDocumentVerification',
            'staff/jobCertification', 'staff/staffJobCategory', 'staff/staffJobs', 'staff/credentials',
            'scheduling/individualStudentSchedule',
            'discipline/reportIncident', 'discipline/incidentApproval', 'discipline/manageIncident', 'discipline/studentDiscipline',
            'attendance/studentSectionAttendance', 'attendance/studentPeriodwiseAttendance', 'attendance/periodDailyAttendanceSummary', 'attendance/teacherMissingAttendance', 'attendance/viewStaffAttendance', 'attendance/examAttendanceAdmin',
            'assessment/assessmentAssets', 'assessment/assessmentAccomodation', 'assessment/assessmentGroup', 'assessment/assessmentInfo', 'assessment/assessmentInfoDetails', 'assessment/assignmentCategory', 'assessment/assignmentDetails', 'assessment/assignmentBank', 'assessment/assignmentGroup', 'assessment/assignmentItem', 'assessment/assessmentGrading', 'assessment/assessmentRubric', 'assessment/sectionGradingAdminView', 'assessment/standardGrading', 'assessment/progressGradeAdminView',
            'attendance/studentMissingAttendance', 'attendance/adminMissingAttendance', 'attendance/attendanceLogDetails'];
        return returnArray;
    };
    // Return list of APIs used for holding DT search filter
    returnFilterAPIs = () => {
        let returnArray = ['v1/studentcounselorlog/getstudentsalllogsbysession',
            'v1/schoolstandardgroup/getallschoolstandardgroup', 'v1/standard/getallstandard',
            'v1/missingstudentattendance/getmissingattendanceperiodsbyteacherid', 'v1/studentattendancelog/getstudentinfobyid',
            'v1/assessmentinfo/getassessmentinfobyassessmentgroup', 'v1/assessmentdetail/getallassessmentdetailsbyassessmentid', 'v1/assignment/getallbyassignmentcategoryid', 'v1/assignmentitemgroup/getallassignmentitemgroup', 'v1/assignmentitem/getallassignmentitemsbyassignmentgroupid'];
        return returnArray;
    };
    // Return list of Payloads used for holding DT search filter
    returnFilterPayloads = (payLoad = '') => {
        if (payLoad.includes('v1/missingstudentattendance/getmissingattendanceperiodsbyteacherid?teacherId=')) {
            let tempPayLoad = payLoad.split("&currentTime");
            if (tempPayLoad.length > 1)
                return tempPayLoad[0];
        }
        else
            return payLoad;
    }
}
export default CommonFuncs;
