import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';
const initialState = {
    scheduleLinkedCourseList: [],
    scheduleAllocatedCourseList: [],
    saveScheduleAllocatedCourse: false,
    scheduleCourseStudentList: [],
    scheduleStudentCourseList: [],
    scheduleSubjectsOfferedList: [],
    scheduleCourseSectionList: [],
    saveScheduleStudentMassDrop: false,
    scheduleTeacherList: [],
    scheduleCourseList: [],
    saveScheduleRequest: false,
    saveScheduleTeacherReassign: false,
    scheduleStudentCourseRequestList: [],
    saveScheduleStudentCourseRequest: false,
    saveScheduleTeacher: {},
    scheduleCoreAcademicCourseList: [],
    scheduleAdditionalCourseList: [],
    scheduleOptionalCourseList: [],
    scheduleSectionList: [],
    scheduleStudentList: [],
    saveScheduleStudent: false,
    scheduleCourseSectionAllocationByCourseSectionList: [],
    saveScheduleSectionAllocationProcess: false,
    scheduleSubjectList: [],
    scheduleCoursesList: [],
    saveScheduleCourseSection: false,
    deleteScheduleCourseSection: {},
    scheduleRequestSubjectList: [],
    scheduleRequestCourseList: [],
    scheduleRequestCourseSectionList: [],
    scheduleStudentCourseSectionList: [],
    saveScheduleCourse: false,
    saveScheduleProcess: {},
    scheduleResultList: [],
    scheduleUnScheduledCoursesList: [],
    scheduleUnScheduledRequestList: [],
    scheduleUnScheduleCourseSectionList: [],
    getScheduleProcess: {},
    saveScheduleAvailableCourseSectionAllocation: false,
    saveCourseRequest: false,
    saveScheduleWarningTeacher: false,
    schedulePeriodList: [],
    scheduleSchoolDaysList: [],
    getScheduleClassReport: [],
    scheduleSchoolDaysList: [],
    scheduleCourseList: [],
    scheduleCourseSectionList: [],
    schedulePeriodList: [],
    getFileUrl: [],
    
}
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
}
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const reducerSchedule = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'SCHEDULE_LIST': return handleSuccess(state, action, camelCase(`schedule_${action.title}_list`));
        case 'SCHEDULE_SAVE': return handleSuccess(state, action, camelCase(`save_schedule_${action.title}`));
        case 'SCHEDULE_GET': return handleSuccess(state, action, camelCase(`get_schedule_${action.title}`));
        case 'SCHEDULE_DELETE': return handleSuccess(state, action, camelCase(`delete_schedule_${action.title}`));
        case commonConfig.SCHEDULE_STORE_RESET: return handleSuccess(state, action, action.title);
        case 'HANDLE_SCHEDULE_FAIL': return handleFail(state);
        default: return state;
    }
}
export default reducerSchedule;
