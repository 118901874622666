import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';
const initialState = {
    getAssessmentStandardGradingTeacherView: {},
    saveAssessmentStandardGrading: {},
    assessmentList: [],
    assessmentGroupList: [],
    assessmentAccommodationList: [],
    saveAssessmentAsset: false,
    getAssessmentAsset: {},
    assessmentAssetList: [],
    saveAssessmentGroup: {},
    getAssessmentGroupById: {},
    saveAssessmentUpdateGroup: {},
    saveAssessmentAccommodation: {},
    getAssessmentAccommodation: {},
    assessmentInfoList: [],
    getcoursempstudentList: [],
    saveAssessmentInfo: {},
    getAssessmentInfoById: {},
    saveAssessmentUpdateInfo: {},
    assessmentTeacherList: [],
    assessmentDepartmentList: [],
    assessmentSubjectList: [],
    assessmentCourseList: [],
    assessmentCourseSectionList: [],
    assessmentSessionList: [],
    assessmentSessionExamList: [],
    assessmentMarkingPeriodList: [],
    saveAssessmentSession: {},
    assessmentAdministrationList: [],
    saveAssessmentAdministration: {},
    getAssessmentAdministration: {},
    assessmentDetailsList: [],
    saveAssessmentDetails: {},
    getAssessmentDetailsById: {},
    saveAssessmentUpdateDetails: {},
    getAssessmentSession: {},
    saveAssessmentRegistration: false,
    getAssessmentSessionExam: {},
    assessmentEndOfYearSummaryList: [],
    saveAssessmentSessionExam: {},
    assessmentEndOfYearSummaryStudentList: [],
    saveAssessmentEndOfYearSummary: false,
    assessmentLetterGradeList: [],
    saveAssessmentResult: false,
    saveAssessmentGenerateTranscript: false,
    assessmentStudentBasicInfoList: [],
    getAssessmentReportCard: {},
    saveAssessmentGenerateReportCard: false,
    assessmentCourseManagerDetailList: [],
    assessmentStudentReportCardList: [],
    getAssessmentReportCardDetails: {},
    saveAssessmentGenerateProgressReportCard: {},
    saveAssessmentGenerateReportCard: {},
    getAssessmentFileUrl: {},
    getAssessmentReportCardTemplate: {},
    assessmentResultCourseSectionList: [],
    assessmentPublishReportCardList: [],
    saveAssessmentPublishReportCard: false,
    assessmentUploadTemplateList: [],
    saveAssessmentTemplate: false,
    getAssessmentTemplate: {},
    assessmentAssignmentCategoryList: [],
    assessmentAssignmentCourseSectionList: [],
    getAssessmentAssignmentCategory: {},
    saveAssessmentAssignmentCategory: false,
    assessmentAssignmentList: [],
    saveAssessmentAssignment: false,
    getAssessmentAssignment: {},
    getAssessmentGradebookDetails: {},
    saveAssessmentGradebook: {},
    getAssessmentSectionGrades: {},
    saveAssessmentSectionGrades: false,
    assessmentSectionGradesCourseSectionList: [],
    assessmentSectionGradesSubjectList: [],
    assessmentSectionGradesCourseList: [],
    saveAssessmentEndOfYearPromotion: {},
    assessmentMassTranscriptList: [],
    deleteAssessment: false,
    assessmentTranscriptGradesList: [],
    assessmentScheduleMarkingPeriodList: [],
    getAssessmentCalculateRank: false,
    assessmentCalculateRankResultList: [],
    saveAssessmentProcessTranscript: false,
    assessmentStudentGpaResultList: [],
    assessmentStudentList: [],
    assessmentStudentTranscriptList: [],
    saveAssessmentStudentTranscript: {},
    assessmentMaintainRankList: [],
    assessmentCalculateGpaList: 0,
    assessmentCourseSectionByIdList: [],
    assessmentStudyMaterialList: [],
    saveAssessmentStudyMaterial: {},
    saveAssessmentActiveInactive: {},
    assessmentAssignmentBankList: [],
    assessmentAssignmentBankItemList: [],
    saveAssessmentAssignmentBankItem: false,
    getAssessmentAssignmentBankItem: {},
    assessmentAssignmentBankRubricList: [],
    getAssessmentAssignmentBank: {},
    saveAssessmentAssignmentBank: {},
    assessmentEventAssignmentMaterialList: [],
    assessmentAssignmentBankChildItemList: [],
    getAssessmentAssignmentBankChildItem: {},
    saveAssessmentAssignmentBankChildItem: false,
    assessmentAssignmentActivityList: [],
    getAssessmentAssignmentActivity: {},
    assessmentAssignmentBankByCourseSectionList: [],
    assessmentAssignmentItemList: [],
    assessmentAssignmentChildItemList: [],
    saveAssessmentAssignmentActivity: false,
    assessmentAssignmentGradingList: [],
    assessmentAssignmentTeacherGradingDetailsList: [],
    assessmentNewAssignmentList: [],
    assessmentCompletedAssignmentList: [],
    getAssessmentAssignmentItems: {},
    saveAssessmentAssignmentItems: false,
    getAssessmentAssignmentResults: {},
    saveAssessmentResumeAssignmentItems: false,
    assessmentAssignmentStudyMaterialList: [],
    assessmentCourseWiseGpaList: [],
    assessmentMarkingPeriodWithExamCodeList: [],
    assessmentHistoricalReportList: [],
    assessmentRunNameList: [],
    assessmentReportCardFormatList: [],
    saveAssessmentReportCardFormat: false,
    getAssessmentReportCardFormat: {},
    saveAssessmentTranscriptFormat: false,
    getAssessmentTranscriptFormat: {},
    assessmentTranscriptFormatList: [],
    getAssessmentGenerateTranscript: {},
    saveAssessmentAssignmentGroup: {},
    getAssessmentAssignmentGroup: {},
    assessmentAssignmentGroupItemList: [],
    assessmentMarkingPeriodList: [],
    //assessment rubric
    assessmentRubricList: [],
    saveAssessmentRubric: false,
    getAssessmentRubric: {},
    saveAssessmentCopyRubric: false,
    deleteAssessmentRubricCriteria: false,
    deleteAssessmentRubricScale: false,
    assessmentDownloadReportCardList: [],
    assessmentStandardGradingByMpList: [],
    saveAssessmentStandardGradingMp: {},
    saveAssessmentPublish: false,
    saveAssessmentPublishReport: {},
    assessmentAssignmentList:[],
    assessmentCourseSectionList:[],
    assessmentCourseAllList:[],
    saveAssessmentCopyAssignmentStructure:{},
    getAssessmentRunningAverageDetails:{}
}
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
}
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const reducerAssessment = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'ASSESSMENT_LIST': return handleSuccess(state, action, camelCase(`assessment_${action.title}_list`));
        case 'ASSESSMENT_SAVE': return handleSuccess(state, action, camelCase(`save_assessment_${action.title}`));
        case 'ASSESSMENT_GET': return handleSuccess(state, action, camelCase(`get_assessment_${action.title}`));
        case 'ASSESSMENT_DELETE': return handleSuccess(state, action, camelCase(`delete_assessment_${action.title}`));
        case commonConfig.ASSESSMENT_STORE_RESET: return handleSuccess(state, action, action.title);
        case 'HANDLE_ASSESSMENT_FAIL': return handleFail(state);
        default: return state;
    }
}
export default reducerAssessment;