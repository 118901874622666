export class commonScheduleConfig {
    static COMPONENT_SCHOOL = 'school';
    static COMPONENT_COURSE = 'course';
    static COMPONENT_SCHEDULE = 'schedule';
    static COMPONENT_CANNEDREPORT = 'cannedreport';
    static COMPONENT_STAFF = 'staff';
    static COMPONENT_STUDENT = 'student';
    static METHOD_TYPE_POST = 'POST';
    static METHOD_TYPE_GET = 'GET';
    //calendar
    static COMPONENT_SCHOOL_CALENDAR_SAVE = 'v1/Calendar/SaveCalendar';
    static COMPONENT_SCHOOL_CALENDAR_UPDATE = 'v1/Calendar/UpdateCalendar';
    static COMPONENT_SCHOOL_CALENDAR_DELETE = 'v1/Calendar/DeleteCalendar';
    static COMPONENT_SCHOOL_CALENDAR_GETALL = 'v1/Calendar/GetCalendar?sessionId=';
    static COMPONENT_SCHOOL_CALENDAR_GETBYID = 'v1/Calendar/{id}';
    //calendar event filter
    static COMPONENT_SCHOOL_CALENDAR_EVENT_DAY = 'v1/Calendar/GetCalendarEventsForDay';
    static COMPONENT_SCHOOL_CALENDAR_EVENT_WEEK = 'v1/Calendar/GetCalendarEventsForWeek';
    static COMPONENT_SCHOOL_CALENDAR_EVENT_MONTH = 'v1/Calendar/GetCalendarEventsForMonth';
    //course curriculum
    static COMPONENT_COURSE_GET_STUDENT_LIST = 'v1/coursecurriculum/getstudentlist?';
    static COMPONENT_COURSE_GRADE_LEVEL_GETALL = 'v1/coursecurriculum/getschoolgradelevel?schoolId=';
    static COMPONENT_STUDENT_COURSE_LIST_GETALL = 'v1/coursecurriculum/getstudentcourselist';
    static COMPONENT_SCHEDULE_SAVE_REQUEST_SCHEDULING = 'v1/coursecurriculum/saverequestforcoursescheduling';
    //Student mass drop
    static COMPONENT_GET_COURSE_BY_SUBJECT_ID = 'v1/courseinfo/getcoursesbysubjectid?subjectId=';
    static COMPONENT_GET_SUBJECT_OFFERED_BY_SCHOOL = 'v1/coursesubjectsoffered/getsubjectsofferedbyschool?schoolId=';
    static COMPONENT_GET_BY_COURSE_ID = 'v1/coursesection/getbycourseid?courseId=';
    static COMPONENT_GET_DROP_STUDENT_LIST = 'v1/schedulemassstudentdrop/getstudentlist?courseSectionId=';
    static COMPONENT_UPDATE_SCHEDULE_MASS_STUDENT_DROP = 'v1/schedulemassstudentdrop/updateschedulemassstudentdrop';
    //Approval Request
    static COMPONENT_STUDENT_PENDING_COURSE_REQUEST_GETALL = 'v1/studentpendingcourserequest/getpendingcourserequeststudentlist?';
    static COMPONENT_STUDENT_PENDING_COURSE_REQUEST_UPDATE = 'v1/studentpendingcourserequest/updatestudentpendingcourserequest';
    //teacher reassignment
    static COMPONENT_SCHEDULE_TEACHER_GETALL = 'v1/staffinfo/getallteacherfromstaffbyschool?schoolId=';
    static COMPONENT_SCHEDULE_TEACHER_GET_BY_ACTIVE_DAYS = 'v1/staffinfo/getallteachesrforschedule?schoolId=';
    static COMPONENT_SCHEDULE_COURSE_GET_BY_TEACHER_ID = 'v1/coursesectionperiodscheduleavailteacher/getcoursesectionperiodscheduleavailteacherbyteacher?teacherId=';
    static COMPONENT_SCHEDULE_RE_ASSIGN_TEACHER = 'v1/coursesectionperiodscheduleavailteacher/reassigncoursesectionperiodscheduleteacher';
    //Teacher schedule
    static COMPONENT_TEACHER_SCHEDULE_SAVE = 'v1/coursesectionperiodscheduleavailteacher/savecoursesectionperiodscheduleavailteacher';
    static COMPONENT_TEACHER_SCHEDULE_UPDATE = 'v1/coursesectionperiodscheduleavailteacher/updatecoursesectionperiodscheduleavailteacher';
    static COMPONENT_TEACHER_SCHEDULE_WARNING = 'v1/coursesectionperiodscheduleavailteacher/warncoursesectionperiodscheduleavailteacher';
    // allocate course and section
    static COMPONENT_AUTO_SECTION_ALLOCATION = 'v1/autosectionallocation/getcourselist?';
    static COMPONENT_ASSIGN_STUDENT_TO_SELECTED_COURSES = 'v1/autosectionallocation/TaskForAllocateCourseAndSection';
    static COMPONENT_DELETE_ASSIGN_TEACHER = 'v1/coursesectionperiodscheduleavailteacher/deleteassignedteacher';
    // student wisw allocation
    static COMPONENT_GET_STUDENT_LIST = 'v1/studenwiseallocation/getstudentlistbyfilter?';
    static COMPONENT_ALLOCTAE_STUDENT_WISE = 'v1/studenwiseallocation/savestudentwiseallocation';
    //section allocation process
    static COMPONENT_GET_SUBJECT_BY_GRADE_LEVEL = 'v1/sectionallocationprocess/getsubjectbygradelevel?gradeId=';
    static COMPONENT_GET_COURSE_BY_SUBJECT = 'v1/sectionallocationprocess/getcoursesbysubject?subjectId=';
    static COMPONENT_GET_COURSE_SECTION_BY_COURSE = 'v1/sectionallocationprocess/getcoursesectionsbycourse?courseId=';
    static COMPONENT_GET_COURSE_SECTION_ALLOCATION = 'v1/sectionallocationprocess/getcoursesectionallocationbycoursesection?';
    static COMPONENT_SAVE_SECTION_ALLOCATION_PROCESS = 'v1/sectionallocationprocess/savesectionallocationprocess'
    //mass student scheduling
    static COMPONENT_GET_STUDENT_LIST_BY_GRADELEVEL = 'v1/massstudentschedule/getstudentcourses?';
    static COMPONENT_GET_SUBJECT_LIST = 'v1/massstudentschedule/getsubjectsbygradeid?';
    static COMPONENT_GET_COURSE_LIST = 'v1/massstudentschedule/getcoursesbysubjectid?';
    static COMPONENT_GET_COURSE_SECTION_LIST = 'v1/massstudentschedule/getcoursesectionbycourseid?';
    static COMPONENT_GET_COURSE_LIST_BY_GRADE_ID = 'v1/massstudentschedule/getoptionaladditionalcourses?';
    static COMPONENT_SCHEDULE_STUDENT = 'v1/massstudentschedule/massstudentscheduleallocation';
    //individual student scheduling
    static COMPONENT_SCHEDULE_COURSE_SECTION_BY_STUDENT_ID = 'v1/studentschedule/getstudentcoursesectionschedule?';
    static COMPONENT_SAVE_STUDENT_COURSE_SECTION_DATA = 'v1/studentschedule/updatestudentcoursesectiondates';
    static COMPONENT_DELETE_STUDENT_COURSE_SECTION_DATA = 'v1/studentschedule/deletescheduledrecord';
    static COMPONENT_DELETE_STUDENT_COURSE_REQUEST_DATA = 'v1/studentschedule/deletecourserequestrecord';
    static COMPONENT_GET_STUDENT_COURSE_LIST = 'v1/studentschedule/getallstudentcourselist?';
    static COMPONENT_PROCESS_STUDENT_SCHEDULE = 'v1/studentschedule/processstudentschedule';
    static COMPONENT_GET_STUDENT_COURSE_BY_SUBJECT_ID = 'v1/studentschedule/getstudentcoursesbysubjectid?';
    static COMPONENT_GET_STUDENT_SCHEDULE_ALLOCATED_COURSE = 'v1/studentschedule/getstudentscheduledallocatedcourse?';
    static COMPONENT_GET_NON_ALLOCATED_STUDENT_COURSES = 'v1/studentschedule/getnonallocatedstudentcourses?';
    static COMPONENT_GET_AVAILABLE_COURSESECTION_FOR_ALLOCATION = 'v1/studentschedule/getavailablecoursesectionforscheduleallocation?';
    static COMPONENT_UPDATE_STUDENT_COURSE_REQUEST = 'v1/studentschedule/updatestudentcourserequest';
    // Scheduler
    static COMPONENT_SCHEDULE_STUDENT_PROCESS = 'v1/studentscheduler/processstudentscheduler';
    static COMPONENT_SCHEDULE_STUDENT_PROCESS_SUMMARY = 'v1/studentscheduler/getstudentschedulersummary?schoolSessionId=';
    static COMPONENT_GET_STUDENT_SCHEDULER_RESULT = "v1/studentschedulerresults/getstudentschedulerresultbysessionid?sessionId=";
    static COMPONENT_GET_STUDENT_SCHEDULER_RECORDS = "v1/studentscheduler/getstudentschedulerecordsview";
    static COMPONENT_GET_STUDENT_UNSCHEDULE_COURSES_RECORDS = "v1/studentscheduler/getunscheduledcoursesectiondetails";
    static COMPONENT_GET_STUDENT_UNSCHEDULE_COURSE_RESULT = "v1/studentschedulercoursesummary/getstudentschedulercoursebysessionId?sessionId=";
    static COMPONENT_GET_STUDENT_UNSCHEDULE_REQUEST_RESULT = "v1/studentschedulerstudentsummary/getstudentschedulerstudentsummarybysessionid?sessionId=";
    static OMPONENT_GET_STUDENT_SCHEDULER_SUBJECT = "v1/studentschedulercoursesummary/getscheduledsubjectslists?sessionId=";
    //Reports
    static COMPONENT_GET_STUDENT_SCHEDULE_REPORT_STUDENT_LIST = "v1/studentscheduledreport/getstudentscheduledinfo";
    static COMPONENT_DOWNLOAD_STUDENT_GRADE_LISTING_REPORT = "v1/studentgradelisting/downloadstudentgradelistingreport";
    static COMPONENT_DOWNLOAD_STUDENT_SCHEDULE_INFO = "v1/studentmasterscheduleinfo/downloadstudentscheduleinfo";
    // Individual Student Schedule Linking
    static COMPONENT_SCHEDULE_GETSTUDENTSCHEDULED_ALLOCATED_COURSEFORLINKING = "v1/studentschedule/getstudentscheduledallocatedcourseforlinking?studentId=";
    static COMPONENT_SCHEDULE_SAVESTUDENTSCHEDULED_ALLOCATED_COURSEFORLINKING = 'v1/coursesectionlinking/savecoursesectionlinking';
    static COMPONENT_SCHEDULE_LINKED_CONNECTIONS = "v1/coursesectionlinking/getalllinkedcoursesectionsbyscsid?";
    static COMPONENT_CLASSROASTER_REPORT_GETCOURSE_SESSIONID = "v1/classroasterreport/getcoursebysessionid?sessionId=";
    static COMPONENT_CLASSROASTER_REPORT_GETCOURSE_SECTIONID_COURSE = "v1/classroasterreport/getcoursesectionbysessionidcourseid?"
    static COMPONENT_CLASSROASTER_REPORT_GETPERIOD_COURSESECTIONID = "v1/classroasterreport/getperiodbycoursesectionid?courseSectionId="
    static COMPONENT_CLASSROASTER_REPORT_GETSCHOOL_WEEKDAYS = "v1/classroasterreport/getschoolweekdays?"
    static COMPONENT_DOWNLOAD_CLASSROASTER_REPORT = "v1/classroasterreport/downloadclassroasterreport"
    static COMPONENT_CLASSROASTER_GET_TEACHERLIST_SESSION_SCHOOLID = "v1​/classroasterreport​/getteacherlistbysessionidschoolid"

    //classRoaster 
    static COMPONENT_GET_COURSE_BY_SESSION_ID = "v1/classroasterreport/getcoursebysessionid?";
    static COMPONENT_GET_COURSE_SECTION_BY_COURSE_ID = "v1/classroasterreport/getcoursesectionbysessionidcourseid?";
    static COMPONENT_GET_PERIOD_BY_COURSE_SECTION_ID = "v1/classroasterreport/getperiodbycoursesectionid?";
    static COMPONENT_CLASSROASTER_REPORT_GETSCHOOL_WEEKDAYS = "v1/classroasterreport/getschoolweekdays?";
    static COMPONENT_DOWNLOAD_CLASS_ROASTER_REPORT = "v1/classroasterreport/downloadclassroasterreport";

    static COMPONENT_VALIDATE_EXTISTING_TEACHER = "v1/coursesectionperiodscheduleavailteacher/validateexistingteacherdependency?teacherId="
}
export default commonScheduleConfig;
