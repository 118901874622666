export class commonSchoolConfig {
  static COMPONENT_SCHOOL = 'school';
  static COMPONENT_SCHEDULE = 'schedule';
  static METHOD_TYPE_POST = 'POST';
  static METHOD_TYPE_GET = 'GET';
  static COMPONENT_RESOURCE = 'resource';
  static COMPONENT_COURSE = 'course';
  static COMPONENT_CONTROLPANEL = 'controlpanel';
  //timezone
  static COMPONENT_SCHOOL_CONFIGURE_SCHOOL_TIMEZONE = 'v1/generalinfo/configureschooltimezone';
  static COMPONENT_SCHOOL_GET_TIMEZONE_SETUP_DETAILS = 'v1/generalinfo/gettimezonesetupdetails?schoolId=';
  //Standards
  static COMPONENT_DATA_STANDARDS = 'v1/standard/getallstandard?StandardGroupId=';
  static COMPONENT_EDIT_STANDARDS = 'v1/standard/getstandardbyid?id=';
  static COMPONENT_DELETE_STANDARDS = 'v1/standard/deletestandard';
  static COMPONENT_DATA_IMPORTED_STANDARDS = 'v1/markingperiod/getreportcardmarkingperiodlists?sessionId=';
  static COMPONENT_UPDATE_STANDARDS = 'v1/standard/updatestandard';
  static COMPONENT_SAVE_STANDARDS = 'v1/standard/savestandard';
  static COMPONENT_DELETE_STANDARDS_MARKING_PERIOD = 'v1/standard/deletemappingperiodgoalsbyid';
  static COMPONENT_DATA_STANDARDS_SUB_GROUP = 'v1/schoolstandardgroup/getallschoolstandardgroup?';
  static COMPONENT_GET_MFA_PERMISSION_BY_SCHOOL_ID = 'v1/multifactorauthenticationpermission/getpermissionbyschoolid?schoolId=';
  //child markign period
  static COMPONENT_MARKINGPERIOD_GETALLCHILDMARKINGPERIODS_BY_COURSESECTION = 'v1/markingperiod/getallchildmarkingbycoursesection?courseSectionId=';
  //school info
  static COMPONENT_SCHOOL_CURRENTSCHOOLINFODETAILSBYID = 'v1/generalinfo/getschooldetailinfo?schoolId=';
  // schedule marking period list
  static COMPONENT_SCHOOL_GET_ALL_SCHEDULE_MARKING_PERIOD_BY_SESSION_ID = 'v1/markingperiod/getallschedulemarkingperiodbysessionid?sessionId=';
  //Standard Grade Info
  static COMPONENT_SAVE_STANDARD_SET = 'v1/schoolstandardset/saveschoolstandardset';
  static COMPONENT_EDIT_STANDARD_SET = 'v1/schoolstandardset/getschoolstandardsetbyid?id=';
  static COMPONENT_DELETE_STANDARD_SET = 'v1/schoolstandardset/deleteschoolstandardset';
  static COMPONENT_DATA_STANDARD_SET = 'v1/schoolstandardset/getallschoolstandardset';
  static COMPONENT_UPDATE_STANDARD_SET = 'v1/schoolstandardset/updateschoolstandardset';
  //GeneralInfo
  static COMPONENT_SCHOOL_GENERALINFO_SAVE = 'v1/GeneralInfo/SaveSchoolInfo';
  static COMPONENT_SCHOOL_GENERALINFO_LOGO_SAVE = 'v1/GeneralInfo/SaveSchoolLogo';
  static COMPONENT_SCHOOL_GENERALINFO_UPDATE = 'v1/GeneralInfo/UpdateSchoolInfo';
  static COMPONENT_SCHOOL_GENERALINFO_DELETE = 'v1/GeneralInfo/DeleteSchoolInfo';
  static COMPONENT_SCHOOL_GENERALINFO_GETALL = 'v1/GeneralInfo/GetAllSchoolForaUser';
  static COMPONENT_SCHOOL_GENERALINFO_GETALL_FOR_USER = 'v1/GeneralInfo/GetAllSchoolForUserById';
  static COMPONENT_SCHOOL_GENERALINFO_GETBYID = 'v1/GeneralInfo/GetSchoolInfoById?id=';
  static COMPONENT_SCHOOL_GENERAL_INFO_STUDENT_STAFF_COUNT = 'v1/GeneralInfo/GetStudentAndStaffCount?schoolId=';
  static COMPONENT_SCHOOL_TIMEZONE_LIST = 'v1/GeneralInfo/GetAllTimeZone';
  static COMPONENT_GET_SCHOOL_TIMEZONE_NAME = 'v1/GeneralInfo/GetSchoolAvailableTimeZone?schoolId=';
  static COMPONENT_SCHOOL_GETALL_BY_PAGINATION = 'v1/generalinfo/getallschoolbypagination';
  static COMPONENT_GET_SCHOOL_COUNTRY_CODE = 'v1/generalinfo/getschoolcountrycode?schoolId=';
  static COMPONENT_PROCESS_ROLL_OVER = 'v1/rollover/processrollover';
  static COMPONENT_GET_SCHOOL_STATE_CODE = 'v1/generalinfo/getschoolstatecode?schoolId=';
  static COMPONENT_GET_SCHOOL_LIST_BY_DISTRICTID = 'v1/generalinfo/getschoolinfobydistrictid?DistrictId=';
  static COMPONENT_SCHOOL_GETALL_BY_STATE = 'v1/dynamicuicomponent/getschoolinfobystate?stateCode=';
  //AddressInfo
  static COMPONENT_SCHOOL_ADDRESSINFO_SAVE = 'v1/SchoolAddressInfo/SaveSchoolAddressInfo';
  static COMPONENT_SCHOOL_ADDRESSINFO_UPDATE = 'v1/SchoolAddressInfo/UpdateSchoolAddressInfo';
  static COMPONENT_SCHOOL_ADDRESSINFO_DELETE = 'v1/SchoolAddressInfo/DeleteSchoolAddressInfo';
  static COMPONENT_SCHOOL_ADDRESSINFO_DELETEBYID = 'v1/SchoolAddressInfo/DeleteSchoolAddressInfoById';
  static COMPONENT_SCHOOL_ADDRESSINFO_GETALL = 'v1/SchoolAddressInfo/GetAllAddressInfoOfSchool';
  static COMPONENT_SCHOOL_ADDRESSINFO_GETBYID = 'v1/SchoolAddressInfo/GetSchoolAddressInfoById';
  //Accredations
  static COMPONENT_SCHOOL_ACCREDATIONS_SAVE = 'v1/SchoolAccreditation/SaveSchoolAccreditation';
  static COMPONENT_SCHOOL_ACCREDATIONS_UPDATE = 'v1/SchoolAccreditation/UpdateSchoolAccreditation';
  static COMPONENT_SCHOOL_ACCREDATIONS_DELETE = 'v1/SchoolAccreditation/DeleteSchoolAccreditation';
  static COMPONENT_SCHOOL_ACCREDATIONS_GETALL = 'v1/SchoolAccreditation/GetSchoolAccreditation';
  static COMPONENT_SCHOOL_ACCREDATIONS_GETBYID = 'v1/SchoolAccreditation/GetSchoolAccreditationById';
  //AdministrationInfo
  static COMPONENT_SCHOOL_ADMINISTRATION_SAVE = 'v1/SchoolAdminAttr/Saveschooladminattr';
  static COMPONENT_SCHOOL_ADMINISTRATION_UPDATE = 'v1/SchoolAdminAttr/Updateschooladminattr';
  static COMPONENT_SCHOOL_ADMINISTRATION_DELETE = 'v1/SchoolAdminAttr/Deleteschooladminattr';
  static COMPONENT_SCHOOL_ADMINISTRATION_GETALL = 'v1/SchoolAdminAttr/GetSchoolAdministration';
  static COMPONENT_SCHOOL_ADMINISTRATION_GETBYID = 'v1/SchoolAdminAttr/GetschoolAdminAttrById';
  //DesignitarInfo
  static COMPONENT_SCHOOL_DESIGNATOR_SAVE = 'v1/SchoolDesignator/SaveSchoolDesignator';
  static COMPONENT_SCHOOL_DESIGNATOR_UPDATE = 'v1/SchoolDesignator/UpdateSchoolDesignator';
  static COMPONENT_SCHOOL_DESIGNATOR_DELETE = 'v1/SchoolDesignator/DeleteSchoolDesignator';
  static COMPONENT_SCHOOL_DESIGNATOR_GETALL = 'v1/SchoolDesignator/GetSchoolDesignator';
  static COMPONENT_SCHOOL_ALL_DESIGNATOR = 'v1/schooldesignator/getallschooldesignator';
  static COMPONENT_SCHOOL_DESIGNATOR_GETBYID = 'v1/SchoolDesignator/GetSchoolDesignatorById';
  static COMPONENT_SCHOOL_DESIGNATOR_GETBYSCHOOLID = 'v1/SchoolDesignator/getSchoolDesignatorBySchoolId';
  //IdentidierInfo
  static COMPONENT_SCHOOL_IDENTIFIER_SAVE = 'v1/SchoolIdentifierCode/SaveSchoolIdentifierCode';
  static COMPONENT_SCHOOL_IDENTIFIER_UPDATE = 'v1/SchoolIdentifierCode/UpdateSchoolIdentifierCode';
  static COMPONENT_SCHOOL_IDENTIFIER_DELETE = 'v1/SchoolIdentifierCode/DeleteSchoolIdentifierCode';
  static COMPONENT_SCHOOL_IDENTIFIER_DELETE_BYID = 'v1/SchoolIdentifierCode/DeleteSchoolIdentifierCode';
  static COMPONENT_SCHOOL_IDENTIFIER_GETALL = 'v1/SchoolIdentifierCode/GetSchoolIdentifierCodeBySchoolId';
  static COMPONENT_SCHOOL_IDENTIFIER_GETBYID = 'v1/SchoolIdentifierCode/GetSchoolIdentifierCodeById';
  //SchoolPhoneEmailInfo
  static COMPONENT_SCHOOL_PHONE_SAVE = 'v1/SchoolPhoneEmail/SaveSchoolPhone';
  static COMPONENT_SCHOOL_PHONE_UPDATE = 'v1/SchoolPhoneEmail/UpdateSchoolPhone';
  static COMPONENT_SCHOOL_PHONE_DELETE = 'v1/SchoolPhoneEmail/DeleteSchoolPhone';
  static COMPONENT_SCHOOL_PHONE_GETALL = 'v1/SchoolPhoneEmail/GetSchoolPhoneBySchoolId';
  static COMPONENT_SCHOOL_PHONE_GETBYID = 'v1/SchoolPhoneEmail/GetSchoolPhoneById';
  static COMPONENT_SCHOOL_PHONE_GETBYSCHOOLID = 'v1/SchoolPhoneEmail/GetSchoolPhoneBySchoolId';
  static COMPONENT_SCHOOL_EMAIL_SAVE = 'v1/SchoolPhoneEmail/SaveSchoolEmail';
  static COMPONENT_SCHOOL_EMAIL_UPDATE = 'v1/SchoolPhoneEmail/UpdateSchoolEmail';
  static COMPONENT_SCHOOL_EMAIL_DELETE = 'v1/SchoolPhoneEmail/DeleteSchoolEmail';
  static COMPONENT_SCHOOL_EMAIL_GETALL = 'v1/SchoolPhoneEmail/GetSchoolEmailBySchoolId';
  static COMPONENT_SCHOOL_EMAIL_GETBYID = 'v1/SchoolPhoneEmail/GetSchoolEmailById';
  static COMPONENT_SCHOOL_EMAIL_GETBYSCHOOLID = 'v1/SchoolPhoneEmail/GetSchoolEmailBySchoolId';
  //MarkingPeriods
  static COMPONENT_SCHOOL_MARKING_PERIOD_GET_REPORTCARDCYCLE = 'v1/markingperiod/getreportcardcyclegradempslistbysession';
  static COMPONENT_SCHOOL_GET_ALL_REPORTCARDCYCLE_MP = 'v1/markingperiod/getreportcardcyclegradempslist';
  static COMPONENT_SCHOOL_MARKING_PERIOD_SAVE = 'v1/MarkingPeriod/SaveMarkingPeriod';
  static COMPONENT_SCHOOL_MARKING_PERIOD_GETBYID = 'v1/MarkingPeriod/GetMarkingPeriodById';
  static COMPONENT_SCHOOL_MARKING_PERIOD_UPDATE = 'v1/MarkingPeriod/UpdateMarkingPeriod';
  static COMPONENT_SCHOOL_MARKING_PERIOD_DELETE = 'v1/MarkingPeriod/DeleteMarkingPeriod';
  static COMPONENT_SCHOOL_MARKING_PERIOD_GETALLBYSESSIONID = 'v1/Markingperiod/GetAllMarkingPeriodBySessionId';
  static COMPONENT_SCHOOL_GET_PROGRESS_REPORTACARD_MARKING_PERIOD = 'v1/markingperiod/getprogressreportcardmarkingperiods';
  static COMPONENT_SCHOOL_MARKING_PERIOD_GET_CHILD_LIST = 'v1/markingperiod/getchildmarkingperiodbyparentmpid?parentMPId=';
  static COMPONENT_SCHOOL_MARKING_PERIOD_FORSESSIONID = 'v1/Markingperiod/GetMarkingPeriodsListForSession';
  static COMPONENT_SCHOOL_MARKING_PERIOD_GETALLBYSCHOOLID = 'v1/markingperiod/getmarkingperiodsbyschoolid?schoolId=';
  static COMPONENT_SCHOOL_MARKING_PERIOD_GRADEBOOK_LIST = 'v1/markingperiod/getgradecyclemarkingperiodbysession?schoolSessionId=';
  static COMPONENT_SCHOOL_MARKING_PERIOD_GET_GRADEBOOK_PROGRESS_MP = 'v1/markingperiod/checkprogresscyclemarkingperiodbyparent?parentMPId=';
  static COMPONENT_SCHOOL_GRADED_MARKING_PERIOD_GETALLBYSCHOOLID = 'v1/markingperiod/getgradedmarkingperiodsbyschoolid?';
  static COMPONENT_SCHOOL_GRADED_MARKING_PERIOD_GETALLBY_SESSIONID = 'v1/markingperiod/getgradedmarkingperiodsbysessionid?';
  static COMPONENT_SCHOOL_MARKING_PERIOD_FOR_WITHDRAWALFORM = 'v1/Markingperiod/GetMarkingPeriodsListForWithdrawalForm';
  static MARKING_PERIOD_REPORTCARD_BY_SCHOOLID = 'v1/markingperiod/getreportcardmarkingperiods?schoolSessionId=';
  static MARKING_PERIOD_GET_GRADELISTINGS_MARKINGPERIOD = 'v1/markingperiod/getgradelistingsmarkingperiods?schoolSessionId=';
  //Periods
  static COMPONENT_SCHOOL_PERIOD_SAVE = 'v1/SchoolPeriod/SaveSchoolPeriod';
  static COMPONENT_SCHOOL_PERIOD_GETALL = 'v1/SchoolPeriod/GetAllPeriodBySchoolSessionId';
  static COMPONENT_SCHOOL_PERIOD_GETBYID = 'v1/SchoolPeriod/GetSchoolPeriodById';
  static COMPONENT_SCHOOL_PERIOD_UPDATE = 'v1/SchoolPeriod/UpdateSchoolPeriod';
  static COMPONENT_SCHOOL_PERIOD_DELETE = 'v1/SchoolPeriod/DeleteSchoolPeriod';
  static COMPONENT_SCHOOL_PERIOD_BY_ATTENDANCE_GETALL = 'v1/schoolperiod/getallperiodbyallowattendance?schoolSessionId=';
  static COMPONENT_SCHOOL_PERIOD_BLOCK_MAPPING_GETALL = 'v1/periodblockmapping/getallperiodblockmapping?periodId=';
  static COMPONENT_SCHOOL_UPDATE_PERIOD_BLOCK_MAPPING = 'v1/periodblockmapping/updateperiodblockmapping';
  //calendar
  static COMPONENT_SCHOOL_CALENDAR_SAVE = 'v1/Calendar/SaveCalendar';
  static COMPONENT_SCHOOL_CALENDAR_UPDATE = 'v1/Calendar/UpdateCalendar';
  static COMPONENT_SCHOOL_CALENDAR_DELETE = 'v1/Calendar/DeleteCalendar';
  static COMPONENT_SCHOOL_CALENDAR_GETALL = 'v1/Calendar/GetCalendar?sessionId=';
  static COMPONENT_SCHOOL_CALENDAR_GETBYID = 'v1/Calendar/GetCalendarById?id=';
  static COMPONENT_SCHOOL_CALENDAR_GETDEFAULT = 'v1/Calendar/GetDefaultCalendar?id=';
  static COMPONENT_SCHOOL_CALENDAR_GETBYMARKING_PERIOD_ID = 'v1/calendar/GetMarkingPeriodCalendarLookup?markingPeriodId=';
  static COMPONENT_SCHOOL_GET_CALENDAR_EVENT_TYPE = 'v1/calendarevent/getallcalendareventbycalendareventtype?lookupCalenderEventType=';
  static COMPONENT_SCHOOL_CALENDAR_BLOCK_SAVE = 'v1/blockdates/createblockdates';
  static COMPONENT_SCHOOL_CALENDAR_GET_ALL_BLOCK = 'v1/blockdates/getallblockdatesaspersessionid?session_Id=';
  static COMPONENT_SCHOOL_CALENDAR_BLOCK_UPDATE = 'v1/blockdates/updateblockdates';
  static COMPONENT_SCHOOL_CALENDAR_VALIDATE_BLOCK_DATES = 'v1/blockdates/getvalidateblockdates';
  static COMPONENT_SCHOOL_CALENDAR_GET_BLOCK_DETAILS_BY_ID = 'v1/blockdates/getblockdatedetailbyid?Id=';
  static COMPONENT_SCHOOL_CALENDAR_GET_CALENDAR_DATES_BYID = 'v1/blockdates/getcalendardatesbyid?id=';

  //calendar event filter
  static COMPONENT_SCHOOL_CALENDAR_EVENT_DAY = 'v1/calendareventfilter/getcalendareventsforday';
  static COMPONENT_SCHOOL_CALENDAR_EVENT_WEEK = 'v1/Calendar/GetCalendarEventsForWeek';
  static COMPONENT_SCHOOL_CALENDAR_EVENT_MONTH = 'v1/calendareventfilter/getcalendareventsformonth';
  //event
  static COMPONENT_SCHOOL_EVENT_GETALL = 'v1/CalendarEvent/GetCalendarEvent?schoolRoleId=';
  static COMPONENT_SCHOOL_EVENT_GET_BY_ID = 'v1/CalendarEvent/GetCalendarEventById?id=';
  static COMPONENT_SCHOOL_CALENDAR_EVENT_UPDATE = 'v1/CalendarEvent/UpdateCalendarEvent';
  static COMPONENT_SCHOOL_CALENDAR_EVENT_SAVE = 'v1/CalendarEvent/SaveCalendarEvent';
  static COMPONENT_SCHOOL_CALENDAR_EVENT_DELETE = 'v1/CalendarEvent/DeleteCalendarEvent';
  static COMPONENT_SCHOOL_GETALL_SCHDULE_CALENDAR_EVENT = 'v1/calendarevent/getcalendareventuserlist?schoolRoleId=';
  //crisis
  static COMPONENT_SCHOOL_CALENDAR_CRISIS_GETALL = 'v1/SchoolCalendarCrisis/GetSchoolCalendarCrisis';
  static COMPONENT_SCHOOL_CRISIS_GET_BY_ID = 'v1/SchoolCalendarCrisis/GetSchoolCalendarCrisisById';
  static COMPONENT_SCHOOL_CALENDAR_CRISIS_UPDATE = 'v1/SchoolCalendarCrisis/UpdateSchoolCalendarCrisis';
  static COMPONENT_SCHOOL_CALENDAR_CRISIS_SAVE = 'v1/SchoolCalendarCrisis/SaveSchoolCalendarCrisis';
  static COMPONENT_SCHOOL_CALENDAR_CRISIS_DELETE = 'v1/SchoolCalendarCrisis/DeleteSchoolCalendarCrisis';
  static COMPONENT_SCHOOL_CALENDAR_GET_ALL_SESSION = 'v1/SchoolCalendarCrisis/GetAllSchoolSession';
  //GradeLevel
  static COMPONENT_SCHOOL_GRADELEVEL_GETALL = 'v1/schoolgradelevel/getallschoolgradelevel?';
  static COMPONENT_SCHOOL_GRADEBYTEACHERID = 'v1/schoolgradelevel/getgradebyteacherid?';
  static COMPONENT_SCHOOL_GRADELEVEL_DELETE = 'v1/schoolgradelevel/deleteschoolgradelevel';
  static COMPONENT_SCHOOL_GRADELEVEL_SAVE = 'v1/schoolgradelevel/saveschoolgradelevel';
  static COMPONENT_SCHOOL_GRADELEVEL_GETBYID = 'v1/schoolgradelevel/getschoolgradelevelbyid';
  static COMPONENT_SCHOOL_GRADELEVEL_UPDATE = 'v1/schoolgradelevel/updateschoolgradelevel';
  static COMPONENT_SCHOOL_GRADELEVEL_ASSOCIATED_DELETE = 'v1/schoolgradelevel/DeleteAssociatedSection';
  static COMPONENT_SCHOOL_GRADELEVEL_FOR_REPORT_CARD = 'v1/SchoolGradeLevel/GetSchoolGradeLevelForReportCard?schoolId=';
  static COMPONENT_SCHOOL_GRADELEVEL_HIGHER_GRADELEVEL = 'v1/schoolgradelevel/gethighergradelevel?';
  static COMPONENT_SCHOOL_GET_HIGHER_GRADE_LEVEL_ENDOFYEAR_STATUS = 'v1/schoolgradelevel/gethighergradelevelforendofyearstatus?';
  //Section
  static COMPONENT_SCHOOL_SECTION_GETALL = 'v1/schoolsection/GetSchoolAllSections?';
  static COMPONENT_SCHOOL_SECTION_DELETE = 'v1/schoolsection/DeleteSchoolSection';
  static COMPONENT_SCHOOL_SECTION_SAVE = 'v1/schoolsection/SaveSchoolSection';
  static COMPONENT_SCHOOL_SECTION_GETBYID = 'v1/schoolsection/GetSchoolSectionById?id=';
  static COMPONENT_SCHOOL_SECTION_UPDATE = 'v1/schoolsection/UpdateSchoolSection';
  static COMPONENT_SCHOOL_SECTION_GETBY_GRADELEVELID = 'v1/schoolsection/getschoolsectionbygradelevelid?grdeLevelIds=';
  //Room
  static COMPONENT_SCHOOL_ROOM_GETALL = 'v1/classroom/GetSchoolPremisesClassRoomList';
  static COMPONENT_SCHOOL_VIRTUAL_ROOM_GETALL = 'v1/classroom/getvirtualroomdetailsbysession?sessionId=';
  static COMPONENT_SCHOOL_ROOM_SAVE = 'v1/classroom/SaveClassRoom';
  static COMPONENT_SCHOOL_ROOM_DELETE = 'v1/classroom/DeleteClassRoom';
  static COMPONENT_SCHOOL_ROOM_GETBYID = 'v1/classroom/GetClassRoomById';
  static COMPONENT_SCHOOL_ROOM_UPDATE = 'v1/classroom/UpdateClassRoom';
  //for rooms to be populated on room dropdown based on schoo premises Id
  static COMPONENT_SCHOOL_ROOM_PERISESBYID = 'v1/classroom/getclassroombyschoolpremiseid?id=';
  //Role
  static COMPONENT_SCHOOL_ROLE_GETALL = 'v1/SchoolRole/GetAllSchoolRole';
  static COMPONENT_SCHOOL_ROLE_SAVE = 'v1/SchoolRole/SaveSchoolRole';
  static COMPONENT_SCHOOL_ROLE_CLONE = 'v1/SchoolRole/CloneSchoolRole';
  static COMPONENT_SCHOOL_ROLE_DELETE = 'v1/SchoolRole/DeleteSchoolRole';
  static COMPONENT_SCHOOL_ROLE_GETBYID = 'v1/SchoolRole/GetSchoolRoleById';
  static COMPONENT_SCHOOL_ROLE_UPDATE = 'v1/SchoolRole/UpdateSchoolRole';
  static COMPONENT_SCHOOL_ROLE_GETALL_BY_SCHOOL = 'v1/SchoolRole/GetAllBySchoolId?';
  static COMPONENT_SCHOOL_ROLE_GETALL_BY_SCHOOL_FOR_STAFF_ROLES = 'v1/SchoolRole/GetAllStaffJobRoles';
  static COMPONENT_SCHOOL_ROLE_GETALL_BY_SCHOOL_AND_USER = 'v1/schoolrole/getallrolebyuser';
  //Role Permission
  static COMPONENT_SCHOOL_ROLE_GETALL_PERMISSION = 'v1/schoolrolepermissionset/GetAllRolePermissionSetByRoleModule';
  static COMPONENT_SCHOOL_ROLE_UPDATE_PERMISSION = 'v1/schoolrolepermissionset/UpdateRolePermissionSet';
  static COMPONENT_SCHOOL_GET_NON_EDITABLE_TEACHER_ROLE_PERMISSION = 'v1/schoolrolepermissionset/getnoneditablepermissionsetbyrole';

  //School Field
  static COMPONENT_SCHOOL_FIELD_GETALLBYGROUPID = 'v1/customfieldgroup/GetCustomFieldGroupById';
  static COMPONENT_SCHOOL_FIELD_GETALLBYGROUPINFO = 'v1/customfieldgroup/GetCustomFieldGroupBySchoolIdAndMenuCode';
  static COMPONENT_SCHOOL_FIELD_SAVE = 'v1/customfield/SaveCustomField';
  static COMPONENT_SCHOOL_FIELDGROUP_SAVE = 'v1/customfieldgroup/SaveCustomFieldGroup';
  static COMPONENT_SCHOOL_FIELD_GETBYID = 'v1/customfield/GetCustomFieldById';
  static COMPONENT_SCHOOL_FIELD_UPDATE = 'v1/customfield/UpdateCustomField';
  static COMPONENT_SCHOOL_FIELD_GROUP_UPDATE = 'v1/customfieldgroup/UpdateCustomFieldGroup';
  static COMPONENT_SCHOOL_FIELD_DELETE = 'v1/customfield/DeleteCustomField/{id}';
  static COMPONENT_SCHOOL_FIELDGROUP_DELETE = 'v1/customfieldgroup/deletecustomfieldgroup';
  //Session
  static COMPONENT_SCHOOL_SESSION_GETALL = 'v1/schoolsession/GetSessionForSchool';
  static COMPONENT_SCHOOL_SESSION_GETALL_ACTIVE_INACTIVE = 'v1/schoolsession/GetActiveInactiveSessionForSchool';
  static COMPONENT_SCHOOL_SESSION_SAVE = 'v1/schoolsession/saveschoolsession';
  static COMPONENT_SCHOOL_SESSION_GETBYID = 'v1/schoolsession/GetSchoolSessionById';
  static COMPONENT_SCHOOL_SESSION_UPDATE = 'v1/schoolsession/updateschoolsession';
  static COMPONENT_SCHOOL_SESSION_DELETE = 'v1/schoolsession/DeleteSchoolSession';
  static COMPONENT_SCHOOL_SESSION_GET_FOR_SCHOOL = 'v1/schoolsession/GetSessionForSchool';
  static COMPONENT_SCHOOL_SESSION_GET_FOR_USER = 'v1/schoolsession/GetSessionForUser';
  static COMPONENT_ALL_SCHOOL_SESSION_GET_FOR_USER = 'v1/schoolsession/GetAllSessionForUser';
  static COMPONENT_SCHOOL_SESSION_GETALL_FOR_USER = 'v1/schoolsession/getallsessionforuser';
  static COMPONENT_SCHOOL_SESSION_NEXTYEAR_GETALL = 'v1/schoolsession/getnextyearsessionforschool?schoolId=';
  static COMPONENT_SCHOOL_SESSION_YEAR_RANGE = 'v1/schoolsession/getschoolsessionyear?schoolId=';
  static COMPONENT_SCHOOL_GET_IS_DEFAULT_SESSION = 'v1/schoolsession/getdefaultsessionotherthancurrent?schoolId=';
  //Premises
  static COMPONENT_SCHOOL_PREMISES_GETALL = 'v1/SchoolPremises/GetSchoolAllPremisesList';
  static COMPONENT_SCHOOL_PREMISES_GETBYID = 'v1/SchoolPremises/GetSchoolPremisesById';
  static COMPONENT_SCHOOL_PREMISES_SAVE = 'v1/SchoolPremises/SaveSchoolPremises';
  static COMPONENT_SCHOOL_PREMISES_UPDATE = 'v1/SchoolPremises/UpdateSchoolPremises';
  static COMPONENT_SCHOOL_PREMISES_DELETE = 'v1/SchoolPremises/DeleteSchoolPremises';
  static COMPONENT_SCHOOL_PREMISES_PHONE_DELETE = 'v1/schooladdressinfo/deleteschoolpremisephone';
  //Activity Groups
  static COMPONENT_SCHOOL_ACTIVITY_GROUPS_GETALL = 'v1/SchoolActivityGroup/GetAllSchoolActivityGroup';
  static COMPONENT_ACTIVITY_GROUP_GETBYID = 'v1/SchoolActivityGroup/GetSchoolActivityGroupById';
  static COMPONENT_SCHOOL_ACTIVITY_GROUPS_SAVE = 'v1/SchoolActivityGroup/SaveSchoolActivityGroup';
  static COMPONENT_SCHOOL_ACTIVITY_GROUPS_UPDATE = 'v1/SchoolActivityGroup/UpdateSchoolActivityGroup';
  static COMPONENT_ACTIVITY_GROUP_DELETE = 'v1/SchoolActivityGroup/DeleteSchoolActivityGroup';
  static COMPONENT_STAFF_DELETE = 'v1/SchoolActivityGroup/deletestaff';
  //Department
  static COMPONENT_GET_SYSTEM_DEPARTMENT_LIST = 'v1/manageuser/getdepartmentbyschool?';
  static COMPONENT_DEPARTMENT_GETALL = 'v1/SchoolDepartment/GetSchoolAllDepartments';
  static COMPONENT_DEPARTMENT_SAVE = 'v1/SchoolDepartment/SaveSchoolDepartment';
  static COMPONENT_DEPARTMENT_GETBYID = 'v1/SchoolDepartment/GetSchoolDepartmentById';
  static COMPONENT_DEPARTMENT_UPDATE = 'v1/SchoolDepartment/UpdateSchoolDepartment';
  static COMPONENT_DEPARTMENT_DELETE = 'v1/SchoolDepartment/DeleteSchoolDepartment';
  //Subject
  static COMPONENT_GET_ALL_SUBJECT = 'v1/coursesubjectsoffered/getallcoursesubjectsoffered?';
  //Course
  static COMPONENT_GET_ALL_COURSE_BY_SUBJECT_IDS = 'v1/courseinfo/getcoursesbysubjectids?SubjectIds=';
  //CourseSection
  static COMPONENT_GET_ALL_COURSE_SECTION_BY_COURSE_IDS = 'v1/courseinfo/getcoursesectionbycourseids?CourseIds=';
  //Grade
  static COMPONENT_GET_SYSTEM_GRADE_LIST = 'v1/manageuser/getschoolgradelevel?';
  //Holiday List
  static COMPONENT_SCHOOL_HOLIDAY_LIST_GETALL = 'v1/HolidayList/GetHolidayListBySessionId';
  static COMPONENT_SCHOOL_HOLIDAY_LIST_SAVE = 'v1/HolidayList/SaveHolidayList';
  static COMPONENT_SCHOOL_HOLIDAY_LIST_DELETE = 'v1/HolidayList/DeleteHolidayList';
  static COMPONENT_SCHOOL_HOLIDAY_LIST_GETBYID = 'v1/HolidayList/GetHolidayListById';
  static COMPONENT_SCHOOL_HOLIDAY_LIST_UPDATE = 'v1/HolidayList/UpdateHolidayList';
  //Holiday
  static COMPONENT_SCHOOL_HOLIDAY_GETALL = 'v1/Holiday/GetHolidayByHolidayInfoId';
  static COMPONENT_SCHOOL_HOLIDAY_SAVE = 'v1/Holiday/SaveHoliday';
  static COMPONENT_SCHOOL_HOLIDAY_DELETE = 'v1/Holiday/DeleteHoliday';
  static COMPONENT_SCHOOL_HOLIDAY_GETBYID = 'v1/Holiday/GetHolidayById';
  static COMPONENT_SCHOOL_HOLIDAY_UPDATE = 'v1/Holiday/UpdateHoliday';
  static COMPONENT_SCHOOL_HOLIDAY_GET_BY_SESSIONID = 'v1/Holiday/GetHolidayBySessionId?sessionId=';
  //Grade Scale
  static COMPONENT_SCHOOL_GETALL_GRADESCALE_CATEGORY = 'v1/schoolgradescale/getallschoolgradescale?schoolid=';
  static COMPONENT_SCHOOL_GETALL_GRADESCALE_CATEGORY_DETAILS = 'v1/schoolgradescale/getschoolgradescalebyid?id=';
  static COMPONENT_SCHOOL_GETALL_GRADESCALE_LETTERGRADE_DETAILS = 'v1/schoolgradescaledetail/getallschoolgradescaledetail?baseGradeId=';
  static COMPONENT_SCHOOL_SAVE_GRADESCALE_CATEGORY = 'v1/schoolgradescale/saveschoolgradescale';
  static COMPONENT_SCHOOL_UPDATE_GRADESCALE_CATEGORY = 'v1/schoolgradescale/updateschoolgradescale';
  static COMPONENT_SCHOOL_GRADESCALE_DELETE = 'v1/schoolgradescale/deleteschoolgradescale';
  static COMPONENT_SCHOOL_SAVE_GRADELETTER = 'v1/schoolgradescaledetail/saveschoolgradescaledetail';
  static COMPONENT_SCHOOL_UPDATE_GRADELETTER = 'v1/schoolgradescaledetail/updateschoolgradescaledetail';
  static COMPONENT_SCHOOL_GET_GRADE_LETTER_DETAILS = 'v1/schoolgradescaledetail/getschoolgradescaledetailbyid?id=';
  static COMPONENT_SCHOOL_GRADELETTER_DELETE = 'v1/schoolgradescaledetail/deleteschoolgradescaledetail';
  static COMPONENT_SCHOOL_GRADESCALE_COPY = 'v1/schoolgradescale/copyschoolgradescale';
  static COMPONENT_ALL_SCHOOL_GRADESCALE_LEVEL_GETALL = 'v1/schoolgradescaledetail/getallschoolgradescaledetaillevel?';
  static COMPONENT_STUDENT_GETALL_WITH_PREVIOUS_GRADELEVEL = 'v1/schoolgradelevel/getgradelevelwithpreviousgradelevel';
  // delete photo
  static COMPONENT_DELETE_SCHOOL_PHOTO = 'v1/GeneralInfo/DeleteLogo';
  // Create Announcement
  static COMPONENT_ANNOUNCEMENT_GETALL = 'v1/announcement/getannouncementbyschool';
  static COMPONENT_ANNOUNCEMENT_BY_FILTER = 'v1/announcement/getcreatedannouncementbyfilter';
  static COMPONENT_SAVE_ANNOUNCEMENT = 'v1/announcement/saveannouncement';
  static COMPONENT_SAVE_ANNOUNCEMENT_ROLEWISE = 'v1/announcement/saveannouncementrolewise';
  static COMPONENT_UPDATE_ANNOUNCEMENT_ROLEWISE = 'v1/announcement/updateannouncementrolewise';
  static COMPONENT_UPDATE_ANNOUNCEMENT = 'v1/announcement/updateannouncement';
  static COMPONENT_ANNOUNCEMENT_BY_ID = 'v1/announcement/getannouncementbyid?id=';
  static COMPONENT_ANNOUNCEMENT_ROLE_WISE_BY_ID = 'v1/announcement/getannouncementrolewisebyid?id=';
  static COMPONENT_ANNOUNCEMENT_DELETE = 'v1/announcement/deleteannouncement';
  static COMPONENT_ANNOUNCEMENT_GET_BY_ROLE_ID = 'v1/announcement/getannouncementbyuserrole?roleId=';
  static COMPONENT_ANNOUNCEMENT_GET_ALL_BY_SCHOOL = 'v1/announcement/getannouncementbyschool?schoolId=';
  static COMPONENT_ANNOUNCEMENT_GET_ALL_BY_ROLEWISE_DATE = 'v1/announcement/GetAllRolewiseReceivedAnnouncementByDate?schoolId=';
  static COMPONENT_DELETE_ANNOUNCEMENT_FILE = 'v1/announcement/removeannouncementfile';
  // role based permissions
  static COMPONENT_GET_ROLE_BASED_PERMISSIONS = 'v1/schoolrolepermissionset/getpermissionsetbyroleid?roleId=';
  static COMPONENT_GET_SCHOOL_BASED_ROLE_LIST_BY_USERID = 'v1/schoolrole/getschoolrolesbyuserid';
  // Message
  static COMPONENT_SCHOOL_GETALL_STUDENT_STAFF_PARENT_LIST = 'v1/communication/getallstudentstaffparentlist';
  // Holiday List Publication
  static COMPONENT_GET_HOLIDAY_LIST = 'v1/holidaylistpublication/getholidayinfolistview?';
  static COMPONENT_GET_HOLIDAY_INFO_BY_ID = 'v1/holidaylistpublication/getholidayinfodetailsbyholidayinfoid?';
  static COMPONENT_UPDATE_HOLIDAY_STATUS = 'v1/holidaylistpublication/updateholidaylistpublicationstatus';
  // Languages
  static COMPONENT_GET_ALL_LANGUAGES_BY_SCHOOL_ID = 'v1/schoollanguage/getschoollanguagebyschool?schoolId=';
  static COMPONENT_SAVE_SCHOOL_LANGUAGES = 'v1/schoollanguage/saveschoollanguage';
  static COMPONENT_DELETE_SCHOOL_LANGUAGES = 'v1/schoollanguage/deleteschoollanguage';
  // Lunch Program
  static COMPONENT_GET_SCHOOL_LUNCH_PROGRAM = 'v1/lunchprogram/getlunchprogrambyid';
  static COMPONENT_SAVE_SCHOOL_LUNCH_PROGRAM = 'v1/lunchprogram/updatelunchprogram';
  //Grade Scale Level
  static COMPONENT_SCHOOL_GRADESCALE_LEVEL_GETALL = 'v1/gradescalelevel/getallgradescalelevel?';
  static COMPONENT_SCHOOL_GRADESCALE_LEVEL_SAVE = 'v1/gradescalelevel/savegradescalelevel';
  static COMPONENT_SCHOOL_GRADESCALE_LEVEL_BY_ID = 'v1/gradescalelevel/getgradescalelevelbyid?';
  static COMPONENT_SCHOOL_GRADESCALE_LEVEL_UPDATE = 'v1/gradescalelevel/updategradescalelevel';
  static COMPONENT_SCHOOL_GRADESCALE_LEVEL_DELETE = 'v1/gradescalelevel/deletegradescalelevel';
  //Define GPA
  static COMPONENT_SCHOOL_DEFINE_GPA_GETALL = 'v1/basegradegpalevel/getallbasegradegpalevel?';
  static COMPONENT_SCHOOL_DEFINE_GPA_SAVE = 'v1/basegradegpalevel/savebasegradegpalevel';
  static COMPONENT_SCHOOL_DEFINE_GPA_BY_ID = 'v1/basegradegpalevel/getbasegradegpalevelbyid?';
  static COMPONENT_SCHOOL_DEFINE_GPA_UPDATE = 'v1/basegradegpalevel/updatebasegradegpalevel';
  static COMPONENT_SCHOOL_DEFINE_GPA_DELETE = 'v1/basegradegpalevel/deletebasegradegpalevel';
  //roleover
  static COMPONENT_GET_SCHOOL_SESSION_BY_SCHOOL_YEAR = 'v1/rollover/getschoolsessionbyschoolyear';
  static COMPONENT_GET_SCHOOL_CALENDAR_BY_SESSION = 'v1/rollover/getcalendarbyschoolsession';
  static COMPONENT_GET_SCHOOL_MARKING_PERIOD_BY_SESSION = 'v1/rollover/getmarkingperiodbysession';
  static COMPONENT_GET_COURSE_MANAGER_BY_SCHOOL = 'v1/rollover/getcoursemanagerbyfilter';
  static COMPONENT_GET_SCHOOL_ROLLOVER_INFO = 'v1/rollover/getrolledoverdetailsdatabysession?sessionId=';
  //blockDefination
  static COMPONENT_BLOCK_DEFINATION_SAVE = 'v1/blockdefinition/createstudentblockdefinition';
  static COMPONENT_BLOCK_DEFINATION_UPDATE = 'v1/blockdefinition/updateblockdefinition';
  static COMPONENT_DELETE_BLOCK_DEFINATION = 'v1/blockdefinition/deleteblockdefinition';
  static COMPONENT_GET_BLOCK_DEFINATION = 'v1/blockdefinition/getblockdefinitionbyid?Id=';
  static COMPONENT_GET_ALL_BLOCK_DEFINATION = 'v1/blockdefinition/getallblockdefinition?schoolId=';
  static COMPONENT_GET_BLOCK_DEFINATION_BY_SESSIONID = 'v1/blockdefinition/getblockdefinitionbysessionid?schoolId=';
  //CohortInfo
  static COMPONENT_COHORT_INFO_SAVE = 'v1/cohortinfo/savecohortinfo';
  static COMPONENT_COHORT_INFO_UPDATE = 'v1/cohortinfo/updatecohortinfo';
  static COMPONENT_GET_COHORT_INFO = 'v1/cohortinfo/getcohortinfobyschoolid?id=';
  static COMPONENT_GET_COHORT_INFO_BY_ID = 'v1/cohortinfo/getcohortinfobyid?id=';
  static COMPONENT_COHORT_INFO_DELETE = 'v1/cohortinfo/deletecohortinfo';
  //School Label
  static COMPONENT_LABEL_ORIENTATION_SAVE = 'v1/labelorientation/savelabelorientation';
  static COMPONENT_LABEL_ORIENTATION_UPDATE = 'v1/labelorientation/updatelabelorientation';
  static COMPONENT_LABEL_ORIENTATION_GETALL = 'v1/labelorientation/getalllabelorientationbyschoolid?schoolId=';
  static COMPONENT_LABEL_ORIENTATION_GET_BY_ID = 'v1/labelorientation/getlabelorientationbyid?id=';
  static COMPONENT_LABEL_ORIENTATION_DELETE = 'v1/labelorientation/deletelabelorientation';
  //Select Schedule Type
  static COMPONENT_SELECT_SCHEDULE_UPDATE = 'v1/schoolcontrolitems/updateschoolcontrolitem';
  static COMPONENT_SELECT_SCHEDULE_GET_BY_ID = 'v1/schoolcontrolitems/getschoolcontrolitembyid?Id=';
  static COMPONENT_SCHOOL_ALLOW_MULTIPLE_SCHEDULE_SAVE = 'v1/schoolcontrolitems/saveallowmultiplestudentschedule';
  static COMPONENT_STUDENT_SCHEDULE_COURSE_SECTIONS_BY_MP = 'v1/studentschedule/getstudentschedulecoursesectionsbymp';

  //Comment code setup
  static COMPONENT_SCHOOL_COMMENT_UPDATE = 'v1/codecomments/updatecodecomments';
  static COMPONENT_SCHOOL_COMMENT_SAVE = 'v1/codecomments/savecodecomments';
  static COMPONENT_SCHOOL_COMMENT_DELETE = 'v1/codecomments/deletecodecomments';
  static COMPONENT_SCHOOL_COMMENT_GETALL = 'v1/codecomments/getcodecommentsbyschoolid?schoolId=';
  static COMPONENT_SCHOOL_COMMENT_BYID = 'v1/codecomments/getcodecommentsbyid?id=';
  //School Standard Scale
  static COMPONENT_SAVE_SCHOOL_STANDARD_SCALE = 'v1/schoolstandardscale/saveschoolstandardscale';
  static COMPONENT_GET_ALL_SCHOOL_STANDARD_SCALE = 'v1/schoolstandardscale/getallschoolstandardscale';
  static COMPONENT_DELETE_SCHOOL_STANDARD_SCALE = 'v1/schoolstandardscale/deleteschoolstandardscale';
  static COMPONENT_GET_SCHOOL_STANDARD_SCALE_BY_ID = 'v1/schoolstandardscale/getschoolstandardscalebyid';
  static COMPONENT_DELETE_SCHOOL_STANDARD_SCALE_DETAILS = 'v1/schoolstandardscale/deleteschoolstandardscaledetails';
  static COMPONENT_UPDATE_SCHOOL_STANDARD_SCALE = 'v1/schoolstandardscale/updateschoolstandardscale';
  //School Standard Group
  static COMPONENT_SCHOOL_STANDARD_GROUP_GETALL = 'v1/schoolstandardgroup/getallschoolstandardgroup?';
  static COMPONENT_SCHOOL_STANDARD_GROUP_DELETE = 'v1/schoolstandardgroup/deleteschoolstandardgroup';
  static COMPONENT_SCHOOL_STANDARD_GROUP_SAVE = 'v1/schoolstandardgroup/saveschoolstandardgroup';
  static COMPONENT_SCHOOL_STANDARD_GROUP_GETBYID = 'v1/schoolstandardgroup/getschoolstandardgroupbyid?id=';
  static COMPONENT_SCHOOL_STANDARD_GROUP_UPDATE = 'v1/schoolstandardgroup/updateschoolstandardgroup';
  //schoolRole
  static COMPONENT_SCHOOL_CALENDAR_ROLE = 'v1/schoolrole/getallbyschoolid?schoolId=';
  //school department
  static COMPONENT_SCHOOL_DEPARTMENT_LIST = 'v1/schooldepartment/getschoolalldepartments';
  static COMPONENT_STUDENT_OVERVIEW_GET_MARKINGPERIODS_LIST_ASSIGNMENT_BY_SESSION = 'v1/markingperiod/getmarkingperiodslistforassignmentsbysession';
  //Student Username Setup
  static COMPONENT_STUDENT_USERNAME_SETUP_SAVE = 'v1/studentusernameconfig/savestudentusernameconfig';
  static COMPONENT_STUDENT_USERNAME_SETUP_UPDATE = 'v1/studentusernameconfig/updatestudentusernameconfig';
  static COMPONENT_STUDENT_USERNAME_SETUP_GETBYID = 'v1/studentusernameconfig/getbydistrictid?DistrictId=';
  static COMPONENT_STUDENT_USERNAME_SETUP_GETBYSCHOOLID = 'v1/studentusernameconfig/getbyschoolid?SchoolId=';

  static COMPONENT_SCHOOL_GET_TEACHER_ATTENDANCE_BY_SCHOOL_ID = 'v1/schoolcontrolitems/getteachereditattendancebyschooid?SchoolId=';

  //New Grade Plan
  static COMPONENT_CONTROL_PANEL_GET_ALL_GRADUATION_PLAN_BY_DISTRICT_ID = 'v1/graduationplan/getallgraduationplanbydistrict?districtId=';
  static COMPONENT_CONTROL_PANEL_SAVE_GRADUATION_PLAN = 'v1/graduationplan/savegraduationplan';
  static COMPONENT_CONTROL_PANEL_UPDATE_GRADUATION_PLAN = 'v1/graduationplan/updategraduationplan';
  static COMPONENT_CONTROL_PANEL_GET_GRADUATION_PLAN_BY_ID = 'v1/graduationplan/getgraduationplanbyid?id=';
  static COMPONENT_CONTROL_PANEL_GETDEFAULT_GP_OTHER_THAN_CURRENT = 'v1/graduationplan/getdefaultgpotherthancurrent?districtId=';
  static COMPONENT_CONTROL_PANEL_DELETE_GRADUATION_PLAN = 'v1/graduationplan/deletegraduationplan';


  //Associate GradPlan Category
  static COMPONENT_CONTROL_PANEL_GET_CATEGORY_WITH_GRAD_PLAN_ASSOC_VALUES = 'v1/graduationcategoryassoc/getcategorieswithgradplanassocvalues?districtId=';
  static COMPONENT_CONTROL_PANEL_SAVE_GRADUATION_CATEGORY = 'v1/graduationcategory/savegraduationcategory';
  static COMPONENT_CONTROL_PANEL_UPDATE_GRADUATION_CATEGORY = 'v1/graduationcategory/updategraduationcategory';
  static COMPONENT_CONTROL_PANEL_GET_ALL_GRADUATION_CATEGORIES_BY_DISTRICT_ID = 'v1/graduationcategory/getallgraduationcategorybydistrict?districtId=';
  static COMPONENT_CONTROL_PANEL_GET_GRADUATION_CATEGORY_BY_ID = 'v1/graduationcategory/getgraduationcategorybyid?id=';
  static COMPONENT_CONTROL_PANEL_DELETE_GRADUATION_CATEGORY_BY_ID = 'v1/graduationcategory/deletegraduationcategory';
  static COMPONENT_CONTROL_PANEL_SAVE_SUB_CATEGORY = 'v1/graduationsubcategory/savegraduationsubcategory';
  static COMPONENT_CONTROL_PANEL_UPDATE_SUB_CATEGORY = 'v1/graduationsubcategory/updategraduationsubcategory';
  static COMPONENT_CONTROL_PANEL_GET_SUB_CATEGORY_BY_ID = 'v1/graduationsubcategory/getgraduationsubcategorybyid?id=';
  static COMPONENT_CONTROL_PANEL_DELETE_SUB_CATEGORY_BY_ID = 'v1/graduationsubcategory/deletegraduationsubcategory';
  static COMPONENT_CONTROL_PANEL_SAVE_GRADUATION_ASSOCIATION_CATEGORIES = 'v1/graduationcategoryassoc/savegraduationcategoryassoc';
  static COMPONENT_CONTROL_PANEL_DELETE_CATEGORY_BY_ID = 'v1/graduationcategoryassoc/deletegraduationcategoryassoc';

  static COMPONENT_CONTROL_PANEL_GET_SEQUENCE = 'v1/graduationrequirement/getsequence?graduation_sub_category_id=';
  static COMPONET_CONTROL_PANEL_GET_ASSOCIATED_COURSE_LIST_BY_SUB_CATEGORY_ID = 'v1/graduationrequirement/getassociatedcourselistbysubcategoryid?graduation_sub_category_id=';

  static COMPONENT_CONTROL_PANEL_SAVE_REQUIREMENT = 'v1/graduationrequirement/saverequirement';
  static COMPONENT_CONTROL_PANEL_UPDATE_REQUIREMENT = 'v1/graduationrequirement/updaterequirement';
  static COMPONENT_CONTROL_PANEL_GET_REQUIREMENT_BY_ID = 'v1/graduationrequirement/getrequirementbyid?id=';
  static COMPONENT_CONTROL_PANEL_DELETE_REQUIREMENT = 'v1/graduationrequirement/deleterequirement';
  static COMPONENT_CONTROL_PANEL_GET_ALL_REQUIREMENT_BY_SUB_CATEGORY_ID = 'v1/graduationrequirement/getallrequirementbysubcategoryid?graduation_sub_category_id=';

  static COMPONENT_CONTROL_PANEL_UPDATE_REQUIREMENT_SEQUENCE = 'v1/graduationrequirement/updaterequirementsequence';
  //signature pad
  static COMPONENT_CONTROL_PANEL_SAVE_PRINCIPAL_SIGNATURE_UPDATE = 'v1/uploadsignature/saveuploadsignature'
  static COMPONENT_CONTROL_PANEL_GET_PRINCIPAL_SIGNATURE = 'v1/uploadsignature/getbyschoolid?schoolId=';

  // SCHOOL SESSION
  static COMPONENT_SCHOOL_GETSESSION_BYDISTRICT = 'v1/schoolsession/getschoolsessionbydistrictid?districtId=';
}
export default commonSchoolConfig;
