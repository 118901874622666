export class commonStaffConfig {
    static COMPONENT_STAFF = 'staff';
    static METHOD_TYPE_POST = 'POST';
    static METHOD_TYPE_GET = 'GET';
    //staff List 
    static COMPONENT_GETALL_STAFF_MEMBERS = 'v1/staffinfo/getstafflistforstudenthealthvisit?schoolId=';
    // Submenu Data
    static COMPONENT_STAFF_SUBMENU_DATA = 'v1/staffinfo/getstaffsectionbyid?staffId=';
    //Demographic Info
    static COMPONENT_STAFF_DEMOGRAPHICINFO_SAVE = 'v1/staffinfo/savestaffinfo';
    static COMPONENT_STAFF_DEMOGRAPHICINFO_LOGO_SAVE = 'v1/DemographicInfo/SaveStaffDemographicInfoLogo';
    static COMPONENT_STAFF_DEMOGRAPHICINFO_UPDATE = 'v1/staffinfo/updatestaffinfo';
    static COMPONENT_STAFF_DEMOGRAPHICINFO_DELETE = 'v1/staffinfo/deletestaffinfo';
    static COMPONENT_STAFF_DEMOGRAPHICINFO_GETALL = 'v1/staffinfo/getstaffinfo';
    static COMPONENT_STAFF_GET_BY_PAGINATION = 'v1/staffinfo/getstaffinfopaginated';
    static COMPONENT_STAFF_GET_BY_EXPORT = 'v1/staffinfo/getbyschoolidexport';
    static COMPONENT_STAFF_DEMOGRAPHICINFO_GETBYID = 'v1/staffinfo/getstaffinfobyid?id=';
    static COMPONENT_STAFF_TEACHERS_GETALL_BY_SCHOOL_ID = 'v1/staffinfo/getallteacherfromstaffbyschool?schoolId=';
    static COMPONENT_STAFF_GET_STAFF_CODE = 'v1/staffinfo/getstaffcode?';
    static COPONENT_STAFF_GET_BY_PDF = 'v1/staffinfo/downloadpdfformatdataforstaffinfo';
    static COMPONENT_STAFF_GET_BY_EXPORT_CSV = 'v1/staffinfo/stafflistexport';
    // Staff Certificate
    static GET_STAFF_JOB_CERTIFICATION = 'v1/staffjobcertification/getallstaffjobcertification'
    static COMPONENT_STAFF_CERTIFICATE_SAVE = 'v1/staffcertification/savestaffcertification';
    static COMPONENT_STAFF_CERTIFICATE_UPDATE = 'v1/staffcertification/updatestaffcertification';
    static COMPONENT_STAFF_CERTIFICATE_DELETE = 'v1/staffcertification/deletestaffcertification';
    static COMPONENT_STAFF_CERTIFICATE_GETBYID = 'v1/staffcertification/getstaffcertificationbyid';
    static COMPONENT_STAFF_CERTIFICATE_GETALL = 'v1/staffcertification/GetStaffAllCertificationByStaffId';
    static COMPONENT_GET_STAFF_TYPE_CREDENTIAL = 'v1/credential/getstafftypecredentials?schoolId=';
    //Staff Certification Verification
    static COMPONENT_STAFF_CERTIFICATE_GETALL_BY_SCHOOL = 'v1/staffcertificationverification/getstaffcertificationverificationbyschoolidandfilter';
    static COMPONENT_STAFF_CERTIFICATE_VERIFICATION_APPROVED_SAVE = 'v1/staffcertificationverification/SaveStaffCertificationVerification';
    static COMPONENT_STAFF_CERTIFICATE_VERIFICATION_APPROVED_UPDATE = 'v1/staffcertificationverification/UpdateStaffCertificationVerification';
    static COMPONENT_STAFF_CERTIFICATION_VERIFICATION_GET_BY_PAGINATION = 'v1/staffcertificationverification/getstaffcertificationverificationpaginated';
    //Phone Info
    static COMPONENT_STAFF_PHONE_SAVE = 'v1/PhoneInfo/SaveStaffPhoneInfo';
    static COMPONENT_STAFF_PHONE_UPDATE = 'v1/PhoneInfo/UpdateStaffPhoneInfo';
    static COMPONENT_STAFF_PHONE_DELETE = 'v1/PhoneInfo/DeleteStaffPhoneInfo';
    static COMPONENT_STAFF_PHONE_GETALL = 'v1/PhoneInfo/GetStaffPhoneInfo';
    static COMPONENT_STAFF_PHONE_GETBYID = 'v1/PhoneInfo/GetStaffPhoneInfoById';
    //Email Info
    static COMPONENT_STAFF_EMAIL_SAVE = 'v1/EmailInfo/SaveStaffEmailInfo';
    static COMPONENT_STAFF_EMAIL_UPDATE = 'v1/EmailInfo/UpdateStaffEmailInfo';
    static COMPONENT_STAFF_EMAIL_DELETE = 'v1/EmailInfo/DeleteStaffEmailInfo';
    static COMPONENT_STAFF_EMAIL_GETALL = 'v1/EmailInfo/GetStaffEmailInfo';
    static COMPONENT_STAFF_EMAIL_GETBYID = 'v1/EmailInfo/GetStaffEmailInfoById';
    //Address Info
    static COMPONENT_STAFF_ADDRESS_SAVE = 'v1/AddressInfo/SaveStaffAddressInfo';
    static COMPONENT_STAFF_ADDRESS_UPDATE = 'v1/AddressInfo/UpdateStaffAddressInfo';
    static COMPONENT_STAFF_ADDRESS_DELETE = 'v1/AddressInfo/DeleteStaffAddressInfo';
    static COMPONENT_STAFF_ADDRESS_GETALL = 'v1/AddressInfo/GetStaffAddressInfo';
    static COMPONENT_STAFF_ADDRESS_GETBYID = 'v1/AddressInfo/GetStaffAddressInfoById';
    //Contact Info
    static COMPONENT_STAFF_CONTACT_SAVE = 'v1/StaffContact/SaveStaffContact';
    static COMPONENT_STAFF_CONTACT_UPDATE = 'v1/StaffContact/UpdateStaffContact';
    static COMPONENT_STAFF_CONTACT_DELETE = 'v1/StaffContact/DeleteStaffContact';
    static COMPONENT_STAFF_CONTACT_GETALL = 'v1/StaffContact/GetStaffAllContactByStaffId';
    static COMPONENT_STAFF_CONTACT_GETBYID = 'v1/StaffContact/GetStaffContactById';
    //Staff Role Info
    static COMPONENT_STAFF_ROLE_ASSIGNMENT_SAVE = 'v1/staffjob/savestaffjob';
    static COMPONENT_STAFF_ROLE_ASSIGNMENT_UPDATE = 'v1/staffjob/updatestaffjob';
    static COMPONENT_STAFF_ROLE_ASSIGNMENT_DELETE = 'v1/staffjob/deletestaffjob';
    static COMPONENT_STAFF_ROLE_ASSIGNMENT_GETALL = 'v1/staffjob/GetStaffAllJobByStaffId';
    static COMPONENT_STAFF_ROLE_ASSIGNMENT_GETBYID = 'v1/staffjob/getstaffjobbyid';
    //Employment Info
    static COMPONENT_STAFF_EMPLOYMENT_SAVE = 'v1/staffEmploymentinfo/saveStaffEmploymentInfo';
    static COMPONENT_STAFF_EMPLOYMENT_GETBYID = 'v1/staffEmploymentinfo/GetStaffEmploymentInfoById';
    static COMPONENT_STAFF_ALL_EMPLOYMENT_GETBY_STAFFID = 'v1/staffemploymentinfo/getstaffemploymentinfobystaffid?staffId=';
    //STAFF CREDENTIAL VERIFICATION
    static COMPONENT_STAFF_CREDENTIAL_VERIFICATION_LIST = 'v1/staffcredential/getstaffcredentialverificationlists?';
    static COMPONENT_STAFF_UPDATE_CERTIFICAION_STATUS = 'v1/staffcredential/updatecredentialcertificationstatus';
    static COMPONENT_STAFF_UPDATE_VERIFICATION_STATUS = 'v1/staffcredential/updatecredentialverificationstatus';
    //Staff Job Info
    static COMPONENT_STAFF_JOB_GETALL = 'v1/Job/GetJobBySchoolId?schoolId=';
    static COMPONENT_STAFF_JOB_SAVE = 'v1/Job/SaveJob';
    static COMPONENT_STAFF_JOB_UPDATE = 'v1/Job/UpdateJob';
    static COMPONENT_STAFF_JOB_GET_BY_ID = 'v1/Job/GetJobById?id=';
    static COMPONENT_STAFF_JOB_DELETE = 'v1/job/DeleteJob';
    static COMPONENT_STAFF_JOB_GET_BY_CATEGORY = 'v1/job/GetJobByCategory?categoryId=';
    //Staff Teaching Experience
    static COMPONENT_STAFF_TEACHING_EXPERIENCE_SAVE = 'v1/teacherexperience/saveteacherexperience';
    static COMPONENT_STAFF_TEACHING_EXPERIENCE_GETBYID = 'v1/teacherexperience/getteacherexperiencebystaffid';
    //Staff Personal Credential 
    static COMPONENT_STAFF_PERSONAL_CREDENTIAL_SAVE = 'v1/staffPersonalCredential/SaveStaffPersonalCredential';
    static COMPONENT_STAFF_PERSONAL_CREDENTIAL_UPDATE = 'v1/staffPersonalCredential/UpdateStaffPersonalCredential';
    static COMPONENT_STAFF_PERSONAL_CREDENTIAL_DELETE = 'v1/staffPersonalCredential/DeleteStaffPersonalCredential';
    static COMPONENT_STAFF_PERSONAL_CREDENTIAL_GETALL = 'v1/staffPersonalCredential/GetStaffPersonalCredentialByStaffId';
    static COMPONENT_STAFF_PERSONAL_CREDENTIAL_GETBYID = 'v1/staffPersonalCredential/GetStaffPersonalCredentialById';
    static COMPONENT_STAFF_PERSONAL_TYPE_CREDENTIAL = 'v1/credential/getpersonaltypecredentials?schoolId=';
    //Staff Visa
    static COMPONENT_STAFF_VISA_GETALL = 'v1/staffVisa/GetStaffAllVisaByStaffId';
    static COMPONENT_STAFF_VISA_SAVE = 'v1/staffVisa/SaveStaffVisa';
    static COMPONENT_STAFF_VISA_UPDATE = 'v1/staffVisa/UpdateStaffVisa';
    static COMPONENT_STAFF_VISA_DELETE = 'v1/staffVisa/DeleteStaffVisa';
    static COMPONENT_STAFF_VISA_GETBYID = 'v1/staffVisa/GetStaffVisaById';
    //Staff Calendar 
    static COMPONENT_STAFF_CALENDAR_GETALL = 'v1/staffcalendar/GetStaffDefaultCalendar';
    static COMPONENT_STAFF_CALENDAR_UPDATE = 'v1/staffcalendar/updatestaffcalendar';
    static COMPONENT_STAFF_CALENDAR_GETBYID = 'v1/staffcalendar/GetStaffCalendarById ';
    static COMPONENT_STAFF_CALENDAR_EVENT_SAVE = 'v1/calendarevent/savecalendareventwithvisibility';
    static COMPONENT_STAFF_CALENDAR_EVENT_UPDATE = 'v1/calendarevent/updatecalendareventwithvisibility';
    static COMPONENT_STAFF_CALENDAR_EVENT_GETALL = 'v1/calendarevent/getcalendarevent';
    static COMPONENT_STAFF_CALENDAR_EVENT_GETALL_BY_SCHOOL_ID = 'v1/calendarevent/getallcalendareventbyschoolandstaff ';
    static COMPONENT_STAFF_CALENDAR_EVENT_GETBYID = 'v1/calendarevent/getcalendareventbyid';
    static COMPONENT_STAFF_CALENDAR_EVENT_DELETE = 'v1/calendarevent/deletecalendarevent';
    static COMPONENT_STAFF_CALENDAR_CRISIS_GETALL = 'v1/SchoolCalendarCrisis/GetSchoolCalendarCrisis';
    static COMPONENT_STAFF_CRISIS_GET_BY_ID = 'v1/StaffCalendarCrisis/GetSchoolCalendarCrisisById';
    //Staff personal document verification
    static COMPONENT_STAFF_PERSONAL_DOCUMENT_VERIFICATION_SAVE = 'v1/staffdocumentverification/savestaffdocumentverification';
    static COMPONENT_STAFF_PERSONAL_DOCUMENT_VERIFICATION_UPDATE = 'v1/staffdocumentverification/updatestaffdocumentverification';
    static COMPONENT_STAFF_PERSONAL_DOCUMENT_VERIFICATION_DELETE = 'v1/staffdocumentverification/deletestaffdocumentverification';
    static COMPONENT_STAFF_PERSONAL_DOCUMENT_VERIFICATION_GET_BY_ID = 'v1/staffdocumentverification/getstaffdocumentverificationbyid?id=';
    static COMPONENT_STAFF_PERSONAL_DOCUMENT_VERIFICATION_GET_ALL = 'v1/staffdocumentverification/getstaffalldocumentverificationbystaffid';
    static COMPONENT_PERSONAL_DOCUMENT_VERIFICATION_BY_SCHOOL_AND_FILTER = 'v1/staffdocumentverification/getstaffalldocumentverificationbyschoolandfilter';
    static COMPONENT_VERIFY_CERTIFY_STAFF_DOCUMENT_VERIFICATION = 'v1/staffdocumentverification/verifycertifystaffdocumentverification';
    static COMPONENT_PERSONAL_DOCUMENT_VERIFICATION_GET_BY_PAGINATION = 'v1/staffdocumentverification/getstaffdocumentverificationpaginated';
    //Staff credential
    static COMPONENT_STAFF_CREDENTIAL_SAVE = 'v1/staffcredential/savestaffcredential';
    static COMPONENT_STAFF_CREDENTIAL_UPDATE = 'v1/staffcredential/updatestaffcredential';
    static COMPONENT_STAFF_CREDENTIAL_DELETE = 'v1/staffcredential/deletestaffcredential';
    static COMPONENT_STAFF_CREDENTIAL_GET_BY_ID = 'v1/staffcredential/getstaffcredentialbyid';
    static COMPONENT_STAFF_CREDENTIAL_GET_ALL = 'v1/staffcredential/GetAllStaffCredentialByStaffId';
    //Staff Leave
    static COMPONENT_STAFF_LEAVE_GETALL = 'v1/StaffLeave/GetStaffLeaveByStaffId';
    static COMPONENT_STAFF_LEAVE_SAVE = 'v1/StaffLeave/SaveStaffLeave';
    static COMPONENT_STAFF_LEAVE_UPDATE = 'v1/StaffLeave/UpdateStaffLeave';
    static COMPONENT_STAFF_LEAVE_GET_BY_ID = 'v1/StaffLeave/GetStaffLeaveById';
    static COMPONENT_STAFF_LEAVE_DELETE = 'v1/StaffLeave/DeleteStaffLeave';
    static COMPONENT_STAFF_LEAVE_APPLIED_TO_ID = 'v1/staffleave/getstaffleavebyappliedtoid';
    static COMPONENT_STAFF_LEAVE_BY_SCHOOL_ID_AND_FILTER = 'v1/staffleave/getstaffleavebyschoolidandfilter';
    static COMPONENT_STAFF_LEAVE_BY_ASSIGNED_TO_ID_AND_FILTER = 'v1/staffleave/GetStaffLeaveByAssignedToIdAndFilter';
    static COMPONENT_STAFF_LEAVE_GET_BY_PAGINATION = 'v1/StaffLeave/GetStaffLeavePaginated';
    //Staff Job category
    static COMPONENT_STAFF_JOB_CATEGORY_SAVE = 'v1/jobcategory/savejobcategory';
    static COMPONENT_STAFF_JOB_CATEGORY_UPDATE = 'v1/jobcategory/updatejobcategory';
    static COMPONENT_STAFF_JOB_CATEGORY_DELETE = 'v1/jobcategory/deletejobcategory';
    static COMPONENT_STAFF_JOB_CATEGORY_GET_BY_ID = 'v1/jobcategory/getjobcategorybyid?id=';
    static COMPONENT_STAFF_JOB_CATEGORY_GET_BY_SCHOOL_ID = 'v1/jobcategory/getjobcategorybyschoolid?schoolId=';
    //Staff Job Certification
    static COMPONENT_STAFF_JOB_CERTIFICATION_GETALL = 'v1/staffJobCertification/GetAllJobCertificationBySchoolId?schoolId=';
    static COMPONENT_STAFF_JOB_CERTIFICATION_SAVE = 'v1/staffJobCertification/SaveStaffJobCertification';
    static COMPONENT_STAFF_JOB_CERTIFICATION_UPDATE = 'v1/staffJobCertification/UpdateStaffJobCertification';
    static COMPONENT_STAFF_JOB_CERTIFICATION_DELETE = 'v1/staffJobCertification/DeleteStaffJobCertification';
    static COMPONENT_STAFF_JOB_CERTIFICATION_GET_BY_ID = 'v1/staffJobCertification/GetStaffJobCertificationById?id=';
    // delete photo
    static COMPONENT_DELETE_STAFF_PHOTO = 'v1/staffInfo/deletephoto';
    static COMPONENT_DELETE_STAFF_DOCUMENT = 'v1/staffdocumentverification/deletedocument';
    static COMPONENT_DELETE_STAFF_CERTIFICATION = 'v1/StaffCertification/DeleteCertification';
    //Staff Authorization
    static COMPONENT_STAFF_AUTHORIZATION_SAVE = 'v1/staffauthorization/savestaffauthorization';
    static COMPONENT_STAFF_AUTHORIZATION_GET_BY_ID = 'v1/staffauthorization/getstaffauthorizationbyid?id=';
    static COMPONENT_STAFF_GET_STAFF_HOLIDAY_LIST = 'v1/staffinfo/getallstaffforholidaylist?schoolId=';
    //staff identifier
    static COMPONENT_STAFF_IDENTIFIER_SAVE = 'v1/staffidentifier/savestaffidentifier';
    static COMPONENT_STAFF_IDENTIFIER_UPDATE = 'v1/staffidentifier/updatestaffidentifier';
    static COMPONENT_STAFF_IDENTIFIER_DELETE = 'v1/staffidentifier/deletestaffidentifier';
    static COMPONENT_STAFF_IDENTIFIER_GET_BY_ID = 'v1/staffidentifier/getstaffidentifierbyid';
    //static COMPONENT_STAFF_IDENTIFIER_GET_ALL = 'v1/staffidentifier/getallstaffidentifier';
    static COMPONENT_STAFF_IDENTIFIER_GET_BY_STAFF_ID = 'v1/staffidentifier/getstaffidentifierbystaffid';
    static COMPONENT_DEFAULT_STAFF_IDENTIFIER_GET = 'v1/staffidentifier/getdefaultstaffidentifier';
    //misc skills
    static COMPONENT_STAFF_MISC_SKILLS_GET_BY_STAFF_ID = 'v1/staffmiscskilldetail/getstaffmiscskilldetailbystaffid?staffId=';
    static COMPONENT_STAFF_MISC_SKILLS_SAVE = 'v1/staffmiscskilldetail/savestaffmiscskilldetail';
    static COMPONENT_STAFF_MISC_SKILLS_UPDATE = 'v1/staffmiscskilldetail/updatestaffmiscskilldetail';
    // INDIA SPECIFIC ENDPOINTS
    // IN - School Staff Info
    static COMPONENT_STAFF_IN_DEMOGRAPHICINFO_SAVE = 'v1/in/indianschoolstaffinfo/savestaffinfo';
    static COMPONENT_STAFF_IN_DEMOGRAPHICINFO_UPDATE = 'v1/in/indianschoolstaffinfo/updatestaffinfo';
    static COMPONENT_STAFF_IN_DEMOGRAPHICINFO_GETBYID = 'v1/in/indianschoolstaffinfo/getstaffinfobyid?id=';
    static COMPONENT_STAFF_IN_GET_BY_PAGINATION = 'v1/in/indianschoolstaffinfo/getstaffinfopaginated';
    //Credential
    static COMPONENT_CREDENTIAL_SAVE = 'v1/credential/savecredential';
    static COMPONENT_CREDENTIAL_UPDATE = 'v1/credential/updatecredential';
    static COMPONENT_CREDENTIAL_DELETE = 'v1/credential/deletecredential';
    static COMPONENT_CREDENTIAL_GET_BY_ID = 'v1/credential/getcredentialbyid?id=';
    static COMPONENT_CREDENTIAL_GET_ALL = 'v1/credential/getallcredentialbyschoolid?schoolId=';
    static COMPONENT_GET_PERSONAL_TYPE_CREDENTIAL = 'v1/credential/getpersonaltypecredentials?schoolId=';
    static COMPONENT_DELETE_STAFF_CREDENTIAL_DOCUMENT = 'v1/staffcredential/DeleteStaffCredentialDocument';
    //IN - Staff ID Card
    static COMPONENT_GET_STAFF_ID_CARD = "v1/staffidcarddesign/getbyschoolid?schoolId=";
    static COMPONENT_SAVE_STAFF_ID_CARD = "v1/staffidcarddesign/savestaffidcarddesign";
    static COMPONENT_GET_STAFF_ID_CARD_DOWNLOAD = "v1/staffidcardfiles/downloadstaffsidcard";
    static COMPONENT_GET_STAFF_GENERATED_ID_CARD = 'v1/staffidcardfiles/getgroupbystaffidcardbyschoolid?schoolId=';
    static COMPONENT_STAFF_GETALL_BY_SCHOOL_AND_DEPARTMENT = 'v1/staffinfo/getallstaffinfobydepartment';

    static COMPONENT_STAFF_GETALL_MENTOR = "v1/staffinfo/getstaffinfomentor?schoolId=";
    // Staff dependencies
    static COMPONENT_STAFF_GET_DEPENDENCIES_BY_ID = 'v1/staffinfo/getstaffdependenciesbyid?staffId=';
    // Delete Staff
    static COMPONENT_STAFF_DELETE_BY_ID = 'v1/staffinfo/deletestaffwithdependenciesbyid?staffId=';
}
export default commonStaffConfig;