import { Link, Redirect } from 'react-router-dom';
import React from 'react';
import commonConfig from '../../common/commonConfig';
import i18n from './../../../i18n';
import { BaseComponent } from '../../shared/BaseComponent';
import { sessionStore } from '../../common/component/sessionStoreComponent';
import { find } from 'lodash';
let moment = require('moment');
class SidebarComponent extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            relativeUrl: '',
            redirectStatus: false,
            commonRouteFlag: false
        }
    }
    // Active menu selection
    getNavLinkClass = (path, exact = true) => {
        if (exact)
            return this.props.location.pathname === path ? 'active' : '';
        else {
            return ((this.props.location.pathname).indexOf(path) !== -1) ? 'active' : '';
        }
    }

    checkLikeArrayValues = (array, moduleKey) => {
        let flag = false;
        for (var i = 0; i < array.length; i++) {
            if (array[i].indexOf(moduleKey) !== -1) {
                flag = true;
                break;
            } else {
                flag = false;
            }
        }
        return flag;
    }
    handleMenuClick = (e, relativeUrl, menuCode) => {
        if (window.location.pathname !== relativeUrl || (new sessionStore().returnCurrentMenuCode() === 'ORG_SCH_SR' || new sessionStore().returnCurrentMenuCode() === 'ORG_RSD_SR')) {
            let permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
            let currentPagePermissions = { viewPermission: false, editPermission: false, deletePermission: false, approvalPermission: false, verifyPermission: false, certifyPermission: false, publicationPermission: false };
            let checkCurrentUserRoleBySchool = find(new sessionStore().returnSessionSchoolBasedRoleList(), { 'schoolId': new sessionStore().returnSchoolID(), 'roleId': new sessionStore().returnUserRole() });
            if (!!menuCode && !!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Admin') {
                if (menuCode === "SCHL_LIST") {
                    menuCode = "SCHL_GEN_INFO";
                }
                if (permissionList && permissionList.length > 0) {
                    if (permissionList.includes(menuCode + "_VIEW")) {
                        currentPagePermissions.viewPermission = true;
                    }
                    if (permissionList.includes(menuCode + "_EDIT")) {
                        currentPagePermissions.editPermission = true;
                    }
                    if (permissionList.includes(menuCode + "_DELETE")) {
                        currentPagePermissions.deletePermission = true;
                    }
                    if (permissionList.includes(menuCode + "_EDIT_APPR")) {
                        currentPagePermissions.approvalPermission = true;
                    }
                    if (permissionList.includes(menuCode + "_EDIT_VERY")) {
                        currentPagePermissions.verifyPermission = true;
                    }
                    if (permissionList.includes(menuCode + "_EDIT_CERT")) {
                        currentPagePermissions.certifyPermission = true;
                    }
                    if (permissionList.includes(menuCode + "_EDIT_PUBL")) {
                        currentPagePermissions.publicationPermission = true;
                    }
                }
            } else {
                let isAdminPrevSchlSessPermAccess = true;
                let adminPrevSessionPermissionList = new sessionStore().returnAdminSchlPrevSessionPermissionList();   //returns permission list for admin with respect to modifying/Add data in previous sessions
                let checkCurrentSchoolPermissionForAdmin = find(adminPrevSessionPermissionList, ['schoolId', new sessionStore().returnSchoolID()]);
                if (new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_ADMIN && !!checkCurrentSchoolPermissionForAdmin) {
                    let isCurrentSchoolSessionActive = new sessionStore().returnIsCurrentSchoolSessionActive();
                    isAdminPrevSchlSessPermAccess = !!isCurrentSchoolSessionActive ? true : !!checkCurrentSchoolPermissionForAdmin.isEdit ? true : false;
                }
                currentPagePermissions.viewPermission = true;
                currentPagePermissions.editPermission = isAdminPrevSchlSessPermAccess;
                currentPagePermissions.deletePermission = isAdminPrevSchlSessPermAccess;
                currentPagePermissions.approvalPermission = isAdminPrevSchlSessPermAccess;
                currentPagePermissions.verifyPermission = isAdminPrevSchlSessPermAccess;
                currentPagePermissions.certifyPermission = isAdminPrevSchlSessPermAccess;
                currentPagePermissions.publicationPermission = isAdminPrevSchlSessPermAccess;
            }
            new sessionStore().removeSessionItem(commonConfig.SESSION_CURRENT_PAGE_PERMISSION);
            new sessionStore().removeSessionItem(commonConfig.SESSION_MENU_CODE);
            new sessionStore().storeSecureData(commonConfig.SESSION_MENU_CODE, menuCode);
            new sessionStore().storeSecureData(commonConfig.SESSION_CURRENT_PAGE_PERMISSION, JSON.stringify(currentPagePermissions));
            this.setState({ relativeUrl: relativeUrl, redirectStatus: true, commonRouteFlag: (menuCode === 'ORG_SCH_SR' || menuCode === 'ORG_RSD_SR') ? true : false })
        }
    }
    render() {
        if (this.state.redirectStatus && !!this.state.relativeUrl && !this.state.commonRouteFlag) {
            return <Redirect to={{ pathname: this.state.relativeUrl, schoolId: new sessionStore().returnSchoolID() }} />
        }
        if (this.state.commonRouteFlag) {
            return <Redirect to={{
                pathname: "/school/commonRoute",
                state: { redirectPath: this.state.relativeUrl, pathRoute: { 'schoolId': new sessionStore().returnSchoolID() } }
            }} />
        }
        let permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
        let checkCurrentUserRoleBySchool = find(new sessionStore().returnSessionSchoolBasedRoleList(), { 'schoolId': new sessionStore().returnSchoolID(), 'roleId': new sessionStore().returnUserRole() });
        return (
            // Add school Info Sidebar Menu
            <aside className="innerpage-nav">
                <ul>
                    <li><Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/viewSchoolGeneralInfo', 'SCHL_GEN_INFO')}
                        className={this.getNavLinkClass("/school/viewSchoolGeneralInfo")
                            || this.getNavLinkClass("/school/viewGeneralInfo")}>{i18n.t('SCHL_GEN_INFO')}</Link>
                    </li>
                    <li><Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/updateSchoolAddressInfo', 'SCHL_ADDR_INFO')}
                        className={this.getNavLinkClass("/school/viewSchoolAddressInfo")
                            || this.getNavLinkClass("/school/updateSchoolAddressInfo")}>{i18n.t('SCHL_ADDR_INFO')}</Link>
                    </li>
                    <li>
                        <Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/updateSchoolPhoneEmail', 'SCHL_PHN_EML')}
                            className={this.getNavLinkClass("/school/viewSchoolPhoneEmail")
                                || this.getNavLinkClass("/school/updateSchoolPhoneEmail")}>{i18n.t('SCHL_PHN_EML')}</Link>
                    </li>
                    {!!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Student' &&
                        !!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Parent' &&
                        <React.Fragment>
                            {!!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole === 'Teacher' ? '' : <li>
                                <Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/updateSchoolDesignators', 'SCHL_DESIGNATOR')}
                                    className={this.getNavLinkClass("/school/updateSchoolDesignators")
                                        || this.getNavLinkClass("/school/updateSchoolDesignators")}>{i18n.t('SCHL_DESIGNATOR')}</Link>
                            </li>}
                            <li>
                                <Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/updateSchoolIdentifier', 'SCHL_IDENTIFIER')}
                                    className={this.getNavLinkClass("/school/viewSchoolIdentifier")
                                        || this.getNavLinkClass("/school/updateSchoolIdentifier")}>{i18n.t('SCHL_IDENTIFIER')}</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/updateSchoolAccreditations', 'SCHL_ACCREDATIONS')}
                                    className={this.getNavLinkClass("/school/updateSchoolAccreditations")}>{i18n.t('SCHL_ACCREDATIONS')}</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/viewSchoolAdministration', 'SCHL_ADMINSTRATION')}
                                    className={this.getNavLinkClass("/school/viewSchoolAdministration")
                                        || this.getNavLinkClass("/school/updateSchoolAdministration")}>{i18n.t('SCHL_ADMINSTRATION')}</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/viewSchoolLunchProgram', 'SCHL_LPGM')}
                                    className={this.getNavLinkClass("/school/viewSchoolLunchProgram")
                                        || this.getNavLinkClass("/school/updateSchoolLunchProgram")}>{i18n.t('SCHL_LUNCH_PROGRAM')}</Link>
                            </li>
                            {new sessionStore().returnshowSRSchool() ?
                                <li>
                                    <Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/updateSchoolStateReportingComponent', commonConfig.STATE_ORG_SCH_SR)}
                                        className={new sessionStore().returnCurrentMenuCode() === commonConfig.STATE_ORG_SCH_SR || this.getNavLinkClass("/school/updateSchoolStateReportingComponent") ? 'active' : ''}>{i18n.t(commonConfig.STATE_ORG_SCH_SR)}</Link>
                                </li> : null}
                            {new sessionStore().returnshowSRSchoolRes() ?
                                <li>
                                    <Link to="#" onClick={(e) => this.handleMenuClick(e, '/school/updateSchoolResStateReportingComponent', commonConfig.STATE_ORG_RSD_SR)}
                                        className={new sessionStore().returnCurrentMenuCode() === commonConfig.STATE_ORG_RSD_SR || this.getNavLinkClass("/school/updateSchoolResStateReportingComponent") ? 'active' : ''}>{i18n.t(commonConfig.STATE_ORG_RSD_SR)}</Link>
                                </li> : null}
                        </React.Fragment>
                    }

                </ul>
            </aside>
        );
    }
}
export default SidebarComponent;
