import React from "react";
import Validation from './validationComponent';
import i18n from '../../../i18n';
import DatePicker from "react-datepicker";
import sessionStore from './sessionStoreComponent';
import "react-datepicker/dist/react-datepicker.css";
let moment = require('moment');
function MarkersDatepicker(props) {
    const {
        divClassName, onlyField, title, isRequired, isError, name, value, dateFormat, placeholder, onChange, startDate, endDate,
        isRemoveIcon, disabled, readOnly = false, validationType = 'field', validationStart, validationEnd, validationAssociatedField, onlyMonthYearPicker, customValidationMessage
    } = props;
    const format = !!dateFormat ? dateFormat : new sessionStore().returnDateFormat();
    let attribute = !!props.attribute && Object.keys(props.attribute).length !== 0 ? { ...props.attribute } : {}
    let validationObj = { field: !!customValidationMessage ? customValidationMessage : title, errorClass: name + '_error', value: value || '', isError: isError, validationType: validationType === 'onlySRDate' ? 'fieldDynamic' : validationType }
    if (!!validationType && !!validationStart && !!validationEnd) {
        validationObj['validationType'] = validationType;
        validationObj['startDate'] = validationStart;
        validationObj['endDate'] = validationEnd;
    }
    if (!!validationType && !!validationAssociatedField) {
        validationObj['validationType'] = validationType;
        validationObj['associatedField'] = validationAssociatedField;
    }
    attribute = { ...attribute, popperClassName: "reactDatepicker-container" }
    attribute = { ...attribute, className: "form-control reactDatepicker" }
    attribute = { ...attribute, showMonthDropdown: true, showYearDropdown: true, dropdownMode: "select" }
    attribute = { ...attribute, placeholderText: !!placeholder ? placeholder : i18n.t("selectDate") }
    if (!!value && !!new Date(value))
        attribute = { ...attribute, selected: moment(value.slice(0, 10))._d };
    if (!!format)
        attribute = { ...attribute, dateFormat: format.replaceAll('D', 'd').replaceAll('Y', 'y') }
    if (!!startDate)
        attribute = { ...attribute, minDate: moment(startDate.slice(0, 10))._d };
    if (!!endDate)
        attribute = { ...attribute, maxDate: moment(endDate.slice(0, 10))._d };
    if (!!disabled)
        attribute = { ...attribute, disabled }
    if (!!readOnly)
        attribute = { ...attribute, readOnly }
    if (!!onlyMonthYearPicker)
        attribute = { ...attribute, showMonthYearPicker: true }
    if (!!onChange)
        attribute = {
            ...attribute,
            onChange: selectedValue => {
                return !!selectedValue ? onChange({ target: { value: moment(selectedValue).format("YYYY-MM-DD"), name, type: 'date' } }) : null
            },
            onChangeRaw: ({ target }) => {
                if (!!target.value && !readOnly) {
                    let inputValue = target.value.replaceAll(" ", '-').replaceAll("_", '-').replaceAll(",", '-').replaceAll("/", '-');
                    let [split1, split2, split3] = inputValue.split("-");
                    if (!!split1 && !!split2 && !!split3) {
                        let day = "";
                        let month = "";
                        let year = "";
                        inputValue.split("-").forEach(dt => {
                            if (!!Number(dt) && Number(dt) > 12 && dt.length <= 2) day = dt;
                            else if (!!Number(dt) && Number(dt) > 31 && dt.length === 4) year = dt;
                            else if (!!Number(dt) && Number(dt) <= 12) month = dt;
                        });
                        inputValue = `${month}-${day}-${year}`;
                    }
                    let inputDate = moment(inputValue);
                    if (inputDate.isValid() && (!startDate || (!!startDate && inputDate.diff(startDate) >= 0)) && (!endDate || (!!endDate && inputDate.diff(endDate) <= 0))) {
                        onChange({ target: { value: moment(inputDate).format("YYYY-MM-DD"), name, type: 'date' } })
                    }
                }
            },
            onInputClick: () => {
                const inputNode = document.querySelector(".form-control.reactDatepicker");
                if (!!inputNode && !readOnly) inputNode.readOnly = false;
            }
        }
    const fieldResult = !!isRemoveIcon ?
        <div className="input-group">
            <div className="react-datepicker-group">
                <DatePicker {...attribute} />
            </div>
        </div>
        :
        <React.Fragment>
            <div className="input-group">
                <div className="react-datepicker-group" data-testing={`date-picker-${name}`}>
                    <div className="input-group-prepend">
                        <span className="input-group-text"><i className="icon-calendar22"></i></span>
                    </div>
                    <DatePicker {...attribute} popperProps={{ positionFixed: true }} />
                    {!!props.isClearable &&
                        <button type="button" className="react-datepicker__close-icon" onClick={props.clearFunction}></button>
                    }
                </div>
            </div>
            {(!!isRequired || (validationType === 'onlyDate')) &&
                <Validation data={validationObj} />
            }
        </React.Fragment>
    return !!onlyField ?
        fieldResult :
        <div className={!!divClassName ? divClassName : "form-group"}>
            <label className="col-form-label">{title}{!!isRequired && <span> *</span>}</label>
            {fieldResult}
        </div>
}
export default MarkersDatepicker;