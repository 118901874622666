import React from 'react';
import { connect } from 'react-redux';
import { BaseComponent } from '../../shared/BaseComponent';
import { sessionStore, i18n } from '../commonSchoolindex';
class SchoolCommonHeaderComponent extends BaseComponent {
    render() {
        var schoolPhoto = new sessionStore().returnSchoolPhoto();
        return (
            <section className="info-header" data-testing="school-common-header">
                <div className="info-title">
                    <div className="preview-image" data-testing="school-common-header-logo" style={{ backgroundImage: `url(${schoolPhoto})`, backgroundRepeat: 'no-repeat' }}></div>
                    <div className="title">
                        <h2>{new sessionStore().returnSchoolName()}</h2>
                        <p>{new sessionStore().returnSchoolAddress()}</p>
                    </div>
                </div>
                <div className="header-element">
                    <div className="element-info">
                        <h3>{this.props.schoolStudentStaffCountList && this.props.schoolStudentStaffCountList.length > 0 && this.props.schoolStudentStaffCountList[0]}</h3>
                        <p className="text-uppercase">{i18n.t('stud')}</p>
                    </div>
                    <div className="element-info">
                        <h3>{this.props.schoolStudentStaffCountList && this.props.schoolStudentStaffCountList.length > 0 && this.props.schoolStudentStaffCountList[1]}</h3>
                        <p className="text-uppercase">{i18n.t('staff')}</p>
                    </div>
                </div>
            </section>
        )
    }
}
const mapStateToProps = state => {
    return {
        schoolStudentStaffCountList: state.schoolStore.schoolStudentStaffCountList
    }
}
const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SchoolCommonHeaderComponent)
