import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { hostArray } from '../../../hostConfig';
import CryptoAES from 'crypto-js/aes';
import logoUsr from './../../../assets/images/left_panel_logo.png';
import MarkersMenuAccess from '../component/markersMenuAccessComponent';
import StudentPasswordGeneration from './studentPasswordGenerationComponent';
import { find } from 'lodash';
import {
    BaseComponent, ExecuteArgs, i18n, Validation, actionsCommon,
    commonConfig, actions, sessionStore, ConvertDate, actionsStaff, actionsStudent, commonStaffConfig, commonStudentConfig, CommonFuncs,
} from '../../common/commonLoginindex';
import { commonMessageConfig } from "../../messages/commonMessagesIndex";
import base64ImageConfig from '../base64ImageConfig';
import commonSystemConfig from '../../system/commonSystemConfig';
import * as actionsSystem from '../../system/store/actions/index';
import * as actionsMessage from '../../messages/store/actions/index';
let moment = require('moment');
const commonFuncObj = new CommonFuncs();
var SECRET_KEY_NOTIFICATION = '8cfLJmCNUVFZkLcmT916tA9n91eAUepfB2';
const MIN_NOTI_TITLE_LENGTH = 70;
class navbarComponent extends BaseComponent {
    initialFields = {
        password: '',
        changedpassword: '',
        cofirmchangedpassword: '',
        otp: '',
        userId: new sessionStore().returnUserID(),
        userName: new sessionStore().returnUserName()
    }
    initializeDistPasswordFields = {
        districtId: new sessionStore().returnDistrictID(),
        systemKey: "PASSWORD_PATTERN",
        minCharacterLength: null,
        maxCharacterLength: null,
        minOneLowerCase: false,
        minOneUpperCase: false,
        minOneNumericCharacter: false,
        minOneLetter: false,
        minOneSpecialCharacter: false,
        id: 0
    }
    initDelFields = {
        id: null,
        deletionReason: '',
    }
    constructor(props) {
        super(props);
        this.state = {
            fields: { ...this.initialFields },
            delFields: { ...this.initDelFields },
            distfields: { ...this.initializeDistPasswordFields },
            logoutStatus: false,
            isError: 0,
            delError: 0,
            menuListFlag: true,
            notificationList: [],
            notificationListFlag: false,
            showNotificationMsg: "",
            isMarkAsRead: false,
            notifyPathName: "",
            notifyPathObjects: {},
            requiredFields: [
                { fieldName: 'password', fieldType: 'default' },
                { fieldName: 'cofirmchangedpassword', fieldType: 'repeatField', parentFieldName: 'changedpassword' }
            ],
            requiredDelFields: [
                { fieldName: 'deletionReason', fieldType: 'default' }
            ],
            deleteFlag: false,
            isChangePassword: false,
            isShowFullMsgFlag: 0,
            verificationCode: "",
            createdDate: "",
            isGetOtp: false,
            isSendOtp: false,
            menuCode: '',
            redirectStatus: false,
            staffId: null,
            studentId: null,
            isStaffInfo: false,
            isStudentInfo: false,
            navigateFlag: false,
            getPasswordPatternModalFlag: false,
            regex: "",
            studentPasswordGenerationFlag: false,
            notificationMesageType: '',
            deleteId: 0,
            massIds: '',
            massMarkRead: false,
            isDeleteModal: false,
            isCheckBoxSelected: false,
        }
    }
    //This function is called for Student Password Generation Contacts modal show.
    generatePasswordStudentContacts = () => {
        window.$("#StudentPasswordGenerationContacts").modal("show");
    }
    studentPasswordGeneration = (flag, notifiableContacts) => {
        this.setState({ studentPasswordGenerationFlag: flag, notifiableContacts: notifiableContacts })
    }
    checkKeyPressEvent = (e) => {
        if (e.which === 116 || e.keyCode === 116 || ((e.which === 82 || e.keyCode === 82) && (e.which === 17 || e.keyCode === 17))) {
            e.preventDefault();
            this.handleLogout();
        }
    }
    componentDidMount = () => {
        window.$("#delModal").modal({
            backdrop: false,
            keyboard: false,
            show: false
        });
        let currentUrl = window.location.href;
        if (!currentUrl.includes("localhost")) {
            document.onkeydown = (e) => this.checkKeyPressEvent(e);
            document.onkeyup = (e) => this.checkKeyPressEvent(e);
        }
        var windowWidth = window.$(window).innerWidth();
        if (windowWidth > '767') {
            window.$('.mainNavigation').slimscroll({
                height: '100vh'
            });
        }
        //window.$(document).on('click', '#notification', function (e) {
        //    e.preventDefault();
        //    window.$('body').addClass('show-slidepanel-left');
        //});
        window.$('#changePasswordModal').modal({
            backdrop: 'static',
            keyboard: false,
            show: false
        });
        window.$(document).on('click', '.closeSlidepanel, .slidepanel-overlay', function (e) {
            e.preventDefault();
            window.$('body').removeClass('show-slidepanel-left');
            window.$('body').removeClass('show-slidepanel-right');
        });
        this.getNotification();
        this.getMessageCount();
        this.getSentMsg();
        this.getAllReceivedMesssages();
        window.$('div.tip').hide();
        window.$('.newPasswordField').focus(function () {
            window.$('div.tip').show();
        }).focusout(function () {
            window.$('div.tip').hide();
        });
        if (new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_STUDENT && new sessionStore().returnStudentID()) {
            this.getStudentInfo(new sessionStore().returnStudentID())
        } else if (new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_TEACHER && new sessionStore().returnStaffID()) {
            this.getStaffInfo(new sessionStore().returnStaffID())
        } else {
            this.getProfilePictureByUserId();
        }
    };
    getProfilePictureByUserId = () => {
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_GET_PROFILE_PICTURE_BY_USER_ID + new sessionStore().returnUserID(),
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetProfilePictureByUserId(executedArgument);
    }
    getAllReceivedMesssages = () => {
        let executedArgument = new ExecuteArgs(commonMessageConfig.COMPONENT_RESOURCE,
            commonMessageConfig.COMPONENT_RECEIVED_MESSAGES_GETALL,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetAllReceivedMessagesSuccess(executedArgument);
    }
    //This function is used to fetch all messages.
    getSentMsg = () => {
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_GET_MESSAGE_SENT_LIST,
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetSentEmail(executedArgument);
    }
    showNotificationMessageModal = (type) => {
        if (type === 'notification') {
            //for tooltip
            window.$('[data-toggle="tooltip"]').tooltip();
            window.$('[data-toggle="tooltip"]').click(function () {
                window.$(this).tooltip('hide');
            });
            window.$('input[name=selectAll]').each(function () {
                this.checked = false;
            });
            this.setState({ isCheckBoxSelected: false });
            this.props.onResetLoginProps({ name: 'notificationList', data: [] });
            this.setState({ notificationListFlag: true, notificationList: [], notificationMesageType: type, notifyPathName: '' }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_GET_NOTIFICATIONS_V2 + new sessionStore().returnSchoolID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllNotifications(executedArgument);
            });
        } else {
            this.props.onResetProps({ name: 'receiveMessagesList', data: [] });
            this.setState({ notificationMesageType: type, notifyPathName: '/messages/sentMessages' }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_GET_RECEIVE_MESSAGES_LIST + new sessionStore().returnUserRole(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllReceiveMessages(executedArgument);
                window.$('body').addClass('show-slidepanel-left');
                window.$('.scrollWrapper').slimscroll({
                    height: '100vh'
                });
            });
        }
    }
    getNotification = () => {
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_GET_NOTIFICATIONS_V2 + new sessionStore().returnSchoolID(),
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetAllNotifications(executedArgument);
    }
    getMessageCount = () => {
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
            commonConfig.COMPONENT_GET_RECEIVE_MESSAGES_LIST + new sessionStore().returnUserRole() + '&userId=' + new sessionStore().returnUserID(),
            commonConfig.METHOD_TYPE_GET);
        this.props.onGetAllReceiveMessages(executedArgument);
    }
    getStaffInfo = (id) => {
        this.setState({ isStaffInfo: true, isStudentInfo: false }, () => {
            let executedArgument = new ExecuteArgs(commonStaffConfig.COMPONENT_STAFF,
                commonStaffConfig.COMPONENT_STAFF_DEMOGRAPHICINFO_GETBYID + id,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetStaffSucess(executedArgument);
        })
    }
    getStudentInfo = (student_id) => {
        this.setState({ isStudentInfo: true, isStaffInfo: false }, () => {
            let executedArgument = new ExecuteArgs(commonStudentConfig.COMPONENT_STUDENT,
                commonStudentConfig.COMPONENT_STUDENT_GET_BY_ID + student_id,
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetByIdSucess(executedArgument);
        });
    }
    getPasswordPattern = () => {
        this.setState({ getPasswordPatternModalFlag: true }, () => {
            //this.props.onResetProps({ name: 'getSystemPasswordPattern', data: {} })
            let executedArgument = new ExecuteArgs(commonSystemConfig.COMPONENT_CONTROLPANEL,
                commonSystemConfig.COMPONENT_GET_PASSWORD_PATTERN_BY_DISTRICT_ID + new sessionStore().returnDistrictID(),
                commonConfig.METHOD_TYPE_GET);
            this.props.onGetByDistrictIdSuccess(executedArgument);
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.getUserProfilePicture && Object.keys(nextProps.getUserProfilePicture).length > 0) {
            new sessionStore().removeSessionItem(commonConfig.SESSION_PROFILE_PICTURE);
            new sessionStore().storeSecureData(commonConfig.SESSION_PROFILE_PICTURE, nextProps.getUserProfilePicture.thumbnail);
            this.props.onResetProps({ name: 'getUserProfilePicture', data: false })
        }
        if (nextProps.getStaffDemographicInfo && Object.keys(nextProps.getStaffDemographicInfo).length > 0 && this.state.isStaffInfo) {
            new sessionStore().removeSessionItem(commonConfig.SESSION_STAFFNAME);
            new sessionStore().removeSessionItem(commonConfig.SESSION_STAFF_PHOTO);
            new sessionStore().removeSessionItem(commonConfig.SESSION_STAFFID);
            new sessionStore().storeSecureData(commonConfig.SESSION_STAFFID, nextProps.getStaffDemographicInfo.id);
            new sessionStore().storeSecureData(commonConfig.SESSION_STAFFNAME, nextProps.getStaffDemographicInfo.staffFullName);
            new sessionStore().storeSecureData(commonConfig.SESSION_STAFF_PHOTO, nextProps.getStaffDemographicInfo.thumbnail);
        }
        if (nextProps.getStudent && Object.keys(nextProps.getStudent).length > 0 && this.state.isStudentInfo) {
            new sessionStore().removeSessionItem(commonConfig.SESSION_STUDENTVISAFLAG);
            new sessionStore().removeSessionItem(commonConfig.SESSION_STUDENTNAME);
            new sessionStore().removeSessionItem(commonConfig.SESSION_STUDENT_PHOTO);
            new sessionStore().removeSessionItem(commonConfig.SESSION_STUDENTID);
            new sessionStore().removeSessionItem(commonConfig.SESSION_STUDENT_CODE);
            new sessionStore().removeSessionItem(commonConfig.SESSION_STUDENT_GRADE_LEVEL_TITLE);
            new sessionStore().storeSecureData(commonConfig.SESSION_STUDENTID, nextProps.getStudent.id);
            new sessionStore().storeSecureData(commonConfig.SESSION_STUDENTVISAFLAG, nextProps.getStudent.isVisaRequired);
            new sessionStore().storeSecureData(commonConfig.SESSION_STUDENTNAME, nextProps.getStudent.otherFullName);
            new sessionStore().storeSecureData(commonConfig.SESSION_STUDENT_PHOTO, nextProps.getStudent.thumbnail);
            new sessionStore().storeSecureData(commonConfig.SESSION_STUDENT_CODE, nextProps.getStudent.studentCode);
            new sessionStore().storeSecureData(commonConfig.SESSION_STUDENT_GRADE_LEVEL_TITLE, nextProps.getStudent.entryGradeLevelTitle);
        }
        if (nextProps.notificationList && nextProps.notificationList.length !== 0 && this.state.notificationListFlag) {
            this.setState({ notificationList: nextProps.notificationList, notificationListFlag: false }, () => {
                window.$('body').addClass('show-slidepanel-left');
                window.$('.scrollWrapper').slimscroll({
                    height: '100vh'
                });
            })
        }
        if (nextProps.isMarkAsRead && this.state.isMarkAsRead) {
            this.setState({ isMarkAsRead: false }, () => {
                this.setState({ notificationListFlag: true, notificationMesageType: 'notification', notifyPathName: '' }, () => {
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                        commonConfig.COMPONENT_GET_NOTIFICATIONS_V2 + new sessionStore().returnSchoolID(),
                        commonConfig.METHOD_TYPE_GET);
                    this.props.onGetAllNotifications(executedArgument);
                });
            })
        }
        if (nextProps.getMarkRead && this.state.massMarkRead) {
            this.setState({ massMarkRead: false }, () => {
                this.props.onResetLoginProps({ name: 'getMarkRead', data: false });
                this.setState({ notificationListFlag: true, notificationList: [], notificationMesageType: 'notification', notifyPathName: '' }, () => {
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                        commonConfig.COMPONENT_GET_NOTIFICATIONS_V2 + new sessionStore().returnSchoolID(),
                        commonConfig.METHOD_TYPE_GET);
                    this.props.onGetAllNotifications(executedArgument);
                });
            })
        }
        if (nextProps.saveUserPreference && !!nextProps.saveUserPreference.isValid && this.state.isChangePassword) {
            this.setState({ isChangePassword: false, isGetOtp: false, fields: this.initialFields, verificationCode: "" }, () => {
                window.$('#changePasswordModal').modal('hide');
            })
        }
        if (nextProps.getUserPreferenceOtp && !!nextProps.getUserPreferenceOtp.isValid && !!nextProps.getUserPreferenceOtp.verificationCode && this.state.isSendOtp) {
            this.setState({ isSendOtp: false, verificationCode: nextProps.getUserPreferenceOtp.verificationCode, isGetOtp: true })
        }
        if (nextProps.getNotiDel && this.state.deleteFlag) {
            this.props.onResetProps({ name: 'getNotiDel', data: false });
            this.props.onResetLoginProps({ name: 'notificationList', data: [] });
            this.setState({ deleteFlag: false, notificationListFlag: true, notificationList: [], notificationMesageType: 'notification', notifyPathName: '' }, () => {
                this.dismissModal();
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.COMPONENT_GET_NOTIFICATIONS_V2 + new sessionStore().returnSchoolID(),
                    commonConfig.METHOD_TYPE_GET);
                this.props.onGetAllNotifications(executedArgument);
            });
        }
        if (nextProps.getSystemPasswordPattern && Object.keys(nextProps.getSystemPasswordPattern).length !== 0 && this.state.getPasswordPatternModalFlag) {
            let requiredFields = [...this.state.requiredFields];
            let minValue = nextProps.getSystemPasswordPattern.minCharacterLength
            let maxValue = nextProps.getSystemPasswordPattern.maxCharacterLength
            let CHANGE_PASSWORD_REGEX = ""
            if (nextProps.getSystemPasswordPattern.minOneSpecialCharacter) {
                var pattern = '(?=.*[!@#$%^&*])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (nextProps.getSystemPasswordPattern.minOneNumericCharacter) {
                var pattern = '(?=.*[0-9])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (nextProps.getSystemPasswordPattern.minOneUpperCase) {
                var pattern = '(?=.*[A-Z])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (nextProps.getSystemPasswordPattern.minOneLowerCase) {
                var pattern = '(?=.*[a-z])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (nextProps.getSystemPasswordPattern.minOneLetter) {
                var pattern = '(?=.*[A-Za-z])'
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + pattern;
            }
            if (!!nextProps.getSystemPasswordPattern.minCharacterLength && !!nextProps.getSystemPasswordPattern.maxCharacterLength) {
                CHANGE_PASSWORD_REGEX = CHANGE_PASSWORD_REGEX + '[a-zA-Z0-9!@#$%^&*]{' + minValue + ',' + maxValue + '}';
            }
            CHANGE_PASSWORD_REGEX = new RegExp('^' + CHANGE_PASSWORD_REGEX + '$');
            requiredFields.push({ fieldName: 'changedpassword', fieldType: 'password', regExp: CHANGE_PASSWORD_REGEX });
            this.setState({ requiredFields, regex: CHANGE_PASSWORD_REGEX, distfields: nextProps.getSystemPasswordPattern, getPasswordPatternModalFlag: false }, () => {
                window.$('#changePasswordModal').modal('show');
            })
        }
    }
    handleChange = event => {
        let fields = Object.assign({}, this.state.fields);
        fields[event.target.id] = event.target.value;
        this.setState({ fields: fields });
    }
    handleStudentPasswordGeneration = () => {
        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_STUDENT,
            commonConfig.COMPONENT_GENERATE_STUDENT_PASSWORD_SCHEDULER,
            commonConfig.METHOD_TYPE_POST, new sessionStore().returnSchoolID());
        this.props.onGenerateStudentPasswordScheduler(executedArgument);
    }
    handleUserPreference = () => {
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.fields, this.state.requiredFields);
        if (submitStatus === 0) {
            this.setState({ isError: 1 });
        } else {
            if (this.state.isGetOtp) {
                const { userId, password, changedpassword, otp } = this.state.fields;
                if (otp === '') {
                    this.props.onShowNotify(i18n.t('pleaseEnterOtp'), 'error')
                } else if (otp !== this.state.verificationCode) {
                    this.props.onShowNotify(i18n.t('invalidOtp'), 'error')
                } else {
                    this.setState({ isError: 0, isChangePassword: true }, () => {
                        let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                            commonConfig.COMPONENT_RESOURCE_UPDATE_USER_PREFERENCE,
                            commonConfig.METHOD_TYPE_POST, { password: password, changedPassword: changedpassword, sentOTP: otp, isForgotPassword: true, id: userId }
                        );
                        this.props.onUpdateUserPreference(executedArgument);
                    })
                }
            } else {
                this.setState({ isError: 0, isSendOtp: true }, () => {
                    const { userId, password, changedpassword } = this.state.fields;
                    let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                        commonConfig.COMPONENT_RESOURCE_GET_USER_PREFERENCE_OTP,
                        commonConfig.METHOD_TYPE_POST, { userId: userId, currentPassword: password, newPassword: changedpassword });
                    this.props.onGetUserPreferenceOtp(executedArgument);
                })
            }
        }
    }
    //Logout function.
    handleLogout = () => {
        let loginUrl = '';
        if (!!new sessionStore().returnLoginUrl() && Object.keys(new sessionStore().returnLoginUrl()).length > 0 && new sessionStore().returnLoginUrl().loginUrl)
            loginUrl = new sessionStore().returnLoginUrl().loginUrl;
        if (loginUrl === null && new sessionStore().returnHostEnvironment())
            loginUrl = hostArray[new sessionStore().returnHostEnvironment()].home;
        this.setState({ logoutStatus: true, loginUrl });
        if (!loginUrl)
            window.location.reload();
    }
    handleReadNotification = (notificationId, notificationText, locationUrl, createdDate, isDetails = false) => {
        let locationArr = [];
        let pathName = "";
        let menuCode = "";
        let staffId = null;
        let studentId = null;
        let pathObject = {};
        let queryStringArr = [];
        let queryStringValArr = [];
        if (locationUrl !== "") {
            locationArr = locationUrl.split("?");
            if (locationArr.length === 1) {
                pathName = locationArr[0].substring(1, locationArr[0].length - 6);
            }
            if (locationArr.length > 1) {
                pathName = locationArr[0].substring(1);
                queryStringArr = locationArr[1].substring(0, locationArr[1].length - 6).split('&');
                for (let i = 0; i < queryStringArr.length; i++) {
                    queryStringValArr = [];
                    queryStringValArr = queryStringArr[i].split('=');
                    if (isNaN(queryStringValArr[1]))
                        pathObject[queryStringValArr[0]] = queryStringValArr[1];
                    else
                        pathObject[queryStringValArr[0]] = parseInt(queryStringValArr[1]);
                }
                if (pathObject && Object.keys(pathObject).length > 0 && !!pathObject.menuCode) {
                    menuCode = pathObject.menuCode.replace(/'/g, "");
                    new sessionStore().removeSessionItem(commonConfig.SESSION_MENU_CODE);
                    new sessionStore().storeSecureData(commonConfig.SESSION_MENU_CODE, menuCode);
                }
                if (pathObject && pathObject.hasOwnProperty("staffId")) {
                    staffId = pathObject.staffId;
                    this.getStaffInfo(staffId);
                }
                if (pathObject && pathObject.hasOwnProperty("studentId")) {
                    studentId = pathObject.studentId;
                    this.getStudentInfo(studentId);
                }
            }
        }
        pathObject['createdDate'] = createdDate.slice(0, 10);
        pathObject['navigateFlag'] = true
        this.setState({
            isShowFullMsgFlag: this.state.isShowFullMsgFlag === notificationId ? 0 : notificationId, staffId: staffId, studentId: studentId, menuCode: menuCode, showNotificationMsg: notificationText,
            notifyPathName: pathName, notifyPathObjects: pathObject, createdDate: createdDate.slice(0, 10), isMarkAsRead: true
        }, () => {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.COMPONENT_MARKASREAD_NOTIFICATIONS,
                commonConfig.METHOD_TYPE_POST, notificationId);
            this.props.onMarkAsReadNotification(executedArgument);
            locationUrl && locationUrl.length > 1 && isDetails && this.closeNotification();
            //window.$('.noti-info#notificationDetails' + notificationId + ' h6').toggleClass('text-truncate');
            //window.$('#notificationDetails' + notificationId + ' .viewLink').toggleClass('d-block');
        })
    }
    changePasswordModal = () => {
        this.setState({ fields: { ...this.initialFields }, isError: 0 }, () => {
            this.getPasswordPattern();
        })
    }
    closeNotification = () => {
        let permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
        let currentPagePermissions = { viewPermission: false, editPermission: false, deletePermission: false, approvalPermission: false, verifyPermission: false, certifyPermission: false, publicationPermission: false };
        let checkCurrentUserRoleBySchool = find(new sessionStore().returnSessionSchoolBasedRoleList(), ['schoolId', new sessionStore().returnSchoolID()]);
        let menuCode = this.state.menuCode;
        if (!!menuCode && !!checkCurrentUserRoleBySchool && checkCurrentUserRoleBySchool.schoolRole !== 'Admin') {
            if (menuCode === 'STUD_GENERAL') {
                menuCode = 'STUD_GEN_INFO';
            }
            if (menuCode === "SCHL_LIST") {
                menuCode = "SCHL_GEN_INFO";
            }
            if (permissionList && permissionList.length > 0) {
                if (permissionList.includes(menuCode + "_VIEW")) {
                    currentPagePermissions.viewPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT")) {
                    currentPagePermissions.editPermission = true;
                }
                if (permissionList.includes(menuCode + "_DELETE")) {
                    currentPagePermissions.deletePermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_APPR")) {
                    currentPagePermissions.approvalPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_VERY")) {
                    currentPagePermissions.verifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_CERT")) {
                    currentPagePermissions.certifyPermission = true;
                }
                if (permissionList.includes(menuCode + "_EDIT_PUBL")) {
                    currentPagePermissions.publicationPermission = true;
                }
            }
        } else {
            let isAdminPrevSchlSessPermAccess = true;
            let adminPrevSessionPermissionList = new sessionStore().returnAdminSchlPrevSessionPermissionList();   //returns permission list for admin with respect to modifying/Add data in previous sessions
            let checkCurrentSchoolPermissionForAdmin = find(adminPrevSessionPermissionList, ['schoolId', new sessionStore().returnSchoolID()]);
            if (new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_ADMIN && !!checkCurrentSchoolPermissionForAdmin) {
                let isCurrentSchoolSessionActive = new sessionStore().returnIsCurrentSchoolSessionActive();
                isAdminPrevSchlSessPermAccess = !!isCurrentSchoolSessionActive ? true : !!checkCurrentSchoolPermissionForAdmin.isEdit ? true : false;
            }
            currentPagePermissions.viewPermission = true;
            currentPagePermissions.editPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.deletePermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.approvalPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.verifyPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.certifyPermission = isAdminPrevSchlSessPermAccess;
            currentPagePermissions.publicationPermission = isAdminPrevSchlSessPermAccess;
        }
        new sessionStore().removeSessionItem(commonConfig.SESSION_CURRENT_PAGE_PERMISSION);
        new sessionStore().storeSecureData(commonConfig.SESSION_CURRENT_PAGE_PERMISSION, JSON.stringify(currentPagePermissions));
        this.setState({ redirectStatus: true, isStaffInfo: false, isStudentInfo: false })
        window.$("body").removeClass('show-slidepanel-left');
    }
    // stripHtml=(html)=> {
    //var temporalDivElement = document.createElement("div");
    //temporalDivElement.innerHTML = html;
    //return temporalDivElement.textContent || temporalDivElement.innerText || "";
    //}
    handleCloseModal = () => {
        this.setState({ isChangePassword: false, isGetOtp: false, fields: this.initialFields, verificationCode: "", isError: 0, isSendOtp: false }, () => {
            window.$('#changePasswordModal').modal('hide');
        })
    }
    returnSessionItemsForNotification() {
        //CryptoAES.decrypt(schoolId.toString(), SECRET_KEY_NOTIFICATION).toString(CryptoENC);
        return 'token=' + new sessionStore().returnSessionToken() + '&l=' + new sessionStore().returnLanguage() + '&sc=' + CryptoAES.encrypt(new sessionStore().returnSchoolID().toString(), SECRET_KEY_NOTIFICATION) +
            '&se=' + CryptoAES.encrypt(new sessionStore().returnSessionID().toString(), SECRET_KEY_NOTIFICATION) + '&d=' + CryptoAES.encrypt(new sessionStore().returnDistrictID().toString(), SECRET_KEY_NOTIFICATION) +
            '&sy=' + CryptoAES.encrypt(new sessionStore().returnSchoolYear().toString(), SECRET_KEY_NOTIFICATION) + '&m=' + CryptoAES.encrypt(new sessionStore().returnMarkingperiodId().toString(), SECRET_KEY_NOTIFICATION) + '&r=' + CryptoAES.encrypt(new sessionStore().returnUserRole().toString(), SECRET_KEY_NOTIFICATION) +
            '&st=' + CryptoAES.encrypt(new sessionStore().returnStaffID().toString(), SECRET_KEY_NOTIFICATION) + '&u=' + CryptoAES.encrypt(new sessionStore().returnUserID().toString(), SECRET_KEY_NOTIFICATION) +
            '&mc=' + CryptoAES.encrypt(new sessionStore().returnCurrentMenuCode(), SECRET_KEY_NOTIFICATION) +
            '&un=' + CryptoAES.encrypt(new sessionStore().returnUserName(), SECRET_KEY_NOTIFICATION) +
            '&ri=' + CryptoAES.encrypt(new sessionStore().returnUserRoleID().toString(), SECRET_KEY_NOTIFICATION) +
            '&ev=' + CryptoAES.encrypt(new sessionStore().returnHostEnvironment().toString(), SECRET_KEY_NOTIFICATION) +
            '&stu=' + CryptoAES.encrypt(new sessionStore().returnStudentID().toString(), SECRET_KEY_NOTIFICATION) +
            '&sed=' + CryptoAES.encrypt(new sessionStore().returnCurrentSchoolSessionEndDate().toString(), SECRET_KEY_NOTIFICATION) +
            '&sdf=' + CryptoAES.encrypt(new sessionStore().returnDateFormat().toString(), SECRET_KEY_NOTIFICATION)
    }

    handleSelectAll = (event) => {
        window.$("#notificationList").find('input[type=checkbox]').each(function () {
            this.checked = event.target.checked;
        });
        this.setState({ isCheckBoxSelected: event.target.checked })
    }

    handleMassDelete = (e, id = 0) => {
        let messageIds = new Array();
        window.$("#notificationList").find('input[type=checkbox]').each(function () {
            if (this.checked && this.value) {
                messageIds.push(this.value ? parseInt(this.value, 10) : 0)
            }
        })
        if ((messageIds && messageIds.length > 0) || id > 0) {
            this.setState({ deleteId: 1, massIds: id && id > 0 ? id : messageIds && messageIds.length > 0 ? messageIds.join(",") : '' }, () => {
                window.$("#delModal").modal('show');
            })
        } else {
            this.props.onShowNotify(i18n.t("pleaseSelectNotificationString"), "error")
        }
    }

    handleMassRead = () => {
        let messageIds = new Array();
        window.$("#notificationList").find('input[type=checkbox]').each(function () {
            if (this.checked && this.value) {
                messageIds.push(this.value ? parseInt(this.value, 10) : 0)
            }
        })
        if (messageIds && messageIds.length > 0) {
            this.submitMassRead(messageIds);
        } else {
            this.props.onShowNotify(i18n.t("pleaseSelectNotificationString"), "error")
        }
    }

    handleDelChange = (e) => {
        let fields = Object.assign({}, this.state.delFields);
        fields[e.target.name] = e.target.value;
        this.setState({ delFields: fields });
    }

    submitMassRead = (id) => {
        let ids = Array.isArray(id) ? id.join(",") : id;
        this.setState({ massMarkRead: true }, () => {
            let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                commonConfig.MASS_READ_MESSAGE + ids,
                commonConfig.METHOD_TYPE_POST);
            this.props.onMarkRead(executedArgument);
        })
    }

    handleDelete = (id, success = false) => {
        const validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.delFields, this.state.requiredDelFields);
        if (submitStatus === 0) {
            this.setState({ delError: 1 })
        } else {
            this.setState({ deleteFlag: true, delError: 0 }, () => {
                let executedArgument = new ExecuteArgs(commonConfig.COMPONENT_RESOURCE,
                    commonConfig.MASS_DELETE_MESSAGE + this.state.massIds,
                    commonConfig.METHOD_TYPE_POST);
                this.props.onDelSuccess(executedArgument);
            })
        }
    }

    dismissModal = () => {
        this.setState({ delFields: { ...this.initDelFields }, deleteId: 0, delError: 0 }, () => { });
        window.$("#delModal").modal('hide');
    }

    truncateFunc = (text, type, truncate) => {
        switch (type) {
            case 'icon':
                return text.length > MIN_NOTI_TITLE_LENGTH ? true : false;
            case 'text':
                if (truncate) {
                    let returnContent = text.substring(0, MIN_NOTI_TITLE_LENGTH);
                    returnContent += returnContent.length < MIN_NOTI_TITLE_LENGTH ? "" : "...";
                    return returnContent;
                } else {
                    return text;
                }
        }
    }

    render() {
        let checkCurrentUserRoleBySchool = find(new sessionStore().returnSessionSchoolBasedRoleList(), { 'schoolId': new sessionStore().returnSchoolID(), 'roleId': new sessionStore().returnUserRole() });
        let permissionList = new sessionStore().returnSessionRoleBasedPermissionList();
        if (this.state.logoutStatus) {
            var n = sessionStorage.length;
            while (n--) {
                var key = sessionStorage.key(n);
                new sessionStore().removeSessionItem(key);
            }
            if (this.state.loginUrl) {
                window.location.href = this.state.loginUrl;
                {/* return <Redirect to= '/sso.development.us.markerspro.com' />*/ }
            }
            else
                return <Redirect to="/login" />
        }
        if (this.state.redirectStatus && !!this.state.notifyPathName) {
            var currentHost = new sessionStore().returnHostEnvironment();
            var redirectUrl = this.state.notifyPathName;
            if (this.state.notifyPathName.includes(commonConfig.IS_BILLING) === true || this.state.notifyPathName.includes(commonConfig.IS_TRANSPORT) === true) {
                let redirectPath = hostArray[currentHost].billing;
                if (this.state.notifyPathName.includes(commonConfig.IS_TRANSPORT) === true) {
                    redirectPath = hostArray[currentHost].transport;
                }
                redirectUrl = redirectPath + this.state.notifyPathName;
                if (this.state.notifyPathName === window.location.pathname) { //for redirection from same page
                    return <Redirect to={{
                        pathname: '/school/commonRoute',
                        schoolId: new sessionStore().returnSchoolID(),
                        state: { redirectPath: redirectUrl, pathRoute: this.state.notifyPathObjects, }
                    }} />
                } else {
                    redirectUrl = redirectUrl + '?' + this.returnSessionItemsForNotification();
                    window.location.href = redirectUrl;
                }
            } else {
                return <Redirect to={{
                    pathname: '/school/commonRoute',
                    schoolId: new sessionStore().returnSchoolID(),
                    state: { redirectPath: redirectUrl, pathRoute: this.state.notifyPathObjects, }
                }} />
            }
        }


        const getCustomRole = () => find(this.props.schoolBasedRoleList, ['id', parseInt(new sessionStore().returnUserRole(), 10)]);

        const schoolRoles = {
            [commonConfig.SCHOOL_ROLE_ADMIN]: i18n.t('admin'),
            [commonConfig.SCHOOL_ROLE_TEACHER]: i18n.t('teacher'),
            [commonConfig.SCHOOL_ROLE_STUDENT]: i18n.t('student'),
            [commonConfig.SCHOOL_ROLE_PARENT]: i18n.t('parent'),
            [commonConfig.SCHOOL_ROLE_ACCOUNTANT]: i18n.t('accountant'),
            [commonConfig.SCHOOL_ROLE_COUNCILLOR]: i18n.t('councilor'),
            [commonConfig.SCHOOL_ROLE_HR]: i18n.t('HR'),
            [commonConfig.SCHOOL_ROLE_TRANSPORTATION_ADMIN]: i18n.t('Transportation_ADMIN'),
            [commonConfig.SCHOOL_ROLE_SUPPORT]: i18n.t('support'),
            [commonConfig.STAFF_ROLE_DRIVER]: i18n.t('Transportation_DRIVER'),
            [commonConfig.STAFF_ROLE_CONDUCTOR]: i18n.t('Transportation_CONDUCTOR'),
            [commonConfig.SCHOOL_ROLE_CUSTOM]: getCustomRole() ? getCustomRole().schoolRoleTitle : ''
        }

        return (
            <React.Fragment>
                {/* <!--Main side bar start-->*/}
                {/* <!--Main side bar start-->*/}
                <nav className="leftNavigation">
                    {/* <!--leftNavpanel side bar start--> */}
                    <div className="leftNavpanel">
                        {/* <!--main ul start--> */}
                        <ul>
                            <li className="d-block d-md-none menu-icon">
                                <Link to="#"><i className="icon-grid2 "></i></Link>
                            </li>
                            <li className="user-profile">
                                <Link to="#">
                                    <img src={logoUsr} alt="" className="border-0" />
                                </Link>
                            </li>
                            <li className="separator"></li>
                            <li className="user-profile dropdown">
                                <Link to="#" className="" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    {/* <img src={base64ImageConfig.USER_DEFAULT_IMG} alt="" width="30"  className="rounded-circle" /> */}
                                    <img src={new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_STUDENT && new sessionStore().returnStudentPhoto() ? new sessionStore().returnStudentPhoto() :
                                        new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_TEACHER && new sessionStore().returnStaffPhoto() ? new sessionStore().returnStaffPhoto() : !!new sessionStore().returnProfilePicture() ? new sessionStore().returnProfilePicture() : base64ImageConfig.USER_DEFAULT_IMG}
                                        alt="" width="30" className="rounded-circle" />
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <Link className="dropdown-item f-s-13" to="#">
                                        <b>
                                            {this.state.fields.userName}<span>&nbsp;</span>
                                            {!!new sessionStore().returnUserRoleID() && <span>({schoolRoles[new sessionStore().returnUserRoleID()]})</span>}
                                        </b>
                                    </Link>
                                    {/* <Link className="dropdown-item f-s-13" to="#">{i18n.t('settings')}</Link>*/}
                                    <Link className="dropdown-item f-s-13" to="#" onClick={() => this.changePasswordModal()}>{i18n.t('changePassword')}</Link>
                                    {/*<Link className="dropdown-item f-s-13" to="#">{i18n.t('help')}</Link>*/}
                                    <Link className="dropdown-item f-s-13" to="#" onClick={this.handleLogout}>{i18n.t('logout')}</Link>
                                </div>
                            </li>
                            <li className="separator"></li>
                            <li className="d-block d-md-none">
                                <Link to="#" id="mobile-search">
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 451 451"
                                        style={{ enableBackground: "new 0 0 451 451" }} xmlSpace="preserve">
                                        <g>
                                            <path d="M447.05,428l-109.6-109.6c29.4-33.8,47.2-77.9,47.2-126.1C384.65,86.2,298.35,0,192.35,0C86.25,0,0.05,86.3,0.05,192.3
                                            s86.3,192.3,192.3,192.3c48.2,0,92.3-17.8,126.1-47.2L428.05,447c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4
                                            C452.25,441.8,452.25,433.2,447.05,428z M26.95,192.3c0-91.2,74.2-165.3,165.3-165.3c91.2,0,165.3,74.2,165.3,165.3
                                            s-74.1,165.4-165.3,165.4C101.15,357.7,26.95,283.5,26.95,192.3z"
                                                fill="#ffffff" />
                                        </g>
                                    </svg>
                                </Link>
                            </li>
                            {(new sessionStore().returnUserSchoolMultiRoleList() && new sessionStore().returnUserSchoolMultiRoleList().length > 0) ?
                                <li>
                                    <Link to="#" id="switchProfile" onClick={(e) => commonFuncObj.showModal('switchProfileModal')}>
                                        <svg id="regular" enableBackground="new 0 0 24 24" style={{ enableBackground: "new 0 0 229.238 229.238" }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path d="m18.25 6h-3.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h2.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v3.5c0 .414-.336.75-.75.75z" /></g><g><path d="m5.75 13c-.414 0-.75-.336-.75-.75v-3.5c0-.414.336-.75.75-.75h3.5c.414 0 .75.336.75.75s-.336.75-.75.75h-2.75v2.75c0 .414-.336.75-.75.75z" /></g><g><path d="m5.921 6.31c-.058 0-.115-.006-.173-.02-.403-.095-.653-.499-.559-.902.749-3.172 3.549-5.388 6.811-5.388 3.095 0 5.854 2.073 6.711 5.042.114.398-.115.814-.513.929-.401.114-.814-.115-.929-.513-.671-2.331-2.838-3.958-5.269-3.958-2.562 0-4.763 1.74-5.35 4.232-.082.345-.389.578-.729.578z" /></g><g><path d="m12 14c-3.095 0-5.854-2.073-6.711-5.042-.114-.398.115-.814.513-.929.399-.118.813.115.928.513.672 2.331 2.839 3.958 5.27 3.958 2.562 0 4.763-1.74 5.35-4.232.097-.404.507-.65.902-.558.403.095.653.499.559.902-.749 3.172-3.549 5.388-6.811 5.388z" /></g><g><path d="m4.5 19c-1.379 0-2.5-1.122-2.5-2.5s1.121-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.121 2.5-2.5 2.5zm0-3.5c-.552 0-1 .449-1 1s.448 1 1 1 1-.449 1-1-.448-1-1-1z" /></g><g><path d="m8.25 24c-.414 0-.75-.336-.75-.75v-.5c0-.689-.561-1.25-1.25-1.25h-3.5c-.689 0-1.25.561-1.25 1.25v.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.5c0-1.517 1.233-2.75 2.75-2.75h3.5c1.517 0 2.75 1.233 2.75 2.75v.5c0 .414-.336.75-.75.75z" /></g><g><path d="m19.5 19c-1.379 0-2.5-1.122-2.5-2.5s1.121-2.5 2.5-2.5 2.5 1.122 2.5 2.5-1.121 2.5-2.5 2.5zm0-3.5c-.552 0-1 .449-1 1s.448 1 1 1 1-.449 1-1-.448-1-1-1z" /></g><g><path d="m23.25 24c-.414 0-.75-.336-.75-.75v-.5c0-.689-.561-1.25-1.25-1.25h-3.5c-.689 0-1.25.561-1.25 1.25v.5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-.5c0-1.517 1.233-2.75 2.75-2.75h3.5c1.517 0 2.75 1.233 2.75 2.75v.5c0 .414-.336.75-.75.75z" /></g></svg>
                                    </Link>
                                </li> : null}
                            <li>
                                <Link to="#" id="notification" onClick={() => this.showNotificationMessageModal('notification')}>
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 229.238 229.238"
                                        style={{ enableBackground: "new 0 0 229.238 229.238" }} xmlSpace="preserve">
                                        <path d="M220.228,172.242c-20.606-17.82-39.675-42.962-39.675-105.734c0-36.353-29.576-65.928-65.93-65.928
                                        c-36.359,0-65.939,29.575-65.939,65.928c0,62.829-19.062,87.946-39.686,105.751C3.28,177.239,0,184.411,0,191.937
                                        c0,4.142,3.358,7.5,7.5,7.5h71.175c3.472,16.663,18.268,29.222,35.944,29.222s32.473-12.558,35.944-29.222h71.175
                                        c4.142,0,7.5-3.358,7.5-7.5C229.238,184.35,225.95,177.167,220.228,172.242z M114.619,213.659c-9.34,0-17.321-5.929-20.381-14.222
                                        H135C131.94,207.73,123.959,213.659,114.619,213.659z M17.954,184.437c0.273-0.296,0.564-0.578,0.871-0.845
                                        c31.443-27.146,44.858-62.162,44.858-117.084c0-28.082,22.852-50.928,50.939-50.928c28.083,0,50.93,22.846,50.93,50.928
                                        c0,54.872,13.417,89.887,44.876,117.091c0.307,0.265,0.598,0.544,0.872,0.838H17.954z" />
                                    </svg>
                                    <span className="badge badge-warning">{this.props.notificationList && this.props.notificationList.length > 0 ? this.props.notificationList[0].unreadCount : 0}</span>
                                </Link>
                            </li>
                            {/*this is for web message start*/}
                            {(new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_STUDENT || new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_PARENT) &&
                                (!!permissionList && (permissionList.includes("COMM_SEML_VIEW") || permissionList.includes("COMM_SMSG_VIEW"))) &&
                                <li className="dropdown">
                                    <Link to="#" id="webMessage" className="web_msg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="icon icon-envelop2 f-s-20 text-white"></i>
                                        <span className="badge badge-warning">{!!this.props.messageReceivedMessagesList && !!this.props.messageReceivedMessagesList.concat(!!this.props.messageSendEmailList && this.props.messageSendEmailList) && this.props.messageReceivedMessagesList.concat(this.props.messageSendEmailList).length > 0 ? this.props.messageReceivedMessagesList.concat(this.props.messageSendEmailList).length : 0}</span>
                                    </Link>
                                    <div className="dropdown-menu" aria-labelledby="webMessage">
                                        {(!!permissionList && permissionList.includes("COMM_SEML_VIEW")) &&
                                            <Link to="/messages/sentEmail" className="dropdown-item f-s-13 d-flex justify-content-between border-bottom">
                                                <div className="mr-2"><i className="icon icon-envelop5 mr-2"></i>
                                                    {i18n.t('email')}</div>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className="badge dropdown-badge bg-success text-white">{!!this.props.messageSendEmailList && this.props.messageSendEmailList.length > 0 ? this.props.messageSendEmailList.length : 0}</span>
                                                </div>
                                            </Link>}
                                        {(!!permissionList && permissionList.includes("COMM_SMSG_VIEW")) &&
                                            <Link to="/messages/sentMessages" id="webMessage" className="dropdown-item f-s-13 d-flex justify-content-between">
                                                <div className="mr-2"><i className="icon icon-bubble-lines3 mr-2"></i>
                                                    {i18n.t('messages')}</div>
                                                <div className="d-flex align-items-center justify-content-center">
                                                <span className="badge dropdown-badge bg-success text-white ml-2">{!!this.props.messageReceivedMessagesList && this.props.messageReceivedMessagesList.length > 0 ? this.props.messageReceivedMessagesList.length : 0}</span></div>
                                            </Link>}
                                    </div>
                                </li>}
                            {/*this is for web message end*/}
                            {
                                new sessionStore().returnUserRoleID() === commonConfig.SCHOOL_ROLE_ADMIN ?
                                    <li className="dropdown">
                                        <Link to="" className="" id="quickAddBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 42 42"
                                                style={{ enableBackground: "new 0 0 42 42" }} xmlSpace="preserve">
                                                <polygon points="42,19 23,19 23,0 19,0 19,19 0,19 0,23 19,23 19,42 23,42 23,23 42,23 " />
                                            </svg>
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="quickAddBtn">
                                            <Link to="/school/addSchool/general" className="dropdown-item f-s-13">{i18n.t('addASchool')}</Link>
                                            <Link to="/student/addStudent/general" className="dropdown-item f-s-13">{i18n.t('addAStudent')}</Link>
                                            <Link to="/staff/addStaff/demographic" className="dropdown-item f-s-13">{i18n.t('addStaff')}</Link>
                                        </div>
                                    </li> : ''}
                            {(!!checkCurrentUserRoleBySchool && (checkCurrentUserRoleBySchool.schoolRole === 'Admin' || (checkCurrentUserRoleBySchool.schoolRole !== 'Admin' && (permissionList && permissionList.length > 0 && !!permissionList.includes('SETT_GEN_PWD_STUD_VIEW'))))) &&
                                <li className="bottom-list dropup">
                                    <Link to="" className="" id="quickSettings" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 478.703 478.703"
                                            style={{ enableBackground: "new 0 0 478.703 478.703" }} xmlSpace="preserve">
                                            <path
                                                d="M454.2,189.101l-33.6-5.7c-3.5-11.3-8-22.2-13.5-32.6l19.8-27.7c8.4-11.8,7.1-27.9-3.2-38.1l-29.8-29.8
                                                c-5.6-5.6-13-8.7-20.9-8.7c-6.2,0-12.1,1.9-17.1,5.5l-27.8,19.8c-10.8-5.7-22.1-10.4-33.8-13.9l-5.6-33.2
                                                c-2.4-14.3-14.7-24.7-29.2-24.7h-42.1c-14.5,0-26.8,10.4-29.2,24.7l-5.8,34c-11.2,3.5-22.1,8.1-32.5,13.7l-27.5-19.8
                                                c-5-3.6-11-5.5-17.2-5.5c-7.9,0-15.4,3.1-20.9,8.7l-29.9,29.8c-10.2,10.2-11.6,26.3-3.2,38.1l20,28.1
                                                c-5.5,10.5-9.9,21.4-13.3,32.7l-33.2,5.6c-14.3,2.4-24.7,14.7-24.7,29.2v42.1c0,14.5,10.4,26.8,24.7,29.2l34,5.8
                                                c3.5,11.2,8.1,22.1,13.7,32.5l-19.7,27.4c-8.4,11.8-7.1,27.9,3.2,38.1l29.8,29.8c5.6,5.6,13,8.7,20.9,8.7c6.2,0,12.1-1.9,17.1-5.5
                                                l28.1-20c10.1,5.3,20.7,9.6,31.6,13l5.6,33.6c2.4,14.3,14.7,24.7,29.2,24.7h42.2c14.5,0,26.8-10.4,29.2-24.7l5.7-33.6
                                                c11.3-3.5,22.2-8,32.6-13.5l27.7,19.8c5,3.6,11,5.5,17.2,5.5l0,0c7.9,0,15.3-3.1,20.9-8.7l29.8-29.8c10.2-10.2,11.6-26.3,3.2-38.1
                                                l-19.8-27.8c5.5-10.5,10.1-21.4,13.5-32.6l33.6-5.6c14.3-2.4,24.7-14.7,24.7-29.2v-42.1
                                                C478.9,203.801,468.5,191.501,454.2,189.101z M451.9,260.401c0,1.3-0.9,2.4-2.2,2.6l-42,7c-5.3,0.9-9.5,4.8-10.8,9.9
                                                c-3.8,14.7-9.6,28.8-17.4,41.9c-2.7,4.6-2.5,10.3,0.6,14.7l24.7,34.8c0.7,1,0.6,2.5-0.3,3.4l-29.8,29.8c-0.7,0.7-1.4,0.8-1.9,0.8
                                                c-0.6,0-1.1-0.2-1.5-0.5l-34.7-24.7c-4.3-3.1-10.1-3.3-14.7-0.6c-13.1,7.8-27.2,13.6-41.9,17.4c-5.2,1.3-9.1,5.6-9.9,10.8l-7.1,42
                                                c-0.2,1.3-1.3,2.2-2.6,2.2h-42.1c-1.3,0-2.4-0.9-2.6-2.2l-7-42c-0.9-5.3-4.8-9.5-9.9-10.8c-14.3-3.7-28.1-9.4-41-16.8
                                                c-2.1-1.2-4.5-1.8-6.8-1.8c-2.7,0-5.5,0.8-7.8,2.5l-35,24.9c-0.5,0.3-1,0.5-1.5,0.5c-0.4,0-1.2-0.1-1.9-0.8l-29.8-29.8
                                                c-0.9-0.9-1-2.3-0.3-3.4l24.6-34.5c3.1-4.4,3.3-10.2,0.6-14.8c-7.8-13-13.8-27.1-17.6-41.8c-1.4-5.1-5.6-9-10.8-9.9l-42.3-7.2
                                                c-1.3-0.2-2.2-1.3-2.2-2.6v-42.1c0-1.3,0.9-2.4,2.2-2.6l41.7-7c5.3-0.9,9.6-4.8,10.9-10c3.7-14.7,9.4-28.9,17.1-42
                                                c2.7-4.6,2.4-10.3-0.7-14.6l-24.9-35c-0.7-1-0.6-2.5,0.3-3.4l29.8-29.8c0.7-0.7,1.4-0.8,1.9-0.8c0.6,0,1.1,0.2,1.5,0.5l34.5,24.6
                                                c4.4,3.1,10.2,3.3,14.8,0.6c13-7.8,27.1-13.8,41.8-17.6c5.1-1.4,9-5.6,9.9-10.8l7.2-42.3c0.2-1.3,1.3-2.2,2.6-2.2h42.1
                                                c1.3,0,2.4,0.9,2.6,2.2l7,41.7c0.9,5.3,4.8,9.6,10,10.9c15.1,3.8,29.5,9.7,42.9,17.6c4.6,2.7,10.3,2.5,14.7-0.6l34.5-24.8
                                                c0.5-0.3,1-0.5,1.5-0.5c0.4,0,1.2,0.1,1.9,0.8l29.8,29.8c0.9,0.9,1,2.3,0.3,3.4l-24.7,34.7c-3.1,4.3-3.3,10.1-0.6,14.7
                                                c7.8,13.1,13.6,27.2,17.4,41.9c1.3,5.2,5.6,9.1,10.8,9.9l42,7.1c1.3,0.2,2.2,1.3,2.2,2.6v42.1H451.9z" />
                                            <path
                                                d="M239.4,136.001c-57,0-103.3,46.3-103.3,103.3s46.3,103.3,103.3,103.3s103.3-46.3,103.3-103.3S296.4,136.001,239.4,136.001
                                                z M239.4,315.601c-42.1,0-76.3-34.2-76.3-76.3s34.2-76.3,76.3-76.3s76.3,34.2,76.3,76.3S281.5,315.601,239.4,315.601z" />
                                        </svg>
                                    </Link>
                                    <div className="dropdown-menu" aria-labelledby="quickSettings">
                                        <Link to="#" onClick={() => this.studentPasswordGeneration(true, false)} className="dropdown-item f-s-13">{i18n.t('generateStudentPassword')}</Link>
                                        <Link to="#" onClick={() => this.studentPasswordGeneration(true, true)} className="dropdown-item f-s-13">{i18n.t('generatePasswordStudentContacts')}</Link>
                                    </div>
                                </li>}
                            <li className="bottom-list">
                                {/*<Link to="#">
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                        style={{ enableBackground: "new 0 0 512 512" }} xmlSpace="preserve">
                                        <circle cx="256" cy="378.5" r="25" />
                                        <path d="M256,0C114.516,0,0,114.497,0,256c0,141.484,114.497,256,256,256c141.484,0,256-114.497,256-256
                                C512,114.516,397.503,0,256,0z M256,472c-119.377,0-216-96.607-216-216c0-119.377,96.607-216,216-216
                                c119.377,0,216,96.607,216,216C472,375.377,375.393,472,256,472z" />
                                        <path
                                            d="M256,128.5c-44.112,0-80,35.888-80,80c0,11.046,8.954,20,20,20s20-8.954,20-20c0-22.056,17.944-40,40-40
                                c22.056,0,40,17.944,40,40c0,22.056-17.944,40-40,40c-11.046,0-20,8.954-20,20v50c0,11.046,8.954,20,20,20
                                c11.046,0,20-8.954,20-20v-32.531c34.466-8.903,60-40.26,60-77.469C336,164.388,300.112,128.5,256,128.5z" />
                                    </svg>
                                </Link>*/}
                            </li>
                        </ul>
                        {/* <!--main ul end--> */}
                    </div>
                    {/* <!--leftNavpanel side bar end--> */}
                    {/* <!--rightNavpanel side bar start--> */}
                    <aside className="rightNavpanel">
                        <div className="mainNavigation p-t-15">
                            <Link to="#" className="miniArrowSidebar show-opensidebar">
                                <span className="m-r-15"></span> <p className="d-none d-sm-block d-md-none">{i18n.t('navigation')}</p>
                            </Link>
                            {/*District Logo to be available on MP Dashboard*/}
                            {!!new sessionStore().returnDistrictLogo() && new sessionStore().returnIsDashBoard() ?
                                <div className="districtLogo mx-3 mb-3">
                                    <Link to="#" className="text-center d-block pl-0">
                                        <img src={new sessionStore().returnDistrictLogo()} />
                                    </Link>
                                </div>
                                : null}
                            {/* <!--main ul start--> */}
                            <ul id="accordionSidenav">
                                {this.props.dynamicMenus && this.props.dynamicMenus.length > 0 &&
                                    //menuAccessComponentObj.returnMenuItems(this.props.menuList)
                                    <MarkersMenuAccess navBarList={this.props.dynamicMenus} navbarRedirect={true} />
                                }
                            </ul>
                            {/* <!--main ul end--> */}
                        </div>
                    </aside>
                    {/* <!--rightNavpanel side bar start--> */}
                    <Link to="#" className="miniArrowSidebar">
                        <span></span>
                    </Link>
                </nav>
                <div className="slidepanel slidepanel-left">
                    <div className="slidepanel-inner">
                        <div className="sidepanel-header d-flex justify-content-between">
                            {/*<h5 className="m-0">{this.state.notificationMesageType === 'notification' ? i18n.t('notifications') : i18n.t('BOTM_MSGS')}</h5>*/}
                            {/*<Link to="#" className="f-s-16 text-muted" className="closeSlidepanel text-muted"><i className="icon-cross"></i></Link>*/}
                            {/*static part start*/}
                            <div className="d-flex align-items-center">
                                <label className="checkbox-block checkbox-block mt-0 mb-0 mr-2">
                                    <input name="selectAll" id="" type="checkbox" value="1" onChange={(e) => this.handleSelectAll(e)} />
                                    <span className="checkmark"></span>
                                </label>
                                <h5 className="m-0">{this.state.notificationMesageType === 'notification' ? i18n.t('notifications') : i18n.t('BOTM_MSGS')}</h5>
                                {this.state.isCheckBoxSelected && <Link to="#" className="text-primary ml-4 mr-3" data-toggle="tooltip" data-placement="top" title={i18n.t('delete')} onClick={(e) => this.handleMassDelete(e)}>
                                    <i className="icon icon-bin2"></i>
                                </Link>}
                                {this.state.isCheckBoxSelected && <Link to="#" className="text-primary" data-toggle="tooltip" data-placement="top" title={i18n.t('markAsRead')} onClick={() => this.handleMassRead()}>
                                    <i className="icon icon-envelop5"></i>
                                </Link>}
                            </div>
                            <Link to="#" className="f-s-16 text-muted closeSlidepanel text-muted">
                                <i className="icon-cross"></i>
                            </Link>
                            {/*static part end*/}
                        </div>
                        <div className="notification-list" id="notificationList">
                            <div className="scrollWrapper">
                                {this.state.notificationMesageType === 'notification' ?
                                    this.state.notificationList.length !== 0 && this.state.notificationList.map((notification, i) => {
                                        var notificationText = "";
                                        notificationText = notification.messageText.split('<a href=');
                                        return <React.Fragment>
                                            <div className="mt-1 mb-3 clearfix noti-main-wrap">
                                                <div className="noticheckbox">
                                                    <label className="checkbox-block checkbox-block mt-0 mb-0">
                                                        <input name="" id="" type="checkbox" value={notification.id} onChange={(e) => this.setState({ isCheckBoxSelected: e.target.checked })} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className={`d-flex noti-wrap ${notification.hasRead == false ? "noti-unread" : ''}`}>
                                                    {notification.hasRead == false ? <i className="icon icon-bell3 text-primary mr-3 mt-1"></i> : <span style={{ marginLeft: "30px" }}></span>}
                                                    <div id={"notificationDetails" + notification.id} className="noti-text-wrap">
                                                        <h6 className={this.state.isShowFullMsgFlag !== notification.id ? `mb-0 f-s-14 text-black-primary d-block ${notification.hasRead == false ? "font-weight-bold" : ''}` : `mb-0 f-s-14 text-black-primary d-block${notification.hasRead == false ? "font-weight-bold" : ''}`} dangerouslySetInnerHTML={{ __html: this.truncateFunc(notificationText[0], "text", this.state.isShowFullMsgFlag !== notification.id) }}>
                                                        </h6>
                                                        <p className="text-muted f-s-12 mb-0">{<ConvertDate calDate={notification.createdOn} />}</p>
                                                    </div>
                                                    <div className="noti-action-btns">
                                                        {this.truncateFunc(notificationText[0], "icon") ? <Link to="#" className="text-muted ml-2" onClick={() => this.handleReadNotification(notification.id, notificationText[0], notificationText.length > 1 ? notificationText[1] : "", notification.createdOn)}>
                                                            <i className="icon icon-arrow-down3 f-s-13"></i>
                                                        </Link> : null}
                                                        <Link to="#" className="text-danger ml-2" onClick={(e) => this.handleMassDelete(e, notification.id)}>
                                                            <i className="icon icon-bin2 f-s-13"></i>
                                                        </Link>
                                                        <Link to="#" className="text-muted ml-2" data-toggle="tooltip" data-placement="top" title={i18n.t('viewDetails')} onClick={() => this.handleReadNotification(notification.id, notificationText[0], notificationText.length > 1 ? notificationText[1] : "", notification.createdOn, true)}>
                                                            <i className="icon icon-arrow-right14 f-s-13"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*static part end*/}
                                        </React.Fragment>
                                    })
                                    :
                                    !!this.props.receiveMessagesList && this.props.receiveMessagesList.length > 0 ?
                                        this.props.receiveMessagesList.map((data, key) => {
                                            let colorArray = ["avatar-circle bg-danger-800", "avatar-circle bg-green-800", "avatar-circle bg-warning-800", "avatar-circle bg-pink-800", "avatar-circle bg-blue-800", "avatar-circle bg-purple-800", "avatar-circle bg-info-800"]
                                            return <div className="media mb-3 clearfix" key={key}>
                                                <div className="media-object media_round_bx">
                                                    {!!data.thumbnail ?
                                                        <img alt="" className="rounded-circle img-fluid" src={data.thumbnail} />
                                                        :
                                                        <div className={colorArray[Math.floor(Math.random() * colorArray.length)]}><span className="initialsName">AS</span></div>
                                                    }
                                                </div>
                                                <div className="media-body">
                                                    <div className="d-flex align-items-start">
                                                        <div id="notificationDetails3854181" className="noti-info">
                                                            <h6 className="mb-0 f-s-14 text-black-primary d-block">
                                                                <b>{data.senderName || commonConfig.DASH_VALUE}</b>
                                                            </h6>
                                                            <p className="m-0" dangerouslySetInnerHTML={{ __html: !!data.messageText ? data.messageText.length > 60 ? data.messageText.substring(0, 70) + '[...]' : data.messageText : commonConfig.DASH_VALUE }}></p>
                                                            <Link className="viewLink d-block mt-1 mb-2" onClick={() => this.closeNotification()} to="#">
                                                                <u>{i18n.t('viewDetails')}</u>
                                                            </Link>
                                                        </div>
                                                        <Link className="text-muted ml-3" to="#">
                                                            <i className="icon-arrow-down3 f-s-13" />
                                                        </Link>
                                                    </div>
                                                    <p className="text-muted f-s-12 mb-1">
                                                        {!!data.createdOn ? <ConvertDate calDate={data.createdOn} /> : commonConfig.DASH_VALUE}
                                                    </p>
                                                </div>
                                            </div>
                                        }) :
                                        <div className="panel-default mt-3">
                                            <div className="text-center panel-body">
                                                <i className="text-danger icon-info2 m-r-5"></i> {i18n.t('noDataFound')}
                                            </div>
                                        </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slidepanel-overlay"></div>
                {/* <!--Change Password Modal--> */}
                <div className="modal fade" id="changePasswordModal">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" >
                            <div className="modal-header">
                                <h5 className="modal-title">{i18n.t("changePassword")}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {!this.state.isGetOtp &&
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">{i18n.t("currentPassword")}</label>
                                                    <input type="password" id="password" className="form-control" value={this.state.fields.password} placeholder={i18n.t("enterCurrentPassword")} onChange={(e) => this.handleChange(e)} />
                                                    <Validation data={{
                                                        field: i18n.t('password'), errorClass: 'passwordError',
                                                        value: this.state.fields.password, isError: this.state.isError, validationType: 'field'
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">{i18n.t("newPassword")} <span>*</span></label>
                                                    <input type="password" id="changedpassword" className="form-control newPasswordField" value={this.state.fields.changedpassword} placeholder={i18n.t("enterNewPassword")} onChange={(e) => this.handleChange(e)} />
                                                    <Validation data={{
                                                        field: i18n.t('newPassword'), errorClass: 'newPasswordError', regExp: this.state.regex, minLength: this.state.distfields.minCharacterLength,
                                                        maxLength: this.state.distfields.maxCharacterLength, value: this.state.fields.changedpassword, isError: this.state.isError, validationType: 'password'
                                                    }} />
                                                </div>
                                                <div className="tip text-muted f-s-14">
                                                    <p className="m-0">{i18n.t('psasswordShouldHave')}:</p>
                                                    <ol className="p-l-15">
                                                        <li>Minimum {this.state.distfields.minCharacterLength} char and Maximum {this.state.distfields.maxCharacterLength}</li>
                                                        {this.state.distfields.minOneLowerCase && <li>{i18n.t('minOneSmallLowercaseLetter')}</li>}
                                                        {this.state.distfields.minOneUpperCase && <li>{i18n.t('minOneCapitalUppercaseLetter')}</li>}
                                                        {this.state.distfields.minOneNumericCharacter && <li>{i18n.t('minOneNumericChar')}</li>}
                                                        {this.state.distfields.minOneLetter && <li>{i18n.t('minOneLetter')}</li>}
                                                        {this.state.distfields.minOneSpecialCharacter && <li>{i18n.t('minOneSpecialChar')}</li>}
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group mb-2">
                                                    <label className="col-form-label">{i18n.t("confirmNewPassword")} <span>*</span></label>
                                                    <input type="password" id="cofirmchangedpassword" onChange={(e) => this.handleChange(e)} value={this.state.fields.cofirmchangedpassword} className="form-control" placeholder={i18n.t("confirmNewPassword")} />
                                                    <Validation data={{
                                                        field: i18n.t('confirmNewPassword'), errorClass: 'cofirmPasswordError', isError: this.state.isError,
                                                        value: this.state.fields.cofirmchangedpassword, parentFieldValue: this.state.fields.changedpassword, validationType: 'repeatField'
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {this.state.isGetOtp &&
                                    <React.Fragment>
                                        <div className="row align-items-center">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">{i18n.t("otp")} <span>*</span></label>
                                                    <input type="password" value={this.state.otp} onChange={(e) => this.setState({ fields: { ...this.state.fields, otp: e.target.value } })} className="form-control" placeholder={i18n.t("enterOtp")} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <Link to="#" onClick={() => { this.setState({ isGetOtp: false }, () => { this.handleUserPreference() }) }}><i className="icon-rotate-ccw3 mr-2"></i> <u>{i18n.t("resendOtp")}</u></Link>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <p><i className="icon-info2 mr-2"></i>{i18n.t("otpValidation")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="modal-footer pt-0">
                                <button type="button" className="btn btn-success" onClick={() => this.handleUserPreference()}>{this.state.isGetOtp ? i18n.t("continue") : i18n.t('sendOtp')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Add Student Password Generation Modal --> */}
                {!!this.state.studentPasswordGenerationFlag &&
                    <StudentPasswordGeneration
                        changeStudentPasswordGenerationFlag={this.studentPasswordGeneration}
                        notifiableContacts={this.state.notifiableContacts}
                    />}
                {this.state.deleteId === 1 &&
                    <React.Fragment>
                        <div id="delModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="deleteModalTitle" aria-modal="true" >
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body text-center pb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="100" viewBox="0 0 64 64" width="100"><g id="Delete_File" data-name="Delete File"><path d="m47.66 13.67a1.72 1.72 0 0 0 -1.72-1.67h-39.57a1.72 1.72 0 0 0 -1.72 1.72v3.39a1.74 1.74 0 0 0 1.72 1.72h39.57a1.74 1.74 0 0 0 1.72-1.72z" fill="#808b95" fill-rule="evenodd" /><path d="m30.78 52.92h-18.64a3.47 3.47 0 0 1 -3.48-3.47v-30.67h35v18.22z" fill="#c6d0da" /><g fill="#808b95" fill-rule="evenodd"><path d="m17.79 27.07a1.69 1.69 0 0 0 -.51-1.22 1.72 1.72 0 0 0 -1.22-.5h-.43a1.72 1.72 0 0 0 -1.73 1.72v17.56a1.74 1.74 0 0 0 1.73 1.73h.43a1.73 1.73 0 0 0 1.22-.51 1.69 1.69 0 0 0 .51-1.22z" /><path d="m28.1 27.07a1.69 1.69 0 0 0 -.51-1.22 1.72 1.72 0 0 0 -1.22-.5h-.43a1.72 1.72 0 0 0 -1.73 1.72v17.56a1.74 1.74 0 0 0 1.73 1.73h.43a1.73 1.73 0 0 0 1.22-.51 1.69 1.69 0 0 0 .51-1.22z" /><path d="m38.41 27.07a1.69 1.69 0 0 0 -.51-1.22 1.72 1.72 0 0 0 -1.22-.5h-.43a1.72 1.72 0 0 0 -1.22.5 1.69 1.69 0 0 0 -.51 1.22v17.56a1.69 1.69 0 0 0 .48 1.22 1.73 1.73 0 0 0 1.22.51h.43a1.73 1.73 0 0 0 1.22-.51 1.69 1.69 0 0 0 .51-1.22z" /></g><path d="m17 4a1.89 1.89 0 0 0 -1.89 1.9v6h4v-4.25h14v4.35h4v-6a1.91 1.91 0 0 0 -1.83-2z" fill="#5e6b75" /><path d="m41.71 34.9a1.42 1.42 0 0 1 2.42 0l13.92 23a1.41 1.41 0 0 1 -1.21 2.1h-27.84a1.4 1.4 0 0 1 -1.2-2.14c3.1-5.13 10.9-18 13.91-22.96z" fill="#f9f48e" /><path d="m45.29 43.17c0 1.31-1.06 7.63-2.37 7.63s-2.37-6.32-2.37-7.63a2.37 2.37 0 1 1 4.74 0z" fill="#df75a5" /><path d="m44.47 55a1.55 1.55 0 1 1 -1.55-1.55 1.55 1.55 0 0 1 1.55 1.55z" fill="#df75a5" /></g></svg>
                                        <h5 className="modal-title mt-4 mb-2" id="exampleModalCenterTitle"><b>{i18n.t("Are You Sure?")}</b></h5>
                                        <p className="m-0 f-s-16 text-muted">{i18n.t("Once deleted, you will not be able to recover this record!")}</p>
                                    </div>
                                    <div className="modal-body">
                                        <div>
                                            <label className="col-form-label">{i18n.t("deletionReason")}</label>
                                            <textarea name="deletionReason" className="resize-none form-control" placeholder={i18n.t("enterDeletionReason")} value={this.state.delFields.deletionReason || ''} style={{ height: "126px" }} onChange={(e) => this.handleDelChange(e)}></textarea>
                                        </div>
                                        <Validation data={{
                                            field: i18n.t("deletionReason"),
                                            errorClass: 'deletion_reason_error',
                                            value: this.state.delFields.deletionReason,
                                            isError: this.state.delError,
                                            validationType: 'field'
                                        }} returnValidation={this.functionValidation} />
                                    </div>
                                    <div className="modal-body pt-3 text-center">
                                        <button type="button" className="btn btn-secondary mr-2" onClick={() => { this.dismissModal() }}>{i18n.t('noCancel')}</button>
                                        <button type="button" className="btn btn-danger" id="yesBtn" onClick={() => this.handleDelete()}>{i18n.t('yesdelete')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>}
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        menuList: state.loginStore.menuList,
        notificationList: state.loginStore.notificationList,
        isMarkAsRead: state.loginStore.isMarkAsRead,
        saveUserPreference: state.loginStore.saveUserPreference,
        getUserPreferenceOtp: state.loginStore.getUserPreferenceOtp,
        getStaffDemographicInfo: state.staffStore.getStaffDemographicInfo,
        getStudent: state.studentStore.getStudent,
        schoolBasedRoleList: state.schoolStore.schoolBasedRoleList,
        getSystemPasswordPattern: state.systemStore.getSystemPasswordPattern,
        getUserProfilePicture: state.commonStore.getUserProfilePicture,
        receiveMessagesList: state.commonStore.receiveMessagesList,
        getDistrictGeneralInfo: state.districtStore.getDistrictGeneralInfo,
        getMarkRead: state.commonStore.getMarkRead,
        getNotiDel: state.commonStore.getNotiDel,
        messageSendEmailList: state.messageStore.messageSendEmailList,
        messageReceivedMessagesList: state.messageStore.messageReceivedMessagesList
    };
}
const mapDispatchToProps = dispatch => {
    return {
        onGetAllNotifications: (executeArgs) => dispatch(actions.getAllNotifications(executeArgs)),
        onGetProfilePictureByUserId: (executeArgs) => dispatch(actionsCommon.callCommonGetByApi(executeArgs, 'user profile picture')),
        onMarkAsReadNotification: (executeArgs) => dispatch(actions.updateMarkAsReadNotification(executeArgs)),
        onUpdateUserPreference: (executeArgs) => dispatch(actions.saveUserPreference(executeArgs)),
        onGetUserPreferenceOtp: (executeArgs) => dispatch(actions.getUserPreferenceOtp(executeArgs)),
        onGenerateStudentPasswordScheduler: (executeArgs) => dispatch(actions.generateStudentPasswordScheduler(executeArgs)),
        onShowNotify: (message, type) => dispatch(actionsCommon.showNotify(message, type)),
        onGetStaffSucess: (executeArgs) => dispatch(actionsStaff.callStaffGetByApi(executeArgs, 'demographic info')),
        onGetByIdSucess: (executeArgs) => dispatch(actionsStudent.callStudentGetByApi(executeArgs, '')),
        onResetLoginProps: (executeArgs) => dispatch(actions.loginStoreStateReset(executeArgs)),
        onGetByDistrictIdSuccess: (executeArgs) => dispatch(actionsSystem.callSystemGetByApi(executeArgs, 'Password Pattern')),
        onResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs)),
        onGetAllReceiveMessages: (executeArgs) => dispatch(actionsCommon.callCommonListApi(executeArgs, 'receive messages')),
        onMarkRead: executeArgs => dispatch(actionsCommon.callCommonGetByApi(executeArgs, 'mark read')),
        onDelSuccess: executeArgs => dispatch(actionsCommon.callCommonGetByApi(executeArgs, 'noti del')),
        onGetSentEmail: (executeArgs) => dispatch(actionsMessage.callMessageListApi(executeArgs, 'SendEmail')),
        onGetAllReceivedMessagesSuccess: (executeArgs) => dispatch(actionsMessage.callMessageListApi(executeArgs, 'received messages'))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(navbarComponent);