import React from 'react';
import { connect } from 'react-redux';
import BaseComponent from '../../shared/BaseComponent'
import ExecuteArgs from '../../shared/model/executeArgs';
import i18n from '../../../i18n';
import Validation from './validationComponent';
import commonConfig from '../../common/commonConfig';
import * as actionsCommon from '../../shared/store/actions/index';
class MarkersDelete extends BaseComponent {
    initialFields = {
        id: null,
        deletionReason: ''
    }
    constructor(props) {
        super(props);
        this.state = {
            fields: { ...this.initialFields },
            requiredFields: [
                { fieldName: 'deletionReason', fieldType: 'default' }
            ],
            isError: 0,
            isDelete: false,
            showDeleteNotify: false,
            hideDeleteReason: false,
            showDeleteReason: true
        }
        if (props.useDeleteSuccessRef) this.setDeleteSuccessRef = React.createRef(props.setDeleteSuccess);
    }
    componentDidMount = () => {
        window.$("#deleteModal").on('hidden.bs.modal', () => {
            if (window.$('.modal:visible').length > 0) {
                window.$('body').addClass('modal-open');
            }
            let selector = document.querySelectorAll('.modal-backdrop');
            !!selector && selector.length > 0 && selector.forEach(e => e.remove());
            this.setState({ fields: this.initialFields, showDeleteNotify: false, isError: 0 }, () => {
                this.props.onResetProps({ name: 'commonDelete', data: false });
                this.props.useDeleteSuccessRef ? this.setDeleteSuccessRef.current(null) : this.props.setDeleteSuccess(null);
            });
        });
    }
    handleChange = (event) => {
        let fields = Object.assign({}, this.state.fields);
        fields[event.target.name] = event.target.value.replace(/^\s+/g, '');    //trims space at start
        this.setState({ fields });
    }
    componentWillReceiveProps(nextProps) {
        if (!!nextProps.setDeleteSuccess && this.props.useDeleteSuccessRef) {
            this.setDeleteSuccessRef.current = nextProps.setDeleteSuccess
        }
        if (!!nextProps.deleteId || (this.props.hasOwnProperty('isStatic') && !!nextProps.isStatic && nextProps.deleteId === 0)) {
            this.setState({ fields: { ...this.initialFields, id: nextProps.deleteId }, showDeleteReason: nextProps.hasOwnProperty('showDeletionReason') ? nextProps.showDeletionReason : true, requiredFields: nextProps.hasOwnProperty('showDeletionReason') ? [] : this.state.requiredFields, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('show');
            });
        }
        if (!!nextProps.deleteId && nextProps.getParamFlag) {
            let fields = Object.assign({}, this.initialFields);
            fields.getParamFlag = nextProps.getParamFlag;
            fields.param1 = nextProps.studentId;
            fields.param2 = nextProps.deleteId;
            fields.param3 = nextProps.sessionId;
            fields.param4 = nextProps.studentCourseRequestCourseId;
            fields.param5 = nextProps.coursepartId;
            delete fields.id
            delete fields.deletionReason
            this.setState({ fields: fields, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('show');
            });
        }

        if (!!nextProps.publishTranscriptFlag && !!nextProps.deleteId) {
            let fields = { ...this.initialFields };
            fields.publishTranscriptFlag = nextProps.publishTranscriptFlag;
            fields.studentTranscriptIds = (nextProps.deleteId).toString();
            fields.isPublish = nextProps.isPublished;
            delete fields.id

            this.setState({ fields: fields, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('show');
            });
        }
        if (!!nextProps.isPublishReportCard && !!nextProps.deleteId) {
            let fields = { ...this.initialFields };
            fields.isPublishReportCard = nextProps.isPublishReportCard;
            fields.studentReportCardIds = (nextProps.deleteId).toString();

            delete fields.id
            this.setState({ fields: fields, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('show');
            });
        }
        if (nextProps.studentTranscriptFlag && !!nextProps.deleteId) {
            let fields = { ...this.initialFields };
            fields.studentTestFormatId = nextProps.deleteId;

            this.setState({ fields: fields, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('show');
            });
        }
        if (!!nextProps.studentTestDataFlag && !!nextProps.deleteId) {
            let fields = { ...this.initialFields };
            fields.studentId = nextProps.studentId
            fields.id = nextProps.deleteId

            fields.studentTestDataFlag = nextProps.studentTestDataFlag
            this.setState({ fields: fields, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('show');
            });
        }

        //test format delete
        if (!!nextProps.testFormatDeleteFlag && !!nextProps.deleteId) {
            let fields = { ...this.initialFields };
            fields.id = nextProps.deleteId

            fields.testFormatDeleteFlag = nextProps.testFormatDeleteFlag
            this.setState({ fields: fields, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('show');
            });
        }

        //district Assoc
        if (!!nextProps.districtAssocCourseCategoryFlag && !!nextProps.deleteObj && !!nextProps.deleteObj.associateCategoryId && !!nextProps.deleteObj.courseId) {
            let tempFields = {};
            tempFields = nextProps.deleteObj;
            tempFields.districtAssocCourseCategoryFlag = nextProps.districtAssocCourseCategoryFlag
            this.setState({ fields: tempFields, isError: 0, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('show');
            });
        }
        if ((nextProps.commonDelete || (!!nextProps.commonDelete && Object.keys(nextProps.commonDelete).length !== 0 && !!nextProps.commonDelete.isValid)) && this.state.isDelete) {
            this.setState({ fields: this.initialFields, isDelete: false, showDeleteNotify: true, hideDeleteReason: nextProps.twoStepDelete }, () => {
                window.$("#deleteModal").modal('hide');
                this.props.setDeleteSuccess(null, true);
            });
        }
    }
    handleShowDeleteReason = event => {
        event.preventDefault();
        this.setState({ hideDeleteReason: false })
    }
    dismissModal = event => {
        event.preventDefault();
        window.$("#deleteModal").modal('hide');
        if (this.props.hasOwnProperty("setCloseModalSuccess") && !!this.props.setCloseModalSuccess) {
            this.props.setCloseModalSuccess();
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        let validationObj = new Validation();
        let submitStatus = validationObj.fieldValidation(this.state.fields, this.state.requiredFields);
        let fields = Object.assign({}, this.state.fields);
        if (submitStatus === 0) {
            this.setState({ isError: 1 });
        } else if (this.props.hasOwnProperty('isStatic') && !!this.props.isStatic) {
            this.setState({ isError: 0, isDelete: false }, () => {
                window.$("#deleteModal").modal('hide');
                this.props.setDeleteSuccess(null, true);
            });
        }
        else if (this.state.fields.publishTranscriptFlag) {

            delete fields.publishTranscriptFlag;
            delete fields.deletionReason;

            this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint, commonConfig.METHOD_TYPE_POST, this.state.fields);
                this.props.onDeleteSuccess(executedArgument);
            });

        }
        else if (!!this.state.fields.studentTestDataFlag) {
            fields.deleteReason = fields.deletionReason
            fields.studentId = fields.studentId
            delete fields.studentTestDataFlag
            delete fields.deletionReason

            this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint, commonConfig.METHOD_TYPE_POST, this.state.fields);
                this.props.onDeleteSuccess(executedArgument);
            });
        }
        else if (!!this.state.fields.testFormatDeleteFlag) {
            fields.type = this.props.testFormatType
            delete fields.testFormatDeleteFlag
            this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint, commonConfig.METHOD_TYPE_POST, this.state.fields);
                this.props.onDeleteSuccess(executedArgument);
            });
        }
        else if (this.state.fields.associateCategoryId && this.state.fields.courseId && !!this.state.fields.districtAssocCourseCategoryFlag) {
            let fields = Object.assign({}, this.state.fields);
            delete fields.districtAssocCourseCategoryFlag;
            this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint, commonConfig.METHOD_TYPE_POST, fields);
                this.props.onDeleteSuccess(executedArgument);
            });
        }
        else if (this.state.fields.studentTestFormatId) {
            this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint + '?studentTestFormatId=' + fields.studentTestFormatId, commonConfig.METHOD_TYPE_POST);
                this.props.onDeleteSuccess(executedArgument);
            });

        }
        else if (this.state.fields.isPublishReportCard && this.state.fields.studentReportCardIds) {
            this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint + '?studentReportCardIds=' + fields.studentReportCardIds, commonConfig.METHOD_TYPE_GET);
                this.props.onDeleteSuccess(executedArgument);
            });
        }
        else if (this.state.fields.param4 && this.state.fields.param5 && this.state.fields.param3 && this.state.fields.getParamFlag) {
            this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint + '?StudentId=' + fields.param1 + '&SessionId=' + fields.param3 + '&StudentCourseRequestCourseId=' + fields.param4 + '&CoursePartId=' + fields.param5, commonConfig.METHOD_TYPE_POST);
                this.props.onDeleteSuccess(executedArgument);
            });

        } else if (!!this.state.fields.id || this.state.fields.getParamFlag) {
            if (this.state.fields.getParamFlag && !!this.props.studentCourseSectionId) {
                this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                    let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint + '?StudentId=' + fields.param1 + '&CourseSectionId=' + this.props.courseSectionId + '&StudentCourseSectionId=' + this.props.studentCourseSectionId, commonConfig.METHOD_TYPE_POST);
                    this.props.onDeleteSuccess(executedArgument);
                });
            }
            else if (this.state.fields.getParamFlag) {
                this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                    let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint + '?StudentId=' + fields.param1 + '&CourseSectionId=' + fields.param3 + '&StudentCourseSectionId=' + fields.param4, commonConfig.METHOD_TYPE_POST);
                    this.props.onDeleteSuccess(executedArgument);
                });
            }
            else {
                if (this.props.deleteId === 'bulkDelete' && (!!this.props.bulkDeleteData && this.props.bulkDeleteData.length > 0)) {
                    this.setState({ isError: 0, isDelete: true }, () => {
                        let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint, commonConfig.METHOD_TYPE_POST, this.props.bulkDeleteData);
                        this.props.onDeleteSuccess(executedArgument);
                        window.$("#deleteModal").modal('hide');
                    });
                } else {
                    this.setState({ isError: 0, isDelete: true, fields: fields }, () => {
                        let executedArgument = new ExecuteArgs(this.props.componentName, this.props.endPoint, this.props.hasOwnProperty('methodType') && this.props.methodType ? this.props.methodType : commonConfig.METHOD_TYPE_POST, !this.props.hasOwnProperty('methodType') && this.state.fields);
                        this.props.onDeleteSuccess(executedArgument);
                        window.$("#deleteModal").modal('hide');
                    });
                }
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <div id="deleteModal" className="modal fade systemModal-v2" data-testing={this.props.dataTesting} data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content px-4">
                            {!this.props.isPublishReportCard &&
                                <div className="modal-body pt-4 px-0 pb-0">
                                    <div className="text-center mb-3">
                                        {!!this.props.svg && this.props.hasOwnProperty('svg') ? this.props.svg :
                                            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="50" height="50" className="svg-danger"><path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z"></path><path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z"></path><path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"></path></svg>
                                        }
                                    </div>
                                    {this.props.deleteMessage ? this.props.deleteMessage : <React.Fragment>
                                        <h5 className="modal-title mt-4 mb-2" id="exampleModalCenterTitle"><b>{i18n.t("Are You Sure?")}</b></h5>
                                        <p className="m-0 f-s-16 text-muted">{i18n.t("Once deleted, you will not be able to recover this record!")}</p>
                                    </React.Fragment>}
                                </div>
                            }
                            {!!this.props.isPublishReportCard &&
                                <div>
                                    <div className="modal-body text-center pb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="100" viewBox="0 0 64 64" width="100"><g id="Delete_File" data-name="Delete File"><path d="m47.66 13.67a1.72 1.72 0 0 0 -1.72-1.67h-39.57a1.72 1.72 0 0 0 -1.72 1.72v3.39a1.74 1.74 0 0 0 1.72 1.72h39.57a1.74 1.74 0 0 0 1.72-1.72z" fill="#808b95" fill-rule="evenodd" /><path d="m30.78 52.92h-18.64a3.47 3.47 0 0 1 -3.48-3.47v-30.67h35v18.22z" fill="#c6d0da" /><g fill="#808b95" fill-rule="evenodd"><path d="m17.79 27.07a1.69 1.69 0 0 0 -.51-1.22 1.72 1.72 0 0 0 -1.22-.5h-.43a1.72 1.72 0 0 0 -1.73 1.72v17.56a1.74 1.74 0 0 0 1.73 1.73h.43a1.73 1.73 0 0 0 1.22-.51 1.69 1.69 0 0 0 .51-1.22z" /><path d="m28.1 27.07a1.69 1.69 0 0 0 -.51-1.22 1.72 1.72 0 0 0 -1.22-.5h-.43a1.72 1.72 0 0 0 -1.73 1.72v17.56a1.74 1.74 0 0 0 1.73 1.73h.43a1.73 1.73 0 0 0 1.22-.51 1.69 1.69 0 0 0 .51-1.22z" /><path d="m38.41 27.07a1.69 1.69 0 0 0 -.51-1.22 1.72 1.72 0 0 0 -1.22-.5h-.43a1.72 1.72 0 0 0 -1.22.5 1.69 1.69 0 0 0 -.51 1.22v17.56a1.69 1.69 0 0 0 .48 1.22 1.73 1.73 0 0 0 1.22.51h.43a1.73 1.73 0 0 0 1.22-.51 1.69 1.69 0 0 0 .51-1.22z" /></g><path d="m17 4a1.89 1.89 0 0 0 -1.89 1.9v6h4v-4.25h14v4.35h4v-6a1.91 1.91 0 0 0 -1.83-2z" fill="#5e6b75" /><path d="m41.71 34.9a1.42 1.42 0 0 1 2.42 0l13.92 23a1.41 1.41 0 0 1 -1.21 2.1h-27.84a1.4 1.4 0 0 1 -1.2-2.14c3.1-5.13 10.9-18 13.91-22.96z" fill="#f9f48e" /><path d="m45.29 43.17c0 1.31-1.06 7.63-2.37 7.63s-2.37-6.32-2.37-7.63a2.37 2.37 0 1 1 4.74 0z" fill="#df75a5" /><path d="m44.47 55a1.55 1.55 0 1 1 -1.55-1.55 1.55 1.55 0 0 1 1.55 1.55z" fill="#df75a5" /></g></svg>
                                        {this.props.deleteMessage ? this.props.deleteMessage : <React.Fragment><h5 className="modal-title mt-4 mb-2" id="exampleModalCenterTitle"><b>{i18n.t("Are You Sure?")}</b></h5><p className="m-0 f-s-16 text-muted">{i18n.t("Once deleted, you will not be able to recover this record!")}</p></React.Fragment>}
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mb-3 card flex-row overflow-hidden shadow-none" style={{ backgroundColor: "#f4e0d7" }}>
                                            <div className="bg-warning-800 p-15 mr-3 align-items-center d-flex">
                                                <i className="icon icon-warning2 f-s-20 text-white"></i>
                                            </div>
                                            <div className="text-black-primary  p-10 p-l-0 align-items-center flex-grow-1 d-flex">
                                                If all the generated report cards associated with Test 1 run is deleted, then that run data would be eradicitad.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {!this.state.hideDeleteReason && this.state.showDeleteReason ?
                                <div className="modal-body px-0 py-2">
                                    <div>
                                        {!!this.props.label && this.props.hasOwnProperty('label') ? this.props.label : <label className="col-form-label">{i18n.t("deletionReason")}</label>}
                                        <textarea name="deletionReason" className="resize-none form-control" placeholder={this.props.placeholder ? this.props.placeholder : i18n.t("enterDeletionReason")} value={this.state.fields.deletionReason || ''} style={{ height: 100 }} onChange={(e) => this.handleChange(e)}></textarea>
                                    </div>
                                    <Validation data={{
                                        field: i18n.t("deletionReason"),
                                        errorClass: 'deletion_reason_error',
                                        value: this.state.fields.deletionReason,
                                        isError: this.state.isError,
                                        validationType: 'field'
                                    }} returnValidation={this.functionValidation} />
                                </div> : null
                            }
                            <div className="modal-footer border-top pt-3 mt-2 px-0 d-flex align-items-center justify-content-between">
                                <button type="button" className="btn w-100 mr-2 text-primary" onClick={this.dismissModal}>{i18n.t('noCancel')}</button>
                                <button type="button" className="btn btn-primary w-100 deleteBtn" id="yesBtn" onClick={this.state.hideDeleteReason ? this.handleShowDeleteReason : this.handleSubmit}>{i18n.t('yesdelete')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        commonDelete: state.commonStore.commonDelete
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onDeleteSuccess: (executeArgs) => dispatch(actionsCommon.callCommonDeleteApi(executeArgs, 'common')),
        onResetProps: (executeArgs) => dispatch(actionsCommon.commonStoreStateReset(executeArgs)),
        onShowNotify: (message, type) => dispatch(actionsCommon.showNotify(message, type))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarkersDelete)
