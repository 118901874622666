import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sessionStore } from '../components/common/component/sessionStoreComponent';
import commonConfig from '../components/common/commonConfig';
import CommonFuncs from '../components/common/component/markersCommonFunctionComponent';
import MarkersNotify from '../components/common/component/markersNotify';
import Swal from 'sweetalert2';
import * as actionTypesShared from '../components/shared/store/actions/actionTypesShared';
import * as actionsCommon from '../components/shared/store/actions/index';
import { SharedApiConfig } from '../components/shared/sharedApiConfig';
import i18n from './../i18n';
const axios = require('axios');
const commonFuncObj = new CommonFuncs();
var loadCount = 0;
class Intercepter extends Component {
    state = {
        isShowNotify: false,
        messageType: true,
        responseMessage: ''
    }
    componentWillMount() {
        axios.interceptors.request.use(function (config) {
            let currentUrl = window.location.href;
            //Hold DT Search...
            let tempData = commonFuncObj.IsJsonString(config.data) ? JSON.parse(config.data) : null;
            let filterAPIList = commonFuncObj.returnFilterAPIs();
            let filterAPIDetailsArray = [];
            if (tempData && tempData.method === "GET") {
                let tempStr = tempData.endPoint;
                tempStr = tempStr.split("?");
                if (tempStr.length > 1) {
                    let includedAPI = (filterAPIList.filter(str => str.includes(tempStr[0])));
                    if (includedAPI && includedAPI.length > 0) {
                        if (new sessionStore().returnFilterAPIDetails())
                            filterAPIDetailsArray = [...new sessionStore().returnFilterAPIDetails()];
                        let tempFilterAPIObj = filterAPIDetailsArray.find(o => o.endPointName === tempStr[0]);
                        if (tempFilterAPIObj) {
                            if (tempFilterAPIObj.endPointDetails != commonFuncObj.returnFilterPayloads(tempData.endPoint)) {
                                let filterRouteList = commonFuncObj.returnFilterRoutes();
                                let tempPath = window.location.href;
                                tempPath = tempPath.split("/");
                                if (tempPath.length > 2) {
                                    let currentRoute = tempPath[tempPath.length - 2] + '/' + tempPath[tempPath.length - 1];
                                    let includedRoutes = (filterRouteList.filter(str => str.includes(currentRoute)));
                                    if (filterRouteList && includedRoutes && includedRoutes.length > 0) {
                                        let filterDetailsArray = [];
                                        if (new sessionStore().returnFilterDetails())
                                            filterDetailsArray = [...new sessionStore().returnFilterDetails()];
                                        let tempFilterObj = filterDetailsArray.find(o => o.routeName === currentRoute);
                                        if (tempFilterObj) {
                                            let tempFilterIndex = filterDetailsArray.findIndex(x => x.routeName === currentRoute);
                                            tempFilterObj.searchValue = ''
                                            tempFilterObj.paginationValue = '';
                                            filterDetailsArray[tempFilterIndex] = tempFilterObj;
                                        }
                                        new sessionStore().storeSecureData(commonConfig.SESSION_DT_FILTER_DETAILS, filterDetailsArray);
                                    }
                                }
                                let tempFilterAPIIndex = filterAPIDetailsArray.findIndex(y => y.endPointName === tempStr[0]);
                                tempFilterAPIObj.endPointDetails = commonFuncObj.returnFilterPayloads(tempData.endPoint);
                                filterAPIDetailsArray[tempFilterAPIIndex] = tempFilterAPIObj;
                            }
                        }
                        else
                            filterAPIDetailsArray.push({ 'endPointName': tempStr[0], 'endPointDetails': commonFuncObj.returnFilterPayloads(tempData.endPoint) });
                        new sessionStore().storeSecureData(commonConfig.SESSION_DT_FILTER_API_DETAILS, filterAPIDetailsArray);
                    }
                }
            }
            //End of Hold DT Search...
            config.headers['request-startTime'] = new Date().getTime();
            // Do something before request is sent
            loadCount++;
            var userId = sessionStore.ACTIVE_USER_ID;
            //Show loader
            window.$(".general-loader").show();
            window.$(".general-loader span p.switchText").html(i18n.t('loading'));
            if (currentUrl.indexOf(commonConfig.COURSE_MANAGER) !== -1 || currentUrl.indexOf(commonConfig.ASSIGNMENT_DETAILS) !== -1) {
                // will update loader text while course/courseSection and assignment study material downloading/uploading
                let data = commonFuncObj.IsJsonString(config.data) ? JSON.parse(config.data) : null;
                if (SharedApiConfig.API_TO_UPLOAD_AND_SAVE === config.url) {
                    window.$(".general-loader span p.switchText").html(i18n.t('uploading'))
                    window.$(".general-loader").show();
                } else if (!!data && !!data.endPoint && data.endPoint.indexOf(commonConfig.DOWNLOAD_FILE) !== -1) {
                    window.$(".general-loader span p.switchText").html(i18n.t('downloading'))
                    window.$(".general-loader").show();
                }
            }
            if (userId) {
                config.headers['ActiveUserId'] = userId
            }
            var token = new sessionStore().returnSessionToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, function (error) {
            // Do something with request error
            window.$(".general-loader").hide();
            return Promise.reject(error);
        });
        axios.interceptors.response.use(response => {
            loadCount--;
            if (loadCount === 0) {
                //Hide loader
                window.$(".general-loader").hide();
            }
            if (!!response.data.showMessage && !!response.data.message) {
                if (new sessionStore().returnNotificationStatus() && new sessionStore().returnNotificationStatus() === commonConfig.IS_HIDE)
                    new sessionStore().storeSecureData('markersNotificationStatus', '');
                else {
                    if (!response.data.message.includes('warning : ')) {
                        this.props.onShowNotify(response.data.message, !!response.data.success ? "success" : "error")
                    }
                }
            }
            return response;
        }, error => {
            window.$(".general-loader").hide();
            if (401 === error.response.status) {
                Swal.fire({
                    title: "Session Expired",
                    text: "Your session has expired. Would you like to be redirected to the login page?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel"
                }).then(() => {
                    new sessionStore().cleanSessionStore()
                    window.location = '/login';
                })
            } else {
                return Promise.reject(error);
            }
            return error;
        });
    }
    setShowNotifyStatus = (status) => {
        this.setState({ isShowNotify: status })
    }
    render() {
        const { notifyMessage, notifyType } = this.props.commonProps;
        return (
            <React.Fragment>
                {!!notifyMessage &&
                    <MarkersNotify
                        isShowNotify={this.props.onRemoveNotify}
                        message={notifyMessage}
                        type={!!notifyType ? notifyType : "success"}
                    />}
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        commonProps: state.commonStore
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onShowNotify: (message, type) => dispatch(actionsCommon.showNotify(message, type)),
        onRemoveNotify: () => dispatch({ type: actionTypesShared.REMOVE_NOTIFY })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Intercepter);