import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';
const initialState = {
    saveAttendanceDownloadCountReport: false,
    attendanceTeacherList: [],
    attendanceStudentFilterList: [],
    attendanceStudentList: [],
    attendanceMissingTeacherList: [],
    attendanceCourseSubjectList: [],
    attendanceCourseSectionList: [],
    attendanceSectionPeriodList: [],
    saveAttendanceEvent: false,
    attendanceAbsenseLetterStudentList: [],
    attendanceAbsenseLetterFormatList: [],
    attendanceRunViewAbsenseLetterFormatList: [],
    attendanceEventList: [],
    attendanceEventProfileList: [],
    attendanceEventPersonList: [],
    attendanceEventPersonIdList: [],
    attendanceEventDepartmentList: [],
    saveAttendanceStudent: false,
    saveAttendanceTeacher: false,
    attendanceGradeLevelList: [],
    lookupAttendanceReportTypeList: [],
    attendanceSubjectList: [],
    attendanceCourseList: [],
    attendancePeriodList: [],
    attendanceSearchResultList: [],
    attendanceResultList: [],
    attendanceStudentListOfAttendanceList: [],
    attendanceCourseSubjectMissingTeacherList: [],
    attendanceCourseMissingTeacherList: [],
    attendanceCourseSectionMissingTeacherList: [],
    attendanceSectionPeriodMissingTeacherList: [],
    attendanceTeacherMissingAttendanceList: [],
    attendanceStaffSearchResultList: [],
    attendanceStaffDayWiseResultList: [],
    attendanceAdministrationList: [],
    attendanceAssessmentSessionList: [],
    attendanceAssessmentSessionExamList: [],
    attendanceExamTeacherList: [],
    attendanceExamStudentList: [],
    saveAttendanceExam: {},
    attendanceTeacherDailyAttendanceList: [],
    attendanceStudentDailyAttendanceList: [],
    saveAttendanceDaily: false,
    attendanceStudentPeriodwiseAttendanceList: [],
    attendanceStudentWiseMonthAttendanceList: [],
    saveAttendanceStudentPeriodWiseAttendance: false,
    attendanceStudentMassAttSchoolCourseList: [],
    attendanceStudentMassAttSchoolCourseSectionList: [],
    attendanceStudentWisePeriodList: [],
    attendanceStudentAttendanceDetailsList: [],
    saveAttendancePeriodWiseStudentDetails: {},
    attendanceStudentMassAttendanceList: [],
    saveAttendanceMassAttendanceReviewDetails: {},
    saveAttendanceProcessMassAttReviewDetails: false,
    saveAttendanceStudentMassAttInfo: {},
    attendanceSchoolCourseList: [],
    attendanceSchoolCourseSectionList: [],
    attendanceStatusList: [],
    attendancePresentList: [],
    attendanceExcusedAbsentList: [],
    attendanceUnexcusedAbsentList: [],
    attendanceCategoryList: [],
    saveAttendanceCategory: {},
    getAttendanceCategory: {},
    attendanceSubCategoryList: [],
    saveAttendanceSubCategory: {},
    getAttendanceSubCategory: {},
    attendanceCodeCategoryList: [],
    attendanceCodeSubCategoryList: [],
    attendancePreviousDateSubjectList: [],
    getAttendanceStaffReport: {},
}
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
}
const commonHandleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const reducerAttendance = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'ATTENDANCE_LIST': return commonHandleSuccess(state, action, camelCase(`attendance_${action.title}_list`));
        case 'ATTENDANCE_SAVE': return commonHandleSuccess(state, action, camelCase(`save_attendance_${action.title}`));
        case 'ATTENDANCE_GET': return commonHandleSuccess(state, action, camelCase(`get_attendance_${action.title}`));
        case 'ATTENDANCE_DELETE': return commonHandleSuccess(state, action, camelCase(`delete_attendance_${action.title}`));
        case commonConfig.ATTENDANCE_STORE_RESET: return commonHandleSuccess(state, action, action.title);
        case 'HANDLE_ATTENDANCE_FAIL': return handleFail(state);
        default: return state;
    }
}
export default reducerAttendance