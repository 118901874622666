import React, { Component } from 'react';
import MarkersDatepicker from '../component/markersDatepicker';
import i18n from '../../../i18n';
var filterData = '';
var filterColumns = [];
var filterArray = [];
class advanceFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datePickerFileds: {}
        }
    }
    //This function is called on submit
    handleSubmit = (event, filterDetails, classObj, filterMethod = '') => {
        event.preventDefault();
        if (filterMethod != '') {
            classObj[filterMethod](filterArray);
        }
        else {
            var searchArr = [];
            var fieldName = '';
            filterDetails.map((values, k) => {
                if (values.type === 'text') {
                    searchArr.push(window.$('#' + filterDetails[0].name).val());
                    fieldName = values.name;
                    window.$('#' + filterDetails[0].name).val("");
                }
                if (values.type === 'dropdown') {
                    searchArr.push(window.$('#' + filterDetails[1].name).find(":selected").text());
                    fieldName = values.name;
                }
                if (values.type === 'checkbox') {
                    searchArr.push(window.$('#' + filterDetails[2].name).find(":checked").text());
                    fieldName = values.name;
                }
                if (values.type === 'toggle') {
                    searchArr.push(window.$('#' + filterDetails[k].name).find(":checked").text());
                    fieldName = values.name;
                }
            });
            if (searchArr.length != 0) {
                classObj['paginate'](1, '', '{' + filterData + '}');
            }
        }
    }
    //This function is called for storing various input values in state variables from input fields.
    handleChange = (event, name, type) => {
        let datePickerFields = this.state.datePickerFileds;
        filterColumns = [...new Set(filterColumns)];
        var id = '';
        if (name === 'birthDate' || name === 'exitDate') {
            id = name
        }
        else {
            id = event.target.id
        }
        var filterArr = filterData.split(",");
        if (filterArr.length != 0 && filterArr.length === 1) {
            filterColumns.map((columnVal, j) => {
                var value = event.target.value
                if (columnVal === id) {
                    if (columnVal === "includeAllSessions" || columnVal === "iscalendarinstructionday" || columnVal === "includeAllSchools" || columnVal === "includeInactive") {
                        if (event.target.checked === true)
                            value = true
                        else
                            value = null
                    }
                    if (columnVal === "birthDate" || columnVal === 'exitDate' || type === 'datePicker') {
                        datePickerFields[name] = event.target.value;
                        value = event.target.value;
                    }
                    filterData = filterData + '"' + columnVal + '":' + '"' + value + '"' + ',';
                }
                else
                    filterData = filterData + '"' + columnVal + '":' + 'null,';
            });
            filterData = filterData.substring(0, filterData.length - 1);
        }
        else {
            var filterVal = [];
            var tempFilterData = '';
            var tempfilterColumn = '';
            filterArr.map((columnVal, j) => {
                filterVal = columnVal.split(":");
                tempfilterColumn = filterVal[0].split('"')[1];
                if (tempfilterColumn === id) {
                    if (!!event.target.value || (event.target.checked !== undefined)) {
                        var value = event.target.value
                        if (tempfilterColumn === "includeAllSessions" || tempfilterColumn === 'iscalendarinstructionday' || tempfilterColumn === "includeAllSchools" || tempfilterColumn === "includeInactive") {
                            if (event.target.checked === true)
                                value = true
                            else
                                value = null
                        }
                        if (tempfilterColumn === "birthDate" || tempfilterColumn === 'exitDate' || type === 'datePicker') {
                            datePickerFields[name] = event.target.value;
                            value = event.target.value;
                        }
                        tempFilterData = tempFilterData + '"' + tempfilterColumn + '":' + '"' + value + '"' + ',';
                    }
                    else
                        tempFilterData = tempFilterData + '"' + event.target.id + '":' + 'null,';
                }
                else
                    tempFilterData = tempFilterData + '"' + tempfilterColumn + '":' + filterVal[1] + ',';
            });
            if (tempFilterData != '')
                filterData = tempFilterData;
            filterData = filterData.substring(0, filterData.length - 1);
        }
        this.setState({ datePickerFields });
    }
    //This function is called for storing various input values in state variables from checkbox fields.
    handleCheckboxChange = (event, field = '') => {
        let fieldValue = event.target.checked;
        if (event.target.checked === true) {
            window.$('#' + event.target.id).prop('checked', true);
        }
        else
            window.$('#' + event.target.id).prop('checked', false);
        let tempObj = { fieldName: field, fieldValue: fieldValue };
        const index = filterArray.findIndex(({ fieldName }) => fieldName === field);
        if (index !== -1) {
            filterArray.splice(index, 1);
        }
        filterArray.push(tempObj);
    }
    clearFilterFields = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        filterData = '';
        this.setState({ datePickerFileds: {} }, () => {
            window.$("#filterFields").trigger("reset");
        });
    }
    //This function returns filter columns
    returnFilter(filterDetails, classObj, filterMethod = '', additionalClass = 'more-filter-empty', filterValues = {}) {
        var returnContent = '';
        var counter = 0;
        var object = '';
        {
            returnContent = filterDetails.map((values, k) => {
                filterColumns.push(values.name);
                counter = (parseInt(k) + 1);
                object = filterDetails[counter];
                if ((k % 2) === 0) {
                    switch (values.type) {
                        case 'text':
                            return <div className="row" key={k}>
                                <div className="col-sm-6 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label">{values.label}</label>
                                        <input type="text" className="form-control" placeholder={values.label} id={values.name} name={values.name} onChange={e => { this.handleChange(e) }} />
                                    </div>
                                </div>
                            </div>
                        case 'dropdown':
                            return <div className="row" key={k}>
                                <div className="col-sm-6 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label ">{filterDetails[k].label}</label>
                                        <select className="custom-select" id={filterDetails[k].name} onChange={e => { this.handleChange(e) }}>
                                            <option value="" >{i18n.t('select')}</option>
                                            {!!filterDetails[k] && !!filterDetails[k].length >= 0 && filterDetails[k].value.map((lookup, key) => {
                                                if (Object.keys(filterValues).length != 0 && Object.values(JSON.parse(filterValues)).includes(lookup.id.toString()) === true)
                                                    return <option key={key} value={lookup.id} selected>{lookup.title}</option>
                                                else
                                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                {filterDetails[counter] !== undefined ?
                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                        <div className="form-group">
                                            <label className="col-form-label">{filterDetails[counter].label}</label>
                                            <select className="custom-select" id={filterDetails[counter].name} onChange={e => { this.handleChange(e) }}>
                                                <option value="">{i18n.t('select')}</option>
                                                {filterDetails[counter].value.map((lookup, key) => {
                                                    if (Object.keys(filterValues).length != 0 && Object.values(JSON.parse(filterValues)).includes(lookup.id.toString()) === true)
                                                        return <option key={key} value={lookup.id} selected>{lookup.title}</option>
                                                    else
                                                        return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    : ""
                                }
                            </div>
                        case 'textDropdown':
                            return <div className="row" key={k}>
                                <div className="col-sm-6 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label">{filterDetails[k].label}</label>
                                        <input type="text" className="form-control" placeholder={filterDetails[k].label} id={filterDetails[k].name} name={filterDetails[k].name} onChange={e => { this.handleChange(e) }} />
                                    </div>
                                </div>
                                {filterDetails[counter] !== undefined ?
                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                        <div className="form-group">
                                            <label className="col-form-label">{filterDetails[counter].label}</label>
                                            <select className="custom-select" id={filterDetails[counter].name} onChange={e => { this.handleChange(e) }}>
                                                <option value="">{i18n.t('select')}</option>
                                                {filterDetails[counter].value.map((lookup, key) => {
                                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    : ""
                                }
                            </div>
                        case 'dropdownToggle':
                            return <div className="row" key={k}>
                                <div className="col-sm-6 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label className="col-form-label">{filterDetails[k].label}</label>
                                        <select className="custom-select" id={filterDetails[k].name} onChange={e => { this.handleChange(e) }}>
                                            <option value="" >{i18n.t('select')}</option>
                                            {!!filterDetails[k] && !!filterDetails[k].lenght >= 0 && filterDetails[k].value.map((lookup, key) => {
                                                if (Object.keys(filterValues).length != 0 && Object.values(JSON.parse(filterValues)).includes(lookup.id.toString()) === true)
                                                    return <option key={key} value={lookup.id} selected>{lookup.title}</option>
                                                else
                                                    return <option key={key} value={lookup.id}>{lookup.title}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                {filterDetails[counter] !== undefined ?
                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                        <label className="col-form-label d-none d-lg-block d-md-none d-sm-block mb-1">&nbsp;</label>
                                        <div className="form-group">
                                            <label className="d-flex align-items-center mb-0">
                                                <div className="fluid-switch-toggle cursor-pointer">
                                                    {
                                                        (Object.keys(filterValues).length != 0 && filterDetails[counter].name && JSON.parse(filterValues)[filterDetails[counter].name] === true)
                                                            ?
                                                            <input className="switch-toggle-state" type="checkbox" name={filterDetails[counter].name} id={filterDetails[counter].name} onChange={e => { this.handleChange(e) }} checked={true} value={true} />
                                                            :
                                                            <input className="switch-toggle-state" type="checkbox" name={filterDetails[counter].name} id={filterDetails[counter].name} onChange={e => { this.handleChange(e) }} />
                                                    }
                                                    <input className="switch-toggle-state" type="checkbox" name="isIncludeInactiveEnrollment" onChange={this.handleChange} checked={this.state.isIncludeInactiveEnrollment} />
                                                    <div className="switch-indicator"></div>
                                                    <div className="switch-activebg"></div>
                                                </div>
                                                <span className="switch-text">{filterDetails[counter].label}</span>
                                            </label>
                                        </div>
                                    </div>
                                    : ""
                                }
                            </div>
                        case 'checkbox':
                            return <div className="row" key={k}>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-12">
                                    <div className="form-group">
                                        <label className="d-flex align-items-center mb-0">
                                            <div className="fluid-switch-toggle cursor-pointer">
                                                <input className="switch-toggle-state" type="checkbox" name={values.name} id={values.name} onChange={e => { this.handleCheckboxChange(e, values.name) }} />
                                                <div className="switch-indicator"></div>
                                                <div className="switch-activebg"></div>
                                            </div>
                                            <span className="switch-text">{values.label}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        case 'toggle':
                            return <div className="row" key={k}>
                                <div className="col-sm-6 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-flex align-items-center mb-0">
                                            <div className="fluid-switch-toggle cursor-pointer">
                                                {
                                                    (Object.keys(filterValues).length != 0 && filterDetails[k].name && JSON.parse(filterValues)[filterDetails[k].name] === true)
                                                        ?
                                                        <input className="switch-toggle-state" type="checkbox" name={filterDetails[k].name} id={filterDetails[k].name} onChange={e => {
                                                            this.handleChange(e)
                                                        }} checked={true} value={true} />
                                                        :
                                                        <input className="switch-toggle-state" type="checkbox" name={filterDetails[k].name} id={filterDetails[k].name} onChange={e => {
                                                            this.handleChange(e)
                                                        }} />
                                                }
                                                <div className="switch-indicator"></div>
                                                <div className="switch-activebg"></div>
                                            </div>
                                            <span className="switch-text">{filterDetails[k].label}</span>
                                        </label>
                                    </div>
                                </div>
                                {filterDetails[counter] !== undefined &&
                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                        <div className="form-group">
                                            <label className="d-flex align-items-center mb-0">
                                                <div className="fluid-switch-toggle cursor-pointer">
                                                    {
                                                        (Object.keys(filterValues).length != 0 && filterDetails[counter].name && JSON.parse(filterValues)[filterDetails[counter].name] === true)
                                                            ?
                                                            <input className="switch-toggle-state" type="checkbox" name={filterDetails[counter].name} id={filterDetails[counter].name} onChange={e => { this.handleChange(e) }} checked={true} value={true} />
                                                            :
                                                            <input className="switch-toggle-state" type="checkbox" name={filterDetails[counter].name} id={filterDetails[counter].name} onChange={e => { this.handleChange(e) }} />
                                                    }
                                                    <div className="switch-indicator"></div>
                                                    <div className="switch-activebg"></div>
                                                </div>
                                                <span className="switch-text">{filterDetails[counter].label}</span>
                                            </label>
                                        </div>
                                    </div>
                                }
                            </div>
                        case 'datePicker':
                            return <div className="row" key={k}>
                                <div className="col-sm-6 col-md-12 col-lg-6">
                                    <MarkersDatepicker
                                        title={filterDetails[k].label}
                                        placeholder={filterDetails[k].label}
                                        name={filterDetails[k].name}
                                        value={this.state.datePickerFileds[filterDetails[k].name]}
                                        onChange={(e) => this.handleChange(e, filterDetails[k].name, 'datePicker')}
                                    />
                                </div>
                                {filterDetails[counter] !== undefined &&
                                    <div className="col-sm-6 col-md-12 col-lg-6">
                                        <MarkersDatepicker
                                            title={filterDetails[counter].label}
                                            placeholder={filterDetails[counter].label}
                                            name={filterDetails[counter].name}
                                            value={this.state.datePickerFileds["exitDate"]}
                                            onChange={(e) => this.handleChange(e, "exitDate", 'datePicker')}
                                        />
                                    </div>
                                }
                            </div>
                    }
                }
            })
        }
        return <div>
            <div className={"slidepanel slidepanel-right " + additionalClass}>
                <div className="slidepanel-inner px-3">
                    <form className="mt-3" id="filterFields">
                        <h4>{i18n.t("moreFilters")}</h4>
                        {returnContent}
                        <div className="sidebar-footer text-right ">
                            <button className="btn btn-secondary mr-2" onClick={this.clearFilterFields} data-dismiss="slidepanel">{i18n.t("cancel")}</button>
                            <button className="btn btn-success " data-dismiss="slidepanel" onClick={(e) => this.handleSubmit(e, filterDetails, classObj, filterMethod)}>{i18n.t("search")}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="slidepanel-overlay-right"></div>
        </div>
    }
}
export default advanceFilter;