import BaseComponent from '../shared/BaseComponent';
import ExecuteArgs from '../shared/model/executeArgs';
import commonSchoolConfig from '../school/commonSchoolConfig';
import commonStudentConfig from '../student/commonStudentConfig';
import commonStaffConfig from '../staff/commonStaffConfig'
import commonCourseConfig from '../course/commonCourseConfig';
import commonConfig from '../common/commonConfig';
import i18n from './../../i18n';
import SchoolCommonComponent from '../school/pages/schoolCommonComponent';
import SchoolCommonHeaderComponent from '../school/pages/schoolCommonHeader';
import SidebarComponent from '../school/pages/sidebarComponent';
import * as actionsLogin from '../common/store/actions/index';
import * as actions from './store/actions/index';
import * as actionsCommon from '../shared/store/actions/index';
import * as actionsStudents from '../student/store/actions/index';
import * as commonSystemConfig from '../system/commonSystemConfig';
import * as actionsSystem from '../system/store/actions/index';
import * as actionsAttendance from '../attendance/store/actions/index';
import * as actionsStaffs from '../staff/store/actions/index';
import * as actionsSchools from '../school/store/actions/index';
import commonAttendanceConfig from '../attendance/commonAttendanceConfig';
import * as actionsCourse from '../course/store/actions/index';
import * as actionsDistrict from '../district/store/actions/index';
import * as convertDateFormat from '../common/component/markersConvertDateComponent';
import MarkersMultiLingual from '../common/component/markersMultiLingualComponent';
import ConvertDate from '../common/component/markersConvertDateComponent';
import dataTable from '../common/component/markersDataTableComponent';
import sessionStore from '../common/component/sessionStoreComponent';
import Validation from '../common/component/validationComponent';
import CommonFuncs from '../common/component/markersCommonFunctionComponent';
import markersBreadCrumb from '../common/component/markersBreadCrumbComponent';
import MarkersCommonSelect from '../common/component/markersSelect';
import markersCommonPhoneComponent from '../common/component/markersCommonPhoneComponent';
import MarkersCommonPerson from '../common/component/markersCommonPersonComponent';
import advanceFilter from '../common/component/markersAdvanceFilterComponent';
import convertTimeZone from '../common/component/convertTimeZoneComponent';
import MarkersDelete from '../common/component/markersDeleteComponent';
import MarkersDeleteV2 from '../common/component/markersDeleteComponentV2';
import commonDistrictConfig from '../district/commonDistrictConfig';
import extendedDataTable from '../common/component/markersExtendedDataTableComponent';
import Paginate from '../common/component/markersPaginateComponent';
import { redirectBuild } from '../common/component/commonBuildRedirect';
import MarkersDatepicker from '../common/component/markersDatepicker';
export {
    BaseComponent,
    Validation,
    ExecuteArgs,
    commonSchoolConfig,
    commonConfig,
    commonStudentConfig,
    commonStaffConfig,
    commonAttendanceConfig,
    commonCourseConfig,
    commonDistrictConfig,
    MarkersDeleteV2,
    i18n,
    sessionStore,
    SchoolCommonComponent,
    SchoolCommonHeaderComponent,
    SidebarComponent,
    actions,
    actionsCommon,
    actionsStudents,
    actionsStaffs,
    actionsSchools,
    actionsCourse,
    actionsLogin,
    actionsDistrict,
    MarkersMultiLingual,
    ConvertDate,
    dataTable,
    CommonFuncs,
    markersBreadCrumb,
    MarkersCommonSelect,
    markersCommonPhoneComponent,
    MarkersCommonPerson,
    advanceFilter,
    convertTimeZone,
    MarkersDelete,
    extendedDataTable,
    Paginate,
    redirectBuild,
    actionsSystem,
    commonSystemConfig,
    convertDateFormat,
    MarkersDatepicker,
    actionsAttendance
}
