import { updateObject } from '../../../shared/store/utility';
import commonConfig from '../../../common/commonConfig';
import { camelCase } from 'lodash';

const initialState = {
    messageSendEmailList: [],
    getMessageSendEmail: {},
    messageSentMessagesList: [],
    getMessageSentMessages: {},
    saveMessageSendMessage: false,
    saveMessageSendEmail: false,
    messageReceivedMessagesList: [],
    messageUserProfileList: [],
    saveMessageSendSms: false,
    messageSmsList: [],
    getMessageSms: {}
}
const handleFail = (state) => {
    return updateObject(state, { isLoading: false });
}
const handleSuccess = (state, action, storeStateName) => {
    return updateObject(state, {
        loading: false,
        [storeStateName]: action.responseData.data
    });
}
const reducerMessage = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case 'MESSAGE_LIST': return handleSuccess(state, action, camelCase(`message_${action.title}_list`));
        case 'MESSAGE_SAVE': return handleSuccess(state, action, camelCase(`save_message_${action.title}`));
        case 'MESSAGE_GET': return handleSuccess(state, action, camelCase(`get_message_${action.title}`));
        case commonConfig.MESSAGE_STORE_RESET: return handleSuccess(state, action, action.title);
        case 'HANDLE_MESSAGE_FAIL': return handleFail(state);
        default: return state;
    }
}
export default reducerMessage;