import sessionStore from './sessionStoreComponent';
export default class markersCommonPhoneComponent {
    getPhoneFormat(num) {
        if (num !== null) {
            let cleaned = ('' + num).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                switch (new sessionStore().returnPhoneFormat()) {
                    case '(###) ###-####':
                        return match[1] + match[2] + match[3]
                    default:
                }
            } else {
                return num
            }
        }
    }
}